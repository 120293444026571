import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import { IAffiliate } from './types';

const EXPLORE = 'explore';
const AFFILIATES = `affiliates`;
export const API_VERSION = 'v2';

const apiRoot = `/${EXPLORE}/${API_VERSION}/${AFFILIATES}`;

const affiliateEndpoints = {
  get: `${apiRoot}/:id`,
  getBySlug: `${apiRoot}/slug/:slug`
};

const getAffiliateById =
  (apiClient: PoinzApiClient) =>
  async (id: string): Promise<IAffiliate> => {
    const result = await apiClient.get(affiliateEndpoints.get, { pathParams: { id } });
    return result.body;
  };

const getAffiliateBySlug =
  (apiClient: PoinzApiClient) =>
  async (slug: string): Promise<NewPaginationModel<IAffiliate>> => {
    const result = await apiClient.get(affiliateEndpoints.getBySlug, {
      pathParams: {
        slug
      }
    });

    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  getById: getAffiliateById(apiClient),
  getAffiliateBySlug: getAffiliateBySlug(apiClient)
});
