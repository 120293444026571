import { PoinzApiClient } from '../../api';
import businessProfile from './businessProfile';
import contractCompany from './contractCompany';
import location from './location';
import webConfig from './webConfig';
import userDeal from './userDeal';
import dealLocation from './dealLocation';
import dealCollects from './dealCollects';
import wallet from './wallet';
import ticket from './ticket';

export default (apiClient: PoinzApiClient) => ({
  contractCompany: contractCompany(apiClient),
  businessProfile: businessProfile(apiClient),
  location: location(apiClient),
  userDeal: userDeal(apiClient),
  webConfig: webConfig(apiClient),
  dealLocation: dealLocation(apiClient),
  dealCollects: dealCollects(apiClient),
  wallet: wallet(apiClient),
  ticket: ticket(apiClient)
});
