import { PoinzApiClient } from '../../api';
import program from './program';
import commission from './commission';
import claims from './claims';

export default (apiClient: PoinzApiClient) => ({
  program: program(apiClient),
  commission: commission(apiClient),
  claims: claims(apiClient)
});
