import * as React from 'react';
import { MenuItem, ListItemText, MenuList, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { TranslationObject } from 'serviceNew/locale';
import Text from '../../components/Text';

type Props = {
  isSidebarOpen: boolean,
  label: TranslationObject,
  children: React.ReactNode
};

type State = {
  expanded: boolean
};

class CollapsableNavListItem extends React.PureComponent<Props, State> {
  state = {
    expanded: true
  };

  handleToggle = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { expanded } = this.state;
    const { children, label, isSidebarOpen } = this.props;

    return (
      <MenuList>
        {isSidebarOpen && (
          <MenuItem onClick={this.handleToggle}>
            <ListItemText>
              <Text variant="subtitle1" context={label.context}>
                {label.key}
              </Text>
            </ListItemText>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
        )}
        <Collapse in={expanded || !isSidebarOpen} timeout="auto">
          {children}
        </Collapse>
      </MenuList>
    );
  }
}

export default CollapsableNavListItem;
