import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AuthProvider from 'App/providers/AuthProvider';
import { LoadingOverlay } from 'App/components';
import 'serviceNew/firebase';
import { theme } from 'theme/muiTheme';
import './index.css';
import App from './App';
import { LocaleProvider } from './App/providers';

const root = createRoot(document.getElementById('root') as any);
root.render(
  <Suspense fallback={<LoadingOverlay loading />}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <LocaleProvider>
            <App />
          </LocaleProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Suspense>
);

// enable only if we need it
// registerServiceWorker();
