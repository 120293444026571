import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { TranslationObject } from 'serviceNew/locale';
import { IconType } from 'App/components/Icon';
import { Checkbox, Text, Button, RawText, IconButton, MoreMenuButton } from 'App/components';
import DataTablePagination from './DataTablePagination';

const SHADOW = '0px 5px 10px 0px #00000050';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius * 2,
    flex: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableContainer: {
    overflowX: 'scroll',
    flex: 1
  },
  batchActionsContainer: {
    position: 'absolute',
    left: 54, //
    top: 0,
    right: 0,
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[900],
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.common.white
  },
  batchActionsButtonContainer: {
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(-1)
  },
  batchActionItem: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  },
  headerBackground: {
    // @ts-expect-error - TS2339 - Property 'headerBackgroundColor' does not exist on type '{}'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: ({ headerBackgroundColor }) => headerBackgroundColor || theme.palette.grey[100]
  },
  bodyBackground: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white
  },
  batchHeaderBackground: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[900]
  },
  tableRow: {
    flex: 1,
    minHeight: 52,
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    minWidth: '100%'
  },
  stickyStartColumn: {
    position: 'sticky',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(1),
    zIndex: 1
  },
  stickyEndColumn: {
    position: 'sticky',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(1),
    zIndex: 1
  },
  mainColumnsContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    borderBottom: '0.5px solid #eee'
  },
  padding: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(2)
  },
  column: {
    width: 100,
    alignItems: 'center'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignCenter: {
    textAlign: 'center'
  },
  textBody2: {
    '&:only-child': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    }
  },
  loadingContainer: {
    height: 120
  },
  content: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(2px)'
  }
}));

const ACTION_ICON_SIZE = 18;
const ACTION_ICON_BUTTON_SIZE = ACTION_ICON_SIZE + 2 * 12;

export type TableAction<T> = {
  icon: IconType;
  label?: TranslationObject;
  hide?: (arg1: T) => boolean;
  action: (data: T, index?: number) => any;
};

type BatchAction<K> = {
  onClick: (arg1: K[]) => unknown;
  label: TranslationObject;
};

type TableColumn<T> = {
  id?: string;
  onHover?: boolean;
  flex?: number;
  width?: number | string;
  headerLabel?: TranslationObject;
  fieldName: string;
  fieldText?:
    | string
    | number
    | TranslationObject
    | ((dataObject: T, index?: number) => TranslationObject | number | string);
  fieldMapper?: (dataObject: T, index?: number) => any;
  textProps?: Partial<
    JSX.LibraryManagedAttributes<typeof RawText, React.ComponentProps<typeof RawText>>
  >;
  align?: 'center' | 'left' | 'right';
};

type TableProps<T, K> = {
  columns: TableColumn<T>[];
  data: Array<T>;
  keyExtractor: (arg1: T) => K;
  handlePageChange?: (arg1: number) => void;
  totalPages?: number;
  currentPage?: number;
  className?: string;
  actions?: TableAction<T>[];
  hiddenActions?: TableAction<T>[];
  batchActions?: BatchAction<K>[];
  isLoading?: boolean;
  emptyLabel?: TranslationObject;
  headerBackgroundColor?: string;
};

function DataTable<T, K extends string | number>(props: TableProps<T, K>) {
  const {
    batchActions,
    data,
    columns,
    keyExtractor,
    actions,
    hiddenActions,
    handlePageChange,
    totalPages,
    currentPage,
    isLoading,
    emptyLabel,
    headerBackgroundColor,
    className
  } = props;

  const [selectedElements, setSelectedElemets] = React.useState<K[]>([]);
  const [isScrolledLeft, setIsScrolledLeft] = React.useState<boolean>(false);
  const [isScrolledRight, setIsScrolledRight] = React.useState<boolean>(false);
  const classes = useStyles({ isScrolledLeft, headerBackgroundColor });
  const scrollRef = React.useRef();

  const hasCheckbox = React.useMemo(
    () => data.length > 0 && !isLoading && batchActions && batchActions.length > 0,
    [batchActions, data, isLoading]
  );

  const hasActions = React.useMemo(
    () => data.length > 0 && !isLoading && actions && actions.length > 0,
    [actions, data, isLoading]
  );

  function getAlignClass(align?: 'center' | 'left' | 'right' | null) {
    switch (align) {
      case 'center':
        return classes.alignCenter;
      case 'right':
        return classes.alignRight;
      default:
        return classes.alignLeft;
    }
  }

  function getScrollAtEnd() {
    if (scrollRef?.current) {
      const { clientWidth, scrollWidth, scrollLeft } = scrollRef.current || {};
      return scrollWidth - scrollLeft === clientWidth;
    }
    return false;
  }

  const checkHasScroll = React.useCallback(() => {
    if (scrollRef?.current) {
      const { clientWidth, scrollWidth } = scrollRef.current || {};
      setIsScrolledRight(clientWidth !== scrollWidth && !getScrollAtEnd());
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', checkHasScroll);
    return () => window.removeEventListener('resize', checkHasScroll);
  }, [checkHasScroll]);

  React.useLayoutEffect(() => {
    checkHasScroll();
  }, [checkHasScroll]);

  function renderCheckboxHeader() {
    return (
      <div
        className={clsx(
          classes.stickyStartColumn,
          selectedElements.length > 0 ? classes.batchHeaderBackground : classes.headerBackground
        )}
        style={{ boxShadow: isScrolledLeft ? SHADOW : undefined }}
      >
        <Checkbox
          color="primary"
          value={selectedElements.length > 0}
          indeterminate={data.length !== selectedElements.length && selectedElements.length > 0}
          onChange={() =>
            setSelectedElemets(currSelection =>
              data.length === currSelection.length ? [] : data.map(el => keyExtractor(el))
            )
          }
          size="small"
        />
      </div>
    );
  }

  function renderEmptyLabel() {
    return (
      <>
        <div className={classes.loadingContainer} />
        <div className={classes.loading}>
          <Text context={emptyLabel?.context}>{emptyLabel?.key || 'general.noData'}</Text>
        </div>
      </>
    );
  }

  function renderCheckboxField(row: T) {
    const rowKey = keyExtractor(row);
    const isSelected = selectedElements.includes(rowKey);
    return (
      <div
        className={clsx(classes.stickyStartColumn, classes.bodyBackground)}
        style={{ boxShadow: isScrolledLeft ? SHADOW : undefined }}
      >
        <Checkbox
          color="primary"
          value={isSelected}
          onChange={() =>
            setSelectedElemets(prevSelection =>
              isSelected ? prevSelection.filter(sel => sel !== rowKey) : [...prevSelection, rowKey]
            )
          }
          size="small"
        />
      </div>
    );
  }

  function renderActionHeader() {
    if (!hasActions || selectedElements.length > 0) {
      return null;
    }
    const hiddenActionLength = hiddenActions?.length ? 1 : 0;
    return (
      <div
        className={clsx(classes.stickyEndColumn, classes.headerBackground)}
        style={{ boxShadow: isScrolledRight ? SHADOW : undefined }}
      >
        <div
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          style={{ width: ACTION_ICON_BUTTON_SIZE * (actions?.length + hiddenActionLength || 0) }}
        />
      </div>
    );
  }

  function renderActionField(row: T, index: number) {
    if (!actions || selectedElements.length > 0) {
      return null;
    }
    return (
      <div
        className={clsx(classes.stickyEndColumn, classes.bodyBackground)}
        style={{ boxShadow: isScrolledRight ? SHADOW : undefined }}
      >
        {actions.map((action, idx) => {
          const hide = typeof action.hide === 'function' && action.hide(row);
          return (
            <IconButton
              key={`${index}-${idx}`}
              size={ACTION_ICON_SIZE}
              disabled={hide}
              icon={action.icon}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                action.action(row, index);
              }}
            />
          );
        })}
        {hiddenActions?.length && (
          <MoreMenuButton actions={hiddenActions || []} data={row} index={index} />
        )}
      </div>
    );
  }

  function renderBatchActions() {
    if (!batchActions) {
      return null;
    }
    return (
      <div className={clsx(classes.batchActionsContainer, classes.padding)}>
        <Text variant="subtitle2" context={{ count: selectedElements.length }}>
          general.selectedRows
        </Text>
        <div className={classes.batchActionsButtonContainer}>
          {batchActions.map((ba, idx) => (
            <Button
              key={idx.toString()}
              variant="outlined"
              color="primary"
              className={classes.batchActionItem}
              onClick={() => ba.onClick(selectedElements)}
              label={ba.label}
            />
          ))}
        </div>
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className={clsx(classes.tableRow, classes.headerBackground)}>
        {hasCheckbox && renderCheckboxHeader()}

        {hasCheckbox && selectedElements.length > 0 ? (
          renderBatchActions()
        ) : (
          <div className={clsx(classes.mainColumnsContainer, classes.headerBackground)}>
            {columns.map(col => (
              <div
                className={clsx(classes.column, classes.padding)}
                style={{ flex: col.flex, width: col.width }}
                key={col.fieldName}
              >
                {col.headerLabel ? (
                  <Text
                    className={clsx(classes.cell, getAlignClass(col.align))}
                    variant="subtitle2"
                    context={col.headerLabel?.context}
                  >
                    {col.headerLabel.key}
                  </Text>
                ) : null}
              </div>
            ))}
          </div>
        )}

        {renderActionHeader()}
      </div>
    );
  }

  function renderCell(row: T, col: TableColumn<T>) {
    if (col.fieldText) {
      let label: TranslationObject | null | undefined;
      if (typeof col.fieldText === 'function') {
        const mappedFieldText = col.fieldText(row);
        label =
          typeof mappedFieldText === 'object'
            ? mappedFieldText
            : { key: 'text.no.translation', context: { value: mappedFieldText } };
      } else if (typeof col.fieldText === 'object') {
        label = col.fieldText;
      } else {
        label = { key: 'text.no.translation', context: { value: col.fieldText } };
      }
      return (
        <Text
          variant="body2"
          className={clsx(classes.cell, getAlignClass(col.align))}
          context={label.context}
          {...col.textProps}
        >
          {label.key}
        </Text>
      );
    }
    if (typeof col.fieldMapper === 'function') {
      return (
        <div className={clsx(classes.cell, classes.textBody2)} {...col.textProps}>
          {col.fieldMapper(row)}
        </div>
      );
    }
    return (
      (
        <RawText
          className={clsx(classes.cell, getAlignClass(col.align))}
          variant="body2"
          {...col.textProps}
        >
          {row[col.fieldName]}
        </RawText>
      ) || null
    );
  }

  function renderRow(row: T, index: number) {
    return (
      <div className={classes.tableRow} key={`row-${keyExtractor(row)}-${index}`}>
        {hasCheckbox && renderCheckboxField(row)}
        <div className={classes.mainColumnsContainer}>
          {columns.map((col, i) => (
            <div
              key={`row-${keyExtractor(row)}-${index}-${i}`}
              className={clsx(classes.column, classes.padding, getAlignClass(col.align))}
              style={{ flex: col.flex, width: col.width, overflow: 'hidden' }}
            >
              {renderCell(row, col)}
            </div>
          ))}
        </div>
        {renderActionField(row, index)}
      </div>
    );
  }

  function onScroll() {
    // @ts-expect-error - TS2339 - Property 'scrollLeft' does not exist on type 'never'.
    setIsScrolledLeft(scrollRef?.current?.scrollLeft !== 0);
    setIsScrolledRight(!getScrollAtEnd());
  }

  function renderPagination() {
    if (totalPages && currentPage != null && typeof handlePageChange === 'function') {
      return (
        <div className={classes.pagination}>
          <DataTablePagination
            disabled={isLoading}
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange as any}
          />
        </div>
      );
    }
    return null;
  }

  function renderContent() {
    if (data.length === 0) {
      return renderEmptyLabel();
    }
    return (
      <div className={classes.content}>
        {isLoading && (
          <>
            <div className={classes.overlay}></div>
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </>
        )}
        {data.map((row, index) => renderRow(row, index))}
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {/* @ts-expect-error - TS2322 - Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
      <div onScroll={onScroll} ref={scrollRef} className={classes.tableContainer}>
        {renderHeader()}
        {renderContent()}
      </div>
      {renderPagination()}
    </div>
  );
}

export default DataTable;
