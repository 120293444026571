import { PoinzApiClient } from '../../api';
import {
  ICartState,
  IItemBody,
  IShoppingCart,
  ILoyaltyUsage,
  IUpdateItemBody,
  ICartAddresses
} from './types';

const ROOT = '/shopping-cart/v1';
const apiRoot = `${ROOT}/shopping-carts`;

const getShoppingCart = (apiClient: PoinzApiClient) => async (): Promise<IShoppingCart> => {
  const result = await apiClient.get(apiRoot, {});
  return result.body;
};

const addItemToCart =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, item: IItemBody): Promise<IShoppingCart> => {
    const response = await apiClient.post(`${apiRoot}/${cartId}/items`, {
      body: item
    });
    return response.body;
  };

const removeItemFromCart =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, itemId: number): Promise<IShoppingCart> => {
    const response = await apiClient.delete(`${apiRoot}/${cartId}/items/${itemId}`, {});
    return response.body;
  };

const removeAllItemsFromCart =
  (apiClient: PoinzApiClient) =>
  async (cartId: number): Promise<IShoppingCart> => {
    const response = await apiClient.delete(`${apiRoot}/${cartId}/items/`, {});
    return response.body;
  };

const updateItem =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, itemId: number, item: IUpdateItemBody): Promise<IShoppingCart> => {
    const response = await apiClient.patch(`${apiRoot}/${cartId}/items/${itemId}`, {
      body: item
    });
    return response.body;
  };

const updateCheckoutState =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, state: ICartState): Promise<IShoppingCart> => {
    const response = await apiClient.patch(`${apiRoot}/${cartId}`, {
      body: { state }
    });
    return response.body;
  };

const updateLoyaltyUsage =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, useLoyalty: boolean): Promise<ILoyaltyUsage> => {
    const response = await apiClient.put(`${apiRoot}/${cartId}/loyalty`, {
      body: { useLoyalty }
    });
    return response.body;
  };

const updateAddress =
  (apiClient: PoinzApiClient) =>
  async (cartId: number, addresses: ICartAddresses): Promise<ICartAddresses> => {
    const response = await apiClient.put(`${apiRoot}/${cartId}/address`, {
      body: addresses
    });

    return response.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: getShoppingCart(apiClient),
  addItem: addItemToCart(apiClient),
  removeItem: removeItemFromCart(apiClient),
  removeAllItems: removeAllItemsFromCart(apiClient),
  updateItem: updateItem(apiClient),
  updateCheckoutState: updateCheckoutState(apiClient),
  updateLoyaltyUsage: updateLoyaltyUsage(apiClient),
  updateAddress: updateAddress(apiClient)
});
