import * as React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import Icon from '../Icon';
import Text from '../Text';
import LoadingOverlay from '../LoadingOverlay';

const useStyles = makeStyles(theme => ({
  dropzone: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    padding: theme.extra.spacing.base,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    border: `1px dashed ${theme.palette.grey[200]}`,
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    // @ts-expect-error - TS2339 - Property 'typography' does not exist on type 'DefaultTheme'.
    ...theme.typography.body1,
    textTransform: 'none',
    overflow: 'hidden'
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    paddingTop: theme.extra.spacing.tiny
  }
}));

type Props = {
  title: TranslationObject;
  subtitle: TranslationObject;
  error: TranslationObject;
  loading: boolean;
  getRootProps: (props?: any) => any;
  getInputProps: () => any;
};

function FileUploadView(props: Props) {
  const { title, subtitle, error, getRootProps, getInputProps, loading } = props;

  const classes = useStyles();

  return (
    <Button color="secondary" {...getRootProps({ className: classes.dropzone, component: 'div' })}>
      <input {...getInputProps()} />
      <div className={classes.content}>
        <Icon icon="cloud_upload" />
        <Text className={classes.text} context={title.context}>
          {title.key}
        </Text>
        {(subtitle || error) && (
          <Text
            color={error ? 'error' : 'secondary'}
            variant="caption"
            className={classes.text}
            context={error ? error.context : subtitle.context}
          >
            {error ? error.key : subtitle.key}
          </Text>
        )}
      </div>
      <LoadingOverlay loading={loading} />
    </Button>
  );
}

export default FileUploadView;
