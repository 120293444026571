import * as React from 'react';

import MuiFormControlLabel from '@mui/material/FormControlLabel';

import { TranslationObject } from 'serviceNew/locale';

import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';

type Props = {
  label: TranslationObject,
  required: boolean
} & LocaleProps;

function FormControlLabel(props: Props) {
  const { label, translate, required, ...restProps } = props;
  return (
// @ts-expect-error - TS2741 - Property 'control' is missing in type '{ changeLocale: (locale: "en" | "de" | "fr" | "it") => void; locale: "en" | "de" | "fr" | "it"; formatDateTime: (value: string, format: string) => string; parseMultilanguageString: (value: string | MultiLanguageString) => string | undefined; formatMessage: (value: LanguageInput) => string; formatNumber: (value: Numb...' but required in type 'FormControlLabelProps'.
    <MuiFormControlLabel
      label={translate(label.key, label.context) + (required ? ' *' : '')}
      {...restProps}
    />
  );
}

FormControlLabel.defaultProps = {
  required: false,
};

export default withLocale(FormControlLabel);
