import * as React from 'react';

import { TranslationKey } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';

import RawText from './RawText';

type TextProps = Diff<
  JSX.LibraryManagedAttributes<typeof RawText, React.ComponentProps<typeof RawText>>,
  {
    children: any;
  }
> & {
  context?: Record<any, any>;
  children: TranslationKey;
} & LocaleProps;

class Text extends React.PureComponent<TextProps> {
  static defaultProps = {
    context: undefined
  };

  render() {
    const { children, context, translate, ...props } = this.props;
    return <RawText {...props}>{translate(children, context)}</RawText>;
  }
}

export default withLocale(Text);
