import { PoinzApiClient } from '../../api';
import { IPaymentMethod } from './types';

const apiRoot = '/worldline/v1/mobile/payment-methods';

const getPaymentMethods = (apiClient: PoinzApiClient) => async (): Promise<IPaymentMethod[]> => {
  const response = await apiClient.get(apiRoot, {});
  return response.body;
};

const deletePaymentMethod =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<void> => {
    await apiClient.delete(`${apiRoot}/${id}`, {});
  };

export default (apiClient: PoinzApiClient) => ({
  get: getPaymentMethods(apiClient),
  delete: deletePaymentMethod(apiClient)
});
