import { ScaledImage } from 'types';
import { IEntityTypes } from './labels';
import { Deal } from 'partnerClient/backend/types';
import { IAffiliate } from 'partnerClient/explore/types';

export enum IProductTypes {
  DEAL = 'DEAL',
  GIFT_CARD = 'GIFT_CARD',
  AFFILIATE_ONLINE = 'AFFILIATE_ONLINE',
  AFFILIATE_OFFLINE = 'AFFILIATE_OFFLINE'
}

export interface ISearchParams {
  page: number;
  size?: number;
  sort?: 'asc' | 'desc';
  query?: string;
  labels?: string[];
  searchableType?: IProductTypes;
  businessProfileId?: number;
}

export interface ISearchProductBPData {
  logo: string;
  name: string;
  id: number;
  description: string;
  picuter: ScaledImage;
}

export interface ISearchProduct {
  description: string;
  businessProfileData: ISearchProductBPData;
  name: string;
  picture: ScaledImage;
  productId: number;
  productType: IEntityTypes;
  searchableEntityId: string;
  searchableTypes: IProductTypes[];
  logo: string;
  slug: string;
  cashbackPercent: number;
  dealData: Deal;
  affiliateData: IAffiliate;
  bestCashback?: number;
}
