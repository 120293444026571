import * as React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Button } from 'App/components';
import { useStyles } from './ButtonSelectionList.styles';

type Option = {
  value: string,
  label: string
};

type Props = {
  options: Option[],
  onSelect: (value?: any) => void,
  selected?: any,
  classes?: any
};

const ButtonSelectionList = ({
  options,
  onSelect,
  classes,
  selected: selectedProp,
}: Props) => {
  const styles = useStyles();
  const [selected, setSelected] = React.useState('');

  const handleSelect = (val: any) => () => {
    setSelected(val);
    onSelect(val);
  };

  React.useEffect(() => {
    setSelected(selectedProp);
  }, [selectedProp]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={clsx('ButtonSelectionList', styles.root)}
      classes={classes}
    >
      {options.map(({ label, value }) => (
        <Grid classes={{ item: styles.item }} item key={`button-selection-item-${value}`}>
          <Button
            className={clsx(styles.button, { [styles.selected]: selected === value })}
            label={{ key: 'translation.no.text', context: label }}
            variant="outlined"
            onClick={handleSelect(value)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ButtonSelectionList;
