import * as React from 'react';

import { IconButton as MuiIconButton } from '@mui/material';
import { TranslationObject } from 'serviceNew/locale';
import Tooltip from 'App/components/Tooltip';
import Icon, { IconProps } from './Icon';

type Props = {
  onClick: (arg1: React.SyntheticEvent) => unknown;
  tooltip?: TranslationObject;
  className?: string;
  disabled?: boolean;
} & IconProps;

function IconButton(props: Props) {
  const { onClick, tooltip, icon, className, disabled, ...restProps } = props;

  const handleClick = (event: React.SyntheticEvent) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const renderContent = (Comp: React.ReactElement) => {
    if (tooltip) {
      return <Tooltip label={tooltip}>{Comp}</Tooltip>;
    }
    return Comp;
  };

  return renderContent(
    <MuiIconButton
      color="inherit"
      onClick={handleClick}
      classes={{ root: className }}
      size="large"
      {...{ disabled }}
    >
      <Icon {...{ icon }} {...restProps} />
    </MuiIconButton>
  );
}

export default IconButton;
