import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Link as RRLink } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles({
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
    pointerEvents: 'auto',
  },
});

type Props = {
  to: string,
  children: React.ReactNode,
  className: string
};

function Link({
  to,
  children,
  className,
}: Props) {
  const classes = useStyles();
  return (
    <RRLink
      className={clsx(classes.link, className)}
      to={location => ({ ...location, pathname: to })}
    >
      {children}
    </RRLink>
  );
}

Link.defaultProps = {
  className: '',
};

export default Link;
