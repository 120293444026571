import React from 'react';
import { Fab } from '@mui/material';
import Icon, { IconType } from './Icon';

type FloatingActionButtonProps = {
  icon: IconType;
};

class FloatingActionButton extends React.PureComponent<FloatingActionButtonProps> {
  static defaultProps = {
    className: '',
    color: 'primary'
  };

  render() {
    const { icon, ...restProps } = this.props;
    return (
      <Fab aria-label={icon} {...restProps}>
        <Icon icon={icon} />
      </Fab>
    );
  }
}

export default FloatingActionButton;
