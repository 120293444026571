import * as React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { TranslationObject } from 'serviceNew/locale';
import { useLocale } from 'App/providers/LocaleProvider';
import { Moment } from 'moment';
import { moment } from 'serviceNew/locale/dates';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import FieldLabel from '../FieldLabel';
import { TextInput } from 'App/components';

type Props = {
  label: TranslationObject;
  noLabel?: boolean;
  required: boolean;
  disablePast: boolean;
  disabled: boolean;
  minDate?: string | Moment;
  maxDate?: string | Moment;
  defaultValue: undefined | string;
} & FieldRenderProps &
  FormContextProps;

function DatePickerField(props: Props) {
  const {
    field,
    meta,
    label,
    submitting,
    required,
    noLabel,
    disabled,
    minDate,
    maxDate,
    // eslint-disable-next-line no-unused-vars
    defaultValue,
    disablePast,
    className,
    tooltip,
    ...restProps
  } = props;

  const { error, touched } = meta;

  const { translate } = useLocale();

  function handleChange(value: Moment) {
    field.onChange(value.format('YYYY-MM-DDTHH:mm:ss'));
  }

  return (
    <div {...{ className }}>
      {!noLabel && <FieldLabel {...{ label, tooltip, required }} />}
      <MobileDatePicker
        renderInput={props => (
          <TextInput
            {...props}
            noLabel
            disabled
            style={{ width: '100%' }}
            error={!!error && touched}
            placeholder={translate(label.key, label.context)}
            label={translate(label.key, label.context)}
            helperText={touched && error && translate(error.key, error.context)}
          />
        )}
        error={!!error && touched}
        required={required}
        placeholder={translate(label.key, label.context)}
        value={field.value || null}
        disabled={submitting || disabled}
        minDate={minDate && moment(minDate)}
        maxDate={maxDate && moment(maxDate)}
        cancelLabel={translate('general.cancel')}
        okLabel={translate('general.ok')}
        inputFormat="l"
        onChange={handleChange}
        disablePast={disablePast}
        inputVariant="outlined"
        {...restProps}
      />
    </div>
  );
}

DatePickerField.defaultProps = {
  required: false
};

// @ts-expect-error - TS2345 - Argument of type 'typeof DatePickerField' is not assignable to parameter of type 'ComponentType<FormContextProps>'.
export default withFieldAdapter(withFormContext(DatePickerField));
