import _ from 'lodash';

import { MultiLanguageString } from 'serviceNew/locale';
import { keyMirror } from '@poinz/utils';
import { IdType } from '@poinz/api';
import { createTargetingRules, TargetingRules } from 'serviceNew/model/targetingRules';
import { Picture, PictureUpdate } from './common';
import { PushSubscriptionType } from '../api/pushSubscriptionType';
import {
  createMessageBlock,
  getMockMessageBlock,
  MessageBlock,
  MESSAGE_BLOCK_TYPE,
  MessageBlockUpdate
} from './messageBlock';

export const MESSAGE_STATE = keyMirror({
  ACTIVE: null,
  EXPIRED: null
});

export type MessageStateType = keyof typeof MESSAGE_STATE;

export class Message {
  id: IdType;

  expiresInDays: number;

  triggered: boolean;

  businessProfileId: number;

  targetingRules: TargetingRules | null | undefined;

  body: Array<MessageBlock> | null | undefined;

  // @ts-expect-error - TS2564 - Property 'name' has no initializer and is not definitely assigned in the constructor.
  name: MultiLanguageString;

  mainPicture: Picture;

  pushSubscriptionType: PushSubscriptionType;

  // We need this for the moment because of how EntityList works
  get picture() {
    return typeof this.mainPicture === 'string'
      ? this.mainPicture
      : _.get(this.mainPicture, 'sourceUrl');
  }

  constructor(message: any) {
    // @ts-expect-error - TS2339 - Property 'messageType' does not exist on type 'Message'.
    this.messageType = 'INFO';
    this.id = message.id;
    this.expiresInDays = message.expiresInDays;
    this.triggered = message.triggered;
    this.businessProfileId = message.businessProfileId;
    this.mainPicture = message.picture;
    this.pushSubscriptionType = message.pushSubscriptionType;
    this.targetingRules = createTargetingRules(message.targetingRules);
    if (Array.isArray(message.body)) {
      this.body = message.body.map(mb => createMessageBlock(mb));

      // We need this for the moment because of how EntityList works
      const subjectBlock = message.body.find(mb => mb.blockType === MESSAGE_BLOCK_TYPE.SUBJECT);
      if (subjectBlock) {
        this.name = subjectBlock.content;
      }
    }
  }
}

export type MessageUpdate = {
  mainPicture?: PictureUpdate;
  body?: Array<MessageBlockUpdate>;
} & Partial<
  Diff<
    Message,
    {
      mainPicture: Picture;
      body: Array<MessageBlock> | null | undefined;
    }
  >
>;

export function getMockMessage() {
  return new Message({
    id: 5,
    expiresInDays: 10,
    triggered: false,
    businessProfileId: 15,
    targetingRules: [],
    body: [
      getMockMessageBlock({ blockType: MESSAGE_BLOCK_TYPE.PICTURE }),
      getMockMessageBlock({ blockType: MESSAGE_BLOCK_TYPE.BRAND_INFO }),
      getMockMessageBlock({ blockType: MESSAGE_BLOCK_TYPE.SUBJECT }),
      getMockMessageBlock({ blockType: MESSAGE_BLOCK_TYPE.TEXT })
    ].map((mb, i) => ({ ...mb, index: i }))
  });
}
