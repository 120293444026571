import * as React from 'react';
import concat from 'lodash/concat';
import ChipInput from 'App/components/ChipInput';

import { TranslationObject } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import FieldLabel from '../FieldLabel';

type Props = {
  label: TranslationObject;
  placeholder?: TranslationObject;
  required: boolean;
  disabled: boolean;
  arrayInput?: boolean;
  addEmpty: boolean;
  className?: string | null | undefined;
  fromArray?: boolean;
} & LocaleProps &
  FieldRenderProps &
  FormContextProps;

type State = {
  chips: Array<string>;
  inputValue: string;
};

class ChipInputField extends React.Component<Props, State> {
  constructor(props: any) {
    // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 0.
    super();
    const { value } = props.input;
    let initialChips: Array<string> = [];
    if (value && value !== '') {
      if (props.fromArray) {
        initialChips = value;
      } else {
        initialChips = value.replace(/\s/g, '').split(',');
      }
    }
    this.state = {
      chips: initialChips,
      inputValue: ''
    };
  }

  static defaultProps = {
    required: false,
    arrayInput: false,
    fromArray: true
  };

  onChange = (newChips: string[] | null | undefined, inputValue: string) => {
    const { chips } = this.state;
    const { input } = this.props;
    if (Array.isArray(newChips) && newChips.length === 0) {
      input.onChange(inputValue.trim());
    } else if (newChips) {
      const newInputValue = inputValue ? concat(newChips, inputValue.trim()) : newChips;
      input.onChange(newInputValue.join(','));
    } else {
      const newInputValue = concat(chips, inputValue.trim());
      input.onChange(newInputValue.join(','));
    }
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    if (value === ',') {
      return;
    }
    if (value.split(',').length > 1) {
      this.addChip(value.split(','));
    } else {
      this.setState({ inputValue: value });
      this.onChange(null, value);
    }
  };

  deleteChip = (value: string) => {
    const { chips, inputValue } = this.state;
    const newChips = chips.filter(c => c !== value);
    this.setState({ chips: newChips });
    this.onChange(newChips, inputValue);
  };

  addChip = (value: string[] | string) => {
    const { chips } = this.state;
    const newChips = concat(chips, value);
    if (value !== '') {
      this.setState({ chips: newChips, inputValue: '' });
      this.onChange(newChips, '');
    }
  };

  render(): React.ReactElement<any> {
    const {
      input,
      meta,
      translate,
      label,
      placeholder,
      submitting,
      disabled,
      className,
      tooltip,
      required,
      name,
      ...props
    } = this.props;
    const { chips, inputValue } = this.state;
    const { error, touched } = meta;
    return (
      <div {...{ className }}>
        <FieldLabel {...{ label, tooltip, required }} />
        <ChipInput
          error={!!error && !!touched}
          fullWidth
          fullWidthInput
          value={chips}
          disabled={submitting || disabled}
          placeholder={
            placeholder
              ? translate(placeholder.key, placeholder.context)
              : translate(label.key, label.context)
          }
          newChipKeyCodes={[188]}
          onAdd={this.addChip}
          blurBehavior="ignore"
          onUpdateInput={this.handleChange}
          onDelete={this.deleteChip}
          variant="outlined"
          onBlur={input.onBlur}
          helperText={touched && !!error && translate(error.key, error.context)}
          InputProps={{ name }}
          {...{ inputValue }}
          {...props}
        />
      </div>
    );
  }
}

// @ts-expect-error - TS2345 - Argument of type 'typeof ChipInputField' is not assignable to parameter of type 'ComponentType<FormContextProps>'.
export default withFieldAdapter(withLocale(withFormContext(ChipInputField)));
