import { PoinzApiClient } from '../api';
import { ClientParams } from '../types';
import { apiRootsInternal } from '../config';

export const createInternalClient = (config: ClientParams) => {
  const { environment, ...restParams } = config;
  const client = new PoinzApiClient({
    apiRoot: apiRootsInternal[environment],
    ...restParams
  });
  return client;
};
