import { useQuery, UseQueryResult } from 'react-query';
import { PartnerClient } from '../../../partnerClient';
import { AnalyticParams, LoyaltyAnalytics } from '../../../partnerClient/loyalty/types/analytics';

const KEY = 'LOYALTY';

const useLoadAnalytics =
  (apiClient: PartnerClient) =>
  (businessProfileId: number, params: AnalyticParams): UseQueryResult<LoyaltyAnalytics> => {
    return useQuery({
      queryKey: businessProfileId ? [KEY, businessProfileId, params] : undefined,
      queryFn: () => apiClient.loyalty.analytics.get(businessProfileId, params)
    });
  };

export default (client: PartnerClient) => ({
  useLoadAnalytics: useLoadAnalytics(client)
});
