import { DiscountType } from 'serviceNew/model/adminMasterdata';
import { parseName as parseNameExternal } from '@poinz/locale';

export { parseNameExternal as parseName };

export default parseNameExternal;

export function formatPrice(price: number, showChf = false) {
  const formattedPrice = Number.isInteger(price) ? `${price}.–` : price.toFixed(2);
  return showChf ? `CHF ${formattedPrice}` : formattedPrice;
}

export const formatCashback = (cashback: number, isDecimal = false) => {
  if (!cashback) return '';
  else if (cashback && isDecimal) {
    return `${(cashback * 100).toFixed(2)}%`;
  }
  return `${cashback}%`;
};

export function formatSwissLoyaltyId(swissLoyaltyId?: string) {
  if (!swissLoyaltyId) return;
  return (
    swissLoyaltyId.slice(0, 4) + ' ' + swissLoyaltyId.slice(4, 8) + ' ' + swissLoyaltyId.slice(8)
  );
}

export function getDiscountString(discountType: DiscountType, discountValue: number) {
  switch (discountType) {
    case 'AMOUNT':
      return `- ${formatPrice(discountValue)}`;
    case 'PERCENTAGE':
      return `${discountValue}%`;
    default:
      return discountValue;
  }
}

export const luhnCheck = (value: string) => {
  let nCheck = 0;
  let bEven = false;

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
};
