import _ from 'lodash';
import { keyMirror } from '@poinz/utils';
import { EnumTranslationKeys } from 'serviceNew/locale';

import { Gender } from './common';

const REGION_TYPE = keyMirror({
  BS: null,
  ZH: null,
  BE: null,
  AG: null,
  OTHER: null,
  NONE: null
});

export type RegionDataType = keyof typeof REGION_TYPE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const REGION_TYPE_TRANSLATIONS: EnumTranslationKeys<RegionDataType> = {
  [REGION_TYPE.BS]: 'region.basel',
  [REGION_TYPE.ZH]: 'region.zurich',
  [REGION_TYPE.BE]: 'region.bern',
  [REGION_TYPE.AG]: 'region.aargau',
  [REGION_TYPE.OTHER]: 'region.other',
  [REGION_TYPE.NONE]: 'region.none'
};

export type TargetingRules = {
  isCustomTargeting: boolean;
  isExclusiveTargeting: boolean;
  userIds?: string;
  demographics: {
    gender?: Gender;
    age?: {
      from: number;
      to: number;
    };
  };
  activity: {
    locationIds?: number[];
    onlyInApp?: boolean;
    offeringExclusionIds?: number[];
    businessProfileExclusionIds?: number[];
    numberOfWeeksAgo: number;
    regions: RegionDataType[];
  };
};

type TargetingRuleRegionType = {
  targetRuleType: 'REGION';
  regions: Array<RegionDataType>;
};

type TargetingRuleLastVisitType = {
  targetRuleType: 'LAST_VISIT';
  numberOfWeeksAgo: number;
};
type TargetingRuleGenderType = {
  targetRuleType: 'GENDER';
  gender: Gender;
};
type TargetingRuleAgeType = {
  targetRuleType: 'AGE';
  from: number;
  to: number;
};
type TargetingRuleLocationType = {
  targetRuleType: 'LOCATION';
  locationIds: Array<number>;
};

type TargetingRuleInAppUserType = {
  targetRuleType: 'IN_APP_USER';
  onlyInApp: boolean;
};

type TargetingRuleOfferingExclusionType = {
  targetRuleType: 'OFFERING_EXCLUSION';
  offeringIds: Array<number>;
};

type TargetingRuleBusinessProfileExclusionType = {
  targetRuleType: 'BUSINESS_PROFILE_EXCLUSION';
  businessProfileIds: Array<number>;
};

type TargetingRuleUserIdsType = {
  targetRuleType: 'USER_IDS';
  userIds: Array<number>;
};

type TargetingRuleExcludedUserIdsType = {
  targetRuleType: 'EXCLUDED_USER_IDS';
  userIds: Array<number>;
};

export type TargetingRuleEntityType =
  | TargetingRuleAgeType
  | TargetingRuleGenderType
  | TargetingRuleLastVisitType
  | TargetingRuleLocationType
  | TargetingRuleInAppUserType
  | TargetingRuleOfferingExclusionType
  | TargetingRuleBusinessProfileExclusionType
  | TargetingRuleUserIdsType
  | TargetingRuleExcludedUserIdsType
  | TargetingRuleRegionType;

export function createTargetingRules(targetingRules: any): TargetingRules | null | undefined {
  // @ts-expect-error - TS2322 - Type 'Record<string, any> | undefined' is not assignable to type 'TargetingRules | null | undefined'.
  return Array.isArray(targetingRules)
    ? targetingRules.reduce<Record<string, any>>((acc, v: TargetingRuleEntityType) => {
        switch (v.targetRuleType) {
          case 'GENDER':
            _.set(acc, 'demographics.gender', v.gender);
            break;
          case 'AGE':
            _.set(acc, 'demographics.age.from', v.from);
            _.set(acc, 'demographics.age.to', v.to);
            break;
          case 'LOCATION':
            _.set(acc, 'activity.locationIds', v.locationIds);
            break;
          case 'IN_APP_USER':
            _.set(acc, 'activity.onlyInApp', v.onlyInApp);
            break;
          case 'OFFERING_EXCLUSION':
            _.set(acc, 'activity.offeringExclusionIds', v.offeringIds);
            break;
          case 'BUSINESS_PROFILE_EXCLUSION':
            _.set(acc, 'activity.businessProfileExclusionIds', v.businessProfileIds);
            break;
          case 'USER_IDS':
            _.set(acc, 'userIds', v.userIds.join(','));
            _.set(acc, 'isCustomTargeting', true);
            break;
          case 'EXCLUDED_USER_IDS':
            _.set(acc, 'userIds', v.userIds.join(','));
            _.set(acc, 'isExclusiveTargeting', true);
            break;
          case 'LAST_VISIT':
            _.set(acc, 'activity.numberOfWeeksAgo', v.numberOfWeeksAgo);
            break;
          case 'REGION':
            _.set(acc, 'activity.regions', v.regions);
            break;
          default:
        }
        return acc;
      }, {})
    : undefined;
}

export function getTargetingRules(origTargetingRules?: TargetingRules | null) {
  if (!origTargetingRules) {
    return undefined;
  }
  const targetingRules: Array<TargetingRules> = [];
  const { isCustomTargeting, isExclusiveTargeting, userIds, demographics, activity } =
    origTargetingRules;

  if (isCustomTargeting || isExclusiveTargeting) {
    targetingRules.push({
      targetRuleType: isCustomTargeting ? 'USER_IDS' : 'EXCLUDED_USER_IDS',
      // @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type 'never'.
      userIds: userIds ? userIds.replace(' ', '').split(',') : []
    });
    return targetingRules;
  }
  if (demographics) {
    const { age, gender } = demographics;
    if (age && (age.to || age.from)) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'AGE', ...age });
    }
    if (gender) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'string' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'GENDER', gender });
    }
  }
  if (activity) {
    const {
      locationIds,
      onlyInApp,
      offeringExclusionIds,
      businessProfileExclusionIds,
      numberOfWeeksAgo,
      regions
    } = activity;
    if (onlyInApp) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'boolean' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'IN_APP_USER', onlyInApp });
    } else if (locationIds && locationIds.length > 0) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'number[]' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'LOCATION', locationIds });
    }

    if (offeringExclusionIds && offeringExclusionIds.length > 0) {
      targetingRules.push({
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'boolean' is not assignable to type 'never'.
        targetRuleType: 'OFFERING_EXCLUSION',
        offeringIds: offeringExclusionIds
      });
    }
    if (businessProfileExclusionIds && businessProfileExclusionIds.length > 0) {
      targetingRules.push({
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'boolean' is not assignable to type 'never'.
        targetRuleType: 'BUSINESS_PROFILE_EXCLUSION',
        businessProfileIds: businessProfileExclusionIds
      });
    }
    if (numberOfWeeksAgo) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type 'number' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'LAST_VISIT', numberOfWeeksAgo });
    }

    if (regions) {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'. | TS2322 - Type '("OTHER" | "NONE" | "BS" | "ZH" | "BE" | "AG")[]' is not assignable to type 'never'.
      targetingRules.push({ targetRuleType: 'REGION', regions });
    }
  }
  return targetingRules;
}
