import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import Text from './Text';
import RawText from './RawText';
import TranslatedText from './TranslatedText';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  label: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(1.5),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(0.5)
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: 25 // needed for alignment purposes if text is empty
  },
  tooltipContainer: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[600]
  }
}));

type Props = {
  label: TranslationObject;
  tooltip?: TranslationObject | null | undefined;
  required?: boolean;
};

function FieldLabel({ label, tooltip, required }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <RawText className={classes.label} variant="subtitle2">
        <TranslatedText text={label} />
        {!!required && ' *'}
      </RawText>
      {tooltip && (
        <Tooltip title={<Text type="body2">{tooltip.key}</Text>}>
          <div className={classes.tooltipContainer}>
            <Icon icon="help_outline" size={18} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default FieldLabel;
