import * as React from 'react';
import { MobileDatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { Portal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import moment from 'moment';
import FilterButton from './FilterButton';
import { FILTER_TYPE, FilterBaseType } from './FilterProps';

const useStyles = makeStyles(() => ({
  datePicker: {
    display: 'none'
  }
}));

type DatePickerProps = Diff<
  JSX.LibraryManagedAttributes<
    typeof MobileDatePicker,
    React.ComponentProps<typeof MobileDatePicker>
  >,
  {
    onChange: any;
  }
>;
type DateTimePickerProps = Diff<
  JSX.LibraryManagedAttributes<typeof DateTimePicker, React.ComponentProps<typeof DateTimePicker>>,
  {
    onChange: any;
  }
>;
export type FilterDateType<S> = {
  filterType: typeof FILTER_TYPE.DATE;
  pickerProps?: DatePickerProps;
  isDateTime: false;
} & FilterBaseType<S>;

export type FilterDateTimeType<S> = {
  filterType: typeof FILTER_TYPE.DATE;
  pickerProps?: DateTimePickerProps;
  isDateTime: true;
} & FilterBaseType<S>;

type Props<V, S> = {
  value: V;
  filter: FilterDateType<S> | FilterDateTimeType<S>;
  filterKey: string;
  className?: string;
  containerRef: React.ElementRef<any>;
};

function DatePickerFilter<V, S>({
  value,
  filter,
  filterKey,
  className,
  containerRef
}: Props<V, S>) {
  const { label, onChange, pickerProps, isDateTime, ...restProps } = filter;
  const classes = useStyles();
  const { translate } = useLocale();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const Comp = isDateTime ? DateTimePicker : MobileDatePicker;

  return (
    <>
      <FilterButton
        label={label}
        onClick={() => setIsOpen(true)}
        className={className}
        {...restProps}
      />
      {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */}
      <Portal container={containerRef.current}>
        <Comp
          renderInput={() => <React.Fragment />}
          className={classes.datePicker}
          inputFormat="L"
          // @ts-ignore
          emptyLabel={translate('general.select')}
          value={value || new Date()}
          cancelLabel={translate('general.cancel')}
          okLabel={translate('general.ok')}
          name={filterKey}
          onChange={(e: any) => onChange(moment(e))}
          ampm={/[AaPp][Mm]/.test(moment(value as any).format('LT'))}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          {...pickerProps}
        />
      </Portal>
    </>
  );
}

export default DatePickerFilter;
