import * as React from 'react';
import { Tabs, Tab as MuiTab } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Text from 'App/components/Text';

import { TranslationObject } from 'serviceNew/locale';

const useStyles = makeStyles(theme => ({
  content: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2, 2)
  },
  tab: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(0.75, 1.5)
  }
}));

type Tab = {
  label: TranslationObject;
  value: string;
  count?: number;
  renderTab: () => React.ReactElement;
};

type Props = {
  tabs: Tab[];
};

function DashboardTabs(props: Props) {
  const { tabs } = props;

  const [activeTab, setActiveTab] = React.useState(0);
  const classes = useStyles();

  function renderTabButton(tab: Tab, index: number) {
    const { label, value } = tab;
    return (
      <MuiTab
        key={value}
        className={classes.tab}
        label={<Text context={label.context}>{label.key}</Text>}
        value={index}
      />
    );
  }

  function renderTabPanel() {
    const { renderTab } = tabs[activeTab];
    return renderTab();
  }

  return (
    <div>
      <Tabs
        variant="fullWidth"
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        {tabs.map(renderTabButton)}
      </Tabs>
      <div className={classes.content}>{renderTabPanel()}</div>
    </div>
  );
}

export default DashboardTabs;
