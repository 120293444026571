import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Location, PartnerClient } from '../../../partnerClient';

const KEY = 'LOCATION';

const useGetLocations =
  (apiClient: PartnerClient) =>
  (businessProfileId: number, includeDeleted = false): UseQueryResult<Location[], any> => {
    return useQuery(
      [KEY, businessProfileId],
      () => apiClient.backend.location.getList(businessProfileId, includeDeleted),
      { enabled: false, refetchOnWindowFocus: false }
    );
  };

const useGetLocation =
  (apiClient: PartnerClient) =>
  ({
    businessProfileId,
    locationId
  }: {
    businessProfileId: number;
    locationId: number;
  }): UseQueryResult<Location, any> => {
    return useQuery(
      [KEY, businessProfileId, locationId],
      () => apiClient.backend.location.get({ businessProfileId, locationId }),
      { enabled: false, refetchOnWindowFocus: false }
    );
  };

const useUpdateLocation =
  (apiClient: PartnerClient) =>
  (
    businessProfileId: number
  ): UseMutationResult<
    Location,
    any,
    {
      locationId: number;
      data: FormData;
    },
    Location
  > => {
    const queryClient = useQueryClient();
    return useMutation(
      ({ locationId, data }) =>
        apiClient.backend.location.update({ businessProfileId, locationId }, data),
      {
        onMutate: async ({ locationId, data }) => {
          await queryClient.cancelQueries([KEY, businessProfileId, locationId]);

          const previousLocation: Location | undefined = queryClient.getQueryData([
            KEY,
            businessProfileId,
            locationId
          ]);
          // queryClient.setQueryData<Partial<Location>>([KEY, businessProfileId, locationId], {
          //   ...previousLocation,
          //   ...data
          // });
          return previousLocation;
        },
        onError: (error, { locationId }, previousLocation) => {
          queryClient.setQueryData([KEY, businessProfileId, locationId], previousLocation);
        },
        onSettled: (data, error, { locationId }) => {
          queryClient.invalidateQueries([KEY, businessProfileId, locationId]);
          queryClient.invalidateQueries([KEY, businessProfileId]);
        }
      }
    );
  };

export default (apiClient: PartnerClient) => ({
  useGetLocations: useGetLocations(apiClient),
  useGetLocation: useGetLocation(apiClient),
  useUpdateLocation: useUpdateLocation(apiClient)
});
