import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import { ROOT } from './config';
import { PaginatedTransactionParams, Transaction } from './types';

const apiRoot = `${ROOT}/v2/business/:businessProfileId/transactions`;

const getPaginatedList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedTransactionParams): Promise<NewPaginationModel<Transaction>> => {
    const { page = 20, businessProfileId } = params;
    const result = await apiClient.get(apiRoot, {
      pathParams: { businessProfileId },
      queryParams: {
        page,
        ...params
      }
    });
    return result;
  };

const exportCsv = (apiClient: PoinzApiClient) => async (params: PaginatedTransactionParams) => {
  const { businessProfileId, ...restParams } = params;
  const header = new Headers();
  header.append('Accept', 'text/csv');
  const result = await apiClient.get(apiRoot, {
    pathParams: { businessProfileId },
    queryParams: restParams,
    headers: header,
    isBinary: true
  });
  return result.blob;
};

export default (apiClient: PoinzApiClient) => ({
  getPaginatedList: getPaginatedList(apiClient),
  exportCsv: exportCsv(apiClient)
});
