import { BusinessLocation } from 'partnerClient/backend/types/dealLocation';
import { PoinzApiClient } from '../../api';

const apiRoot = `/worldline/partners`;

const getLocations =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<BusinessLocation[]> => {
    const response = await apiClient.get(`${apiRoot}/${id}/locations`, {});
    return response.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: getLocations(apiClient)
});
