import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'ContextRouter'.
import { withRouter, ContextRouter } from 'react-router';

import { Button as MuiButton } from '@mui/material';

type Variant = 'contained' | 'text' | 'outlined' | 'fab';

type ButtonProps = {
  variant: Variant;
  type: 'submit' | 'button';
  children: React.ReactNode;
  onClick?: string | (() => void);
} & JSX.LibraryManagedAttributes<typeof MuiButton, React.ComponentProps<typeof MuiButton>> &
  ContextRouter;

class ButtonBase extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    variant: 'text',
    type: 'button'
  };

  handleClick = (event: React.SyntheticEvent<any>) => {
    const { onClick, history } = this.props;
    if (typeof onClick === 'string') {
      history.push(onClick);
    } else if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      onClick,
      // eslint-disable-next-line no-unused-vars
      match,
      // eslint-disable-next-line no-unused-vars
      history,
      // eslint-disable-next-line no-unused-vars
      location,
      ...restProps
    } = this.props;

    return <MuiButton {...restProps} onClick={this.handleClick} />;
  }
}

export default withRouter(ButtonBase);
