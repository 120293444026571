export type Label =
  | 'AccountVergessen'
  | 'Scannprobleme'
  | 'Einlösungsprobleme'
  | 'Belohnung'
  | 'BelohnungEinlöseProbleme'
  | 'EinUnternehmenVorschlagen'
  | 'Coupon'
  | 'Zahlungsprobleme'
  | 'Mailwechsel'
  | 'Feedback'
  | 'Marketingaktion'
  | 'Cashback'
  | 'Geschenkkarten'
  | 'Kreditkarte'
  | 'Shopping'
  | 'Other';

export const TICKET_LABEL = {
  FORGOT_ACCOUNT: 'AccountVergessen',
  REDEEM_DEAL: 'BelohnungEinlöseProbleme',
  DEAL: 'Belohnung',
  COUPON: 'Coupon',
  MARKETING_CAMPAIGN: 'Marketingaktion',
  SCAN: 'Scannprobleme',
  REDEEM: 'Einlösungsprobleme',
  EMAIL_CHANGE: 'Mailwechsel',
  PAYMENT: 'Zahlungsprobleme',
  SUGGEST_BUSINESS: 'EinUnternehmenVorschlagen',
  FEEDBACK: 'Feedback',
  CASHBACK: 'Cashback',
  GIFT_CARDS: 'Geschenkkarten',
  CREDIT_CARD: 'Kreditkarte',
  SHOPPING: 'Shopping',
  OTHER: 'Other'
};

export type TicketLabel = keyof typeof TICKET_LABEL;

export const TICKET_LABEL_TRANSLATIONS = {
  FORGOT_ACCOUNT: 'contact.label.forgotAccount',
  SCAN: 'contact.label.scan',
  REDEEM: 'contact.label.redeem',
  DEAL: 'contact.label.deal',
  REDEEM_DEAL: 'contact.label.redeemDeal',
  COUPON: 'contact.label.coupon',
  EMAIL_CHANGE: 'contact.label.emailChange',
  PAYMENT: 'contact.label.payment',
  FEEDBACK: 'contact.label.feedback',
  SUGGEST_BUSINESS: 'contact.label.suggestBusiness',
  OTHER: 'contact.label.other',
  MARKETING_CAMPAIGN: 'contact.label.marketingCampaign',
  CASHBACK: 'contact.label.cashback',
  GIFT_CARDS: 'contact.label.giftCards',
  CREDIT_CARD: 'contact.label.creditCard',
  SHOPPING: 'contact.label.shopping'
};

export interface TicketForm {
  email: string;
  label: string;
  description: string;
}
