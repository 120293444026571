import * as React from 'react';
import _ from 'lodash';
import ReactExport from 'react-data-export';
import ExcelExporterButton from './ExcelExporterButton';
import { ExcelFileData, ExcelSheetData } from './ExcelSheetModel';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

type GenerateExcelCallback = () => any;
type RenderPropsFn = (generateExcel: GenerateExcelCallback) => React.ReactElement;

type Props = {
  fileName?: string,
  children?: RenderPropsFn
} & ExcelFileData;

type State = {
  generateExcelCallback: GenerateExcelCallback
};

export default class DataExporter extends React.PureComponent<Props, State> {
  state = {
    generateExcelCallback() {},
  };

  static defaultProps = {
    fileName: 'poinz-excel-data',
    children: (generateExcel: GenerateExcelCallback) => (
      <ExcelExporterButton generateExcel={generateExcel} />
    ),
  };

  setExcelFileRef = (element: React.ElementRef<typeof ExcelFile>) => {
    if (element) {
      this.setState({
// @ts-expect-error - TS2571 - Object is of type 'unknown'.
        generateExcelCallback: element.handleDownload,
      });
    }
  };

  getExcelSheet = (sheet: ExcelSheetData, key: number): React.ReactElement => {
    const { data, name, columns } = sheet;
    const dataset = [{ data, columns }];
    return <ExcelSheet dataSet={dataset} name={_.escape(name)} key={key} />;
  };

  render() {
    const { fileName, children, sheets } = this.props;
    return (
      <>
        <ExcelFile filename={fileName} element={null} ref={this.setExcelFileRef}>
          {sheets.map(this.getExcelSheet)}
        </ExcelFile>
        {children && children(this.state.generateExcelCallback)}
      </>
    );
  }
}

// USAGE SAMPLES
// ==============================

/*
<DataExporter sheets={excelSheetsData} fileName="Loyality Timeline Data">
  {generateExcel => (
    <div>
      <p>I am a text not clickable</p>
      <button type="button" onClick={generateExcel}>EXTERNAL DOWNLOAD</button>
    </div>
  )}
</DataExporter>
*/

/*
<DataExporter sheets={excelSheetsData} fileName="Other Data" />
*/
