import * as React from 'react';
import { Breadcrumbs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { TranslationObject } from 'serviceNew/locale';
import Text from './Text';
import Link from './Link';
import IconButton from './IconButton';
import TabView, { Props as TabViewProps } from './TabView';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    borderBottomColor: theme.palette.grey[400],
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(4)
  },
  breadcrumbs: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(1.5)
  },
  current: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(1.5)
  },
  link: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  currentRoute: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tabBar: {
    paddingBottom: 0
  },
  pt12: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: theme.spacing(1.5)
  }
}));

export type NavigationRoute = {
  renderCurrentPath?: (arg1: any) => React.ReactElement;
  currentPathLabel?: TranslationObject | ((arg1: any) => TranslationObject);
  breadcrumbLabel?: TranslationObject | ((arg1: any) => TranslationObject);
  action?: (arg1: any) => React.ReactElement;
  disabled: boolean;
  hideBackButton: boolean;
  hideBreadCrumbs?: boolean;
  tabBar?: TabViewProps<string>;
};

export type NavigationHeaderProps = {
  tabBarProps?: TabViewProps<string>;
  onBackClick?: () => void;
  renderTitle?: TranslationObject | (() => React.ReactElement);
  renderAction?: (arg1: any) => React.ReactElement;
};

type Props = {
  navigationRoutes?: {
    [key: string]: NavigationRoute;
  };
  providerProps?: NavigationHeaderProps | null | undefined;
  className?: string;
};

function NavigationHeader({ navigationRoutes, providerProps, className }: Props) {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const classes = useStyles();

  const splittedPath = React.useMemo(() => pathname.split('/').filter(p => !!p), [pathname]);
  const pathList = React.useMemo(
    () => splittedPath.slice(0, splittedPath.length - 1),
    [splittedPath]
  );

  const getMatchForRoute = React.useCallback(
    (routeName: string) =>
      navigationRoutes &&
      Object.keys(navigationRoutes).find(route =>
        matchPath(routeName, {
          path: route,
          exact: true
        })
      ),
    [navigationRoutes]
  );

  const matchedRoute = React.useMemo(
    () => getMatchForRoute(pathname),
    [getMatchForRoute, pathname]
  );

  function getMatchedRouteParams(match: undefined | string) {
    return match ? navigationRoutes?.[match] : null;
  }

  // @ts-expect-error - TS2339 - Property 'params' does not exist on type '{} | null'.
  const { params } = React.useMemo(
    () =>
      matchedRoute
        ? matchPath(pathname, {
            path: matchedRoute,
            exact: true
          })
        : {},
    [matchedRoute, pathname]
  );

  const currentRoute = getMatchedRouteParams(matchedRoute);

  const { renderCurrentPath, currentPathLabel, action, hideBackButton, tabBar, hideBreadCrumbs } =
    currentRoute || {};

  function getLabel(label: (arg1?: any) => never): TranslationObject {
    return typeof label === 'function' ? label({ params, state: state || {} }) : label;
  }

  function renderBreadcrumb(path: any, index: any) {
    const breadcrumbPath = `/${pathList.slice(0, index + 1).join('/')}`;

    const matchedBreadcrumbRoute =
      navigationRoutes &&
      Object.keys(navigationRoutes).find(r =>
        matchPath(breadcrumbPath, {
          path: r,
          exact: true
        })
      );

    const breadcrumbRoute = matchedBreadcrumbRoute
      ? navigationRoutes?.[matchedBreadcrumbRoute]
      : null;

    const {
      currentPathLabel: breadcrumbCurrentPathLabel,
      breadcrumbLabel,
      disabled
    } = breadcrumbRoute || {};

    let label: TranslationObject = { key: `navigation.${path}` };

    if (breadcrumbLabel) {
      // @ts-expect-error - TS2345 - Argument of type 'TranslationObject | ((arg1: any) => TranslationObject)' is not assignable to parameter of type '(arg1?: any) => never'.
      const breadcrumbLabelTranslationObject = getLabel(breadcrumbLabel);
      if (breadcrumbLabelTranslationObject) {
        label = breadcrumbLabelTranslationObject;
      }
    } else if (breadcrumbCurrentPathLabel) {
      // @ts-expect-error - TS2345 - Argument of type 'TranslationObject | ((arg1: any) => TranslationObject)' is not assignable to parameter of type '(arg1?: any) => never'.
      const currentPathLabelTranslationObject = getLabel(breadcrumbCurrentPathLabel);
      if (currentPathLabelTranslationObject) {
        label = currentPathLabelTranslationObject;
      }
    }

    const to = `/${pathList.slice(0, index + 1).join('/')}`;
    if (disabled) {
      return (
        <Text key={to} context={label.context}>
          {label.key}
        </Text>
      );
    }
    return (
      <Link key={to} to={to}>
        <Text context={label.context}>{label.key}</Text>
      </Link>
    );
  }

  function renderBreadcrumbs() {
    if (pathList.length > 0) {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {pathList.map(renderBreadcrumb)}
        </Breadcrumbs>
      );
    }
    return null;
  }

  function renderCurrentRouteLeftContent() {
    if (providerProps && providerProps.renderTitle) {
      if (typeof providerProps.renderTitle === 'function') {
        return providerProps.renderTitle();
      }
      return (
        <Text variant="h5" context={providerProps.renderTitle.context}>
          {providerProps.renderTitle.key}
        </Text>
      );
    }

    if (typeof renderCurrentPath === 'function') {
      return renderCurrentPath({ params, path: pathname, state: state || {} });
    }

    if (currentPathLabel) {
      const textObject =
        typeof currentPathLabel === 'function'
          ? currentPathLabel({ params, path: pathname, state: state || {} })
          : currentPathLabel;
      if (textObject) {
        return (
          <Text variant="h5" context={textObject.context}>
            {textObject.key}
          </Text>
        );
      }
    }

    return <Text variant="h5">{`navigation.${splittedPath.slice(-1)[0]}`}</Text>;
  }

  function renderAction() {
    if (
      providerProps &&
      providerProps.renderAction &&
      typeof providerProps.renderAction === 'function'
    ) {
      return providerProps.renderAction({ params, path: pathname, state: state || {} });
    }
    if (typeof action === 'function') {
      return action({ params, path: pathname, state: state || {} });
    }
    return null;
  }

  function renderCurrentRoute() {
    return (
      <div className={classes.currentRoute}>
        {renderCurrentRouteLeftContent()}
        {renderAction()}
      </div>
    );
  }

  function renderTabBar() {
    if (providerProps && providerProps?.tabBarProps) {
      return <TabView tabBarClassName={classes.tabBar} {...providerProps.tabBarProps} />;
    }

    if (tabBar) {
      return <TabView tabBarClassName={classes.tabBar} {...tabBar} />;
    }
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {!hideBreadCrumbs && <div className={classes.breadcrumbs}>{renderBreadcrumbs()}</div>}
      <div className={clsx(classes.current, { [classes.pt12]: hideBreadCrumbs })}>
        {splittedPath.length > 1 && !hideBackButton && (
          <IconButton
            className={classes.link}
            icon="chevron_left"
            onClick={
              providerProps &&
              providerProps.onBackClick &&
              typeof providerProps?.onBackClick === 'function'
                ? providerProps.onBackClick
                : () => history.goBack()
            }
          />
        )}
        {renderCurrentRoute()}
      </div>
      {renderTabBar()}
    </div>
  );
}

export default NavigationHeader;
