import {IdType} from '@poinz/api';
import { ChipProps } from '../StatusChip';
import { TranslationObject } from 'serviceNew/locale';
import { Cashback } from 'serviceNew/model/cashback';

export type DragItem = {
  id: IdType,
  title: TranslationObject,
  chips?: ChipProps[],
  cashback?: Cashback,
  imageUrl?: string
};

export const reorder = (list: DragItem[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
