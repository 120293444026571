import React from 'react';
import { Paper, Table, TableBody, TableRow, TableCell, Collapse } from '@mui/material';
import moment from 'moment';
import { StatusChip } from 'App/components';
import { LanguageInput, NumberInput } from 'App/providers/LocaleProvider';
import { formatDate } from 'serviceNew/locale/dates';
import { MESSAGE_STATE_TYPE, MESSAGE_STATE_COLOR } from '../../../../../constants/general';
import { parseName } from '../../../../../util/StringHelper';
import MessageStatsDetails from './MessageStatsDetails';
import { MessageStatsTableBodyProps, MessageStatsTableStylesProps } from './MessageStatsTable';

type Props = MessageStatsTableBodyProps &
  MessageStatsTableStylesProps & {
    formatMessage: (arg1: LanguageInput) => string;
    formatNumber: (arg1: NumberInput) => string;
  };

class MessageStatsTableBody extends React.PureComponent<Props> {
  defineClass = (classname: string): string => {
    const { classes, selectedMessage, message } = this.props;
    return selectedMessage === message.id ? `${classname} ${classes.selectedMessage}` : classname;
  };

  getExpirationDate = () => {
    const { message } = this.props;
    const date = message.sentDate || message.scheduledDate;
    return date ? moment(date).add(message.expiresInDays, 'days') : null;
  };

  getFormattedExpirationDate = (): string => {
    const { message, formatMessage } = this.props;
    const expirationDate = this.getExpirationDate();
    return expirationDate
      ? formatDate(expirationDate.format('YYYY-MM-DD'))
      : `${message.expiresInDays} ${formatMessage({ id: 'general.days' })}`;
  };

  onClickTableRow = () => {
    const { message, handleSelectMessage } = this.props;
    handleSelectMessage({
      id: message.id,
      startDate: message.createdAt,
      endDate: this.getExpirationDate()
    });
  };

  render() {
    const {
      classes,
      selectedMessage,
      message,
      lang,
      detailLoading,
      messageDetails,
      resolution,
      formatMessage,
      formatNumber
    } = this.props;

    const isSameMessage = selectedMessage === message.id;
    const isMessageDetailsVisible = !detailLoading && isSameMessage;
    const sentDate = message.sentDate || message.scheduledDate;

    return (
      <Paper className={isSameMessage ? classes.paperSelected : ''}>
        <Table>
          <TableBody>
            <TableRow onClick={this.onClickTableRow}>
              <TableCell className={this.defineClass(classes.idCol)} align="left">
                {message.id}
              </TableCell>
              <TableCell className={this.defineClass(classes.chipCol)}>
                <StatusChip
                  label={{ key: MESSAGE_STATE_TYPE[message.messageState] }}
                  color={MESSAGE_STATE_COLOR[message.messageState]}
                />
              </TableCell>
              <TableCell className={this.defineClass(classes.firstCol)} style={{ fontWeight: 500 }}>
                {parseName(message.subject, lang)}
              </TableCell>
              <TableCell className={this.defineClass(classes.middleCol)} align="right">
                {message.totalSent}
              </TableCell>
              <TableCell className={this.defineClass(classes.middleCol)} align="right">
                {message.totalRead}
              </TableCell>
              <TableCell className={this.defineClass(classes.middleCol)} align="right">
                {message.totalCollected}
              </TableCell>
              <TableCell className={this.defineClass(classes.middleCol)} align="right">
                {/* @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'. */}
                {formatDate(sentDate)}
              </TableCell>
              <TableCell className={this.defineClass(classes.middleCol)} align="right">
                {this.getFormattedExpirationDate()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Collapse in={isMessageDetailsVisible}>
          {messageDetails && (
            <MessageStatsDetails
              messageDetails={messageDetails}
              resolution={resolution}
              formatMessage={formatMessage}
              formatNumber={formatNumber}
            />
          )}
        </Collapse>
      </Paper>
    );
  }
}

export default MessageStatsTableBody;
