import * as React from 'react';

import { IdType } from '@poinz/api';
import { Location } from 'serviceNew/model/location';
import locationApi, { createLocationFormData } from 'serviceNew/api/location';
import { useLocalStorage } from '@poinz/hooks';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import apiNew from 'serviceNew/api/sharedApiClient';

type LocationContextType = {
  locationId: IdType | null | undefined;
  selectedLocation: Location | null | undefined;
  locations: Location[];
  selectLocation: (id: IdType) => void;
  createLocation: (values: Partial<Location>) => Promise<void>;
  updateLocation: (id: IdType, values: Partial<Location>) => Promise<void>;
  removeLocation: (id: IdType) => Promise<void>;
  getLocation: (id: IdType) => Promise<Location>;
  isLoading: boolean;
};

const LocationContext = React.createContext<LocationContextType>({
  locationId: null,
  selectedLocation: undefined,
  locations: [],
  selectLocation: () => {},
  createLocation: async () => {},
  updateLocation: async () => {},
  removeLocation: async () => {},

  // @ts-expect-error - TS2322 - Type '() => Promise<void>' is not assignable to type '(id: number) => Promise<Location>'.
  getLocation: async () => {},
  isLoading: false
});

export type LocationProps = {
  locationContext: LocationContextType;
};

type Props = {
  children: React.ReactNode;
};

const init: IdType | null | undefined = undefined;

function LocationProvider(props: Props) {
  const { children } = props;
  const { businessProfileId } = useBusinessProfile();
  const [selectedLocation, setSelectedLocation] = React.useState<Location | null | undefined>(
    undefined
  );
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [locationId, setLocationId] = useLocalStorage<IdType | null | undefined>({
    key: 'locationId',
    initialValue: init
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(businessProfileId != null);

  React.useEffect(() => {
    if (!selectedLocation || locationId !== selectedLocation.id) {
      const newSelectedLocation = locations.find(l => l.id === locationId);
      setSelectedLocation(newSelectedLocation);
      if (!isLoading && newSelectedLocation == null) {
        setLocationId(undefined);
      }
    }
  }, [locations, locationId, isLoading, setLocationId, selectedLocation]);

  const selectLocation = React.useCallback(
    (id?: IdType | null) => {
      setLocationId(id);
    },
    [setLocationId]
  );

  const createLocation = React.useCallback(
    async (values: Partial<Location>) => {
      if (businessProfileId != null) {
        const newLocationId = await locationApi.create({ businessProfileId, values });
        const newLocation = await apiNew.backend.location.get({
          businessProfileId,
          locationId: newLocationId
        });
        setLocations(currLocations => [...currLocations, newLocation]);
      }
    },
    [businessProfileId]
  );

  const updateLocation = React.useCallback(
    async (id: IdType, values: Partial<Location>) => {
      if (businessProfileId != null) {
        const updatedLocation = await apiNew.backend.location.update(
          {
            businessProfileId,
            locationId: id
          },
          // @ts-expect-error - TS2345 - Argument of type 'Partial<Location>' is not assignable to parameter of type 'Location'.
          createLocationFormData(values)
        );
        setLocations(currLocations => [...currLocations.filter(l => l.id !== id), updatedLocation]);
        setSelectedLocation(currSelected =>
          currSelected && currSelected.id === id ? updatedLocation : currSelected
        );
      }
    },
    [businessProfileId]
  );

  const removeLocation = React.useCallback(
    async (id: IdType) => {
      if (businessProfileId != null) {
        await locationApi.remove({
          businessProfileId,
          locationId: id
        });
        setLocations(currLocations => currLocations.filter(l => l.id !== id));
      }
    },
    [businessProfileId]
  );

  const getLocation = React.useCallback(
    async (id: IdType) => {
      if (businessProfileId != null) {
        return apiNew.backend.location.get({
          businessProfileId,
          locationId: id
        });
      }
      throw new Error('Cannot retrieve location without business profile id.');
    },
    [businessProfileId]
  );

  React.useEffect(() => {
    async function getLocations(id: IdType) {
      setIsLoading(true);
      const retrievedLocations = await apiNew.backend.location.getList(id);
      setLocations(retrievedLocations);
      setIsLoading(false);
    }
    if (businessProfileId != null) {
      getLocations(businessProfileId);
    } else {
      setLocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProfileId]);

  React.useEffect(() => {
    if (!isLoading && locationId == null && locations.length === 1) {
      selectLocation(locations[0].id);
    }
    if (
      !isLoading &&
      locationId != null &&
      locations &&
      !locations.find(l => l.id === locationId)
    ) {
      selectLocation(undefined);
    }
  }, [locationId, isLoading, selectedLocation, locations, selectLocation]);

  const contextValue = React.useMemo(
    () => ({
      locationId,
      selectedLocation,
      locations,
      createLocation,
      isLoading,
      removeLocation,
      selectLocation,
      updateLocation,
      getLocation
    }),
    [
      locationId,
      selectedLocation,
      locations,
      createLocation,
      isLoading,
      removeLocation,
      selectLocation,
      updateLocation,
      getLocation
    ]
  );

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
}

export function useLocation() {
  return React.useContext(LocationContext);
}

export function withLocation<P extends LocationProps, C extends React.ComponentType<P>>(
  Comp: C
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>, LocationProps>
> {
  return (props: Record<any, any>) => (
    <LocationContext.Consumer>
      {(context: LocationContextType) => <Comp {...(props as any)} locationContext={context} />}
    </LocationContext.Consumer>
  );
}

export default LocationProvider;
