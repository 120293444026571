import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;

function useBehaviorSubject<T>(
  subject: BehaviorSubject<T>
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState<T>(subject.getValue());

  React.useEffect(() => {
    const subscription = subject.pipe(skip(1)).subscribe(setState);
    return () => subscription.unsubscribe();
  }, [subject]);

  const setNewValue = React.useCallback(
    (newValue: React.SetStateAction<T>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subject.next(typeof newValue === 'function' ? newValue(subject.getValue()) : newValue);
    },
    [subject]
  );

  return [state, setNewValue];
}

export default useBehaviorSubject;
