import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(2)
  },
  chip: {
    marginLeft: theme.spacing(1)
  },
  image: {
    marginLeft: '0.5rem',
    maxWidth: '2.5rem'
  },
  title: {}
}));
