import * as React from 'react';
import clsx from 'clsx';
import { useStyles } from './DraggableSort.styles';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { DragItem, reorder } from './DraggableSort.utils';
import DraggableItem from './DraggableItem';
import { Cashback } from 'serviceNew/model/cashback';

type Props = {
  onDragEnd: (items: DragItem[]) => any,
  initialItems: DragItem[],
  lastElementRef: React.Ref<any>,
  disabled?: boolean,
  handleSelect?: (cashback: null | Cashback) => void
};

const DraggableSort = ({
  onDragEnd,
  initialItems,
  lastElementRef,
  disabled = false,
  handleSelect,
}: Props) => {
  const classes = useStyles();
  const [items, setItems] = React.useState<DragItem[]>(initialItems);
  React.useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);

    if (typeof onDragEnd === 'function') {
      onDragEnd(newItems);
    }
    setItems(newItems);
  };

  return (
    <div className="DraggableSort">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.list}>
              {items.map((item: DragItem, index: number) => (
                <Draggable
                  isDragDisabled={disabled}
                  key={`draggable-item-${item.id}`}
                  draggableId={item.id}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.item}
                      style={provided.draggableProps.style}
                    >
                      {items.length === index + 1 && lastElementRef ? (
                        <div ref={lastElementRef}>
                          <DraggableItem
                            className={clsx({ [classes.disabled]: disabled })}
                            data={item}
                            onItemSelect={handleSelect}
                          />
                        </div>
                      ) : (
                        <DraggableItem
                          className={clsx({ [classes.disabled]: disabled })}
                          data={item}
                          onItemSelect={handleSelect}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableSort;
