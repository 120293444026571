import * as React from 'react';
import MuiAlert from '@mui/lab/Alert';
import { keyMirror } from '@poinz/utils';
import { TranslationObject } from 'serviceNew/locale';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Text from './Text';

export const ALERT_TYPE = keyMirror({
  ERROR: null,
  INFO: null,
  SUCCESS: null,
  WARNING: null
});

const useStyles = makeStyles(theme => ({
  alert: {
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius * 2
  }
}));

type Props = {
  severity: keyof typeof ALERT_TYPE;
  label: TranslationObject;
} & Diff<
  JSX.LibraryManagedAttributes<typeof MuiAlert, React.ComponentProps<typeof MuiAlert>>,
  {
    severity?: string;
  }
>;

function Alert(props: Props) {
  const { severity, label, className, ...restProps } = props;
  const classes = useStyles();
  return (
    <MuiAlert
      severity={severity.toLowerCase() as any}
      color={severity.toLowerCase() as any}
      className={clsx(classes.alert, className || '')}
      {...restProps}
    >
      <Text variant="body2" context={label.context}>
        {label.key}
      </Text>
    </MuiAlert>
  );
}

export default Alert;
