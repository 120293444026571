import * as React from 'react';
import { CircularProgress, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles'
import TranslatedText from './TranslatedText';

const useStyles = makeStyles(theme => ({
  progress: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(3),
  },
}));

type Props = {
  open: boolean
};

function FormSubmissionDialog({
  open,
}: Props) {
  const classes = useStyles();
  return (
    <Dialog {...{ open }}>
      <DialogTitle>
        <TranslatedText text={{ key: 'form.pleaseWait' }} />
      </DialogTitle>
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    </Dialog>
  );
}

export default React.memo<Props>(FormSubmissionDialog);
