import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { FALLBACK_LANGUAGE, LANGUAGE } from './translations';
import translations from './translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: Object.keys(LANGUAGE),
    debug: false,
    resources: {
      ...translations
    },
    keySeparator: false,
    nsSeparator: false
  });

export default i18n;
