import { keyMirror } from '@poinz/utils';

const API_KEY_PERMISSION = keyMirror({
  LOYALTY_ADD_CASH: null,
  LOYALTY_READ_CASH: null
});

export type ApiKeyPermission = keyof typeof API_KEY_PERMISSION;

export interface ApiKey {
  id: number;
  userId: number;
  businessProfileId: number;
  name: string;
  keyHash: string;
  createdAt: string;
  revokedAt: string;
}

export interface CreateApiKeyBody {
  name: string;
  businessProfileId: number;
  permissions: ApiKeyPermission[];
}
