import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  buttonOpen: {
    background: `rgba(255, 255, 255, 0.1)`
  },
  button: {
    textDecoration: 'none',
    color: 'white'
  },
  box: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1, 0, 1)
  },
  menuItem: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1, 2.5, 1, 2.5),
    boxSizing: 'border-box'
  }
}));
