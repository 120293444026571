// @ts-expect-error - TS2724 - '"@poinz/api"' has no exported member named 'NewPaginatedResult'. Did you mean 'getPaginatedResult'?
import { NewPaginatedResult, PagableParams } from '@poinz/api';
import { createMultipartRequest, getIdFromHeaders } from '@poinz/api';
import { IdType } from '@poinz/api';
import { Message, MessageUpdate, MessageStateType } from 'serviceNew/model/message';
import { getTargetingRules, TargetingRules } from 'serviceNew/model/targetingRules';
import {
  MessageBlockUpdate,
  MESSAGE_BLOCK_TYPE,
  OfferingPromotion,
  OfferingPromotionBlock
} from 'serviceNew/model/messageBlock';
import { apiClient, callEndpoint } from './apiClient';
import { BUSINESS_PROFILES } from './businessProfile';
import { mapPicture } from '../picture';

export const MESSAGES = 'messages';

const getRoot = (id: IdType) => `/${BUSINESS_PROFILES}/${id}/${MESSAGES}`;

function createRequest(messageForm: MessageUpdate) {
  const pictureData: Array<File | any> = [];
  const { mainPicture, ...messageFormTransformed } = messageForm;
  if (messageForm.body) {
    messageFormTransformed.body = messageForm.body.map((b: MessageBlockUpdate) => {
      if (b.blockType === MESSAGE_BLOCK_TYPE.PICTURE) {
        return {
          ...b,
          imageList:
            b.imageList &&
            b.imageList
              .filter(i => (i.imageInfo as any).file != null)
              .map(i => {
                pictureData.push((i.imageInfo as any).file);
                return { imageInfo: mapPicture(i.imageInfo as any), url: i.url };
              })
        };
      }
      if ((b as any).getFormValue) {
        return (b as any).getFormValue();
      }
      if (b.blockType === MESSAGE_BLOCK_TYPE.OFFERING) {
        return new OfferingPromotionBlock({
          id: b.id,
          index: b.index,
          blockType: b.blockType,
          offeringPromotions: b.offeringPromotions.map((entity: OfferingPromotion) => ({
            codeId: entity.codeId != null ? entity.codeId : entity.entityId,
            description: entity.description
          }))
        });
      }
      return b;
    });
  }
  const multiPartData: Array<
    | any
    | {
        data: Array<File | any>;
        name: string;
      }
    | {
        data: File | any;
        name: string;
      }
  > = [];
  if (mainPicture && (mainPicture as any).file) {
    (messageFormTransformed as any).mainPicture = mapPicture(mainPicture as any);
    multiPartData.push({ name: 'mainPicture', data: (mainPicture as any).file });
  }
  if (pictureData.length > 0) {
    multiPartData.push({ name: 'pictures', data: pictureData });
  }
  return createMultipartRequest('messageForm', messageFormTransformed, multiPartData);
}

type MessageQueryParams = PagableParams & {
  businessProfileId: IdType;
  state: MessageStateType;
};

async function getMessages(params: MessageQueryParams): Promise<NewPaginatedResult<Message>> {
  const { businessProfileId, page, ...restParams } = params;
  // @ts-expect-error - TS2345 - Argument of type 'string | number | undefined' is not assignable to parameter of type 'number | undefined'.
  const result = await apiClient.callPaginatedEndpoint(page, getRoot(businessProfileId), {
    queryParams: { page, ...restParams }
  });
  return { ...result, content: result.content.map(m => new Message(m)) };
}

async function getMessageById(params: {
  messageId: IdType;
  businessProfileId: IdType;
}): Promise<Message> {
  const { messageId, businessProfileId } = params;
  const result = await callEndpoint(`${getRoot(businessProfileId)}/${messageId}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return new Message(result.body);
}

async function createMessage(params: {
  businessProfileId: IdType;
  values: MessageUpdate;
}): Promise<IdType> {
  const { businessProfileId, values } = params;
  const formData = createRequest(values);
  const result = await callEndpoint(getRoot(businessProfileId), 'POST', {
    headers: new Headers(),
    authenticated: true,
    body: formData,
    isMultiPart: true
  });
  return getIdFromHeaders(result.headers);
}

async function updateMessage(params: {
  businessProfileId: IdType;
  messageId: IdType;
  values: MessageUpdate;
}): Promise<Message> {
  const { businessProfileId, messageId, values } = params;
  const formData = createRequest(values);
  const result = await callEndpoint(`${getRoot(businessProfileId)}/${messageId}`, 'PUT', {
    headers: new Headers(),
    authenticated: true,
    body: formData,
    isMultiPart: true
  });
  return new Message(result.body);
}

async function addTargetingRules(params: {
  businessProfileId: IdType;
  messageId: IdType;
  values: Partial<TargetingRules>;
}): Promise<Message> {
  const { businessProfileId, messageId, values } = params;
  const result = await callEndpoint(
    `${getRoot(businessProfileId)}/${messageId}/target-rules`,
    'PUT',
    {
      headers: new Headers(),
      authenticated: true,
      body: {
        // @ts-expect-error - TS2345 - Argument of type 'Partial<TargetingRules>' is not assignable to parameter of type 'TargetingRules'.
        targetingRules: getTargetingRules(values)
      }
    }
  );
  return new Message(result.body);
}

async function deleteMessage(params: {
  businessProfileId: IdType;
  messageId: IdType;
}): Promise<void> {
  const { businessProfileId, messageId } = params;
  await callEndpoint(`${getRoot(businessProfileId)}/${messageId}`, 'DELETE', {
    headers: new Headers(),
    authenticated: true
  });
}

export default {
  getMessages,
  getMessageById,
  createMessage,
  updateMessage,
  deleteMessage,
  addTargetingRules
};
