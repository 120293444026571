import * as React from 'react';
import { readImage } from 'serviceNew/model/common';

import ImageCropDialog, { Crop } from './ImageCropDialog';

const DEFAULT_ASPECT_RATIO = 1.875;

const getAbsoluteCrop = (crop: Crop, imageWidth: number, imageHeight: number) => {
  const { x, y, width, height } = crop;
  const aspect = crop.aspect || 1;
  const imageAspect = imageWidth / imageHeight;
  if (x != null && y != null && height != null) {
    return {
      x: Math.floor((x * imageWidth) / 100),
      y: Math.floor((y * imageHeight) / 100),
      width: Math.floor((width * imageWidth) / 100),
      height: Math.floor((height * imageHeight) / 100),
      imageWidth,
      imageHeight
    };
  }
  return {
    x: Math.floor(imageAspect > aspect ? (imageWidth - aspect * imageHeight) / 2 : 0),
    y: Math.floor(imageAspect > aspect ? 0 : (imageHeight - imageWidth / aspect) / 2),
    width: Math.floor(imageAspect > aspect ? aspect * imageHeight : imageWidth),
    height: Math.floor(imageAspect > aspect ? imageHeight : imageWidth / aspect),
    imageWidth,
    imageHeight
  };
};

async function getCroppedImage(
  imageUrl: string,
  crop: Crop
): Promise<{
  imageBlob: Blob;
  crop: Crop;
}> {
  const image = await readImage(imageUrl);

  const canvas = document.createElement('canvas');

  const absoluteCrop = getAbsoluteCrop(crop, image.width, image.height);

  canvas.width = absoluteCrop.width;
  canvas.height = absoluteCrop.height;
  const ctx = canvas.getContext('2d');

  // @ts-expect-error - TS2531 - Object is possibly 'null'.
  ctx.drawImage(
    image,
    absoluteCrop.x,
    absoluteCrop.y,
    absoluteCrop.width,
    absoluteCrop.height,
    0,
    0,
    absoluteCrop.width,
    absoluteCrop.height
  );

  return new Promise(resolve => {
    canvas.toBlob(
      // @ts-expect-error - TS2345 - Argument of type '(imageBlob: Blob) => void' is not assignable to parameter of type 'BlobCallback'.
      (imageBlob: Blob) => {
        resolve({ imageBlob, crop: absoluteCrop });
      },
      'image/jpeg',
      1
    );
  });
}

type Props = {
  value: any;
  onChange: (value?: any) => void;
  children: (arg1: { startCropping: () => void; imageUrl: string }) => React.ReactElement;
  aspectRatio?: number;
};

function CroppableImage(props: Props) {
  const { value, onChange, children, aspectRatio = DEFAULT_ASPECT_RATIO } = props;

  const { x, y, width: cropWidth, height: cropHeight, imageWidth, imageHeight } = value || {};

  const [crop, setCrop] = React.useState<Crop>({
    aspect: DEFAULT_ASPECT_RATIO,
    width: 100,
    // @ts-expect-error - TS2345 - Argument of type '{ aspect: number; width: number; unit: string; }' is not assignable to parameter of type 'Crop | (() => Crop)'.
    unit: '%'
  });

  const initialCrop = React.useMemo(
    () =>
      x != null
        ? {
            x: (100 * x) / imageWidth,
            y: (100 * y) / imageHeight,
            width: (100 * cropWidth) / imageWidth,
            height: (100 * cropHeight) / imageHeight,
            unit: '%'
          }
        : undefined,
    [cropHeight, cropWidth, imageHeight, imageWidth, x, y]
  );

  React.useEffect(() => {
    if (initialCrop) {
      setCrop(c => ({ ...c, aspect: aspectRatio, ...initialCrop }));
    }
  }, [aspectRatio, initialCrop]);

  const [isCropping, setIsCropping] = React.useState<boolean>(false);
  const [isCalcCropping, setIsCalcCropping] = React.useState<boolean>(false);
  // TODO: Ok to hardcode aspect for now, as we are going to remove it soon

  const imageObject = React.useMemo(() => value || {}, [value]);
  const { sourceUrl, file, croppedPreview, preview } = imageObject;

  const nImageUrl = sourceUrl ?? preview;

  const isNewFile = file != null;

  const handleCropChange = React.useCallback(
    async (newCrop: Crop) => {
      if (nImageUrl) {
        setIsCalcCropping(true);
        const { imageBlob, crop: absoluteCrop } = await getCroppedImage(nImageUrl, newCrop);
        const newCroppedPreview = URL.createObjectURL(imageBlob);
        onChange({ ...imageObject, ...absoluteCrop, croppedPreview: newCroppedPreview });
        setIsCalcCropping(false);
      }
    },
    [imageObject, nImageUrl, onChange]
  );

  React.useEffect(() => {
    if (preview && !croppedPreview && !isCalcCropping) {
      // @ts-expect-error - TS2345 - Argument of type '{ aspect: number; unit: string; width: number; }' is not assignable to parameter of type 'Crop'.
      handleCropChange({ aspect: aspectRatio, unit: '%', width: 100 });
    }
  }, [aspectRatio, croppedPreview, handleCropChange, isCalcCropping, preview]);

  React.useEffect(() => {
    if (isNewFile && !preview) {
      const newImageUrl = URL.createObjectURL(file);
      onChange({ ...imageObject, preview: newImageUrl });
    }
    return undefined;
  }, [file, imageObject, isNewFile, onChange, preview]);

  React.useEffect(
    () => () => {
      URL.revokeObjectURL(preview);
    },
    [preview]
  );

  React.useEffect(
    () => () => {
      URL.revokeObjectURL(croppedPreview);
    },
    [croppedPreview]
  );

  return (
    <>
      {children({
        imageUrl: isNewFile ? croppedPreview : sourceUrl,
        startCropping: () => setIsCropping(true)
      })}
      {nImageUrl && (
        <ImageCropDialog
          isOpen={isCropping}
          onClose={() => setIsCropping(false)}
          onCropChange={handleCropChange}
          image={nImageUrl}
          {...{ crop }}
        />
      )}
    </>
  );
}

export default CroppableImage;
