import * as React from 'react';
import { keyMirror } from 'util/ObjectHelper';
import { TranslationObject } from 'serviceNew/locale';
import Button from '../Button';

export type Dispatch<A, S> = (value: A) => S;

export const FILTER_TYPE = keyMirror({
  DATE: null,
  SELECT: null,
  AUTOCOMPLETE: null,
  INPUT: null
});

export type FilterBaseType<S> = {
  onChange: (value: any) => void;
  onDelete: (value: any) => void;
  getChipLabel: (value: any, allValues: S) => string | number;
  label: TranslationObject;
  key: string;
} & Diff<
  JSX.LibraryManagedAttributes<typeof Button, React.ComponentProps<typeof Button>>,
  {
    onClick?: any;
  }
>;
