import React from 'react';
import { Paper, TranslatedText } from 'App/components';
import { Typography, Button } from '@mui/material';

const UpgradePageUnconnected = () => (
// @ts-expect-error - TS2322 - Type '{ children: Element[]; style: { textAlign: string; alignItems: string; }; }' is not assignable to type 'IntrinsicAttributes & Omit<{ className: string; children: ReactNode; title?: TranslationObject | null | undefined; noPadding: boolean; } & { ...; } & RefAttributes<...>, "classes"> & StyledComponentProps<...>'.
  <Paper style={{ textAlign: 'center', alignItems: 'center' }}>
    <Typography variant="h5">
      <TranslatedText text={{ key: 'upgrade.header' }} />
    </Typography>
    <Typography style={{ marginTop: 12 }}>
      <TranslatedText text={{ key: 'upgrade.body' }} />
    </Typography>
    <Button
      variant="contained"
      color="primary"
      href="mailto:info@poinz.ch"
      target="blank"
      style={{ marginTop: 12 }}
    >
      <TranslatedText text={{ key: 'upgrade.button.contact' }} />
    </Button>
    <Button color="primary" href="http://www.poinz.ch" target="blank" style={{ marginTop: 12 }}>
      <TranslatedText text={{ key: 'upgrade.button.information' }} />
    </Button>
  </Paper>
);

export default UpgradePageUnconnected;
