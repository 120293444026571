import * as React from 'react';

import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import { FormLabel } from 'App/components';

const useStyles = makeStyles(theme => ({
  container: {
// @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    marginTop: theme.extra.spacing.small,
// @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    marginBottom: theme.extra.spacing.small,
  },
}));

type Props = {
  label: TranslationObject
};

function FormSectionHeader(props: Props) {
  const { label } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FormLabel {...{ label }} />
    </div>
  );
}

export default FormSectionHeader;
