import _ from 'lodash';
import { readImage } from 'serviceNew/model/common';
import { formatDateTime, moment } from 'serviceNew/locale/dates';
import { Languages, TranslationObject } from 'serviceNew/locale';
import { luhnCheck } from 'util/StringHelper';

export const STRING_LIMIT = {
  TITLE: 255,
  BODY: 1500
} as const;

export const compose =
  (
    ...validators: Array<(value: any, allValues: any) => TranslationObject | null | undefined>
  ): any =>
  (value: any | null | undefined, allValues: any) =>
    // @ts-expect-error - TS2769 - No overload matches this call.
    validators.reduce((error, validator) => error || validator(value, allValues), undefined);

export function required(value: any) {
  return value != null && value !== '' ? undefined : { key: 'form.errors.required' };
}

export function requiredIf(fieldName: string) {
  return (value: any, allValues: any) => {
    const requiredValue = _.get(allValues, fieldName);
    if (requiredValue != null && requiredValue.length !== 0) {
      return required(value);
    }
    return undefined;
  };
}

export function requredIfValueEqualTo(fieldName: string, fieldValue: any) {
  return (value: any, allValues: any) => {
    const _value = _.get(allValues, fieldName);
    if (_value === fieldValue && !value) {
      return required(value);
    }
    return undefined;
  };
}

export function isAtLeast(minimum: number) {
  return (value?: number | null) =>
    typeof value !== 'number' || value >= minimum
      ? undefined
      : { key: 'form.errors.numbers.isAtLeast', context: { value: minimum } };
}

export function isAtMost(maximum: number) {
  return (value?: number | null) =>
    typeof value !== 'number' || value <= maximum
      ? undefined
      : { key: 'form.errors.numbers.isAtMost', context: { value: maximum } };
}

export function isAtLeastField(fieldName: string) {
  return (value: number | null | undefined, allValues: any) => {
    const minimum = _.get(allValues, fieldName);
    if (minimum != null) {
      return isAtLeast(minimum)(value);
    }
    return undefined;
  };
}

export function lengthAtLeast(min: number) {
  return (value?: string | null) => {
    if (value && value.toString().length < min) {
      return { key: 'form.errors.string.tooShort', context: { count: min } };
    }
    return undefined;
  };
}

export function lengthAtMost(max: number) {
  return (value?: string | null) => {
    if (value && value.toString().length > max) {
      return { key: 'form.errors.string.tooLong', context: { count: max } };
    }
    return undefined;
  };
}

export function lengthBetween(min: number, max: number) {
  return compose(lengthAtLeast(min), lengthAtMost(max));
}

export function lengthEquals(length: number) {
  return (value?: string | null) => {
    if (value != null) {
      if (`${value}`.length !== length) {
        return {
          key: 'form.errors.string.wrongLength',
          context: { count: length }
        };
      }
    }
    return undefined;
  };
}

export function lengthEitherOr(min: number, max: number) {
  return (value?: string | null) => {
    if (value && value.toString().length !== min && value.toString().length !== max) {
      return { key: 'form.errors.string.wrongLength', context: { count: `${min} or ${max}` } };
    }
    return undefined;
  };
}

export function isAtMostField(fieldName: string) {
  return (value: number | null | undefined, allValues: any) => {
    const maximum = _.get(allValues, fieldName);
    if (maximum != null) {
      return isAtMost(maximum)(value);
    }
    return undefined;
  };
}

export function isInteger(value?: any | null) {
  if (value != null && !Number.isInteger(Number(value))) {
    return { key: 'form.errors.numbers.isInteger' };
  }
  return undefined;
}

export function isBiggerThan(minimum: number) {
  return (value: number) =>
    typeof value !== 'number' || value > minimum
      ? undefined
      : { key: 'form.errors.numbers.isBiggerThan', context: { value: minimum } };
}

export function isLessThan(maximum: number) {
  return (value: number) =>
    typeof value !== 'number' || value < maximum
      ? undefined
      : { key: 'form.errors.numbers.isLessThan', context: { value: maximum } };
}

export function numberHasUpToDecimals(maxDecimals: number) {
  return (value: number) =>
    typeof value !== 'number' || value === parseFloat(value.toFixed(maxDecimals))
      ? undefined
      : { key: 'form.errors.numbers.numberHasUpToDecimals', context: { value: maxDecimals } };
}

export function isNotEmptyArray(value?: any[] | null) {
  if (!value || value.length < 1) {
    return { key: 'form.errors.array.isNotEmpty' };
  }
  return undefined;
}

export const afterDate =
  (fieldName: string) => (value: string | null | undefined, allValues: any) => {
    const startDate = _.get(allValues, fieldName);
    if (startDate && value) {
      return moment(startDate) >= moment(value)
        ? {
            key: 'form.errors.date',
            context: { time: 'general.after', value: formatDateTime(startDate) }
          }
        : undefined;
    }
    return undefined;
  };

export const afterOrEqualsDate =
  (fieldName: string) => (value: string | null | undefined, allValues: any) => {
    const startDate = _.get(allValues, fieldName);
    if (startDate && value) {
      return moment(startDate) > moment(value)
        ? {
            key: 'form.errors.date',
            context: { time: 'general.after', value: formatDateTime(startDate) }
          }
        : undefined;
    }
    return undefined;
  };

export const isDigit = (numberOfDigits?: number | null) => (value: string) => {
  if (value == null) {
    return undefined;
  }
  const regExp = numberOfDigits != null ? new RegExp(`^\\d{${numberOfDigits}}$`) : /\d+/;
  return regExp.test(value)
    ? undefined
    : { key: 'form.errors.redemption.token', context: { value: numberOfDigits } };
};

export const isValidEmail = (email?: string | null) => {
  if (email != null) {
    const isValid =
      /^[\w0-9.!#$%&'*+/=?^_`{|}-]+@((\[[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}\.[0-9]{1,3}])|(([\w\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        email
      );
    if (!isValid) {
      return { key: 'form.errors.invalidEmail' };
    }
  }
  return undefined;
};

export function isValidPhone(phone?: string | null) {
  if (phone != null) {
    const isValid = /^((\+|0)\d{1,3}\s)?\d{1,4}\s\d{1,5}(\s\d{2,})?(\s\d+)?$/.test(phone);
    if (!isValid) {
      return { key: 'form.errors.invalidPhone' };
    }
  }
  return undefined;
}

export const isValidUrl = (url?: string | null) => {
  if (url != null && url.length > 0) {
    const isValid =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?/g.test(
        url
      );
    if (!isValid) {
      return { key: 'form.errors.invalidUrl' };
    }
  }
  return undefined;
};

export const validSwissLoyaltyNumber = (value: string | number) => {
  if (!value) {
    return false;
  }

  const slNumber = value.toString().replace(/\s/g, '');

  if (!/^[A-Z]{2}[0-9]{10}$/.test(slNumber)) {
    return false;
  }

  if (!luhnCheck(slNumber.slice(2, slNumber.length))) {
    return false;
  }
  return true;
};

export const isValidMobileDeepLink = (url?: string | null) => {
  if (url != null) {
    const isValid = /^poinzapp(-test)?(-staging)?(-dev)?:\/\/poinz/.test(url);
    if (!isValid) {
      return { key: 'form.errors.invalidUrl' };
    }
  }
  return undefined;
};

export function isValidUrlOrMobileLink(url?: string | null) {
  const isValid = isValidUrl(url) == null || isValidMobileDeepLink(url) == null;
  if (!isValid) {
    return { key: 'form.errors.invalidUrl' };
  }
  return undefined;
}

export const isValidCommaSeparatedIds = (idList?: string | null) => {
  if (idList != null && !/^\s*\d+\s*(,\s*\d+\s*)*$/.test(idList)) {
    return { key: 'message.rules.form.error.userIds' };
  }
  return undefined;
};

export function isValidCommaSeparatedString(
  validator: (value?: string | null | undefined) => TranslationObject | null | undefined
) {
  return (commaSeparatedString?: string | null): any => {
    if (commaSeparatedString != null && commaSeparatedString.length > 0) {
      return (
        commaSeparatedString
          .split(',')
          // @ts-expect-error - TS2769 - No overload matches this call.
          .reduce((error, value) => error || validator(value.trim()), undefined)
      );
    }
    return undefined;
  };
}

export const isSameAs = (value: string | null | undefined, allValues: any) => {
  if (_.get(allValues, 'password') !== value) {
    return { key: 'form.errors.unmatchedPasswordConfirmation' };
  }
  return undefined;
};

export const oneRequired = (value?: Record<any, any> | null) => {
  if (!value || _.isEmpty(value) || Object.values(value).every(v => !v && v !== 0)) {
    return { key: 'form.errors.atLeastOneLangRequired' };
  }
  return undefined;
};

export const allRequired = (value: any) => {
  const neededKeys = Object.keys(Languages);
  if (
    !value ||
    _.isEmpty(value) ||
    Object.values(value).every(v => !v && v !== 0) ||
    !neededKeys.every(key => Object.keys(value).includes(key))
  ) {
    return { key: 'form.errors.allLangRequired' };
  }
  return undefined;
};

export const alphaNumeric = (value?: string | null) => {
  if (value != null) {
    const isValid = /^[A-Za-z0-9]+$/.test(value);
    if (!isValid) {
      return { key: 'form.errors.alphaNumeric' };
    }
  }
};

export const hasValidDimensions =
  (validDimensions: { width: number; height: number }) =>
  async (
    value?: {
      preview: string;
    } | null
  ) => {
    const { width, height } = validDimensions;

    // if it has preview it's newly updated image othetwice it's just initial value
    if (value && value.preview) {
      const { width: imageWidth, height: imageHeight } = await readImage(value.preview);
      if (imageWidth !== width || imageHeight !== height) {
        return { key: 'form.errors.invalidDimensions', context: { width, height } };
      }
    }

    return null;
  };

export const isImageSizeLessThan = (maximum: number) => (value: number) =>
  typeof value !== 'number' || value < maximum
    ? null
    : { key: 'form.errors.isImageSizeLessThan', context: { maxSize: maximum } };
