/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyMirror } from '@poinz/utils';

export interface AgentHeader {
  app: string;
  version: string;
  [key: string]: string | null | undefined;
}

export interface UrlParams {
  pathParams?: Record<string, string | number | boolean> | Record<string, never>;
  queryParams?: Record<string, any> | Record<string, never>;
}

export interface Params extends UrlParams {
  headers?: Headers;
  authenticated?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body?: Object | null;
  isMultiPart?: boolean;
  isBinary?: boolean;
}

export interface PaginatedParams {
  headers?: Headers;
  queryParams?: Record<string, any>;
}

export interface FetchInit {
  method?: ApiMethod;
  headers?: Headers;
  body?: any;
}

export interface Result {
  headers: Headers;
  blob?: Blob;
  body?: any;
}

export type PaginatedResult<T> = T & {
  pageNumber: number;
  totalPages: number;
  totalElements: number;
};

export type PaginationModel<T> = {
  pageNumber: number;
  totalPages: number;
  totalElements: number;
  body: Array<T>;
};

export type NewPaginationModel<T> = {
  content: Array<T>;
  body: Array<T>;
  headers: Headers;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number: number;
  numberOfElements?: number;
  pageable?: string;
  size?: number;
  sort?: Sort;
  totalPages?: number;
  pageNumber?: number;
  totalElements?: number;
};

export const DEFAULT_NEW_PAGINATION: NewPaginationModel<any> = {
  totalPages: 0,
  totalElements: 0,
  content: [],
  body: [],
  headers: new Headers(),
  empty: false,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  size: 0,
  pageNumber: 0
};

export const DEFAULT_PAGINATION: PaginationModel<any> = {
  pageNumber: 0,
  totalPages: 0,
  totalElements: 0,
  body: []
};

export const API_METHOD = keyMirror({
  GET: null,
  POST: null,
  PUT: null,
  DELETE: null,
  PATCH: null
});

export type ApiMethod = keyof typeof API_METHOD;

const ENVIRONMENT = keyMirror({
  test: null,
  development: null,
  staging: null,
  prod: null
});

export type Environment = keyof typeof ENVIRONMENT;

type BaseClientParams = {
  agent: string;
  language: string;
  errorHandler?: (response: Response, notification?: Notification) => any;
  setAuthenticatedHeaders: (header: Headers, endpoint?: string) => Promise<Headers>;
};

export type ClientParams = {
  environment: Environment;
} & BaseClientParams;

export type PoinzClientParams = {
  apiRoot: string;
} & BaseClientParams;

export type AuthApi = (
  endpoint: string,
  method: ApiMethod,
  params: Params
) => Promise<{ body: any; headers: Headers }>;

export interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
  sort: Sort;
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export type Nullable<T> = T | null;

export interface PagableParams {
  size?: number;
  sort?: string | string[];
  page?: string | number;
}
