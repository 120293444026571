import moment from 'moment';

export const isInProgress = (startDate: string, endDate: string) => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  const hasStarted = today.getTime() > start.getTime();
  const didntFinish = today.getTime() < end.getTime();

  if (hasStarted && didntFinish) return true;
  return false;
};

// "26 December 2023" (See more luxon formats here: https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens)
export const LOCALIZED_DATE_WITH_FULL_MONTH_FORMAT = 'DDD';

export const formatDateAndTime = (date?: string | null): string | null | undefined => date ? moment(date).format('YYYY-MM-DDTHH:mm:ssZ') : null;
