import * as React from 'react';

import NavigationHeader, {
  NavigationRoute as NavigationRouteProps,
  NavigationHeaderProps
} from 'App/components/NavigationHeader';

type NavigationHeaderContextProps = {
  setNavigationHeaderProps: (arg1?: NavigationHeaderProps | null | undefined) => void,
  setNavigationHeaderVisibility: (arg1: boolean) => void,
  isVisible: boolean
};

const NavigationHeaderContext = React.createContext<NavigationHeaderContextProps>({
  setNavigationHeaderProps: () => undefined,
  setNavigationHeaderVisibility: () => undefined,
  isVisible: true
});

function NavigationHeaderProvider(props: {
  children: React.ReactNode,
  navigationRoutes: {
    [key: string]: NavigationRouteProps
  }
}) {
  const { children, navigationRoutes } = props;

  const [isVisible, setIsVisible] = React.useState<boolean>(true);
  const [navigationHeaderProps, setNavigationHeaderProps] = React.useState<NavigationHeaderProps | null | undefined>(undefined);

  const contextValue = React.useMemo(
    () => ({
      setNavigationHeaderProps: (newValues?: NavigationHeaderProps | null) =>
        setNavigationHeaderProps(newValues),
      setNavigationHeaderVisibility: setIsVisible,
      isVisible
    }),
    [isVisible]
  );

  return (
    <NavigationHeaderContext.Provider value={contextValue}>
      {isVisible && (
        <NavigationHeader
          navigationRoutes={navigationRoutes}
          providerProps={navigationHeaderProps}
        />
      )}
      {children}
    </NavigationHeaderContext.Provider>
  );
}

export const useNavigationHeader = () => React.useContext(NavigationHeaderContext);

export default NavigationHeaderProvider;
