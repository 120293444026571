import * as React from 'react';
import { makeStyles } from '@mui/styles';

const SIZE = 60;
const getImgSize = (size: number) => size / Math.sqrt(2);

const useStyles = makeStyles({
  root: {
    // @ts-expect-error - TS2322 - Type '(size: {}) => {}' is not assignable to type 'Width<string | number> | PropsFunc<{}, Width<string | number> | undefined> | undefined'.
    width: size => size,
    // @ts-expect-error - TS2322 - Type '(size: {}) => {}' is not assignable to type 'Height<string | number> | PropsFunc<{}, Height<string | number> | undefined> | undefined'.
    height: size => size,
    // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
    padding: size => (size - getImgSize(size)) / 2,
    borderRadius: '50%'
  },
  image: {
    width: '100%',
    height: '100%'
  }
});

type Props = {
  logoUrl: string;
  logoBackgroundColor?: string;
  size?: number;
};

function BrandLogo(props: Props) {
  const { logoUrl, logoBackgroundColor, size } = props;

  const classes = useStyles(size);

  return (
    <div className={classes.root} style={{ backgroundColor: logoBackgroundColor }}>
      <img className={classes.image} src={logoUrl} alt="logo" />
    </div>
  );
}

BrandLogo.defaultProps = {
  size: SIZE
};

export default React.memo<Props>(BrandLogo);
