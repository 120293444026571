import { keyMirror } from '@poinz/utils';
import { PagableParams } from '../../../types';
import { GiftCard } from './giftCard';

export const EGIFT_STATUS = keyMirror({
  COMPLETED: null,
  FAILED: null,
  PENDING: null
});

export type EgiftStatus = keyof typeof EGIFT_STATUS;

export interface Egift {
  id: number;
  status: EgiftStatus;
  giftCard: GiftCard;
  userId: number;
  referenceNumber: string;
  amount: number;
  created: string;
  errorDescription: string;
}

export interface EgiftPayload {
  giftCardId: number;
  userId: string;
  amount: number;
}

export interface PaginatedEgiftParams extends PagableParams {
  eGiftId: number;
  status: EgiftStatus;
}
