import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  // @ts-expect-error - TS2339 - Property 'mixins' does not exist on type 'DefaultTheme'.
  toolbar: { ...theme.mixins.toolbar, display: 'flex', alignItems: 'center' },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowY: 'auto',
    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'DefaultTheme'.
    width: theme.mainComponents.drawer.width,
    // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
    transition: theme.transitions.create('width', {
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      easing: theme.transitions.easing.sharp,
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      duration: theme.transitions.duration.enteringScreen
    }),
    height: '100%'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
    transition: theme.transitions.create('width', {
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      easing: theme.transitions.easing.sharp,
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      duration: theme.transitions.duration.leavingScreen
    }),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    width: theme.spacing(7)
  },
  drawerContent: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  logo: {
    marginTop: 5,
    marginLeft: 12,
    width: 80
  }
}));
