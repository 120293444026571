import * as React from 'react';
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton,
  Icon,
  Collapse,
  CircularProgress,
  TablePagination as MuiTablePagination,
  TableFooter as MuiTableFooter
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { TablePaginationActions } from 'App/components';

import { TranslationObject } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { destructureDeep } from 'util/ObjectHelper';

import RawText from './RawText';
import Paper from './Paper';

const styles = createStyles(theme => ({
  centered: {
    textAlign: 'center'
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[100]
  },
  hover: {
    cursor: 'pointer'
  },
  container: {
    overflowX: 'auto'
  }
}));

export type TableColumn = {
  header: TranslationObject;
  padding?: 'default' | 'checkbox' | 'none';
  align?: 'right' | 'left' | 'center' | 'justify' | 'inherit';
  dataMapper: string | ((dataObject: any, index?: number) => any);
};

type TableAction = {
  icon: string;
  key?: string;
  action: (arg1: any, index?: number) => Promise<undefined> | undefined;
  hiddenCondition?: (arg1: any) => boolean;
};

export type TablePagination = {
  pageNumber: number;
  rowsPerPage: number;
  totalElements: number;
  handlePageChange: (toPage: number) => void;
};

type TableDetail = {
  isExpanded: boolean;
  data: any;
};

export type TableProps = {
  emptyContext?: any;
  loading?: boolean;
  columns: TableColumn[];
  data: Array<any>;
  actions?: Array<TableAction>;
  onAdd?: () => void;
  error: string | null | undefined;
  noPaper: boolean;
  actionLabel?: TranslationObject;
  tableClassName: string;
  tableHeadClassName: string;
  tableBodyClassName: string;
  tableCellClassName: string;
  tableHeadCellClassName: string;
  tableRowClassName: string;
  tableHeadRowClassName: string;
  onClick?: (arg1: any) => Promise<undefined> | undefined;
  pagination?: TablePagination;
  DetailComponent?: React.ComponentType<TableDetail>;
} & LocaleProps &
  WithStyles<typeof styles>;

type TableState = {
  collapsedRow: number;
};

class Table extends React.PureComponent<TableProps, TableState> {
  static defaultProps = {
    loading: false,
    noPaper: false,
    error: undefined,
    tableClassName: '',
    tableHeadClassName: '',
    tableBodyClassName: '',
    tableCellClassName: '',
    tableHeadCellClassName: '',
    tableRowClassName: '',
    tableHeadRowClassName: ''
  };

  state: TableState = {
    collapsedRow: -1
  };

  handleClick(dataObject: any, index: number) {
    const { onClick, DetailComponent } = this.props;
    const { collapsedRow } = this.state;
    if (typeof onClick === 'function') {
      onClick(dataObject);
    }
    if (DetailComponent !== undefined) {
      this.setState({ collapsedRow: collapsedRow === index ? -1 : index });
    }
  }

  renderHeader() {
    const {
      columns,
      actions,
      translate,
      onAdd,
      actionLabel,
      tableHeadCellClassName,
      tableHeadRowClassName
    } = this.props;
    return (
      <MuiTableRow className={tableHeadRowClassName}>
        {columns.map((c, idx) => (
          <MuiTableCell
            // @ts-expect-error - TS2322 - Type 'TranslationKey' is not assignable to type 'Key | null | undefined'.
            key={c.header?.key ?? `header-${idx}`}
            // @ts-expect-error - TS2322 - Type '"none" | "checkbox" | "default" | undefined' is not assignable to type '"none" | "normal" | "checkbox" | undefined'.
            padding={c.padding}
            align={c.align}
            className={tableHeadCellClassName}
          >
            {c.header && translate(c.header.key, c.header.context)}
          </MuiTableCell>
        ))}
        {actions && (
          <MuiTableCell align="right" padding="checkbox" className={tableHeadCellClassName}>
            {typeof onAdd === 'function' && (
              <IconButton onClick={onAdd}>
                <Icon>add</Icon>
              </IconButton>
            )}
            {actionLabel && translate(actionLabel.key, actionLabel.context)}
          </MuiTableCell>
        )}
      </MuiTableRow>
    );
  }

  renderRow(dataObject: any, index: any) {
    const {
      columns,
      actions,
      tableRowClassName,
      tableCellClassName,
      onClick,
      classes,
      DetailComponent,
      translate
    } = this.props;
    const { collapsedRow } = this.state;
    const clickable = typeof onClick === 'function' || DetailComponent !== undefined;
    return (
      <React.Fragment key={index.toString()}>
        <MuiTableRow
          // @ts-expect-error - TS2339 - Property 'hover' does not exist on type 'ClassNameMap<never>'.
          className={`${tableRowClassName} ${clickable ? classes.hover : ''}`}
          hover={clickable}
          onClick={clickable ? () => this.handleClick(dataObject, index) : undefined}
        >
          {columns.map((c: TableColumn, colIdx: number) => {
            let columnValue = '';
            const { dataMapper } = c;
            if (typeof dataMapper === 'string') {
              columnValue = destructureDeep(dataMapper, dataObject);
            } else if (typeof dataMapper === 'function') {
              columnValue = dataMapper(dataObject, index);
            }
            // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'string' and 'boolean' have no overlap.
            if (columnValue === false) {
              columnValue = translate('general.no');
            }
            // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'string' and 'boolean' have no overlap.
            if (columnValue === true) {
              columnValue = translate('general.yes');
            }
            return (
              <MuiTableCell
                key={`row-${index}-col-${colIdx.toString()}`}
                // @ts-expect-error - TS2322 - Type '"none" | "checkbox" | "default" | undefined' is not assignable to type '"none" | "normal" | "checkbox" | undefined'.
                padding={c.padding}
                align={c.align}
                className={tableCellClassName}
              >
                {columnValue}
              </MuiTableCell>
            );
          })}
          {actions && (
            <MuiTableCell align="right" className={tableCellClassName}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {actions
                  .filter(a => !a.hiddenCondition || !a.hiddenCondition(dataObject))
                  .map(a => (
                    <IconButton
                      key={a.icon}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        a.action(dataObject, index);
                      }}
                    >
                      <Icon>{a.icon}</Icon>
                    </IconButton>
                  ))}
              </div>
            </MuiTableCell>
          )}
        </MuiTableRow>
        {DetailComponent !== undefined && (
          <MuiTableRow className={tableRowClassName}>
            <MuiTableCell
              className={tableCellClassName}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={columns.length}
            >
              <Collapse in={collapsedRow === index} timeout="auto">
                <DetailComponent data={dataObject} isExpanded={collapsedRow === index} />
              </Collapse>
            </MuiTableCell>
          </MuiTableRow>
        )}
      </React.Fragment>
    );
  }

  renderEmpty() {
    const {
      columns,
      actions,
      translate,
      emptyContext,
      classes,
      tableRowClassName,
      tableCellClassName
    } = this.props;
    return (
      <MuiTableRow className={tableRowClassName}>
        <MuiTableCell
          // @ts-expect-error - TS2339 - Property 'centered' does not exist on type 'ClassNameMap<never>'.
          className={`${classes.centered} ${tableCellClassName}`}
          colSpan={columns.length + (actions ? 1 : 0)}
        >
          {translate('general.table.noData', emptyContext || { label: 'general.entries' })}
        </MuiTableCell>
      </MuiTableRow>
    );
  }

  renderError(error: string) {
    const { columns, actions, classes, tableRowClassName, tableCellClassName } = this.props;
    return (
      <MuiTableRow className={tableRowClassName}>
        <MuiTableCell
          // @ts-expect-error - TS2339 - Property 'centered' does not exist on type 'ClassNameMap<never>'.
          className={`${classes.centered} ${tableCellClassName}`}
          colSpan={columns.length + (actions ? 1 : 0)}
        >
          <RawText color="error">{error}</RawText>
        </MuiTableCell>
      </MuiTableRow>
    );
  }

  renderBody() {
    const { data, error } = this.props;
    if (error) {
      return this.renderError(error);
    }
    return data.length > 0 ? data.map((d, idx) => this.renderRow(d, idx)) : this.renderEmpty();
  }

  renderLoading() {
    const { columns, actions, classes, tableRowClassName, tableCellClassName } = this.props;
    return (
      <MuiTableRow className={tableRowClassName}>
        <MuiTableCell
          // @ts-expect-error - TS2339 - Property 'centered' does not exist on type 'ClassNameMap<never>'.
          className={`${classes.centered} ${tableCellClassName}`}
          colSpan={columns.length + (actions ? 1 : 0)}
        >
          <CircularProgress />
        </MuiTableCell>
      </MuiTableRow>
    );
  }

  renderFooter() {
    const { columns, pagination } = this.props;

    return (
      pagination && (
        <MuiTableFooter>
          <MuiTableRow>
            <MuiTablePagination
              rowsPerPageOptions={[pagination.rowsPerPage]}
              colSpan={columns.length}
              count={pagination.totalElements}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.pageNumber}
              onPageChange={(event, page) => pagination.handlePageChange(page)}
              // @ts-expect-error - TS2769 - No overload matches this call.
              ActionsComponent={TablePaginationActions}
            />
          </MuiTableRow>
        </MuiTableFooter>
      )
    );
  }

  renderTable() {
    const { loading, classes, tableClassName, tableHeadClassName } = this.props;
    return (
      <MuiTable className={tableClassName}>
        {/* @ts-expect-error - TS2339 - Property 'tableHeader' does not exist on type 'ClassNameMap<never>'. */}
        <MuiTableHead className={`${classes.tableHeader} ${tableHeadClassName}`}>
          {this.renderHeader()}
        </MuiTableHead>
        <MuiTableBody>{loading ? this.renderLoading() : this.renderBody()}</MuiTableBody>
        {this.renderFooter()}
      </MuiTable>
    );
  }

  render() {
    const { noPaper, classes } = this.props;

    return noPaper ? (
      // @ts-expect-error - TS2339 - Property 'container' does not exist on type 'ClassNameMap<never>'.
      <div className={classes.container}>{this.renderTable()}</div>
    ) : (
      // @ts-expect-error - TS2339 - Property 'container' does not exist on type 'ClassNameMap<never>'.
      <Paper noPadding className={classes.container}>
        {this.renderTable()}
      </Paper>
    );
  }
}

export default withStyles(styles)(withLocale(Table));
