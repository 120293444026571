import * as React from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { TranslationObject } from 'serviceNew/locale';

import Icon from './Icon';
import Text from './Text';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  text: {
    paddingTop: theme.spacing(1)
  },
  buttonLabel: {
    padding: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none'
  }
}));

type Props = {
  onClick: () => undefined | Promise<undefined>;
  className?: string;
  label: TranslationObject;
};

function AddButton(props: Props) {
  const { onClick, className, label } = props;

  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="secondary"
      classes={{
        root: clsx(classes.button, className)
      }}
      {...{ onClick, className }}
    >
      <Icon icon="add" />
      <Text className={classes.text} context={label.context}>
        {label.key}
      </Text>
    </Button>
  );
}

export default AddButton;
