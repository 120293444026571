import React from 'react';
import clsx from 'clsx';
import { IdType, ScaledImage } from '@poinz/api';
import { CardMedia, CardContent, Card, CardActionArea } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import { Theme } from '@mui/material/styles';

import { UserRoles } from 'serviceNew/model/adminUser';
import RenderIf from 'App/components/RenderIf';
import { useLocale } from 'App/providers/LocaleProvider';
import { BrandLogo } from 'App/components/brand';
// @ts-expect-error - TS2307 - Cannot find module './16-9.jpg' or its corresponding type declarations.
import defaultPicture from './16-9.jpg';
import RawText from '../RawText';
import Button from '../Button';
import { Icon } from '../index';
import { IconType } from '../Icon';

// @ts-expect-error - TS2345 - Argument of type '(theme: DefaultTheme) => { card: { overflow: "hidden"; }; actions: { display: "flex"; flexDirection: "row"; justifyContent: "flex-end"; alignItems: "center"; flexWrap: "wrap"; padding: any; paddingTop: number; }; ... 9 more ...; iconContainer: { ...; }; }' is not assignable to parameter of type 'Styles<DefaultTheme, {}, "content" | "button" | "column" | "row" | "selected" | "actions" | "logo" | "card" | "entityId" | "cardContainer" | "cardBodyContainer" | "iconContainer">'.
const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'hidden'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    paddingTop: 0
  },
  selected: {
    background: theme.palette.grey[400]
  },
  entityId: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  logo: {
    paddingRight: theme.spacing(1)
  },
  content: {
    position: 'relative'
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row'
  },
  cardBodyContainer: {
    width: '100%'
  },
  iconContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(3)
  }
}));

export type EntityCardAction = {
  label: TranslationObject;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  hidden?: boolean;
};

type Props = {
  id: IdType;
  name: string;
  picture?: string | ScaledImage | null | undefined;
  logo?: string | null | undefined;
  onClick?: () => void | null | undefined;
  selected: boolean;
  disabled?: boolean;
  withPicture?: boolean;
  additionalContent?: Array<{
    label: TranslationObject;
    value: string | number;
  }>;
  actions?: EntityCardAction[];
  showId?: boolean;
  icon?: IconType;
};

function EntityCard(props: Props) {
  const {
    id,
    name,
    picture,
    logo,
    selected,
    icon,
    disabled,
    onClick,
    actions,
    withPicture,
    additionalContent,
    showId
  } = props;

  const classes = useStyles();
  const { translate } = useLocale();

  const clickDisabled = disabled || typeof onClick !== 'function';

  const renderImage = () => {
    if (withPicture) {
      const pictureUrl =
        typeof picture === 'string' ? picture : picture?.sourceUrl ?? defaultPicture;
      return (
        <CardMedia
          onClick={disabled ? undefined : onClick}
          component="img"
          // @ts-expect-error - TS2339 - Property 'media' does not exist on type 'ClassNameMap<never>'.
          className={classes.media}
          height="120"
          image={pictureUrl}
          title="Image"
        />
      );
    }
    return null;
  };

  const renderId = () => {
    if (id) {
      return (
        // @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component.
        <RenderIf roles={!showId ? UserRoles.POINZ_ADMIN : undefined}>
          <RawText variant="caption" className={classes.entityId}>
            {`ID: ${id}`}
          </RawText>
        </RenderIf>
      );
    }
    return null;
  };

  const renderTitle = () => (
    <div className={classes.row}>
      {logo && (
        <div className={classes.logo}>
          <BrandLogo logoUrl={logo} />
        </div>
      )}
      <RawText variant="subtitle1">{name}</RawText>
    </div>
  );

  const renderAdditionalContent = () => (
    <div className={classes.column}>
      {additionalContent &&
        additionalContent.map(ac => (
          <RawText key={ac.label.key as string} variant="caption" noWrap>
            {`${translate(ac.label.key, ac.label.context)}: ${ac.value}`}
          </RawText>
        ))}
    </div>
  );

  const renderAction = (action: EntityCardAction, index: number) => {
    if (!action.hidden) {
      return (
        <Button
          size="small"
          color="primary"
          key={index.toString()}
          onClick={action.onClick}
          disabled={action.disabled}
          className={classes.button}
          loading={action.loading}
          label={action.label}
        />
      );
    }
    return null;
  };

  const renderIcon = () =>
    icon && (
      <div className={classes.iconContainer}>
        <Icon icon={icon} color="primary" />
      </div>
    );

  const renderCard = () => (
    <Card className={clsx(selected && classes.selected, classes.card, classes.cardContainer)}>
      {renderIcon()}
      <div className={classes.cardBodyContainer}>
        <CardActionArea onClick={clickDisabled ? undefined : onClick} disabled={clickDisabled}>
          {renderImage()}
          <CardContent className={classes.content}>
            {renderId()}
            {renderTitle()}
            {renderAdditionalContent()}
          </CardContent>
        </CardActionArea>
        {actions && <div className={classes.actions}>{actions.map(renderAction)}</div>}
      </div>
    </Card>
  );

  return <div>{renderCard()}</div>;
}

EntityCard.defaultProps = {
  onClick: undefined,
  actions: undefined,
  withPicture: false,
  disabled: false,
  showId: false
};

export default EntityCard;
