import { ScaledImage } from 'types';
import { REDEMPTION_METHOD, REDEMPTION_TYPE, WALLET_ASSET_TYPE } from './wallet';
import {
  Deal,
  DealDiscountType,
  DealPriceData,
  DealRedemptionMethod,
  DealRedemptionType,
  DealState,
  DealType
} from './userDeal';
import { BusinessProfile } from './businessProfile';
import { Location } from './location';

export interface IDealCollectsStartPayment {
  dealCollectId: number;
  invoiceAddressId: number | null;
  deliveryAddressId: number | null;
}

export interface ICreateDealCollectsParams {
  dealId: number;
  dealOptionId: number;
  deviceId: string;
}

export interface IPaginatedGetDealCollectsListParams {
  page: number;
  size: number;
  sort: 'asc' | 'desc';
  type: DealType;
  state: DealState;
}

export interface ICreateDealCollectResponse {
  id: number;
  reference: string;
}

export enum DEAL_COLLECT_STATE {
  PENDING = 'PENDING',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PURCHASED = 'PURCHASED',
  REDEEMED = 'REDEEMED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED'
}

export enum DEAL_COLLECT_PAYMENT_TYPE {
  VIS = 'VIS',
  ECA = 'ECA',
  PEF = 'PEF',
  PFC = 'PFC',
  PAP = 'PAP',
  POINZ = 'POINZ',
  PMC = 'PMC',
  TWI = 'TWI',
  PAY = 'PAY',
  APL = 'APL',
  AMX = 'AMX'
}

export enum DEAL_COLLECT_BARCODE_TYPE {
  EAN13 = 'EAN13',
  ITF = 'ITF',
  EAN8 = 'EAN8',
  CODE128 = 'CODE128',
  CODE39 = 'CODE39',
  QRCODE = 'QRCODE',
  AZTEC = 'AZTEC',
  PDF417 = 'PDF417',
  UPCA = 'UPCA',
  DATAMATRIX = 'DATAMATRIX'
}

export enum DELIVERY_STATE {
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED'
}

export interface IDealCollect {
  id: number;
  userId: number;
  dealId: number;
  redemptionType: DealRedemptionType;
  redemptionMethod: DealRedemptionMethod;
  deliveryState: DELIVERY_STATE;
  barcodeType: DEAL_COLLECT_BARCODE_TYPE;
  redemptionCodeId: number;
  redemptionToken: string;
  certificate: number;
  dealType: DealType;
  dealCollectState: DEAL_COLLECT_STATE;
  purchaseDate: string;
  redeemDate: string;
  offlineRedeemDate: string;
  cancellationDate: string;
  cancellationFee: number;
  cancellationLabel: string;
  refundValue: number;
  price: number;
  discountedPrice: number;
  expirationDate: string;
  referenceNumber: string;
  businessProfile: Partial<BusinessProfile>;
  deal: Partial<Deal>;
  dealOption: Partial<DealPriceData>;
  location: Partial<Location>;
}
