import { IdType, NewPaginationModel } from '@poinz/api';
import { apiClient, callEndpoint, callNotificationEndpoint } from './apiClient';
import { Category } from 'serviceNew/model/category';
import { getMessage } from 'serviceNew/notifications';

const ROOT = '/categories';
const CATEGORY_ENTITY = 'category';

const getPaginatedCategories = async (page: number): Promise<NewPaginationModel<Category>> => {
  const result: NewPaginationModel<Category> = await apiClient.callPaginatedEndpoint(
    page || 0,
    ROOT,
    {
      headers: new Headers(),
      queryParams: {
        page,
        size: 200,
        sort: 'id,asc'
      }
    }
  );
  return result;
};

const updateCategory = async (body: Category): Promise<Category> => {
  const result = await callNotificationEndpoint(
    `${ROOT}/${body.id}`,
    'PUT',
    {
      headers: new Headers(),
      authenticated: true,
      body
    },
    getMessage.update(CATEGORY_ENTITY)
  );
  return result.body;
};

const createCategory = async (body: Category): Promise<Category> => {
  const result = await callNotificationEndpoint(
    ROOT,
    'POST',
    {
      headers: new Headers(),
      authenticated: true,
      body
    },
    getMessage.create(CATEGORY_ENTITY)
  );
  return result.body;
};

const getCategory = async (id: IdType): Promise<Category> => {
  const result = await callEndpoint(`${ROOT}/${id}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return result.body;
};

const deleteCategory = async (id: IdType): Promise<void> => {
  await callNotificationEndpoint(
    `${ROOT}/${id}`,
    'DELETE',
    { headers: new Headers(), authenticated: true },
    getMessage.delete(CATEGORY_ENTITY)
  );
};

export default {
  getPaginatedCategories,
  getCategory,
  updateCategory,
  createCategory,
  deleteCategory
};
