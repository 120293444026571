import * as React from 'react';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  LineChart as RCLineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Text
} from 'recharts';

import { useLocale } from 'App/providers/LocaleProvider';

import { TranslationObject } from 'serviceNew/locale';

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2)
  }
}));

type Props<S, T> = {
  data: T[];
  height: number;
  dataSets: S[];
  // @ts-expect-error - TS2344 - Type 'S' does not satisfy the constraint 'string | number | symbol'.
  dataSetNames: Partial<Record<S, TranslationObject>>;
  xAxis: S;
};

const COLORS = ['#EB6308', '#17BEBB', '#006494', '#283593', '#00695c'];

function AxisTick(props: {
  x: number;
  y: number;
  payload: {
    value: number;
  };
}) {
  const {
    x,
    y,
    payload: { value }
  } = props;

  if (value === 0) {
    return null;
  }

  return (
    <Text x={x} y={y} dx={6} dy={4} textAnchor="end" verticalAnchor="start" fill="#666">
      {value}
    </Text>
  );
}

function LineChart<T, S extends keyof T>(props: Props<S, T>) {
  const { height, xAxis, dataSets, data, dataSetNames } = props;

  const [unselected, setUnselected] = React.useState({});
  const theme = useTheme();
  const { translate } = useLocale();
  const classes = useStyles();

  function handleLegendClick(event: any) {
    const dataKey = event.dataKey.trim();
    setUnselected(curUnselected => ({ ...curUnselected, [dataKey]: !curUnselected[dataKey] }));
  }

  function renderLine(dataSet: S, idx: number) {
    // @ts-expect-error - TS2536 - Type 'S' cannot be used to index type '{}'.
    const dataKey = unselected[dataSet] ? `${dataSet} ` : dataSet;
    // @ts-expect-error - TS2536 - Type 'S' cannot be used to index type '{}'.
    const strokeColor = unselected[dataSet]
      ? // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
        theme.mainComponents.chart.axis.line.stroke
      : COLORS[idx % COLORS.length];
    const name = dataSetNames[dataSet];
    return (
      <Line
        dot
        strokeWidth={2}
        type="monotone"
        name={name && translate(name.key, name.context)}
        // @ts-expect-error - TS2769 - No overload matches this call.
        dataKey={dataKey}
        stroke={strokeColor}
      />
    );
  }

  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" {...{ height }}>
        <RCLineChart {...{ data }}>
          {/* @ts-expect-error - TS2322 - Type 'string | number | symbol' is not assignable to type 'DataKey<any> | undefined'. */}
          <XAxis dataKey={xAxis} tickLine={false} />
          {/* @ts-expect-error - TS2322 - Type '(props: { x: number; y: number; payload: { value: number; }; }) => Element | null' is not assignable to type 'boolean | SVGProps<SVGTextElement> | ReactElement<SVGElement, string | JSXElementConstructor<any>> | ((props: any) => ReactElement<...>) | undefined'. */}
          <YAxis axisLine={false} tickLine={false} orientation="right" mirror tick={AxisTick} />
          <Tooltip />
          <CartesianGrid vertical={false} />
          <Legend iconType="circle" onClick={handleLegendClick} />
          {dataSets.map(renderLine)}
        </RCLineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineChart;
