import * as React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { TranslationObject } from 'serviceNew/locale';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormError from './FormError';
import Button from './Button';
import Text from './Text';

const Accordion = withStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    borderRadius: 8,
    border: 'none',
    boxShadow: 'none',
    '&:first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '&:last-child': {
      borderBottom: 0,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&$disabled': {
      backgroundColor: theme.palette.common.white,
    },
  },
  expanded: {},
  disabled: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
    '&$disabled': {
      opacity: 1,
    },
  },
  content: {
    margin: '0 0',
    '&$expanded': {
      margin: '0 0',
    },
  },
  expanded: {},
  disabled: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
// @ts-expect-error - TS2339 - Property 'disabled' does not exist on type '{}'.
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
  },
  children: {
    flex: 1,
  },
  accordion: {
// @ts-expect-error - TS2339 - Property 'disabled' does not exist on type '{}'.
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
  },
});

type SectionAction = {
  label: TranslationObject,
  onClick: () => void
};

type Props = {
  children: React.ReactNode,
  className: string,
  title: TranslationObject,
  subtitle?: TranslationObject,
  touched?: boolean,
  error?: TranslationObject | null | undefined,
  actions?: SectionAction[],
  accordionDisabled?: boolean,
  accordionInitialOpen?: boolean
};

function FormSection({
  children,
  className,
  title,
  subtitle,
  actions,
  error,
  touched,
  accordionDisabled,
  accordionInitialOpen,
}: Props) {
  const classes = useStyles({ disabled: accordionDisabled });
  const [open, setOpen] = React.useState<boolean>(accordionDisabled ? true : !!accordionInitialOpen);
  return (
    <div className={clsx(classes.container, className)}>
      <Accordion
        expanded={open}
        onChange={accordionDisabled ? undefined : () => setOpen(prevOpen => !prevOpen)}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={accordionDisabled ? undefined : <ExpandMoreIcon />}>
          <div className={classes.column}>
            <div className={classes.title}>
              <Text variant="h6" context={title.context}>
                {title.key}
              </Text>
              {actions &&
                actions.map((action, idx) => (
                  <Button key={idx.toString()} label={action.label} onClick={action.onClick} />
                ))}
            </div>
            {subtitle && (
              <div className={classes.title}>
                <Text variant="body2" context={subtitle.context}>
                  {subtitle.key}
                </Text>
              </div>
            )}
          </div>
{ /* @ts-expect-error - TS2322 - Type '{ error: TranslationObject | null | undefined; touched: boolean | undefined; variant: "standard"; }' is not assignable to type 'Props'. */}
          <FormError variant="standard" {...{ error, touched }} />
        </AccordionSummary>
        <AccordionDetails>
          {children != null &&
            (Array.isArray(children) ? children.some(child => child != null) : true) && (
              <div className={classes.children}>{children}</div>
            )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

FormSection.defaultProps = {
  className: '',
  accordionDisabled: true,
  accordionInitialOpen: true,
};

export default FormSection;
