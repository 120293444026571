import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"formik"' has no exported member 'FieldRenderProps'.
import { getIn, FieldRenderProps } from 'formik';
import { FormHelperText } from '@mui/material';
import { withFieldAdapter } from '../Field';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import Checkbox from '../Checkbox';
import Text from '../Text';

type SwitchFieldProps = {
  className: string | null | undefined;
} & JSX.LibraryManagedAttributes<typeof Checkbox, React.ComponentProps<typeof Checkbox>> &
  FieldRenderProps &
  FormContextProps;

class CheckboxField extends React.PureComponent<SwitchFieldProps> {
  render(): React.ReactElement {
    const { field, form, submitting, disabled, className, ...props } = this.props;
    const fieldError = getIn(form.error, field.name);

    return (
      <div {...{ className }}>
        <Checkbox
          color="secondary"
          disabled={submitting || disabled}
          {...field}
          {...props}
          value={field.value || false}
        />
        <FormHelperText error>
          {fieldError && (
            <Text color="error" variant="caption" context={fieldError.context}>
              {fieldError.key}
            </Text>
          )}
        </FormHelperText>
      </div>
    );
  }
}

export default withFieldAdapter(withFormContext(CheckboxField));
