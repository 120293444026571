import * as React from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TranslatedText } from 'App/components';
import { TranslationObject } from 'serviceNew/locale';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: '10px',
    height: '20px',
// @ts-expect-error - TS2339 - Property 'type' does not exist on type '{}'.
    color: ({ type }) =>
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
      type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
// @ts-expect-error - TS2339 - Property 'color' does not exist on type '{}'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    background: ({ color }) => color || theme.palette.common.grey
  }
}));

export type ChipProps = {
  label: TranslationObject,
  color?: string,
  className?: string,
  type?: 'light' | 'dark'
};

function StatusChip(props: ChipProps) {
  const { label, color, className, type } = props;
  const classes = useStyles({ color, type });
  return (
    <Chip
      label={<TranslatedText text={label} />}
      classes={{ root: clsx(classes.chip, className) }}
    />
  );
}

StatusChip.defaultProps = {
  type: 'light'
};

export default StatusChip;
