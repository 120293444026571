import {apiClient} from './apiClient';
import { getPaginatedResult, PaginationModel } from './api';

import {
  CodeCollectActivity,
  CardCollectActivity,
  DealCollectActivity,
} from '../model/collectActivity';

import { DealCollectState } from '../model/dealCollect';
import { DealType } from '../model/deal';

const ROOT = '/activities/poinz';
const CODE_COLLECTS = 'code-collects';
const CARD_COLLECTS = 'card-collects';
const DEAL_COLLECTS = 'deal-collects';

async function getCodeCollectsActivity(
  queryParams: {
    pageNumber?: number,
    date: string
  },
): Promise<PaginationModel<CodeCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${CODE_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams,
    }
  );
// @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<CodeCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

async function getCardCollectsActivity(
  queryParams: {
    pageNumber?: number,
    date: string
  },
): Promise<PaginationModel<CardCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${CARD_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams,
    }
  );
// @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<CardCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

async function getDealCollectsActivity(
  queryParams: {
    pageNumber?: number,
    date: string,
    dealCollectState?: DealCollectState | null | undefined,
    dealType?: DealType | null | undefined
  },
): Promise<PaginationModel<DealCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${DEAL_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams,
    }
  );
// @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<DealCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

export default {
  getCodeCollectsActivity,
  getCardCollectsActivity,
  getDealCollectsActivity,
};
