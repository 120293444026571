import * as React from 'react';

import { TranslationObject } from 'serviceNew/locale';

import TextInput from './TextInput';
import { TextFieldProps } from '@mui/material';

type Props = Partial<TextFieldProps> & {
  label: TranslationObject;
  value: string | number;
  className?: string;
  useMonoFont?: boolean;
  startAdornment?: any;
  placeholder?: TranslationObject;
};

function ReadOnlyText(props: Props) {
  const { label, value, className, useMonoFont, startAdornment, ...restProps } = props;
  return (
    <TextInput
      InputProps={{
        startAdornment,
        readOnly: true,
        style: { fontFamily: useMonoFont ? 'roboto mono' : 'default' }
      }}
      InputLabelProps={{
        shrink: true
      }}
      {...{ label, value, className }}
      {...restProps}
    />
  );
}

ReadOnlyText.defaultProps = {
  className: '',
  useMonoFont: false
};

export default React.memo<Props>(ReadOnlyText);
