type ApiErrorModel = {
  message: string;
  errors?: string[] | null;
};

export class ApiError {
  error: ApiErrorModel;

  constructor(error: ApiErrorModel) {
    this.error = error;
  }

  getErrors() {
    if (!this.error.errors) {
      return undefined;
    }
    return this.error.errors.join('\n');
  }

  toString() {
    return `${this.error.errors ? `\n${this.error.errors.join('\n')}` : this.error.message}`;
  }
}
