import {EnumTranslationKeys} from 'serviceNew/locale';
import { keyMirror } from '@poinz/utils';
import { IdType } from '@poinz/api';
import { DealType } from './deal';

export type DealCollectState = 'REDEEMED' | 'FAILED' | 'PURCHASED' | 'CANCELLED' | 'EXPIRED';

// eslint-disable-next-line
// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const DEAL_COLLECT_STATE: EnumTranslationKeys<DealCollectState> = {
  REDEEMED: 'deal.redeemed',
  FAILED: 'deal.failed',
  PURCHASED: 'deal.purchased',
  CANCELLED: 'deal.cancelled',
  EXPIRED: 'deal.expired'
};

export const DEAL_DELIVERY_STATE = keyMirror({
  IN_PROGRESS: null,
  SENT: null,
  DELIVERED: null
});

export type DealDeliveryState = keyof typeof DEAL_DELIVERY_STATE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const DEAL_DELIVERY_STATE_TRANSLATED: EnumTranslationKeys<DealCollectState> = {
  [DEAL_DELIVERY_STATE.IN_PROGRESS]: 'dealCollect.deliveryState.inProgress',
  [DEAL_DELIVERY_STATE.SENT]: 'dealCollect.deliveryState.sent',
  [DEAL_DELIVERY_STATE.DELIVERED]: 'dealCollect.deliveryState.delivered'
};

export const REFUND_TYPE = keyMirror({
  CASH: null,
  LOYALTY: null
});

export type RefundType = keyof typeof REFUND_TYPE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const REFUND_TYPE_TRANSLATIONS: EnumTranslationKeys<RefundType> = {
  [REFUND_TYPE.CASH]: 'dealCollect.refund.cash',
  [REFUND_TYPE.LOYALTY]: 'dealCollect.refund.swissLoyalty'
};

export const CANCELATION_TYPE = keyMirror({
  COVID: null,
  MISPURCHASE: null,
  DEAL_EXTENSION: null,
  DEAL_DESCRIPTION: null,
  TEST: null,
  REDEMPTION_IMPOSSIBLE: null,
  RESERVATION: null,
  QUALITY: null,
  REDEMPTION_PROCESS: null,
  READING_ERROR: null,
  DELIVERY_DELAY: null,
  PARTNER_IMPRESSION: null,
  OTHER: null
});

export type CancelationType = keyof typeof CANCELATION_TYPE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const CANCELATION_TYPE_TRANSLATIONS: EnumTranslationKeys<RefundType> = {
  [CANCELATION_TYPE.COVID]: 'dealCollect.cancelation.covid',
  [CANCELATION_TYPE.MISPURCHASE]: 'dealCollect.refund.mispurchase',
  [CANCELATION_TYPE.DEAL_EXTENSION]: 'dealCollect.refund.dealExtension',
  [CANCELATION_TYPE.DEAL_DESCRIPTION]: 'dealCollect.refund.dealDescription',
  [CANCELATION_TYPE.TEST]: 'dealCollect.refund.test',
  [CANCELATION_TYPE.REDEMPTION_IMPOSSIBLE]: 'dealCollect.refund.redemptionImpossible',
  [CANCELATION_TYPE.RESERVATION]: 'dealCollect.refund.reservation',
  [CANCELATION_TYPE.QUALITY]: 'dealCollect.refund.quality',
  [CANCELATION_TYPE.REDEMPTION_PROCESS]: 'dealCollect.refund.redemptionProcess',
  [CANCELATION_TYPE.READING_ERROR]: 'dealCollect.refund.readingError',
  [CANCELATION_TYPE.DELIVERY_DELAY]: 'dealCollect.refund.deliveryDelay',
  [CANCELATION_TYPE.PARTNER_IMPRESSION]: 'dealCollect.refund.partnerImpression',
  [CANCELATION_TYPE.OTHER]: 'dealCollect.refund.other'
};

export type OfflineRedeem = {
  locationId: IdType,
  redeemDate: string
};

export type DealCollect = {
  id: IdType,
  userId: IdType,
  purchaseDate: string,
  dealCollectState: DealCollectState,
  expirationDate: string,
  locationId: IdType,
  locationName: string,
  dealType: DealType,
  dealOptionName: string,
  discountedPrice: number,
  redeemDate: string,
  locations: Partial<Record<IdType, string>>,
  voucherId: IdType // voucher id for refund
};
