import { MultiLanguageString, defaultLanguage, Language } from '.';

export const selectMultiLanguage = (
  multiLanguageObject: MultiLanguageString,
  language: Language
): string => {
  const languages = Object.keys(multiLanguageObject);

  if (languages.includes(language)) {
    return multiLanguageObject[language];
  }
  if (languages.includes(defaultLanguage)) {
    return multiLanguageObject[defaultLanguage];
  }
  // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
  return Object.values(multiLanguageObject).find(v => !!v);
};
