import * as React from 'react';

interface SessionStorageItems<T> {
  key: string;
  initialValue?: T;
}

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;

function useSessionStorage<T extends boolean | string | number | number[] | string[]>({
  key,
  initialValue
}: SessionStorageItems<T>): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      return initialValue;
    }
  });

  const setValue = React.useCallback(
    (value: SetStateAction<T>) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        //
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
}

export default useSessionStorage;
