import {EnumTranslationKeys, MultiLanguageString} from 'serviceNew/locale';
import { keyMirror } from '@poinz/utils';

export const REDEMPTION_METHOD = keyMirror({
  DEFAULT: null,
  TIMER: null
});

export type RedemptionMethod = keyof typeof REDEMPTION_METHOD;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const REDEMPTION_METHOD_TRANSLATIONS: EnumTranslationKeys<RedemptionMethod> = {
  DEFAULT: 'deal.redemptionMethod.default',
  TIMER: 'deal.redemptionMethod.timer'
};

const DEFAULT_REDEMPTION_METHOD: RedemptionMethod = REDEMPTION_METHOD.DEFAULT;

export const REDEMPTION_TYPE = keyMirror({
  POINZ: null,
  BARCODE: null,
  ONLINE: null,
  NONE: null,
  DELIVERY: null
});

export type RedemptionType = keyof typeof REDEMPTION_TYPE;

export const DEFAULT_REDEMPTION_TYPE: RedemptionType = REDEMPTION_TYPE.POINZ;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const REDEMPTION_TYPE_TRANSLATIONS: EnumTranslationKeys<RedemptionType> = {
  BARCODE: 'deal.redemptionType.barcode',
  ONLINE: 'deal.redemptionType.onlineVoucher',
  POINZ: 'deal.redemptionType.poinz',
  NONE: 'deal.redemptionType.none',
  DELIVERY: 'deal.redemptionType.delivery'
};

export const BARCODE_TYPE = keyMirror({
  EAN13: null,
  EAN8: null,
  ITF: null,
  QRCODE: null,
  CODE39: null,
  AZTEC: null,
  CODE128: null,
  UPCA: null,
  PDF417: null
});

export type BarCodeType = keyof typeof BARCODE_TYPE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const BARCODE_TYPE_TRANSLATIONS: EnumTranslationKeys<BarCodeType> = {
  EAN13: 'deal.barcodeType.EAN13',
  EAN8: 'deal.barcodeType.EAN8',
  ITF: 'deal.barcodeType.ITF',
  QRCODE: 'deal.barcodeType.QRCODE',
  CODE39: 'deal.barcodeType.CODE39',
  AZTEC: 'deal.barcodeType.AZTEC',
  CODE128: 'deal.barcodeType.CODE128',
  UPCA: 'deal.barcodeType.UPCA',
  PDF417: 'deal.barcodeType.PDF417'
};

export type DealOptionToken = {
  dealOptionId: string,
  dealOptionName: MultiLanguageString,
  tokens: string
};

export class DealRedemptionCode {
  isUnique: boolean | null | undefined;

// @ts-expect-error - TS2564 - Property 'numberOfAvailableTokens' has no initializer and is not definitely assigned in the constructor.
  numberOfAvailableTokens: number;

// @ts-expect-error - TS2564 - Property 'numberOfTokens' has no initializer and is not definitely assigned in the constructor.
  numberOfTokens: number;

// @ts-expect-error - TS2564 - Property 'redemptionType' has no initializer and is not definitely assigned in the constructor.
  redemptionType: RedemptionType;

// @ts-expect-error - TS2564 - Property 'redemptionMethod' has no initializer and is not definitely assigned in the constructor.
  redemptionMethod: RedemptionMethod;

  barcodeType: BarCodeType | null | undefined;

  token: string | null | undefined;

  tokens: string[] | null | undefined;

  dealOptionTokens: DealOptionToken[] | null | undefined;

  constructor(redemptionCode: Record<any, any>) {
    Object.assign(this, redemptionCode);
  }

  get isDefaultType() {
    return this.redemptionType === DEFAULT_REDEMPTION_TYPE;
  }
}

export const REDEMPTION_TYPES_WITH_TIMER = new Set<RedemptionType>([
  REDEMPTION_TYPE.NONE,
  REDEMPTION_TYPE.ONLINE,
  REDEMPTION_TYPE.BARCODE
]);

export const REDEMPTION_TYPES_WITH_CODES = new Set<RedemptionType>([
  REDEMPTION_TYPE.ONLINE,
  REDEMPTION_TYPE.BARCODE
]);

export function isRedemptionTypeWithCodes(redemptionType: RedemptionType) {
  return redemptionType ? REDEMPTION_TYPES_WITH_CODES.has(redemptionType) : false;
}

export function isRedemptionTypeWithTimer(redemptionType: RedemptionType) {
  return redemptionType ? REDEMPTION_TYPES_WITH_TIMER.has(redemptionType) : false;
}

export function isDefaultRedemptionType(redemptionCode: DealRedemptionCode) {
  const { redemptionType, redemptionMethod } = redemptionCode;
  return (
    redemptionType === DEFAULT_REDEMPTION_TYPE && redemptionMethod === DEFAULT_REDEMPTION_METHOD
  );
}
