import mapValues from 'lodash/mapValues';

import { NewPicture, PictureUpdate, OrderedPictureUpdate } from 'serviceNew/model/common';

export function mapPicture(picture: NewPicture) {
  const { x, y, width, height, file } = picture;
  return {
    fileName: file && file.name,
    x,
    y,
    width,
    height
  };
}

type MapImageListParams<V extends PictureUpdate | OrderedPictureUpdate> = {
  imageList: V[];
  multiPartData: Array<{
    name: string;
    data: any;
  }>;
};
function mapImageList(params: MapImageListParams<OrderedPictureUpdate>) {
  const { imageList, multiPartData } = params;
  const pictureData: Array<File | any> = [];
  const result = imageList.map((image: OrderedPictureUpdate, idx: number) => {
    // @ts-expect-error - TS2339 - Property 'file' does not exist on type 'OrderedPictureUpdate'.
    if (image.file != null) {
      const {
        id,
        // @ts-expect-error - TS2339 - Property 'x' does not exist on type 'OrderedPictureUpdate'.
        x,
        // @ts-expect-error - TS2339 - Property 'y' does not exist on type 'OrderedPictureUpdate'.
        y,
        width,
        height,
        // @ts-expect-error - TS2339 - Property 'file' does not exist on type 'OrderedPictureUpdate'.
        file
      } = image;
      // @ts-expect-error - TS2339 - Property 'file' does not exist on type 'OrderedPictureUpdate'.
      pictureData.push(image.file);
      return {
        id,
        x,
        y,
        width,
        height,
        fileName: file.name,
        index: idx
      };
    }
    const { id } = image;
    return { id, index: idx };
  });
  multiPartData.push({ name: 'pictures', data: pictureData });
  return result;
}

type MapParams<K extends string, F extends Partial<Record<K, any>>> = {
  form: F;
  imageFields?: K[];
  imageListFields?: K[];
  multiPartData: Array<{
    name: string;
    data: File;
  }>;
};
export function mapFormWithImages<K extends string, F extends Partial<Record<K, any>>>(
  params: MapParams<K, F>
) {
  const { form, imageFields, imageListFields, multiPartData } = params;
  return mapValues(form, (val, key) => {
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'K'.
    if (imageFields && imageFields.includes(key) && val != null && val.file) {
      multiPartData.push({ name: key, data: val.file });
      return { ...val, fileName: val.file.name };
    }
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'K'. | TS2358 - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
    if (imageFields && imageFields.includes(key) && val != null && val instanceof File) {
      multiPartData.push({ name: key, data: val });
      return { ...val, fileName: val.name };
    }
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'K'.
    if (imageListFields && imageListFields.includes(key) && val != null) {
      return mapImageList({ imageList: val, multiPartData });
    }
    return val;
  });
}

export const GIFT_CARD_IMAGE_VALID_DIMENSIONS = {
  width: 700,
  height: 442
} as const;
export const EXPLORE_IMAGE_VALID_DIMENSIONS = {
  width: 600,
  height: 600
} as const;

export const GIFT_CARD_IMAGE_MAX_SIZE = 1048576; // 1MB in bytes
export const EXPLORE_IMAGE_MAX_SIZE = 524288; // 500kb in bytes
export const getBytesFromMegabytes = (mb: number) => mb / 1024 / 1024;

export const GIFT_CARD_IMAGE_SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'] as const;
export const EXPLORE_IMAGE_SUPPORTED_FORMATS = ['image/jpeg'] as const;

function mimeToExtension(mime: string) {
  const parts = mime.split('/');
  if (parts.length > 1) {
    return parts[1];
  }

  if (mime.includes('.')) {
    return mime;
  }
  return '???';
}

export function getFormats(accept: string | string[]) {
  if (!accept) {
    return '*';
  }
  if (typeof accept === 'string') {
    return mimeToExtension(accept);
  }
  return accept.map(mimeToExtension).join(', ');
}
