import {IdType} from '@poinz/api';
import { keyMirror } from 'util/ObjectHelper';

export const CODE_TYPE = keyMirror({
  TAC: null,
  MUC: null,
  SBC: null,
  OTC: null,
  COC: null
});

export type CodeType = keyof typeof CODE_TYPE;

export const CODE_TYPE_TRANSLATIONS = {
  [CODE_TYPE.TAC]: 'code.takeAwayCode',
  [CODE_TYPE.MUC]: 'code.multipleUsageCode',
  [CODE_TYPE.SBC]: 'code.standbyCode',
  [CODE_TYPE.OTC]: 'code.onetimeCode',
  [CODE_TYPE.COC]: 'code.correctionCode'
} as const;

export const ALL_CODE_TYPE_TRANSLATIONS = {
  ...CODE_TYPE_TRANSLATIONS,
  POS: 'code.posDeviceCode',
  API: 'code.apiCode',
  MRC: 'code.messageRewardCode',
  LOC: 'code.locationCode',
  OFF: 'code.offline',
  APP_OFFLINE: 'code.app.offline',
  IN_APP: 'code.inapp'
} as const;

export const TAC_TYPE = {
  FLYER: 'FLYER',
  POSTER: 'POSTER',
  TABLE_TENT: 'TABLE_TENT',
  TABLE_STICKER: 'TABLE_STICKER',
  FACEBOOK: 'FACEBOOK',
  MENU: 'MENU',
  WEBSITE: 'WEBSITE',
  IN_APP: 'IN_APP',
  OTHER: 'OTHER',
  TRIGGER_MSG: 'TRIGGER_MSG',
  NEWSLETTER: 'NEWSLETTER',
  LANDINGPAGE: 'LANDINGPAGE',
  ROLL_UP: 'ROLL_UP',
  PAPER_CARD: 'PAPER_CARD',
  PROMO_BOX: 'PROMO_BOX',
  MESSAGE_PROMO: 'MESSAGE_PROMO'
} as const;

export type TacType = keyof typeof TAC_TYPE;

export const TAC_TYPE_TRANSLATIONS = {
  FLYER: 'tacType.flyer',
  POSTER: 'tacType.poster',
  TABLE_TENT: 'tacType.tableTent',
  TABLE_STICKER: 'tacType.tableSticker',
  FACEBOOK: 'tacType.facebook',
  MENU: 'tacType.menu',
  WEBSITE: 'tacType.website',
  IN_APP: 'tacType.inApp',
  OTHER: 'tacType.other',
  TRIGGER_MSG: 'tacType.triggerMsg',
  NEWSLETTER: 'tacType.newsletter',
  LANDINGPAGE: 'tacType.landingPage',
  ROLL_UP: 'tacType.rollUp',
  PAPER_CARD: 'tacType.paperCard',
  PROMO_BOX: 'tacType.promoBox',
  MESSAGE_PROMO: 'tac.messagePromo'
} as const;

export class Code {
// @ts-expect-error - TS2564 - Property 'created' has no initializer and is not definitely assigned in the constructor.
  created: string;

// @ts-expect-error - TS2564 - Property 'modified' has no initializer and is not definitely assigned in the constructor.
  modified: string;

// @ts-expect-error - TS2564 - Property 'id' has no initializer and is not definitely assigned in the constructor.
  id: IdType;

  locationId: IdType | null | undefined;

// @ts-expect-error - TS2564 - Property 'offeringId' has no initializer and is not definitely assigned in the constructor.
  offeringId: IdType;

// @ts-expect-error - TS2564 - Property 'codeGroupId' has no initializer and is not definitely assigned in the constructor.
  codeGroupId: IdType;

// @ts-expect-error - TS2564 - Property 'value' has no initializer and is not definitely assigned in the constructor.
  value: number;

// @ts-expect-error - TS2564 - Property 'codeType' has no initializer and is not definitely assigned in the constructor.
  codeType: CodeType;

// @ts-expect-error - TS2564 - Property 'lockPeriod' has no initializer and is not definitely assigned in the constructor.
  lockPeriod: number;

// @ts-expect-error - TS2564 - Property 'codeLink' has no initializer and is not definitely assigned in the constructor.
  codeLink: string;

// @ts-expect-error - TS2564 - Property 'inAppLink' has no initializer and is not definitely assigned in the constructor.
  inAppLink: string;

  constructor(code: Record<any, any>) {
    Object.assign(this, code);
  }
}

export function getPossibleValueOptions(maxValue: number): any {
  return [...Array(maxValue).keys()].map(v => ({
    label: { key: 'text.no.translation', context: { value: (v + 1).toString() } },
    value: (v + 1).toString()
  }));
}
