export function popLastPath(path: string): string {
  return path.replace(/\/[^/]+(\/)*$/, '');
}

export function notEmptyString(input: string): boolean {
  return !!input;
}

export function trimElements(input: string | string[]): string | string[] {
  if (!input) {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map<string>(s => s.trim());
  }
  return input.trim();
}

export function formatPrice(price: number, showChf = false): string {
  const formattedPrice = Number.isInteger(price) ? `${price}.–` : price.toFixed(2);
  return showChf ? `CHF ${formattedPrice}` : formattedPrice;
}

export function parseEntityIdFromRequestParameters(input: string, entityName: string) {
  const searchParams = new URLSearchParams(input);
  const id = parseInt(searchParams.get(entityName) || '', 10);
  return Number.isNaN(id) ? undefined : id;
}

export function getEntityFromRequestParameters(input: string, entityName: string): string | null {
  const searchParams = new URLSearchParams(input);
  return searchParams.get(entityName);
}

export function stringToHslColor(str: string, s = 40, l = 60): string {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export const formatCashbackValue = (value: number, type: 'AMOUNT' | 'PERCENTAGE', numOfDecimalPlaces = 2) => {
  if (value && type === 'PERCENTAGE') {
    if(numOfDecimalPlaces) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return `${value * 100}%`
  }
  if (value && type === 'AMOUNT') {
    if(numOfDecimalPlaces) {
      return `${value.toFixed(2)} CHF`;
    }
    return `${value} CHF`;
  }
  return value;
};
