import * as React from 'react';
import { Avatar, Menu, MenuItem, ButtonBase, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'ContextRouter'.
import { withRouter, ContextRouter } from 'react-router';
import { AdminUser } from 'serviceNew/model/adminUser';
import routes from 'App/routing';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { signOut } from 'serviceNew/firebase/auth';
import { stringToHslColor } from '@poinz/utils';

import TranslatedText from '../../TranslatedText';

const useStyles = makeStyles({
  avatar: {
    // @ts-expect-error - TS2339 - Property 'color' does not exist on type '{}'.
    backgroundColor: ({ color }) => color
  },
  button: {
    borderRadius: '50%'
  }
});

type Props = ContextRouter & {
  children: React.ReactNode;
};

function getInitials(adminUser: AdminUser) {
  const { firstName, lastName } = adminUser;
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
}

function UserMenu(props: Props) {
  const { history, children } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { adminUser } = useAdminUser();
  const { firstName, lastName } = adminUser || {};
  const classes = useStyles({ color: stringToHslColor(`${firstName} ${lastName}`, 60, 40) });

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAccountSettings() {
    history.push(routes.accountSettings.route);
    handleClose();
  }

  function renderMenu() {
    return (
      <Menu
        id="user-menu"
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        // @ts-expect-error - TS2322 - Type '{ children: Element[]; anchorEl: HTMLElement | null; id: string; open: boolean; onClose: () => void; anchorOrigin: { vertical: "bottom"; horizontal: "right"; }; getContentAnchorEl: null; }' is not assignable to type 'IntrinsicAttributes & MenuProps'.
        getContentAnchorEl={null}
        {...{ anchorEl }}
      >
        <MenuItem>{children}</MenuItem>
        <Divider />
        <MenuItem onClick={handleAccountSettings}>
          <TranslatedText text={{ key: 'userMenu.accountSettings' }} />
        </MenuItem>
        <MenuItem onClick={signOut}>
          <TranslatedText text={{ key: 'general.logout' }} />
        </MenuItem>
      </Menu>
    );
  }

  if (!adminUser) {
    return null;
  }

  return (
    <>
      <ButtonBase className={classes.button} onClick={event => setAnchorEl(event.currentTarget)}>
        <Avatar className={classes.avatar}>{getInitials(adminUser)}</Avatar>
      </ButtonBase>
      {renderMenu()}
    </>
  );
}

export default withRouter(UserMenu);
