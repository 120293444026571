import { createPartnerClient } from '@poinz/api';
import { setCampAuthenticatedHeader, apiCampAgent } from './api';
import { localeSubject } from 'App/providers/LocaleProvider';

const parameters = {
  agent: apiCampAgent,
  language: localeSubject.getValue(),
  environment: process.env.REACT_APP_ENVIRONMENT,
  setAuthenticatedHeaders: setCampAuthenticatedHeader
} as const;

const apiNew = createPartnerClient(parameters);

localeSubject.subscribe(val => apiNew.changeLanguage(val));

export default apiNew;
