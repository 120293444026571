import { PoinzApiClient } from '../../api';
import { AdminUser } from '../user/types';
import { ContractCompany, ContractCompanySearchCandidate } from './types';
import { ROOT } from './config';

const VERSION = '/v2';
const CONTRACT_COMPANIES = '/contract-companies';

const apiRoot = `${ROOT}${VERSION}${CONTRACT_COMPANIES}`;

const searchContractCompany =
  (apiClient: PoinzApiClient) =>
  async (query: string): Promise<ContractCompanySearchCandidate[]> => {
    const result = await apiClient.get(`${apiRoot}/search`, {
      queryParams: { name: query }
    });
    return result.body;
  };

const getListForAdminUser =
  (apiClient: PoinzApiClient) =>
  async (adminUserId: number): Promise<ContractCompany[]> => {
    const result = await apiClient.get(apiRoot, {
      queryParams: { adminUserId }
    });
    return result.body;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<ContractCompany> => {
    const result = await apiClient.get(`${apiRoot}/:id`, { pathParams: { id } });
    return result.body;
  };

const getUsers =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<AdminUser[]> => {
    const result = await apiClient.get(`${apiRoot}/:id/admin-users`, { pathParams: { id } });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  getListForAdminUser: getListForAdminUser(apiClient),
  getUsers: getUsers(apiClient),
  searchContractCompany: searchContractCompany(apiClient)
});
