import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import clsx from 'clsx';
import Text from './Text';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 8,
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[300],
    flexWrap: 'nowrap',
    display: 'flex',
  },
  label: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1, 2),
    borderRadius: 8,
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[300],
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  selected: {
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.primary.light,
  },
}));

type TabObject<T> = {
  key: T,
  label: TranslationObject
};

type Props<T> = {
  className?: string,
  onPress: (arg1: T) => void,
  tabs: TabObject<T>[],
  selectedTab: T
};

function SelectionTab<T extends string>(props: Props<T>) {
  const { className, tabs, onPress, selectedTab } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      {tabs.map(tab => (
        <button
          key={tab.key}
          type="button"
          onClick={() => onPress(tab.key)}
          className={clsx(classes.label, selectedTab === tab.key && classes.selected)}
        >
          <Text variant="caption">{tab.label.key}</Text>
        </button>
      ))}
    </div>
  );
}

export default SelectionTab;
