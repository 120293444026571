import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { TranslationObject } from 'serviceNew/locale';

import { Text, RawText, FieldLabel, Button } from 'App/components';
import ButtonBase from '@mui/material/ButtonBase';
import { FieldRenderProps, withFieldAdapter } from '../Field';

import Icon from '../Icon';
import FormError from '../FormError';
import { Theme } from '@mui/material';

type Props = {
  disabled: boolean;
  label: TranslationObject;
  textHint: TranslationObject;
  textHintUploaded: TranslationObject;
  required: boolean;
  accept: string;
  maxSize: number;
  className: string;
} & FieldRenderProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  outerButton: {
    padding: 0,
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'Theme'.
    border: theme.border,
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      borderColor: theme.palette.common.black
    }
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageUpload: {
    display: 'flex',
    height: 200,
    width: '100%',
    alignItems: 'stretch',
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    flex: 1,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    padding: theme.extra.spacing.small,
    textTransform: 'none'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    '&:hover': {
      opacity: 1
    },
    color: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    padding: theme.extra.spacing.small
  }
}));

function ImageField(props: Props) {
  const [uploadError, setUploadError] = React.useState<TranslationObject | null | undefined>(
    undefined
  );

  const {
    input,
    meta,
    label,
    textHint,
    textHintUploaded,
    disabled,
    accept,
    maxSize,
    required,
    className
  } = props;
  const { touched, error } = meta;
  const classes = useStyles();

  const onError = () => {
    setUploadError({ key: 'imageUpload.rejected' });
  };

  const onImageDrop = (fileToUpload: File[]) => {
    setUploadError(null);
    const file = fileToUpload[0];
    // @ts-expect-error - TS2339 - Property 'preview' does not exist on type 'File'.
    file.preview = URL.createObjectURL(file);
    input.onChange(file);
  };

  const onClearImage = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    input.onChange(null);
  };

  const renderError = () => {
    if (uploadError) {
      return (
        <Text variant="body1" context={uploadError.context} color="error">
          {uploadError.key}
        </Text>
      );
    }
    return null;
  };

  let backgroundImage;
  if (input.value) {
    backgroundImage = `url('${input.value.preview || input.value}')`;
  }

  const backgroundStyle = {
    backgroundImage
  } as const;

  const dropzoneClasses = [classes.imageUpload];
  if (input.value) {
    dropzoneClasses.push('uploaded');
  }
  if (disabled) {
    dropzoneClasses.push('mdc-tet-field--disabled');
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: onImageDrop,
    onDropRejected: onError,
    maxSize,
    multiple: false,
    disabled,
    // @ts-expect-error - TS2345 - Argument of type '{ accept: any; onDropAccepted: (fileToUpload: File[]) => void; onDropRejected: () => void; maxSize: any; multiple: false; disabled: any; name: any; }' is not assignable to parameter of type 'DropzoneOptions'.
    name: label
  });

  return (
    <div className={[classes.outerContainer, className].join(' ')}>
      <FieldLabel {...{ label, required }} />
      <ButtonBase component="div" className={classes.outerButton}>
        <div
          {...getRootProps({
            className: dropzoneClasses.join(' ')
          })}
        >
          <input {...getInputProps()} />
          <div className={classes.container} style={backgroundStyle}>
            {input.value ? (
              <div className={classes.overlay}>
                <RawText variant="body1" color="inherit">
                  {input.value.name}
                </RawText>
                <Text color="inherit" context={textHintUploaded.context}>
                  {textHintUploaded.key}
                </Text>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClearImage}
                  label={{ key: 'imageUpload.removeImage' }}
                  icon="delete"
                  onMouseDown={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
            ) : (
              <>
                <Icon size={50} icon="image" />
                {renderError()}
                <Text color="textSecondary" context={textHint.context}>
                  {textHint.key}
                </Text>
              </>
            )}
          </div>
        </div>
      </ButtonBase>
      <FormError {...{ touched, error }} />
    </div>
  );
}

ImageField.defaultProps = {
  className: ''
};

export default withFieldAdapter(ImageField);
