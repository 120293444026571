import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import Button from '../Button';

type Props = {
  title: string | null | undefined;
  message: string | null | undefined;
};

export const ErrorPageUnconnected = ({ title, message }: Props) => {
  const history = useHistory();

  const pushHome = React.useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <div>
      <div>
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <Typography variant="title">{title || 'Error'}</Typography>
      </div>
      <div style={{ paddingTop: 12 }}>
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <Typography variant="body">{message || 'Something went wrong'}</Typography>
      </div>
      <div style={{ paddingTop: 12 }}>
        <Button
          onClick={pushHome}
          color="primary"
          label={{ key: 'text.no.translation', context: { value: 'Go to Home' } }}
        />
      </div>
    </div>
  );
};

export default ErrorPageUnconnected;
