import React from 'react';
import { useLocale } from 'App/providers/LocaleProvider';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import dealStatsApi from 'serviceNew/api/dealStats';
import { DealOverviewStats } from 'serviceNew/model/dealStats';
import { DEAL_TYPE } from 'serviceNew/model/deal';
import { reduceForProperty } from 'util/ObjectHelper';
import Table from 'App/components/Tables/Table';
import { SignalStateDisplay } from 'App/components';
import RoundImageWithLabels from 'App/components/RoundImageWithLabels';
import { formatPrice } from '@poinz/utils';
import moment from 'moment';
import routes from 'App/routing';
import useNavigation from 'serviceNew/navigation/useNavigation';
import { useAdminUser } from 'App/providers/AdminUserProvider';

type Props = {
  selectedTab: typeof DEAL_TYPE.COUPON | typeof DEAL_TYPE.DEAL;
};

function DealStatsOverview({ selectedTab }: Props) {
  const { parseMultilanguageString } = useLocale();
  const { isPoinzAdmin } = useAdminUser();

  const { businessProfileId, businessProfiles, selectBusinessProfile } = useBusinessProfile();

  const [dealStatsList, setDealStatsList] = React.useState<DealOverviewStats[]>([]);
  const { push } = useNavigation();

  React.useEffect(() => {
    if (!businessProfileId) {
      selectBusinessProfile(businessProfiles[0]?.id);
    }
  }, [businessProfileId, businessProfiles, selectBusinessProfile]);

  React.useEffect(() => {
    async function fetchDealOverviewStats() {
      const dealStatsOverview = await dealStatsApi.getDealOverviewStats(
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        businessProfileId,
        selectedTab
      );
      setDealStatsList(dealStatsOverview);
    }
    if (businessProfileId) {
      fetchDealOverviewStats();
    }
  }, [businessProfileId, selectedTab]);

  const getDealRuleSignalState = (startDate: any, endDate: any, expirationDate: any) => {
    const endDateObj = new Date(endDate);
    const startDateObj = new Date(startDate);
    const expirationDateObj = new Date(expirationDate);
    const now = new Date();

    if (now.getTime() < endDateObj.getTime()) {
      return now.getTime() >= startDateObj.getTime() ? 'GREEN' : 'YELLOW';
    }
    if (now.getTime() < expirationDateObj.getTime()) {
      return 'YELLOW';
    }
    return 'GREY';
  };

  const getDealSignalState = (dealRuleStats: any) => {
    const states = dealRuleStats.map(dealRule =>
      getDealRuleSignalState(dealRule?.startDate, dealRule?.endDate, dealRule?.expirationDate)
    );
    if (states.includes('GREEN')) {
      return 'GREEN';
    }
    if (states.includes('YELLOW')) {
      return 'YELLOW';
    }
    return 'GREY';
  };

  function renderDealRuleTable({ data }) {
    return (
      <Table
        headerSeparator
        // @ts-expect-error - TS2322 - Type '({ dealRuleId }: any) => void' is not assignable to type '(arg1: any) => Promise<undefined> | undefined'.
        onClick={({ dealRuleId }) =>
          push({
            path: routes.dashboard.deals[':id'].dealRules[':dealRuleId'].getRoute({
              id: data.dealId,
              dealRuleId
            }),
            state: { dealStatOverview: data }
          })
        }
        data={data.dealRuleStats}
        columns={[
          {
            id: 'status',
            dataMapper: ({ startDate, endDate, expirationDate }) => (
              <SignalStateDisplay
                size={16}
                state={getDealRuleSignalState(startDate, endDate, expirationDate)}
              />
            ),
            flex: 0.2
          },
          {
            header: { key: 'general.active' },
            flex: 0.5,
            align: 'left',
            text: ({ startDate, endDate }) => ({
              key: 'text.no.translation',
              context: {
                value: `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`
              }
            })
          },
          {
            header: { key: 'deal.expires' },
            flex: 0.5,
            align: 'left',
            text: ({ expirationDate }) => ({
              key: 'text.no.translation',
              context: { value: moment(expirationDate).format('L') }
            })
          },
          {
            header: { key: selectedTab === DEAL_TYPE.COUPON ? 'deal.collects' : 'deal.purchases' },
            flex: 0.5,
            align: 'left',
            dataMapper: 'numPurchased'
          },
          {
            header: { key: 'deal.redemptions' },
            flex: 0.5,
            align: 'left',
            dataMapper: 'numRedeemed'
          },
          // @ts-expect-error - TS2322 - Type '{ header: { key: string; }; flex: number; align: string; text: ({ commissionFee }: { commissionFee: any; }) => { key: string; context: { value: string; }; } | { key: string; context?: undefined; }; }' is not assignable to type 'TableColumn'.
          ...(selectedTab !== DEAL_TYPE.COUPON
            ? [
                {
                  header: { key: 'deal.commissionPoinz' },
                  flex: 0.5,
                  align: 'left',
                  text: ({ commissionFee }) =>
                    commissionFee
                      ? {
                          key: 'text.no.translation',
                          context: { value: `${commissionFee}%` }
                        }
                      : { key: 'stats.deals.noCommissionFee' }
                }
              ]
            : [])
        ]}
      />
    );
  }

  function renderTable() {
    return (
      <Table
        data={dealStatsList}
        DetailComponent={renderDealRuleTable}
        columns={[
          {
            id: 'status',
            dataMapper: ({ dealRuleStats }) => (
              <SignalStateDisplay size={16} state={getDealSignalState(dealRuleStats)} />
            ),
            flex: 0.5
          },
          {
            header: { key: 'general.id' },
            dataMapper: 'dealId',
            align: 'left',
            flex: 0.5
          },
          {
            flex: 3,
            align: 'left',
            header: { key: 'deal.deal' },
            dataMapper: ({ picture, name }) => (
              <RoundImageWithLabels
                logo={picture?.sourceUrl}
                size={60}
                // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
                title={parseMultilanguageString(name)}
                titleVariant="subtitle2"
              />
            )
          },
          {
            header: { key: selectedTab === DEAL_TYPE.COUPON ? 'deal.collects' : 'deal.purchases' },
            dataMapper: 'numPurchased',
            totalRow: {
              key: 'text.no.translation',
              context: { value: reduceForProperty(dealStatsList, 'numPurchased') }
            }
          },
          {
            header: { key: 'deal.redeemed' },
            dataMapper: 'numRedeemed',
            totalRow: {
              key: 'text.no.translation',
              context: { value: reduceForProperty(dealStatsList, 'numRedeemed') }
            }
          },
          ...(selectedTab !== DEAL_TYPE.COUPON && isPoinzAdmin
            ? [
                {
                  header: { key: 'deal.revenue' },
                  totalRow: {
                    key: 'text.no.translation',
                    context: {
                      value: formatPrice(
                        reduceForProperty(dealStatsList, 'revenueRedeemed') || 0,
                        true
                      )
                    }
                  },
                  text: ({ revenueRedeemed }) => ({
                    key: 'text.no.translation',
                    context: { value: formatPrice(revenueRedeemed || 0, true) }
                  })
                }
              ]
            : [])
        ]}
      />
    );
  }

  return renderTable();
}

export default DealStatsOverview;
