import {IdType} from '@poinz/api';
import { TranslationKey, MultiLanguageString } from 'serviceNew/locale';
import { keyMirror } from 'util/ObjectHelper';

export type IdentifierPicture = {
  key: string,
  url: string
};

export const DEAL_TYPE = keyMirror({
  DEAL: null,
  COUPON: null,
  COUPON_MSG: null
});

export type DealType = keyof typeof DEAL_TYPE;

export type LocationMode = 'ALL' | 'CUSTOM' | 'NONE';

export const DEAL_CATEGORY = keyMirror({
  FOOD: null,
  SHOPPING_SERVICES: null,
  LEISURE: null,
  TRAVEL: null,
  PRODUCT: null,
  WELLNESS_BEAUTY: null,
  COUPON: null,
  ONLINE: null
});

export type DealCategory = keyof typeof DEAL_CATEGORY;

export type DiscountType = 'PERCENTAGE' | 'AMOUNT' | 'AMOUNT_PER_UNIT' | 'CUSTOM';

export type DiscountInfo = {
  description: MultiLanguageString,
  type: DiscountType
};

export const DISCOUNT_UNIT = {
  CHF: 'general.chf',
  RAPPEN: 'general.rappen'
} as const;

export const UNIT = {
  LITER: 'general.liter'
} as const;

export const DISCOUNT_TYPE: Partial<Record<DiscountType, TranslationKey>> = {
  PERCENTAGE: 'coupon.discount.percentage',
  AMOUNT: 'coupon.discount.amount',
  AMOUNT_PER_UNIT: 'coupon.discount.amount.per.unit',
  CUSTOM: 'coupon.discount.custom'
};

export const DEAL_CATEGORY_TRANSLATED: Partial<Record<DealCategory, TranslationKey>> = {
  [DEAL_CATEGORY.FOOD]: 'category.food',
  [DEAL_CATEGORY.SHOPPING_SERVICES]: 'category.shopping_services',
  [DEAL_CATEGORY.LEISURE]: 'category.leisure',
  [DEAL_CATEGORY.WELLNESS_BEAUTY]: 'category.wellnessBeauty',
  [DEAL_CATEGORY.TRAVEL]: 'category.travel',
  [DEAL_CATEGORY.PRODUCT]: 'category.product',
  [DEAL_CATEGORY.ONLINE]: 'category.online',
  [DEAL_CATEGORY.COUPON]: 'category.coupon'
};

export const SPECIAL_DEAL_CATEGORIES = [DEAL_CATEGORY.ONLINE, DEAL_CATEGORY.COUPON] as const;

export const DEAL_TYPE_TRANSLATIONS: Partial<Record<DealType, TranslationKey>> = {
  DEAL: 'deal.deal',
  COUPON: 'deal.coupon',
  COUPON_MSG: 'deal.couponMsg'
};

export const LOCATION_MODE: Partial<Record<LocationMode, TranslationKey>> = {
  ALL: 'location.mode.all',
  CUSTOM: 'location.mode.custom',
  NONE: 'location.mode.none'
};

export type ProvisionCalculation = {
  distributions: Array<{
    consultant: IdType,
    share: number
  }>
};
