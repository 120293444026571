import * as React from 'react';
import { Formik } from 'formik';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import Table from '../Table';
import Button from '../Button';
import NumberField from './NumberField';
import TextSelectField from './TextSelectField';
import FormRow from '../FormRow';
import FieldComp, { withFieldAdapter, FieldRenderProps } from '../Field';
import FormError from '../FormError';

const styles = createStyles(theme => ({
  flex: {
    flex: 1
  },
  dialog: {
    ...theme.mainComponents.modal
  }
}));

type FieldType = 'NUMBER' | 'TEXT_SELECT';

type Field<T extends string> = {
  name: T;
  label: TranslationObject;
  type: FieldType;
  conditionalProps: (values: Partial<Record<T, any>>) => Record<any, any>;
};

type Props<T> = {
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string'.
  fields: Field<T>[];
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
  initialValues: Partial<Record<T, any>>;
} & FieldRenderProps &
  WithStyles<typeof styles>;

function ArrayField<T extends string>(props: Props<T>) {
  const { input, meta, name, form, fields, classes, initialValues } = props;
  const { touched, error } = meta;
  const [isAdding, setAdding] = React.useState(false);

  const safeInputValue = input.value || [];

  const closeDialog = () => {
    setAdding(false);
    input.onBlur();
  };

  const openDialog = () => {
    setAdding(true);
    // input.onFocus();
  };

  const handleSubmit = (values: any) => {
    form.setFieldValue(name, [...safeInputValue, values]);
    closeDialog();
  };

  const handleDelete = (value: any) => {
    // input.onFocus();
    form.setFieldValue(
      name,
      safeInputValue.filter((d, idx) => value.idx !== idx)
    );
    input.onBlur();
  };

  const renderTable = () => (
    <Table
      columns={fields.map(f => ({
        header: f.label,
        numeric: f.type === 'NUMBER',
        dataMapper: f.name
      }))}
      data={safeInputValue.map((v, idx) => ({ ...v, idx }))}
      noPaper
      actions={[{ icon: 'delete', action: handleDelete }]}
      onAdd={openDialog}
    />
  );

  const renderField =
    (values: any) =>
    ({ type, conditionalProps, ...restFieldProps }) => {
      const fieldProps = {
        className: classes.flex,
        ...restFieldProps,
        ...(conditionalProps ? conditionalProps(values) : {})
      } as const;
      let innerField;
      switch (type) {
        case 'NUMBER':
          innerField = <FieldComp component={NumberField as any} {...fieldProps} />;
          break;
        case 'TEXT_SELECT':
          innerField = <FieldComp component={TextSelectField} {...fieldProps} />;
          break;
        default:
          innerField = null;
      }
      return <FormRow>{innerField}</FormRow>;
    };

  const renderForm = () => (
    <Dialog open={isAdding} onClose={closeDialog}>
      {/* @ts-expect-error - TS2322 - Type '{ children: ({ handleSubmit: handleSubmitForm, values, invalid }: FormikProps<any>) => Element; isUpdate: true; initialValues: any; onSubmit: any; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Formik<any>> & Pick<Readonly<FormikConfig<any>>, "children" | ... 7 more ... | "validate"> & InexactPartial<...> & InexactPartial<...>'. */}
      <Formik onSubmit={handleSubmit} {...{ initialValues }} isUpdate>
        {/* @ts-expect-error - TS2339 - Property 'invalid' does not exist on type 'FormikSharedConfig<{}> & FormikState<any> & FormikActions<any> & FormikHandlers & FormikComputedProps<any> & FormikRegistration'. */}
        {({ handleSubmit: handleSubmitForm, values, invalid }) => (
          <>
            <form onSubmit={handleSubmitForm}>
              <DialogContent>{fields.map(renderField(values))}</DialogContent>
              <DialogActions>
                <Button disabled={invalid} type="submit" label={{ key: 'general.add' }} />
              </DialogActions>
            </form>
          </>
        )}
      </Formik>
    </Dialog>
  );

  return (
    <>
      {renderForm()}
      <div className={classes.flex}>
        {renderTable()}
        <FormError {...{ touched, error }} />
      </div>
    </>
  );
}

export default withStyles(styles)(React.memo(withFieldAdapter(ArrayField)));
