import { DealType } from 'partnerClient/backend/types';
import { ScaledImage } from 'types';

export enum WALLET_ASSET_TYPE {
  'DEAL' = 'DEAL',
  'CARD' = 'CARD',
  'COUPON' = 'COUPON'
}

export enum REDEMPTION_TYPE {
  BARCODE = 'BARCODE',
  ONLINE = 'ONLINE',
  DELIVERY = 'DELIVERY',
  POINZ = 'POINZ',
  NONE = 'NONE'
}

export enum REDEMPTION_METHOD {
  DEFAULT = 'DEFAULT',
  TIMER = 'TIMER'
}

export interface IWalletAssset {
  id: number;
  additionalInfo?: string;
  additionalPictures?: ScaledImage[];
  businessProfileName: string;
  currentCounter: number;
  currentMaxDeals: number;
  dealCategory: string;
  description: string;
  dynamicLink?: string;
  endDateTime: string;
  expirationDate: string;
  facebookId?: string;
  instagramId?: string;
  logo: string;
  logoBackgroundColor?: string;
  mainColor: string;
  name: string;
  orderLink?: string;
  picture: ScaledImage;
  pictureMainColor: string;
  redemptionType: REDEMPTION_TYPE;
  redemptionMethod: REDEMPTION_METHOD;
  redeemDate: string | null;
  redemptionDescription: string;
  terms: string;
  twitterId?: string;
  url?: string;
  deliveryFromDate?: string;
  deliveryToDate?: string;
  videoUrlArray?: string[];
  hidePrice: boolean;
  locationLabel: string;
  cashbackPercent: number;
  assetId: number;
  price: number;
  discountedPrice: number;
  dealType: DealType;
  assetType: WALLET_ASSET_TYPE;
  slug: string;
}

export interface IPaginatedGetWalletListParams {
  pageNumber: number;
  type: WALLET_ASSET_TYPE;
}

export interface IGetWalletAssetParams {
  id: number;
  type: WALLET_ASSET_TYPE;
}

export interface IBrandAddress {
  city: string;
  countryCode: string;
  street: string;
  zip: string;
}

export interface IBrandInfo {
  id: number;
  address: IBrandAddress;
  businessHours: string | null;
  distance: number;
  businessProfileDescription: string;
  email: string | null;
  facebookId: string;
  freeWlan: boolean;
  genericName: string;
  genericUrl: string;
  instagramId: string;
  latitude: number;
  longitude: number;
  name: string;
  phone: string | null;
  picture: string;
  pictureMainColor: string;
  twitterId: string;
  url: string;
}
