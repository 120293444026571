import { PoinzApiClient } from '../../api';
import { IUserAddress, IUserAddressPayload } from './types/addresses';

const VERSION = '/v2';
const WEB = '/web';
const USER_ADDRESSES = '/user-addresses';

const apiRoot = `${VERSION}${WEB}${USER_ADDRESSES}`;

const get = (apiClient: PoinzApiClient) => async (): Promise<IUserAddress[]> => {
  const response = await apiClient.get(`${apiRoot}`, {
    authenticated: true
  });

  return response.body;
};

const create =
  (apiClient: PoinzApiClient) =>
  async (address: IUserAddressPayload): Promise<IUserAddress> => {
    const response = await apiClient.post(`${apiRoot}`, {
      authenticated: true,
      body: address
    });

    return response.body;
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (addressId: number, address: IUserAddressPayload): Promise<IUserAddress> => {
    const response = await apiClient.put(`${apiRoot}/:addressId`, {
      authenticated: true,
      pathParams: { addressId },
      body: address
    });

    return response.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  create: create(apiClient),
  update: update(apiClient)
});
