import { PoinzApiClient } from '../../api';
import analytics from './analytics';
import campaign from './campaign';
import poinzTransaction from './poinzTransaction';
import transaction from './transaction';
import userBalance from './userBalance';

export default (apiClient: PoinzApiClient) => ({
  campaign: campaign(apiClient),
  analytics: analytics(apiClient),
  transaction: {
    ...transaction(apiClient),
    poinz: poinzTransaction(apiClient)
  },
  userBalance: userBalance(apiClient)
});
