export const mapToNameValueObject = (values: any) => {
  const list: Array<{
    name: string,
    value: any
  }> = [];
  Object.keys(values || {}).map(e => list.push({ name: e, value: values[e] }));
  return list;
};

export const getPagination = (header: any) => ({
  totalElements: parseInt(header.get('total-elements'), 10),
  totalPages: parseInt(header.get('total-pages'), 10) - 1,
  currentPage: parseInt(header.get('page-number'), 10),
});

export const initialPagination = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
} as const;
