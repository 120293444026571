import { PoinzApiClient } from '../../api';
import { GiftCardLine, PaginatedGiftCardLineParams, UpdateGiftCardLineBody } from './types';
import { NewPaginationModel } from '../../types';
import { ROOT } from './config';

const GIFTCARDLINES = '/gift-card-lines';
const PUBLIC = '/public';
const VERSION = '/v2';

const apiRoot = `${ROOT}${VERSION}${GIFTCARDLINES}`;
const publicApiRoot = `${ROOT}${VERSION}${PUBLIC}${GIFTCARDLINES}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedGiftCardLineParams): Promise<NewPaginationModel<GiftCardLine>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<GiftCardLine> => {
    const result = await apiClient.get(`${apiRoot}/:id`, { pathParams: { id } });
    return result.body;
  };

const getPublic =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<GiftCardLine> => {
    const result = await apiClient.get(`${publicApiRoot}/:id`, { pathParams: { id } });
    return result.body;
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (id: number, body: UpdateGiftCardLineBody): Promise<GiftCardLine> => {
    const result = await apiClient.patch(`${apiRoot}/:id`, {
      pathParams: { id },
      body
    });
    return result.body;
  };

const updateImage =
  (apiClient: PoinzApiClient) =>
  async (id: number, data: Blob): Promise<GiftCardLine> => {
    const formData = new FormData();
    formData.append('image', data);

    const result = await apiClient.put(`${apiRoot}/:id/images`, {
      pathParams: { id },
      isMultiPart: true,
      body: formData
    });

    return result.body;
  };

const updateOrder =
  (apiClient: PoinzApiClient) =>
  async (order: number[]): Promise<void> => {
    const result = await apiClient.post(`${apiRoot}/order`, {
      headers: new Headers(),
      authenticated: true,
      body: {
        order
      }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  update: update(apiClient),
  get: get(apiClient),
  getPublic: getPublic(apiClient),
  getList: getList(apiClient),
  updateImage: updateImage(apiClient),
  updateOrder: updateOrder(apiClient)
});
