import * as React from 'react';

import { IdType } from '@poinz/api';
import { withLoadingDialog } from './LoadingDialog';
import { LoadingDialogProps } from './LoadingDialog/LoadingDialog.utils';

type OpenPdfProps = {
  openPdf: (id: IdType) => void;
};

type Props = {
  children: (openPdfProps: OpenPdfProps) => React.ReactElement;
  getPdf: (id: IdType) => Promise<Blob>;
} & LoadingDialogProps;

function OpenPdf(props: Props) {
  const { getPdf, setLoadingMessage, children } = props;
  const [currentId, setCurrentId] = React.useState<any>(null);
  React.useEffect(() => {
    async function loadPdf() {
      if (currentId != null) {
        setLoadingMessage({ key: 'openPdf.loading' });
        const pdfBlob = await getPdf(currentId);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        setCurrentId(null);
        setLoadingMessage(null);
      }
    }
    loadPdf();
  }, [currentId, getPdf, setLoadingMessage]);

  return children({ openPdf: setCurrentId });
}

export default withLoadingDialog(OpenPdf);
