import React, { PureComponent } from 'react';
import { Typography } from '@mui/material';
import { rechartWithExportableData } from 'App/components/DataExporter';
import poinzStatsApi, { PoinzActivityTimelineStatsType } from 'serviceNew/api/poinzStats';
import { mapToNameValueObject } from 'serviceNew/util/helperFunctions';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import BarChart from 'App/components/charts/BarChart';
import { moment } from 'serviceNew/locale/dates';
import { POINZ_STATS } from '../../../../constants/general';
import StatsCardList from '../../../components/StatsCardList';
import { GridContainer, GridItem } from '../../../components/FlexGridContainer';
import PoinzStatsHeader from './components/PoinzStatsHeader';
import { DashboardCardBody } from '../../../components/DashboardCard';
import { ActivityTimeLineType } from '../../../components/ActivityTimelineInput';
import TopRewardsTable from './components/TopRewardsTable';

const ExportableBarChart = rechartWithExportableData(BarChart);

export type PoinzStatsOverviewType = {
  name: string;
  value: number;
};

type Props = LocaleProps;

type State = ActivityTimeLineType & {
  poinzStatsOverview: Array<PoinzStatsOverviewType>;
  poinzStatsActivityTimeline: Array<PoinzActivityTimelineStatsType>;
};

const today = moment();
const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
const startRange = today.subtract(3, 'month').format('YYYY-MM-DD');

class PoinzStatsUnconnected extends PureComponent<Props, State> {
  // @ts-expect-error - TS2416 - Property 'state' in type 'PoinzStatsUnconnected' is not assignable to the same property in base type 'PureComponent<LocaleProviderProps, State, any>'.
  state = {
    startDate: startRange,
    endDate: yesterday,
    resolution: 'DAILY',
    poinzStatsOverview: [],
    poinzStatsActivityTimeline: []
  };

  async componentDidMount() {
    const { startDate, endDate, resolution } = this.state;
    await this.getOverview();
    await this.getActivityTimeline({ startDate, endDate, resolution });
    // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
    this.interval = setInterval(async () => this.getOverview(), 60000); // Updates overview figures every minute
  }

  getOverview = async () => {
    const result = await poinzStatsApi.getOverview();
    const poinzStatsOverview = mapToNameValueObject(result);
    this.setState({ poinzStatsOverview });
  };

  getActivityTimeline = async (params: any) => {
    const result = await poinzStatsApi.getActivityTimeline(params);
    const poinzStatsActivityTimeline = result.reverse();
    this.setState({ poinzStatsActivityTimeline });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // @ts-expect-error - TS2564 - Property 'interval' has no initializer and is not definitely assigned in the constructor.
  interval: number;

  handleChangeDate = (name: any, value: any) => {
    // @ts-expect-error - TS2345 - Argument of type '{ [x: number]: any; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<LocaleProviderProps>) => State | Pick<State, keyof ActivityTimeLineType | ... 1 more ... | "poinzStatsActivityTimeline"> | null) | Pick<...> | null'.
    this.setState({ [name]: value });
    this.getActivityTimeline({ ...this.state, [name]: value });
  };

  render() {
    const { formatMessage } = this.props;
    const { resolution, endDate, startDate, poinzStatsOverview, poinzStatsActivityTimeline } =
      this.state;

    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <PoinzStatsHeader
              endDate={endDate}
              startDate={startDate}
              resolution={resolution}
              handleChangeDate={this.handleChangeDate}
            />
          </GridItem>
          <GridItem xs={12}>
            {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node', but multiple children were provided. */}
            <DashboardCardBody>
              <Typography variant="subtitle1">
                {formatMessage({ id: 'stats.dailyCounter' })}
              </Typography>
              <StatsCardList
                statsList={poinzStatsOverview}
                statsFormat={POINZ_STATS}
                vertical={false}
              />
            </DashboardCardBody>
          </GridItem>
          <GridItem xs={12}>
            <DashboardCardBody>
              <ExportableBarChart
                name="activityTimeline"
                // @ts-expect-error - TS2322 - Type '{ name: string; height: number; title: string; chartData: never[]; chartList: string[]; xaxis: string; resolution: string; tooltip: true; legend: true; }' is not assignable to type 'IntrinsicAttributes & WrappedProps'.
                height={250}
                title="stats.activityTimelineLoyalty"
                chartData={poinzStatsActivityTimeline}
                chartList={[
                  'numActiveUser',
                  'numNewUser',
                  'numScans',
                  'numRedemptions',
                  'numInAppCodeCollects'
                ]}
                xaxis="dateDay"
                resolution={resolution}
                tooltip
                legend
              />
            </DashboardCardBody>
          </GridItem>
          <GridItem xs={12}>
            <DashboardCardBody>
              <ExportableBarChart
                name="activityTimeline"
                // @ts-expect-error - TS2322 - Type '{ name: string; height: number; title: string; chartData: never[]; chartList: string[]; xaxis: string; resolution: string; tooltip: true; legend: true; }' is not assignable to type 'IntrinsicAttributes & WrappedProps'.
                height={250}
                title="stats.activityTimelineRewards"
                chartData={poinzStatsActivityTimeline}
                chartList={['numDealPurchased', 'numDealRedeemed', 'numDealCancelled']}
                xaxis="dateDay"
                resolution={resolution}
                tooltip
                legend
              />
            </DashboardCardBody>
          </GridItem>
          <GridItem xs={12}>
            <DashboardCardBody style={{ padding: 0 }}>
              <TopRewardsTable classes={{}} />
            </DashboardCardBody>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withLocale(PoinzStatsUnconnected);
