import {PoinzApiClient} from '@poinz/api';
import { API_ROOT } from 'config';
import {
  errorHandler,
  createCallNotificationEndpoint,
  createCallEndpoint,
  setCampAuthenticatedHeader,
  apiCampAgent
} from './api';
import { localeSubject } from 'App/providers/LocaleProvider';

export const apiClient = new PoinzApiClient({
  agent: apiCampAgent,
  language: localeSubject.getValue(),
  apiRoot: API_ROOT,
  errorHandler,
  setAuthenticatedHeaders: setCampAuthenticatedHeader
});

localeSubject.subscribe(val => apiClient.changeLanguage(val));

export const callEndpoint = createCallEndpoint(apiClient);
export const callNotificationEndpoint = createCallNotificationEndpoint(callEndpoint);
