import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  appBar: {
    // @ts-expect-error - TS2339 - Property 'zIndex' does not exist on type 'DefaultTheme'.
    zIndex: theme.zIndex.drawer + 1,
    // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
    transition: theme.transitions.create(['width', 'margin'], {
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      easing: theme.transitions.easing.sharp,
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      duration: theme.transitions.duration.leavingScreen
    }),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(3)
  },
  mobileIndex: {
    // @ts-expect-error - TS2339 - Property 'zIndex' does not exist on type 'DefaultTheme'.
    zIndex: theme.zIndex.drawer - 1
  },
  appBarShift: {
    // @ts-expect-error - TS2339 - Property 'breakpoints' does not exist on type 'DefaultTheme'.
    [theme.breakpoints.up('md')]: {
      // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'DefaultTheme'.
      marginLeft: theme.mainComponents.drawer.width,
      // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'DefaultTheme'.
      width: `calc(100% - ${theme.mainComponents.drawer.width}px)`,
      // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
      transition: theme.transitions.create(['width', 'margin'], {
        // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error - TS2339 - Property 'transitions' does not exist on type 'DefaultTheme'.
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 24
  },
  hide: {
    display: 'none'
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  },
  language: {
    color: 'white'
  }
}));
