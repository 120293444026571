import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TranslationObject } from 'serviceNew/locale';

import Button from './Button';
import TranslatedText from './TranslatedText';

export type ButtonType = {
  label: TranslationObject,
  onClick: () => unknown
};

type Props = {
  open: boolean,
  title: TranslationObject | null | undefined,
  body: TranslationObject,
  buttons: Array<ButtonType>
};

const Transition = React.forwardRef((props, ref) => (
// @ts-expect-error - TS2741 - Property 'children' is missing in type '{ ref: ForwardedRef<unknown>; direction: "up"; }' but required in type 'SlideProps'.
  <Slide direction="up" {...{ ref }} {...props} />
));

function GeneralDialog(props: Props) {
  const { open, title, body, buttons } = props;
  return (
// @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'JSXElementConstructor<TransitionProps & { children: ReactElement<any, any>; }> | undefined'.
    <Dialog TransitionComponent={Transition} {...{ open }}>
      {title != null && (
        <DialogTitle>
          <TranslatedText text={title} />
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          <TranslatedText text={body} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(b => (
// @ts-expect-error - TS2322 - Type 'TranslationKey' is not assignable to type 'Key | null | undefined'.
          <Button key={b.label.key} color="primary" label={b.label} onClick={b.onClick} />
        ))}
      </DialogActions>
    </Dialog>
  );
}

GeneralDialog.defaultProps = {
  title: undefined,
  buttonLabel: { key: 'general.yes' },
};

export default GeneralDialog;
