import React, { ReactNode } from 'react';
import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  control: {
    background: '#eee',
    padding: '15px'
  },
  body: {
    padding: '15px'
  }
});

type Props = {
  children: React.ReactElement;
  classes: any;
  right?: boolean;
  elevation?: number;
  style?: any;
};

export const DashboardCardBody = withStyles(styles, { withTheme: true })(
  ({ children, classes, elevation, style }: Props) => (
    <Paper elevation={elevation} className={classes.body} style={style}>
      {children}
    </Paper>
  )
);

export const DashboardCardHeader = withStyles(styles, { withTheme: true })(
  ({ right, children, classes, elevation }: Props) => (
    <Paper
      elevation={elevation}
      className={classes.control}
      style={{ textAlign: right ? 'right' : 'center' }}
    >
      {children}
    </Paper>
  )
);
