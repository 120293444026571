import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Languages, Language } from 'serviceNew/locale';
import { Button } from 'App/components';
import { useLocale } from 'App/providers/LocaleProvider';

export const PHONE_PREVIEW_WIDTH = 216;

const useStyles = makeStyles(theme => ({
  root: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    borderRadius: theme.spacing(2),
    width: `${PHONE_PREVIEW_WIDTH + 10}px`,
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
// @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[3]
  },
  content: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    borderRadius: theme.spacing(1),
    width: `${PHONE_PREVIEW_WIDTH}px`,
    height: '432px',
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    background: theme.palette.common.white,
    overflow: 'scroll',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(3),
// @ts-expect-error - TS2339 - Property 'isPictureAtTop' does not exist on type '{}'. | TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: ({ isPictureAtTop }) => (isPictureAtTop ? 0 : theme.spacing(2))
  },
  phoneTop: {
    height: '4px',
    width: '40px',
    borderRadius: '2px',
    margin: '10px 0',
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    background: theme.palette.grey[700],
    opacity: 0.5
  },
  phoneBottom: {
    height: '30px',
    width: '30px'
  },
  textSecondary: {
// @ts-expect-error - TS2339 - Property 'isModal' does not exist on type '{}'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: ({ isModal }) => (isModal ? theme.palette.common.white : theme.palette.common.black)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-1.7)
  }
}));

type Props = {
  children: (
    arg1: {
      language: Language
    },
  ) => React.ReactElement,
  isPictureAtTop?: boolean,
  isModal?: boolean
};

function PhonePreview({
  children,
  isPictureAtTop,
  isModal,
}: Props) {
  const classes = useStyles({ isPictureAtTop, isModal });

// @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language: initialLanguage } = useLocale();
  const [language, setLanguage] = React.useState<Language>(initialLanguage);

  function renderLanguageButton(lang: Language) {
    const isActive = language === lang;
    return (
      <Button
        onClick={() => setLanguage(lang)}
        color={isActive ? 'primary' : 'secondary'}
        variant="text"
        size="small"
// @ts-expect-error - TS2551 - Property 'button' does not exist on type 'ClassNameMap<"content" | "root" | "textSecondary" | "buttons" | "phoneTop" | "phoneBottom">'. Did you mean 'buttons'?
        className={clsx(classes.button, !isActive && classes.textSecondary)}
        label={{ key: 'text.no.translation', context: { value: lang } }}
      />
    );
  }

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.phoneTop} />
        <div className={classes.content}>{children({ language })}</div>
        <div className={classes.phoneBottom} />
      </div>
{ /* @ts-expect-error - TS2345 - Argument of type '(lang: "en" | "de" | "fr" | "it") => Element' is not assignable to parameter of type '(value: string, index: number, array: string[]) => Element'. */}
      <div className={classes.buttons}>{Object.keys(Languages).map(renderLanguageButton)}</div>
    </div>
  );
}

export default React.memo<Props>(PhonePreview);
