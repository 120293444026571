import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

type QueryValue = string | number | boolean;

function useQueryState<T extends QueryValue | QueryValue[]>(
  name: string,
  fallbackValue?: T
): [T | undefined, (val: T | undefined | ((val: T | undefined) => T | undefined)) => void] {
  const location = useLocation();
  const history = useHistory();

  const queryParams = React.useMemo(
    () => queryString.parse(location.search, { parseNumbers: true }),
    [location.search]
  );
  const queryParamsRef = React.useRef(queryParams);
  const value = (queryParams[name] as T) || fallbackValue;

  const setValue = React.useCallback(
    newValue => {
      const curQueryParams = queryParamsRef.current;
      const curValue = curQueryParams[name];

      const updatedValue = typeof newValue === 'function' ? newValue(curValue) : newValue;
      if (updatedValue !== curValue) {
        history.replace({
          ...history.location,
          search: queryString.stringify({ ...curQueryParams, [name]: newValue }, { skipNull: true })
        });
      }
    },
    [history, name]
  );

  React.useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  return [value, setValue];
}

export default useQueryState;
