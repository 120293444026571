import * as React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { withStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import { RawText, Text } from 'App/components';

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 8,
      borderColor: '1px solid #80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

type Props = {
  id: string | number | null | undefined,
  array: any[],
  onChange: any,
  renderName: any,
  idProperty?: string,
  emptyLabel: TranslationObject,
  className?: string,
  withEmpty: boolean
};

function SelectionList(props: Props) {
  const { id, array, onChange, renderName, idProperty, className, emptyLabel, withEmpty } = props;

  const hasOnlyOne = array.length === 1;

  const idProp = idProperty || 'id';

  return (
    <FormControl variant="standard" {...{ className }}>
      <Select
        variant="standard"
        displayEmpty
        disabled={hasOnlyOne}
        value={hasOnlyOne ? array[0][idProp] : id || ''}
        onChange={onChange}
        input={<BootstrapInput />}>
        {withEmpty && (
          <MenuItem key="empty" value="">
            <Text variant="caption" context={emptyLabel.context}>
              {emptyLabel.key}
            </Text>
          </MenuItem>
        )}
        {array.map(e => (
          <MenuItem key={e[idProp]} value={e[idProp]}>
            <RawText variant="caption">{renderName(e)}</RawText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectionList.defaultProps = {
  emptyLabel: { key: 'general.showAll' },
  className: '',
  withEmpty: true,
};

export default SelectionList;
