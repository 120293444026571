import { MultiLanguageString } from '@poinz/locale';
import { ScaledImage } from '../../../types/image';

export type Address = {
  street: string;
  zip: string;
  city: string;
  countryCode: string;
  streetNumber?: string;
};

export class Location {
  address: Address;

  businessHours: MultiLanguageString;

  businessProfileId: number;

  codeLink: string;

  codeType: 'LOC';

  created: string;

  customGeolocation: boolean;

  description: MultiLanguageString;

  email: string;

  freeWlan: boolean;

  genericName: MultiLanguageString;

  genericUrl: string;

  id: number;

  imageLink: string;

  latitude: number;

  longitude: number;

  name: string;

  nextSequenceNumber: number;

  pdfLink: string;

  phone: string;

  picture: ScaledImage;

  pictureMainColor: string;

  showOnMap: boolean;

  token: string;

  useOnlyOnExplore: boolean;

  constructor(location: any) {
    Object.assign(this, location);
  }
}
