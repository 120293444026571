import { Nullable, PagableParams } from '../../../types';

interface TargetRule {
  targetingRuleType: string;
}

export enum CampaignType {
  DEFAULT = 'DEFAULT',
  FIRST_TRANSACTION = 'FIRST_TRANSACTION'
}

export const CAMPAIGN_TYPE_TRANSLATIONS: Record<CampaignType, string> = Object.freeze({
  [CampaignType.DEFAULT]: 'general.default',
  [CampaignType.FIRST_TRANSACTION]: 'swissLoyalty.campaign.type.firstTransaction'
});

export interface PaginatedCampaignParams extends PagableParams {
  businessProfileId: number;
  from?: Nullable<string>;
  to?: Nullable<string>;
  locationIds?: Nullable<number[]>;
}

export interface Campaign {
  id: number;
  businessProfileId: number;
  cashback: number;
  description: Nullable<Record<string, string>>;
  startDateTime: string | null;
  endDateTime: string | null;
  deactivationDateTime: string;
  locationIds: number[];
  targetRules: Nullable<TargetRule[]>;
  type: CampaignType;
}

export type CreateCampaignBody = Partial<Campaign>;

export type UpdateCampaignBody = Partial<Campaign>;
