import React from 'react';
import { Icon as MuiIcon } from '@mui/material';

export type IconType =
  | 'search'
  | 'delete'
  | 'edit'
  | 'undo'
  | 'image'
  | 'add'
  | 'clear'
  | 'done'
  | 'drag_indicator'
  | 'cloud_upload'
  | 'close'
  | 'help_outline'
  | 'phone_android'
  | 'crop'
  | 'arrow_drop_down'
  | 'description'
  | 'link'
  | 'arrow_back'
  | 'file_copy'
  | 'refresh'
  | 'shopping_cart'
  | 'phone_iphone'
  | 'attach_money'
  | 'credit_card'
  | 'straighten'
  | 'arrow_forward'
  | 'visibility'
  | 'account_balance'
  | 'contacts'
  | 'date_range'
  | 'business'
  | 'chevron_left'
  | 'chevron_right'
  | 'comment'
  | 'mail'
  | 'request_quote'
  | 'save_alt'
  | 'attachment'
  | 'check'
  | 'person'
  | 'person_remove'
  | 'android'
  | 'apple'
  | 'info_outline'
  | 'add_card'
  | 'receipt_long_outlined'
  | 'airline_stops'
  | 'percent'
  | 'group_add'
  | 'more_vertical'
  | 'pause'
  | 'restart_alt'
  | 'web'
  | 'alarm_add'
  | 'swap_horiz'
  | 'download'
  | 'pie_chart'
  | 'vpn_key_off'
  | 'vpn_key_on'
  | 'paid'
  | 'label';

export type IconProps = {
  icon: IconType;
  color?: string | null | undefined;
  size?: number | null | undefined;
  className?: string | null | undefined;
};

class Icon extends React.PureComponent<IconProps> {
  static defaultProps = {
    color: undefined,
    size: undefined
  };

  render() {
    const { icon, color, size, className } = this.props;
    return (
      <MuiIcon
        style={{
          // @ts-expect-error - TS2769 - No overload matches this call.
          fontSize: size
        }}
        {...{ color, className }}
      >
        {icon}
      </MuiIcon>
    );
  }
}

export default Icon;
