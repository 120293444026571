import * as React from 'react';
import {
  Field as FormikField,
  FieldConfig,
  // @ts-expect-error - TS2305 - Module '"formik"' has no exported member 'FieldRenderProps'.
  FieldRenderProps as FormikFieldRenderProps,
  getIn,
  connect,
  FormikContext
} from 'formik';
import { Option } from 'App/components/TextSelect';
import { TranslationObject } from '@poinz/locale';

type Props = {
  onChange?: (arg1: React.SyntheticEvent) => Promise<undefined> | undefined;
  hidden?: boolean;
  clearOnUnmount?: boolean;
  defaultOptions?: Option[];
  noLabel?: boolean;
  className?: string;
  fullWidth?: boolean;
  required?: boolean;
  tooltip?: TranslationObject;
  disabled?: any;
  variant?: string;
  textHint?: TranslationObject;
  textHintUploaded?: TranslationObject;
  placeholder?: TranslationObject;
  accept?: any;
  maxSize?: number;
  options?: Option[];
  viewText?: string;
  uploadHint?: string;
  initialValue?: any;
  disablePast?: boolean;
  minDate?: any;
  maxDate?: any;
  max?: number;
  min?: number;
  step?: any;
  label?: TranslationObject;
  component?: any;
  inputFormat?: string;
  defaultProps?: any;
  validate?: any;
  startAdornment?: any;
  endAdornment?: any;
  withTimezone?: boolean;
  inputProps?: any;
  minWidth?: any;
  width?: any;
  height?: any;
  fromArray?: boolean;
  renderOption?: any;
  disableFuture?: boolean;
  multiline?: boolean;
  unit?: any;
  minRows?: number;
  withClear?: any;
  addEmpty?: boolean;
  withTimeZone?: boolean;
  aspectRatio?: number;
  meta?: any;
  enable1Password?: boolean;
  handleChange?: (arg1: { value: string | number | null; setFieldValue: any }) => void;
} & Omit<FieldConfig, 'validate'> &
  Partial<FormikContext<any>>;

type AdapterProps = {
  onChange?: (arg1: React.SyntheticEvent) => Promise<undefined> | undefined;
} & FormikFieldRenderProps;

export type FieldRenderProps = FormikFieldRenderProps & {
  name: string;
  input: {
    onChange: (e: React.SyntheticEvent<any>) => void;
    onBlur: (e: React.SyntheticEvent<any>) => void;
    value: any;
    name: string;
  };
  meta: {
    touched: boolean;
    dirty: boolean;
    error: any | null | undefined;
    valid: boolean;
    submitting: boolean;
  };
};

const getModifiedField = (field: any, form: any, onChange: any) => ({
  ...field,
  onChange: event => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
    if (event && event.target) {
      field.onChange(event);
    } else {
      form.setFieldValue(field.name, event);
    }
  }
});

export const formikAdapter = ({ field, form, onChange, ...props }: AdapterProps) => ({
  name: field.name,
  form,
  input: getModifiedField(field, form, onChange),
  field: getModifiedField(field, form, onChange),
  ...props
});

export function withFieldAdapter(Comp: React.ComponentType<AdapterProps>) {
  return (props: AdapterProps) => <Comp {...formikAdapter(props)} />;
}

const Field = ({
  name,
  // @ts-expect-error - TS2339 - Property 'formik' does not exist on type '{ onChange?: ((arg1: SyntheticEvent<Element, Event>) => Promise<undefined> | undefined) | undefined; hidden?: boolean | undefined; clearOnUnmount?: boolean | undefined; defaultOptions: Option[]; handleChange?: ((arg1: { ...; }) => void) | undefined; } & ... 7 more ... & Pick<...>'.
  formik,
  hidden,
  validate,
  initialValue,
  clearOnUnmount,
  handleChange,
  ...restProps
}: Props) => {
  const { setFieldValue, values } = formik;
  const value = getIn(values, name);

  React.useEffect(() => {
    if (!getIn(values, name) && getIn(values, name) !== 0 && initialValue) {
      setFieldValue(name, initialValue);
    }
  }, [initialValue, name, setFieldValue, values]);

  React.useEffect(() => {
    if (handleChange && typeof handleChange === 'function') {
      handleChange({ value, name, setFieldValue });
    }
  }, [handleChange, name, setFieldValue, value]);

  React.useEffect(
    () => () => {
      if (clearOnUnmount) {
        setFieldValue(name);
      }
    },
    [clearOnUnmount, name, setFieldValue]
  );

  if (hidden) {
    return null;
  }

  const meta = {
    touched: getIn(formik.touched, name) || formik.submitCount > 0,
    error: getIn(formik.errors, name),
    submitting: formik.isSubmitting
  } as const;

  return (
    <FormikField
      validate={validate && ((value: any) => validate(value, formik.values))}
      {...{ name, meta }}
      {...restProps}
    />
  );
};

Field.defaultProps = {
  hidden: false
};

export default connect(Field);
