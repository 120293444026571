import React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell } from '@mui/material';
import { MessageStatsTableStylesProps } from './MessageStatsTable';
import { TranslatedText } from '../../../../components';

const MessageStatsTableHeader = ({
  classes,
}: MessageStatsTableStylesProps) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.idCol}>
            <TranslatedText text={{ key: 'general.id' }} />
          </TableCell>
          <TableCell className={classes.chipCol}>
            <TranslatedText text={{ key: 'message.messageState' }} />
          </TableCell>
          <TableCell className={classes.firstCol}>
            <TranslatedText text={{ key: 'message.subject' }} />
          </TableCell>
          <TableCell className={classes.middleCol} align="right">
            <TranslatedText text={{ key: 'message.totalSent' }} />
          </TableCell>
          <TableCell className={classes.middleCol} align="right">
            <TranslatedText text={{ key: 'message.totalRead' }} />
          </TableCell>
          <TableCell className={classes.middleCol} align="right">
            <TranslatedText text={{ key: 'message.totalCollected' }} />
          </TableCell>
          <TableCell className={classes.middleCol} align="right">
            <TranslatedText text={{ key: 'message.sentDate' }} />
          </TableCell>
          <TableCell className={classes.middleCol} align="right">
            <TranslatedText text={{ key: 'message.expirationDate' }} />
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  </Paper>
);

export default React.memo<MessageStatsTableStylesProps>(MessageStatsTableHeader);
