import React from 'react';
import { Paper, Table, TableBody, TableCell, TableRow, CircularProgress } from '@mui/material';
import { TranslatedText } from 'App/components';

export const EmptyTable = ({
  colspan,
}: {
  colspan: number
}) => (
  <Paper>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell colSpan={colspan}>
            <div style={{ textAlign: 'center' }}>
              <TranslatedText text={{ key: 'stats.noDataAvailable' }} />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
);

export const LoadingTable = ({
  colspan,
}: {
  colspan: number
}) => (
  <Paper>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell colSpan={colspan}>
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
);
