import {DealCollect, OfflineRedeem, RefundType, CancelationType, REFUND_TYPE} from 'serviceNew/model/dealCollect';
import { IdType } from '@poinz/api';
import { callEndpoint } from './apiClient';

const ROOT = '/deal-collects';

export const PLATFORM_TYPE_TRANSLATIONS = {
  MOBILE_APP: 'deal.platform.mobileApp',
  WEB_APP: 'deal.platform.webApp'
} as const;
export type AppType = keyof typeof PLATFORM_TYPE_TRANSLATIONS;

async function getDealCollectByReferenceNumber(referenceNumber: string): Promise<DealCollect[]> {
  const result = await callEndpoint(ROOT, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { referenceNumber }
  });
  return result.body;
}

async function transferDealToUser(dealCollectId: IdType, userId: string): Promise<DealCollect> {
  const result = await callEndpoint(`${ROOT}/${dealCollectId}/transfer`, 'POST', {
    headers: new Headers(),
    authenticated: true,
    body: { userId }
  });
  return result.body;
}

async function offlineRedeemDealCollect(
  params: {
    id: IdType,
    values: OfflineRedeem
  },
): Promise<DealCollect> {
  const { id, values } = params;
  const result = await callEndpoint(`${ROOT}/${id}/redeem`, 'PUT', {
    headers: new Headers(),
    authenticated: true,
    queryParams: values
  });
  return result.body;
}

async function extendExpirationDate(id: IdType, expirationDate: string): Promise<DealCollect> {
  const result = await callEndpoint(`${ROOT}/${id}/extend-expiration-date`, 'PUT', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { expirationDate }
  });
  return result.body;
}

async function cancelDealCollect(
  id: IdType,
  hasCancellationFee: boolean,
  type: RefundType,
  label: CancelationType,
): Promise<void> {
  await callEndpoint(`${ROOT}/${id}/refund`, 'PUT', {
    headers: new Headers(),
    authenticated: true,
    queryParams: {
      ...(type === REFUND_TYPE.CASH ? { hasCancellationFee } : {}),
      type,
      label
    }
  });
}

export default {
  extendExpirationDate,
  getDealCollectByReferenceNumber,
  offlineRedeemDealCollect,
  cancelDealCollect,
  transferDealToUser
};
