import * as React from 'react';

import { TranslationObject } from 'serviceNew/locale';

import { DashboardTabs, DashboardSection } from 'App/components';
import { LineChart } from 'App/components/charts';

type Props = {
  rewardGroups: {
    [key: string]: TranslationObject
  },
  numWinner: any,
  numWinnerNotClaimed: any
};

function RewardGroupStats(props: Props) {
  const { rewardGroups, numWinner, numWinnerNotClaimed } = props;
  const renderNumWinner = React.useCallback(
    () => (
      <LineChart
        data={numWinner}
        dataSets={Object.keys(rewardGroups)}
        dataSetNames={rewardGroups}
        xAxis="dateDay"
        height={250}
      />
    ),
    [numWinner, rewardGroups]
  );
  const renderNumWinnerNotClaimed = React.useCallback(
    () => (
      <LineChart
        data={numWinnerNotClaimed}
        dataSets={Object.keys(rewardGroups)}
        dataSetNames={rewardGroups}
        xAxis="dateDay"
        height={250}
      />
    ),
    [numWinnerNotClaimed, rewardGroups]
  );
  const tabs = React.useMemo(
    () => [
      {
        label: { key: 'marketingCampaign.winners' },
        value: 'WINNER',
        renderTab: renderNumWinner,
      },
      {
        label: { key: 'marketingCampaign.notClaimed' },
        value: 'NOT_CLAIMED',
        renderTab: renderNumWinnerNotClaimed,
      },
    ],
    [renderNumWinner, renderNumWinnerNotClaimed]
  );
  return (
    <DashboardSection title={{ key: 'rewardGroup.rewardGroups' }}>
      <DashboardTabs {...{ tabs }} />
    </DashboardSection>
  );
}

export default RewardGroupStats;
