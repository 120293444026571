import React from 'react';
import _ from 'lodash';
import { useLocale } from 'App/providers/LocaleProvider';
import RowListBox from 'App/components/Tables/RowListBox';

const LOCATION_KEY = 'location';

const TABLE_LAYOUT = [
  {},
  {
    justifyContent: 'flex-end'
  },
  {
    justifyContent: 'flex-end'
  }
];

type Props = {
  data: any[];
  locations: any[];
  className?: string;
};

function DealStatsRowListBox(props: Props) {
  const { className, data, locations } = props;
  const { translate } = useLocale();
  const locationRedemptions: Array<any> = [];

  data.forEach(dayObject => {
    const redeemedObject = dayObject.redeemed;
    Object.keys(redeemedObject).forEach(optionKey => {
      if (optionKey.includes('o')) {
        const optionObject = redeemedObject[optionKey];
        Object.keys(optionObject).forEach(locationKey => {
          if (locationKey.includes('l')) {
            const locationObject = optionObject[locationKey];
            locationObject[LOCATION_KEY] = locationKey.replace('l', '');
            locationRedemptions.push(locationObject);
          }
        });
      }
    });
  });

  const groupedLocations = _.groupBy(locationRedemptions, obj => obj.location);

  const reducedArray = Object.keys(groupedLocations).map(locationKey =>
    groupedLocations[locationKey].reduce<Record<string, any>>((accumulator, item) => {
      Object.keys(item).forEach(key => {
        if (key === LOCATION_KEY) {
          accumulator[key] = item[key];
          return;
        }
        accumulator[key] = (accumulator[key] || 0) + item[key];
      });
      return accumulator;
    }, {})
  );

  const getLocationString = (locationId: any) => {
    const locationObject = locations.filter(loc => loc.id === parseInt(locationId, 10)).shift();
    return locationObject.name;
  };

  const headerRow = [
    { text: translate('location.location'), ...TABLE_LAYOUT[0] },
    { text: translate('general.count'), ...TABLE_LAYOUT[1] }
  ];

  const displayData = reducedArray.map(obj => [
    { text: getLocationString(obj?.location), ...TABLE_LAYOUT[0] },
    { text: obj?.count, ...TABLE_LAYOUT[1] }
  ]);

  if (displayData.length === 0) {
    return null;
  }

  return (
    <RowListBox
      title={{ key: 'stats.locationRedemptions' }}
      data={displayData}
      totalRow={[]}
      headerRow={headerRow}
      {...{ className }}
    />
  );
}

export default DealStatsRowListBox;
