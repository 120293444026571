import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { TranslationKey } from 'serviceNew/locale';
import { IdType } from '@poinz/api';
import { useNavigation, UrlLocation } from 'serviceNew/navigation';

import { Title, Text, Button } from 'App/components';

const useStyles = makeStyles(theme => ({
  backButton: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1)
  }
}));

type Props = {
  error: boolean,
  children: React.ReactNode,
  redirect: string | UrlLocation,
  noEntity: TranslationKey,
  id: IdType
};

function NotFound(props: Props) {
  const { error, children, redirect, noEntity, id } = props;

  const classes = useStyles();
  const { replace } = useNavigation();

  if (error) {
    return (
      <>
        <Title label={{ key: 'errors.notFound' }} />
        <Text context={{ noEntity, id }}>errors.entity.notFound</Text>
        <Button
          className={classes.backButton}
          label={{ key: 'message.backToOverview' }}
          onClick={() => replace(redirect)}
        />
      </>
    );
  }

  return children;
}

export default NotFound;
