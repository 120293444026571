import { EnumTranslationKeys } from 'serviceNew/locale';
import { keyMirror } from 'util/ObjectHelper';
import { IdType } from '@poinz/api';

export const PERMISSION_CATEGORY = keyMirror({
  DEAL: null,
  LOYALTY: null,
  COMMUNICATION: null
});

type PermissionCategory = keyof typeof PERMISSION_CATEGORY;

export const CONTRACT_COMPANY_PRICE_CATEGORY = keyMirror({
  WIN: null,
  GROW: null,
  BUDGET: null,
  NO_BRAINER: null,
  PREMIUM: null,
  INTERN: null,
  SPECIAL: null,
  COUPON_ONLY: null,
  TOGETHERSTRONG: null
});

type ContractCompanyPriceCategory = keyof typeof CONTRACT_COMPANY_PRICE_CATEGORY;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const CONTRACT_COMPANY_PRICE_CATEGORY_TRANSLATED: EnumTranslationKeys<ContractCompanyPriceCategory> =
  {
    [CONTRACT_COMPANY_PRICE_CATEGORY.WIN]: 'contractCompany.win',
    [CONTRACT_COMPANY_PRICE_CATEGORY.GROW]: 'contractCompany.grow',
    [CONTRACT_COMPANY_PRICE_CATEGORY.BUDGET]: 'contractCompany.budget',
    [CONTRACT_COMPANY_PRICE_CATEGORY.NO_BRAINER]: 'contractCompany.noBrainer',
    [CONTRACT_COMPANY_PRICE_CATEGORY.PREMIUM]: 'contractCompany.premium',
    [CONTRACT_COMPANY_PRICE_CATEGORY.INTERN]: 'contractCompany.intern',
    [CONTRACT_COMPANY_PRICE_CATEGORY.SPECIAL]: 'contractCompany.special',
    [CONTRACT_COMPANY_PRICE_CATEGORY.COUPON_ONLY]: 'contractCompany.couponOnly',
    [CONTRACT_COMPANY_PRICE_CATEGORY.TOGETHERSTRONG]: 'contractCompany.togetherstrong'
  };

export type ContractCompany = {
  id: IdType;
  name: string;
  priceCategory: ContractCompanyPriceCategory;
  permissions: PermissionCategory;
  contractFile: string | null | undefined;
};

export type ContractCompanySearchCandidate = {
  id: IdType;
  name: string;
};
