import * as React from 'react';

import { AuthState } from 'serviceNew/model/auth';
import { initialAuthState, useAuthState } from 'serviceNew/auth';

const Context = React.createContext<AuthState>(initialAuthState);

export type AuthProps = {
  authState: AuthState;
};

type Props = {
  children: React.ReactNode;
};

function AuthProvider(props: Props) {
  const { children } = props;

  const [authState] = useAuthState();

  return <Context.Provider value={authState}>{children}</Context.Provider>;
}

export function withAuth<P extends AuthProps, C extends React.ComponentType<P>>(
  Comp: C
): React.ComponentType<Diff<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>, AuthProps>> {
  return (props: Record<any, any>) => (
    <Context.Consumer>
      {authState => <Comp {...{ authState }} {...(props as any)} />}
    </Context.Consumer>
  );
}

export default AuthProvider;
