import * as React from 'react';
import { Chip, ButtonGroup as MuiButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import DatePickerFilter, { FilterDateType } from './DatePickerFilter';
import AutocompleteFilter, { FilterAutocompleteType } from './AutocompleteFilter';
import SelectFilter, { FilterSelectType } from './SelectFilter';
import InputFilter, { FilterInputType } from './InputFilter';
import { FILTER_TYPE, Dispatch } from './FilterProps';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chipContainer: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  item: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1)
  },
  button: {
    // @ts-expect-error - TS2339 - Property 'typography' does not exist on type 'DefaultTheme'.
    ...theme.typography.caption
  },
  root: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1)
  }
}));

type FilterContextProps<S, A> = {
  filterState: S;
  dispatch: Dispatch<A, S>;
};

const initialContext = {
  filterState: {},
  dispatch: () => ({})
} as const;

const FilterContext = React.createContext<FilterContextProps<any, any>>(initialContext);

type FilterType<S> =
  | FilterSelectType<S>
  | FilterDateType<S>
  | FilterAutocompleteType<S>
  | FilterInputType<S>;

type Props<S, A> = {
  children: React.ReactNode;
  filters: Array<FilterType<S> | Array<FilterType<S>>>;
  className?: string;
} & FilterContextProps<S, A>;

function FilterContainer<S, A>(props: Props<S, A>) {
  const { children, filters, className, filterState, dispatch } = props;

  const classes = useStyles();
  const { translate } = useLocale();
  const container = React.useRef();

  function renderFilterElement(filter) {
    const filterKey = filter.key;
    switch (filter.filterType) {
      case FILTER_TYPE.DATE:
        return (
          <DatePickerFilter
            key={filter.key}
            className={classes.item}
            value={filterState[filterKey]}
            containerRef={container}
            {...{
              filterKey,
              filter
            }}
          />
        );
      case FILTER_TYPE.SELECT:
        return (
          <SelectFilter
            key={filter.key}
            className={classes.item}
            value={filterState[filterKey]}
            containerRef={container}
            {...{ filterKey, filter }}
          />
        );
      case FILTER_TYPE.AUTOCOMPLETE:
        return (
          <AutocompleteFilter
            key={filter.key}
            className={classes.item}
            value={filterState[filterKey]}
            containerRef={container}
            {...{ filterKey, filter }}
          />
        );
      case FILTER_TYPE.INPUT:
        return (
          <InputFilter
            key={filter.key}
            className={classes.item}
            value={filterState[filterKey]}
            containerRef={container}
            {...{ filterKey, filter }}
          />
        );
      default:
        return null;
    }
  }

  function renderFilter(filter: FilterType<S> | Array<FilterType<S>>, idx: number) {
    if (Array.isArray(filter)) {
      return (
        <MuiButtonGroup key={`button-group-${idx.toString()}`} variant="outlined">
          {filter.map(renderFilterElement)}
        </MuiButtonGroup>
      );
    }
    return renderFilterElement(filter);
  }

  function renderChip(filterKey: string) {
    const filterValue = filterState[filterKey];
    if (filterValue != null) {
      // @ts-expect-error
      const filter: any = filters.reduce((acc, cur) => {
        if (Array.isArray(cur)) {
          const nestedFil = cur.find(fil => fil.key === filterKey);
          return nestedFil || acc;
        }
        return cur.key === filterKey ? cur : acc;
      }, null);

      if (!filter) {
        return null;
      }

      return (
        <Chip
          className={classes.item}
          key={`chip-${filterKey}`}
          label={translate('general.filter.chip', {
            key: filter.label.key,
            value: filter.getChipValue(filterValue, filterState)
          })}
          onDelete={filter.onDelete}
        />
      );
    }
    return null;
  }

  return (
    <FilterContext.Provider value={{ filterState, dispatch }}>
      <div className={clsx('FilterContainer', className, classes.root)}>
        <div className={classes.buttonContainer}>{filters.map(renderFilter)}</div>
        {/* @ts-expect-error - TS2322 - Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div ref={container} />
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <div className={classes.chipContainer}>{Object.keys(filterState).map(renderChip)}</div>
      </div>
      {children}
    </FilterContext.Provider>
  );
}

FilterContainer.defaultProps = {
  className: ''
};

export default FilterContainer;
export const useFilter = () => React.useContext(FilterContext);
