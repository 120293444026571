import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import clsx from 'clsx';
import { destructureDeep } from 'util/ObjectHelper';
import { useLocale } from 'App/providers/LocaleProvider';
import { TablePagination } from 'App/components/Table';
import { CircularProgress } from '@mui/material';
import Text from '../Text';
import RawText from '../RawText';
import IconButton from '../IconButton';
import { IconType } from '../Icon';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1, 0)
  },
  tableCell: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:first-child': {
      // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
      paddingLeft: theme.spacing(2),
      justifyContent: 'flex-start'
    },
    '&:last-child': {
      // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
      paddingRight: theme.spacing(2)
    }
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tableBodyRow: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    borderRadius: 8
  },
  space: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2, 0),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  },
  buttonRow: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  tableCellRight: {
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  tableCellLeft: {
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  tableCellCenter: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  detailComponent: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1.5),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-0.5),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(-0.5),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    // @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[5],
    zIndex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  separator: {
    borderBottomWidth: 1,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid'
  },
  hidden: {
    visibility: 'hidden'
  },
  visible: {
    visibility: 'visible'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  loadingContainer: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  loading: {
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

type TableAction = {
  icon: IconType;
  hide?: (arg1: any) => boolean;
  action: (arg1: any) => Promise<undefined> | undefined;
};

type TableColumn = {
  id?: string;
  onHover?: boolean;
  flex?: number;
  header?: TranslationObject;
  padding?: 'default' | 'checkbox' | 'none';
  align?: 'right' | 'left' | 'center';
  dataMapper?: string | ((dataObject: any, index?: number, className?: string) => any);
  text?: TranslationObject | ((dataObject: any, index?: number) => TranslationObject);
  totalRow?: TranslationObject;
};

type TableDetail = {
  isExpanded: boolean;
  data: any;
};

type TableProps = {
  columns: TableColumn[];
  data: Array<any>;
  noMarginStyle?: boolean;
  onClick?: (arg1: any) => Promise<undefined> | undefined;
  DetailComponent?: React.ComponentType<TableDetail>;
  headerSeparator?: boolean;
  className: string;
  pagination?: TablePagination;
  actions?: TableAction[];
  isLoading?: boolean;
  emptyLabel?: TranslationObject;
};

function getColumnKey(column: TableColumn, idx: number) {
  return column.id || idx.toString();
}

function getRowKey(row: any, idx: number) {
  return row.id || `row-${idx.toString()}`;
}

function Table(props: TableProps) {
  const {
    data,
    columns,
    DetailComponent,
    headerSeparator,
    className,
    actions,
    pagination,
    onClick,
    noMarginStyle,
    isLoading,
    emptyLabel
  } = props;
  const [expandedRows, setExanedRows] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null | undefined>(null);

  const classes = useStyles();
  const { translate } = useLocale();

  function getBooleanText(value: any) {
    if (value === true) {
      return translate('general.yes');
    }
    if (value === false) {
      return translate('general.no');
    }
    return value;
  }

  function renderIsLoading() {
    return (
      <div className={classes.loadingContainer}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  function renderEmptyLabel() {
    return (
      <div className={classes.loadingContainer}>
        <div className={classes.loading}>
          <Text context={emptyLabel?.context}>{emptyLabel?.key || 'general.noData'}</Text>
        </div>
      </div>
    );
  }

  function getClassNameForAlign(align?: 'right' | 'left' | 'center' | null) {
    switch (align) {
      case 'right':
        return classes.tableCellRight;
      case 'center':
        return classes.tableCellCenter;
      case 'left':
      default:
        return classes.tableCellLeft;
    }
  }

  function renderHeader() {
    return (
      <div className={clsx(classes.tableRow, headerSeparator && classes.separator)}>
        {columns.map((col, colIdx) => (
          <div
            key={getColumnKey(col, colIdx)}
            className={clsx(classes.tableCell, getClassNameForAlign(col.align))}
            style={{ flex: col.flex || 1 }}
          >
            {col.header ? (
              <Text className={classes.text} variant="subtitle2" context={col.header.context}>
                {col.header.key}
              </Text>
            ) : null}
          </div>
        ))}
        {actions && (
          <div key="actions-header" className={clsx(classes.tableCell)} style={{ flex: 1 }} />
        )}
      </div>
    );
  }

  function renderTotal() {
    return (
      <div className={clsx(classes.tableRow)}>
        {columns.map((col, colIdx) => (
          <div
            key={getColumnKey(col, colIdx)}
            className={clsx(classes.tableCell, getClassNameForAlign(col.align))}
            style={{ flex: col.flex || 1 }}
          >
            {col.totalRow ? (
              <Text className={classes.text} variant="subtitle2" context={col.totalRow.context}>
                {col.totalRow.key}
              </Text>
            ) : null}
          </div>
        ))}
        {actions && (
          <div
            key="actions-header"
            className={clsx(classes.tableCell)}
            // style={{ flex: 1 }}
          />
        )}
      </div>
    );
  }

  function renderBody() {
    const Wrapper = ({ children, row, index, ...restProps }) => {
      if (DetailComponent != null || typeof onClick === 'function') {
        return (
          <button
            className={clsx(
              classes.tableRow,
              classes.tableBodyRow,
              classes.buttonRow,
              !noMarginStyle && classes.space
            )}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (typeof onClick === 'function') {
                onClick(row);
              } else {
                setExanedRows(prevExpanded =>
                  prevExpanded.includes(index)
                    ? prevExpanded.filter(pe => pe !== index)
                    : [...prevExpanded, index]
                );
              }
            }}
            type="button"
            {...restProps}
          >
            {children}
          </button>
        );
      }
      return (
        <div
          className={clsx(classes.tableRow, classes.tableBodyRow, !noMarginStyle && classes.space)}
          {...restProps}
        >
          {children}
        </div>
      );
    };

    const Content = ({ column, row, index, className: contentClassName }) => {
      const { text, dataMapper } = column;
      if (text != null) {
        const translationObj = typeof text === 'function' ? text(row, index) : text;
        return (
          <Text
            variant="body2"
            className={clsx(classes.text, contentClassName)}
            context={translationObj.context}
          >
            {translationObj.key}
          </Text>
        );
      }
      if (dataMapper != null) {
        return typeof dataMapper === 'function' ? (
          dataMapper(row, index, contentClassName)
        ) : (
          <RawText variant="body2" className={clsx(classes.text, contentClassName)}>
            {getBooleanText(destructureDeep(dataMapper, row))}
          </RawText>
        );
      }
      return null;
    };

    function getHoverProps(index: number) {
      return columns.some(col => col.onHover) || actions
        ? {
            onMouseEnter: () => setHoveredRow(index),
            onMouseLeave: () =>
              setHoveredRow(prevHovered => (prevHovered === index ? null : prevHovered))
          }
        : {};
    }
    if (isLoading) {
      return renderIsLoading();
    }

    if (data.length === 0) {
      return renderEmptyLabel();
    }

    return data.map((row, rowIdx) => (
      <React.Fragment key={getRowKey(row, rowIdx)}>
        <Wrapper index={rowIdx} row={row} {...getHoverProps(rowIdx)}>
          {columns.map((col, colIdx) => (
            <div
              style={{ flex: col.flex || 1 }}
              key={`${getColumnKey(col, colIdx)}-${getRowKey(row, rowIdx)}`}
              className={clsx(classes.tableCell, getClassNameForAlign(col.align))}
            >
              <Content
                className={col.onHover && hoveredRow !== rowIdx ? classes.hidden : ''}
                column={col}
                row={row}
                index={rowIdx}
              />
            </div>
          ))}
          {actions && (
            <div
              className={clsx(
                classes.tableCell,
                classes.action,
                hoveredRow !== rowIdx && classes.hidden
              )}
              // style={{ flex: 1 }}
            >
              {actions.map(
                (action, index) =>
                  (typeof action.hide !== 'function' ||
                    (typeof action.hide === 'function' && !action.hide(row))) && (
                    <IconButton
                      key={`table-icon-${index}`}
                      icon={action.icon}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        action.action(row);
                      }}
                    />
                  )
              )}
            </div>
          )}
        </Wrapper>
        {DetailComponent != null && expandedRows.includes(rowIdx) ? (
          <div className={classes.detailComponent}>
            <DetailComponent data={row} isExpanded={expandedRows.includes(rowIdx)} />
          </div>
        ) : null}
      </React.Fragment>
    ));
  }

  function renderFooter() {
    if (pagination && pagination.totalElements >= 0) {
      return <Footer colSpan={columns.length} pagination={pagination} />;
    }
    return null;
  }

  return (
    <div className={clsx(classes.container, className)}>
      {renderHeader()}
      {renderBody()}
      {renderTotal()}
      {renderFooter()}
    </div>
  );
}

Table.defaultProps = {
  className: ''
};

export default Table;
