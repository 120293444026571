import routes, {
  LOYALTY,
  DASHBOARD_LOYALTY,
  DASHBOARD_DEALS,
  DASHBOARD_ACTIVITY,
  DASHBOARD_POINZ_ACTIVITY,
  DASHBOARD_COMMUNICATION,
  DASHBOARD_POINZ,
  MARKETING_CAMPAIGNS,
  DASHBOARD_MARKETING_CAMPAIGNS,
  DEAL_BILLINGS
} from 'App/routing';

export const SIDEBAR = {
  GENERAL: {
    label: 'sidebar.general',
    icon: 'settings'
  },
  GENERAL_SALES_COMMISSION: {
    label: 'salesCommission.salesCommission',
    menu: 'GENERAL',
    icon: 'savings',
    link: routes.salesCommission.route
  },
  GENERAL_SUBSCRIPTIONS: {
    label: 'subscription.subscriptions',
    menu: 'GENERAL',
    icon: 'request_quote',
    link: routes.subscriptions.route
  },
  GENERAL_BILLINGS: {
    label: 'dealBilling.dealBillings',
    icon: 'attach_money',
    menu: 'GENERAL',
    link: DEAL_BILLINGS
  },
  GENERAL_ORDERS: {
    label: 'subscription.orderCount',
    icon: 'description',
    menu: 'GENERAL',
    link: routes.orders.route
  },
  DASHBOARD: {
    label: 'sidebar.dashboard',
    icon: 'dashboard'
  },
  DASHBOARD_OVERVIEW: {
    label: 'sidebar.dashboard.overview',
    icon: 'pie_chart',
    menu: 'DASHBOARD',
    link: DASHBOARD_POINZ
  },
  DASHBOARD_POINZ_ACTIVITY: {
    label: 'sidebar.dashboard.poinz.activity',
    icon: 'table_chart',
    menu: 'DASHBOARD',
    link: DASHBOARD_POINZ_ACTIVITY
  },
  DASHBOARD_ACTIVITY: {
    label: 'sidebar.dashboard.activity',
    icon: 'table_chart',
    menu: 'DASHBOARD',
    link: DASHBOARD_ACTIVITY
  },
  DASHBOARD_LOYALTY: {
    label: 'sidebar.dashboard.loyalty',
    icon: 'bar_chart',
    menu: 'DASHBOARD',
    link: DASHBOARD_LOYALTY
  },
  DASHBOARD_REWARDS: {
    label: 'sidebar.dashboard.deals',
    icon: 'attach_money',
    menu: 'DASHBOARD',
    link: DASHBOARD_DEALS
  },
  DASHBOARD_COMMUNICATION: {
    label: 'sidebar.dashboard.communication',
    icon: 'message',
    menu: 'DASHBOARD',
    link: DASHBOARD_COMMUNICATION
  },
  DASHBOARD_MARKETING_CAMPAIGNS: {
    label: 'sidebar.dashboard.campaigns',
    icon: 'sms_failed',
    menu: 'DASHBOARD',
    link: DASHBOARD_MARKETING_CAMPAIGNS
  },
  ADMINISTRATION: {
    label: 'sidebar.administration',
    icon: 'settings'
  },
  ADMINISTRATION_MANAGE: {
    label: 'sidebar.manage',
    icon: 'settings',
    menu: 'ADMINISTRATION',
    link: routes.administration.overview.route
  },
  ADMINISTRATION_USER_MANAGEMENT: {
    label: 'sidebar.administration.userManagement',
    icon: 'person',
    menu: 'ADMINISTRATION',
    link: routes.administration.userManagement.route
  },
  ADMINISTRATION_REPORTS: {
    label: 'sidebar.administration.reports',
    icon: 'description',
    menu: 'ADMINISTRATION',
    link: routes.administration.reports.route
  },
  ADMINISTRATION_PROMO_DEAL: {
    label: 'sidebar.administration.promoDeal',
    icon: 'local_mall',
    menu: 'ADMINISTRATION',
    link: routes.administration.promoDeals.route
  },
  ADMINISTRATION_CARD_TEMPLATES: {
    label: 'sidebar.administration.cardTemplates',
    icon: 'credit_card',
    menu: 'ADMINISTRATION',
    link: routes.administration.cardTemplates.route
  },
  ADMINISTRATION_VOUCHER: {
    label: 'sidebar.administration.voucher',
    icon: 'percent',
    menu: 'ADMINISTRATION',
    link: routes.administration.voucher.route
  },
  ADMINISTRATION_APP_LINKS: {
    label: 'sidebar.administration.appLinks',
    icon: 'link',
    menu: 'ADMINISTRATION',
    link: routes.administration.appLinks.route
  },
  ADMINISTRATION_CATEGORIES: {
    label: 'sidebar.administration.categories',
    icon: 'category',
    menu: 'ADMINISTRATION',
    link: routes.administration.categories.route
  },
  ADMINISTRATION_REFERRAL_CAMPAIGNS: {
    label: 'sidebar.administration.referralCampaigns',
    icon: 'group_add',
    menu: 'ADMINISTRATION',
    link: routes.administration.referralCampaigns.route
  },
  ADMINISTRATION_LABELING: {
    label: 'sidebar.administration.labeling',
    icon: 'label',
    menu: 'ADMINISTRATION',
    link: routes.administration.labeling.route
  },
  LOYALTY: {
    label: 'sidebar.loyalty',
    icon: 'loyalty',
    link: LOYALTY
  },
  REWARDS: {
    label: 'sidebar.deals',
    icon: 'shopping_cart',
    link: routes.deals.route
  },
  REWARDS_OVERVIEW: {
    label: 'sidebar.deals.manage',
    icon: 'shopping_cart',
    menu: 'REWARDS',
    link: routes.deals.overview.route
  },
  REWARDS_REDEMPTION: {
    label: 'sidebar.deals.collect',
    icon: 'portable_wifi_off',
    menu: 'REWARDS',
    link: routes.deals.redeemOffline.route
  },
  COMMUNICATION: {
    label: 'sidebar.communication',
    icon: 'pie_chart',
    link: routes.communication.route
  },
  COMMUNICATION_MESSAGES: {
    label: 'sidebar.communication.manage',
    icon: 'mail',
    menu: 'COMMUNICATION',
    link: routes.communication.messages.route
  },
  COMMUNICATION_COUPONS: {
    label: 'sidebar.communication.coupons',
    icon: 'redeem',
    menu: 'COMMUNICATION',
    link: routes.communication.coupons.route
  },
  MARKETING_CAMPAIGNS: {
    label: 'sidebar.communication.campaigns',
    icon: 'event',
    menu: 'COMMUNICATION',
    link: MARKETING_CAMPAIGNS
  },
  SWISS_LOYALTY: {
    label: 'sidebar.loyalty',
    icon: 'loyalty',
    link: routes.swissLoyalty.awin.route
  },
  AWIN: {
    label: 'sidebar.awin',
    icon: 'airline_stops',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.awin.route
  },
  ADTRACTION: {
    label: 'sidebar.adtraction',
    icon: 'airline_stops',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.adtraction.route
  },
  TRADEDOUBLER: {
    label: 'sidebar.tradedoubler',
    icon: 'airline_stops',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.tradedoubler.route
  },
  EXPLORE: {
    label: 'sidebar.explore',
    icon: 'explore',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.explore.route
  },
  GIFTCARDS: {
    label: 'sidebar.giftcards',
    icon: 'loyalty'
  },
  GIFTCARDS_MANAGE: {
    label: 'sidebar.manage',
    icon: 'redeem',
    menu: 'GIFTCARDS',
    link: routes.giftcardLines.route
  },
  GIFTCARDS_PURCHASES: {
    label: 'sidebar.purchases',
    icon: 'add_card',
    menu: 'GIFTCARDS',
    link: routes.giftcardLines.purchases.route
  },
  TRANSACTION: {
    label: 'sidebar.transaction',
    icon: 'receipt_long_outlined',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.transaction.route
  },
  WORLDLINE: {
    label: 'sidebar.swissLoyalty.worldline',
    icon: 'attach_money',
    menu: 'SWISS_LOYALTY',
    link: routes.swissLoyalty.worldline.route
  }
} as const;
