import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import Text from './Text';
import RawText from './RawText';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-2)
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 150,
    flexGrow: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2)
  },
  label: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[600]
  }
}));

type NumberField<T, S> = {
  label: TranslationObject;
  propertyName?: S;
  render?: (arg1: T) => React.ReactElement;
};

type Props<T, S> = {
  fields: NumberField<T, S>[];
  data: T;
};

function DashboardNumberBlock<T, S extends keyof T>(props: Props<T, S>) {
  const { fields, data } = props;

  const classes = useStyles();

  function renderField(field: NumberField<T, S>) {
    const { propertyName, render, label } = field;
    let content = null;
    if (propertyName != null) {
      // @ts-expect-error - TS2322 - Type 'T[S]' is not assignable to type 'null'.
      content = data[propertyName];
    }
    if (typeof render === 'function') {
      // @ts-expect-error - TS2322 - Type 'ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to type 'null'.
      content = render(data);
    }
    return (
      <div className={classes.field}>
        <Text className={classes.label} color="inherit" context={label.context}>
          {label.key}
        </Text>
        <RawText>
          {/* @ts-expect-error - TS2769 - No overload matches this call. */}
          {content}
        </RawText>
      </div>
    );
  }

  return <div className={classes.container}>{fields.map(renderField)}</div>;
}

export default DashboardNumberBlock;
