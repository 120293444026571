import React from 'react';
import { Grid, ImageList, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Tooltip from 'App/components/Tooltip';
import { TranslationKey } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import TranslatedText from './TranslatedText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = (theme: any) => ({
  container: {
    padding: theme.spacing(2)
  },
  text: {
    paddingRight: theme.spacing(1)
  },
  root: {
    margin: -2
  }
});

export type StatsType = {
  name: string;
  value: any;
};

type StatsFormat = {
  secondNumber?: string;
  label: TranslationKey;
  helperText?: TranslationKey;
  style: 'decimal' | 'percent' | 'text';
  suffix?: TranslationKey;
};

type Props = {
  statsList: Array<any>;
  statsFormat: {
    [key: string]: StatsFormat;
  };
  classes: any;
} & LocaleProps;

function returnGridListContent(input: any) {
  const object = Object.entries(input).map(e => ({ [e[0]]: e[1] }));
  return object;
}

const StatsCardList = ({ statsList, statsFormat, classes, formatNumber }: Props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const cols = smDown ? 2 : mdDown ? 3 : 4;

  return (
    <ImageList className={classes.root} cols={cols} rowHeight="auto">
      {/* @ts-expect-error - TS2769 - No overload matches this call. */}
      {returnGridListContent(statsFormat).map(element => {
        const key = Object.keys(element)[0];
        const statListItem = statsList.filter(e => e.name === key)?.[0];
        const statItem = element[key];

        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const value = (statListItem && statListItem.value) || (statItem.style === 'text' ? ' ' : 0);

        const secondNummer =
          // @ts-expect-error - TS2571 - Object is of type 'unknown'.
          statItem && statItem.secondNumber
            ? // @ts-expect-error - TS2571 - Object is of type 'unknown'.
              statsList.find(li => li.name === statItem.secondNumber)
            : null;

        return (
          statItem && (
            <Grid key={key}>
              <div className={classes.container}>
                <Typography variant="h5">
                  {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */}
                  {statItem.style === 'text' ? (
                    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                    <TranslatedText text={{ key: statItem.translation[value] }} />
                  ) : (
                    formatNumber({
                      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
                      value: statItem.render ? statItem.render(statsList) : value,
                      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                      style: statItem.style,
                      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                      currency: statItem.style === 'currency' ? 'CHF' : undefined,
                      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                      maximumFractionDigits: statItem.style === 'currency' ? 2 : 0
                    })
                  )}{' '}
                  {secondNummer &&
                    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                    `/ ${formatNumber({ value: secondNummer.value, style: statItem.style })}`}
                  {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'. */}
                  {statItem.suffix && <TranslatedText text={{ key: statItem.suffix }} />}
                </Typography>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                  <Typography inline variant="body1" className={classes.text}>
                    {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */}
                    <TranslatedText text={{ key: statItem.label }} />
                  </Typography>
                  {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'. */}
                  {statItem.helperText && <Tooltip label={{ key: statItem.helperText }} />}
                </Grid>
              </div>
            </Grid>
          )
        );
      })}
    </ImageList>
  );
};

export default withStyles(styles, { withTheme: true })(withLocale(StatsCardList));
