import { NewPaginationModel } from '../../types';
import { PoinzApiClient } from '../../api';
import { GiftCard, PaginatedGiftCardParams, UpdateGiftCardBody } from './types';
import { ROOT } from './config';

const GIFTCARDS = '/gift-cards';
const PUBLIC = '/public';
const VERSION = '/v2';
const apiRoot = `${ROOT}${VERSION}${GIFTCARDS}`;
const publicApiRoot = `${ROOT}${VERSION}${PUBLIC}${GIFTCARDS}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedGiftCardParams): Promise<NewPaginationModel<GiftCard>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<GiftCard> => {
    const result = await apiClient.get(`${apiRoot}/:id`, { pathParams: { id } });
    return result.body;
  };

const getPublic =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<GiftCard> => {
    const result = await apiClient.get(`${publicApiRoot}/:id`, { pathParams: { id } });
    return result.body;
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (id: number, body: UpdateGiftCardBody): Promise<GiftCard> => {
    const result = await apiClient.patch(`${apiRoot}/:id`, {
      pathParams: { id },
      body
    });
    return result.body;
  };

const updateImage =
  (apiClient: PoinzApiClient) =>
  async (id: number, data: Blob): Promise<GiftCard> => {
    const formData = new FormData();
    formData.append('image', data);

    const result = await apiClient.put(`${apiRoot}/:id/images`, {
      pathParams: { id },
      isMultiPart: true,
      body: formData
    });

    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  getList: getList(apiClient),
  get: get(apiClient),
  getPublic: getPublic(apiClient),
  update: update(apiClient),
  updateImage: updateImage(apiClient)
});
