import React from 'react';
import { TranslatedText, RawText } from 'App/components';
import { VERSION } from 'config';
import { useStyles } from './Footer.styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <RawText variant="body2">
        {`© ${new Date().getFullYear()} poinz AG, `}
        {<TranslatedText text={{ key: 'footer.copyright' }} />}
      </RawText>
      <div className={classes.version}>
        <RawText variant="caption">{`Version: ${VERSION}`}</RawText>
      </div>
    </footer>
  );
};

export default Footer;
