import {IdType} from '@poinz/api';
import { callEndpoint } from './apiClient';
import {
  AppLinkFormType,
  AppLinkType,
  AppLinkEntityType,
  AppLinkTypeObject,
  APP_LINK_ENTITY_TYPE
} from '../model/appLink';

const ADMIN = 'admin';
const APP_LINK = 'app-links';

const buildAppLinkUrl = `/${ADMIN}/${APP_LINK}`;
const buildAppLinkUrlwithID = (id: IdType): string => `${buildAppLinkUrl}/${id}`;

const buildAppLinkUrlForPathWithId = (path: 'vouchers' | 'card-templates', id: IdType): string => `${buildAppLinkUrl}/${path}/${id}`;

async function getAppLinksForType(type: AppLinkType | null): Promise<Array<AppLinkEntityType>> {
  const result = await callEndpoint(`${buildAppLinkUrl}`, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { type }
  });
  return result.body;
}

async function createAppLink(appLinkForm: AppLinkTypeObject): Promise<void> {
  await callEndpoint(`${buildAppLinkUrl}`, 'POST', {
    headers: new Headers(),
    authenticated: true,
    body: appLinkForm
  });
}

async function deleteAppLink(id: IdType): Promise<void> {
  await callEndpoint(`${buildAppLinkUrlwithID(id)}`, 'DELETE', {
    headers: new Headers(),
    authenticated: true
  });
}

async function getAppLinksForVoucher(voucherId: IdType): Promise<Array<AppLinkEntityType>> {
  const result = await callEndpoint(
    `${buildAppLinkUrlForPathWithId('vouchers', voucherId)}`,
    'GET',
    {}
  );
  return result.body;
}

async function createAppLinkForVoucher(voucherId: IdType, appLinkForm: AppLinkFormType): Promise<void> {
  const result = await callEndpoint(
    `${buildAppLinkUrlForPathWithId('vouchers', voucherId)}`,
    'POST',
    {
      body: appLinkForm
    }
  );
  return result.body;
}

async function getAppLinksForCardTemplate(cardTemplateId: IdType): Promise<Array<AppLinkEntityType>> {
  const result = await callEndpoint(
    `${buildAppLinkUrlForPathWithId('card-templates', cardTemplateId)}`,
    'GET',
    {}
  );
  return result.body;
}

async function createCardTemplateAppLink(cardTemplateId: IdType, appLinkForm: AppLinkFormType): Promise<void> {
  const result = await callEndpoint(
    `${buildAppLinkUrlForPathWithId('card-templates', cardTemplateId)}`,
    'POST',
    {
      body: appLinkForm
    }
  );
  return result.body;
}

export default {
  getAppLinksForType,
  createAppLink,
  deleteAppLink,
  [APP_LINK_ENTITY_TYPE.VOUCHERS]: {
    createAppLink: createAppLinkForVoucher,
    getAppLinks: getAppLinksForVoucher
  },
  [APP_LINK_ENTITY_TYPE.CARD_TEMPLATES]: {
    createAppLink: createCardTemplateAppLink,
    getAppLinks: getAppLinksForCardTemplate
  }
};
