import * as React from 'react';

import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IdType } from '@poinz/api';
import { useLocale } from 'App/providers/LocaleProvider';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { TranslationObject, MultiLanguageString } from 'serviceNew/locale';
import { parseName } from 'util/StringHelper';
import { UrlLocation } from 'serviceNew/navigation';

import GridContainer from './GridContainer';
import EntityCard, { EntityCardAction } from './EntityCard/EntityCard';
import Text from './Text';
import AddSign from './AddSign';
import { IconType } from './Icon';

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2, 0, 6, 0)
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  add: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(2)
  },
  grid: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: theme.spacing(4)
  }
}));

export type Entity =
  | {
      id: number;
      name: MultiLanguageString;
      disabled?: boolean;
    }
  | {
      id: number;
      name: string;
      disabled?: boolean;
    };

type Props<T> = {
  allowAdd?: boolean;
  entityList?: T[] | null | undefined;
  onEntitySelect?: (arg1: IdType) => void;
  selectedEntityId?: IdType | null | undefined;
  title: TranslationObject;
  isLoading?: boolean;
  addLink?: string | UrlLocation;
  withPicture?: boolean;
  additionalContent?: Array<{
    label: TranslationObject;
    mapper: (arg1: T) => string | number;
  }>;
  actions?: ((arg1: T) => EntityCardAction[]) | EntityCardAction[];
  showId?: boolean;
  getIcon?: (arg1: T) => IconType;
};

export function EntityOverview<T extends Entity>(props: Props<T>) {
  const {
    entityList,
    selectedEntityId,
    onEntitySelect,
    title,
    isLoading,
    addLink,
    actions,
    withPicture,
    additionalContent,
    showId = false,
    getIcon
  } = props;

  const classes = useStyles();
  const { isPoinzAdmin } = useAdminUser();
  // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language } = useLocale();

  const allowAdd = props.allowAdd || isPoinzAdmin;
  const clickable = typeof onEntitySelect === 'function';

  const selectEntity = (id: IdType) => () => {
    if (typeof onEntitySelect === 'function') {
      onEntitySelect(id);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Text variant="h4" context={title.context}>
          {title.key}
        </Text>
        {allowAdd && addLink && <AddSign className={classes.add} link={addLink} />}
      </div>

      <div className={classes.grid}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          entityList &&
          entityList.length > 0 && (
            <GridContainer>
              {entityList.map(e => (
                <EntityCard
                  id={e.id}
                  key={e.id}
                  showId={showId}
                  // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
                  name={parseName(e.name, language)}
                  additionalContent={
                    additionalContent &&
                    additionalContent.map(ac => ({ label: ac.label, value: ac.mapper(e) }))
                  }
                  withPicture={withPicture}
                  // @ts-expect-error - TS2339 - Property 'picture' does not exist on type 'Entity'.
                  picture={e.picture || undefined}
                  // @ts-expect-error - TS2339 - Property 'logo' does not exist on type 'Entity'.
                  logo={e.logo || undefined}
                  icon={typeof getIcon === 'function' ? getIcon(e) : undefined}
                  // @ts-expect-error - TS2322 - Type '(() => void) | null' is not assignable to type '(() => void | null | undefined) | undefined'.
                  onClick={clickable && !e.disabled ? selectEntity(e.id) : null}
                  selected={selectedEntityId === e.id}
                  disabled={e.disabled}
                  actions={typeof actions === 'function' ? actions(e) : actions}
                />
              ))}
            </GridContainer>
          )
        )}
      </div>
    </div>
  );
}

export default EntityOverview;
