import * as React from 'react';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownSharp';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
  button: {
    // fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textTransform: 'none',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    height: theme.spacing(4)
  }
}));

type Props = {
  className?: string;
  children?: React.ReactNode;
} & JSX.LibraryManagedAttributes<typeof Button, React.ComponentProps<typeof Button>>;

function FilterButton(props: Props) {
  const { className, children, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <Button
      color="secondary"
      variant="outlined"
      className={[classes.button, className]}
      endIcon={<ArrowDropDownIcon fontSize="small" />}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export default FilterButton;
