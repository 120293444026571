import { IdType, ScaledImage } from '@poinz/api';
import { MultiLanguageString } from 'serviceNew/locale';
// @ts-expect-error - TS2307 - Cannot find module 'App/components/EntityCard/16-9.jpg' or its corresponding type declarations.
import defaultPicture from 'App/components/EntityCard/16-9.jpg';
import { RedemptionType } from 'serviceNew/model/redemptionCode';
import { DealOption } from 'serviceNew/model/dealOption';
import { DEAL_RULE_STATE, DealRule, DealRuleState } from 'serviceNew/model/dealRule';
import {
  DEAL_TYPE,
  DealCategory,
  DealType,
  DiscountInfo,
  IdentifierPicture,
  LocationMode,
  ProvisionCalculation
} from './dealTypes';

export class Deal {
  // @ts-expect-error - TS2564 - Property 'id' has no initializer and is not definitely assigned in the constructor.
  id: IdType;

  // @ts-expect-error - TS2564 - Property 'created' has no initializer and is not definitely assigned in the constructor.
  created: string;

  // @ts-expect-error - TS2564 - Property 'name' has no initializer and is not definitely assigned in the constructor.
  name: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'description' has no initializer and is not definitely assigned in the constructor.
  description: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'additionalInfo' has no initializer and is not definitely assigned in the constructor.
  additionalInfo: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'locationLabel' has no initializer and is not definitely assigned in the constructor.
  locationLabel: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'terms' has no initializer and is not definitely assigned in the constructor.
  terms: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'dealType' has no initializer and is not definitely assigned in the constructor.
  dealType: DealType;

  // @ts-expect-error - TS2564 - Property 'expiresInDays' has no initializer and is not definitely assigned in the constructor.
  expiresInDays: number;

  discountInfo: DiscountInfo | null | undefined;

  // @ts-expect-error - TS2564 - Property 'price' has no initializer and is not definitely assigned in the constructor.
  price: number;

  // @ts-expect-error - TS2564 - Property 'discountedPrice' has no initializer and is not definitely assigned in the constructor.
  discountedPrice: number;

  // @ts-expect-error - TS2564 - Property 'limitPerUser' has no initializer and is not definitely assigned in the constructor.
  limitPerUser: number;

  // @ts-expect-error - TS2564 - Property 'picture' has no initializer and is not definitely assigned in the constructor.
  picture: ScaledImage;

  videoUrlArray: Array<string> | null | undefined;

  // @ts-expect-error - TS2564 - Property 'campaign' has no initializer and is not definitely assigned in the constructor.
  campaign: string;

  // @ts-expect-error - TS2564 - Property 'businessProfileId' has no initializer and is not definitely assigned in the constructor.
  businessProfileId: number;

  // @ts-expect-error - TS2564 - Property 'locationMode' has no initializer and is not definitely assigned in the constructor.
  locationMode: LocationMode;

  // @ts-expect-error - TS2564 - Property 'locationIds' has no initializer and is not definitely assigned in the constructor.
  locationIds: Array<number>;

  // @ts-expect-error - TS2564 - Property 'dealCategory' has no initializer and is not definitely assigned in the constructor.
  dealCategory: DealCategory;

  // @ts-expect-error - TS2564 - Property 'dynamicLink' has no initializer and is not definitely assigned in the constructor.
  dynamicLink: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'redemptionType' has no initializer and is not definitely assigned in the constructor.
  redemptionType: RedemptionType;

  // @ts-expect-error - TS2564 - Property 'additionalPictures' has no initializer and is not definitely assigned in the constructor.
  additionalPictures: Array<IdentifierPicture>;

  // @ts-expect-error - TS2564 - Property 'inAppLink' has no initializer and is not definitely assigned in the constructor.
  inAppLink: string;

  // @ts-expect-error - TS2564 - Property 'commissionFee' has no initializer and is not definitely assigned in the constructor.
  commissionFee: number;

  // @ts-expect-error - TS2564 - Property 'provisionCalculation' has no initializer and is not definitely assigned in the constructor.
  provisionCalculation: ProvisionCalculation;

  // @ts-expect-error - TS2564 - Property 'defaultDealOption' has no initializer and is not definitely assigned in the constructor.
  defaultDealOption: number;

  dealOptions: DealOption[] | null | undefined;

  dealRules: DealRule[];

  // @ts-expect-error - TS2564 - Property 'hidePrice' has no initializer and is not definitely assigned in the constructor.
  hidePrice: boolean;

  // @ts-expect-error - TS2564 - Property 'cashbackPercent' has no initializer and is not definitely assigned in the constructor.
  cashbackPercent: number;

  // @ts-expect-error - TS2564 - Property 'previewId' has no initializer and is not definitely assigned in the constructor.
  previewId: string;

  constructor(deal: any) {
    Object.assign(this, deal);
    if (Array.isArray(deal.dealOptions)) {
      const defaultOption = deal.dealOptions.find(d => d.isDefault);
      if (defaultOption) {
        this.defaultDealOption = defaultOption.id;
      }
    }
    this.dealRules = Array.isArray(deal.dealRules)
      ? deal.dealRules.map(dr => new DealRule(dr))
      : [];
  }

  get isCouponMsg() {
    return this.dealType === DEAL_TYPE.COUPON_MSG;
  }

  get dealState(): DealRuleState | null | undefined {
    const dealRuleStates = this.dealRules.map(dr => dr.dealRuleState);

    if (dealRuleStates.includes(DEAL_RULE_STATE.ACTIVE)) {
      return DEAL_RULE_STATE.ACTIVE;
    }
    if (dealRuleStates.includes(DEAL_RULE_STATE.SCHEDULED)) {
      return DEAL_RULE_STATE.SCHEDULED;
    }
    if (dealRuleStates.length > 0) {
      return DEAL_RULE_STATE.FINISHED;
    }
    return null;
  }

  get campaigns() {
    const dealRuleCampaigns = this.dealRules.map(dr => dr.campaign).filter(c => c != null);
    return [...new Set([...(this.campaign != null ? [this.campaign] : []), ...dealRuleCampaigns])];
  }

  get commissionFees() {
    const dealRuleCommissionFees = this.dealRules
      .map(dr => dr.commissionFee)
      .filter(c => c != null);
    return [
      ...new Set([
        ...(this.commissionFee != null ? [this.commissionFee] : []),
        ...dealRuleCommissionFees
      ])
    ];
  }
}

export const mockDeal: Deal = new Deal({
  id: 0,
  created: new Date(),
  name: { en: 'Preview Deal', de: 'Vorschau Deal' },
  description: { en: 'Preview Deal', de: 'Vorschau Deal' },
  additionalInfo: { en: 'Preview Deal', de: 'Vorschau Deal' },
  terms: { en: 'Preview Deal', de: 'Vorschau Deal' },
  dealType: 'DEAL',
  discount: 20,
  expiresInDays: 30,
  price: 20,
  discountedPrice: 10,
  limitPerUser: 1,
  picture: defaultPicture,
  businessProfileId: 0,
  locationMode: 'ALL',
  locationIds: [0],
  dealCategory: 'FOOD',
  dynamicLink: '',
  additionalPictures: [{ key: '', url: '' }],
  inAppLink: ''
});
