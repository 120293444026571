/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

import _ from 'lodash';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import { difference } from '@poinz/utils';

// TODO: fix this
export const mapRemoveStylingProfile = (entityWithStylingProfile: any) => {
  const { stylingProfile, ...rest } = entityWithStylingProfile;
  return { ...rest, ...stylingProfile };
};

// TODO: fix this
export const mapBusinessProfileStats = (initial: any, mapper: any) => {
  const list: Array<{
    name: string;
    value: any;
  }> = [];
  const keys = Object.keys(mapper);
  if (initial.length > 0) {
    keys.forEach(value => {
      if (typeof initial[0][value] !== 'undefined') {
        list.push({ name: value, value: initial[0][value] });
      }
    });
  }
  return list;
};

export const clean = (obj: any) => _.omitBy(obj, _.isNil);

export const keyMirror = (obj: Record<string, null>) => _.mapValues(obj, (v, k) => k);

export const destructureDeep = (fieldPath: string, obj: any) => _.get(obj, fieldPath);

export function hasTruthyValue(obj: any) {
  if (obj == null) {
    return false;
  }
  if (Array.isArray(obj)) {
    return obj.length !== 0;
  }
  if (typeof obj !== 'object') {
    return obj === false ? false : obj != null && obj !== '';
  }
  return Object.keys(obj).some(k => hasTruthyValue(obj[k]));
}

export function blockDiff(
  newBlocks?: any[] | null,
  oldBlocks?: any[] | null,
  keepFields: string[] = []
): any[] | null | undefined {
  if (oldBlocks == null || oldBlocks.length === 0) {
    return newBlocks;
  }
  if (newBlocks == null || newBlocks.length === 0) {
    return null;
  }
  const idMap = keyBy(oldBlocks, 'id');
  const copy = [...newBlocks];
  copy.forEach((b, i) => {
    if (idMap[b.id] != null) {
      copy[i] = {
        ...difference(newBlocks[i], idMap[b.id], false),
        ...pick(b, ['id', ...keepFields])
      };
    }
  });
  return copy;
}

export function groupKeysOfTypeInObject(obj: any, type: any, groupKey: string): any {
  const groupValues = Object.values(type);
  const newObj: Record<string, any> = {};
  const newGroupObj: Record<string, any> = {};
  const keyArray = Object.keys(obj);
  keyArray.forEach(key => {
    if (groupValues.includes(key)) {
      newGroupObj[key] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  });
  if (keyArray.length > 0) {
    newObj[groupKey] = newGroupObj;
  }
  return newObj;
}

export const reduceForProperty = (objectList: any[], property: string) =>
  objectList.map(object => object[property]).reduce((prev, curr) => prev + curr, 0);

export const reduceForProperties = (objectList: any[], properties: string[]) => {
  const obj: Record<string, any> = {};
  properties.forEach(property => {
    obj[property] = reduceForProperty(objectList, property);
  });
  return obj;
};
