import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TranslationObject } from 'serviceNew/locale';

import Text from './Text';
import RawText from './RawText';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
// @ts-expect-error - TS2339 - Property 'useFormPadding' does not exist on type '{}'. | TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: ({ useFormPadding }) => (useFormPadding ? theme.spacing(0, 0.5) : undefined),
  },
}));

type Props = {
  label: TranslationObject,
  children: React.ReactNode | null | undefined,
  className?: string,
  useFormPadding: boolean
};

function LabeledValue(props: Props) {
  const { label, children, className, useFormPadding } = props;

  const classes = useStyles({ useFormPadding });

  function renderChildren() {
    if (children == null) {
      return <Text>general.notSet</Text>;
    }
    if (typeof children === 'string') {
      return <RawText>{children}</RawText>;
    }
    return children;
  }

  return (
    <div className={clsx(classes.container, className)}>
      <Text variant="subtitle2" context={label.context}>
        {label.key}
      </Text>
      {renderChildren()}
    </div>
  );
}

LabeledValue.defaultProps = {
  useFormPadding: false,
};

export default LabeledValue;
