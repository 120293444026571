import { keyMirror } from '@poinz/utils';

export const PAYMENT_TYPE = keyMirror({
  ECA: null,
  VIS: null,
  AMX: null,
  PFC: null,
  TWI: null,
  PAP: null,
  PEF: null,
  PAY: null,
  APL: null
});

export type PaymentType = keyof typeof PAYMENT_TYPE;

export interface PaymentMethod {
  id: number;
  aliasCreditCard: string;
  expiryMonthCreditCard: number;
  expiryYearCreditCard: number;
  maskedCreditCard: string;
  paymentType: PaymentType;
  defaultPaymentMethod: boolean;
}
