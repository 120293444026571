import * as React from 'react';
import { InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { FILTER_TYPE, FilterBaseType } from './FilterProps';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    height: theme.spacing(4),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    borderRadius: theme.spacing(1)
  },
  input: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textTransform: 'none',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(0, 0),
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}));

interface InputProps extends Omit<OutlinedInputProps, 'onChange' | 'className'> {
  onChange?: (value: string) => void;
  className?: string;
}

export type FilterInputType<S> = {
  filterType: typeof FILTER_TYPE.INPUT;
  filterProps?: InputProps;
} & Partial<FilterBaseType<S>>;

type Props<V, S> = {
  value: V;
  filter: FilterInputType<S>;
  filterKey: string;
  className?: string;
  containerRef: React.ElementRef<any>;
};

function InputFilter<V, S>({ filter, className, value }: Props<V, S>) {
  const { label, onChange, className: filterClassName, filterProps } = filter;
  const { translate } = useLocale();
  const classes = useStyles();

  function handleChange(event: any) {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <OutlinedInput
      // @ts-expect-error - TS2322 - Type '{ filterProps: any; variant: string; placeholder: any; onChange: (event: any) => void; value: string | V; classes: { root: string; input: string; }; className: string; startAdornment: Element; }' is not assignable to type 'IntrinsicAttributes & OutlinedInputProps'.
      variant="outlined"
      placeholder={label ? translate(label.key, label.context) : ''}
      onChange={handleChange}
      value={value || ''}
      classes={{
        root: classes.root,
        input: classes.input
      }}
      className={clsx(className, filterClassName)}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      }
      {...{ filterProps }}
    />
  );
}

export default InputFilter;
