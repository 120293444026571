import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Grid, Select, MenuItem, FormControl, Typography, FormHelperText } from '@mui/material';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';

import { withContractCompany, ContractCompanyProps } from 'App/providers/ContractCompanyProvider';
import { withBusinessProfile, BusinessProfileProps } from 'App/providers/BusinessProfileProvider';
import { withAdminUser, AdminUserProps } from 'App/providers/AdminUserProvider';
import {
  ExcelSheetData,
  ExcelSheetColumnData,
  ExcelSheetCellData
} from 'App/components/DataExporter/ExcelSheetModel';
import { DataExporter } from 'App/components/DataExporter';
import { ADMINISTRATION } from 'App/routing';
import { Offering } from 'serviceNew/model/offering';
import { BusinessProfile } from 'serviceNew/model/businessProfile';
import offeringApi from 'serviceNew/api/offering';
import businessProfileStatsApi from 'serviceNew/api/businessProfileStats';
import offeringStatsApi from 'serviceNew/api/offeringStats';
import { mapToNameValueObject } from 'serviceNew/util/helperFunctions';
import { BarChart, AuthorizableBarChart, PieChart, TranslatedText } from 'App/components';
import { parseName } from '../../../../../util/StringHelper';

import StatsCardList from '../../../../components/StatsCardList';
import {
  ResolutionType,
  CHART_STYLING,
  BUSINESS_PROFILE_STATS
} from '../../../../../constants/general';
import ActivityTimeLineInput from '../../../../components/ActivityTimelineInput';
import { DashboardCardHeader, DashboardCardBody } from '../../../../components/DashboardCard';

type SimpleChartDataType = {
  name: string;
  value: number | string;
};

type Props = LocaleProps & ContractCompanyProps & BusinessProfileProps & AdminUserProps;

type State = {
  resolution: ResolutionType;
  endDate: string;
  startDate: string;
  businessProfile: BusinessProfile | null | undefined;
  offeringId: number | null | undefined;
  offeringList: Offering[];
  activityTimeline: Array<any>;
  overview: Array<any>;
  genderDistribution: Array<SimpleChartDataType>;
  ageDistribution: Array<SimpleChartDataType>;
  registeredDistribution: Array<SimpleChartDataType>;
  pointDistribution: Array<SimpleChartDataType>;
  openingCode: Array<SimpleChartDataType>;
  stateActivityTimeline: string | null | undefined;
};

const today = moment();
const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
const startRange = today.subtract(3, 'month').format('YYYY-MM-DD');

const mapBusinessProfileStats = (name: string, stats: any) => mapToNameValueObject(stats?.[name]);

class BusinessProfileStats extends Component<Props, State> {
  // @ts-expect-error - TS2416 - Property 'state' in type 'BusinessProfileStats' is not assignable to the same property in base type 'Component<Props, State, any>'.
  state = {
    resolution: 'WEEKLY',
    endDate: yesterday,
    startDate: startRange,
    businessProfile: null,
    offeringId: null,
    offeringList: [],
    activityTimeline: [],
    overview: [],
    genderDistribution: [],
    ageDistribution: [],
    registeredDistribution: [],
    pointDistribution: [],
    openingCode: [],
    stateActivityTimeline: null
  };

  chartList = {
    SUPER_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser', 'numLostUser'],
    POINZ_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser'],
    PARTNER_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser'],
    // flow was going on error because the UserRole type must have also the USER prop
    USER: []
  };

  componentDidMount() {
    const { businessProfileContext } = this.props;
    const { businessProfileId } = businessProfileContext;

    if (businessProfileId) {
      this.setBusinessProfile(businessProfileId);
      this.getBpActivityTimeline({
        id: businessProfileId,
        ...this.state
      });
      this.getBpOverview({ id: businessProfileId });
      this.loadOfferings();
    }
  }

  async setBusinessProfile(id: any) {
    const { getBusinessProfile } = this.props.businessProfileContext;
    const businessProfile = await getBusinessProfile(id);
    this.setState({ businessProfile });
  }

  componentDidUpdate(prevProps: Props) {
    const newBpId = this.props.businessProfileContext.businessProfileId;
    if (prevProps.businessProfileContext.businessProfileId !== newBpId) {
      this.loadOfferings();

      this.setBusinessProfile(newBpId);

      if (newBpId) {
        this.getBpActivityTimeline({
          id: newBpId,
          ...this.state
        });
        this.getBpOverview({ id: newBpId });
      }
    }
  }

  getBpOverview = async (params: any) => {
    try {
      const result = await businessProfileStatsApi.getByBpId(params);
      this.setState({
        overview: mapToNameValueObject(result),
        genderDistribution: mapBusinessProfileStats('genderDistribution', result),

        ageDistribution: mapBusinessProfileStats('ageDistribution', result),
        registeredDistribution: mapBusinessProfileStats('registeredDistribution', result),
        openingCode: mapBusinessProfileStats('openingCode', result)
      });
    } catch (e: any) {
      //
    }
  };

  getBpActivityTimeline = async (params: any) => {
    try {
      const result = await businessProfileStatsApi.getActivityTimeline(params);
      const activityTimeline = result.reverse();
      this.setState({ activityTimeline });
    } catch (e: any) {
      //
    }
  };

  getOfferingOverview = async (params: any) => {
    const result = await offeringStatsApi.getByOfferingId(params);
    this.setState({
      overview: mapToNameValueObject(result),
      pointDistribution: mapBusinessProfileStats('pointDistribution', result),
      genderDistribution: mapBusinessProfileStats('genderDistribution', result),

      ageDistribution: mapBusinessProfileStats('ageDistribution', result),
      registeredDistribution: mapBusinessProfileStats('registeredDistribution', result),
      openingCode: mapBusinessProfileStats('openingCode', result)
    });
  };

  getOfferingActivityTimeline = async (params: any) => {
    const result = await offeringStatsApi.getActivityTimeline(params);
    const activityTimeline = result.reverse();
    this.setState({ activityTimeline });
  };

  loadOfferings = async () => {
    const { businessProfileId } = this.props.businessProfileContext;
    if (businessProfileId) {
      const retrievedOfferings = await offeringApi.getOfferings(businessProfileId);
      this.setState({ offeringList: retrievedOfferings });
    }
  };

  handleChange = (entity: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value !== '' ? event.target.value : null;
    const { businessProfileContext } = this.props;
    const { selectBusinessProfile } = businessProfileContext;

    if (entity === 'offering' && value) {
      this.setState({ offeringId: parseInt(value, 10) });
      this.getOfferingOverview({ id: parseInt(value, 10) });
      this.getOfferingActivityTimeline({
        id: parseInt(value, 10),
        ...this.state
      });
    }
    if (entity === 'businessProfile' || !value) {
      if (value) {
        selectBusinessProfile(parseInt(value, 10));
      }
      this.setState({ offeringId: null });
    }
  };

  handleChangeDate = (name: string, value: string) => {
    const { businessProfileContext } = this.props;
    const { businessProfileId } = businessProfileContext;
    const { offeringId } = this.state;
    // @ts-expect-error - TS2345 - Argument of type '{ [x: string]: string; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, keyof State> | null) | Pick<...> | null'.
    this.setState({ [name]: value });
    if (offeringId) {
      this.getOfferingActivityTimeline({
        id: offeringId,
        ...this.state,
        [name]: value
      });
    } else if (businessProfileId) {
      this.getBpActivityTimeline({
        id: businessProfileId,
        ...this.state,
        [name]: value
      });
    }
  };

  getActivityTimelineSheets = (chartList: any): Array<ExcelSheetData> => {
    const { adminUserContext, translate } = this.props;
    const { activityTimeline } = this.state;
    // get the name
    const name: string = translate('stats.activityTimeline');
    // get the name of the columns based on the current user role logged in
    const dataColumns: Array<string> = chartList[adminUserContext.userRole];
    // create the list of ExcelSheetColumnData with custom Date and data columns translated
    const columns: Array<ExcelSheetColumnData> = [
      { title: 'Date' },
      ...dataColumns.map(col => ({ title: translate(CHART_STYLING.activityTimeline[col].label) }))
    ];
    // create the list of rows ExcelSheetCellData from the chart data
    const data: Array<Array<ExcelSheetCellData>> = activityTimeline.map(row => [
      // @ts-expect-error - TS2339 - Property 'dateDay' does not exist on type 'never'.
      { value: row.dateDay },
      ...dataColumns.map(col => ({ value: row[col] }))
    ]);
    // create the sheet to be exported
    const sheet: ExcelSheetData = { name, columns, data };
    const sheets: Array<ExcelSheetData> = [sheet];
    return sheets;
  };

  setPermissions(permissions: any) {
    if (permissions?.ENABLE_POS_ACTIVE_USERS) {
      this.chartList.PARTNER_ADMIN.push('numActiveUserPos');
    }
  }

  render() {
    // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'Readonly<Props>'.
    const { contractCompany, translate, language, businessProfileContext } = this.props;
    const {
      resolution,
      endDate,
      startDate,
      offeringId,
      offeringList,
      overview,
      activityTimeline,
      stateActivityTimeline,
      genderDistribution,
      ageDistribution,
      openingCode,
      pointDistribution,
      businessProfile
    } = this.state;

    // @ts-expect-error - TS2339 - Property 'permissions' does not exist on type 'never'.
    this.setPermissions(businessProfile?.permissions);

    const { businessProfileId, businessProfiles } = businessProfileContext;
    // TODO: handle this in separate component
    if (!contractCompany) {
      return <Redirect to={{ pathname: `${ADMINISTRATION}/contract-company/search` }} />;
    }

    return (
      <div>
        <Grid container spacing={2}>
          {businessProfiles && (
            <Grid item xs={12} sm={12}>
              {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node', but multiple children were provided. */}
              <DashboardCardHeader>
                <FormControl style={{ margin: 24, minWidth: 200, textAlign: 'left' }}>
                  <Select
                    variant="standard"
                    disabled={businessProfiles.length === 1}
                    value={businessProfileId || ''}
                    displayEmpty
                    // @ts-expect-error - TS2345 - Argument of type 'SelectChangeEvent<number>' is not assignable to parameter of type 'ChangeEvent<HTMLInputElement>'.
                    onChange={e => this.handleChange('businessProfile', e)}
                  >
                    <MenuItem key="empty" disabled value="">
                      <TranslatedText text={{ key: 'general.select' }} />
                    </MenuItem>
                    {businessProfiles.map(e => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ textAlign: 'left' }}>
                    {translate('businessProfile.businessProfile')}
                  </FormHelperText>
                </FormControl>
                {businessProfileId && (
                  <FormControl style={{ margin: 24, minWidth: 200, textAlign: 'left' }}>
                    <Select
                      variant="standard"
                      displayEmpty
                      value={offeringId || ''}
                      // @ts-expect-error - TS2345 - Argument of type 'SelectChangeEvent<"">' is not assignable to parameter of type 'ChangeEvent<HTMLInputElement>'.
                      onChange={e => this.handleChange('offering', e)}
                      disabled={!businessProfileId}
                    >
                      <MenuItem key="empty" value="">
                        <TranslatedText text={{ key: 'general.showAll' }} />
                      </MenuItem>
                      {offeringList.map(e => (
                        // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'. | TS2339 - Property 'id' does not exist on type 'never'.
                        <MenuItem key={e.id} value={e.id}>
                          {/* @ts-expect-error - TS2339 - Property 'name' does not exist on type 'never'. */}
                          {parseName(e.name, language)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ textAlign: 'left' }}>
                      {translate('offering.offerings')}
                    </FormHelperText>
                  </FormControl>
                )}
              </DashboardCardHeader>
            </Grid>
          )}
          {businessProfileId && (
            <>
              <Grid item xs={12} sm={12}>
                <DashboardCardBody>
                  {overview.length > 0 ? (
                    <StatsCardList
                      statsList={overview}
                      statsFormat={BUSINESS_PROFILE_STATS}
                      vertical={false}
                    />
                  ) : (
                    <Typography align="left">
                      <TranslatedText text={{ key: 'stats.noDataAvailable' }} />
                    </Typography>
                  )}
                </DashboardCardBody>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node', but multiple children were provided. */}
                <DashboardCardBody>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography align="left" variant="h5">
                        <TranslatedText text={{ key: 'stats.activityTimeline' }} />
                      </Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                      <ActivityTimeLineInput
                        endDate={endDate}
                        startDate={startDate}
                        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"DAILY" | "WEEKLY" | "MONTHLY" | undefined'.
                        resolution={resolution}
                        handleChangeDate={this.handleChangeDate}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      {!stateActivityTimeline && activityTimeline.length > 0 ? (
                        <>
                          {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                          <Grid item xs={12} align="right">
                            <DataExporter
                              sheets={this.getActivityTimelineSheets(this.chartList)}
                              fileName={translate('stats.activityTimeline')}
                            />
                          </Grid>
                          <AuthorizableBarChart
                            title=""
                            height={400}
                            name="activityTimeline"
                            chartData={activityTimeline}
                            chartList={this.chartList}
                            xaxis="dateDay"
                            resolution={this.state.resolution}
                            tooltip
                            legend
                          />
                        </>
                      ) : (
                        <Typography align="left">
                          <TranslatedText text={{ key: 'stats.noDataAvailable' }} />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </DashboardCardBody>
              </Grid>
              <Grid item xs={6} sm={3}>
                <DashboardCardBody>
                  <PieChart
                    name="openingCode"
                    title="stats.openingCode"
                    chartData={openingCode}
                    tooltip
                    legend
                  />
                </DashboardCardBody>
              </Grid>
              <Grid item xs={6} sm={3}>
                <DashboardCardBody>
                  <PieChart
                    name="genderDistribution"
                    title="stats.genderDistribution"
                    chartData={genderDistribution}
                    tooltip
                    legend
                  />
                </DashboardCardBody>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardCardBody>
                  <BarChart
                    name="ageDistribution"
                    title="stats.ageDistribution"
                    chartData={ageDistribution}
                    tooltip
                  />
                </DashboardCardBody>
              </Grid>
              {offeringId && (
                <Grid item xs={12} sm={6}>
                  <DashboardCardBody>
                    <BarChart
                      name="pointDistribution"
                      title="stats.pointDistribution"
                      chartData={pointDistribution}
                      tooltip
                    />
                  </DashboardCardBody>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withAdminUser(
  // @ts-expect-error
  withBusinessProfile(withContractCompany(withLocale(BusinessProfileStats)))
);
