import * as React from 'react';
import _ from 'lodash';
import { withStyles, WithStyles } from '@mui/styles';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { TranslationKey } from 'serviceNew/locale';
import DataExporter from './DataExporter';
import {
  ExportableDataFormatter,
  ExcelSheetCellData,
  ExcelSheetColumnData,
} from './ExcelSheetModel';

const styles = () => ({
  wrapper: {
    position: 'relative',
    '& .default-generate-excel-button': {
      position: 'absolute',
      right: 0,
    },
  },
});

export type ExportableTableField = {
  name: string,
  label: TranslationKey,
  formatter?: ExportableDataFormatter
};

type WrappedProps = Record<any, any>;

// @ts-expect-error - TS2344 - Type '() => { wrapper: { position: string; '& .default-generate-excel-button': { position: string; right: number; }; }; }' does not satisfy the constraint 'ClassKeyInferable<any, any>'.
type HOCComponentProps = LocaleProps & WithStyles<typeof styles>;

export default function tableWithExportableData<Props extends WrappedProps, Comp extends React.ComponentType<Props>>(
  Component: Comp,
  dataPath: string,
  fields: Array<ExportableTableField>,
  title: TranslationKey,
): React.ComponentType<Props> {
  class WrappedTableWithExportableData extends React.PureComponent<Props & HOCComponentProps> {
    getColumns = (): Array<ExcelSheetColumnData> => {
      const { translate } = this.props;
      return fields.map(field => ({ title: translate(field.label) }));
    };

    getData = (): Array<Array<ExcelSheetCellData>> => {
      const values = _.get(this.props, dataPath, []);
// @ts-expect-error - TS2339 - Property 'map' does not exist on type 'never[] | Exclude<Readonly<Props & LocaleProviderProps & { classes: ClassNameMap<never>; }>[string], undefined>'.
      return values.map(val => this.getRow(val));
    };

    getRow = (val: any): Array<ExcelSheetCellData> => fields.map(field => ({ value: this.getFieldValue(val, field) }));

    getFieldValue = (val: any, field: ExportableTableField): string | number => {
      const fieldValue = val[field.name];
      if (!fieldValue) {
        return '-';
      }
      return field.formatter ? field.formatter(fieldValue) : fieldValue;
    };

    render() {
      const {
        classes,
        translate,
        // eslint-disable-next-line no-unused-vars
        language,
        // eslint-disable-next-line no-unused-vars
        setLanguage,
        ...otherProps
      } = this.props;
      const name = translate(title);
      const columns = this.getColumns();
      const data = this.getData();
      const sheet = { name, columns, data } as const;
      const sheets = [sheet];
      return (
        <div className={classes.wrapper}>
          <DataExporter sheets={sheets} fileName={name} />
{ /* @ts-expect-error - TS2322 - Type 'Omit<Readonly<Props & LocaleProviderProps & { classes: ClassNameMap<never>; }>, "translate" | "language" | "classes" | "setLanguage">' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<Comp, Props>'. */}
          <Component {...otherProps} />
        </div>
      );
    }
  }

  const LocaleComponent = withLocale(WrappedTableWithExportableData);
// @ts-expect-error - TS2345 - Argument of type '() => { wrapper: { position: string; '& .default-generate-excel-button': { position: string; right: number; }; }; }' is not assignable to parameter of type 'Styles<any, any, string>'.
  const StyledComponent = withStyles(styles)(LocaleComponent);
  return StyledComponent;
}
