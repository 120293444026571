import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  formRow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-1)
  },
  children: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(1),
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    flexBasis: 220,
    flexGrow: 1,
    flexShrink: 0
  }
}));

type Props = {
  className?: string | null | undefined,
  children: React.ReactNode
};

function FormRow(props: Props) {
  const classes = useStyles();

  const { children, className } = props;

  return (
    <div className={clsx(classes.formRow, className)}>
      {React.Children.toArray(children).map(child =>
// @ts-expect-error - TS2769 - No overload matches this call. | TS2339 - Property 'props' does not exist on type 'string | number | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal'.
        React.cloneElement(child, { className: clsx(classes.children, child.props.className) })
      )}
    </div>
  );
}

export default FormRow;
