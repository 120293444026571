import {createMultipartRequest, getIdFromHeaders} from '@poinz/api';
import { IdType } from '@poinz/api';
import { Location } from 'serviceNew/model/location';
import { getMessage } from 'serviceNew/notifications';
import { callEndpoint, callNotificationEndpoint } from './apiClient';
import { mapFormWithImages } from '../picture';

const ROOT = '/business-profiles';
export const LOCATIONS = 'locations';
const ENTITY = 'location.location';

export const createLocationFormData = (location: Location) => {
  const multiPartData: Array<{
    data: File,
    name: string
  }> = [];
  const mappedForm = mapFormWithImages({ form: location, imageFields: ['picture'], multiPartData });
  return createMultipartRequest('locationForm', mappedForm, multiPartData);
};

async function create(
  params: {
    businessProfileId: IdType,
    values: Partial<Location>
  },
): Promise<IdType> {
  const { values, businessProfileId } = params;
// @ts-expect-error - TS2345 - Argument of type 'Partial<Location>' is not assignable to parameter of type 'Location'.
  const formData = createLocationFormData(values);
  const result = await callNotificationEndpoint(
    `${ROOT}/${businessProfileId}/${LOCATIONS}`,
    'POST',
    {
      headers: new Headers(),
      authenticated: true,
      isMultiPart: true,
      body: formData
    },
    getMessage.create(ENTITY)
  );
  return getIdFromHeaders(result.headers);
}

async function remove(
  params: {
    businessProfileId: IdType,
    locationId: IdType
  },
): Promise<void> {
  const { businessProfileId, locationId } = params;
  await callNotificationEndpoint(
    `${ROOT}/${businessProfileId}/${LOCATIONS}/${locationId}`,
    'DELETE',
    {
      headers: new Headers(),
      authenticated: true
    },
    getMessage.delete(ENTITY)
  );
}

async function getPdf(
  params: {
    businessProfileId: IdType,
    locationId: IdType
  },
): Promise<Blob> {
  const { businessProfileId, locationId } = params;
  const result = await callEndpoint(
    `${ROOT}/${businessProfileId}/${LOCATIONS}/${locationId}/pdf`,
    'GET',
    {
      headers: new Headers(),
      authenticated: true,
      isBinary: true
    }
  );
// @ts-expect-error - TS2322 - Type 'Blob | undefined' is not assignable to type 'Blob'.
  return result.blob;
}

async function getImage(
  params: {
    businessProfileId: IdType,
    locationId: IdType
  },
): Promise<Blob> {
  const { businessProfileId, locationId } = params;
  const result = await callEndpoint(
    `${ROOT}/${businessProfileId}/${LOCATIONS}/${locationId}/image`,
    'GET',
    {
      headers: new Headers(),
      authenticated: true,
      isBinary: true
    }
  );
// @ts-expect-error - TS2322 - Type 'Blob | undefined' is not assignable to type 'Blob'.
  return result.blob;
}

export default {
  create,
  remove,
  getPdf,
  getImage
};
