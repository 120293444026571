import React, { PureComponent } from 'react';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { withContractCompany, ContractCompanyProps } from 'App/providers/ContractCompanyProvider';
import { withBusinessProfile, BusinessProfileProps } from 'App/providers/BusinessProfileProvider';
import { Pagination } from 'App/components';
import messageStatsApi from 'serviceNew/api/messageStats';
import { getPagination, initialPagination } from 'serviceNew/util/helperFunctions';
import { GridContainer, GridItem } from 'App/components/FlexGridContainer';
import { moment } from 'serviceNew/locale/dates';
import { ResolutionType } from '../../../../constants/general';
import { BusinessProfileSelection } from '../components/FilterFields';
import { DashboardCardHeader } from '../../../components/DashboardCard';
import MessageStatsTable from './components/MessageStatsTable';

type Props = LocaleProps & ContractCompanyProps & BusinessProfileProps;

type State = {
  selectedMessage: number | null | undefined;
  resolution: ResolutionType;
  overview: Array<any>;
  overviewLoading: boolean;
  messageDetail: Array<any>;
  messageDetailLoading: boolean;
  currentPage: number;
  totalElements: number;
  totalPages: number;
};

class MessageStatsUnconnected extends PureComponent<Props, State> {
  // @ts-expect-error - TS2416 - Property 'state' in type 'MessageStatsUnconnected' is not assignable to the same property in base type 'PureComponent<Props, State, any>'.
  state = {
    selectedMessage: null,
    resolution: 'DAILY',
    overview: [],
    overviewLoading: false,
    messageDetail: [],
    messageDetailLoading: false,
    ...initialPagination
  };

  async componentDidMount() {
    const { businessProfileContext } = this.props;
    const { businessProfileId } = businessProfileContext;

    if (businessProfileId) {
      await this.getMessageStatsOverview({ id: businessProfileId });
    }
  }

  getMessageStatsOverview = async (params: any) => {
    this.setState({ overviewLoading: true });
    const { body, headers } = await messageStatsApi.getByBusinessProfileId(params);
    const data = {
      overview: body,
      ...getPagination(headers)
    } as const;
    this.setState({ ...data, overviewLoading: false });
  };

  getStatsMessageDetails = async (params: any) => {
    this.setState({ messageDetailLoading: true });
    const result = await messageStatsApi.getByMessageId(params);
    const messageDetail = {
      ...result,
      activityTimelineData: [...result.activityTimelineData.reverse()]
    } as const;
    this.setState({
      messageDetail,
      messageDetailLoading: false
    });
  };

  handleBpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { businessProfileContext } = this.props;
    businessProfileContext.selectBusinessProfile(parseInt(e.target.value, 10));
    this.getMessageStatsOverview({ id: parseInt(e.target.value, 10) });
  };

  handlePageChange = (toPage: any) => {
    const { businessProfileContext } = this.props;
    const { businessProfileId } = businessProfileContext;

    this.setState({ selectedMessage: null });

    if (businessProfileId) {
      this.getMessageStatsOverview({ id: businessProfileId, pageNumber: toPage });
    }
  };

  handleSelectMessage = async ({ id, startDate, endDate }) => {
    const { businessProfileContext } = this.props;
    const { businessProfileId } = businessProfileContext;

    const yesterday = moment().subtract(1, 'days');

    if (this.state.selectedMessage !== id && businessProfileId) {
      await this.getStatsMessageDetails({
        id: businessProfileId,
        messageId: id,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate:
          endDate != null
            ? moment.min([endDate, yesterday]).format('YYYY-MM-DD')
            : yesterday.format('YYYY-MM-DD'),
        ...this.state
      });
    }
    this.setState(state => ({ selectedMessage: state.selectedMessage === id ? null : id }));
  };

  render() {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const { language, businessProfileContext } = this.props;

    const {
      selectedMessage,
      overview,
      overviewLoading,
      messageDetail,
      messageDetailLoading,
      currentPage,
      totalElements,
      totalPages
    } = this.state;

    const { businessProfileId, businessProfiles } = businessProfileContext;

    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <DashboardCardHeader>
              <BusinessProfileSelection
                selectedBusinessProfileId={businessProfileId}
                handleBpChange={this.handleBpChange}
                businessProfilesList={businessProfiles}
              />
            </DashboardCardHeader>
          </GridItem>
          {businessProfileId != null && (
            <GridItem xs={12}>
              <MessageStatsTable
                messageStatsOverview={overview}
                handleSelectMessage={this.handleSelectMessage}
                selectedMessage={selectedMessage}
                messageDetails={messageDetail}
                detailLoading={messageDetailLoading}
                overviewLoading={overviewLoading}
                lang={language}
              />
              <div style={{ marginTop: 24, textAlign: 'center' }}>
                <Pagination
                  currentPage={currentPage}
                  totalElements={totalElements}
                  totalPages={totalPages}
                  handlePageChange={this.handlePageChange}
                  disabled={overviewLoading}
                  // TODO: replace this prop of the component because this value is returned by the api
                  items={10}
                />
              </div>
            </GridItem>
          )}
        </GridContainer>
      </>
    );
  }
}

export default withBusinessProfile(withContractCompany(withLocale(MessageStatsUnconnected)) as any);
