import * as React from 'react';
import ReactCrop from 'react-image-crop';
import { Dialog, DialogActions, Grid } from '@mui/material';
import { Button } from '.';

import 'react-image-crop/dist/ReactCrop.css';

export type Crop = {
  x?: number,
  y?: number,
  width: number,
  height?: number,
  aspect?: number
};

type Props = {
  image: string,
  crop?: Crop | null | undefined,
  onCropChange: (crop: Crop) => unknown,
  isOpen: boolean,
  onClose: () => void
};

function ImageCropDialog(props: Props) {
  const { image, crop: initialCrop, onCropChange, isOpen, onClose } = props;

  const imageTag = React.useRef<HTMLImageElement | null | undefined>(undefined);
  const [crop, setCrop] = React.useState<Crop | null | undefined>(initialCrop);

  React.useEffect(() => {
    setCrop(initialCrop);
  }, [initialCrop]);

  function handleSubmit() {
    const { naturalWidth, naturalHeight } = imageTag.current || {};
// @ts-expect-error - TS2345 - Argument of type '{ imageWidth: number | undefined; imageHeight: number | undefined; x?: number | undefined; y?: number | undefined; width?: number | undefined; height?: number | undefined; aspect?: number | undefined; }' is not assignable to parameter of type 'Crop'.
    onCropChange({ ...crop, imageWidth: naturalWidth, imageHeight: naturalHeight });
    onClose();
  }

  return (
    <Dialog open={isOpen} {...{ onClose }}>
      <Grid sx={{ maxHeight: '70vh', overflow: 'auto' }}>
        <ReactCrop
// @ts-expect-error - TS2769 - No overload matches this call.
          onImageLoaded={img => {
            imageTag.current = img;
          }}
          onChange={(_, newCrop) => setCrop(newCrop)}
          {...{ crop }}
        >
          <img src={image} />
        </ReactCrop>
      </Grid>
      <DialogActions>
        <Button onClick={onClose} label={{ key: 'general.cancel' }} />
        <Button onClick={handleSubmit} label={{ key: 'general.done' }} />
      </DialogActions>
    </Dialog>
  );
}

export default ImageCropDialog;
