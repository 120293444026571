import { PoinzApiClient } from '../../api';
import locations from './locations';
import paymentMethods from './paymentMethods';

const WorldLineClient = (apiClient: PoinzApiClient) => ({
  locations: locations(apiClient),
  paymentMethods: paymentMethods(apiClient)
});

export default WorldLineClient;
