import * as React from 'react';
import { FormikProps, FormikActions } from 'formik';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import { Prompt } from 'react-router';
import clsx from 'clsx';
import { useLocale } from 'App/providers/LocaleProvider';
import FormRow from './FormRow';
import Button from './Button';
import Title from './Title';
import Paper from './Paper';
import GenericForm from './forms/GenericForm';
import MultiLanguageForm from './MultiLanguageForm';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'. | TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(-1)
  },
  buttonContainerFixed: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(0)
  },
  button: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  },
  multiLanguage: {
    padding: 0
  },
  footer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: 80,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    // @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[10],
    zIndex: 1000,
    transform: 'translateY(80px)',
    opcaity: 0,
    transitionDuration: '500ms',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(0, 3)
  },
  footerVisible: {
    opcaity: 1,
    transform: 'translateY(0px)'
  }
}));

export type ButtonItem = JSX.LibraryManagedAttributes<
  typeof Button,
  React.ComponentProps<typeof Button>
>;

type FormProps<T> = {
  title?: TranslationObject;
  className: string;
  // @ts-expect-error - TS2314 - Generic type 'FormikProps' requires 1 type argument(s).
  renderChildren?: (formProps: FormikProps) => React.ReactElement;
  children?:
    | Array<React.ReactElement<React.ComponentProps<typeof FormRow>>>
    | React.ReactElement<React.ComponentProps<typeof FormRow>>;
  submitLabel: TranslationObject;
  initialValues?: Partial<T> | null | undefined;
  // @ts-expect-error - TS2314 - Generic type 'FormikActions<Values>' requires 1 type argument(s).
  onSubmit: (values: Partial<T>, allValues: T, actions: FormikActions) => Promise<void>;
  buttons: Array<ButtonItem>;
  isUpdate: boolean;
  showPaper: boolean;
  readOnly?: boolean;
  multiLanguage: boolean;
  submitButtonProps?: Partial<
    JSX.LibraryManagedAttributes<typeof Button, React.ComponentProps<typeof Button>>
  >;
  buttonsFixed: boolean;
  useUnloadAlert: boolean;
  formikRef?: React.ElementRef<any>;
  enableReinitialize?: boolean;
  noValidate?: boolean;
};

function FormBody({ formProps, renderChildren, children, renderButtonContainer, useUnloadAlert }) {
  const { translate } = useLocale();
  const { hasChangedValues, isSubmitting } = formProps;
  const renderedChildren = renderChildren ? renderChildren(formProps) : children;
  return (
    <>
      {renderedChildren}
      {renderButtonContainer(formProps)}
      <Prompt
        message={translate('navigation.leaveForm')}
        when={useUnloadAlert && hasChangedValues && !isSubmitting}
      />
    </>
  );
}

function Form<T extends Record<any, any>>(props: FormProps<T>) {
  const {
    initialValues,
    title,
    showPaper,
    isUpdate,
    onSubmit,
    buttons,
    children,
    renderChildren,
    submitLabel,
    submitButtonProps,
    readOnly,
    multiLanguage,
    buttonsFixed,
    useUnloadAlert,
    formikRef = null,
    enableReinitialize = false,
    noValidate
  } = props;

  const classes = useStyles();

  function onClickAuxButtons(originalCallback: any) {
    const fn = async (event: React.SyntheticEvent) => {
      try {
        if (originalCallback) {
          await originalCallback(event);
        }
      } catch (e: any) {
        // nothing we can do
      }
    };
    return fn;
  }

  function renderButtons() {
    return buttons.map(({ onClick, ...restButtonItem }) => (
      <Button
        key={restButtonItem.label.key}
        className={classes.button}
        type="button"
        color="secondary"
        onClick={onClickAuxButtons(onClick)}
        variant={restButtonItem.variant === 'primary' ? 'contained' : 'text'}
        {...restButtonItem}
      />
    ));
  }

  function renderButtonContainer({ isSubmitting, hasChangedValues }) {
    const buttonRow = !readOnly && (
      <div className={clsx(classes.buttonContainer, buttonsFixed && classes.buttonContainerFixed)}>
        <Button
          disabled={isSubmitting}
          className={classes.button}
          color="primary"
          variant="contained"
          type="submit"
          label={submitLabel}
          {...submitButtonProps}
        />
        {renderButtons()}
      </div>
    );

    if (buttonsFixed && !readOnly) {
      return (
        <div className={clsx(classes.footer, hasChangedValues && classes.footerVisible)}>
          {buttonRow}
        </div>
      );
    }

    return buttonRow;
  }

  function renderForm() {
    const WrapComp = showPaper ? Paper : 'div';
    const MultiLanguage = multiLanguage ? MultiLanguageForm : 'div';

    return (
      <WrapComp className={clsx(multiLanguage && classes.multiLanguage)}>
        <MultiLanguage>
          {/* @ts-expect-error - TS2322 - Type '{ children: (formProps: any) => Element; formikRef: unknown; enableReinitialize: boolean; noValidate: boolean; onSubmit: (values: Partial<T>, allValues: T, actions: any) => Promise<...>; initialValues: Partial<...> | ... 1 more ... | undefined; isUpdate: boolean; }' is not assignable to type 'Pick<Props<T>, "children" | "className" | "onSubmit" | "noValidate" | "initialValues" | "formikRef" | "enableReinitialize">'. */}
          <GenericForm
            {...{ onSubmit, initialValues, isUpdate }}
            formikRef={formikRef}
            enableReinitialize={enableReinitialize}
            noValidate={!!noValidate}
          >
            {formProps => (
              <FormBody
                {...{
                  useUnloadAlert,
                  renderButtonContainer,
                  renderChildren,
                  children,
                  readOnly,
                  formProps
                }}
              />
            )}
          </GenericForm>
        </MultiLanguage>
      </WrapComp>
    );
  }

  return (
    <div>
      {title && <Title label={title} />}
      {renderForm()}
    </div>
  );
}

Form.defaultProps = {
  className: '',
  buttons: [],
  submitLabel: { key: 'general.submit' },
  isUpdate: false,
  showPaper: true,
  multiLanguage: false,
  submitButtonProps: {},
  buttonsFixed: false,
  useUnloadAlert: false
};

export default Form;
