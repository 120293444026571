import { PoinzApiClient } from '../../api';
import { Commission, CommissionDto } from './types';
import { ROOT } from './config';

const COMMISSIONS = 'commissions';
const apiRoot = `${ROOT}/${COMMISSIONS}`;

const commissionEndpoints = {
  get: `${apiRoot}/:commissionId`,
  update: `${apiRoot}/:commissionId`
};

const getCommission =
  (apiClient: PoinzApiClient) =>
  async (commissionId: number): Promise<Commission> => {
    const result = await apiClient.get(commissionEndpoints.get, {
      pathParams: { commissionId }
    });
    return result.body;
  };

const updateCommission =
  (apiClient: PoinzApiClient) =>
  async (commissionId: number, body: CommissionDto): Promise<void> => {
    const result = await apiClient.patch(commissionEndpoints.update, {
      pathParams: { commissionId },
      body
    });
  };

export default (apiClient: PoinzApiClient) => ({
  getCommission: getCommission(apiClient),
  updateCommission: updateCommission(apiClient)
});
