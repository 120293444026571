import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { RowDataDisplay } from '.';

const ROW_HEIGHT = 90;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1.2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1.2)
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    height: ROW_HEIGHT,
    borderRadius: 12,
    alignItems: 'center',
    padding: 0,
    backgroundColor: 'white',
    cursor: 'pointer',
    border: 'none',
    outline: 'none'
  },
  expansion: {
    display: 'flex',
    width: '101%',
    height: 'auto',
    borderRadius: 12,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-0.5),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(3),
    backgroundColor: 'white',
    zIndex: 5,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)'
  }
}));

type Props = {
  data?: any[];
  children?: React.ReactNode;
};

function ExpandableRow(props: Props) {
  const { data, children } = props;

  const classes = useStyles();

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classes.container}>
      <button type="button" onClick={toggleExpanded} className={classes.tableRow}>
        <RowDataDisplay data={data} />
      </button>
      {isExpanded && children && <div className={classes.expansion}>{children}</div>}
    </div>
  );
}

export default ExpandableRow;
