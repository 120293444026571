import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-2),
    flexDirection: 'column'
  }
}));

type Props = {
  children: React.ReactNode;
};

function FlexContainer(props: Props) {
  const { children } = props;

  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
}

export default FlexContainer;
