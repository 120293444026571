import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getNewLocation, UrlLocation } from './navigation';

function useNavigation() {
  const history = useHistory();
  const push = React.useCallback(
    (urlLocation: UrlLocation | string) => {
      history.push(
        typeof urlLocation === 'string'
          ? urlLocation
          : getNewLocation(history.location, urlLocation)
      );
    },
    [history]
  );
  const replace = React.useCallback(
    (urlLocation: UrlLocation | string) => {
      history.replace(
        typeof urlLocation === 'string'
          ? urlLocation
          : getNewLocation(history.location, urlLocation)
      );
    },
    [history]
  );

  return { push, replace };
}

export default useNavigation;
