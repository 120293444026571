import { IPasswordStrengthIndicator, IIndicatorColorMap } from './types';

export const getStrengthIndicatroColor = (
  indicatorColorMap: Record<IPasswordStrengthIndicator, IIndicatorColorMap>,
  strength: IPasswordStrengthIndicator
) => indicatorColorMap[strength] || indicatorColorMap[IPasswordStrengthIndicator.NO_PASSWORD];

export const getStrengthIndicatroWidth = (strength: IPasswordStrengthIndicator) => {
  return `${
    (100 /
      (Object.values(IPasswordStrengthIndicator).filter(value => typeof value == 'number').length -
        1)) *
    strength
  }%`;
};

export const getStrengthIndicatrTranslationKey = (
  indicatorTranslationKeysMap: Record<IPasswordStrengthIndicator, string>,
  strength: IPasswordStrengthIndicator
) => indicatorTranslationKeysMap[strength];

export const hasOnlyOneRepeatingCharacter = (inputString: string) => {
  if (!inputString.length) {
    return false; // Empty string is not considered to have one repeating character
  }

  const stringArray = inputString.split('');
  const firstChar = stringArray[0];

  return stringArray.every(char => char === firstChar);
};

// values represented in bits
export const ENTROPY_UP_TO_LIMITS = {
  VERY_WEAK: 35,
  WEAK: 45,
  SOLID: 55,
  STRONG: 65
};

export const getPasswordStrength = ({
  poolSize = 0,
  passwordLength = 0,
  isOneRepeatingChar = false,
  passwordEntropy = 0,
  isKnownPassword = false
}: {
  poolSize?: number;
  passwordLength?: number;
  isOneRepeatingChar: boolean;
  passwordEntropy?: number;
  isKnownPassword: boolean;
}): IPasswordStrengthIndicator => {
  if (!passwordLength || !poolSize) {
    return IPasswordStrengthIndicator.NO_PASSWORD;
  }

  if (isKnownPassword || isOneRepeatingChar || passwordEntropy < ENTROPY_UP_TO_LIMITS.VERY_WEAK) {
    return IPasswordStrengthIndicator.VERY_WEAK;
  }

  if (passwordEntropy < ENTROPY_UP_TO_LIMITS.WEAK) {
    return IPasswordStrengthIndicator.WEAK;
  }

  if (passwordEntropy < ENTROPY_UP_TO_LIMITS.SOLID) {
    return IPasswordStrengthIndicator.SOLID;
  }

  if (passwordEntropy < ENTROPY_UP_TO_LIMITS.STRONG) {
    return IPasswordStrengthIndicator.STRONG;
  }

  if (passwordEntropy >= ENTROPY_UP_TO_LIMITS.STRONG) {
    return IPasswordStrengthIndicator.VERY_STRONG;
  }

  return IPasswordStrengthIndicator.NO_PASSWORD;
};

export const sanitizeToNumber = (value: number) => {
  const isRealNumber = typeof value === 'number' && isFinite(value) && !isNaN(value);
  return isRealNumber ? value : 0;
};

export const PASSWORD_POOLSIZE_OPTIONS = [
  {
    // lowerCase letters
    regex: /[a-z]+/,
    poolSize: 26
  },
  {
    // upperCase letters
    regex: /[A-Z]+/,
    poolSize: 26
  },
  {
    // numbers
    regex: /[0-9]+/,
    poolSize: 10
  },
  {
    // special chars
    regex: /[$@#&!*?%^()\-_+=<>{}[\]:;'"|~`,.\\ ]+/,
    poolSize: 32
  }
];
