import * as React from 'react';
import { useLocale } from 'App/providers/LocaleProvider';
import { TranslationObject } from 'serviceNew/locale';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from '../TextInput';
import { withFieldAdapter, FieldRenderProps } from '../Field';

type Props = {
  readOnly: boolean | null | undefined;
  label: TranslationObject;
  withClear: boolean;
} & JSX.LibraryManagedAttributes<typeof TextInput, React.ComponentProps<typeof TextInput>> &
  FieldRenderProps;

function TextField(props: Props) {
  const { field, meta, form, readOnly, disabled, withClear, ...restProps } = props;
  const { error, touched, submitting } = meta;

  const { name, value } = field;

  const { translate } = useLocale();

  return (
    <TextInput
      error={!!error && !!touched}
      disabled={submitting || disabled}
      type="text"
      {...field}
      {...restProps}
      value={value || ''}
      helperText={touched && error && translate(error.key, error.context)}
      maxRows={6}
      InputProps={{
        readOnly,
        endAdornment:
          withClear && value && value !== '' ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="clear input"
                onClick={() => form.setFieldValue(name, undefined)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
      }}
    />
  );
}

TextField.defaultProps = {
  required: false,
  withClear: false
};

export default withFieldAdapter(TextField);
