import { keyMirror } from '@poinz/utils';

const IMAGE_SCALE = keyMirror({
  X1: null,
  X2: null,
  X3: null,
  X4: null
});

export type ImageScale = keyof typeof IMAGE_SCALE;

export interface ScaledImage {
  sourceUrl: string;
  width: number;
  height: number;
  mainColor: string;
  availableScales: ImageScale[];
}
