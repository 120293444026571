import * as React from 'react';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  img: {
    // @ts-expect-error - TS2339 - Property 'url' does not exist on type '{}'.
    backgroundImage: ({ url }) => `url(${url})`,
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // @ts-expect-error - TS2339 - Property 'backgroundColor' does not exist on type '{}'. | TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: ({ backgroundColor }) => backgroundColor || theme.palette.grey[500]
  }
}));

type Props = {
  image: string;
  backgroundColor?: string | null | undefined;
  className?: string | null | undefined;
};

function CroppedImage(props: Props) {
  const { image, backgroundColor, className } = props;

  const classes = useStyles({ url: image, backgroundColor });
  return <div className={clsx(classes.img, className)} />;
}

export default React.memo<Props>(CroppedImage);
