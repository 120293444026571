import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 1
  },
  item: {
    position: 'relative',
    userSelect: 'none',
    padding: theme.spacing(2),
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.grey[400]}`,
    background: '#fff',
    '&:hover': {
      background: theme.palette.grey[100]
    }
  },
  disabled: {
    opacity: 0.5
  }
}));
