export interface IBaseAddress {
  city: string;
  country: string;
  street: string;
  number: string;
  zip: string;
}

export interface IFlatAddress extends IBaseAddress {
  firstName: string;
  lastName: string;
}

export enum IUserAddressType {
  DELIVERY = 'DELIVERY',
  INVOICE = 'INVOICE'
}

export type IUserBaseAddress = Omit<IBaseAddress, 'country' | 'number'> & {
  countryCode: string;
  streetNumber: string;
};

export interface IUserAddress {
  address: IUserBaseAddress;
  created: Date;
  firstName: string;
  id: number;
  lastName: string;
  type: IUserAddressType;
  validated: boolean;
}

export type IUserAddressPayload = Omit<IUserAddress, 'id' | 'validated' | 'created'> & {
  type?: IUserAddressType;
};
