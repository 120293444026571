import * as React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { TranslationObject } from 'serviceNew/locale';
import reduceFileSize from 'serviceNew/picture/reduceFileSize';
import FileUploadView from './FileUploadView';
import { getFormats } from 'serviceNew/picture';

export type FileUploadViewProps = JSX.LibraryManagedAttributes<
  typeof FileUploadView,
  React.ComponentProps<typeof FileUploadView>
>;

type Props = {
  children?: (arg1: FileUploadViewProps) => React.ReactElement;
  onDrop: (arg1: File[]) => unknown;
  onReject?: (arg1: File[]) => unknown;
  title?: TranslationObject;
  getRootProps?: any | null | undefined;
  resizeFiles?: boolean;
} & DropzoneOptions;

function FileUpload({ onDrop, onReject, resizeFiles, children, title, ...restProps }: Props) {
  const [error, setError] = React.useState<TranslationObject | null | undefined>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function handleDrop(acceptedFiles: any) {
    if (resizeFiles) {
      setLoading(true);
      const resizedFiles = await Promise.all(acceptedFiles.map(f => reduceFileSize(f)));
      setLoading(false);
      onDrop(resizedFiles);
    } else {
      onDrop(acceptedFiles);
    }
    setError(null);
  }

  function handleReject(files: any) {
    const file = files[0];
    const { accept } = restProps;
    if (typeof onReject === 'function') {
      // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
      onReject();
    }
    if (accept && ((Array.isArray(accept) && accept.includes(file.type)) || accept !== file.type)) {
      return setError({ key: 'general.file.upload.error.type' });
    }
    return setError({ key: 'general.file.upload.error', context: { count: files.length } });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleDrop,
    onDropRejected: handleReject,
    ...restProps
  });

  const childrenProps = {
    getRootProps,
    getInputProps,
    title,
    error,
    subtitle: {
      key: 'pictureUpload.subtitle',
      // @ts-expect-error - TS2345 - Argument of type 'string | string[] | undefined' is not assignable to parameter of type 'string | string[]'.
      context: { formats: getFormats(restProps.accept) }
    },
    loading
  } as const;

  // @ts-expect-error - TS2345 - Argument of type '{ readonly getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps; readonly getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps; readonly title: TranslationObject | undefined; readonly error: TranslationObject | ... 1 more ... | undefined; readonly subtitle: { ...; }; re...' is not assignable to parameter of type 'Props'. | TS2322 - Type '{ getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps; getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps; title: TranslationObject | undefined; error: TranslationObject | ... 1 more ... | undefined; subtitle: { ...; }; loading: boolean; }' is not assignable to type 'Props'.
  return children ? children(childrenProps) : <FileUploadView {...childrenProps} />;
}

FileUpload.defaultProps = {
  title: { key: 'pictureUpload.title' },
  resizeFiles: true
};

export default React.memo<Props>(FileUpload);
