import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import * as _ from 'lodash';

import { selectMultiLanguage } from 'serviceNew/locale/strings';

import { Text, SelectionTab, SelectionList } from 'App/components';
import { StackedBarChart } from 'App/components/charts';
import { useLocale } from 'App/providers/LocaleProvider';
import clsx from 'clsx';
import moment from 'moment';
import { DealOption } from 'serviceNew/model/dealOption';
import { Location } from 'serviceNew/model/location';
import { DealStatsDetailsData, DealStatsDetailsItem } from 'serviceNew/model/dealStats';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(3),
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(4),
    flexWrap: 'wrap',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-2)
  },
  title: {
    flex: 1,
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2)
  },
  tabs: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2)
  }
}));

const reducedByPeriod = (
  period: 'DAILY' | 'MONTHLY' | 'WEEKLY',
  dailyData: Array<any>,
  groupMapper: (key: string) => boolean
) => {
  if (period === 'DAILY') {
    return dailyData;
  }

  const dailyDataWithStart = dailyData.map(day => ({
    periodStart: moment(day?.dateDay)
      .startOf(period === 'WEEKLY' ? 'week' : 'month')
      .format('YYYY-MM-DD'),
    groupKey: Object.keys(day).find(groupMapper),
    ...day
  }));

  return (
    _.chain(dailyDataWithStart)
      // .filter(day => day.groupKey != null)
      .groupBy('periodStart')
      .map((val, key) => ({
        dateDay: key,
        ..._.chain(val)
          .groupBy('groupKey')
          .reduce<Record<string, any>>(
            (res, v, k) => ({
              ...res,
              [k]: _.reduce(val, (acc, cur) => acc + (cur[k] || 0), 0)
            }),
            {}
          )
          .value()
      }))
      .value()
  );
};

type Props = {
  title: TranslationObject;
  className?: string;
  activityTimeline: DealStatsDetailsData[];
  dealOptions: DealOption[] | null | undefined;
  locations: Location[];
  isCoupon: boolean;
};

function DealStatsActivity(props: Props) {
  const { activityTimeline, dealOptions, className, title, locations, isCoupon } = props;

  // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language } = useLocale();
  const classes = useStyles();

  const initialLocation = React.useMemo(
    () => (locations.length === 1 ? locations[0].id.toString() : ''),
    [locations]
  );

  const [locationId, setLocationId] = React.useState<string>(initialLocation);
  const [timePeriodTab, setTimePeriodTab] = React.useState<'DAILY' | 'WEEKLY' | 'MONTHLY'>('DAILY');

  const handleLocationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    setLocationId(id);
  };

  const xAxisTickFormatter = (label: string) => moment(label).format('L');
  const tooltipLabelFormatter = (label: any) => {
    switch (timePeriodTab) {
      case 'WEEKLY':
        return `${moment(label).format('ll')} - ${moment(label).endOf('week').format('ll')}`;
      case 'MONTHLY':
        return `${moment(label).format('MMMM YYYY')}`;
      default:
        return `${moment(label).format('ddd')}, ${moment(label).format('LL')}`;
    }
  };

  const dailyData = React.useMemo(
    () =>
      activityTimeline.map(obj => {
        if (isCoupon) {
          return {
            dateDay: obj.dateDay,
            // eslint-disable-next-line prefer-template
            count: locationId ? obj.redeemed?.['l' + locationId]?.count : obj.redeemed?.t?.count
          };
        }

        // @ts-expect-error - TS2739 - Type 'Record<string, any>' is missing the following properties from type 'DealStatsDetailsItem': count, revenue, value
        const optionObject: DealStatsDetailsItem = Object.keys(obj.redeemed).reduce<
          Record<string, any>
        >((acc, cur) => {
          if (locationId) {
            // eslint-disable-next-line prefer-template
            acc[cur] = obj.redeemed[cur]?.['l' + locationId]?.count;
          } else {
            acc[cur] = obj.redeemed[cur]?.t?.count;
          }
          return acc;
        }, {});
        const { dateDay } = obj;
        // @ts-expect-error - TS2322 - Type '{ count: number; revenue: number; value: number; t?: DealStatsDetailsItem | undefined; dateDay: string; }' is not assignable to type 'DealStatsDetailsItem'.
        const dataObj: DealStatsDetailsItem = { dateDay, ...optionObject };
        if (dataObj?.t) {
          delete dataObj.t;
        }
        return dataObj;
      }),
    [activityTimeline, isCoupon, locationId]
  );

  const data: Array<any> = React.useMemo(
    () =>
      reducedByPeriod(
        timePeriodTab,
        dailyData,
        isCoupon ? (key: string) => key === 'count' : (key: string) => key.startsWith('o')
      ),
    [dailyData, isCoupon, timePeriodTab]
  );

  const dataSetNames: {
    [key: string]: any;
  } | null = React.useMemo(() => {
    if (isCoupon) {
      return { count: { key: 'general.count' } };
    }
    if (dealOptions && dealOptions.length > 0) {
      return dealOptions.reduce<Record<string, any>>((acc, dealOption) => {
        acc[`o${dealOption.id}`] = { key: selectMultiLanguage(dealOption.name, language) };
        return acc;
      }, {});
    }
    return null;
  }, [dealOptions, isCoupon, language]);

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.header}>
        <Text className={classes.title} variant="h6">
          {title.key}
        </Text>
        <SelectionList
          className={classes.tabs}
          id={locationId}
          array={locations}
          onChange={handleLocationChange}
          renderName={e => e.name}
          emptyLabel={{ key: 'stats.deals.allLocations' }}
        />
        <SelectionTab
          className={classes.tabs}
          onPress={setTimePeriodTab}
          selectedTab={timePeriodTab}
          tabs={[
            { key: 'DAILY', label: { key: 'general.daily' } },
            { key: 'WEEKLY', label: { key: 'general.weekly' } },
            { key: 'MONTHLY', label: { key: 'general.monthly' } }
          ]}
        />
      </div>
      {dataSetNames && (
        <StackedBarChart
          height={300}
          xAxis="dateDay"
          dataSets={Object.keys(dataSetNames)}
          tooltipProps={{
            labelFormatter: tooltipLabelFormatter
          }}
          {...{
            data,
            dataSetNames,
            xAxisTickFormatter
          }}
        />
      )}
    </div>
  );
}

export default DealStatsActivity;
