import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import {
  IWalletAssset,
  IPaginatedGetWalletListParams,
  IGetWalletAssetParams,
  IBrandInfo
} from './types';

const V2 = '/v2';
const WEB = '/web';
const WALLET = '/wallet';

const apiRoot = `${V2}${WEB}${WALLET}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: IPaginatedGetWalletListParams): Promise<NewPaginationModel<IWalletAssset>> => {
    const { pageNumber = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      queryParams: {
        pageNumber,
        ...restParams
      }
    });
    return result;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async ({ type, id }: IGetWalletAssetParams): Promise<IBrandInfo> => {
    const result = await apiClient.get(`${apiRoot}/${id}/details`, {
      queryParams: { type }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  getList: getList(apiClient),
  get: get(apiClient)
});
