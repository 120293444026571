import {EnumTranslationKeys, MultiLanguageString} from 'serviceNew/locale';
import { keyMirror } from '@poinz/utils';
import { IdType } from '@poinz/api';
import { ProvisionCalculation } from 'serviceNew/model/deal';
import moment from 'moment';
import { UserAddress } from './user';

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

type DealRuleRepetitionType = 'NONE' | 'DAILY' | 'WEEKLY';

export const DEAL_RULE_STATE = keyMirror({
  ACTIVE: null,
  FINISHED: null,
  SCHEDULED: null
});

export type DealRuleState = keyof typeof DEAL_RULE_STATE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const DEAL_RULE_STATE_TRANSLATIONS: EnumTranslationKeys<DealRuleState> = {
  [DEAL_RULE_STATE.ACTIVE]: 'general.active',
  [DEAL_RULE_STATE.SCHEDULED]: 'general.scheduled',
  [DEAL_RULE_STATE.FINISHED]: 'general.finished'
};

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const DEAL_RULE_REPETITION_TYPE_TRANSLATED: EnumTranslationKeys<DealRuleRepetitionType> = {
  NONE: 'dealRule.none',
  DAILY: 'dealRule.daily',
  WEEKLY: 'dealRule.weekly'
};

export const DEAL_CAMPAIGN = keyMirror({
  POINZ_LEGACY: null,
  POINZ: null,
  PRODUCT: null,
  TOGETHERSTRONG: null,
  NONE: null
});

export const DEAL_CAMPAIGN_COLORS = {
  [DEAL_CAMPAIGN.POINZ_LEGACY]: 'grey',
  [DEAL_CAMPAIGN.POINZ]: 'orange',
  [DEAL_CAMPAIGN.PRODUCT]: 'blue',
  [DEAL_CAMPAIGN.TOGETHERSTRONG]: 'red',
  [DEAL_CAMPAIGN.NONE]: 'black'
} as const;

export type DealCampaign = keyof typeof DEAL_CAMPAIGN;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const DEAL_CAMPAIGN_TRANSLATED: EnumTranslationKeys<DealCampaign> = {
  [DEAL_CAMPAIGN.POINZ]: 'dealCampaign.poinz',
  [DEAL_CAMPAIGN.POINZ_LEGACY]: 'dealCampaign.poinzLegacy',
  [DEAL_CAMPAIGN.PRODUCT]: 'dealCampaign.product',
  [DEAL_CAMPAIGN.TOGETHERSTRONG]: 'dealCampaign.togetherstrong',
  [DEAL_CAMPAIGN.NONE]: 'dealCampaign.none'
};

export const ACTIVE_DEAL_CAMPAIGNS = [
  DEAL_CAMPAIGN.NONE,
  DEAL_CAMPAIGN.POINZ,
  DEAL_CAMPAIGN.PRODUCT
] as const;

export type DealOptionsLimit = {
  id: IdType,
  limit: number
};

export class DealRule {
// @ts-expect-error - TS2564 - Property 'id' has no initializer and is not definitely assigned in the constructor.
  id: IdType;

// @ts-expect-error - TS2564 - Property 'startDate' has no initializer and is not definitely assigned in the constructor.
  startDate: string;

// @ts-expect-error - TS2564 - Property 'endDate' has no initializer and is not definitely assigned in the constructor.
  endDate: string;

// @ts-expect-error - TS2564 - Property 'maxDeals' has no initializer and is not definitely assigned in the constructor.
  maxDeals: number;

// @ts-expect-error - TS2564 - Property 'dealId' has no initializer and is not definitely assigned in the constructor.
  dealId: number;

// @ts-expect-error - TS2564 - Property 'repetitionType' has no initializer and is not definitely assigned in the constructor.
  repetitionType: DealRuleRepetitionType;

// @ts-expect-error - TS2564 - Property 'campaign' has no initializer and is not definitely assigned in the constructor.
  campaign: DealCampaign;

// @ts-expect-error - TS2564 - Property 'commissionFee' has no initializer and is not definitely assigned in the constructor.
  commissionFee: number;

// @ts-expect-error - TS2564 - Property 'provisionCalculation' has no initializer and is not definitely assigned in the constructor.
  provisionCalculation: ProvisionCalculation;

// @ts-expect-error - TS2564 - Property 'startDay' has no initializer and is not definitely assigned in the constructor.
  startDay: DayOfWeek;

// @ts-expect-error - TS2564 - Property 'endDay' has no initializer and is not definitely assigned in the constructor.
  endDay: DayOfWeek;

// @ts-expect-error - TS2564 - Property 'limitPerUser' has no initializer and is not definitely assigned in the constructor.
  limitPerUser: number;

// @ts-expect-error - TS2564 - Property 'startTime' has no initializer and is not definitely assigned in the constructor.
  startTime: string;

// @ts-expect-error - TS2564 - Property 'endTime' has no initializer and is not definitely assigned in the constructor.
  endTime: string;

// @ts-expect-error - TS2564 - Property 'timeZone' has no initializer and is not definitely assigned in the constructor.
  timeZone: string;

  dealOptionsLimit: DealOptionsLimit[];

  constructor(dealRule: any) {
    Object.assign(this, dealRule);
    if (dealRule.dealOptionsLimit) {
      this.dealOptionsLimit = Array.isArray(dealRule.dealOptionsLimit)
        ? dealRule.dealOptionsLimit
        : Object.entries(dealRule.dealOptionsLimit).map(
// @ts-expect-error - TS2345 - Argument of type '([key, value]: [string, number]) => DealOptionsLimit' is not assignable to parameter of type '(value: [string, unknown], index: number, array: [string, unknown][]) => DealOptionsLimit'.
            ([key, value]: [string, number]): DealOptionsLimit => ({
              id: Number(key),
              limit: Number(value)
            })
          );
    } else {
      this.dealOptionsLimit = [];
    }
  }

  get dealRuleState() {
    const now = moment();
    const startDateTime = moment(`${this.startDate} ${this.startTime}`);
    const endDateTime = moment(`${this.endDate} ${this.endTime}`);

    if (now >= startDateTime && now <= endDateTime) {
      return DEAL_RULE_STATE.ACTIVE;
    }
    if (now < startDateTime) {
      return DEAL_RULE_STATE.SCHEDULED;
    }
    return DEAL_RULE_STATE.FINISHED;
  }
}

export type DeliveryData = (UserAddress) & {
  dealOptionName: MultiLanguageString,
  dealName: MultiLanguageString
};
