import * as React from 'react';

import { ButtonGroup as MuiButtonGroup } from '@mui/material';

import Button, { ButtonVariant } from './Button';

type ButtonGroupProps = {
  className?: string | null | undefined;
  variant: ButtonVariant;
  buttons: Array<JSX.LibraryManagedAttributes<typeof Button, React.ComponentProps<typeof Button>>>;
};

function ButtonGroup(props: ButtonGroupProps) {
  const { variant, className, buttons, ...restProps } = props;

  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <MuiButtonGroup {...{ className, variant }} {...restProps}>
      {buttons.map((buttonProps, idx) => (
        <Button key={`button-${idx.toString()}`} {...buttonProps} {...{ variant }} />
      ))}
    </MuiButtonGroup>
  );
}

Button.defaultProps = {
  variant: 'outline'
};

export default ButtonGroup;
