import { PagableParams } from '../../../types';
import { keyMirror } from '@poinz/utils';
import { EnumTranslationKeys } from '@poinz/locale';

export interface AnalyticParams extends PagableParams {
  from: string | null;
  to: string | null;
  agg: number | null;
  customerType: CustomerType;
}

export enum CustomerType {
  EXISTING_CUSTOMER = 'EXISTING_CUSTOMER',
  NEW_USER = 'NEW_USER'
}

export const LOYALTY_CUSTOMER_TYPE_TRANSLATIONS: Record<CustomerType, string> = Object.freeze({
  [CustomerType.EXISTING_CUSTOMER]: 'loyalty.analytics.userType.existingUsers',
  [CustomerType.NEW_USER]: 'loyalty.analytics.userType.newUsers'
});

export enum PaymentTypeAnalytics {
  MC = 'MC',
  AMEX = 'AMEX',
  VISA = 'VISA',
  TWINT = 'TWINT'
}

export const LOYALTY_PAYMENT_TYPE_TRANSLATIONS: Record<PaymentTypeAnalytics, string> =
  Object.freeze({
    [PaymentTypeAnalytics.MC]: 'Mastercard',
    [PaymentTypeAnalytics.AMEX]: 'American Express',
    [PaymentTypeAnalytics.TWINT]: 'TWINT',
    [PaymentTypeAnalytics.VISA]: 'Visa'
  });

export interface GenderType {
  male: number;
  female: number;
  other: number;
  unknown: number;
}

export interface AgeType {
  age6to15: number;
  age16to25: number;
  age26to35: number;
  age36to45: number;
  age46to55: number;
  age56to65: number;
  age66to100: number;
  unknown: number;
}

export interface LoyaltyAnalyticData {
  date: string;
  amount: number;
  value: number;
  transactions: number;
  users: number;
  avgTransactionAmount: number;
  usersGenderPercentage: GenderType;
  transactionsGenderPercentage: GenderType;
  valueGenderPercentage: GenderType;
  amountGenderPercentage: GenderType;
  avgTransactionAmountGender: GenderType;
  usersAgePercentage: AgeType;
  transactionsAgePercentage: AgeType;
  valueAgePercentage: AgeType;
  amountAgePercentage: AgeType;
  avgTransactionAmountAge: AgeType;
}

export interface LoyaltyAnalyticPeriod {
  from: string;
  to: string;
  agg: number;
  data: Array<LoyaltyAnalyticData>;
}

export interface LoyaltyAnalytics {
  totalAmount: number;
  totalValue: number;
  totalTransactions: number;
  totalUsers: number;
  totalAvgTransactionAmount: number;
  currentPeriod: LoyaltyAnalyticPeriod;

  totalAmountAgePercentage: LoyaltyAgePeriod;
  totalAvgTransactionAmountAge: LoyaltyAgePeriod;
  totalTransactionsAgePercentage: LoyaltyAgePeriod;
  totalUsersAgePercentage: LoyaltyAgePeriod;
  totalValueAgePercentage: LoyaltyAgePeriod;

  totalAmountGenderPercentage: LoyaltyGender;
  totalAvgTransactionAmountGender: LoyaltyGender;
  totalTransactionsGenderPercentage: LoyaltyGender;
  totalUsersGenderPercentage: LoyaltyGender;
  totalValueGenderPercentage: LoyaltyGender;
}

export enum LoyaltyAgg {
  DAY = 1,
  WEEK = 7,
  MONTH = 30
}

export interface LoyaltyGender {
  female: number;
  male: number;
  other: number;
  unknown: number;
}

export interface LoyaltyAgePeriod {
  age6to15: number;
  age16to25: number;
  age26to35: number;
  age36to45: number;
  age46to55: number;
  age56to65: number;
  age66to100: number;
  unknown: number;
}

export const LOYALTY_AGG_TRANSLATIONS: Record<LoyaltyAgg, string> = Object.freeze({
  [LoyaltyAgg.DAY]: 'loyalty.agg.daily',
  [LoyaltyAgg.WEEK]: 'loyalty.agg.weekly',
  [LoyaltyAgg.MONTH]: 'loyalty.agg.monthly'
});

export const TRANSACTION_STATUS_ANALYTICS = Object.freeze(
  keyMirror({
    BLOCKED: null,
    CREDITED: null
  })
);

export type TransactionStatusAnalytics = keyof typeof TRANSACTION_STATUS_ANALYTICS;

export const TRANSACTION_STATUS_ANALYTICS_TRANSLATION: EnumTranslationKeys = {
  [TRANSACTION_STATUS_ANALYTICS.BLOCKED]: 'swissLoyalty.transaction.status.blocked',
  [TRANSACTION_STATUS_ANALYTICS.CREDITED]: 'swissLoyalty.transaction.status.credited'
};
