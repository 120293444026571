import { NewPaginationModel } from 'types';
import {
  ICreateDealCollectResponse,
  IDealCollect,
  IDealCollectsStartPayment,
  ICreateDealCollectsParams,
  IPaginatedGetDealCollectsListParams
} from './types/dealCollects';
import { PoinzApiClient, getIdFromHeaders } from 'api';

const WEB = '/web';
const WALLET = '/deal-collects';

const v2ApiRoot = `/v2${WEB}${WALLET}`;
const v3ApiRoot = `/v3${WALLET}`;

const create =
  (apiClient: PoinzApiClient) =>
  async ({
    dealId,
    dealOptionId,
    deviceId
  }: ICreateDealCollectsParams): Promise<ICreateDealCollectResponse> => {
    const result = await apiClient.callAuthenticatedEndpoint(v2ApiRoot, 'POST', {
      queryParams: {
        deviceId,
        dealId,
        dealOptionId
      }
    });
    const { body, headers } = result;
    const id = getIdFromHeaders(headers);
    return {
      id,
      reference: body.referenceNumber
    };
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<IDealCollect> => {
    const result = await apiClient.callAuthenticatedEndpoint(`${v3ApiRoot}/${id}`, 'GET', {});
    return result.body;
  };

const getList =
  (apiClient: PoinzApiClient) =>
  async (
    params: IPaginatedGetDealCollectsListParams
  ): Promise<NewPaginationModel<IDealCollect>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(v3ApiRoot, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

const redeem =
  (apiClient: PoinzApiClient) =>
  async (dealCollectId: number): Promise<{ referenceNumber: string }> => {
    const result = await apiClient.callAuthenticatedEndpoint(
      `${v2ApiRoot}/${dealCollectId}`,
      'PUT',
      {}
    );
    return result.body;
  };

const startPaymentTransaction =
  (apiClient: PoinzApiClient) =>
  async ({
    dealCollectId,
    invoiceAddressId,
    deliveryAddressId
  }: IDealCollectsStartPayment): Promise<{ referenceNumber: string }> => {
    const result = await apiClient.callAuthenticatedEndpoint(
      `${v2ApiRoot}/${dealCollectId}/start-payment`,
      'PUT',
      { queryParams: { deliveryAddressId, invoiceAddressId } }
    );
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  getList: getList(apiClient),
  create: create(apiClient),
  redeem: redeem(apiClient),
  startPaymentTransaction: startPaymentTransaction(apiClient)
});
