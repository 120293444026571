import * as React from 'react';
import { TablePaginationActions } from 'App/components';
import { TablePagination } from 'App/components/Table';

type Props = {
  colSpan: number;
  pagination: TablePagination;
};

function Footer(props: Props) {
  const { pagination } = props;

  const { totalElements, rowsPerPage, pageNumber, handlePageChange } = pagination;

  return (
    <div>
      <TablePaginationActions
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onChangePage={(event, page) => handlePageChange(page)}
        showPageNumbers
      />
    </div>
  );
}

export default Footer;
