import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Tabs, Paper, Tab } from '@mui/material';
import { DASHBOARD_LOYALTY } from 'App/routing';
import BusinessProfileStats from './BusinessProfileStats/BusinessProfileStats';
import LocationStats from './LocationStats/LocationStats';
import { useLocale } from 'App/providers/LocaleProvider';
import { LOYALTY_TABS } from './LoyaltyStats.utils';

const locationTab = LOYALTY_TABS.LOCATIONS;
const businessTab = LOYALTY_TABS.BUSINESS;

const LoyaltyStats = () => {
  const location = useLocation();
  const history = useHistory();
  const { translate } = useLocale();

  const [value, setValue] = React.useState(
    location.pathname.includes(locationTab) ? locationTab : businessTab
  );

  React.useEffect(() => {
    if (value === locationTab) {
      history.push(`${DASHBOARD_LOYALTY}/${locationTab}`);
    } else {
      history.push(`${DASHBOARD_LOYALTY}/${businessTab}`);
    }
  }, [value, history]);

  const handleChange = (event: any, value: string) => {
    setValue(value as any);
  };

  return (
    <>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={businessTab} label={translate({ key: 'businessProfile.businessProfile' })} />
          <Tab value={locationTab} label={translate({ key: 'location.locations' })} />
        </Tabs>
      </Paper>
      {value === businessTab && <BusinessProfileStats />}
      {value === locationTab && <LocationStats />}
    </>
  );
};

export default LoyaltyStats;
