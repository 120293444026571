import * as React from 'react';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';

import { TranslationObject } from 'serviceNew/locale';
import TranslatedText from './TranslatedText';

type TableColumn<T, S> = {
  label: TranslationObject;
  propertyName?: S;
  render: (obj: T) => React.ReactElement;
};

type Props<T, S> = {
  data: T[];
  columns: TableColumn<T, S>[];
};

function DashboardTable<T, S extends keyof T>(props: Props<T, S>) {
  const { columns, data } = props;

  function renderHeader(column: TableColumn<T, S>) {
    return (
      <TableCell>
        <TranslatedText text={column.label} />
      </TableCell>
    );
  }

  function renderCell(column: TableColumn<T, S>, row: T) {
    const { propertyName, render } = column;

    let content = null;
    if (propertyName != null) {
      // @ts-expect-error - TS2322 - Type 'T[S]' is not assignable to type 'null'.
      content = row[propertyName];
    }
    if (typeof render === 'function') {
      // @ts-expect-error - TS2322 - Type 'ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to type 'null'.
      content = render(row);
    }

    return <TableCell>{content}</TableCell>;
  }

  function renderRow(row: T) {
    return <TableRow>{columns.map(col => renderCell(col, row))}</TableRow>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>{columns.map(renderHeader)}</TableRow>
      </TableHead>
      <TableBody>{data.map(renderRow)}</TableBody>
    </Table>
  );
}

export default DashboardTable;
