import * as React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';

import { keyMirror } from '@poinz/utils';
import { TranslationObject } from 'serviceNew/locale';

import TextInput from './TextInput';
import TextSelect, { getOptionsFromEnum } from './TextSelect';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex'
  },
  textInput: {
    flex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  }
}));

const DURATION_UNITS = {
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years'
} as const;

export const TIME_UNITS = keyMirror(DURATION_UNITS);

const DURATION_UNITS_TRANSLATIONS = {
  MILLISECONDS: 'duration.unit.milliseconds',
  SECONDS: 'duration.unit.seconds',
  MINUTES: 'duration.unit.minutes',
  HOURS: 'duration.unit.hours',
  DAYS: 'duration.unit.days',
  WEEKS: 'duration.unit.weeks',
  MONTHS: 'duration.unit.months',
  YEARS: 'duration.unit.years'
} as const;

type DurationUnit = keyof typeof DURATION_UNITS;

type Props = {
  className?: string | null | undefined;
  value: number | null | undefined;
  onChange: (value?: number | null | undefined) => void;
  unit: DurationUnit;
  label: TranslationObject;
  tooltip?: TranslationObject | null | undefined;
};

function formattedToNumber(formattedValue: string): number | null | undefined {
  if (formattedValue === '') {
    return undefined;
  }
  return Number(formattedValue);
}

function numberToFormatted(value?: number | null): string {
  if (value == null) {
    return '';
  }
  return value.toString();
}

function convertUnits(value: number | null | undefined, unit: DurationUnit, newUnit: DurationUnit) {
  if (value == null) {
    return undefined;
  }
  const duration = moment.duration(value, DURATION_UNITS[unit]);
  return duration.as(DURATION_UNITS[newUnit]);
}

function TimeDuration(props: Props) {
  const { value, onChange, unit, className, label, tooltip } = props;
  const [formattedValue, setFormattedValue] = React.useState<string>(
    value != null ? value.toString() : ''
  );
  const curValue = React.useRef(value);
  const [selectedUnit, setSelectedUnit] = React.useState<DurationUnit>(unit);

  const classes = useStyles();

  const handleChange = React.useCallback(event => {
    setFormattedValue(event.target.value);
  }, []);

  const handleUnitChange = React.useCallback(event => {
    const newSelectedUnit = event.target.value;
    setSelectedUnit(curSelectedUnit => {
      setFormattedValue(curFormattedValue =>
        numberToFormatted(
          convertUnits(formattedToNumber(curFormattedValue), curSelectedUnit, newSelectedUnit)
        )
      );
      return newSelectedUnit;
    });
  }, []);

  React.useEffect(() => {
    if (formattedValue === '') {
      if (curValue.current == null) {
        return;
      }
      curValue.current = undefined;
      onChange(undefined);
      return;
    }
    const newValue = convertUnits(formattedToNumber(formattedValue), selectedUnit, unit);
    if (curValue.current !== newValue) {
      curValue.current = newValue;
      onChange(newValue);
    }
  }, [formattedValue, onChange, selectedUnit, unit]);

  React.useEffect(() => {
    if (value !== curValue.current) {
      curValue.current = value;
      setFormattedValue(numberToFormatted(convertUnits(value, unit, selectedUnit)));
    }
  }, [selectedUnit, unit, value]);

  return (
    // @ts-expect-error - TS2322 - Type '{ children: Element[]; className: string | null | undefined; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.
    <div {...{ className }}>
      <FieldLabel {...{ label, tooltip }} />
      <div className={classes.row}>
        <TextInput
          className={classes.textInput}
          type="number"
          value={formattedValue}
          onChange={handleChange}
          noLabel
          label={label as any}
        />
        <TextSelect
          value={selectedUnit}
          onChange={handleUnitChange}
          options={getOptionsFromEnum(DURATION_UNITS_TRANSLATIONS)}
          label={{ key: 'duration.unit' }}
          noLabel
        />
      </div>
    </div>
  );
}

export default TimeDuration;
