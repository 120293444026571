/* eslint max-classes-per-file:0 */

import { v4 as uuidv4 } from 'uuid';
import { MultiLanguageString } from 'serviceNew/locale';
import { IdType } from '@poinz/api';
import { ENTITY } from 'App/components/EntitySelection/EntitySelectionModel';
import { Picture, PictureUpdate } from './common';

type MessageBlockType =
  | 'PICTURE'
  | 'TEXT'
  | 'LINK_BUTTON'
  | 'REWARD_BUTTON'
  | 'BRAND_INFO'
  | 'SUBJECT'
  | 'DEAL_PROMOTION'
  | 'VIDEO';

export type Image = {
  sourceUrl: string;
  url?: string;
};

export const MESSAGE_BLOCK_TYPE = {
  PICTURE: 'PICTURE',
  TEXT: 'TEXT',
  LINK_BUTTON: 'LINK_BUTTON',
  REWARD_BUTTON: 'REWARD_BUTTON',
  BRAND_INFO: 'BRAND_INFO',
  SUBJECT: 'SUBJECT',
  DEAL_PROMOTION: 'DEAL_PROMOTION',
  OFFERING: 'OFFERING',
  VIDEO: 'VIDEO'
} as const;

export const MessageBlockTranslations = {
  PICTURE: 'message.block.picture',
  TEXT: 'message.block.text',
  LINK_BUTTON: 'message.block.link',
  REWARD_BUTTON: 'message.block.reward',
  SUBJECT: 'message.block.subject',
  DEAL_PROMOTION: 'message.block.dealPromotion',
  BRAND_INFO: 'message.block.brandInfo',
  OFFERING: 'message.offering',
  VIDEO: 'message.block.video'
} as const;

type RewardEntity = {
  entityType: 'LOYALTY' | 'DEAL' | 'VOUCHER';
  entityId: number;
  amount?: number;
};

export type OfferingPromotion = {
  entityId: number;
  codeId?: number;
  description: MultiLanguageString;
};

export class MessageBlockBase {
  id: string;

  index: number;

  isCreated: boolean | null | undefined;

  constructor(messageBlock: any) {
    if (messageBlock.id != null) {
      this.id = messageBlock.id;
    } else {
      this.id = uuidv4();
    }
    this.index = messageBlock.index;
    // @ts-expect-error - TS2339 - Property 'blockType' does not exist on type 'MessageBlockBase'.
    this.blockType = messageBlock.blockType;
  }
}

export class BrandInfoMessageBlock extends MessageBlockBase {
  blockType: 'BRAND_INFO' = MESSAGE_BLOCK_TYPE.BRAND_INFO;

  // @ts-expect-error - TS2564 - Property 'name' has no initializer and is not definitely assigned in the constructor.
  name: MultiLanguageString;

  // @ts-expect-error - TS2564 - Property 'logoUrl' has no initializer and is not definitely assigned in the constructor.
  logoUrl: string;

  // @ts-expect-error - TS2564 - Property 'logoBackgroundColor' has no initializer and is not definitely assigned in the constructor.
  logoBackgroundColor: string;

  constructor(messageBlock: any) {
    super(messageBlock);
    ['name', 'logoUrl', 'logoBackgroundColor'].forEach(k => {
      if (messageBlock[k] != null) {
        this[k] = messageBlock[k];
      }
    });
  }
}

export class SubjectMessageBlock extends MessageBlockBase {
  blockType: 'SUBJECT' = MESSAGE_BLOCK_TYPE.SUBJECT;

  content: MultiLanguageString;

  constructor(messageBlock: any) {
    super(messageBlock);
    this.content = messageBlock.content;
  }
}

export class TextMessageBlock extends MessageBlockBase {
  blockType: 'TEXT' = MESSAGE_BLOCK_TYPE.TEXT;

  content: MultiLanguageString;

  constructor(messageBlock: any) {
    super(messageBlock);
    this.content = messageBlock.content;
  }
}

export class PictureMessageBlock extends MessageBlockBase {
  blockType: 'PICTURE' = MESSAGE_BLOCK_TYPE.PICTURE;

  imageList: Array<{
    imageInfo: Picture;
    url?: string;
  }>;

  constructor(messageBlock: any) {
    super(messageBlock);
    this.imageList = messageBlock.imageList;
  }
}

export class DealPromotionMessageBlock extends MessageBlockBase {
  blockType: 'DEAL_PROMOTION' = MESSAGE_BLOCK_TYPE.DEAL_PROMOTION;

  dealIdList:
    | Array<{
        entityType: 'DEAL' | 'COUPON';
        entityId: IdType;
      }>
    | null
    | undefined;

  getFormValue() {
    const { dealIdList, ...restProps } = this;
    return {
      ...restProps,
      dealIdList: dealIdList != null ? dealIdList.map(dealObject => dealObject.entityId) : null
    };
  }

  constructor(messageBlock: any) {
    super(messageBlock);
    const { dealIdList } = messageBlock;
    this.dealIdList = Array.isArray(dealIdList)
      ? dealIdList.map(id => (typeof id === 'number' ? { entityType: 'DEAL', entityId: id } : id))
      : dealIdList;
  }
}

export class LinkButtonMessageBlock extends MessageBlockBase {
  blockType: 'LINK_BUTTON' = MESSAGE_BLOCK_TYPE.LINK_BUTTON;

  label: MultiLanguageString;

  url: MultiLanguageString;

  constructor(messageBlock: any) {
    super(messageBlock);
    this.label = messageBlock.label;
    this.url = messageBlock.url;
  }
}

export class VideoMessageBlock extends MessageBlockBase {
  blockType: 'VIDEO' = MESSAGE_BLOCK_TYPE.VIDEO;

  videoUrl: MultiLanguageString;

  constructor(messageBlock: any) {
    super(messageBlock);
    this.videoUrl = messageBlock.videoUrl;
  }
}

export class RewardButtonMessageBlock extends MessageBlockBase {
  blockType: 'REWARD_BUTTON' = MESSAGE_BLOCK_TYPE.REWARD_BUTTON;

  label: MultiLanguageString;

  rewardEntity: RewardEntity | null | undefined;

  getFormValue() {
    const { rewardEntity, ...restProps } = this;
    return {
      ...restProps,
      rewardForm: rewardEntity
        ? {
            rewardOfferingType: rewardEntity.entityType,
            rewardId: rewardEntity.entityId,
            amount: rewardEntity.amount
          }
        : null
    };
  }

  constructor(messageBlock: any) {
    super(messageBlock);
    this.label = messageBlock.label;
    const { rewardEntity } = messageBlock;
    if (rewardEntity) {
      this.rewardEntity = {
        entityType: rewardEntity.entityType === 'COUPON_MSG' ? 'DEAL' : rewardEntity.entityType,
        entityId: rewardEntity.entityId,
        amount: rewardEntity.amount
      };
    } else if (messageBlock.voucherId) {
      this.rewardEntity = {
        entityType: 'VOUCHER',
        entityId: messageBlock.voucherId
      };
    }
  }
}

export class OfferingPromotionBlock extends MessageBlockBase {
  blockType: 'OFFERING' = MESSAGE_BLOCK_TYPE.OFFERING;

  offeringPromotions: Array<OfferingPromotion>;

  constructor(offerPromotion: any) {
    super(offerPromotion);
    this.offeringPromotions = offerPromotion.offeringPromotions
      ? offerPromotion.offeringPromotions.map((op: OfferingPromotion) => ({
          description: op.description,
          entityId: op.codeId,
          codeId: op.codeId,
          entityType: ENTITY.CODE
        }))
      : [];
  }
}

type PictureMessageBlockUpdate = {
  imageList: Array<{
    url?: string;
    imageInfo: PictureUpdate;
  }>;
} & Diff<
  PictureMessageBlock,
  {
    imageList: any;
  }
>;

export type MessageBlock =
  | BrandInfoMessageBlock
  | SubjectMessageBlock
  | TextMessageBlock
  | PictureMessageBlock
  | DealPromotionMessageBlock
  | LinkButtonMessageBlock
  | RewardButtonMessageBlock
  | OfferingPromotionBlock
  | VideoMessageBlock;

export type MessageBlockUpdate =
  | BrandInfoMessageBlock
  | SubjectMessageBlock
  | TextMessageBlock
  | PictureMessageBlockUpdate
  | DealPromotionMessageBlock
  | LinkButtonMessageBlock
  | RewardButtonMessageBlock
  | OfferingPromotionBlock
  | VideoMessageBlock;

export function createMessageBlock(messageBlock: any): MessageBlock {
  switch (messageBlock.blockType) {
    case MESSAGE_BLOCK_TYPE.BRAND_INFO:
      return new BrandInfoMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.SUBJECT:
      return new SubjectMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.TEXT:
      return new TextMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.PICTURE:
      return new PictureMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.DEAL_PROMOTION:
      return new DealPromotionMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.LINK_BUTTON:
      return new LinkButtonMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.OFFERING:
      return new OfferingPromotionBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.VIDEO:
      return new VideoMessageBlock(messageBlock);
    case MESSAGE_BLOCK_TYPE.REWARD_BUTTON:
    default:
      return new RewardButtonMessageBlock(messageBlock);
  }
}

function getMockTextMessageBlock() {
  return new TextMessageBlock({
    blockType: MESSAGE_BLOCK_TYPE.TEXT,
    content: {
      de:
        // eslint-disable-next-line max-len
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum lacus vel vehicula dictum. Mauris congue fringilla tempor. Curabitur vitae congue dolor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam sed libero eleifend, laoreet nisi at, pulvinar ante. Curabitur viverra rutrum finibus. Sed convallis, odio ac finibus.',
      en:
        // eslint-disable-next-line max-len
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum lacus vel vehicula dictum. Mauris congue fringilla tempor. Curabitur vitae congue dolor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam sed libero eleifend, laoreet nisi at, pulvinar ante. Curabitur viverra rutrum finibus. Sed convallis, odio ac finibus.'
    }
  });
}

function getMockBrandInfoMessageBlock() {
  return new BrandInfoMessageBlock({
    blockType: MESSAGE_BLOCK_TYPE.BRAND_INFO,
    name: {
      de: 'Name des Geschäftsprofiles',
      en: 'Name of the business profile'
    },
    logoUrl: 'https://storage.googleapis.com/bucket-test.poinz.ch/84/bp/356/logo_1553506772540.png',
    logoBackgroundColor: '#4BE4B1'
  });
}

function getMockSubjectMessageBlock() {
  return new SubjectMessageBlock({
    blockType: MESSAGE_BLOCK_TYPE.SUBJECT,
    content: {
      de: 'Betreff',
      en: 'Subject'
    }
  });
}

function getMockPictureMessageBlock() {
  return new PictureMessageBlock({
    blockTyp: MESSAGE_BLOCK_TYPE.PICTURE,
    imageList: [
      {
        imageInfo: {
          sourceUrl:
            'https://storage.googleapis.com/bucket-test.poinz.ch/84/bp/356/picture_1553506773080.jpg'
        },
        url: 'https://www.google.ch'
      },
      {
        imageInfo: {
          sourceUrl:
            'https://storage.googleapis.com/bucket-test.poinz.ch/84/bp/355/loc/417/picture_1553506614414.jpg'
        }
      }
    ]
  });
}

export function getMockMessageBlock(params: { blockType: MessageBlockType }) {
  switch (params.blockType) {
    case MESSAGE_BLOCK_TYPE.BRAND_INFO:
      return getMockBrandInfoMessageBlock();
    case MESSAGE_BLOCK_TYPE.SUBJECT:
      return getMockSubjectMessageBlock();
    case MESSAGE_BLOCK_TYPE.TEXT:
      return getMockTextMessageBlock();
    case MESSAGE_BLOCK_TYPE.PICTURE:
    default:
      return getMockPictureMessageBlock();
  }
}
