import React, { PureComponent } from 'react';
import moment from 'moment';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { TextInput } from 'App/components';
import { MobileDatePicker } from '@mui/x-date-pickers';
type Props = {
  onStartDateChange: (arg1: string, arg2: string) => void;
  onEndDateChange: (arg1: string, arg2: string) => void;
  endDate: string;
  startDate: string;
  maxDate: string;
  disableFuture: boolean;
  label: boolean;
  helperText: boolean;
} & LocaleProps;

type State = {
  startDate: string;
  endDate: string;
  isStartOpen: boolean;
  isEndOpen: boolean;
};

export class DateRangePickerUnconnected extends PureComponent<Props, State> {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    isStartOpen: false,
    isEndOpen: false
  };

  handleChangeStartDate = (value: moment.Moment) => {
    this.setState({ startDate: value.format('YYYY-MM-DD') });
    const { onStartDateChange } = this.props;
    onStartDateChange('startDate', value.format('YYYY-MM-DD'));
  };

  handleChangeEndDate = (value: moment.Moment) => {
    this.setState({ endDate: value.format('YYYY-MM-DD') });
    const { onEndDateChange } = this.props;
    onEndDateChange('endDate', value.format('YYYY-MM-DD'));
  };

  render() {
    const { startDate, endDate } = this.state;
    const { formatMessage, disableFuture, maxDate, label = false, helperText = false } = this.props;

    return (
      <>
        <MobileDatePicker
          renderInput={(props: any) => (
            <TextInput
              {...props}
              noLabel
              disabled
              variant="standard"
              style={{ maxWidth: 90 }}
              label={label && (formatMessage({ id: 'general.startDate' }) as any)}
              helperText={helperText && formatMessage({ id: 'general.from' })}
            />
          )}
          name="startDate"
          value={startDate}
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Moment | undefined'.
          maxDate={maxDate}
          inputFormat="l"
          // @ts-expect-error - TS2322 - Type '(value: moment.Moment) => void' is not assignable to type '(value: Moment | null, keyboardInputValue?: string | undefined) => void'.
          onChange={this.handleChangeStartDate}
          disableFuture={disableFuture}
          okLabel={formatMessage({ id: 'general.ok' })}
          cancelLabel={formatMessage({ id: 'general.cancel' })}
        />
        <MobileDatePicker
          renderInput={(props: any) => (
            <TextInput
              {...props}
              noLabel
              style={{ maxWidth: 90 }}
              variant="standard"
              label={label && (formatMessage({ id: 'general.endDate' }) as any)}
              helperText={helperText && formatMessage({ id: 'general.to' })}
            />
          )}
          name="endDate"
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Moment | undefined'.
          minDate={startDate}
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Moment | undefined'.
          maxDate={maxDate}
          inputFormat="l"
          value={endDate}
          // @ts-expect-error - TS2322 - Type '(value: moment.Moment) => void' is not assignable to type '(value: Moment | null, keyboardInputValue?: string | undefined) => void'.
          onChange={this.handleChangeEndDate}
          disableFuture={disableFuture}
          okLabel={formatMessage({ id: 'general.ok' })}
          cancelLabel={formatMessage({ id: 'general.cancel' })}
        />
      </>
    );
  }
}

export default withLocale(DateRangePickerUnconnected);
