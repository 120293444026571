import { keyMirror } from '@poinz/utils';
import { EnumTranslationKeys, MultiLanguageString } from '@poinz/locale';
import { Nullable, PagableParams } from '../../../types';

export const TRANSACTION_STATUS = Object.freeze(
  keyMirror({
    BLOCKED: null,
    CREDITED: null,
    REFUNDED: null
  })
);

export type TransactionStatus = keyof typeof TRANSACTION_STATUS;

export const TRANSACTION_STATUS_TRANSLATION: EnumTranslationKeys = {
  [TRANSACTION_STATUS.BLOCKED]: 'swissLoyalty.transaction.status.blocked',
  [TRANSACTION_STATUS.CREDITED]: 'swissLoyalty.transaction.status.credited',
  [TRANSACTION_STATUS.REFUNDED]: 'swissLoyalty.transaction.status.refunded'
};

export const TRANSACTION_TYPE = Object.freeze(
  keyMirror({
    WORLDLINE: null,
    API_KEYS: null
  })
);

export type TransactionType = keyof typeof TRANSACTION_TYPE;

export interface Transaction {
  id: number;
  status: TransactionStatus;
  blockedUntil: string;
  created: string;
  type: TransactionType;
  locationName: string;
  cashbackPercent: number;
  paymentBrand: string;
  amount: number;
  terminalId: number;
  reference: string;
  value: string;
  cashback: number;
}

export type CreateTransactionBody = {
  note: null | string;
  label: MultiLanguageString;
  value: number;
  userIds: number[];
};

export interface PaginatedTransactionParams extends PagableParams {
  businessProfileId: number;
  from?: Nullable<string>;
  to?: Nullable<string>;
  status?: Nullable<TransactionStatus>;
  isCreditDate?: Nullable<boolean>;
  campaignId?: Nullable<number>;
}
