import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { Tooltip as MuiTooltip } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { TranslationObject } from 'serviceNew/locale';
import Text from './Text';

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(0.5),
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[600],
  },
}));

type Props = {
  label: TranslationObject,
  children?: React.ReactNode
};

function Tooltip(props: Props) {
  const { label, children, ...restProps } = props;

  const classes = useStyles();

  function renderTooltipComponent() {
    return children || <HelpOutline fontSize="small" className={classes.tooltipContainer} />;
  }

  if (label == null) {
    return null;
  }

  return (
    <MuiTooltip
      title={
        <Text type="body2" context={label.context}>
          {label.key}
        </Text>
      }
      {...restProps}
    >
{ /* @ts-expect-error - TS2322 - Type 'string | number | true | Element | Iterable<ReactNode>' is not assignable to type 'ReactElement<any, any>'. */}
      {renderTooltipComponent()}
    </MuiTooltip>
  );
}

export default React.memo<Props>(Tooltip);
