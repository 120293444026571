import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Text } from 'App/components';
import { TranslationObject } from 'serviceNew/locale';
import { RowDataDisplay, RowList } from '.';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 12,
    backgroundColor: 'white',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  title: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1.5)
  },
  totalRow: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  data: any[];
  headerRow?: any[];
  totalRow: any[];
  className?: string;
  title: TranslationObject;
  children?: React.ReactNode;
};

function RowListBox(props: Props) {
  const { data, headerRow, totalRow, title, className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <Text className={classes.title} variant="h6">
        {title.key}
      </Text>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {headerRow && <RowDataDisplay data={headerRow} />}
        <RowList data={data} />
      </div>
      <RowDataDisplay className={classes.totalRow} variant="h6" data={totalRow} />
    </div>
  );
}

export default RowListBox;
