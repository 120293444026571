import { MultiLanguageString } from 'serviceNew/locale';
import { keyMirror } from '@poinz/utils';
import { IdType } from '@poinz/api';
import { DealCategory } from './deal';
import { OfferingCategory } from './offering';

export const APP_LINK_MEDIUM = {
  NEWSLETTER: 'applink.newsletter',
  MESSAGE: 'applink.poinz.message',
  AD: 'applink.ad',
  AD_MOBILE: 'applink.ad.mobile',
  AD_WEB: 'applink.ad.web',
  OTHER: 'general.other'
} as const;

export const APP_LINK_SOURCE = {
  POINZ: 'general.poinz.app',
  FACEBOOK: 'general.facebook',
  INSTAGRAM: 'general.instagram',
  GOOGLE: 'general.google',
  RAPID_MAIL: 'general.rapid.mail'
} as const;

export const APP_LINK_TYPES = {
  HOME: 'appLinkType.home',
  WALLET: 'appLinkType.wallet',
  DEAL_EXPLORE: 'appLinkType.dealExplore',
  OFFERING_EXPLORE: 'appLinkType.offeringExplore'
} as const;

export const WALLET_TYPES = {
  USER_CARD: 'walletType.userCard',
  CARD: 'walletType.card',
  DEAL: 'walletType.deal',
  COUPON: 'walletType.coupon'
} as const;

export const APP_LINK_ENTITY_TYPE = keyMirror({
  VOUCHERS: null,
  CARD_TEMPLATES: null
});

export type AppLinkEntity = keyof typeof APP_LINK_ENTITY_TYPE;

export type AppLinkMediumType = keyof typeof APP_LINK_MEDIUM;
export type AppLinkSourceType = keyof typeof APP_LINK_SOURCE;
export type AppLinkType = keyof typeof APP_LINK_TYPES;

export type WalletTypes = keyof typeof WALLET_TYPES;

export type AppLinkEntityType = {
  id: IdType;
  type: AppLinkType;
  medidum: any;
  source: AppLinkSourceType;
  campaign: string;
  created: string;
  link: MultiLanguageString;
  dealId: IdType;
  medium: AppLinkMediumType;
};

export type AppLinkFormType = {
  medium: AppLinkMediumType;
  source: AppLinkSourceType;
  campaign: string;
};

export type AppLinkTypeObject = {
  appLinkType: AppLinkType;
  medium: AppLinkMediumType;
  source: AppLinkSourceType;
  campaign: string;
  walletTab?: WalletTypes;
  dealCategory?: DealCategory;
  offeringCategory?: OfferingCategory;
};
