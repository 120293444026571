import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
  },
});

type Props = {
  className?: string | null | undefined,
  getImage: () => Promise<Blob>
};

function QrCode(props: Props) {
  const { getImage, className } = props;

  const [imageUrl, setImageUrl] = React.useState<any>(null);
  const classes = useStyles();

  React.useEffect(() => {
    let newImageUrl;
    async function loadImage() {
      const imageBlob = await getImage();
      newImageUrl = URL.createObjectURL(imageBlob);
      setImageUrl(newImageUrl);
    }
    loadImage();
    return () => URL.revokeObjectURL(newImageUrl);
  }, [getImage]);

  return (
    <div className={clsx(className, classes.container)}>
      <img className={classes.image} src={imageUrl} alt="qr-code" />
    </div>
  );
}

export default QrCode;
