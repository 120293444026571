import * as React from 'react';
import { makeStyles } from '@mui/styles';
import RawText from '../RawText';

const DOT_SIZE = 8;

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2),
    borderRadius: 12,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    // @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[6]
  },
  title: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(1.5)
  },
  label: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontWeight: 300,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[500]
  },
  item: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  }
}));

type PayloadEntity = {
  color: string;
  value: string | number;
  name: string;
};

type Props = {
  label: string;
  labelFormatter?: (arg1: string) => string;
  payload: PayloadEntity[];
};

function Tooltip(props: Props) {
  const classes = useStyles();
  const { labelFormatter, label, payload } = props;

  if (Array.isArray(payload) && payload.length > 0) {
    return (
      <div className={classes.container}>
        <RawText variant="subtitle2" className={classes.title}>
          {typeof labelFormatter === 'function' ? labelFormatter(label) : label}
        </RawText>
        {payload.map((entity, idx) => (
          <div key={idx.toString()} className={classes.item}>
            <div className={classes.label}>
              <div className={classes.dot} style={{ backgroundColor: entity.color }} />
              <RawText className={classes.text} variant="body2">
                {entity.name}
              </RawText>
            </div>
            <RawText className={classes.text} variant="body2">
              {/* @ts-expect-error - TS2769 - No overload matches this call. */}
              {entity.value}
            </RawText>
          </div>
        ))}
      </div>
    );
  }

  return null;
}

export default Tooltip;
