import React from 'react';
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getNewLocation, UrlLocation } from 'serviceNew/navigation';

type Props = {
  link: UrlLocation | string;
  className?: string | null | undefined;
};

function AddSign(props: Props) {
  const { link, className } = props;
  return (
    // @ts-expect-error - TS2322 - Type '{ children: (string | Element)[]; className: string | null | undefined; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.
    <div {...{ className }}>
      <Link
        to={typeof link === 'string' ? link : (location: any) => getNewLocation(location, link)}
      >
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <Fab mini color="primary" aria-label="Add">
          <AddIcon />
        </Fab>
      </Link>
    </div>
  );
}

export default AddSign;
