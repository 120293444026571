import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { TranslationObject } from 'serviceNew/locale';
import Tooltip from './Tooltip';

export type SignalState = 'GREEN' | 'YELLOW' | 'RED' | 'GREY';

const useStyles = makeStyles({
  container: {
    display: 'flex',
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    width: ({ size }) => size,
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    height: ({ size }) => size,
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    borderRadius: ({ size }) => size / 2,
// @ts-expect-error - TS2339 - Property 'state' does not exist on type '{}'.
    backgroundColor: ({ state }) => state,
  },
});

type Props = {
  className?: string,
  size: number,
  state: SignalState,
  tooltipLabel?: TranslationObject
};

function SignalStateDisplay(props: Props) {
  const { className, size, state, tooltipLabel } = props;

  const classes = useStyles({ size, state });

  if (tooltipLabel) {
    return (
      <Tooltip label={tooltipLabel}>
        <div className={clsx(classes.container, className)} />
      </Tooltip>
    );
  }

  return <div className={clsx(classes.container, className)} />;
}

export default SignalStateDisplay;
