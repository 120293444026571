import * as React from 'react';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { MyTheme } from 'theme/muiTheme';
import { TranslationKey } from 'serviceNew/locale';
import Text from './Text';

const styles = createStyles((theme: MyTheme) => ({
  root: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    margin: `${theme.extra.spacing.tiny}px 0`,
    textDecorationColor: 'black'
  }
}));

type Props = {
  link: string;
  displayText: TranslationKey;
} & WithStyles<typeof styles>;

class LinkText extends React.PureComponent<Props> {
  render() {
    const { link, displayText, classes } = this.props;

    return (
      // @ts-expect-error - TS2339 - Property 'root' does not exist on type 'ClassNameMap<never>'.
      <a href={link} className={classes.root}>
        <Text variant="subtitle1">{displayText}</Text>
      </a>
    );
  }
}

export default withStyles(styles)(LinkText);
