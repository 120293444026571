import {Code} from 'serviceNew/model/code';
import { Deal } from 'serviceNew/model/deal';
import { Offering } from 'serviceNew/model/offering';
import { TranslationObject } from 'serviceNew/locale';
import { CodeGroup } from 'serviceNew/model/codeGroup';
import { Location } from 'serviceNew/model/location';

export type EntityDataSelection = Deal | Offering | Code;

export type Entity = 'DEAL' | 'OFFERING' | 'CODE' | 'CODE_GROUP' | 'LOCATION';

export type EntityTypeOption = 'DEAL' | 'OFFERING' | 'CODE';

export type EntityData = {
  DEAL: Array<Deal> | null | undefined,
  OFFERING: Array<Offering> | null | undefined,
  CODE: Array<(Code) & {
    locationName: string | null | undefined
  }> | null | undefined,
  CODE_GROUP: Array<CodeGroup> | null | undefined,
  LOCATION: Array<Location> | null | undefined
};

export const ENTITY = {
  DEAL: 'DEAL',
  OFFERING: 'OFFERING',
  CODE: 'CODE',
  CODE_GROUP: 'CODE_GROUP',
  LOCATION: 'LOCATION',
} as const;

export const ENTITY_LABEL: Partial<Record<Entity, TranslationObject>> = {
  CODE_GROUP: { key: 'codeGroup.codeGroup' },
  OFFERING: { key: 'offering.offering' },
  DEAL: { key: 'deal' },
  CODE: { key: 'code.code' },
  LOCATION: { key: 'location.location' },
};
