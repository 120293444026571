import * as React from 'react';

import apiNew from 'serviceNew/api/sharedApiClient';
import { IdType } from '@poinz/api';
import { BusinessProfile } from 'serviceNew/model/businessProfile';
import businessProfileApi, { createBpFormData } from 'serviceNew/api/businessProfile';
import {
  withOptionalContractCompany,
  OptionalContractCompanyProps
} from 'App/providers/ContractCompanyProvider';
import { useLocalStorage } from '@poinz/hooks';

type BusinessProfileContextType = {
  businessProfileId: IdType | null | undefined;
  selectedBusinessProfile: BusinessProfile | null | undefined;
  businessProfiles: BusinessProfile[];
  selectBusinessProfile: (id: IdType) => void;
  createBusinessProfile: (values: Partial<BusinessProfile>) => Promise<void>;
  updateBusinessProfile: (id: IdType, values: Partial<BusinessProfile>) => Promise<void>;
  removeBusinessProfile: (id: IdType) => Promise<void>;
  pushPosDevices: (id: IdType) => Promise<void>;
  getBusinessProfile: (id: IdType) => Promise<BusinessProfile>;
  isLoading: boolean;
};

const BusinessProfileContext = React.createContext<BusinessProfileContextType>({
  businessProfileId: null,
  businessProfiles: [],
  selectedBusinessProfile: undefined,
  selectBusinessProfile: () => {},
  createBusinessProfile: async () => {},
  updateBusinessProfile: async () => {},
  removeBusinessProfile: async () => {},
  pushPosDevices: async () => {},

  // @ts-expect-error - TS2322 - Type '() => Promise<void>' is not assignable to type '(id: number) => Promise<BusinessProfile>'.
  getBusinessProfile: async () => {},
  isLoading: false
});

export type BusinessProfileProps = {
  businessProfileContext: BusinessProfileContextType;
};

type Props = {
  children: React.ReactNode;
} & OptionalContractCompanyProps;

const init: IdType | null | undefined = undefined;

function BusinessProfileProvider(props: Props) {
  const { children, contractCompanyId } = props;

  const [selectedBusinessProfile, setSelectedBusinessProfile] = React.useState<
    BusinessProfile | null | undefined
  >(undefined);
  const [businessProfiles, setBusinessProfiles] = React.useState<BusinessProfile[]>([]);
  const [businessProfileId, setBusinessProfileId] = useLocalStorage<IdType | null | undefined>({
    key: 'businessProfileId',
    initialValue: init
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(contractCompanyId != null);

  React.useEffect(() => {
    if (!selectedBusinessProfile || businessProfileId !== selectedBusinessProfile.id) {
      const newSelectedBusinessProfile = businessProfiles.find(bp => bp.id === businessProfileId);
      setSelectedBusinessProfile(newSelectedBusinessProfile);
      if (!isLoading && newSelectedBusinessProfile == null) {
        setBusinessProfileId(undefined);
      }
    }
  }, [
    businessProfiles,
    businessProfileId,
    isLoading,
    setBusinessProfileId,
    selectedBusinessProfile
  ]);

  const selectBusinessProfile = React.useCallback(
    (id?: IdType | null) => {
      setBusinessProfileId(id);
    },
    [setBusinessProfileId]
  );

  const createBusinessProfile = React.useCallback(
    async (values: Partial<BusinessProfile>) => {
      if (contractCompanyId != null) {
        const newBusinessProfileId = await businessProfileApi.create({ contractCompanyId, values });
        const newBusinessProfile = await apiNew.backend.businessProfile.get({
          contractCompanyId,
          businessProfileId: newBusinessProfileId
        });
        // @ts-expect-error - TS2322 - Type 'BusinessProfile | BusinessProfile' is not assignable to type 'BusinessProfile'. | TS2322 - Type 'import("/Users/mare/Desktop/projects_new/klunga/packages/api/dist/partnerClient/backend/types/businessProfile").BusinessProfile' is not assignable to type 'import("/Users/mare/Desktop/projects_new/klunga/apps/admin-ui/src/serviceNew/model/businessProfile").BusinessProfile'.
        setBusinessProfiles([...businessProfiles, newBusinessProfile]);
      }
    },
    [businessProfiles, contractCompanyId]
  );

  const updateBusinessProfile = React.useCallback(
    async (id: IdType, values: Partial<BusinessProfile>) => {
      if (contractCompanyId != null) {
        const updatedBusinessProfile = await apiNew.backend.businessProfile.update(
          {
            contractCompanyId,
            businessProfileId: id
          },
          // @ts-expect-error - TS2345 - Argument of type 'Partial<BusinessProfile>' is not assignable to parameter of type 'BusinessProfile'.
          createBpFormData(values)
        );
        setBusinessProfiles([
          // @ts-expect-error - TS2322 - Type 'BusinessProfile | BusinessProfile' is not assignable to type 'BusinessProfile'.
          ...businessProfiles.filter(bp => bp.id !== id),
          // @ts-expect-error - TS2322 - Type 'import("/Users/mare/Desktop/projects_new/klunga/packages/api/dist/partnerClient/backend/types/businessProfile").BusinessProfile' is not assignable to type 'import("/Users/mare/Desktop/projects_new/klunga/apps/admin-ui/src/serviceNew/model/businessProfile").BusinessProfile'.
          updatedBusinessProfile
        ]);
      }
    },
    [businessProfiles, contractCompanyId]
  );

  const removeBusinessProfile = React.useCallback(
    async (id: IdType) => {
      if (contractCompanyId != null) {
        await businessProfileApi.remove({
          contractCompanyId,
          businessProfileId: id
        });
        setBusinessProfiles(businessProfiles.filter(bp => bp.id !== id));
      }
    },
    [businessProfiles, contractCompanyId]
  );

  const pushPosDevices = React.useCallback(
    async (id: IdType) => {
      if (contractCompanyId != null) {
        await businessProfileApi.pushPosDevices({
          contractCompanyId,
          businessProfileId: id
        });
      }
    },
    [contractCompanyId]
  );

  const getBusinessProfile = React.useCallback(
    async (id: IdType) => {
      if (contractCompanyId != null) {
        return apiNew.backend.businessProfile.get({
          contractCompanyId,
          businessProfileId: id
        });
      }
      throw new Error('Contract company needs to be set in order to load the business profile.');
    },
    [contractCompanyId]
  );

  React.useEffect(() => {
    async function getBusinessProfiles(id: IdType) {
      setIsLoading(true);
      const retrievedBusinessProfiles = await apiNew.backend.businessProfile.getList(id);
      // @ts-expect-error - TS2345 - Argument of type 'BusinessProfile[]' is not assignable to parameter of type 'SetStateAction<BusinessProfile[]>'.
      setBusinessProfiles(retrievedBusinessProfiles);
      setIsLoading(false);
    }
    if (contractCompanyId != null) {
      getBusinessProfiles(contractCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCompanyId]);

  React.useEffect(() => {
    if (!isLoading && businessProfileId == null && businessProfiles.length === 1) {
      selectBusinessProfile(businessProfiles[0].id);
    }
    if (
      !isLoading &&
      businessProfileId != null &&
      businessProfiles &&
      !businessProfiles.find(bp => bp.id === businessProfileId)
    ) {
      selectBusinessProfile(undefined);
    }
  }, [businessProfileId, businessProfiles, isLoading, selectBusinessProfile]);

  const contextValue = React.useMemo(
    () => ({
      isLoading,
      businessProfileId,
      businessProfiles,
      selectedBusinessProfile,
      selectBusinessProfile,
      createBusinessProfile,
      updateBusinessProfile,
      removeBusinessProfile,
      pushPosDevices,
      getBusinessProfile
    }),
    [
      businessProfileId,
      selectedBusinessProfile,
      businessProfiles,
      createBusinessProfile,
      isLoading,
      pushPosDevices,
      removeBusinessProfile,
      selectBusinessProfile,
      updateBusinessProfile,
      getBusinessProfile
    ]
  );

  return (
    // @ts-expect-error - TS2322 - Type '{ isLoading: boolean; businessProfileId: number | null | undefined; businessProfiles: BusinessProfile[]; selectedBusinessProfile: BusinessProfile | null | undefined; ... 5 more ...; getBusinessProfile: (id: number) => Promise<...>; }' is not assignable to type 'BusinessProfileContextType'.
    <BusinessProfileContext.Provider value={contextValue}>
      {children}
    </BusinessProfileContext.Provider>
  );
}

export function useBusinessProfile() {
  return React.useContext(BusinessProfileContext);
}

export function withBusinessProfile<
  P extends BusinessProfileProps,
  C extends React.ComponentType<P>
>(
  Comp: C
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>, BusinessProfileProps>
> {
  return (props: Record<any, any>) => (
    <BusinessProfileContext.Consumer>
      {(context: BusinessProfileContextType) => (
        <Comp {...(props as any)} businessProfileContext={context} />
      )}
    </BusinessProfileContext.Consumer>
  );
}

export default withOptionalContractCompany(React.memo(BusinessProfileProvider));
