import React from 'react';
import ReactDOM from 'react-dom';
import {
  Input,
  FilledInput,
  OutlinedInput,
  InputLabel,
  Chip,
  FormControl,
  FormHelperText,
  ChipProps,
  InputProps
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { blue } from '@mui/material/colors';
import clsx from 'clsx';

const variantComponent = {
  standard: Input,
  filled: FilledInput,
  outlined: OutlinedInput
} as const;

const styles = (theme: any) => {
  const light = theme.palette.type === 'light';
  const bottomLineColor = light ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)';

  return {
    root: {},
    inputRoot: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      flex: 1,
      marginTop: 0,
      minWidth: 70,
      '&$outlined,&$filled': {
        boxSizing: 'border-box'
      },
      '&$outlined': {
        paddingTop: 14
      },
      '&$filled': {
        paddingTop: 28
      }
    },
    input: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      appearance: 'none', // Remove border in Safari, doesn't seem to break anything in other browsers
      WebkitTapHighlightColor: 'rgba(0,0,0,0)', // Remove mobile color flashing (deprecated style).
      float: 'left',
      flex: 1
    },
    chipContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      cursor: 'text',
      marginBottom: -2,
      minHeight: 40,
      '&$labeled&$standard': {
        marginTop: 18
      }
    },
    outlined: {
      '& input': {
        height: 16,
        paddingTop: 4,
        paddingBottom: 12,
        marginTop: 4,
        marginBottom: 4
      }
    },
    standard: {},
    filled: {
      '& input': {
        height: 22,
        marginBottom: 4,
        marginTop: 4,
        paddingTop: 0
      },
      '$marginDense & input': {
        height: 26
      }
    },
    labeled: {},
    label: {
      top: 4,
      '&$outlined&:not($labelShrink)': {
        top: 2,
        '$marginDense &': {
          top: 5
        }
      },
      '&$filled&:not($labelShrink)': {
        top: 15,
        '$marginDense &': {
          top: 20
        }
      }
    },
    labelShrink: {
      top: 0
    },
    helperText: {
      marginBottom: -20
    },
    focused: {},
    disabled: {},
    underline: {
      '&:after': {
        borderBottom: `2px solid ${theme.palette.primary[light ? 'dark' : 'light']}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut
        }),
        pointerEvents: 'none' // Transparent to the hover style.
      },
      '&$focused:after': {
        transform: 'scaleX(1)'
      },
      '&$error:after': {
        borderBottomColor: theme.palette.error.main,
        transform: 'scaleX(1)' // error is always underlined in red
      },
      '&:before': {
        borderBottom: `1px solid ${bottomLineColor}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '"\\00a0"',
        position: 'absolute',
        right: 0,
        transition: theme.transitions.create('border-bottom-color', {
          duration: theme.transitions.duration.shorter
        }),
        pointerEvents: 'none' // Transparent to the hover style.
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid ${theme.palette.text.primary}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderBottom: `1px solid ${bottomLineColor}`
        }
      },
      '&$disabled:before': {
        borderBottomStyle: 'dotted'
      }
    },
    error: {
      '&:after': {
        backgroundColor: theme.palette.error.main,
        transform: 'scaleX(1)' // error is always underlined in red
      }
    },
    chip: {
      margin: '0 8px 8px 0',
      float: 'left'
    },
    marginDense: {}
  };
};

const keyCodes = {
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39
} as const;

type Props = ChipProps &
  InputProps & {
    allowDuplicates?: boolean;
    alwaysShowPlaceholder?: boolean;
    chipRenderer?: any;
    clearInputValueOnChange?: boolean;
    dataSource?: Array<any>;
    dataSourceConfig: {
      text: string;
      value: string;
    };
    delayBeforeAdd?: boolean;
    disableUnderline?: boolean;
    fullWidthInput?: boolean;
    newChipKeyCodes?: Array<number>;
    newChipKeys?: Array<string>;
    onAdd?: any;
    onBeforeAdd?: any;
    onUpdateInput?: any;
    filter?: any;
    required?: boolean;
    rootRef?: any;
    classes: any;
  };

class ChipInput extends React.Component<Props, any> {
  state = {
    chips: [],
    errorText: undefined,
    focusedChip: null,
    inputValue: '',
    isClean: true,
    isFocused: false,
    chipsUpdated: false,
    prevPropsValue: []
  };

  labelRef: any;
  input: any;
  labelNode: any;
  inputBlurTimeout: any;
  actualInput: any;
  _preventChipCreation: any;
  _keyPressed: any;

  constructor(props: any) {
    super(props);
    if (props.defaultValue) {
      this.state.chips = props.defaultValue;
    }
    this.labelRef = React.createRef();
    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.props.variant === 'outlined') {
      // eslint-disable-next-line
      this.labelNode = ReactDOM.findDOMNode(this.labelRef.current);
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.inputBlurTimeout);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let newState = null;

    if (props.value && props.value.length !== state.prevPropsValue.length) {
      // @ts-expect-error - TS2322 - Type '{ prevPropsValue: any; }' is not assignable to type 'null'.
      newState = { prevPropsValue: props.value };
      if (props.clearInputValueOnChange) {
        // @ts-expect-error - TS2531 - Object is possibly 'null'.
        newState.inputValue = '';
      }
    }

    // if change detection is only needed for clearInputValueOnChange
    if (
      props.clearInputValueOnChange &&
      props.value &&
      props.value.length !== state.prevPropsValue.length
    ) {
      // @ts-expect-error - TS2322 - Type '{ prevPropsValue: any; inputValue: string; }' is not assignable to type 'null'.
      newState = { prevPropsValue: props.value, inputValue: '' };
    }

    if (props.disabled) {
      // @ts-expect-error - TS2698 - Spread types may only be created from object types.
      newState = { ...newState, focusedChip: null };
    }

    if (!state.chipsUpdated && props.defaultValue) {
      // @ts-expect-error - TS2698 - Spread types may only be created from object types.
      newState = { ...newState, chips: props.defaultValue };
    }

    return newState;
  }

  /**
   * Blurs this component.
   * @public
   */
  blur() {
    if (this.input) this.actualInput.blur();
  }

  /**
   * Focuses this component.
   * @public
   */
  focus = () => {
    this.actualInput.focus();
    if (this.state.focusedChip != null) {
      this.setState({ focusedChip: null });
    }
  };

  handleInputBlur = (event: any) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.setState({ isFocused: false });
    if (this.state.focusedChip != null) {
      this.setState({ focusedChip: null });
    }
    const value = event.target.value;
    let addChipOptions;
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    switch (this.props.blurBehavior) {
      // @ts-expect-error - TS7029 - Fallthrough case in switch.
      case 'add-or-clear':
        addChipOptions = { clearInputOnFail: true };
      // falls through
      case 'add':
        if (this.props.delayBeforeAdd) {
          // Lets assume that we only want to add the existing content as chip, when
          // another event has not added a chip within 200ms .
          // e.g. onSelection Callback in Autocomplete case
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
          const numChipsBefore = (this.props.value || this.state.chips).length;
          this.inputBlurTimeout = setTimeout(() => {
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
            const numChipsAfter = (this.props.value || this.state.chips).length;
            if (numChipsBefore === numChipsAfter) {
              this.handleAddChip(value, addChipOptions);
            } else {
              this.clearInput();
            }
          }, 150);
        } else {
          this.handleAddChip(value, addChipOptions);
        }
        break;
      case 'clear':
        this.clearInput();
        break;
    }
  };

  handleInputFocus = (event: any) => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleKeyDown = (event: any) => {
    const { focusedChip } = this.state;
    this._keyPressed = false;
    this._preventChipCreation = false;
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
      if (event.isDefaultPrevented()) {
        return;
      }
    }
    // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'any[]'.
    const chips: Array<any> = this.props.value || this.state.chips;
    if (
      (this.props.newChipKeyCodes && this.props.newChipKeyCodes.indexOf(event.keyCode) >= 0) ||
      (this.props.newChipKeys && this.props.newChipKeys.indexOf(event.key) >= 0)
    ) {
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'. | TS2531 - Object is possibly 'null'. | TS2339 - Property 'value' does not exist on type 'EventTarget'.
      const result = this.handleAddChip(event.target.value);
      if (result !== false) {
        event.preventDefault();
      }
      return;
    }

    switch (event.keyCode) {
      case keyCodes.BACKSPACE:
        if (event.target.value === '') {
          if (focusedChip != null) {
            this.handleDeleteChip(chips[focusedChip], focusedChip);
            if (focusedChip > 0) {
              this.setState({ focusedChip: focusedChip - 1 });
            }
          } else {
            this.setState({ focusedChip: chips.length - 1 });
          }
        }
        break;
      case keyCodes.DELETE:
        if (event.target.value === '' && focusedChip != null) {
          this.handleDeleteChip(chips[focusedChip], focusedChip);
          if (focusedChip <= chips?.length - 1) {
            this.setState({ focusedChip });
          }
        }
        break;
      case keyCodes.LEFT_ARROW:
        if (focusedChip == null && event.target.value === '' && chips.length) {
          this.setState({ focusedChip: chips.length - 1 });
        } else if (focusedChip != null && focusedChip > 0) {
          this.setState({ focusedChip: focusedChip - 1 });
        }
        break;
      case keyCodes.RIGHT_ARROW:
        if (focusedChip != null && focusedChip < chips.length - 1) {
          this.setState({ focusedChip: focusedChip + 1 });
        } else {
          this.setState({ focusedChip: null });
        }
        break;
      default:
        this.setState({ focusedChip: null });
        break;
    }
  };

  handleKeyUp = (event: any) => {
    if (
      !this._preventChipCreation &&
      ((this.props.newChipKeyCodes && this.props.newChipKeyCodes.indexOf(event.keyCode) >= 0) ||
        (this.props.newChipKeys && this.props.newChipKeys.indexOf(event.key) >= 0)) &&
      this._keyPressed
    ) {
      this.clearInput();
    } else {
      this.updateInput(event.target.value);
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
    }
  };

  handleKeyPress = (event: any) => {
    this._keyPressed = true;
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    }
  };

  handleUpdateInput = (e: any) => {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    if (this.props.inputValue == null) {
      this.updateInput(e.target.value);
    }

    if (this.props.onUpdateInput) {
      this.props.onUpdateInput(e);
    }
  };

  /**
   * Handles adding a chip.
   * @param {string|object} chip Value of the chip, either a string or an object (if dataSourceConfig is set)
   * @param {object=} options Additional options
   * @param {boolean=} options.clearInputOnFail If `true`, and `onBeforeAdd` returns `false`, clear the input
   * @returns True if the chip was added (or at least `onAdd` was called), false if adding the chip was prevented
   */
  handleAddChip(
    chip: any,
    options:
      | undefined
      | {
          clearInputOnFail: boolean;
        }
  ) {
    if (this.props.onBeforeAdd && !this.props.onBeforeAdd(chip)) {
      this._preventChipCreation = true;
      if (options != null && options.clearInputOnFail) {
        this.clearInput();
      }
      return false;
    }
    this.clearInput();
    const chips = this.props.value || this.state.chips;

    if (this.props.dataSourceConfig) {
      if (typeof chip === 'string') {
        chip = {
          [this.props.dataSourceConfig.text]: chip,
          [this.props.dataSourceConfig.value]: chip
        };
      }

      if (
        this.props.allowDuplicates ||
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        !chips.some(
          c => c[this.props.dataSourceConfig.value] === chip[this.props.dataSourceConfig.value]
        )
      ) {
        if (this.props.value && this.props.onAdd) {
          this.props.onAdd(chip);
        } else {
          this.updateChips([...this.state.chips, chip]);
        }
      }
      return true;
    }

    if (chip.trim().length > 0) {
      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
      if (this.props.allowDuplicates || chips.indexOf(chip) === -1) {
        if (this.props.value && this.props.onAdd) {
          this.props.onAdd(chip);
        } else {
          this.updateChips([...this.state.chips, chip]);
        }
      }
      return true;
    }
    return false;
  }

  handleDeleteChip(chip: any, i: any) {
    if (!this.props.value) {
      const chips = this.state.chips.slice();
      const changed = chips.splice(i, 1); // remove the chip at index i
      if (changed) {
        let focusedChip = this.state.focusedChip;
        if (this.state.focusedChip === i) {
          focusedChip = null;
        } else if (Number(this.state.focusedChip) > i) {
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          focusedChip = Number(this.state.focusedChip) - 1;
        }
        this.updateChips(chips, { focusedChip });
      }
    } else if (this.props.onDelete) {
      // @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
      this.props.onDelete(chip, i);
    }
  }

  updateChips(chips, additionalUpdates = {}) {
    this.setState({ chips, chipsUpdated: true, ...additionalUpdates });
    if (this.props.onChange) {
      this.props.onChange(chips);
    }
  }

  /**
   * Clears the text field for adding new chips.
   * This only works in uncontrolled input mode, i.e. if the inputValue prop is not used.
   * @public
   */
  clearInput() {
    this.updateInput('');
  }

  updateInput(value: string) {
    this.setState({ inputValue: value });
  }

  /**
   * Set the reference to the actual input, that is the input of the Input.
   * @param {object} ref - The reference
   */
  setActualInputRef = (ref: any) => {
    this.actualInput = ref;
    if (this.props.inputRef) {
      // @ts-expect-error - TS2349 - This expression is not callable.
      this.props.inputRef(ref);
    }
  };

  render() {
    const {
      // eslint-disable-next-line
      allowDuplicates,
      alwaysShowPlaceholder,
      // eslint-disable-next-line
      // @ts-expect-error - TS2339 - Property 'blurBehavior' does not exist on type 'Readonly<Props>'.
      blurBehavior,
      // eslint-disable-next-line
      children,
      chipRenderer = defaultChipRenderer,
      classes,
      className,
      // eslint-disable-next-line
      clearInputValueOnChange,
      // eslint-disable-next-line
      dataSource,
      dataSourceConfig,
      // eslint-disable-next-line
      defaultValue,
      // eslint-disable-next-line
      delayBeforeAdd,
      disabled,
      disableUnderline,
      error,
      // eslint-disable-next-line
      filter,
      // @ts-expect-error - TS2339 - Property 'FormHelperTextProps' does not exist on type 'Readonly<Props>'.
      FormHelperTextProps,
      fullWidth,
      fullWidthInput,
      // @ts-expect-error - TS2339 - Property 'helperText' does not exist on type 'Readonly<Props>'.
      helperText,
      id,
      // @ts-expect-error - TS2339 - Property 'InputProps' does not exist on type 'Readonly<Props>'.
      InputProps = {},
      // eslint-disable-next-line
      inputRef,
      // @ts-expect-error - TS2339 - Property 'InputLabelProps' does not exist on type 'Readonly<Props>'.
      InputLabelProps = {},
      // @ts-expect-error - TS2339 - Property 'inputValue' does not exist on type 'Readonly<Props>'.
      inputValue,
      label,
      // eslint-disable-next-line
      newChipKeyCodes,
      // eslint-disable-next-line
      newChipKeys,
      // eslint-disable-next-line
      onBeforeAdd,
      // eslint-disable-next-line
      onAdd,
      // eslint-disable-next-line
      onBlur,
      // eslint-disable-next-line
      onDelete,
      // eslint-disable-next-line
      onChange,
      // eslint-disable-next-line
      onFocus,
      // eslint-disable-next-line
      onKeyDown,
      // eslint-disable-next-line
      onKeyPress,
      // eslint-disable-next-line
      onKeyUp,
      // eslint-disable-next-line
      onUpdateInput,
      placeholder,
      readOnly,
      required,
      rootRef,
      // eslint-disable-next-line
      value,
      variant = 'standard',
      ...other
    } = this.props;

    const chips: any = value || this.state.chips;
    const actualInputValue = inputValue != null ? inputValue : this.state.inputValue;

    const hasInput =
      (this.props.value || actualInputValue).length > 0 || actualInputValue.length > 0;
    const shrinkFloatingLabel =
      InputLabelProps.shrink != null
        ? InputLabelProps.shrink
        : label != null && (hasInput || this.state.isFocused || chips.length > 0);

    const chipComponents = chips.map((chip, i) => {
      const value = dataSourceConfig ? chip[dataSourceConfig.value] : chip;
      return chipRenderer(
        {
          value,
          text: dataSourceConfig ? chip[dataSourceConfig.text] : chip,
          chip,
          isDisabled: !!disabled,
          isReadOnly: readOnly,
          isFocused: this.state.focusedChip === i,
          handleClick: () => this.setState({ focusedChip: i }),
          handleDelete: () => this.handleDeleteChip(chip, i),
          className: classes.chip
        },
        i
      );
    });

    const InputMore: Record<string, any> = {};
    if (variant === 'outlined') {
      InputMore.notched = shrinkFloatingLabel;
      InputMore.labelWidth =
        (shrinkFloatingLabel && this.labelNode && this.labelNode.offsetWidth) || 0;
    }

    if (variant !== 'standard') {
      InputMore.startAdornment = <React.Fragment>{chipComponents}</React.Fragment>;
    } else {
      InputProps.disableUnderline = true;
    }

    const InputComponent = variantComponent[variant];

    return (
      <FormControl
        ref={rootRef}
        fullWidth={fullWidth}
        className={clsx(className, classes.root, {
          [classes.marginDense]: other.margin === 'dense'
        })}
        error={error}
        required={chips.length > 0 ? undefined : required}
        onClick={this.focus}
        disabled={disabled}
        variant={variant}
        {...other}
      >
        {label && (
          <InputLabel
            htmlFor={id}
            classes={{ root: clsx(classes[variant], classes.label), shrink: classes.labelShrink }}
            shrink={shrinkFloatingLabel}
            focused={this.state.isFocused}
            variant={variant}
            ref={this.labelRef}
            required={required}
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <div
          className={clsx(classes[variant], classes.chipContainer, {
            [classes.focused]: this.state.isFocused,
            [classes.underline]: !disableUnderline && variant === 'standard',
            [classes.disabled]: disabled,
            [classes.labeled]: label != null,
            [classes.error]: error
          })}
        >
          {variant === 'standard' && chipComponents}
          <InputComponent
            ref={this.input}
            classes={{
              input: clsx(classes.input, classes[variant]),
              root: clsx(classes.inputRoot, classes[variant])
            }}
            id={id}
            value={actualInputValue}
            onChange={this.handleUpdateInput}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            inputRef={this.setActualInputRef}
            disabled={disabled}
            fullWidth={fullWidthInput}
            placeholder={
              (!hasInput && (shrinkFloatingLabel || label == null)) || alwaysShowPlaceholder
                ? placeholder
                : null
            }
            readOnly={readOnly}
            {...InputProps}
            {...InputMore}
          />
        </div>
        {helperText && (
          <FormHelperText
            {...FormHelperTextProps}
            className={
              FormHelperTextProps
                ? clsx(FormHelperTextProps.className, classes.helperText)
                : classes.helperText
            }
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

// @ts-expect-error - TS2339 - Property 'defaultProps' does not exist on type 'typeof ChipInput'.
ChipInput.defaultProps = {
  allowDuplicates: false,
  blurBehavior: 'clear',
  clearInputValueOnChange: false,
  delayBeforeAdd: false,
  disableUnderline: false,
  newChipKeyCodes: [13],
  newChipKeys: ['Enter'],
  variant: 'standard'
};

// @ts-expect-error - TS2345 - Argument of type '(theme: any) => { root: {}; inputRoot: { display: string; flexWrap: string; flex: number; marginTop: number; minWidth: number; '&$outlined,&$filled': { boxSizing: string; }; '&$outlined': { paddingTop: number; }; '&$filled': { ...; }; }; ... 14 more ...; marginDense: {}; }' is not assignable to parameter of type 'Styles<any, any, string>'.
export default withStyles(styles, { name: 'WAMuiChipInput' })(ChipInput);

export const defaultChipRenderer = (
  {
    // @ts-expect-error - TS2339 - Property 'text' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    text,
    // @ts-expect-error - TS2339 - Property 'isFocused' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    isFocused,
    // @ts-expect-error - TS2339 - Property 'isDisabled' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    isDisabled,
    // @ts-expect-error - TS2339 - Property 'isReadOnly' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    isReadOnly,
    // @ts-expect-error - TS2339 - Property 'handleClick' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    handleClick,
    // @ts-expect-error - TS2339 - Property 'handleDelete' does not exist on type 'ChipOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "size" | ... 15 more ... | "skipFocusWhenDisabled"> & { ...; }'.
    handleDelete,
    className
  }: ChipProps,
  key: string
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      backgroundColor: isFocused ? blue[300] : undefined
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
);
