import * as React from 'react';
import Downshift from 'downshift';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { MenuItem, Paper } from '@mui/material';
import TextInput from 'App/components/TextInput';
import Icon, { IconType } from 'App/components/Icon';
import { TranslationObject } from 'serviceNew/locale';

import { matchSorter } from 'match-sorter';

import clsx from 'clsx';
// @ts-expect-error - TS2307 - Cannot find module './powered_by_google_on_white.png' or its corresponding type declarations.
import poweredByGoogleImage from './powered_by_google_on_white.png';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  inputRoot: {
    flex: 1,
    // @ts-expect-error - TS2339 - Property 'width' does not exist on type '{}'.
    width: ({ width }) => width || 'auto'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    // @ts-expect-error - TS2339 - Property 'width' does not exist on type '{}'.
    width: ({ width }) => width || 'auto'
  }
}));

type Suggestion<T> = {
  id: T;
  name: string;
};

type Props<T> = {
  className?: string | null | undefined;
  label: TranslationObject;
  placeholder?: TranslationObject;
  noLabel?: boolean;
  suggestions: Array<Suggestion<T>>;
  width?: string | number;
  onSelect?: (id?: T | null | undefined) => unknown | null | undefined;
  onChange?: (searchString: string) => unknown;
  resetCondition?: (searchString: string) => boolean;
  isPoweredByGoogle?: boolean;
  withLeadingIcon?: IconType;
  autoFocus?: boolean;
  initialValue?: string;
  disabled?: boolean;
  isLocal?: boolean;
};

function AutoCompletion<T extends string | number>(props: Props<T>) {
  const {
    onChange,
    width,
    onSelect,
    suggestions,
    label,
    noLabel,
    placeholder,
    disabled,
    resetCondition,
    isPoweredByGoogle,
    withLeadingIcon,
    autoFocus,
    initialValue,
    className,
    isLocal
  } = props;
  const classes = useStyles({ width });
  const [searchString, setSearchString] = React.useState<string>(initialValue || '');
  const [selectedItem, setSelectedItem] = React.useState<Suggestion<T> | null | undefined>(null);

  const handleSearchStringChange = React.useCallback(
    (newSearchString: string) => {
      if (newSearchString != null) {
        setSearchString(newSearchString);
        if (onChange) {
          onChange(newSearchString);
        }
      }
    },
    [onChange]
  );

  const handleStateChange = React.useCallback(
    state => {
      switch (state.type) {
        case Downshift.stateChangeTypes.changeInput:
          handleSearchStringChange(state.inputValue);
          break;
        default:
      }
    },
    [handleSearchStringChange]
  );

  const handleSelectedItemChange = React.useCallback(
    (item?: Suggestion<T> | null) => {
      setSearchString(item ? item.name : '');
      setSelectedItem(item);
      if (onSelect) {
        onSelect(item ? item.id : undefined);
      }
    },
    [onSelect]
  );

  const handleFocus = (openMenu: any) => () => {
    if (isLocal) {
      openMenu();
    }
  };

  const handleBlur = (closeMenu: any) => () => {
    if (isLocal) {
      closeMenu();
    }
    if (searchString !== null && resetCondition && resetCondition(searchString)) {
      handleSelectedItemChange(null);
    }
  };

  function getSuggestions(inputValue: any) {
    if (!isLocal) {
      return suggestions;
    }
    return inputValue ? matchSorter(suggestions, inputValue, { keys: ['name'] }) : suggestions;
  }

  function renderInput({ InputProps, ...other }: any) {
    return (
      <TextInput
        InputProps={{
          classes: {
            root: classes.inputRoot,
            // @ts-expect-error - TS2339 - Property 'inputInput' does not exist on type 'ClassNameMap<"container" | "paper" | "inputRoot">'.
            input: classes.inputInput
          },
          startAdornment: withLeadingIcon ? (
            <InputAdornment position="start">
              <Icon icon={withLeadingIcon} />
            </InputAdornment>
          ) : undefined,
          ...InputProps
        }}
        {...other}
        {...{
          autoFocus,
          noLabel,
          placeholder,
          disabled
        }}
        label={label as any}
        placeholder={placeholder as any}
      />
    );
  }

  function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem ? selectedItem.name : '').indexOf(suggestion.name) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.name}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 700 : 400
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  function renderPoweredByGoogle() {
    return (
      <MenuItem key="google" component="div">
        <img src={poweredByGoogleImage} alt="powered by Google" />
      </MenuItem>
    );
  }

  return (
    // @ts-expect-error - TS2339 - Property 'root' does not exist on type 'ClassNameMap<"container" | "paper" | "inputRoot">'.
    <div className={clsx(classes.root, className)}>
      <Downshift
        // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'Suggestion<T>'.
        itemToString={item => (item ? item.value : '')}
        inputValue={searchString}
        onChange={handleSelectedItemChange}
        onStateChange={handleStateChange}
        {...{ selectedItem }}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          inputValue,
          openMenu,
          closeMenu
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            onBlur: handleBlur(closeMenu),
            onFocus: handleFocus(openMenu),
            value: searchString
          });

          return (
            <div className={classes.container}>
              {renderInput({
                InputProps: {
                  onBlur,
                  onFocus
                },
                inputProps
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        // @ts-expect-error - TS2345 - Argument of type '{ suggestion: Suggestion<T>; index: number; itemProps: Omit<Overwrite<GetItemPropsReturnValue, { item: Suggestion<T>; }>, "item" | "index">; highlightedIndex: number | null; selectedItem: Suggestion<...> | ... 1 more ... | undefined; }' is not assignable to parameter of type '{ suggestion: any; index: any; itemProps: any; highlightedIndex: any; }'.
                        selectedItem
                      })
                    )}
                    {isPoweredByGoogle && renderPoweredByGoogle()}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}

AutoCompletion.defaultProps = {
  isLocal: false
};

export default AutoCompletion;
