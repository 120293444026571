import { PoinzApiClient } from '../../api';
import { getDeviceInfo } from '../../utils/deviceInfo';
import { User } from './types';

const VERSION = '/v2';
const USERS = '/users';

const apiRoot = `${VERSION}${USERS}`;

const getMyUser =
  (apiClient: PoinzApiClient) =>
  async (deviceId: string): Promise<[User, boolean]> => {
    const deviceInfo = getDeviceInfo();
    const result = await apiClient.get(`${apiRoot}/me`, {
      queryParams: {
        deviceId,
        ...deviceInfo
      }
    });

    if (result.headers.get('x-refresh-firebase-token')) {
      return [result.body, true];
    }
    return [result.body, false];
  };

const updateUser =
  (apiClient: PoinzApiClient) =>
  async (id: number, userInfo: Partial<User>): Promise<User> => {
    const result = await apiClient.put(`${apiRoot}/${id}`, {
      body: userInfo
    });

    return result.body;
  };

const requestPasswordReset =
  (apiClient: PoinzApiClient) =>
  async (email: string): Promise<void> => {
    await apiClient.get(`${apiRoot}/request-password-reset`, {
      queryParams: { email },
      authenticated: false
    });
  };

export default (apiClient: PoinzApiClient) => ({
  getMe: getMyUser(apiClient),
  update: updateUser(apiClient),
  requestPasswordReset: requestPasswordReset(apiClient)
});
