import * as React from 'react';
import { useLocale } from 'App/providers/LocaleProvider';

import { Autocomplete } from '@mui/material';

import TextInput from './TextInput';
import { Option } from './TextSelect';

type Props = {
  options: Option[];
  className?: string | null | undefined;
  withSelectAll?: boolean;
  renderOption?: (arg1: Option) => React.ReactElement;
} & JSX.LibraryManagedAttributes<typeof TextInput, React.ComponentProps<typeof TextInput>>;

function MultiSelect({
  options,
  value,
  label,
  onChange,
  className,
  withSelectAll,
  renderOption,
  ...restProps
}: Props) {
  const { translate } = useLocale();
  const [selectedValue, setSelectedValue] = React.useState([]);

  const values = React.useMemo(() => {
    if (Array.isArray(value) && value.length > 0) {
      return options.filter(o => value.includes(o.value));
    }
    return selectedValue;
  }, [options, selectedValue, value]);

  function handleChange(event: any, option: any) {
    const newValues = option.map(o => o.value).includes('ALL') ? options : option;
    setSelectedValue(newValues);

    if (typeof onChange === 'function') {
      onChange(newValues.map(val => val.value));
    }
  }

  function getOptionLabel(option: any) {
    return translate(option.label.key, option.label?.context);
  }

  const allOptions = React.useMemo(
    () => [
      ...(values.length === options.length || !withSelectAll || options.length === 1
        ? []
        : [{ label: { key: 'general.all' }, value: 'ALL' }]),
      ...options
    ],
    [options, values.length, withSelectAll]
  );

  function renderAutoComplete() {
    return (
      <Autocomplete
        onChange={handleChange}
        value={values}
        multiple
        limitTags={3}
        options={allOptions}
        autoComplete
        filterSelectedOptions
        clearText={translate('general.clear')}
        closeText={translate('general.close')}
        openText={translate('general.open')}
        getOptionLabel={getOptionLabel}
        renderInput={params => <TextInput {...params} {...restProps} {...{ label }} />}
        renderOption={renderOption as any}
      />
    );
  }

  return <div {...{ className }}>{renderAutoComplete()}</div>;
}

MultiSelect.defaultProps = {
  value: undefined,
  className: '',
  withSelectAll: true
};

export default MultiSelect;
