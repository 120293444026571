import React from 'react';
import { FormControl, FormHelperText, Select, MenuItem, Grid } from '@mui/material';
import { moment } from 'serviceNew/locale/dates';
import DateRangePicker from './DateRangePicker';
import { ResolutionType, RESOLUTION_TYPE } from '../../constants/general';
import TranslatedText from './TranslatedText';

export type ActivityTimeLineType = {
  resolution?: ResolutionType;
  startDate: string;
  endDate: string;
};

type Props = ActivityTimeLineType & {
  handleChangeDate: (arg1: string, arg2: string) => void;
};

const ActivityTimeLineInput = ({ endDate, startDate, resolution, handleChangeDate }: Props) => {
  const resolutionMenuItems = Object.keys(RESOLUTION_TYPE).map(e => (
    <MenuItem key={e} value={e}>
      <TranslatedText text={{ key: RESOLUTION_TYPE[e] }} />
    </MenuItem>
  ));

  const maxDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
      <DateRangePicker
        onEndDateChange={handleChangeDate}
        onStartDateChange={handleChangeDate}
        helperText
        label={false}
        endDate={endDate}
        startDate={startDate}
        maxDate={maxDate}
        disableFuture
      />
      {resolution && (
        <FormControl variant="standard">
          <Select
            variant="standard"
            name="resolution"
            value={resolution}
            onChange={e => handleChangeDate('resolution', e.target.value)}
          >
            {resolutionMenuItems}
          </Select>
          <FormHelperText>
            <TranslatedText text={{ key: 'general.resolution' }} />
          </FormHelperText>
        </FormControl>
      )}
    </Grid>
  );
};

export default ActivityTimeLineInput;
