import { PoinzApiClient } from '../../api';
import eGift from './eGift';
import giftCard from './giftCard';
import giftCardLine from './giftCardLine';

export default (apiClient: PoinzApiClient) => ({
  eGift: eGift(apiClient),
  giftCardLine: giftCardLine(apiClient),
  giftCard: giftCard(apiClient)
});
