import React from 'react';
import { Link } from 'react-router-dom';
// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'Location'.
import { withRouter, Location } from 'react-router';
import {
  MenuItem,
  ListItemText,
  Typography,
  Chip,
  ListItemIcon,
  Tooltip,
  Icon
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withLocale, LocaleProps, LanguageInput } from 'App/providers/LocaleProvider';
import { SIDEBAR } from './Sidebar.utils';

export const isInexactMatch = (target: string, path: string) => path.indexOf(target) === 0;

const styles = (theme: any) => ({
  newChip: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    fontSize: '10px',
    height: '20px',
    width: '40px',
    marginLeft: '20px'
  },
  text: {
    margin: theme.spacing(0.5, 0)
  },
  selected: {
    backgroundColor: `${theme.palette.grey[200]}!important`
  }
});

type SidebarType = {
  link: string;
  label: string;
  icon?: string;
  menu?: string;
  new?: string;
};

const NavLink = withStyles(styles, { withTheme: true })(({
  menu,
  activated,
  classes,
  tooltip,
  formatMessage
}: {
  menu: SidebarType;
  activated: boolean;
  classes: any;
  tooltip: boolean;
  formatMessage: (arg1: LanguageInput) => string;
}) => {
  const subItem = Object.keys(menu).includes('menu');
  const menuText = formatMessage({ id: menu.label });
  const topMenuText = menu.menu ? formatMessage({ id: SIDEBAR[menu.menu].label }) : null;
  const tooltipLabel = topMenuText ? `${topMenuText} - ${menuText}` : menuText;

  const Item = (
    <MenuItem classes={{ selected: classes.selected }} selected={activated}>
      {menu.icon && (
        <ListItemIcon>
          <Icon>{menu.icon}</Icon>
        </ListItemIcon>
      )}
      <ListItemText disableTypography style={{ paddingLeft: subItem ? 20 : 8 }}>
        <Typography className={classes.text} variant={subItem ? 'body2' : 'subtitle1'}>
          {menuText}
          {menu.new && (
            <>
              <Chip className={classes.newChip} label={formatMessage({ id: 'general.new' })} />
            </>
          )}
        </Typography>
      </ListItemText>
    </MenuItem>
  );

  return tooltip ? (
    <Tooltip title={tooltipLabel} placement="right">
      {Item}
    </Tooltip>
  ) : (
    Item
  );
});

type Props = {
  menu: SidebarType;
  location: Location;
  open: boolean;
  isExact?: boolean;
} & LocaleProps;

const NavListItemUnconnected = ({
  menu,
  location,
  open,
  formatMessage,
  formatNumber,
  isExact
}: Props) => (
  <Link to={menu.link} style={{ color: 'black', textDecoration: 'none' }}>
    <NavLink
      menu={menu}
      activated={
        isExact ? menu.link === location.pathname : isInexactMatch(menu.link, location.pathname)
      }
      tooltip={!open}
      formatMessage={formatMessage}
      // @ts-expect-error - TS2322 - Type '{ menu: SidebarType; activated: boolean; tooltip: boolean; formatMessage: (value: LanguageInput) => string; formatNumber: (value: NumberInput) => string; }' is not assignable to type 'IntrinsicAttributes & (Omit<{ menu: SidebarType; activated: boolean; classes: any; tooltip: boolean; formatMessage: (arg1: LanguageInput) => string; }, "classes" | "theme"> & (StyledComponentProps<...> & (object | {})))'.
      formatNumber={formatNumber}
    />
  </Link>
);

export default withRouter(withLocale(NavListItemUnconnected));
