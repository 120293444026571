import * as React from 'react';
import {
  PoinzApiClient,
  ProblemApiError,
  ApiMethod,
  Params,
  Result,
  PaginatedResult
} from '@poinz/api';
import { getIdToken } from 'serviceNew/firebase/auth';
import { setAuthorizationToken, buildAgentHeader } from '@poinz/api';
import { TranslationObject } from 'serviceNew/locale';
import { ApiError } from '../model/apiError';
import { VERSION } from 'config';
export const SnackbarRef = React.createRef<any>();

const MAX_TRIES = 3;

export const NOT_FOUND = 'NOT_FOUND';

export type PaginationModel<T> = {
  pageNumber: number;
  totalPages: number;
  totalElements: number;
  dataList: Array<T>;
};

export const defaultPaginatedResult: PaginationModel<any> = {
  pageNumber: 0,
  totalPages: 0,
  totalElements: 0,
  dataList: []
};

export type NewPaginationModel<T> = {
  pageNumber: number;
  totalPages: number;
  totalElements: number;
  body: Array<T>;
};

export const newDefaultPaginatedResult: PaginatedResult<{
  body: any;
}> = {
  pageNumber: 0,
  totalPages: 0,
  totalElements: 0,
  body: []
};

export const newestDefaultPaginatedResult: PaginatedResult<{
  content: any;
}> = {
  pageNumber: 0,
  totalPages: 0,
  totalElements: 0,
  content: []
};

export function getPaginatedResult<T>(
  result: PaginatedResult<{
    headers: Headers;
    body: T;
    pageNumber: number;
  }>
): PaginationModel<T> {
  try {
    const {
      // eslint-disable-next-line no-unused-vars
      headers,
      // eslint-disable-next-line no-unused-vars
      body,
      pageNumber,
      totalPages,
      ...restResult
    } = result;
    return {
      ...restResult,
      // @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'T[]'.
      dataList: result.body,
      pageNumber,
      totalPages: totalPages - 1
    };
  } catch (e: any) {
    throw new Error('Error getting paginated result');
  }
}

type Notifications = {
  success: TranslationObject;
  error?: TranslationObject;
};

export async function errorHandler(response: Response) {
  const error = await response.json();
  const err = error.message ? error : { message: JSON.stringify(error, null, 2) };
  throw new ApiError(err);
}

export async function setCampAuthenticatedHeader(headers: any) {
  const idToken = (await getIdToken()) || '';
  if (idToken == null) {
    throw new Error('Token must be set for an authetnicated call.');
  }
  setAuthorizationToken(headers, '');
  setAuthorizationToken(headers, idToken);
  headers.set('grant_type', 'FIREBASE_AUTH');

  return headers;
}

export const apiCampAgent = buildAgentHeader({ app: 'camp', version: VERSION });

export const createCallEndpoint = (client: PoinzApiClient) => {
  const createCallEndpointFn = async (
    endpoint: string,
    method: ApiMethod,
    params: Params,
    retry?: number
  ): Promise<Result> => {
    // if the browser is offline, we throw an error
    if (!window.navigator.onLine) {
      throw new ApiError({ message: JSON.stringify('offline', null, 2) });
    }

    const { authenticated = true } = params;
    if (authenticated) {
      try {
        return await client.callAuthenticatedEndpoint(endpoint, method, params);
      } catch (e: any) {
        // refresh token if needed
        let shouldRefreshFirebaseToken = false;
        if (params.headers && params.headers.get('x-refresh-firebase-token')) {
          shouldRefreshFirebaseToken = true;
        }
        if (shouldRefreshFirebaseToken) {
          await getIdToken(true);

          // we need to limit this to avoid potential infinite loop
          if ((retry || 0) < MAX_TRIES) {
            return createCallEndpointFn(endpoint, method, params, retry ? retry++ : 1);
          }
        } else {
          // pass error further
          throw e;
        }
      }
    }
    return client.callEndpoint(endpoint, method, params);
  };
  return createCallEndpointFn;
};

export const createCallNotificationEndpoint =
  (apiFunc: (endpoint: string, method: ApiMethod, params: Params) => Promise<Result>) =>
  async (
    endpoint: string,
    method: ApiMethod,
    params: Params,
    notifications: Notifications
  ): Promise<Result> => {
    try {
      const result = await apiFunc(endpoint, method, params);
      if (SnackbarRef.current != null) {
        SnackbarRef.current(notifications.success);
      }
      return result;
    } catch (e: any) {
      if (e instanceof ApiError || e instanceof ProblemApiError) {
        // @ts-expect-error - TS2339 - Property 'message' does not exist on type 'ApiError | ProblemApiError'. | TS2339 - Property 'message' does not exist on type 'ApiError | ProblemApiError'.
        const errorText = e.message ? e.message.toString() : e.toString();
        if (SnackbarRef.current != null) {
          SnackbarRef.current(
            notifications.error
              ? {
                  key: notifications.error.key,
                  context: { ...notifications.error.context, error: errorText }
                }
              : e
          );
        }
      }
      throw e;
    }
  };
