import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
// @ts-expect-error - TS2724 - '"react-router"' has no exported member named 'Match'. Did you mean 'match'?
import { Match } from 'react-router';
import { UserRoles } from 'serviceNew/model/adminUser';
import { DASHBOARD_ACTIVITY } from 'App/routing';
import PrivateRoute from '../../PrivateRoute';
import CollectsActivity from './CollectsActivity/CollectsActivity';
import PoinzCollectsActivity from './PoinzCollectsActivity/PoinzCollectsActivity';
import PoinzStats from './PoinzStats/PoinzStats';
import MessageStats from './MessageStats/MessageStats';
import LoyaltyStats from './LoyaltyStats/LoyaltyStats';
import { DealStats, MarketingCampaignStats } from '../../routes/Dashboard';

const Dashboard = (props: {
  match: Match
}) => (
  <div>
    <Switch>
      <PrivateRoute
        roles={UserRoles.POINZ_ADMIN}
        path={`${props.match.url}/poinz`}
        component={PoinzStats}
        redirectTo={DASHBOARD_ACTIVITY}
      />
      <PrivateRoute path={`${props.match.url}/activity`} component={CollectsActivity} />
      <PrivateRoute path={`${props.match.url}/poinz-activity`} component={PoinzCollectsActivity} />
      <PrivateRoute
        path={`${props.match.url}/marketing-campaigns`}
        component={MarketingCampaignStats}
      />
      <PrivateRoute
        loyalty
        path={`${props.match.url}/loyalty`}
        component={LoyaltyStats}
        redirectTo={DASHBOARD_ACTIVITY}
      />
      <PrivateRoute deal path={`${props.match.url}/deals`} component={DealStats} />
      <PrivateRoute
        communication
        path={`${props.match.url}/communication`}
        component={MessageStats}
      />
    </Switch>
  </div>
);

export default withRouter(Dashboard);
