import { DateTime, Info } from 'luxon';

export const dayKeys = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const formatTime = (time: string): string =>
  DateTime.fromISO(`${DateTime.now().toFormat('YYYY-MM-DD')}T${time}`).toFormat('LT');

export const formatDate = (date: string): string => date && DateTime.fromISO(date).toFormat('L');

export const formatDateTime = (dateTime: string): string =>
  `${DateTime.fromISO(dateTime).toFormat('L')} ${DateTime.fromISO(dateTime).toFormat('LTS')}`;

// shift the index by one because weekdays starts with a sunday
export const dayName = (daykey: string): string =>
  Info.weekdays('long', { locale: DateTime.local().locale })[(dayKeys.indexOf(daykey) + 1) % 7];

export const dayNameShort = (daykey: string): string =>
  Info.weekdays('short', { locale: DateTime.local().locale })[(dayKeys.indexOf(daykey) + 1) % 7];
