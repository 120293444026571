import { EnumTranslationKeys, Language } from '@poinz/locale';
import { keyMirror } from '@poinz/utils';

export const GENDER = keyMirror({
  MALE: null,
  FEMALE: null
});

type Gender = keyof typeof GENDER;

export const GENDER_TRANSLATION: EnumTranslationKeys = {
  [GENDER.MALE]: 'general.male',
  [GENDER.FEMALE]: 'general.female'
};

export type UserRole = 'SUPER_ADMIN' | 'POINZ_ADMIN' | 'PARTNER_ADMIN' | 'USER';

export const INTERNAL_ROLE = keyMirror({
  TECH: null,
  SALES: null,
  MARKETING: null,
  BUSINESS_SUPPORT: null,
  MANAGEMENT: null,
  HR: null,
  ACCOUNTING: null,
  QM: null,
  AM: null
});

export const INTERNAL_ROLE_CONSULTANT = [
  INTERNAL_ROLE.AM,
  INTERNAL_ROLE.SALES,
  INTERNAL_ROLE.MANAGEMENT
];

export type InternalRole = keyof typeof INTERNAL_ROLE;

export const INTERNAL_ROLE_TRANSLATIONS: EnumTranslationKeys = {
  [INTERNAL_ROLE.AM]: 'adminUser.internalRoles.am',
  [INTERNAL_ROLE.SALES]: 'adminUser.internalRoles.sales',
  [INTERNAL_ROLE.TECH]: 'adminUser.internalRoles.tech',
  [INTERNAL_ROLE.MARKETING]: 'adminUser.internalRoles.marketing',
  [INTERNAL_ROLE.BUSINESS_SUPPORT]: 'adminUser.internalRoles.businessSupport',
  [INTERNAL_ROLE.MANAGEMENT]: 'adminUser.internalRoles.management',
  [INTERNAL_ROLE.HR]: 'adminUser.internalRoles.hr',
  [INTERNAL_ROLE.ACCOUNTING]: 'adminUser.internalRoles.accounting',
  [INTERNAL_ROLE.QM]: 'adminUser.internalRoles.qm'
};

export const USER_ROLES = keyMirror({
  POINZ_ADMIN: null,
  PARTNER_ADMIN: null,
  SUPER_ADMIN: null,
  USER: null
});

export const USER_ROLE_TRANSLATIONS = {
  POINZ_ADMIN: 'adminUser.roles.poinzAdmin',
  PARTNER_ADMIN: 'adminUser.roles.partnerAdmin',
  SUPER_ADMIN: 'adminUser.roles.superAdmin',
  USER: 'adminUser.roles.user'
};

export const POINZ_ADMIN_USER_ROLES = [USER_ROLES.POINZ_ADMIN, USER_ROLES.SUPER_ADMIN];

export const UserRoleHierarchy: { [key in UserRole]: number } = {
  SUPER_ADMIN: 0,
  POINZ_ADMIN: 1,
  PARTNER_ADMIN: 2,
  USER: 3
};

export class AdminUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: Gender;
  language: Language;
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
  belongsToIds: Array<number>;
  role: UserRole;
  internalRole: InternalRole;
  confirmedAccount: boolean;

  constructor(adminUser: Record<string, unknown>) {
    Object.assign(this, adminUser);
  }

  isPoinzAdmin() {
    return UserRoleHierarchy[this.role] <= UserRoleHierarchy.POINZ_ADMIN;
  }

  isSuperAdmin() {
    return UserRoleHierarchy[this.role] === UserRoleHierarchy.SUPER_ADMIN;
  }
}
