import * as React from 'react';
import { Typography, IconButton, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';

const useStyles = makeStyles(() => ({ text: { display: 'inline' } }));

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  totalElements: number;
  disabled?: boolean;
  items: number;
  handlePageChange: (toPage: number) => void;
};

const Pagination = ({
  currentPage,
  items,
  totalElements,
  totalPages,
  disabled,
  handlePageChange
}: PaginationProps) => {
  const { translate } = useLocale();
  const classes = useStyles();

  const getFirstElement = (): number => {
    return currentPage * items + 1;
  };

  const getLastElement = (): number => {
    const lastElement: number = items * (currentPage + 1);
    return lastElement > totalElements ? totalElements : lastElement;
  };

  const getText = (): string => {
    const separator: string = translate('general.of');
    return `${getFirstElement()} - ${getLastElement()} ${separator} ${totalElements}`;
  };

  const isPrevPageDisabled = (): boolean => {
    return disabled || currentPage <= 0;
  };

  const isNextPageDisabled = (): boolean => {
    return disabled || currentPage >= totalPages;
  };

  const toPage =
    (page: number): (() => void) =>
    () => {
      return handlePageChange(page);
    };

  return (
    <div>
      <Typography className={classes.text}>{getText()}</Typography>
      <IconButton
        onClick={toPage(0)}
        disabled={isPrevPageDisabled()}
        aria-label={translate('pagination.first')}
      >
        <Icon>first_page</Icon>
      </IconButton>
      <IconButton
        onClick={toPage(currentPage - 1)}
        disabled={isPrevPageDisabled()}
        aria-label={translate('pagination.prev')}
      >
        <Icon>keyboard_arrow_left</Icon>
      </IconButton>
      <IconButton
        onClick={toPage(currentPage + 1)}
        disabled={isNextPageDisabled()}
        aria-label={translate('pagination.next')}
      >
        <Icon>keyboard_arrow_right</Icon>
      </IconButton>
      <IconButton
        onClick={toPage(totalPages)}
        disabled={isNextPageDisabled()}
        aria-label={translate('pagination.last')}
      >
        <Icon>last_page</Icon>
      </IconButton>
    </div>
  );
};

export default Pagination;
