import { TicketForm } from './types/ticket';
import { PoinzApiClient } from 'api';

const ROOT = '/v2/mobile/tickets';
const createTicketForAuthenticatedUser =
  (apiClient: PoinzApiClient) =>
  async (ticketForm: TicketForm, deviceId: string, device: any): Promise<void> => {
    const { label, ...restTicketForm } = ticketForm;

    const response = await apiClient.post(`${ROOT}/authenticated`, {
      body: {
        ...restTicketForm,
        device,
        deviceId,
        labels: [label]
      }
    });
    return response.body;
  };

const createTicketForUnauthenticatedUser =
  (apiClient: PoinzApiClient) =>
  async (ticketForm: TicketForm, deviceId: string, device: any): Promise<void> => {
    const { label, ...restTicketForm } = ticketForm;

    const response = await apiClient.post(`${ROOT}/unauthenticated`, {
      body: {
        ...restTicketForm,
        device,
        deviceId,
        labels: [label]
      }
    });
    return response.body;
  };

export default (apiClient: PoinzApiClient) => ({
  createTicketForAuthenticatedUser: createTicketForAuthenticatedUser(apiClient),
  createTicketForUnauthenticatedUser: createTicketForUnauthenticatedUser(apiClient)
});
