import {callEndpoint} from './apiClient';

export const STATS_API = 'stats';
export const OVERVIEW = 'overview';
export const ACTIVITY_TIMELINE = 'activity-timeline';
const OFFERINGS = 'offerings';

async function getByOfferingId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${OFFERINGS}/${params.id}/${OVERVIEW}`,
    'GET',
    {}
  );
  return result.body;
}

async function getActivityTimeline(params: any): Promise<Array<any>> {
  const result = await callEndpoint(
    `/${STATS_API}/${OFFERINGS}/${params.id}/${ACTIVITY_TIMELINE}`,
    'GET',
    {
      queryParams: {
        resolution: params.resolution,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    }
  );
  return result.body;
}

export default {
  getByOfferingId,
  getActivityTimeline,
};
