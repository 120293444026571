import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { BusinessProfile } from './types';

const VERSION = '/v2';
const CONTRACT_COMPANIES = '/contract-companies';
const BUSINESS_PROFILES = '/business-profiles';
const apiRoot = `${ROOT}${VERSION}${CONTRACT_COMPANIES}/:contractCompanyId${BUSINESS_PROFILES}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (id: number, includeDeleted = false): Promise<BusinessProfile[]> => {
    const result = await apiClient.get(apiRoot, {
      pathParams: { contractCompanyId: id },
      queryParams: { includeDeleted }
    });
    return result.body.map((bp: any) => new BusinessProfile(bp));
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (params: {
    businessProfileId: number;
    contractCompanyId: number;
  }): Promise<BusinessProfile> => {
    const result = await apiClient.get(`${apiRoot}/:businessProfileId`, {
      pathParams: params
    });
    return new BusinessProfile(result.body);
  };

const getPublic =
  (apiClient: PoinzApiClient) =>
  async (params: { businessProfileId: number }): Promise<BusinessProfile> => {
    const result = await apiClient.get('/v2/public/business-profiles/:businessProfileId', {
      pathParams: params
    });
    return new BusinessProfile(result.body);
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (
    params: {
      businessProfileId: number;
      contractCompanyId: number;
    },
    data: FormData
  ): Promise<BusinessProfile> => {
    const result = await apiClient.put(`${apiRoot}/:businessProfileId`, {
      isMultiPart: true,
      body: data,
      pathParams: params
    });
    return new BusinessProfile(result.body);
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  getPublic: getPublic(apiClient),
  update: update(apiClient),
  getList: getList(apiClient)
});
