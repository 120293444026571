import * as React from 'react';
import { ImageListItemBar, FormHelperText } from '@mui/material';
import clsx from 'clsx';

import {
  FieldLabel,
  LoadingOverlay,
  TranslatedText,
  CroppableImage,
  ContainedImage,
  IconButton
} from 'App/components';
import FileUpload, { FileUploadView, FileUploadViewProps } from 'App/components/FileUpload';
import { withFieldAdapter, FieldRenderProps } from 'App/components/Field';

import { useStyles } from './CroppableImageField.styles';

type Props = {
  className?: string | null | undefined;
} & FieldRenderProps;

function CroppableImageField(props: Props) {
  const { field, label, className, required, tooltip, accept, maxSize, aspectRatio, meta } = props;
  const { error, touched } = meta;
  const { value, onChange } = field;
  const file = value?.file;
  const isNewFile = file != null;

  const classes = useStyles();

  const handleDrop = (files: File[]) => {
    const newFile = files && files[0];
    if (newFile) {
      field.onChange({ file: newFile });
    }
  };

  const renderActionIcon = React.useCallback(
    (fileUploadProps, startCropping) => {
      const buttons = [{ icon: 'image', onClick: fileUploadProps.getRootProps().onClick }];
      if (isNewFile) {
        buttons.push({ icon: 'crop', onClick: startCropping });
      }
      return (
        <div className={classes.actionButtons}>
          {buttons.map(b => (
            // @ts-expect-error - TS2786 - 'IconButton' cannot be used as a JSX component. | TS2322 - Type 'string' is not assignable to type 'IconType'.
            <IconButton key={b.icon} icon={b.icon} onClick={b.onClick} />
          ))}
        </div>
      );
    },
    [classes.actionButtons, isNewFile]
  );

  const renderPreview = React.useCallback(
    fileUploadProps =>
      ({ imageUrl, startCropping }) => (
        <div className={classes.field}>
          <ContainedImage
            // @ts-expect-error - TS2339 - Property 'imagePreview' does not exist on type 'ClassNameMap<"error" | "container" | "field" | "infoBar" | "actionButtons">'.
            className={classes.imagePreview}
            image={imageUrl}
            backgroundColor={value && value.mainColor}
          />
          <ImageListItemBar
            title={file?.name}
            actionIcon={renderActionIcon(fileUploadProps, startCropping)}
          />
          <LoadingOverlay loading={fileUploadProps.loading} />
        </div>
      ),
    // @ts-expect-error - TS2339 - Property 'imagePreview' does not exist on type 'ClassNameMap<"error" | "container" | "field" | "infoBar" | "actionButtons">'.
    [classes.field, classes.imagePreview, file, renderActionIcon, value]
  );

  const renderContent = (fileUploadProps: FileUploadViewProps) => {
    if (!value) {
      return (
        <div className={clsx({ [classes.error]: touched && error })}>
          <FileUploadView {...fileUploadProps} />
        </div>
      );
    }
    return (
      <div onDrop={fileUploadProps.getRootProps().onDrop}>
        <input {...fileUploadProps.getInputProps()} />
        <CroppableImage {...{ value, onChange, aspectRatio }}>
          {renderPreview(fileUploadProps)}
        </CroppableImage>
      </div>
    );
  };

  return (
    <div className={clsx(classes.container, className)}>
      <FieldLabel {...{ label, required, tooltip }} />
      <FileUpload onDrop={handleDrop} {...{ accept, maxSize }}>
        {renderContent}
      </FileUpload>
      {touched && error && (
        <FormHelperText {...{ error }}>
          <TranslatedText text={error} />
        </FormHelperText>
      )}
    </div>
  );
}

export default withFieldAdapter(CroppableImageField);
