import { useQuery, UseQueryResult } from 'react-query';
import { AdminUser, ContractCompanySearchCandidate, PartnerClient } from '../../../partnerClient';

const KEY = 'CONTRACT_COMPANY';

enum ENTITY {
  USER = 'USER'
}

const useSearchContractCompany =
  (apiClient: PartnerClient) =>
  (query: string): UseQueryResult<ContractCompanySearchCandidate[], any> => {
    return useQuery(
      [KEY, query],
      () => apiClient.backend.contractCompany.searchContractCompany(query),
      {
        enabled: false,
        refetchOnWindowFocus: false
      }
    );
  };

const useGetUserList =
  (apiClient: PartnerClient) =>
  (id: number): UseQueryResult<AdminUser[], any> => {
    const query = useQuery([KEY, id, ENTITY.USER], () =>
      apiClient.backend.contractCompany.getUsers(id)
    );

    return {
      ...query,
      data: query.data || []
    } as UseQueryResult<AdminUser[], any>;
  };

export default (apiClient: PartnerClient) => ({
  useSearchContractCompany: useSearchContractCompany(apiClient),
  useGetUserList: useGetUserList(apiClient)
});
