import {BUSINESS_PROFILES} from 'serviceNew/api/businessProfile';
import { STATS_API, OVERVIEW, ACTIVITY_TIMELINE } from 'serviceNew/api/businessProfileStats';
import { callEndpoint } from './apiClient';

const LOCATIONS = 'locations';

async function getByBpId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${LOCATIONS}`,
    'GET',
    {
      queryParams: {
        pageNumber: params.pageNumber
      }
    }
  );
  return result;
}

async function getByLocationId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${LOCATIONS}/
  ${params.locationId}/${OVERVIEW}`,
    'GET',
    {}
  );
  return result.body;
}

async function getActivityTimeline(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${LOCATIONS}/${params.locationId}/${ACTIVITY_TIMELINE}`,
    'GET',
    {
      queryParams: {
        resolution: params.resolution,
        startDate: params.startDate,
        endDate: params.endDate
      }
    }
  );
  return result.body;
}

export default {
  getByBpId,
  getByLocationId,
  getActivityTimeline
};
