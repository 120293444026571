import * as React from 'react';
import { useTheme } from '@mui/material';
import _ from 'lodash';
import {
  BarChart as RCBarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip as RCTooltip,
  CartesianGrid,
  Legend as RCLegend
} from 'recharts';
import { useLocale } from 'App/providers/LocaleProvider';

import { TranslationObject } from 'serviceNew/locale';
import Legend from './Legend';
import Tooltip from './Tooltip';

type Props<S, T, U> = {
  data: T[];
  height: number;
  dataSets: U[];
  // @ts-expect-error - TS2344 - Type 'U' does not satisfy the constraint 'string | number | symbol'.
  dataSetNames: Partial<Record<U, TranslationObject>>;
  xAxis: S;
  xAxisTickFormatter?: (arg1: string) => string;
  tooltipProps?: JSX.LibraryManagedAttributes<
    typeof RCTooltip,
    React.ComponentProps<typeof RCTooltip>
  >;
};

const COLORS = ['#FFC8A0', '#CCA0FF', '#006494', '#283593', '#00695c'];

const getPath = (x: number, y: number, width: number, height: number, radius: number) => `M${x},${
  y + height
}
          v-${height - radius}
          q0,-${radius} ${radius},-${radius}
          h${width - 2 * radius}
          q${radius},0 ${radius},${radius}
          v+${height - radius}
          Z`;

type BarProps<U> = {
  dataSets: U[];
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  value: [number, number];
  // @ts-expect-error - TS1337 - An index signature parameter type cannot be a literal type or generic type. Consider using a mapped object type instead.
  [key: U]: number;
};

function RoundedBar<U extends string>(props: BarProps<U>) {
  const { fill, x, y, width, height, value, dataSets } = props;
  const maxValue = value[1];
  const totalValue = Object.values(
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'U'.
    _.pickBy(props, (val, key) => dataSets.includes(key))
    // @ts-expect-error - TS2365 - Operator '+' cannot be applied to types 'string | number | [number, number] | U[]' and 'string | number | [number, number] | U[]'.
  ).reduce((acc, cur) => acc + cur, 0);
  const rounded = maxValue === totalValue;
  const radius = height > 0 && rounded ? Math.min((width / 2) * 0.4, 8) : 0;

  return <path d={getPath(x, y, width, height, radius)} stroke="none" fill={fill} />;
}

function StackedBarChart<S extends string, T extends Partial<Record<S, string>>, U extends keyof T>(
  props: Props<S, T, U>
) {
  const { height, xAxis, dataSets, data, dataSetNames, xAxisTickFormatter, tooltipProps } = props;

  const [unselected, setUnselected] = React.useState({});
  const theme = useTheme();
  const { translate } = useLocale();

  function handleLegendClick(dataKey: any) {
    setUnselected(curUnselected => ({ ...curUnselected, [dataKey]: !curUnselected[dataKey] }));
  }

  function renderBar(dataSet: U, idx: number) {
    // @ts-expect-error - TS2536 - Type 'U' cannot be used to index type '{}'.
    const dataKey = unselected[dataSet] ? `${dataSet} ` : dataSet;
    // @ts-expect-error - TS2536 - Type 'U' cannot be used to index type '{}'.
    const fillColor = unselected[dataSet]
      ? // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
        theme.mainComponents.chart.axis.line.stroke
      : COLORS[idx % COLORS.length];
    const name = dataSetNames[dataSet];
    return (
      <Bar
        strokeWidth={2}
        stackId="stack"
        name={name && translate(name.key, name.context)}
        // @ts-expect-error - TS2769 - No overload matches this call.
        dataKey={dataKey}
        fill={fillColor}
        // @ts-expect-error - TS2740 - Type '{ dataSets: U[]; }' is missing the following properties from type 'BarProps<string>': fill, x, y, width, and 2 more.
        shape={<RoundedBar {...{ dataSets }} />}
        legendType="circle"
      />
    );
  }

  return (
    <ResponsiveContainer
      /* Setting width to 100% does not work */
      width="99%"
      {...{ height }}
    >
      <RCBarChart {...{ data }}>
        <XAxis
          dataKey={xAxis}
          tickFormatter={xAxisTickFormatter || undefined}
          // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
          tick={theme.mainComponents.chart.axis.label}
          // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
          tickLine={theme.mainComponents.chart.axis.line}
          // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
          axisLine={theme.mainComponents.chart.axis.line}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
          tick={theme.mainComponents.chart.axis.label}
        />
        {/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'Props': label, payload */}
        <RCTooltip content={<Tooltip />} {...(tooltipProps || {})} />
        <CartesianGrid vertical={false} />
        <RCLegend
          onClick={handleLegendClick}
          // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
          wrapperStyle={theme.mainComponents.chart.legend}
          // @ts-expect-error - TS2741 - Property 'payload' is missing in type '{}' but required in type 'Props'.
          content={<Legend />}
        />
        {dataSets.map(renderBar)}
      </RCBarChart>
    </ResponsiveContainer>
  );
}

export default StackedBarChart;
