import * as React from 'react';
import clsx from 'clsx';
import * as SimpleMarkdown from 'simple-markdown';
import { FieldProps } from 'formik';
import SimpleMDE from 'react-simplemde-editor';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { Text } from 'App/components';
import { MyTheme } from 'theme/muiTheme';
import FormHelperText from '@mui/material/FormHelperText';
import { withFieldAdapter } from '../../Field';
import FieldLabel from '../../FieldLabel';

import './markdowninput.css';

const rules = {
  ...SimpleMarkdown.defaultRules,
  paragraph: {
    ...SimpleMarkdown.defaultRules.paragraph,
    // add extra line break here so its behaviour matches our mobile app
    html: (node, recurseOutput) => `${recurseOutput(node.content)}<br><br>`
  }
} as const;

const parser = SimpleMarkdown.parserFor(rules);
// @ts-expect-error - TS2339 - Property 'reactFor' does not exist on type 'typeof import("/Users/mare/Desktop/projects_new/klunga/node_modules/simple-markdown/simple-markdown")'. | TS2339 - Property 'ruleOutput' does not exist on type 'typeof import("/Users/mare/Desktop/projects_new/klunga/node_modules/simple-markdown/simple-markdown")'.
const htmlOutput = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'html'));

export const customMarkdownParser = (source: string): Array<string> => {
  // Many rules require content to end in \n\n to be interpreted
  // as a block.
  const blockSource = `${source}\n\n`;
  const parseTree = parser(blockSource, { inline: false });
  const outputResult = htmlOutput(parseTree);

  return outputResult;
};

const styles = createStyles((theme: MyTheme) => ({
  root: {
    flex: 1
  },
  container: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'Theme'.
    border: theme.border,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  previewLabel: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    padding: theme.extra.spacing.tiny
  },
  editorWrapper: {
    flex: 3,
    display: 'flex'
  },
  previewWrapper: {
    flex: 2,
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'Theme'.
    borderLeft: theme.border,
    alignSelf: 'stretch'
  },
  editor: {
    width: '100%'
  },
  innerPreview: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  error: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    paddingLeft: theme.extra.spacing.tiny
  },
  errorBorder: {
    borderColor: theme.palette.error.main
  }
}));

type Props = FieldProps & LocaleProps & WithStyles<typeof styles>;

function MarkdownInputField(props: Props) {
  // @ts-expect-error - TS2339 - Property 'label' does not exist on type 'FieldProps<any> & LocaleProviderProps & { classes: ClassNameMap<never>; }'. | TS2339 - Property 'className' does not exist on type 'FieldProps<any> & LocaleProviderProps & { classes: ClassNameMap<never>; }'. | TS2339 - Property 'meta' does not exist on type 'FieldProps<any> & LocaleProviderProps & { classes: ClassNameMap<never>; }'.
  const { field, form, translate, label, classes, className, meta } = props;
  const { name, value, onChange } = field;

  const { error, touched } = meta;

  const handleBlur = () => {
    if (!touched) {
      form.setFieldTouched(name, true, true);
    }
  };

  return (
    // @ts-expect-error - TS2339 - Property 'root' does not exist on type 'ClassNameMap<never>'.
    <div className={clsx(classes.root, className)}>
      <FieldLabel {...{ label }} />
      {/* @ts-expect-error - TS2339 - Property 'container' does not exist on type 'ClassNameMap<never>'. | TS2339 - Property 'errorBorder' does not exist on type 'ClassNameMap<never>'. */}
      <div className={`${classes.container} ${error ? classes.errorBorder : ''}`}>
        {/* @ts-expect-error - TS2339 - Property 'editorWrapper' does not exist on type 'ClassNameMap<never>'. */}
        <div className={classes.editorWrapper}>
          <SimpleMDE
            key={name}
            // @ts-expect-error - TS2339 - Property 'editor' does not exist on type 'ClassNameMap<never>'.
            className={classes.editor}
            events={{
              blur: handleBlur
            }}
            options={{
              hideIcons: [
                'image',
                'table',
                'fullscreen',
                'side-by-side',
                'preview',
                'heading',
                'quote',
                'guide'
              ],
              placeholder: label
                ? translate(label.key, label.context)
                : translate('form.general.placeholder'),
              spellChecker: false,
              status: false
            }}
            {...{ onChange, value, name }}
          />
        </div>

        {/* @ts-expect-error - TS2339 - Property 'previewWrapper' does not exist on type 'ClassNameMap<never>'. */}
        <div className={classes.previewWrapper}>
          <div className="editor-toolbar">
            <Text>form.markdown.preview</Text>
          </div>
          <div className="CodeMirror">
            {/* eslint-disable-next-line react/no-danger          */}
            {/* @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type 'string | TrustedHTML'. */}
            {value && <div dangerouslySetInnerHTML={{ __html: customMarkdownParser(value) }} />}
            {!value && <Text color="textSecondary">form.markdown.nothing.to.preview</Text>}
          </div>
        </div>
      </div>
      {error && (
        // @ts-expect-error - TS2339 - Property 'error' does not exist on type 'ClassNameMap<never>'.
        <FormHelperText className={clsx(classes.error, className)} error>
          <Text color="error" variant="caption" context={error.context}>
            {error.key}
          </Text>
        </FormHelperText>
      )}
    </div>
  );
}

export default withStyles(styles)(withFieldAdapter(withLocale(MarkdownInputField)));
