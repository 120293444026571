export const apiRoots = {
  test: 'https://api-test.poinz.ch',
  development: 'https://api-test.poinz.ch',
  staging: 'https://api-staging.poinz.ch',
  prod: 'https://api.poinz.ch'
};

export const apiRootsInternal = {
  test: 'https://internal-api-test.poinz.ch',
  development: 'https://internal-api-test.poinz.ch',
  staging: 'https://internal-api-staging.poinz.ch',
  prod: 'https://internal-api.poinz.ch'
};
