import moment from 'moment';
import { dayKeys } from '../../constants/general';

export const formatTime = (time: string) =>
  moment(`${moment(new Date()).format('YYYY-MM-DD')}T${time}`).format('LT');
export const formatDate = (date: string) => date && moment(date).format('L');
export const formatDateTime = (dateTime: string) =>
  `${moment(dateTime).format('L')} ${moment(dateTime).format('LTS')}`;
// shift the index by one because weekdays starts with a sunday
// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY"'.
export const dayName = (daykey: string) => moment.weekdays()[(dayKeys.indexOf(daykey) + 1) % 7];
export const dayNameShort = (daykey: string) =>
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY"'.
  moment.weekdaysShort()[(dayKeys.indexOf(daykey) + 1) % 7];

export { moment };
