import * as React from 'react';
import { Autocomplete as MuiAutocomplete, InputAdornment, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import { TranslationObject } from 'serviceNew/locale';
import SearchIcon from '@mui/icons-material/Search';
import { FILTER_TYPE, FilterBaseType } from './FilterProps';

const Autocomplete = withStyles(theme => ({
  inputRoot: {
    height: theme.spacing(4),
    borderRadius: theme.spacing(1),
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 0
    }
  },
  input: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textTransform: 'none',
    padding: theme.spacing(0, 0, 0, 0)
  },
  paper: {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180
  },
  endAdornment: {
    display: 'none'
  },
  root: {
    width: 250,
    padding: theme.spacing(0, 0, 0, 0)
  }
}))(MuiAutocomplete);

type Option = {
  value: any;
  label: TranslationObject;
};

export type FilterAutocompleteType<S> = {
  filterType: typeof FILTER_TYPE.AUTOCOMPLETE;
  options: Option[];
  onInput: (arg1: string) => unknown;
} & FilterBaseType<S>;

type Props<V, S> = {
  value: V;
  filter: FilterAutocompleteType<S>;
  filterKey: string;
  className?: string;
  containerRef: React.ElementRef<any>;
};

function AutocompleteFilter<V, S>({ filter, className }: Props<V, S>) {
  const { label, onChange: onChangeProp, options, onInput } = filter;
  const { translate } = useLocale();
  const [inputValue, setInputValue] = React.useState<string>('');

  function getOptionLabel(option: any) {
    return translate(option.label.key, option.label.context);
  }
  function onInputChange(event: any, val: any) {
    setInputValue(val);
    onInput(val);
  }

  function onChange(event: any, option: any) {
    onChangeProp(option);
    setInputValue('');
  }

  return (
    <Autocomplete
      options={options}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      disableClearable
      value={null}
      noOptionsText={translate('general.noOptions')}
      clearOnBlur
      blurOnSelect
      classes={{
        root: className
      }}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          variant="outlined"
          placeholder={translate(label.key, label.context)}
          {...params}
          InputProps={{
            ...InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          // @ts-expect-error - TS2322 - Type '{ InputProps: { startAdornment: Element; ref: Ref<any>; className: string; endAdornment: ReactNode; }; shrink: boolean; id: string; disabled: boolean; fullWidth: boolean; ... 4 more ...; placeholder: any; }' is not assignable to type 'IntrinsicAttributes & { variant?: "outlined" | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">'.
          shrink={false}
        />
      )}
    />
  );
}

export default AutocompleteFilter;
