import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'ContextRouter'. | TS2305 - Module '"react-router"' has no exported member 'LocationShape'.
import { withRouter, ContextRouter, LocationShape } from 'react-router';

import { Button as MuiButton, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';
import { useLocale } from 'App/providers/LocaleProvider';
import clsx from 'clsx';
import Icon, { IconType } from './Icon';

const useStyles = makeStyles(theme => ({
  icon: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  },
  root: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    borderRadius: theme.spacing(1),
    boxShadow: 'none'
  }
}));

export type ButtonVariant = 'contained' | 'text' | 'outlined';

type ButtonProps = {
  className?: string | null | undefined;
  color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary';
  onClick?: (arg1: React.SyntheticEvent<any>) => unknown;
  to?: 'string' | LocationShape;
  label?: TranslationObject;
  variant: ButtonVariant;
  loading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button';
  icon: IconType | null | undefined;
  autoFocus?: boolean;
  onMouseDown?: (e: React.SyntheticEvent<any>) => void;
  size?: 'small';
  style?: any;
  href?: string;
  target?: string;
  children: React.ReactNode;
} & ContextRouter;

function Button(props: ButtonProps) {
  const {
    onClick,
    to,
    history,
    icon,
    label,
    loading,
    disabled,
    // eslint-disable-next-line no-unused-vars
    children,
    // eslint-disable-next-line no-unused-vars
    staticContext,
    className,
    ...restProps
  } = props;

  const { translate } = useLocale();
  const classes = useStyles();

  function getOnClick() {
    if (to == null) {
      return onClick;
    }
    if (onClick == null) {
      return () => history.push(to);
    }
    return (e: React.SyntheticEvent<any>) => {
      onClick(e);
      history.push(to);
    };
  }

  function renderIcon() {
    if (icon != null) {
      return <Icon className={classes.icon} {...{ icon }} />;
    }
    return null;
  }

  return (
    <MuiButton
      disabled={disabled || loading}
      onClick={getOnClick()}
      className={clsx(classes.root, className)}
      {...restProps}
    >
      {children}
      {renderIcon()}
      {loading ? <CircularProgress size={20} /> : !!label && translate(label.key, label.context)}
    </MuiButton>
  );
}

Button.defaultProps = {
  variant: 'text',
  loading: false,
  disabled: false,
  type: 'button',
  icon: undefined
};

export default withRouter(Button);
