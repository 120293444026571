import { TranslationKey } from '@poinz/locale';
export class ApiError extends Error {
  message: string;
  errors?: string[];
  status?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: any, status?: number) {
    const message = error.message || error;
    super(message);
    this.message = message;
    this.errors = error.errors;
    this.status = status || error.status;
  }

  getErrors() {
    if (!this.errors) {
      return undefined;
    }
    return this.errors.join('\n');
  }

  toString() {
    return `${this.message}\n${this.getErrors() || ''}`;
  }
}

type Params = Record<string, string>;

export class ProblemApiError extends Error {
  status: number;
  title: string;
  detail?: string;
  params?: Params;
  key?: TranslationKey;

  constructor(error: any) {
    super(error.detail || error.title);
    Object.assign(this, error);
  }
}
