import * as React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.common.black,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[5],
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius * 2,
    maxWidth: undefined
  }
}));

type Props = {
  children: React.ReactNode;
  shouldShow?: boolean;
  renderContent: () => React.ReactElement;
};

function ContentTooltip({ children, shouldShow = true, renderContent }: Props) {
  const classes = useStyles();

  return !shouldShow ? (
    children
  ) : (
    <Tooltip classes={{ tooltip: classes.container }} title={renderContent()}>
      {/* @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, any>'. */}
      {children}
    </Tooltip>
  );
}

export default ContentTooltip;
