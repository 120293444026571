import * as React from 'react';
import { FormHelperText } from '@mui/material';

import { TranslationObject } from 'serviceNew/locale';
import TranslatedText from './TranslatedText';

type Props = {
  touched: boolean,
  error: TranslationObject | null | undefined,
  variant?: 'filled' | 'outlined' | 'standard'
};

function FormError(props: Props) {
  const { error, touched, variant } = props;
  if (touched && error) {
    return (
      <FormHelperText variant={variant} error>
        <TranslatedText text={error} />
      </FormHelperText>
    );
  }
  return null;
}

FormError.defaultProps = {
  variant: 'outlined',
};

// @ts-expect-error - TS2345 - Argument of type 'typeof FormError' is not assignable to parameter of type 'FunctionComponent<Props>'.
export default React.memo<Props>(FormError);
