import { PoinzApiClient } from 'api';
import { apiRoots } from 'config';
import { ClientParams } from 'types';
import apiKeys from './apiKey';
import backend from './backend';
import datatransApi from './datatrans';
import giftCard from './giftCard';
import loyaltyApi from './loyalty';
import referral from './referral';
import userApi from './user';
import adtraction from './adtraction';
import shoppingCart from './shoppingCart';
import socket from './socket';
import search from './search';
import explore from './explore';
import worldLine from './worldline';

export * from './apiKey/types';
export * from './backend/types';
export * from './giftCard/types';
export * from './loyalty/types';
export * from './user/types';
export * from './referral/types';
export * from './datatrans/types';
export * from './adtraction/types';
export * from './shoppingCart/types';
export * from './search/types';
export * from './explore/types';
export * from './worldline/types';

export const createPartnerClient = (config: ClientParams) => {
  const { environment, ...restParams } = config;
  const client = new PoinzApiClient({
    apiRoot: apiRoots[environment],
    ...restParams
  });

  return {
    getUrl: client.getUrl,
    changeLanguage: client.changeLanguage.bind(client),
    setAuthenthicatedHeaders: client.setAuthenthicatedHeaders,
    loyalty: loyaltyApi(client),
    user: userApi(client),
    datatrans: datatransApi(client),
    giftCard: giftCard(client),
    backend: backend(client),
    apiKey: apiKeys(client),
    referral: referral(client),
    adtraction: adtraction(client),
    shoppingCart: shoppingCart(client),
    socket: socket(client),
    search: search(client),
    explore: explore(client),
    worldLine: worldLine(client)
  };
};

type ReturnType<T> = T extends (...args: any[]) => infer R ? R : any;

export type PartnerClient = ReturnType<typeof createPartnerClient>;
