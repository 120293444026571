import * as React from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { FormControl, Select, FormHelperText, MenuItem, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useLocale } from 'App/providers/LocaleProvider';
import { useLocation } from 'App/providers/LocationProvider';

import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { ADMINISTRATION } from 'App/routing';
import { Offering } from 'serviceNew/model/offering';
import offeringApi from 'serviceNew/api/offering';
import { getPagination, initialPagination } from 'serviceNew/util/helperFunctions';
import locationStatsApi from 'serviceNew/api/locationStats';

import { TranslatedText } from 'App/components';
import { DAYLIST } from '../../../../../constants/general';
import LocationStatsTable from './LocationStatsTable';
import ActivityTimeLineInput, {
  ActivityTimeLineType
} from '../../../../components/ActivityTimelineInput';
import { DashboardCardHeader } from '../../../../components/DashboardCard';

const useStyles = makeStyles(() => ({
  selectFilter: {
    margin: 24,
    minWidth: 200,
    textAlign: 'left'
  },
  selectFilterRight: {
    margin: 24,
    marginRight: 48,
    minWidth: 200,
    textAlign: 'left'
  }
}));

type Props = {
  isRequesting?: boolean;
};

type PaginationType = {
  currentPage: number;
  totalElements: number;
  totalPages: number;
};

const today = moment();
const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
const startRange = today.subtract(3, 'month').format('YYYY-MM-DD');

function Locations(props: Props) {
  const { isRequesting } = props;

  const { businessProfileId, selectBusinessProfile, businessProfiles } = useBusinessProfile();
  const classes = useStyles();
  const contractCompany = useContractCompany();
  const { userRole, isSuperAdmin } = useAdminUser();
  // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language } = useLocale();
  const { locations } = useLocation();

  const [selectedLocation, setSelectedLocation] = React.useState<number | null | undefined>(null);
  const [days, setDays] = React.useState<string>('d30');
  const [offeringList, setOfferingList] = React.useState<Offering[]>([]);
  const [locationStatsOverview, setLocationStatsOverview] = React.useState<any[]>([]);
  const [locationStatsDetailOverview, setLocationDetailsOverview] = React.useState<any[]>([]);
  const [locationStatsActivityTimeline, setLocationStatsActivityTimeline] = React.useState<any[]>(
    []
  );
  const [pagination, setPagination] = React.useState<PaginationType>(initialPagination);
  const [activityTimeLineParams, setActivityTimeLineParams] = React.useState<ActivityTimeLineType>({
    resolution: 'WEEKLY',
    endDate: yesterday,
    startDate: startRange
  });

  async function getLocationOverview(params: any) {
    try {
      const { body, headers } = await locationStatsApi.getByBpId(params);
      setPagination(getPagination(headers));
      setLocationStatsOverview(body);
    } catch (e: any) {
      //
    }
  }

  React.useEffect(() => {
    (async function loadData() {
      if (businessProfileId) {
        try {
          await getLocationOverview({ id: businessProfileId });
          const retrievedOfferings = await offeringApi.getOfferings(businessProfileId);
          setOfferingList(retrievedOfferings);
        } catch (e: any) {
          //
        }
      }
    })();
  }, [businessProfileId]);

  async function getLocationDetail(params: any) {
    try {
      const retrievedLocationStatsDetailOverview = await locationStatsApi.getByLocationId(params);
      setLocationDetailsOverview(retrievedLocationStatsDetailOverview);
    } catch (e: any) {
      //
    }
  }

  async function getActivityTimeline(params: any) {
    try {
      const retrievedLocationStatsActivityTimeline =
        await locationStatsApi.getActivityTimeline(params);
      setLocationStatsActivityTimeline(retrievedLocationStatsActivityTimeline.reverse());
    } catch (e: any) {
      //
    }
  }

  React.useEffect(() => {
    if (selectedLocation && businessProfileId) {
      getActivityTimeline({
        id: businessProfileId,
        locationId: selectedLocation,
        ...activityTimeLineParams
      });
    }
  }, [selectedLocation, businessProfileId, activityTimeLineParams]);

  function handleChangeDate(name: any, value: any) {
    setActivityTimeLineParams(curVal => ({ ...curVal, [name]: value }));
  }

  async function handleSelectLocation(id: any) {
    if (selectedLocation !== id && businessProfileId) {
      await getLocationDetail({ id: businessProfileId, locationId: id });
    }
    setSelectedLocation(curVal => (curVal === id ? null : id));
  }

  async function handleBpChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedLocation(null);
    selectBusinessProfile(parseInt(e.target.value, 10));
    await getLocationOverview({ id: parseInt(e.target.value, 10) });
  }

  async function handlePageChange(toPage: any) {
    setSelectedLocation(null);
    if (businessProfileId) {
      await getLocationOverview({ id: businessProfileId, pageNumber: toPage });
    }
  }

  // TODO: put this in a separate component
  if (!contractCompany) {
    return (
      <Redirect
        to={{
          pathname: `${ADMINISTRATION}/contract-company/search`
        }}
      />
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DashboardCardHeader>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <FormControl variant="standard" className={classes.selectFilter}>
                  <Select
                    variant="standard"
                    displayEmpty
                    disabled={businessProfiles.length === 1}
                    value={businessProfileId || ''}
                    // @ts-expect-error - TS2322 - Type '(e: ChangeEvent<HTMLInputElement>) => Promise<void>' is not assignable to type '(event: SelectChangeEvent<number>, child: ReactNode) => void'.
                    onChange={handleBpChange}
                  >
                    <MenuItem key="empty" disabled value="">
                      <TranslatedText text={{ key: 'general.select' }} />
                    </MenuItem>
                    {businessProfiles.map(e => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <TranslatedText text={{ key: 'businessProfile' }} />
                  </FormHelperText>
                </FormControl>
              </Grid>
              {businessProfileId && (
                <>
                  <Grid item>
                    <FormControl variant="standard" className={classes.selectFilterRight}>
                      <Select
                        variant="standard"
                        value={days}
                        onChange={event => setDays(event.target.value)}
                      >
                        {Object.keys(DAYLIST).map(e => (
                          <MenuItem key={DAYLIST[e].label} value={DAYLIST[e].value}>
                            <TranslatedText text={{ key: DAYLIST[e].label }} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        <TranslatedText text={{ key: 'general.timePeriod' }} />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <ActivityTimeLineInput
                      handleChangeDate={handleChangeDate}
                      {...activityTimeLineParams}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DashboardCardHeader>
        </Grid>
        <Grid item sm={12}>
          {businessProfileId && (
            <LocationStatsTable
              userRole={userRole}
              isRequesting={isRequesting}
              locationStatsOverview={locationStatsOverview}
              locationStatsDetailOverview={locationStatsDetailOverview}
              locationStatsActivityTimeline={locationStatsActivityTimeline}
              resolution={activityTimeLineParams.resolution}
              locationList={locations}
              offeringList={offeringList}
              selectedLocation={selectedLocation}
              handleSelectLocation={handleSelectLocation}
              days={days}
              lang={language}
              handlePageChange={handlePageChange}
              superAdmin={isSuperAdmin}
              {...pagination}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Locations;
