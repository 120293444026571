import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Grid } from '@mui/material';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { theme } from '../../../theme/muiTheme';
import { CHART_STYLING } from '../../../constants/general';
import ChartTooltip from '../charts/ChartTooltip';
import { ChartDataType } from '../charts/BarChart';

type Props = {
  chartData: Array<ChartDataType>;
  legend: boolean;
  tooltip: boolean;
  height?: number;
  innerRadius?: string;
  title: string;
  name: string;
} & LocaleProps;

export const PieChartUnconnected = ({
  chartData,
  legend,
  tooltip,
  height = 300,
  innerRadius = '50%',
  title,
  name,
  formatMessage
}: Props) => {
  let total = 0;
  const chartDataLength = chartData ? chartData.length : 0;
  if (chartDataLength > 0) {
    for (let i = 0; i < chartDataLength; i += 1) {
      // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
      total += parseFloat(chartData[i].value);
    }
  }

  const colorOfIndex = (i: number) => {
    const idx =
      i -
      // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
      theme.mainComponents.chart.color.length *
        // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
        Math.floor(i / theme.mainComponents.chart.color.length);
    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
    return theme.mainComponents.chart.color[idx];
  };

  return (
    <>
      <Grid container spacing={2}>
        {title && (
          <Grid item xs={12} sm={12}>
            <Typography noWrap align="left" variant="subtitle1">
              {formatMessage({ id: title })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {(chartData && chartData.length) > 0 ? (
            <ResponsiveContainer width="100%" height={height}>
              <PieChart>
                <Pie dataKey="value" data={chartData} innerRadius={innerRadius}>
                  {name &&
                    chartData.map((entry, index) => (
                      <Cell
                        key={entry.name}
                        fill={
                          CHART_STYLING[name][entry.name]
                            ? CHART_STYLING[name][entry.name].color
                            : colorOfIndex(index)
                        }
                      />
                    ))}
                </Pie>
                {tooltip && (
                  <Tooltip
                    content={
                      <ChartTooltip
                        total={total}
                        name={name}
                        formatMessage={formatMessage}
                        payload={[]}
                      />
                    }
                  />
                )}
                {legend && name && (
                  <Legend
                    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                    wrapperStyle={theme.mainComponents.chart.legend}
                    payload={chartData.map((e, index) =>
                      CHART_STYLING[name][e.name]
                        ? {
                            id: e.name,
                            value: formatMessage({ id: CHART_STYLING[name][e.name].label }),
                            color: CHART_STYLING[name][e.name].color,
                            type: 'circle'
                          }
                        : {
                            id: e.name,
                            value: e.name,
                            color: colorOfIndex(index),
                            type: 'circle'
                          }
                    )}
                  />
                )}
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Typography align="left">{formatMessage({ id: 'stats.noDataAvailable' })}</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withLocale(PieChartUnconnected);
