import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import { Text } from 'App/components';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'DefaultTheme'.
    border: theme.border,
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius
  },
  contentPadding: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2, 2)
  },
  title: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  }
}));

type Props = {
  title: TranslationObject;
  children: React.ReactNode;
  withPadding?: boolean;
};

function DashboardSection(props: Props) {
  const { title, children, withPadding } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text className={classes.title} variant="h5" context={title.context}>
        {title.key}
      </Text>
      <div className={clsx(classes.content, withPadding && classes.contentPadding)}>{children}</div>
    </div>
  );
}

DashboardSection.defaultProps = {
  withPadding: false
};

export default DashboardSection;
