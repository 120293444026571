import React from 'react';
import { FormControl, Select, FormHelperText, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TranslatedText } from 'App/components';
import { BusinessProfile } from 'serviceNew/model/businessProfile';

const styles = () => ({
  selection: {
    minWidth: 200,
    textAlign: 'left',
  },
});

type Props = {
  selectedBusinessProfileId: number,
  handleBpChange: (arg1: React.ChangeEvent<HTMLInputElement>) => void,
  businessProfilesList: Array<BusinessProfile>,
  classes: any
};

// @ts-expect-error - TS2345 - Argument of type '() => { selection: { minWidth: number; textAlign: string; }; }' is not assignable to parameter of type 'Styles<any, any, string>'.
export const BusinessProfileSelection = withStyles(styles, { withTheme: true })(
  ({
    selectedBusinessProfileId,
    handleBpChange,
    businessProfilesList,
    classes,
  }: Props) => (
    <>
      <FormControl variant="standard" className={classes.selection}>
        <Select
          variant="standard"
          displayEmpty
          disabled={businessProfilesList.length === 1}
          value={selectedBusinessProfileId || ''}
// @ts-expect-error - TS2322 - Type '(arg1: ChangeEvent<HTMLInputElement>) => void' is not assignable to type '(event: SelectChangeEvent<number>, child: ReactNode) => void'.
          onChange={handleBpChange}>
          <MenuItem key="empty" disabled value="">
            <TranslatedText text={{ key: 'general.select' }} />
          </MenuItem>
          {businessProfilesList.map(e => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          <TranslatedText text={{ key: 'businessProfile' }} />
        </FormHelperText>
      </FormControl>
    </>
  )
);

export default BusinessProfileSelection;
