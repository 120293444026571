import * as React from 'react';
import { FieldArray, ArrayHelpers } from 'formik';

import { TranslationObject } from 'serviceNew/locale';

import { Field, FormError } from 'App/components';

import ItemList from './ItemList';

type Props<T> = {
  name: string;
  FormComponent: React.ComponentType<{
    values: T;
  }>;
  SummaryComponent: React.ComponentType<{
    values: T;
  }>;
  RightInfoComponent: React.ComponentType<{
    values: T;
  }>;
  maxItems: number | null | undefined;
  addLabel: TranslationObject;
  createItem: () => any;
  className?: string;
  validate?: () => TranslationObject | null | undefined;
  isDeletable?: boolean;
  isMultiLanguage?: boolean;
  clearOnUnmount?: boolean;
};

function FieldList<
  T extends {
    id: number;
  }
>(props: Props<T>) {
  const {
    name,
    createItem,
    FormComponent,
    SummaryComponent,
    RightInfoComponent,
    maxItems,
    addLabel,
    className,
    validate,
    isDeletable,
    isMultiLanguage,
    clearOnUnmount
  } = props;

  const renderFieldList = React.useCallback(
    (arrayHelpers: ArrayHelpers) => (
      // @ts-expect-error - TS2322 - Type '{ name: string; createItem: () => any; FormComponent: ComponentType<{ values: T; }>; SummaryComponent: ComponentType<{ values: T; }>; arrayHelpers: ArrayHelpers; ... 4 more ...; isMultiLanguage: boolean | undefined; }' is not assignable to type 'Props<any>'.
      <ItemList
        {...{
          name,
          createItem,
          FormComponent,
          SummaryComponent,
          arrayHelpers,
          RightInfoComponent,
          maxItems,
          addLabel,
          isDeletable,
          isMultiLanguage
        }}
      />
    ),
    [
      FormComponent,
      RightInfoComponent,
      SummaryComponent,
      addLabel,
      createItem,
      isDeletable,
      isMultiLanguage,
      maxItems,
      name
    ]
  );

  return (
    <div {...{ className }}>
      <FieldArray {...{ name }}>{renderFieldList}</FieldArray>
      <Field
        {...{ name, clearOnUnmount }}
        validate={validate as any}
        initialValue={[]}
        component={({ meta }: any) => <FormError error={meta.error} touched={meta.touched} />}
      />
    </div>
  );
}

export default FieldList;
