import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { useLocale } from 'App/providers/LocaleProvider';
import { IdType } from '@poinz/api';
import { TranslationObject } from 'serviceNew/locale';
import { selectMultiLanguage } from 'serviceNew/locale/strings';

import AutoCompletion from './AutoCompletion';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
});

export interface FilterItem {
  id: IdType;
  name: string;
}

type Props<F> = {
  items?: any[];
  itemsList?: Array<any[] | null | undefined>;
  filterItems: F[];
  searchLabel: TranslationObject;
  // Type is (item, id) => boolean or an array of that
  isValid?: (arg1: any, arg2: IdType) => boolean;
  isValidList?: Array<(arg1: any, arg2: IdType) => boolean>;
  children: (filteredList: any[]) => React.ReactElement;
};

function FilteredList<F extends FilterItem>(props: Props<F>) {
  const { items, filterItems, searchLabel, isValid, children, itemsList, isValidList } = props;

  // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language } = useLocale();

  const [filterItem, setFilterItem] = React.useState<any>(undefined);
  const [filteredList, setFilteredList] = React.useState<any[]>(items || itemsList || []);

  const translatedFilterItems = React.useMemo(
    () =>
      filterItems.map(f => ({
        id: f.id,
        name: typeof f.name === 'string' ? f.name : selectMultiLanguage(f.name, language)
      })),
    [filterItems, language]
  );

  const classes = useStyles();

  React.useEffect(() => {
    let newList = items || itemsList || [];
    if (filterItem != null) {
      if (itemsList != null && isValidList != null) {
        newList = itemsList.map(
          (list, idx) => list && list.filter(i => isValidList[idx](i, filterItem))
        );
      } else if (items != null && isValid != null) {
        newList = items.filter(i => isValid(i, filterItem));
      }
    }
    setFilteredList(newList);
  }, [filterItem, isValid, isValidList, items, itemsList]);

  const shouldShowFilter = items && filterItems && items.length > 1 && filterItems.length > 1;

  return (
    <div className={classes.container}>
      {shouldShowFilter && (
        <AutoCompletion
          suggestions={translatedFilterItems}
          label={searchLabel}
          onSelect={setFilterItem}
          width={400}
          withLeadingIcon="search"
          isLocal
          resetCondition={s => s === '' || !translatedFilterItems.some(fi => fi.name === s)}
        />
      )}
      {children(filteredList)}
    </div>
  );
}

export default FilteredList;
