import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { AdtractionClaimDto } from './types';

const CLAIMS = 'claims';
const apiRoot = `${ROOT}/${CLAIMS}`;

const createClaim =
  (apiClient: PoinzApiClient) =>
  async (body: AdtractionClaimDto): Promise<void> => {
    await apiClient.post(apiRoot, { body });
  };

export default (apiClient: PoinzApiClient) => ({
  create: createClaim(apiClient)
});
