import packageJson from '../../package.json';

export { firebaseConfig } from './firebase.config';

const apiVersion = 'v2';
const backendHost = process.env.REACT_APP_BACKEND_HOST;
const internalApiHost = process.env.REACT_APP_INTERNAL_API_HOST;
if (!backendHost || !internalApiHost) {
  throw new Error('No backend host is specified!');
}

export const VERSION = packageJson.version;
export const env = process.env.NODE_ENV;
export const API_ROOT = `${backendHost}/${apiVersion}`;
export const AWIN_HOST = `${backendHost}/awin`;
export const TRADEDOUBLER_HOST = `${backendHost}/tradedoubler`;
export const INTERNAL_API_ROOT = `${internalApiHost}/v1/admin`;
export const GIFT_CARD_API_ROOT = `${backendHost}/gift-card/v1`;
export const EXPLORE_API_ROOT = `${backendHost}/explore`;
export const LOYALTY_API_ROOT = `${backendHost}/loyalty/v1`;
export const REFERRAL_API_ROOT = `${backendHost}/referral`;
export const WORLDLINE_API_ROOT = `${backendHost}/worldline`;
export const SHOPPING_CART_API_ROOT = `${backendHost}/shopping-cart/v1`;

// You can use the following api for simulating http errors: https://httpstat.us/
// simply add as endpoint the http error number you want to simulate e.g. https://httpstat.us/503 and perform a GET request on it
