import queryString from 'query-string';
// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'Location'.
import { Location } from 'react-router';

export type UrlLocation = {
  path: string;
  queryParams?: any;
  state?: any;
};

export function getNewLocation(curLocation: Location, urlLocation: UrlLocation) {
  const { path, queryParams = {}, state = {} } = urlLocation;
  const { state: currentState } = curLocation;
  // const currentQueryParams = queryString.parse(search);
  return {
    pathname: path,
    search: queryString.stringify(queryParams),
    state: { ...currentState, ...state }
  };
}
