import * as React from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import { TranslationObject } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import FormControlLabel from './FormControlLabel';
import InfoText from './InfoText';

type Props = {
  label?: TranslationObject;
  value: boolean;
  disabled: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tooltip?: TranslationObject | null | undefined;
  className?: string;
} & LocaleProps;

function Checkbox({ onChange, onClick, value, label, tooltip, className, ...restProps }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const getContent = (): React.ReactElement => (
    // @ts-expect-error - TS2322 - Type '((event: ChangeEvent<HTMLInputElement>) => void) | undefined' is not assignable to type 'MouseEventHandler<HTMLButtonElement> | undefined'.
    <MUICheckbox {...restProps} onClick={onClick} onChange={handleChange} checked={value} />
  );

  if (!label) {
    return getContent();
  }

  return (
    <InfoText label={tooltip} {...{ className }}>
      <FormControlLabel control={getContent()} {...{ label }} />
    </InfoText>
  );
}

Checkbox.defaultProps = {
  label: undefined,
  disabled: false
};

export default withLocale(Checkbox);
