import en from './en/translation.json';
import de from './de/translation.json';
import it from './it/translation.json';
import fr from './fr/translation.json';

export const LANGUAGE = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it'
};

export type Language = keyof typeof LANGUAGE;

export const LANGUAGES_TRANSLATED = {
  en: 'language.en',
  de: 'language.de',
  fr: 'language.fr',
  it: 'language.it'
};

export type TranslationKey = keyof typeof en | string;

export type Translate = (key: TranslationKey, context?: any) => string;

export type TranslationObject = {
  key: TranslationKey;
  context?: Record<string, unknown>;
};

export type EnumTranslationKeys = {
  [key: string]: TranslationKey;
};

export type MultiLanguageString = { [key: string]: string };

type TranslationDictionaries = Record<Language, Record<TranslationKey, string>>;

function createDefaultNamespaces(translations: TranslationDictionaries) {
  return Object.keys(translations).reduce((acc: any, key) => {
    acc[key] = {
      translation: {
        ...translations[key as Language]
      }
    };
    return acc;
  }, {});
}

export const translationsObject: TranslationDictionaries = {
  en,
  de,
  it,
  fr
};

export const FALLBACK_LANGUAGE: Language = LANGUAGE.de as Language;

export default createDefaultNamespaces(translationsObject);
