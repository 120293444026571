import * as React from 'react';

import { withStyles, createStyles, WithStyles } from '@mui/styles';

import { MyTheme } from 'theme/muiTheme';
import clsx from 'clsx';

const styles = createStyles((theme: MyTheme) => ({
  view: {
    flex: 1,
    position: 'relative',
    margin: 'auto',
// @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
    maxWidth: theme.mainComponents.view.width,
// @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
    paddingTop: theme.mainComponents.view.padding,
  },
}));

type ViewProps = {
  children: React.ReactNode,
  className: string
} & WithStyles<typeof styles>;

class View extends React.PureComponent<ViewProps> {
  static defaultProps = {
    className: '',
  };

  render() {
    const { children, classes, className } = this.props;
// @ts-expect-error - TS2339 - Property 'view' does not exist on type 'ClassNameMap<never>'.
    return <div className={clsx(classes.view, className)}>{children}</div>;
  }
}

export default withStyles(styles)(View);
