import { PoinzApiClient } from '../../api';
import { AdtractionProgram, AdtractionProgramDto } from './types';
import { ROOT } from './config';
import { NewPaginationModel } from '../../types';
import { PagableParams } from '../../types';

const PROGRAMS = 'programs';
const apiRoot = `${ROOT}/${PROGRAMS}`;

const programEndpoints = {
  create: apiRoot,
  getList: apiRoot,
  get: `${apiRoot}/:programId`,
  update: `${apiRoot}/:programId`,
  syncCommissions: `${apiRoot}/:programId/commissions/sync`
};

const create =
  (apiClient: PoinzApiClient) =>
  async (body: AdtractionProgramDto): Promise<void> => {
    await apiClient.post(programEndpoints.create, {
      body
    });
  };

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: PagableParams): Promise<NewPaginationModel<AdtractionProgram>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(programEndpoints.getList, {
      queryParams: {
        page,
        sort: 'businessProfile.name,asc',
        ...restParams
      }
    });
    return result;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (programId: number): Promise<AdtractionProgram> => {
    const result = await apiClient.get(programEndpoints.get, {
      pathParams: { programId }
    });
    return result.body;
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (programId: number, body: AdtractionProgramDto): Promise<void> => {
    const result = await apiClient.patch(programEndpoints.update, {
      pathParams: { programId },
      body
    });
  };

const syncCommissions =
  (apiClient: PoinzApiClient) =>
  async (programId: number): Promise<void> => {
    await apiClient.post(programEndpoints.syncCommissions, {
      pathParams: { programId }
    });
  };

export default (apiClient: PoinzApiClient) => ({
  create: create(apiClient),
  getList: getList(apiClient),
  get: get(apiClient),
  update: update(apiClient),
  syncCommissions: syncCommissions(apiClient)
});
