import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useMutation,
  UseMutationResult
} from 'react-query';
import { NewPaginationModel } from '../../../types';
import {
  Campaign,
  CreateCampaignBody,
  Location,
  PaginatedCampaignParams,
  PartnerClient
} from '../../../partnerClient';

const KEY = 'CAMPAIGN';

const getLocationById = (locations: Array<Location>, locationId: number): Location | undefined =>
  locations.find(loc => loc.id === locationId);

const useGetPaginatedList =
  (apiClient: PartnerClient) =>
  (params: PaginatedCampaignParams): UseQueryResult<NewPaginationModel<Campaign>, any> => {
    return useQuery([KEY, params], () => apiClient.loyalty.campaign.getPaginatedList(params));
  };

const useGetCampaign =
  (apiClient: PartnerClient) =>
  (campaignId: number): UseQueryResult<Campaign, any> => {
    return useQuery([KEY, campaignId], () => apiClient.loyalty.campaign.get(campaignId), {
      select: ({ cashback, ...rest }) =>
        ({
          cashback: Number((cashback * 100).toFixed(2)),
          // locations: locationIds.map((locId: number) => getLocationById(locations, locId)),
          ...rest
        } as Campaign)
    });
  };

const useDeleteCampaign =
  (apiClient: PartnerClient) =>
  (
    page: number,
    params: PaginatedCampaignParams
  ): UseMutationResult<Campaign, any, number, NewPaginationModel<Campaign>> => {
    const queryClient = useQueryClient();
    return useMutation((campaignId: number) => apiClient.loyalty.campaign.delete(campaignId), {
      onMutate: async (campaignId: number) => {
        await queryClient.cancelQueries([KEY, page, params]);
        const previousCampaignData: NewPaginationModel<Campaign> | undefined =
          queryClient.getQueryData([KEY, page, params]);
        const filteredCampaigns =
          previousCampaignData?.content?.filter(camp => camp.id !== campaignId) || [];

        queryClient.setQueryData<Partial<NewPaginationModel<Campaign>>>([KEY, page, params], {
          ...previousCampaignData,
          content: filteredCampaigns
        });
        return previousCampaignData;
      },
      onError: (
        err: any,
        campaignId: number,
        previousCampaignData: NewPaginationModel<Campaign> | undefined
      ) => {
        queryClient.setQueryData([KEY, page, params], previousCampaignData);
      },
      onSettled: () => {
        queryClient.invalidateQueries([KEY, page, params]);
      }
    });
  };

const useUpdateCampaign =
  (apiClient: PartnerClient) =>
  (campaignId: number): UseMutationResult<any, any, CreateCampaignBody, void> => {
    const queryClient = useQueryClient();
    return useMutation(
      (data: CreateCampaignBody) => apiClient.loyalty.campaign.update(campaignId, data),
      {
        onSettled: () => {
          // refetch campaign in background in case it gets updated
          queryClient.invalidateQueries([KEY, campaignId]);
        }
      }
    );
  };

const usePatchCampaign =
  (apiClient: PartnerClient) =>
  (campaignId: number): UseMutationResult<any, any, CreateCampaignBody, void> => {
    const queryClient = useQueryClient();
    return useMutation(
      (data: CreateCampaignBody) => apiClient.loyalty.campaign.patch(campaignId, data),
      {
        onSettled: () => {
          // refetch campaign in background in case it gets updated
          queryClient.invalidateQueries([KEY, campaignId]);
        }
      }
    );
  };

const useDeactivateCampaign =
  (apiClient: PartnerClient) =>
  (
    page: number,
    params: PaginatedCampaignParams,
    deactivationDateTime: string
  ): UseMutationResult<Campaign, any, number, NewPaginationModel<Campaign>> => {
    const queryClient = useQueryClient();
    return useMutation((campaignId: number) => apiClient.loyalty.campaign.deactivate(campaignId), {
      onMutate: async (campaignId: number) => {
        await queryClient.cancelQueries([KEY, page, params]);

        // Snapshot the previous value
        const previousCampaignData: NewPaginationModel<Campaign> | undefined =
          queryClient.getQueryData([KEY, page, params]);

        // Prepare new value
        const updatedCampaigns =
          previousCampaignData?.content?.map(camp => {
            if (camp.id === campaignId) {
              return {
                ...camp,
                deactivationDateTime
              };
            }
            return camp;
          }) || [];

        // Optimistically update to the new value
        queryClient.setQueryData<Partial<NewPaginationModel<Campaign>>>([KEY, page, params], {
          ...previousCampaignData,
          content: updatedCampaigns
        });
        // Return a context object with the snapshotted value
        return previousCampaignData;
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (
        err: any,
        campaignId: number,
        previousCampaignData: NewPaginationModel<Campaign> | undefined
      ) => {
        queryClient.setQueryData([KEY, page, params], previousCampaignData);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([KEY, page, params]);
      }
    });
  };

export default (client: PartnerClient) => ({
  useGetPaginatedList: useGetPaginatedList(client),
  useGetCampaign: useGetCampaign(client),
  useDeleteCampaign: useDeleteCampaign(client),
  useUpdateCampaign: useUpdateCampaign(client),
  usePatchCampaign: usePatchCampaign(client),
  useDeactivateCampaign: useDeactivateCampaign(client)
});
