import React, { Component } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import poinzStatsApi, { TopRewardsType } from 'serviceNew/api/poinzStats';
import { getPagination, initialPagination } from 'serviceNew/util/helperFunctions';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { Pagination } from 'App/components';
import { DashboardCardBody } from '../../../../components/DashboardCard';
import { tableWithExportableData } from '../../../../components/DataExporter';

export type PaginationType = {
  totalElements: number;
  totalPages: number;
  currentPage: number;
};

const styles = () => ({
  container: {
    overflow: 'auto'
  }
});

type Props = {
  classes: any;
} & LocaleProps;

type State = {
  poinzStatsTopRewards: {
    topRewards: Array<TopRewardsType>;
  } & PaginationType;
};

class TopRewardsTableUnconnected extends Component<Props, State> {
  state = {
    poinzStatsTopRewards: {
      topRewards: [],
      ...initialPagination
    }
  };

  async componentDidMount() {
    await this.getTopRewards({ pageNumber: 0 });
  }

  handlePageChange = (toPage: any) => {
    this.getTopRewards({ pageNumber: toPage });
  };

  getTopRewards = async (params: any) => {
    const { body, headers } = await poinzStatsApi.getTopRewards(params);
    const poinzStatsTopRewards = {
      topRewards: body,
      ...getPagination(headers)
    } as const;
    this.setState({ poinzStatsTopRewards });
  };

  render() {
    const { classes, formatMessage, formatNumber } = this.props;
    const { poinzStatsTopRewards } = this.state;
    const { topRewards, currentPage, totalElements, totalPages } = poinzStatsTopRewards;

    return (
      <>
        <DashboardCardBody elevation={0}>
          <Typography noWrap align="left" variant="subtitle1">
            {formatMessage({ id: 'stats.topRewards' })}
          </Typography>
        </DashboardCardBody>
        <div className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage({ id: 'businessProfile.businessProfile' })}</TableCell>
                <TableCell>{formatMessage({ id: 'deal.deal' })}</TableCell>
                <TableCell align="right">{formatMessage({ id: 'stats.numPurchased' })}</TableCell>
                <TableCell align="right">{formatMessage({ id: 'stats.numRedemptions' })}</TableCell>
                <TableCell align="right">{formatMessage({ id: 'deal.cancelled' })}</TableCell>
                <TableCell align="right">{formatMessage({ id: 'deal.revenue' })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topRewards.map((d: any) => (
                <TableRow key={d.id}>
                  <TableCell>{d.businessProfileName}</TableCell>
                  <TableCell>{d.name}</TableCell>
                  <TableCell align="right">{d.numPurchased}</TableCell>
                  <TableCell align="right">{d.numRedeemed}</TableCell>
                  <TableCell align="right">{d.numCancelled}</TableCell>
                  <TableCell align="right">
                    {formatNumber({ value: d.revenue, style: 'currency', currency: 'CHF' })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {topRewards.length < totalElements && (
          <DashboardCardBody style={{ textAlign: 'center' }} elevation={0}>
            <Pagination
              currentPage={currentPage}
              totalElements={totalElements}
              totalPages={totalPages}
              handlePageChange={this.handlePageChange}
              items={10}
            />
          </DashboardCardBody>
        )}
      </>
    );
  }
}

const fields = [
  {
    name: 'businessProfileName',
    label: 'businessProfile.businessProfile'
  },
  {
    name: 'name',
    label: 'deal.deal'
  },
  {
    name: 'numPurchased',
    label: 'stats.numPurchased'
  },
  {
    name: 'numRedeemed',
    label: 'stats.numRedemptions'
  },
  {
    name: 'numCancelled',
    label: 'deal.cancelled'
  },
  {
    name: 'revenue',
    label: 'deal.revenue',
    formatter: (input: number) => input.toLocaleString('de', { style: 'currency', currency: 'CHF' })
  }
];
const dataPath = 'poinzStatsTopRewards.topRewards';
const name = 'stats.topRewards';

const StyledTable = withStyles(styles, { withTheme: true })(TopRewardsTableUnconnected);
// @ts-expect-error - TS2749 - 'StyledTable' refers to a value, but is being used as a type here. Did you mean 'typeof StyledTable'?
const ExportableTable = tableWithExportableData<Props, StyledTable>(
  StyledTable,
  dataPath,
  fields,
  name
);

export default withLocale(ExportableTable);
