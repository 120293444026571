import * as React from 'react';
import { makeStyles } from '@mui/styles';
import RawText from '../RawText';

const useStyles = makeStyles({
  rowContent: {
    display: 'flex',
    height: '100%',
    // @ts-expect-error - TS2339 - Property 'flex' does not exist on type '{}'.
    flex: ({ flex }) => flex,
    // @ts-expect-error - TS2339 - Property 'justifyContent' does not exist on type '{}'.
    justifyContent: ({ justifyContent }) => justifyContent
  },
  text: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  }
});

type RowData = {
  className?: string;
  flex?: number;
  justifyContent?: string;
  text?: any;
  component?: React.ReactNode;
  variant?: string;
};

const renderText = (text, className: any, textClassName: undefined | string, variant: string) => (
  <div className={className}>
    {/* @ts-expect-error - TS2769 - No overload matches this call. */}
    <RawText variant={variant} className={textClassName}>
      {text}
    </RawText>
  </div>
);

function DataField(props: RowData) {
  const {
    className,
    flex = 1,
    justifyContent = 'flex-start',
    text,
    component,
    variant = 'subtitle2'
  } = props;

  const classes = useStyles({ flex, justifyContent });

  return (
    <div className={classes.rowContent}>
      {component || renderText(text, classes.text, className, variant)}
    </div>
  );
}

export default DataField;
