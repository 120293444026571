import {MyTheme} from 'theme/muiTheme';
import { IdType } from '@poinz/api';
import { Picture } from './common';

export const CASHBACK_TYPE = Object.freeze({
  GIFT_CARD_LINE: 'GIFT_CARD_LINE',
  PARTNER: 'PARTNER'
});
export type CashbackType = keyof typeof CASHBACK_TYPE;

export const CASHBACK_SUBTYPE = Object.freeze({
  GIFT_CARD: 'GIFT_CARD',
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE'
});
export type CashbackSubtype = keyof typeof CASHBACK_SUBTYPE;

export const BEST_CASHBACK_TYPE = Object.freeze({
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE'
});
export type BestCashbackType = keyof typeof BEST_CASHBACK_TYPE;

export const CASHBACK_TYPE_TRANSLATIONS = Object.freeze({
  [CASHBACK_TYPE.PARTNER]: { key: 'explore.cashback.type.partner' },
  [CASHBACK_TYPE.GIFT_CARD_LINE]: { key: 'explore.cashback.type.giftCardLine' }
});

export const getCashbackTypeColor = (type: CashbackType, theme: MyTheme) => {
  switch (type) {
    case CASHBACK_TYPE.PARTNER:
      return theme.palette.success.light;
    case CASHBACK_TYPE.GIFT_CARD_LINE:
      return theme.palette.info.main;
    default:
      return theme.palette.primary.main;
  }
};

export type Cashback = {
  id: IdType,
  type: CashbackType,
  subtype: CashbackSubtype,
  businessProfileId: IdType,
  title: string,
  logoBackgroundColor: string,
  mainColor: string,
  logo: string,
  picture: Picture,
  cashbackCount: number,
  bestCashbackValue: number,
  bestCashbackType: BestCashbackType,
  ordering: number
};
