import * as React from 'react';

import { TranslationObject } from 'serviceNew/locale';

import GeneralDialog, { ButtonType } from 'App/components/GeneralDialog';
import { ApiError } from 'serviceNew/model/apiError';
import { ProblemApiError, getProblemErrorMessage } from '@poinz/api';

type ShowDialogProps<T> = {
  title: TranslationObject;
  body: TranslationObject;
  buttons: Array<{
    label: TranslationObject;
    value: T;
  }>;
};

type GeneralDialogProps = {
  title: TranslationObject;
  body: TranslationObject;
  buttons: Array<ButtonType>;
};

type WrappedFunction<O extends Record<any, any> | null | undefined> = <A extends any[], T>(
  F: (...args: A) => Promise<T>,
  arg2: O
) => (...args: A) => Promise<T>;

type ShowApiError = WrappedFunction<{
  rethrow?: boolean;
}>;
type ShowConfirmationDialog = WrappedFunction<{
  title: TranslationObject;
  body: TranslationObject;
}>;

type DialogProps = {
  showDialog: <T extends string>(arg1: ShowDialogProps<T>) => Promise<T>;
  showApiError: ShowApiError;
  showConfirmationDialog: ShowConfirmationDialog;
};

const defaultProps = {
  title: { key: 'errors.error' },
  body: { key: 'errors.error' },
  buttons: []
} as const;

const DialogContext = React.createContext<DialogProps>({
  // @ts-expect-error - TS2322 - Type 'Promise<undefined>' is not assignable to type 'Promise<T>'.
  showDialog: async () => undefined,
  // @ts-expect-error - TS2322 - Type 'Promise<undefined>' is not assignable to type 'Promise<T>'.
  showApiError: () => async () => undefined
});

function DialogProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const [isDialogVisible, setIsDialogVisible] = React.useState<boolean>(false);
  const [dialogProps, setDialogProps] = React.useState<GeneralDialogProps | null | undefined>(
    undefined
  );

  const showDialog = React.useCallback(
    (showDialogProps: ShowDialogProps<any>): Promise<any> => {
      const { title, body, buttons } = showDialogProps;
      return new Promise(resolve => {
        setDialogProps({
          title,
          body,
          buttons: buttons.map(b => ({
            label: b.label,
            onClick: () => {
              setIsDialogVisible(false);
              resolve(b.value);
            }
          }))
        });
      });
    },
    [setDialogProps]
  );
  const showApiError = React.useCallback(
    // eslint-disable-next-line prefer-arrow-callback
    function f<A extends any[], R>(
      apiFunc: (...args: A) => Promise<R>,
      options: {
        rethrow?: boolean;
      } = {}
    ): (...args: A) => Promise<R | null | undefined> {
      const wrapped = async (...args: A): Promise<R | null | undefined> => {
        try {
          const response = await apiFunc(...args);
          return response;
        } catch (e: any) {
          const isApiError = e instanceof ApiError;
          const isProblemError = e instanceof ProblemApiError;
          const title = isApiError ? { key: 'errors.badRequest.title' } : { key: 'errors.error' };

          let body = { key: 'errors.unknown' };
          if (isApiError) {
            body = {
              key: 'text.no.translation',
              // @ts-expect-error - TS2322 - Type '{ key: string; context: { value: string; }; }' is not assignable to type '{ key: string; }'.
              context: {
                value: `${e.error.message}${
                  Array.isArray(e.error.errors) ? `, ${e.error.errors[0]}` : ''
                }`
              }
            };
          } else if (isProblemError) {
            getProblemErrorMessage(e).message;
          } else if (typeof e?.message === 'string') {
            body = {
              key: 'text.no.translation',
              // @ts-expect-error - TS2322 - Type '{ key: string; context: { value: any; }; }' is not assignable to type '{ key: string; }'.
              context: {
                value: e.message
              }
            };
          }

          await showDialog({
            title,
            body,
            buttons: [{ label: { key: 'general.ok' }, value: 'ok' }]
          });
          if (options.rethrow !== false) {
            throw e;
          }
          return null;
        }
      };
      return wrapped;
    },
    [showDialog]
  );

  const showConfirmationDialog = React.useCallback(
    // eslint-disable-next-line prefer-arrow-callback
    function f<A extends any[], R>(
      func: (...args: A) => Promise<R>,
      {
        title,
        body
      }: {
        title: TranslationObject;
        body: TranslationObject;
      }
    ): (...args: A) => Promise<R | null | undefined> {
      return async (...args: A): Promise<R | null | undefined> => {
        const response = await showDialog({
          title,
          body,
          buttons: [
            { label: { key: 'general.ok' }, value: 'ok' },
            { label: { key: 'general.cancel' }, value: 'cancel' }
          ]
        });
        if (response === 'ok') {
          return func(...args);
        }
        return null;
      };
    },
    [showDialog]
  );

  React.useEffect(() => {
    if (dialogProps != null) {
      setIsDialogVisible(true);
    }
  }, [dialogProps]);

  const contextValue = React.useMemo(
    () => ({
      showDialog,
      showApiError,
      showConfirmationDialog
    }),
    [showApiError, showConfirmationDialog, showDialog]
  );

  return (
    <DialogContext.Provider value={contextValue as any}>
      {children}
      <GeneralDialog open={isDialogVisible} {...(dialogProps || (defaultProps as any))} />
    </DialogContext.Provider>
  );
}

export const useDialog = () => React.useContext(DialogContext);

export default DialogProvider;
