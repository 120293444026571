import {PoinzApiClient} from '@poinz/api';
import { INTERNAL_API_ROOT } from 'config';
import {
  errorHandler,
  createCallEndpoint,
  createCallNotificationEndpoint,
  setCampAuthenticatedHeader,
  apiCampAgent
} from '../api';
import { localeSubject } from 'App/providers/LocaleProvider';

export const internalApiClient = new PoinzApiClient({
  agent: apiCampAgent,
  language: localeSubject.getValue(),
  apiRoot: INTERNAL_API_ROOT,
  errorHandler,
  setAuthenticatedHeaders: setCampAuthenticatedHeader
});

localeSubject.subscribe(val => internalApiClient.changeLanguage(val));

export const callEndpoint = createCallEndpoint(internalApiClient);
export const callNotificationEndpoint = createCallNotificationEndpoint(callEndpoint);
