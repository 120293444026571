import { PoinzApiClient } from '../../api';
import { IReceipt } from './types';

const ROOT = '/shopping-cart/v1';
const apiRoot = `${ROOT}/receipts`;

const getReceiptByReferenceNumber =
  (apiClient: PoinzApiClient) =>
  async (referenceNumber: string): Promise<IReceipt> => {
    const result = await apiClient.get(apiRoot, {
      queryParams: { referenceNumber }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: getReceiptByReferenceNumber(apiClient)
});
