import { keyMirror } from 'util/ObjectHelper';
import { DealType } from 'serviceNew/model/deal';
import { DealCollectState } from 'serviceNew/model/dealCollect';

export const FILTER = keyMirror({
  DEAL_TYPE: null,
  COLLECT_STATE: null
});

export const FILTER_ACTION = keyMirror({
  REMOVE: null,
  SET: null
});

type RemoveAction = {
  type: typeof FILTER_ACTION.REMOVE;
  filter: keyof typeof FILTER;
};

type SetDealCollectStateAction = {
  type: typeof FILTER_ACTION.SET;
  filter: typeof FILTER.COLLECT_STATE;
  value: DealCollectState | null | undefined;
};

type SetDealTypeAction = {
  type: typeof FILTER_ACTION.SET;
  filter: typeof FILTER.DEAL_TYPE;
  value: DealType | null | undefined;
};

type ReducerAction = SetDealTypeAction | SetDealCollectStateAction | RemoveAction;

type FilterState = {
  DEAL_TYPE: DealType | null | undefined;
  COLLECT_STATE: DealCollectState | null | undefined;
};

// @ts-expect-error - TS2739 - Type '{ [x: number]: null; }' is missing the following properties from type 'FilterState': DEAL_TYPE, COLLECT_STATE
export const initialFilterState: FilterState = {
  [FILTER.DEAL_TYPE]: null,
  [FILTER.COLLECT_STATE]: null
};

export type FilterDispatch = (action: ReducerAction) => void;

export function filterReducer(state: FilterState, action: ReducerAction): FilterState {
  switch (action.type) {
    case FILTER_ACTION.REMOVE:
      return {
        ...state,
        [action.filter]: null
      };
    case FILTER_ACTION.SET:
      return {
        ...state,

        // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'ReducerAction'.
        [action.filter]: action.value
      };
    default:
      return state;
  }
}
