import { keyMirror } from '@poinz/utils';
import { MultiLanguageString } from '@poinz/locale';
import { PagableParams } from '../../../types';

export const GIFTCARD_STATUS = keyMirror({
  DRAFT: null,
  ACTIVATED: null,
  SUSPENDED: null
});

export type GiftCardStatus = keyof typeof GIFTCARD_STATUS;

export interface PaginatedGiftCardParams extends PagableParams {
  giftCardLineId: number;
  status: string;
}

export interface GiftCard {
  id: number;
  giftCardLineId: number;
  suggestedValues: Array<number>;
  name: MultiLanguageString;
  description: MultiLanguageString;
  termsAndConditionsEmail: MultiLanguageString;
  termsAndConditionsWeb: MultiLanguageString;
  redemptionText: MultiLanguageString;
  cashbackValue: number;
  modified: string;
  modifiedById: string;
  created: string;
  status: GiftCardStatus;
}

export interface UpdateGiftCardBody {
  status: GiftCardStatus;
}
