import {createMultipartRequest, getIdFromHeaders} from '@poinz/api';
import { IdType } from '@poinz/api';
import { BusinessProfile } from 'serviceNew/model/businessProfile';
import { getMessage } from 'serviceNew/notifications';

import { callEndpoint, callNotificationEndpoint } from './apiClient';
import { mapFormWithImages } from '../picture';

const ROOT = '/contract-companies';
export const BUSINESS_PROFILES = 'business-profiles';
const ENTITY = 'businessProfile.businessProfile';

export function createBpFormData(businessProfile: BusinessProfile) {
  const multiPartData: Array<{
    data: File,
    name: string
  }> = [];
  const mappedForm = mapFormWithImages({
    form: businessProfile,
    imageFields: ['logo', 'picture'],
    multiPartData
  });
  return createMultipartRequest('businessProfileForm', mappedForm, multiPartData);
}

async function create(
  params: {
    contractCompanyId: IdType,
    values: Partial<BusinessProfile>
  },
): Promise<IdType> {
  const { values, contractCompanyId } = params;
// @ts-expect-error - TS2345 - Argument of type 'Partial<BusinessProfile>' is not assignable to parameter of type 'BusinessProfile'.
  const formData = createBpFormData(values);
  const result = await callNotificationEndpoint(
    `${ROOT}/${contractCompanyId}/${BUSINESS_PROFILES}`,
    'POST',
    {
      headers: new Headers(),
      authenticated: true,
      isMultiPart: true,
      body: formData
    },
    getMessage.create(ENTITY)
  );
  return getIdFromHeaders(result.headers);
}

async function remove(
  params: {
    businessProfileId: IdType,
    contractCompanyId: IdType
  },
): Promise<void> {
  const { businessProfileId, contractCompanyId } = params;
  await callNotificationEndpoint(
    `${ROOT}/${contractCompanyId}/${BUSINESS_PROFILES}/${businessProfileId}`,
    'DELETE',
    {
      headers: new Headers(),
      authenticated: true
    },
    getMessage.delete(ENTITY)
  );
}

async function pushPosDevices(
  params: {
    businessProfileId: IdType,
    contractCompanyId: IdType
  },
): Promise<void> {
  const { businessProfileId, contractCompanyId } = params;
  await callEndpoint(
    `${ROOT}/${contractCompanyId}/${BUSINESS_PROFILES}/${businessProfileId}/push`,
    'POST',
    {
      headers: new Headers(),
      authenticated: true
    }
  );
}

export default {
  create,
  remove,
  pushPosDevices
};
