import React from 'react';
import clsx from 'clsx';
import { MenuList, Drawer, Divider, IconButton, Grid } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { UserRoles } from 'serviceNew/model/adminUser';
import RenderIf from 'App/components/RenderIf';
import NavListItem from './NavListItem';
// @ts-expect-error - TS2307 - Cannot find module './poinz-logo-orange.png' or its corresponding type declarations.
import poinzLogo from './poinz-logo-orange.png';
import { SIDEBAR } from './Sidebar.utils';
import { CollapsableNavListItem } from './components';
import { useStyles } from './Sidebar.styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
type Props = {
  open: boolean;
  onClick: () => void;
};

const Sidebar = ({ open, onClick }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const drawerItems = (
    <div className={classes.drawerContent}>
      {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node | undefined', but multiple children were provided. | TS2786 - 'RenderIf' cannot be used as a JSX component. */}
      <RenderIf roles={UserRoles.POINZ_ADMIN}>
        <CollapsableNavListItem label={{ key: 'sidebar.general' }} isSidebarOpen={open}>
          <NavListItem menu={SIDEBAR.GENERAL_SALES_COMMISSION} />
          <NavListItem menu={SIDEBAR.GENERAL_SUBSCRIPTIONS} />
          {/* @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component. */}
          <RenderIf roles={UserRoles.SUPER_ADMIN}>
            <NavListItem menu={SIDEBAR.GENERAL_BILLINGS} />
          </RenderIf>
          <NavListItem menu={SIDEBAR.GENERAL_ORDERS} />
        </CollapsableNavListItem>
        <Divider />
      </RenderIf>

      <CollapsableNavListItem label={{ key: 'sidebar.dashboard' }} isSidebarOpen={open}>
        {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node | undefined', but multiple children were provided. | TS2786 - 'RenderIf' cannot be used as a JSX component. */}
        <RenderIf roles={UserRoles.POINZ_ADMIN}>
          <NavListItem menu={SIDEBAR.DASHBOARD_OVERVIEW} />
          <NavListItem menu={SIDEBAR.DASHBOARD_POINZ_ACTIVITY} />
        </RenderIf>

        <NavListItem menu={SIDEBAR.DASHBOARD_ACTIVITY} />

        <RenderIf loyalty>
          <NavListItem menu={SIDEBAR.DASHBOARD_LOYALTY} />
        </RenderIf>
        <RenderIf deal>
          <NavListItem menu={SIDEBAR.DASHBOARD_REWARDS} />
        </RenderIf>
        <RenderIf communication loyalty deal>
          <NavListItem menu={SIDEBAR.DASHBOARD_COMMUNICATION} />
        </RenderIf>
        <NavListItem menu={SIDEBAR.DASHBOARD_MARKETING_CAMPAIGNS} />
      </CollapsableNavListItem>

      <Divider />

      <CollapsableNavListItem label={{ key: 'sidebar.administration' }} isSidebarOpen={open}>
        <NavListItem menu={SIDEBAR.ADMINISTRATION_MANAGE} />
        {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node | undefined', but multiple children were provided. | TS2786 - 'RenderIf' cannot be used as a JSX component. */}
        <RenderIf roles={UserRoles.SUPER_ADMIN}>
          <NavListItem menu={SIDEBAR.ADMINISTRATION_USER_MANAGEMENT} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_REPORTS} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_PROMO_DEAL} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_CARD_TEMPLATES} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_VOUCHER} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_APP_LINKS} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_CATEGORIES} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_REFERRAL_CAMPAIGNS} />
          <NavListItem menu={SIDEBAR.ADMINISTRATION_LABELING} />
        </RenderIf>
      </CollapsableNavListItem>

      <Divider />

      <MenuList>
        <NavListItem menu={SIDEBAR.LOYALTY} />
      </MenuList>

      {/* @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component. */}
      <RenderIf roles={UserRoles.SUPER_ADMIN}>
        <>
          <Divider />
          <CollapsableNavListItem label={{ key: 'sidebar.swissLoyalty' }} isSidebarOpen={open}>
            <NavListItem menu={SIDEBAR.AWIN} />
            <NavListItem menu={SIDEBAR.ADTRACTION} />
            <NavListItem menu={SIDEBAR.TRADEDOUBLER} />
            <NavListItem menu={SIDEBAR.TRANSACTION} />
            <NavListItem menu={SIDEBAR.WORLDLINE} />
            <NavListItem menu={SIDEBAR.EXPLORE} />
          </CollapsableNavListItem>
        </>
      </RenderIf>

      <Divider />

      {/* @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component. */}
      <RenderIf roles={UserRoles.POINZ_ADMIN}>
        <>
          <Divider />
          <CollapsableNavListItem label={{ key: 'sidebar.giftcards' }} isSidebarOpen={open}>
            <NavListItem menu={SIDEBAR.GIFTCARDS_MANAGE} isExact />
            <NavListItem menu={SIDEBAR.GIFTCARDS_PURCHASES} />
          </CollapsableNavListItem>
        </>
      </RenderIf>

      <Divider />

      <RenderIf
        deal
        elseComponent={() => (
          <MenuList>
            <NavListItem menu={SIDEBAR.REWARDS} />
          </MenuList>
        )}
      >
        <CollapsableNavListItem label={{ key: 'sidebar.deals' }} isSidebarOpen={open}>
          <NavListItem menu={SIDEBAR.REWARDS_OVERVIEW} />
          <NavListItem menu={SIDEBAR.REWARDS_REDEMPTION} />
        </CollapsableNavListItem>
      </RenderIf>

      <Divider />
      <RenderIf
        loyalty
        deal
        elseComponent={() => (
          <MenuList>
            <NavListItem menu={SIDEBAR.COMMUNICATION} />
          </MenuList>
        )}
      >
        <CollapsableNavListItem
          label={{ key: 'sidebar.communication' }}
          // @ts-expect-error - TS2769 - No overload matches this call.
          in
          timeout="auto"
          isSidebarOpen={open}
        >
          <NavListItem menu={SIDEBAR.COMMUNICATION_MESSAGES} />
          <NavListItem menu={SIDEBAR.COMMUNICATION_COUPONS} />
          {/* @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component. */}
          <RenderIf roles={UserRoles.SUPER_ADMIN}>
            <NavListItem menu={SIDEBAR.MARKETING_CAMPAIGNS} />
          </RenderIf>
        </CollapsableNavListItem>
      </RenderIf>

      <Divider />
    </div>
  );

  return !smDown ? (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      open={open}
    >
      <div className={classes.toolbar}>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <IconButton onClick={onClick} style={{ marginLeft: 12 }}>
              <ChevronLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <img src={poinzLogo} className={classes.logo} alt="Logo" />
          </Grid>
        </Grid>
      </div>
      <Divider />
      {drawerItems}
    </Drawer>
  ) : (
    <Drawer
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClick}
      classes={{
        paper: classes.drawerPaper
      }}
      ModalProps={{
        keepMounted: false // true for better mobile performance
      }}
    >
      {drawerItems}
    </Drawer>
  );
};

export default Sidebar;
