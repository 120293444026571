import * as React from 'react';
import { MultiLanguageString, TranslationObject } from 'serviceNew/locale';
import { SnackbarRef } from 'serviceNew/api/api';
import { Snackbar as MuiSnackbar, IconButton } from '@mui/material';

import { Icon, TranslatedText } from 'App/components';

type AddSnackbar = (notification: TranslationObject) => void;
export type SnackbarProps = {
  addNotification: AddSnackbar;
  link?: MultiLanguageString;
};
const SnackbarContext = React.createContext<AddSnackbar>(() => undefined);

type Props = {
  children: React.ReactNode;
  classes: any;
};

function Snackbar(props: Props) {
  const { children, classes } = props;

  const queueRef = React.useRef([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [messageText, setMessageText] = React.useState<TranslationObject | null | undefined>(
    undefined
  );

  function processQueue() {
    if (queueRef.current.length > 0) {
      setMessageText(queueRef.current.shift());
      setOpen(true);
    }
  }

  const addNotification = React.useCallback(
    (notification: TranslationObject) => {
      // @ts-expect-error - TS2345 - Argument of type 'TranslationObject' is not assignable to parameter of type 'never'.
      queueRef.current.push(notification);
      if (open) {
        setOpen(false);
      } else {
        processQueue();
      }
    },
    [open]
  );

  function handleClose() {
    setOpen(false);
  }

  function handleExited() {
    processQueue();
  }

  // @ts-expect-error - TS2540 - Cannot assign to 'current' because it is a read-only property.
  SnackbarRef.current = addNotification;

  return (
    <SnackbarContext.Provider value={addNotification}>
      {children}
      <MuiSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        onClose={handleClose}
        // @ts-expect-error - TS2322 - Type '{ open: boolean; classes: any; anchorOrigin: { vertical: "bottom"; horizontal: "left"; }; autoHideDuration: number; onClose: () => void; onExited: () => void; message: Element | undefined; action: Element[]; }' is not assignable to type 'IntrinsicAttributes & SnackbarProps'.
        onExited={handleExited}
        message={messageText ? <TranslatedText text={messageText} /> : undefined}
        action={[
          <IconButton key="close" onClick={handleClose} size="large">
            <Icon icon="close" />
          </IconButton>
        ]}
        {...{ open, classes }}
      />
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => React.useContext(SnackbarContext);

export function withSnackbar<P extends SnackbarProps, Comp extends React.ComponentType<P>>(C) {
  return props => (
    <SnackbarContext.Consumer>
      {(addNotification: AddSnackbar) => <C {...{ addNotification }} {...props} />}
    </SnackbarContext.Consumer>
  );
}

export default Snackbar;
