import * as React from 'react';
import { FieldProps } from 'formik';
import { useLocale } from 'App/providers/LocaleProvider';
import { TranslationObject } from 'serviceNew/locale';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from '../TextInput';
import { TextFieldProps } from '@mui/material';

type Props = {
  readOnly?: boolean;
  label: TranslationObject;
  withClear: boolean;
  max?: number;
  min: number;
  step: number;
  meta?: {
    touched: boolean;
    dirty: boolean;
    error: any | null | undefined;
    valid: boolean;
    submitting: boolean;
  };
  placeholder?: TranslationObject;
  startAdornment?: React.ReactElement | string;
  endAdornment?: React.ReactElement | string;
} & TextFieldProps &
  FieldProps;

const NumberField = ({
  field,
  readOnly,
  disabled,
  withClear = false,
  max,
  min,
  step,
  meta,
  form,
  startAdornment,
  endAdornment,
  required = false,
  ...restProps
}: Props) => {
  const { error, touched, submitting } = meta || {};

  const { name, value } = field;

  const { translate } = useLocale();

  return (
    <TextInput
      error={!!error && touched}
      disabled={submitting || disabled}
      type="number"
      {...field}
      {...restProps}
      required={required}
      value={value != null ? value : ''}
      helperText={touched && error && translate(error.key, error.context)}
      InputProps={{
        inputProps: {
          max,
          min,
          step,
          'data-1p-ignore': ''
        },
        readOnly,
        startAdornment,
        endAdornment:
          withClear && value && value !== '' ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="clear input"
                onClick={() => form.setFieldValue(name, undefined)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            endAdornment
          )
      }}
    />
  );
};

export default NumberField;
