import { ScaledImage } from '../../../types';

export const DEAL_TYPE = {
  DEAL: 'DEAL',
  COUPON: 'COUPON',
  COUPON_MSG: 'COUPON_MSG'
} as const;

export type DealType = (typeof DEAL_TYPE)[keyof typeof DEAL_TYPE];

export const DEAL_STATE = {
  PENDING: 'PENDING',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
  PURCHASED: 'PURCHASED',
  REDEEMED: 'REDEEMED',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED'
} as const;

export type DealState = (typeof DEAL_STATE)[keyof typeof DEAL_STATE];

export const DEAL_REDEMPTION_TYPE = {
  POINZ: 'POINZ',
  BARCODE: 'BARCODE',
  ONLINE: 'ONLINE',
  NONE: 'NONE',
  DELIVERY: 'DELIVERY'
} as const;

export type DealRedemptionType = (typeof DEAL_REDEMPTION_TYPE)[keyof typeof DEAL_REDEMPTION_TYPE];

export const DEAL_REDEMPTION_METHOD = {
  DEFAULT: 'DEFAULT',
  TIMER: 'TIMER'
} as const;

export type DealRedemptionMethod =
  (typeof DEAL_REDEMPTION_METHOD)[keyof typeof DEAL_REDEMPTION_METHOD];

export const DEAL_ASSET_TYPE = {
  CARD: 'CARD',
  DEAL: 'DEAL',
  COUPON: 'COUPON'
} as const;

export type DealAssetType = (typeof DEAL_ASSET_TYPE)[keyof typeof DEAL_ASSET_TYPE];

export const DEAL_DISCOUNT_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT',
  AMOUNT_PER_UNIT: 'AMOUNT_PER_UNIT',
  CUSTOM: 'CUSTOM'
} as const;

export type DealDiscountType = (typeof DEAL_DISCOUNT_TYPE)[keyof typeof DEAL_DISCOUNT_TYPE];

export const DEAL_DISCOUNT_UNIT_TYPE = {
  CHF: 'CHF',
  // Rp and Centime are custom units
  RP: 'Rp',
  CENTIME: 'Centime'
} as const;

export type DealDiscountUnitType =
  (typeof DEAL_DISCOUNT_UNIT_TYPE)[keyof typeof DEAL_DISCOUNT_UNIT_TYPE];

export interface DealPriceData {
  name: string;
  nameLocalized: string;
  price: number;
  discountedPrice: number;
  index: number;
  isDefault: boolean;
  id: number;
  increment: number;
  isSoldOut: boolean;
  isValid: boolean;
}

export interface DealDiscount {
  type: DealDiscountType;
  description: string;
  value: number;
  discountUnit?: DealDiscountUnitType;
  shortText?: string;
  unit?: string;
}

export interface Deal {
  businessProfileName: string;
  businessProfileId: number;
  businessProfileDescription: string;
  logo: string;
  mainColor: string;
  logoBackgroundColor: string;
  facebookId: string;
  instagramId: string;
  twitterId: string;
  url: string;
  picture: ScaledImage;
  description: string;
  descriptionLocalized: string;
  name: string;
  nameLocalized: string;
  terms: string;
  termsLocalized: string;
  redemptionDescription: string;
  redemptionDescriptionLocalized: string;
  id: number;
  dealCategory: string;
  dealType: DealType;
  additionalInfo: string;
  additionalInfoLocalized: string;
  locationLabel: string;
  dynamicLink: string;
  orderLink: string;
  additionalPictures: Array<ScaledImage>;
  videoUrlArray: Array<string>;
  expirationDate: Date;
  redemptionType: DealRedemptionType;
  redemptionMethod: DealRedemptionMethod;
  currentCounter: number;
  currentMaxDeals: number;
  endDateTime?: Date;
  deliveryFromDate: Date;
  deliveryToDate: Date;
  dealOptionsCounter: any;
  dealOptionsLimit: any;
  assetType: DealAssetType;
  discount: DealDiscount;
  options: Array<DealPriceData>;
  hidePrice: boolean;
  cashbackPercent: number;
  userLimit: number;
  userCounter: number;
  active: boolean;
  slug: string;
}
