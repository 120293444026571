import * as React from 'react';
import { IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useLocale } from 'App/providers/LocaleProvider';

import { Text } from 'App/components';

type Props = {
  count: number,
  page: number,
  rowsPerPage: number,
  onChangePage: (arg1: any, arg2: number) => void,
  showPageNumbers?: boolean
};

function TablePaginationActions(props: Props) {
  const { count, page, rowsPerPage, onChangePage, showPageNumbers } = props;

  const { translate } = useLocale();

  function handleFirstPageButtonClick(event: any) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event: any) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event: any) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event: any) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  const isFirstPage = page === 0;
  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {showPageNumbers && (
        <Text
          context={{
            startItem: page * rowsPerPage + 1,
            endItem: Math.min(page * rowsPerPage + rowsPerPage, count),
            totalItems: count,
          }}
        >
          general.pagination
        </Text>
      )}
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isFirstPage}
        aria-label={translate('general.firstPage')}
        size="large">
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isFirstPage}
        aria-label={translate('general.previousPage')}
        size="large">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label={translate('general.nextPage')}
        size="large">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage}
        aria-label={translate('general.lastPage')}
        size="large">
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

export default TablePaginationActions;
