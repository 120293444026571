import * as React from 'react';
import { ContractCompany } from 'serviceNew/model/contractCompany';
import { ListItemText, Box, MenuItem, Popover, Button, Typography } from '@mui/material';
import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import { useStyles } from './ContractCompanyPopover.styles';
import clsx from 'clsx';

const ContractCompanyPopover = () => {
  const anchorRef = React.useRef(null);
  const { contractCompany, contractCompanyList, selectContractCompany } = useContractCompany();
  const [open, setOpen] = React.useState(false);
  const styles = useStyles();

  return !contractCompany ? null : (
    <>
      <Button
        ref={anchorRef}
        variant="text"
        className={clsx(styles.button, { [styles.buttonOpen]: open })}
        onClick={() => setOpen(true)}
        disabled={contractCompanyList.length <= 1}
      >
        <Typography color="inherit" variant="subtitle1" noWrap>
          {contractCompany?.name}
        </Typography>
      </Button>

      {contractCompanyList?.length > 0 && (
        <Popover
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={anchorRef.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Box className={styles.box}>
            {contractCompanyList.map((companyOption: ContractCompany) => (
              <MenuItem
                className={styles.menuItem}
                key={companyOption.id}
                selected={companyOption.id === contractCompany?.id}
                onClick={() => {
                  selectContractCompany(companyOption.id);
                  setOpen(false);
                }}
              >
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {companyOption?.name}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default ContractCompanyPopover;
