import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import { Text } from 'App/components';

const useStyles = makeStyles(theme => ({
  blockHeader: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  }
}));

type Props = {
  label: TranslationObject;
};

function FormBlockHeader(props: Props) {
  const { label } = props;

  const classes = useStyles();

  return (
    <Text className={classes.blockHeader} variant="subtitle2" context={label.context}>
      {label.key}
    </Text>
  );
}

export default React.memo<Props>(FormBlockHeader);
