import { createTheme } from '@mui/material/styles';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

export const STATUS_COLORS = {
  GREEN: '#89d9a3',
  YELLOW: '#f0e69e',
  RED: '#d49898',
  BLUE: '#8ecfde',
  SAND: '#e8e5dc',
  GREY: '#a8a8a8'
} as const;

export const theme = createTheme({
  palette: {
    primary: { main: '#f26100', dark: '#8c3800', light: '#FCE3D2' },
    secondary: { main: '#3f3f3f' },
    overlay: {
      dark: 'rgba(0,0,0,0.7)',
      middle: 'rgba(0,0,0,0.6)',
      bright: 'rgba(255,255,255,0.6)'
    },
    background: {
      default: '#EEEEEE'
    },
    // @ts-expect-error - TS2322 - Type '{ hint: string; }' is not assignable to type 'Partial<TypeText>'.
    text: { hint: 'rgba(0, 0, 0, 0.38)' },
    status: mapKeys(STATUS_COLORS, (_, key) => camelCase(key))
  },
  borderRadius: {
    tiny: 4,
    small: 8,
    medium: 12,
    large: 16,
    xLarge: 32
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } }
    }
  },
  transition: {
    duration: {
      shorter: 200
    }
  },
  border: '1px solid rgba(0, 0, 0, 0.23)',
  mainComponents: {
    drawer: {
      width: 240
    },
    footer: {
      height: 95
    },
    view: {
      width: 1200,
      padding: 20
    },
    modal: {
      minWidth: 300
    },
    preview: {
      spacing: {
        tiny: 2,
        small: 3,
        medium: 4,
        large: 6,
        xLarge: 8,
        huge: 12
      }
    },
    chart: {
      legend: {
        fontSize: 12,
        color: 'rgb(102, 102, 102)',
        fontFamily: 'roboto'
      },
      color: ['#EB6308', '#17BEBB', '#006494'],
      axis: {
        label: {
          fontSize: 12,
          fontFamily: 'roboto'
        },
        line: {
          stroke: '#BDBDBD',
          strokeWidth: 1.5
        }
      }
    }
  },
  extra: {
    spacing: {
      tiny: 8,
      small: 16,
      base: 24,
      large: 32
    }
  }
});

export type MyTheme = typeof theme;
