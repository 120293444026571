import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1),
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  item: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  indicator: {
    width: 12,
    height: 12,
    borderRadius: 6,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(1)
  }
}));

type Entry = {
  dataKey: string;
  value: number;
  color: string;
};

type Props = {
  payload: Entry[];
  onClick?: (key: string, entry?: Entry) => void;
  wrapperStyle?: any;
};

function Legend(props: Props) {
  const classes = useStyles();
  const { payload, wrapperStyle, onClick } = props;

  function handleClick(key: string, entry: Entry) {
    return () => {
      if (typeof onClick === 'function') {
        onClick(key, entry);
      }
    };
  }

  return (
    <div className={classes.container} style={wrapperStyle}>
      {payload.map((entry, index) => (
        <div
          className={classes.item}
          key={`item-${entry.dataKey}`}
          onClick={handleClick(entry.dataKey.trim(), entry)}
          onKeyPress={handleClick(entry.dataKey.trim(), entry)}
          role="button"
          tabIndex={index}
        >
          <div className={classes.indicator} style={{ backgroundColor: entry.color }} />
          {entry.value}
        </div>
      ))}
    </div>
  );
}

export default Legend;
