import * as React from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { TranslationObject } from 'serviceNew/locale';
import { useLocale } from 'App/providers/LocaleProvider';
import { Moment } from 'moment';
import { moment } from 'serviceNew/locale/dates';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import FieldLabel from '../FieldLabel';
import { TextInput } from 'App/components';

type Props = {
  className?: string | null | undefined;
  label: TranslationObject;
  tooltip?: TranslationObject | null | undefined;
  required: boolean;
  disabled: boolean;
  disablePast: boolean;
  minDate?: string | Moment;
  maxDate?: string | Moment;
  defaultValue: undefined | string;
  withTimezone: boolean;
} & FieldRenderProps;

function DateTimePickerField(props: Props) {
  const {
    field,
    meta,
    label,
    required,
    disabled,
    minDate,
    maxDate,
    // eslint-disable-next-line no-unused-vars
    defaultValue,
    disablePast,
    withTimezone,
    className,
    tooltip,
    ...restProps
  } = props;
  const { error, touched, submitting } = meta;

  const { translate } = useLocale();

  function handleChange(value: Moment) {
    field.onChange(value.format(withTimezone ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DDTHH:mm:ss'));
  }

  return (
    <div {...{ className }}>
      <FieldLabel {...{ label, tooltip, required }} />
      <MobileDateTimePicker
        renderInput={props => (
          <TextInput
            {...props}
            noLabel
            disabled
            style={{ width: '100%' }}
            error={!!error && touched}
            placeholder={translate(label.key, label.context)}
            label={translate(label.key, label.context)}
            helperText={touched && error && translate(error.key, error.context)}
          />
        )}
        style={{ width: '100%' }}
        error={!!error && !!touched}
        helperText={touched && error && translate(error.key, error.context)}
        required={required}
        placeholder={translate(label.key, label.context)}
        value={field.value || new Date()}
        disabled={submitting || disabled}
        minDate={minDate && moment(minDate)}
        maxDate={maxDate && moment(maxDate)}
        ampm={/[AaPp][Mm]/.test(moment(field.value).format('LT'))}
        cancelLabel={translate('general.cancel')}
        okLabel={translate('general.ok')}
        inputFormat="l LT"
        onChange={handleChange}
        disablePast={disablePast}
        inputVariant="outlined"
        {...restProps}
      />
    </div>
  );
}

DateTimePickerField.defaultProps = {
  required: false,
  withTimezone: false
};

export default withFieldAdapter(DateTimePickerField);
