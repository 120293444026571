import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ContractCompany, PERMISSION_CATEGORY } from 'serviceNew/model/contractCompany';
import { IdType } from '@poinz/api';
import { useLocalStorage } from '@poinz/hooks';
import { useAdminUser } from './AdminUserProvider';
import apiNew from 'serviceNew/api/sharedApiClient';

const CONTRACT_COMPANY_ID_KEY = '@selectedEntities:contractCompanyId';

export type OptionalContractCompanyProps = {
  contractCompanyId: IdType | null | undefined;
  contractCompany: ContractCompany | null | undefined;
  contractCompanyList: ContractCompany[];
  contractCompanyLoading: boolean;
  selectContractCompany: (id?: IdType | null | undefined) => Promise<ContractCompany | undefined>;
  updateContractCompany: (contractCompany: ContractCompany) => void;
  hasDealPermission: boolean | null | undefined;
  hasLoyaltyPermission: boolean | null | undefined;
  hasCommunicationPermission: boolean | null | undefined;
  belongsToIds: number[];
};

export type ContractCompanyProps = {
  contractCompany: ContractCompany;
  contractCompanyList: ContractCompany[];
  updateContractCompany: (contractCompany: ContractCompany) => void;
  selectContractCompany: (id?: IdType | null | undefined) => Promise<ContractCompany | undefined>;
  hasDealPermission: boolean | null | undefined;
  hasLoyaltyPermission: boolean | null | undefined;
  hasCommunicationPermission: boolean | null | undefined;
  belongsToIds: number[];
};

const defaultContext: OptionalContractCompanyProps = {
  contractCompanyId: undefined,
  contractCompany: undefined,
  contractCompanyList: [],
  contractCompanyLoading: false,
  selectContractCompany: async () => undefined,
  updateContractCompany: () => undefined,
  hasDealPermission: false,
  hasLoyaltyPermission: false,
  hasCommunicationPermission: false,
  belongsToIds: []
};

export const ContractCompanyContext =
  React.createContext<OptionalContractCompanyProps>(defaultContext);

type Props = {
  children: React.ReactNode;
};

function ContractCompanyProvider(props: Props) {
  const { children } = props;

  const [contractCompany, setContractCompany] = React.useState<ContractCompany | null | undefined>(
    null
  );
  const [contractCompanyList, setContractCompanyList] = React.useState<ContractCompany[]>([]);
  const [contractCompanyId, setContractCompanyId] = useLocalStorage<IdType | null | undefined>({
    key: CONTRACT_COMPANY_ID_KEY,
    initialValue: null
  });
  const [loading, setLoading] = React.useState<boolean>(contractCompanyId != null);
  const { adminUser, isLoading: isAdminUserLoading } = useAdminUser();

  const selectContractCompany = React.useCallback(
    async (id?: IdType | null) => {
      setContractCompanyId(id);
    },
    [setContractCompanyId]
  );

  const updateContractCompany = React.useCallback((newContractCompany: ContractCompany) => {
    setContractCompany(newContractCompany);
  }, []);

  const hasDealPermission =
    contractCompany && contractCompany.permissions.includes(PERMISSION_CATEGORY.DEAL);
  const hasLoyaltyPermission =
    contractCompany && contractCompany.permissions.includes(PERMISSION_CATEGORY.LOYALTY);
  const hasCommunicationPermission =
    contractCompany && contractCompany.permissions.includes(PERMISSION_CATEGORY.COMMUNICATION);

  React.useEffect(() => {
    async function loadContractCompany(id: IdType) {
      setLoading(true);
      try {
        const retrievedContractCompany = await apiNew.backend.contractCompany.get(id);
        setContractCompany(retrievedContractCompany);
      } finally {
        setLoading(false);
      }
    }

    if (contractCompanyId) {
      const preLoadedContractCompany = contractCompanyList.find(cc => cc.id === contractCompanyId);
      if (preLoadedContractCompany) {
        setContractCompany(preLoadedContractCompany);
      } else if (
        adminUser != null &&
        (!contractCompany || contractCompanyId !== contractCompany?.id)
      ) {
        loadContractCompany(contractCompanyId);
      }
    } else {
      const adminUserContractCompanyId = contractCompanyList[0]?.id;
      if (adminUserContractCompanyId != null && !contractCompanyId) {
        selectContractCompany(adminUserContractCompanyId);
      } else {
        setContractCompany(null);
      }
    }
  }, [adminUser, contractCompany, contractCompanyId, contractCompanyList, selectContractCompany]);

  React.useEffect(() => {
    if (adminUser == null && !isAdminUserLoading) {
      selectContractCompany(null);
      setLoading(false);
    }
  }, [adminUser, isAdminUserLoading, selectContractCompany]);

  React.useEffect(() => {
    (async function loadContractCompaniesForAdminUser() {
      if (adminUser) {
        setLoading(true);
        try {
          const retrievedContractCompanyList =
            await apiNew.backend.contractCompany.getListForAdminUser(adminUser.id);
          setContractCompanyList(retrievedContractCompanyList);
        } finally {
          setLoading(false);
        }
      } else {
        setContractCompanyList([]);
      }
    })();
  }, [adminUser]);

  const contextValue = React.useMemo(
    () => ({
      contractCompany,
      contractCompanyList,
      contractCompanyLoading: loading || !adminUser,
      selectContractCompany,
      updateContractCompany,
      contractCompanyId,
      hasDealPermission,
      belongsToIds: contractCompanyList.map(cc => cc.id),
      hasLoyaltyPermission,
      hasCommunicationPermission
    }),
    [
      adminUser,
      contractCompany,
      contractCompanyId,
      hasCommunicationPermission,
      contractCompanyList,
      hasDealPermission,
      hasLoyaltyPermission,
      loading,
      selectContractCompany,
      updateContractCompany
    ]
  );

  return (
    // @ts-expect-error - TS2322 - Type '{ contractCompany: ContractCompany | null | undefined; contractCompanyList: ContractCompany[]; contractCompanyLoading: boolean; selectContractCompany: (id?: number | ... 1 more ... | undefined) => Promise<...>; ... 5 more ...; hasCommunicationPermission: any; }' is not assignable to type 'OptionalContractCompanyProps'.
    <ContractCompanyContext.Provider value={contextValue}>
      {children}
    </ContractCompanyContext.Provider>
  );
}

export default ContractCompanyProvider;

export const useContractCompany = () => React.useContext(ContractCompanyContext);

export function withContractCompany<
  P extends ContractCompanyProps,
  Comp extends React.ComponentType<P>
>(
  C: any
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>, ContractCompanyProps>
> {
  return (
    props: Diff<
      JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>,
      ContractCompanyProps
    >
  ) => (
    <ContractCompanyContext.Consumer>
      {(context: OptionalContractCompanyProps) => {
        const {
          contractCompany,
          contractCompanyLoading,
          updateContractCompany,
          selectContractCompany,
          belongsToIds,
          hasDealPermission,
          hasLoyaltyPermission,
          hasCommunicationPermission,
          contractCompanyList
        } = context;
        if (contractCompanyLoading) {
          return <CircularProgress />;
        }
        if (!contractCompany) {
          return <Redirect to="/administration/contract-company/search" />;
        }

        return (
          <C
            {...{
              belongsToIds,
              contractCompany,
              contractCompanyList,
              updateContractCompany,
              selectContractCompany,
              hasDealPermission,
              hasLoyaltyPermission,
              hasCommunicationPermission
            }}
            {...props}
          />
        );
      }}
    </ContractCompanyContext.Consumer>
  );
}
export function withOptionalContractCompany<
  P extends OptionalContractCompanyProps,
  Comp extends React.ComponentType<P>
>(
  C: any
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>, OptionalContractCompanyProps>
> {
  return (
    props: Diff<
      JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>,
      OptionalContractCompanyProps
    >
  ) => (
    <ContractCompanyContext.Consumer>
      {(context: OptionalContractCompanyProps) => <C {...context} {...props} />}
    </ContractCompanyContext.Consumer>
  );
}
