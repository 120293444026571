import UAParser from 'ua-parser-js';

export const getDeviceInfo = () => {
  const parserResult = UAParser();
  const { device, browser, os } = parserResult;
  return {
    device: device.model,
    brand: device.vendor,
    systemVersion: os.version,
    systemName: os.name,
    browser: `${browser.name}/${browser.version}`
  };
};
