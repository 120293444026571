import { PoinzApiClient } from '../../api';
import { Campaign, CreateCampaignBody, PaginatedCampaignParams, UpdateCampaignBody } from './types';
import { ROOT } from './config';
import { NewPaginationModel } from '../../types';

const VERSION = '/v1';
const CAMPAIGNS = '/campaigns';
const apiRoot = `${ROOT}${VERSION}${CAMPAIGNS}`;

export const campaignEndpoints = {
  create: apiRoot,
  getList: apiRoot,
  get: `${apiRoot}/:campaignId`,
  delete: `${apiRoot}/:campaignId`,
  update: `${apiRoot}/:campaignId`,
  deactivate: `${apiRoot}/:campaignId/deactivate`
};

const create =
  (apiClient: PoinzApiClient) =>
  async (businessProfileId: number, body: CreateCampaignBody): Promise<void> => {
    await apiClient.post(campaignEndpoints.create, {
      queryParams: { businessProfileId },
      body
    });
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (campaignId: number, body: UpdateCampaignBody): Promise<void> => {
    await apiClient.put(campaignEndpoints.update, {
      pathParams: { campaignId },
      body
    });
  };

const patch =
  (apiClient: PoinzApiClient) =>
  async (campaignId: number, body: UpdateCampaignBody): Promise<void> => {
    await apiClient.patch(campaignEndpoints.update, {
      pathParams: { campaignId },
      body
    });
  };

const deleteCampaign =
  (apiClient: PoinzApiClient) =>
  async (campaignId: number): Promise<Campaign> => {
    const result = await apiClient.delete(campaignEndpoints.delete, { pathParams: { campaignId } });
    return result.body;
  };

const deactivate =
  (apiClient: PoinzApiClient) =>
  async (campaignId: number): Promise<Campaign> => {
    const result = await apiClient.put(campaignEndpoints.deactivate, {
      pathParams: { campaignId }
    });
    return result.body;
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (campaignId: number): Promise<Campaign> => {
    const result = await apiClient.get(campaignEndpoints.get, { pathParams: { campaignId } });

    return result.body;
  };

const getPaginatedList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedCampaignParams): Promise<NewPaginationModel<Campaign>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(campaignEndpoints.getList, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

export default (apiClient: PoinzApiClient) => ({
  delete: deleteCampaign(apiClient),
  create: create(apiClient),
  update: update(apiClient),
  patch: patch(apiClient),
  get: get(apiClient),
  getPaginatedList: getPaginatedList(apiClient),
  deactivate: deactivate(apiClient)
});
