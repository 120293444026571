import { BUSINESS_PROFILES } from 'serviceNew/api/businessProfile';
import { MESSAGES } from 'serviceNew/api/message';
import { STATS_API } from 'serviceNew/api/businessProfileStats';
import { callEndpoint } from './apiClient';

import { MessageType, MessageStateType } from '../../constants/general';
import { MultiLanguageString } from '../locale';

export type MessageStatsDetailsType = {
  dateDay: string;
  openingRate: number;
  collectingRate: number | null | undefined;
  avgTimeToRead: number;
  avgTimeToCollect: number | null | undefined;
  activityTimelineData: Array<any>;
};

export type MessageStatsOverviewType = {
  id: number;
  subject: MultiLanguageString;
  messageType: MessageType;
  triggered: boolean;
  createdAt: string;
  scheduledDate: string | null | undefined;
  sentDate: string | null | undefined;
  expiresInDays: number;
  totalSent: number;
  totalPushed: number;
  totalRead: number;
  totalCollected: number;
  numUsers: number | null | undefined;
  numTargetedUsers: number | null | undefined;
  messageState: MessageStateType;
};

async function getByBpId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${MESSAGES}`,
    'GET',
    {}
  );
  return result.body;
}

async function getByMessageId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${MESSAGES}/${params.messageId}`,
    'GET',
    {
      queryParams: {
        resolution: params.resolution,
        startDate: params.startDate,
        endDate: params.endDate
      }
    }
  );
  return result.body;
}

async function getByBusinessProfileId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${MESSAGES}`,
    'GET',
    {
      queryParams: {
        pageNumber: params.pageNumber
      }
    }
  );
  return result;
}

export default {
  getByBpId,
  getByMessageId,
  getByBusinessProfileId
};
