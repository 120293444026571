import * as React from 'react';

import MuiFormLabel from '@mui/material/FormLabel';

import { TranslationObject } from 'serviceNew/locale';

import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';

type Props = {
  label: TranslationObject,
  required: boolean,
  error: boolean
} & LocaleProps;

function FormLabel(props: Props) {
  const { label, translate, required, error } = props;
  return (
    <MuiFormLabel {...{ error }}>
      {translate(label.key, label.context) + (required ? ' *' : '')}
    </MuiFormLabel>
  );
}

FormLabel.defaultProps = {
  required: false,
  error: false,
};

export default withLocale(FormLabel);
