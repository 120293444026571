import {MyTheme} from 'theme/muiTheme';
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles<MyTheme>(theme => ({
  root: {},
  button: {
    width: '100%',
    textTransform: 'none',
    borderRadius: 4,
    height: 55,
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: '1rem'
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  item: {
    padding: `${theme.spacing(0.5, 0, 0)}!important`
  }
}));
