import * as React from 'react';
import { makeStyles } from '@mui/styles'
import {
  Languages,
  Language,
  TranslationObject,
  defaultLanguage
} from 'serviceNew/locale';
import Text from './Text';
import TabView from './TabView';
import { useLocale } from 'App/providers/LocaleProvider';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1
  },
  title: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  },
  content: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  tabs: {
    textTransform: 'uppercase'
  }
}));

type MultiLanguageInputProps = {
  title?: TranslationObject,
  noPadding?: boolean,
  children: any,
  languages?: Language[],
  language?: Language
};

const MultiLanguageInputForm = ({
  title,
  children,
  noPadding,
  languages,
  ...other
}: MultiLanguageInputProps) => {
// @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language: contextLanguage } = useLocale();
  const [language, setLanguage] = React.useState(other.language || contextLanguage);
  const classes = useStyles();
  const availableLanguages = Object.keys(Languages);

  const handleChange = (lang: Language) => {
    setLanguage(lang);
  };

  return (
    <div className={noPadding ? undefined : classes.container}>
      {title && (
        <Text className={classes.title} variant="subtitle1" context={title.context}>
          {title.key}
        </Text>
      )}

      <TabView
        tabClassName={classes.tabs}
// @ts-expect-error - TS2322 - Type '{ tab: string; label: { key: string; context: { value: string; }; }; }[]' is not assignable to type 'TabType<"en" | "de" | "fr" | "it">[]'.
        tabs={(languages || availableLanguages).map(lang => ({
          tab: lang,
          label: { key: 'text.no.translation', context: { value: lang } }
        }))}
        value={language}
        onChange={handleChange}
      />

      <MultiLanguageContext.Provider value={{ language }}>
        <div className={noPadding ? undefined : classes.content}>{children}</div>
      </MultiLanguageContext.Provider>
    </div>
  );
};

const MultiLanguageContext = React.createContext({ language: defaultLanguage });

export const useMultiLanguage = () => React.useContext(MultiLanguageContext);

export default MultiLanguageInputForm;
