import * as React from 'react';
import { createStyles, withStyles, WithStyles } from '@mui/styles';

const styles = createStyles(theme => ({
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.grey[300],
    position: 'absolute',
    left: 0,
    marginTop: theme.extra.spacing.small + theme.extra.spacing.tiny,
    marginBottom: theme.extra.spacing.small,
    display: 'block',
    top: 'auto',
  },
  placeholder: {
    marginTop: theme.extra.spacing.small + theme.extra.spacing.tiny,
    marginBottom: theme.extra.spacing.small,
    width: '100%',
    height: 1,
  },
}));

type Props = WithStyles<typeof styles>;

function Separator(props: Props) {
  const { classes } = props;
  return (
    <>
{ /* @ts-expect-error - TS2339 - Property 'separator' does not exist on type 'ClassNameMap<never>'. */}
      <div className={classes.separator} />
{ /* @ts-expect-error - TS2339 - Property 'placeholder' does not exist on type 'ClassNameMap<never>'. */}
      <div className={classes.placeholder} />
    </>
  );
}

export default withStyles(styles)(React.memo(Separator));
