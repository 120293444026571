import { PoinzApiClient } from '../../api';

const apiRoot = '/websocket-notification/tickets';

const get = (apiClient: PoinzApiClient) => async (): Promise<{ ticket: string }> => {
  const response = await apiClient.get(apiRoot, {});
  return response.body;
};

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient)
});
