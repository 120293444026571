import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'flexBasis' does not exist on type '{}'.
    flexBasis: ({ flexBasis }) => flexBasis,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0
  }
}));

type Props = {
  children: React.ReactNode;
  flexBasis?: number;
};

function FlexItem(props: Props) {
  const { children, flexBasis } = props;

  const classes = useStyles({ flexBasis });

  return <div className={classes.container}>{children}</div>;
}

FlexItem.defaultProps = {
  minWidth: 0
};

export default FlexItem;
