import { PaymentMethod } from 'partnerClient/datatrans/types';
import { IUserAddress } from 'partnerClient/user/types';
import { ICartItemType, ICashbackType } from './cart';
import { ScaledImage } from 'types';

export enum LOYALTY_STATUS {
  NOT_USED = 'NOT_USED',
  REQUESTED = 'REQUESTED',
  RESERVED = 'RESERVED'
}

export interface ReceiptItem {
  id: number;
  giftCardId: number;
  dealId: number;
  dealOptionId: number;
  name: string;
  picture: ScaledImage;
  itemType: ICartItemType;
  quantity: number;
  cashback: number;
  cashbackType: ICashbackType;
  price: number;
}

export interface IReceipt {
  id: number;
  userId: number;
  cartId: number;
  totalPrice: number;
  finalPrice: number;
  loyaltyStatus: LOYALTY_STATUS;
  loyaltyCash: number;
  referenceNumber: string;
  deliveryAddress: IUserAddress;
  invoiceAddress: IUserAddress;
  paymentMethod: PaymentMethod;
  items: Array<ReceiptItem>;
  createdAt: string;
}
