import React from 'react';
import ActivityTimeLineInput from '../../../../components/ActivityTimelineInput';
import { DashboardCardHeader } from '../../../../components/DashboardCard';

const PoinzStatsHeader = (props: any) => (
  <DashboardCardHeader right>
    <ActivityTimeLineInput {...props} />
  </DashboardCardHeader>
);

export default PoinzStatsHeader;
