/* eslint-disable @typescript-eslint/no-explicit-any */
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import isNill from 'lodash/isNil';
import qs from 'qs';

import {
  ApiError,
  ProblemApiError,
  NewPaginationModel,
  Params,
  ApiMethod,
  API_METHOD,
  Result,
  PaginatedParams,
  AgentHeader,
  PoinzClientParams,
  UrlParams
} from './types';

export const buildAgentHeader = (agentHeader: AgentHeader): string => {
  function encodeValue(x: string) {
    return x.replace(/[\s/]/g, '_');
  }
  return map(
    omitBy(agentHeader, v => v == null),
    (v: string, k) => `${encodeValue(k)}/${encodeValue(v)}`
  ).join(' ');
};

async function errorHandler(response: Response) {
  // convert to .text and then parse it if response exist
  // otherwise it might crash if there is no response
  const data = await response.text();
  const error = data ? JSON.parse(data) : {};
  const headers = response.headers;
  const isProblemError = headers.get('content-type') === 'application/problem+json';

  if (isProblemError) {
    throw new ProblemApiError(error);
  }

  const apiError = error.message ? error : { message: JSON.stringify(error, null, 2) };

  throw new ApiError(apiError);
}

export function getPaginatedResult(result: Result) {
  if (result.body == null) {
    throw new Error('Body should not be null for a paginated call.');
  }
  const { body, headers: responseHeaders } = result;

  if (body.content) {
    return {
      ...body,
      body: body.content,
      headers: responseHeaders,
      pageNumber: body.pageable.pageNumber
    };
  }

  const retrievedPageNumber = parseInt(result.headers.get('page-number') as string, 10);
  const totalPages = parseInt(result.headers.get('total-pages') as string, 10);
  const totalElements = parseInt(result.headers.get('total-elements') as string, 10);
  return {
    body,
    content: body,
    headers: responseHeaders,
    pageNumber: retrievedPageNumber,
    totalPages,
    totalElements
  };
}

export function setAuthorizationToken(headers: Headers, token: string) {
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  } else {
    headers.delete('Authorization');
  }
  return headers;
}

export class PoinzApiClient {
  private agent: string;

  private language: string;

  private apiRoot: string;

  private errorHandler?: (response: Response) => Promise<void>;

  private setAuthenticatedHeaders: (headers: Headers, endpoint?: string) => Promise<Headers>;

  private headers: Headers;

  constructor(params: PoinzClientParams) {
    this.agent = params.agent;
    this.language = params.language;
    this.apiRoot = params.apiRoot;
    this.errorHandler = params.errorHandler || errorHandler;
    this.setAuthenticatedHeaders = params.setAuthenticatedHeaders;
    this.headers = new Headers();
  }

  changeLanguage(language: string) {
    if (language) {
      this.language = language;
    }
  }

  setHeaders(headers: Headers) {
    this.headers = headers;
  }

  getHeaders() {
    return this.headers;
  }

  getUrl = (rawPath: string, { pathParams = {}, queryParams = {} }: UrlParams) => {
    let path = rawPath;
    const pathParamsList = Object.entries(pathParams);
    if (pathParamsList.length > 0) {
      pathParamsList.forEach(([key, value]) => {
        path = path.replace(`:${key}`, String(value));
      });
    }
    const cleanQueryParams = omitBy(queryParams, isNill);
    const searchString = qs.stringify(cleanQueryParams, { arrayFormat: 'comma' });
    return this.apiRoot + path + (searchString.length === 0 ? '' : `?${searchString}`);
  };

  setAuthenthicatedHeaders = (token: string) => {
    const newHeaders = setAuthorizationToken(this.headers, token);
    this.setHeaders(newHeaders);
  };

  async get(endpoint: string, params: Params) {
    const { authenticated = true, queryParams = {}, ...restParams } = params;
    const res = await this.callEndpoint(endpoint, API_METHOD.GET, {
      authenticated,
      queryParams,
      ...restParams
    });
    const queryKeys = Object.keys(queryParams);
    if (queryKeys.includes('page') || queryKeys.includes('pageNumber')) {
      return getPaginatedResult(res);
    }
    return res;
  }

  async post(endpoint: string, params: Params) {
    const { authenticated = true, ...restParams } = params;
    const res = await this.callEndpoint(endpoint, API_METHOD.POST, {
      authenticated,
      ...restParams
    });

    return res;
  }

  async put(endpoint: string, params: Params) {
    const { authenticated = true, ...restParams } = params;
    const res = await this.callEndpoint(endpoint, API_METHOD.PUT, {
      authenticated,
      ...restParams
    });

    return res;
  }

  async delete(endpoint: string, params: Params) {
    const { authenticated = true, ...restParams } = params;
    const res = await this.callEndpoint(endpoint, API_METHOD.DELETE, {
      authenticated,
      ...restParams
    });

    return res;
  }

  async patch(endpoint: string, params: Params) {
    const { authenticated = true, ...restParams } = params;
    const res = await this.callEndpoint(endpoint, API_METHOD.PATCH, {
      authenticated,
      ...restParams
    });

    return res;
  }

  async callEndpoint(endpoint: string, method: ApiMethod, params: Params): Promise<Result> {
    const {
      headers = new Headers(),
      queryParams,
      body,
      isMultiPart,
      isBinary,
      pathParams,
      authenticated
    } = params;

    const fetchInit: Record<string, any> = {};
    fetchInit.method = method;
    fetchInit.headers = headers;

    headers.set('Accept-Language', this.language);
    headers.set('Poinz-Agent', this.agent);

    if (authenticated && typeof this.setAuthenticatedHeaders === 'function') {
      await this.setAuthenticatedHeaders(headers, endpoint);
      const token = this.headers.get('Authorization');
      if (token) {
        headers.set('Authorization', token);
      }
    }

    if (body) {
      if (isMultiPart) {
        fetchInit.body = body as any;
      } else {
        headers.append('Content-type', 'application/json');
        fetchInit.body = JSON.stringify(body);
      }
    }

    const url = this.getUrl(endpoint, { queryParams, pathParams });
    const response = await fetch(url, fetchInit);
    if (!response.ok && typeof this.errorHandler === 'function') {
      await this.errorHandler(response);
    }
    const result: Result = {
      headers: response.headers
    };
    const contentType = response.headers.get('content-type');
    const contentLength = response.headers.get('content-length');
    if (isBinary) {
      result.blob = await response.blob();
    }
    if (contentType && contentType.indexOf('application/json') !== -1 && contentLength !== '0') {
      const recievedBody = await response.json();
      result.body = recievedBody;
    }

    return result;
  }

  async callAuthenticatedEndpoint(endpoint: string, method: ApiMethod, params?: Params) {
    const { headers = new Headers() } = params || {};
    const authenticatedHeaders = await this.setAuthenticatedHeaders(headers, endpoint);

    return this.callEndpoint(endpoint, method, {
      ...params,
      authenticated: true,
      headers: authenticatedHeaders
    });
  }

  async callPaginatedEndpoint<T>(
    page: number | undefined,
    endpoint: string,
    params: PaginatedParams = {}
  ): Promise<NewPaginationModel<T>> {
    const { queryParams = {} } = params;
    const result = await this.callAuthenticatedEndpoint(endpoint, API_METHOD.GET, {
      queryParams: {
        ...queryParams,
        pageNumber: page
      }
    });
    return getPaginatedResult(result);
  }
}

export function getIdFromHeaders(headers: Headers): number {
  const locationHeader = headers.get('location');
  if (!locationHeader) {
    throw new Error('Location not present in headers.');
  }
  const splitted = locationHeader.split('/');
  try {
    return parseInt(splitted[splitted.length - 1], 10);
  } catch (e) {
    throw new Error('Location header does not contain a valid id.');
  }
}

export const createMultipartRequest = (
  formName: string,
  formData: Record<string, any> | Record<string, any>[],
  multiPartData: Array<{ name: string; data: any }>
): FormData => {
  const request = new FormData();
  request.append(
    formName,
    new Blob(
      [
        JSON.stringify(
          Array.isArray(formData)
            ? formData
            : mapValues(formData, v => (v === undefined ? null : v))
        )
      ],
      {
        type: 'application/json'
      }
    )
  );
  if (multiPartData) {
    multiPartData.forEach(p => {
      if (Array.isArray(p.data)) {
        p.data.forEach(v => request.append(p.name, v));
      } else {
        request.append(p.name, p.data);
      }
    });
  }

  return request;
};
