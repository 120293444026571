import { TranslationObject } from 'serviceNew/locale';
import { ScaledImage } from '@poinz/api';

export type Option<T> = {
  label: TranslationObject;
  value: T;
  subtitle?: TranslationObject;
};

export type Gender = 'FEMALE' | 'MALE';

export const Genders = {
  FEMALE: 'FEMALE',
  MALE: 'MALE'
} as const;

export const GenderTranslations = {
  FEMALE: 'general.female',
  MALE: 'general.male'
} as const;

export type Picture = {
  sourceUrl: string;
  width: number;
  height: number;
  x: number;
  y: number;
  imageWidth: number;
  imageHeight: number;
  croppedPreview: string;
  mainColor: string;
};

export type NewPicture = {
  file: File;
  x: number;
  y: number;
  width: number;
  height: number;
};

type OrderedPicture<K> = {
  id: string;
  index: number;
} & K;

const PERIOD_TYPE = {
  WEEKLY: 'general.weekly',
  MONTHLY: 'general.monthly'
} as const;

export type OrderedScaledPicture = OrderedPicture<ScaledImage>;
export type OrderedNewPicture = OrderedPicture<NewPicture>;
export type PictureUpdate = NewPicture | ScaledImage;
export type OrderedPictureUpdate = OrderedNewPicture | OrderedScaledPicture;
export type PeriodType = keyof typeof PERIOD_TYPE;

export function readImage(fileUrl: string): Promise<HTMLImageElement> {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.src = fileUrl;
  });
}
