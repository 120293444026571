import * as React from 'react';

import Button from './Button';

type Props = {
  onClick: (arg1: React.SyntheticEvent) => Promise<void>;
} & Diff<
  JSX.LibraryManagedAttributes<typeof Button, React.ComponentProps<typeof Button>>,
  {
    onClick?: (arg1: React.SyntheticEvent) => unknown;
  }
>;

type State = {
  loading: boolean;
};

class AsyncButton extends React.PureComponent<Props, State> {
  static defaultProps = {
    className: '',
    variant: 'text'
  };

  state = {
    loading: false
  };

  handleClick = async (e: React.SyntheticEvent) => {
    const { onClick } = this.props;

    this.setState({ loading: true });
    await onClick(e);
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return <Button {...this.props} onClick={this.handleClick} {...{ loading }} />;
  }
}

export default AsyncButton;
