import Compressor from 'compressorjs';

const MAX_DIMENSION = 3072;
const MAX_SIZE = 4 * 1024 * 1024;

function blobToFile(
  blob: Blob & {
    name: string;
    lastModified: number;
  }
): File {
  return new File([blob], blob.name, { lastModified: blob.lastModified, type: blob.type });
}

export default async function reduceFileSize(file: File, quality = 0.8): Promise<File> {
  if (file.size <= MAX_SIZE) {
    return file;
  }
  const compressedFile = await new Promise(
    (resolve: (result: Promise<File> | File) => void, reject: (error?: any) => void) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        maxWidth: MAX_DIMENSION,
        maxHeight: MAX_DIMENSION,
        quality,
        // @ts-expect-error - TS2345 - Argument of type 'Blob | File' is not assignable to parameter of type 'Blob & { name: string; lastModified: number; }'.
        success: blob => resolve(blobToFile(blob)),
        error: reject
      });
    }
  );
  return reduceFileSize(compressedFile, quality * 0.95);
}
