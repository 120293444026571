import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-router-dom"' has no exported member 'ContextRouter'.
import { withRouter, ContextRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { MarketingCampaignStats } from 'serviceNew/model/marketingCampaignStats';
import API from 'serviceNew/api/marketingCampaignStats';
import { formatDateTime } from 'serviceNew/locale/dates';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import { PaginationModel, defaultPaginatedResult } from 'serviceNew/api/api';
import { Table, SelectionList } from 'App/components';
import { withContractCompany, ContractCompanyProps } from 'App/providers/ContractCompanyProvider';
import { parseEntityIdFromRequestParameters } from '@poinz/utils';
import NavigationHeader from 'App/components/NavigationHeader';
import routes from 'App/routing';
import { MarketingCampaignDetailStats } from './components';

const columns = [
  {
    header: { key: 'general.id' },
    dataMapper: 'id'
  },
  {
    header: { key: 'general.startDate' },
    // @ts-expect-error - TS2339 - Property 'startDateTime' does not exist on type 'never'.
    dataMapper: (campaign: MarketingCampaignStats) => formatDateTime(campaign.startDateTime)
  },
  {
    header: { key: 'general.endDate' },
    // @ts-expect-error - TS2339 - Property 'endDateTime' does not exist on type 'never'.
    dataMapper: (campaign: MarketingCampaignStats) => formatDateTime(campaign.endDateTime)
  },
  {
    header: { key: 'marketingCampaign.global' },
    dataMapper: 'isGlobal'
  },
  {
    header: { key: 'marketingCampaign.totalPlayer' },
    dataMapper: 'totalParticipant'
  },
  {
    header: { key: 'marketingCampaign.totalWinner' },
    dataMapper: 'totalWinner'
  },
  {
    header: { key: 'marketingCampaign.totalWinnerNotCollected' },
    dataMapper: 'totalWinnerNotCollected'
  }
];

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  fab: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    marginLeft: theme.extra.spacing.small
  },
  row: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    marginBottom: theme.extra.spacing.small,
    display: 'flex',
    justifyContent: 'space-between'
  },
  select: {
    maxWidth: 250
  },
  table: {
    // tableLayout: 'fixed',
  }
}));

type Props = {
  error?: string;
} & ContractCompanyProps &
  ContextRouter;

function MarketingCampaignStatsUnconnected(props: Props) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [marketingCampaignStats, setMarketingCampaignStats] = React.useState<
    PaginationModel<MarketingCampaignStats>
  >(defaultPaginatedResult as any);
  const { history, error, location } = props;

  const classes = useStyles();

  const { dataList, pageNumber, totalElements, totalPages } = marketingCampaignStats;

  const { businessProfileId: selectedBusinessProfileId, businessProfiles } = useBusinessProfile();

  const businessProfileId = parseEntityIdFromRequestParameters(
    location.search,
    'businessProfileId'
  );
  const loadData = React.useCallback(async () => {
    if (businessProfileId != null) {
      setLoading(true);
      const retrievedMarketingCampaignStats = await API.getMarketingCampaignStats(
        businessProfileId,
        {
          pageNumber
        }
      );
      setMarketingCampaignStats(retrievedMarketingCampaignStats);
    }
    setLoading(false);
  }, [businessProfileId, pageNumber]);
  React.useEffect(
    () => {
      if (businessProfileId == null || !businessProfiles.some(bp => bp.id === businessProfileId)) {
        let newBusinessProfileId = selectedBusinessProfileId;
        if (
          newBusinessProfileId == null &&
          businessProfiles != null &&
          businessProfiles.length > 0
        ) {
          newBusinessProfileId = businessProfiles[0].id;
        }
        if (newBusinessProfileId != null) {
          history.push({ search: `businessProfileId=${newBusinessProfileId}` });
        }
      }
    },
    // we do not care if history changes and it is thus ok to use it inside the hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessProfileId, selectedBusinessProfileId, businessProfiles]
  );

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // TODO: display some error
  if (businessProfileId == null) {
    return null;
  }

  async function handlePageChange(toPage: number) {
    if (toPage <= totalPages && toPage >= 0) {
      setMarketingCampaignStats({ ...marketingCampaignStats, pageNumber: toPage });
    }
  }

  function renderTable() {
    if (!marketingCampaignStats) {
      return null;
    }
    return (
      <Table
        loading={loading}
        emptyContext={{ label: 'marketingCampaigns' }}
        error={error}
        data={dataList}
        DetailComponent={MarketingCampaignDetailStats}
        pagination={{
          rowsPerPage: 10,
          pageNumber,
          totalElements,
          handlePageChange
        }}
        tableClassName={classes.table}
        {...{ columns }}
      />
    );
  }

  function renderAction() {
    return (
      <SelectionList
        id={businessProfileId}
        array={businessProfiles}
        onChange={e => history.push({ search: `businessProfileId=${e.target.value}` })}
        withEmpty={false}
        renderName={e => e.name}
      />
    );
  }

  const navigationRoutes = {
    [routes.dashboard.getMatch()]: {
      breadcrumbLabel: { key: 'sidebar.dashboard' },
      disabled: true
    },
    [routes.dashboard.marketingCampaigns.getMatch()]: {
      currentPathLabel: { key: 'sidebar.dashboard.campaigns' },
      disabled: true,
      hideBackButton: true,
      action: renderAction
    }
  } as const;

  return (
    <>
      {/* @ts-expect-error - TS2322 - Type '{ navigationRoutes: { readonly [x: number]: { readonly breadcrumbLabel: { readonly key: "sidebar.dashboard"; }; readonly disabled: true; readonly currentPathLabel?: undefined; readonly hideBackButton?: undefined; readonly action?: undefined; } | { ...; }; }; }' is not assignable to type 'Props'. */}
      <NavigationHeader {...{ navigationRoutes }} />
      {renderTable()}
    </>
  );
}

export default withRouter(withContractCompany(MarketingCampaignStatsUnconnected) as any);
