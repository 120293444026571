import React from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { IdType } from '@poinz/api';
import useEntityOrLoad from 'hooks/useEntityOrLoad';
import { useLocale } from 'App/providers/LocaleProvider';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import { useLocation } from 'App/providers/LocationProvider';

import dealApi from 'serviceNew/api/deal';
import dealStatsApi from 'serviceNew/api/dealStats';
import { DealDetailsStats } from 'serviceNew/model/dealStats';
import { Deal, DEAL_TYPE } from 'serviceNew/model/deal';
import { DataDisplayBox } from 'App/components';
import Alert, { ALERT_TYPE } from 'App/components/Alert';
import clsx from 'clsx';
import { formatPrice } from '@poinz/utils';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import DealStatsActivity from '../components/DealStatsActivity';
import DealStatsRowListBox from '../components/DealStatsRowListBox';
import { useSessionStorage } from '@poinz/hooks';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-1),
    flexWrap: 'wrap'
  },
  rowItem: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1)
  },
  chart: {
    flex: 2.5,
    flexBasis: 500
  },
  table: {
    flex: 1,
    flexBasis: 200
  },
  dataBox: {
    display: 'flex',
    flex: 1,
    flexBasis: 200
  },
  alert: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  },
  details: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: theme.spacing(3)
  }
}));

function DealStatsDetails() {
  const classes = useStyles();

  const { translate, formatNumber } = useLocale();
  const { isPoinzAdmin } = useAdminUser();

  const { businessProfileId } = useBusinessProfile();
  const { locations } = useLocation();

  // @ts-expect-error - TS2339 - Property 'id' does not exist on type '{}'. | TS2339 - Property 'dealRuleId' does not exist on type '{}'.
  const { id: dealId, dealRuleId } = useParams();

  const [deal, setDeal] = React.useState<Deal | null | undefined>(null);
  const [dealRuleStatDetails, setDealRuleStatDetails] = React.useState<
    DealDetailsStats | null | undefined
  >(null);
  const [detailStatsLoading, setDetailStatsLoading] = React.useState<boolean>(false);
  const [infoMessageDismissed, setInfoMessageDismissed] = useSessionStorage({
    key: 'deal_dashboard_details_info',
    initialValue: false
  });

  const isCoupon = React.useMemo(() => deal?.dealType === DEAL_TYPE.COUPON, [deal]);

  const getDealStatsOverview = React.useCallback(
    async (id: IdType) => {
      if (businessProfileId) {
        const retrievedDealStats = await dealStatsApi.getDealOverviewStatsForDealId({
          id: businessProfileId,
          dealId: id
        });
        return retrievedDealStats;
      }
      return null;
    },
    [businessProfileId]
  );

  const [dealStat, , overviewStatsLoading] = useEntityOrLoad(
    'dealStatOverview',
    getDealStatsOverview,
    null,
    'dealId'
  );

  const dealRuleStat = React.useMemo(() => {
    if (dealRuleId) {
      const statArray = dealStat?.dealRuleStats || [];
      return statArray.find(element => element.dealRuleId === parseInt(dealRuleId, 10));
    }
    return dealStat;
  }, [dealRuleId, dealStat]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        const retrievedDeal = await dealApi.getDeal(dealId, businessProfileId);
        setDeal(retrievedDeal);
        setDetailStatsLoading(true);
        const retrievedDealStatsDetails = await dealStatsApi.getDealDetailStatsForDealId({
          // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number'.
          id: businessProfileId,
          dealId,
          dealRuleId
        });
        setDealRuleStatDetails(retrievedDealStatsDetails);
      } finally {
        setDetailStatsLoading(false);
      }
    }
    if (dealId && businessProfileId) {
      fetchData();
    }
  }, [businessProfileId, dealId, dealRuleId, dealRuleStat, dealStat]);

  function renderDetailStats() {
    if (detailStatsLoading) {
      // render loading component
      return null;
    }

    return (
      <div className={classes.details}>
        {!infoMessageDismissed && (
          <Alert
            className={classes.alert}
            // @ts-expect-error - TS2345 - Argument of type 'true' is not assignable to parameter of type 'SetStateAction<false>'.
            onClose={() => setInfoMessageDismissed(true)}
            severity={ALERT_TYPE.INFO}
            label={{ key: 'stats.deals.details.info' }}
          />
        )}

        <div className={classes.rowContainer}>
          <DealStatsActivity
            title={{ key: 'stats.timeRedemptions' }}
            className={clsx(classes.chart, classes.rowItem)}
            activityTimeline={dealRuleStatDetails?.data || []}
            dealOptions={deal?.dealOptions}
            {...{ isCoupon, locations }}
          />
          <DealStatsRowListBox
            className={clsx(classes.rowItem, classes.table)}
            locations={locations}
            data={dealRuleStatDetails?.data || []}
          />
        </div>
      </div>
    );
  }

  function renderOverviewStats() {
    if (overviewStatsLoading) {
      // render loading component
      return null;
    }

    if (dealRuleStat) {
      return (
        <div className={classes.rowContainer}>
          <DataDisplayBox
            className={clsx(classes.rowItem, classes.dataBox)}
            icon="shopping_cart"
            label={{ key: 'deal.purchases' }}
            value={dealRuleStat.numPurchased}
          />
          <DataDisplayBox
            className={clsx(classes.rowItem, classes.dataBox)}
            icon="phone_iphone"
            label={{ key: 'deal.redemptions' }}
            value={dealRuleStat.numRedeemed}
          />
          {!isCoupon && (
            <>
              <DataDisplayBox
                className={clsx(classes.rowItem, classes.dataBox)}
                icon="straighten"
                label={{ key: 'stats.deals.commissionFee' }}
                value={
                  dealRuleStat.commissionFee
                    ? formatNumber({ value: dealRuleStat.commissionFee / 100, style: 'percent' })
                    : translate('stats.deals.noCommissionFee')
                }
              />
              {isPoinzAdmin && (
                <DataDisplayBox
                  className={clsx(classes.rowItem, classes.dataBox)}
                  icon="attach_money"
                  label={{ key: 'deal.revenue' }}
                  value={
                    dealRuleStat.revenueRedeemed
                      ? formatPrice(dealRuleStat.revenueRedeemed || 0, true)
                      : translate('stats.deals.noCommissionFee')
                  }
                />
              )}
            </>
          )}
        </div>
      );
    }
    return null;
  }

  if (deal == null || dealStat == null || dealRuleStat == null) {
    // handle error case
    return <div />;
  }

  return (
    <div>
      {renderOverviewStats()}
      {renderDetailStats()}
    </div>
  );
}

export default DealStatsDetails;
