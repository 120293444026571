import { useQuery, UseQueryResult } from 'react-query';
import { PaginatedTransactionParams, PartnerClient, Transaction } from '../../../partnerClient';
import { NewPaginationModel } from '../../../types';

const KEY = 'TRANSACTIONS';

const useGetPaginatedList =
  (apiClient: PartnerClient) =>
  (params: PaginatedTransactionParams): UseQueryResult<NewPaginationModel<Transaction>, any> => {
    return useQuery([KEY, params], () => apiClient.loyalty.transaction.getPaginatedList(params));
  };

const useExportCSV =
  (apiClient: PartnerClient) =>
  (params: PaginatedTransactionParams): UseQueryResult<Blob | undefined, any> => {
    return useQuery(
      [KEY, { csv: true }, params],
      () => apiClient.loyalty.transaction.exportCsv(params),
      { enabled: false, refetchOnWindowFocus: false }
    );
  };

export default (client: PartnerClient) => ({
  useExportCSV: useExportCSV(client),
  useGetPaginatedList: useGetPaginatedList(client)
});
