import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { MessageStatsOverviewType, MessageStatsDetailsType } from 'serviceNew/api/messageStats';
import { moment } from 'serviceNew/locale/dates';
import { Language } from 'serviceNew/locale';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { ResolutionType } from '../../../../../constants/general';
import { LoadingTable, EmptyTable } from '../../components/TableElements';
import MessageStatsTableHeader from './MessageStatsTableHeader';
import MessageStatsTableBody from './MessageStatsTableBody';

const styles = () => ({
  chipCol: {
    width: '10%',
    maxWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  firstCol: {
    maxWidth: 0,
    width: '18%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  idCol: {
    maxWidth: 0,
    width: '6%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  middleCol: {
    maxWidth: 0,
    width: '13.2%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  selectedMessage: {
    fontWeight: 500,
    background: '#eee'
  },
  paperSelected: {
    marginTop: 12,
    marginBottom: 12
  }
});

export type MessageStatsTableStylesProps = Record<any, any> & WithStyles<typeof styles>;

export type MessageStatsDetailsProps = {
  messageDetails: MessageStatsDetailsType;
  resolution: ResolutionType;
};

export type HandleSelectMessageType = (arg1: {
  id: number;
  startDate: string;
  endDate: moment.Moment | null | undefined;
}) => void;

export type MessageStatsTableBodyProps = {
  selectedMessage: number;
  message: MessageStatsOverviewType;
  handleSelectMessage: HandleSelectMessageType;
  lang: Language;
  detailLoading: boolean;
} & MessageStatsDetailsProps;

type MessageStatsTableProps = {
  messageStatsOverview: Array<MessageStatsOverviewType>;
  overviewLoading: boolean;
} & MessageStatsTableStylesProps &
  MessageStatsTableBodyProps &
  LocaleProps;

class MessageStatsTableUnconnected extends React.PureComponent<MessageStatsTableProps> {
  render() {
    const {
      messageStatsOverview,
      classes,
      lang,
      detailLoading,
      resolution,
      overviewLoading,
      selectedMessage,
      handleSelectMessage,
      messageDetails,
      formatMessage,
      formatNumber
    } = this.props;
    const { chipCol, firstCol, middleCol } = classes;
    const messageStatsTableHeaderClasses = { chipCol, firstCol, middleCol } as const;
    // if still loading
    if (overviewLoading) {
      return (
        <>
          {/* @ts-expect-error - TS2739 - Type '{ readonly chipCol: string; readonly firstCol: string; readonly middleCol: string; }' is missing the following properties from type 'ClassNameMap<"chipCol" | "firstCol" | "idCol" | "middleCol" | "selectedMessage" | "paperSelected">': idCol, selectedMessage, paperSelected */}
          <MessageStatsTableHeader classes={messageStatsTableHeaderClasses} />
          <LoadingTable colspan={8} />
        </>
      );
    }
    // if there are not messageStatsOverview
    if (!messageStatsOverview.length) {
      return (
        <>
          {/* @ts-expect-error - TS2322 - Type '{ readonly chipCol: string; readonly firstCol: string; readonly middleCol: string; }' is not assignable to type 'ClassNameMap<"chipCol" | "firstCol" | "idCol" | "middleCol" | "selectedMessage" | "paperSelected">'. */}
          <MessageStatsTableHeader classes={messageStatsTableHeaderClasses} />
          <EmptyTable colspan={8} />
        </>
      );
    }
    // if it is not loading, and there are messageStatsOverview
    return (
      <>
        {/* @ts-expect-error - TS2322 - Type '{ readonly chipCol: string; readonly firstCol: string; readonly middleCol: string; }' is not assignable to type 'ClassNameMap<"chipCol" | "firstCol" | "idCol" | "middleCol" | "selectedMessage" | "paperSelected">'. */}
        <MessageStatsTableHeader classes={messageStatsTableHeaderClasses} />
        {messageStatsOverview.map(m => (
          <MessageStatsTableBody
            key={m.id}
            message={m}
            classes={classes}
            selectedMessage={selectedMessage}
            handleSelectMessage={handleSelectMessage}
            lang={lang}
            formatMessage={formatMessage}
            formatNumber={formatNumber}
            detailLoading={detailLoading}
            messageDetails={messageDetails}
            resolution={resolution}
          />
        ))}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withLocale(MessageStatsTableUnconnected));
