import { PoinzApiClient } from '../../api';
import adminUser from './adminUser';
import mobileUser from './mobileUser';
import user from './user';
import addresses from './addresses';

const UserClient = (apiClient: PoinzApiClient) => ({
  adminUser: adminUser(apiClient),
  mobileUser: mobileUser(apiClient),
  userGeneral: user(apiClient),
  addresses: addresses(apiClient)
});

export default UserClient;
