import * as React from 'react';

import { TranslationObject } from 'serviceNew/locale';

import { FormRow, LabeledValue } from 'App/components';

type Field<T, E> = {
  label: TranslationObject;
  propertyName?: T;
  render?: (entity: E) => React.ReactElement;
  component?: React.ComponentType<{
    entity: E;
  }>;
  hide?: (entity: E) => boolean;
};

type Props<T, E> = {
  entity: E;
  fields: Field<T, E>[];
  isRow?: boolean;
};

function DisplayEntity<T extends string, E extends Partial<Record<T, any>>>(props: Props<T, E>) {
  const { entity, fields, isRow } = props;

  function renderFieldValue(field: Field<T, E>) {
    const { propertyName, render, component: Component } = field;
    if (render != null) {
      return render(entity);
    }
    if (Component != null) {
      return <Component {...{ entity }} />;
    }
    if (propertyName != null) {
      return entity[propertyName];
    }
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('Either render, component or propertyName must be specified.');
    }
    return null;
  }

  function renderField(field: Field<T, E>) {
    const { label, hide } = field;
    if (hide != null && hide(entity)) {
      return null;
    }
    return (
      // @ts-expect-error - TS2322 - Type 'TranslationKey' is not assignable to type 'Key | null | undefined'.
      <FormRow key={label.key}>
        <LabeledValue label={label}>{renderFieldValue(field)}</LabeledValue>
      </FormRow>
    );
  }

  const Comp = React.useMemo(() => (isRow ? FormRow : 'div'), [isRow]);

  return <Comp>{fields.map(renderField)}</Comp>;
}

export default DisplayEntity;
