import * as React from 'react';

import { UserRole } from 'serviceNew/model/adminUser';
import { useAdminUser } from 'App/providers/AdminUserProvider';

type AuthScopeProps = {
  userRole: UserRole
};

function withAuthScope<Props extends AuthScopeProps, Comp extends React.ComponentType<Props>>(Component: Comp, propToModify: string): React.ComponentType<JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>> {
  return (compProps: JSX.LibraryManagedAttributes<Comp, React.ComponentProps<Comp>>) => {
    const originalValue = compProps[propToModify];

    const { userRole } = useAdminUser();

    const transformedProps = { ...compProps, [propToModify]: originalValue[userRole] } as const;
// @ts-expect-error - TS2322 - Type 'LibraryManagedAttributes<Comp, ComponentProps<Comp>> & { readonly [x: string]: any; }' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<Comp, Props>'.
    return <Component {...transformedProps} />;
  };
}

export default withAuthScope;
