import {getIdFromHeaders, createMultipartRequest} from '@poinz/api';
import { IdType } from '@poinz/api';
import { Offering } from 'serviceNew/model/offering';
import { callEndpoint } from './apiClient';
import { mapFormWithImages } from '../picture';

const ROOT = 'business-profiles';
const OFFERINGS = 'offerings';

function getRoot(businessProfileId: IdType) {
  return `/${ROOT}/${businessProfileId}/${OFFERINGS}`;
}

function createRequest(offering: Offering) {
  const multiPartData: Array<{
    data: File,
    name: string
  }> = [];
  const mappedForm = mapFormWithImages({ form: offering, imageFields: ['picture'], multiPartData });
  return createMultipartRequest('offeringForm', mappedForm, multiPartData);
}

async function getOffering(id: IdType): Promise<Offering> {
  const result = await callEndpoint(`/${OFFERINGS}/${id}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return result.body;
}

async function getOfferings(id: IdType): Promise<Offering[]> {
  const result = await callEndpoint(`/${ROOT}/${id}/${OFFERINGS}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return result.body;
}

type CreateOfferingParams = {
  businessProfileId: IdType,
  offering: Offering
};

async function createOfferingFromBusinessProfile(params: CreateOfferingParams): Promise<IdType> {
  const { businessProfileId, offering } = params;
  const formData = createRequest(offering);
  const result = await callEndpoint(getRoot(businessProfileId), 'POST', {
    authenticated: true,
    isMultiPart: true,
    body: formData
  });
  return getIdFromHeaders(result.headers);
}

type UpdateOfferingParams = {
  offeringId: IdType,
  businessProfileId: IdType,
  offering: Offering
};

async function updateOfferingFromBusinessProfile(params: UpdateOfferingParams): Promise<Offering> {
  const { offeringId, businessProfileId, offering } = params;
  const formData = createRequest(offering);
  const result = await callEndpoint(`${getRoot(businessProfileId)}/${offeringId}`, 'PUT', {
    authenticated: true,
    isMultiPart: true,
    body: formData
  });
  return result.body;
}

type DeleteOfferingParams = {
  offeringId: IdType,
  businessProfileId: IdType
};

async function deleteOffering(params: DeleteOfferingParams): Promise<void> {
  const { offeringId, businessProfileId } = params;
  await callEndpoint(`${getRoot(businessProfileId)}/${offeringId}`, 'DELETE', {
    authenticated: true
  });
}

export default {
  getOffering,
  getOfferings,
  createOfferingFromBusinessProfile,
  updateOfferingFromBusinessProfile,
  deleteOffering
};
