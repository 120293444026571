import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Languages, TranslationObject, MultiLanguageString } from 'serviceNew/locale';
import { useLocale } from 'App/providers/LocaleProvider';

import { FormError } from 'App/components';

import { allRequired, oneRequired } from 'serviceNew/validators';
import Field from '../Field';
import { useMultiLanguage } from '../MultiLanguageForm';

const useStyles = makeStyles({
  container: {
    flex: 1
  }
});

type Props = {
  label: TranslationObject;
  required?: boolean;
  allLangRequired?: boolean;
  className?: string;
  validate?: (value?: string | null | undefined) => TranslationObject | null | undefined;
  name: string;
  initialValue?: MultiLanguageString;
};

function MultiLanguageField(props: Props) {
  const { name, required, allLangRequired, label, className, initialValue, ...restProps } = props;
  const { translate } = useLocale();
  const classes = useStyles();
  const { language } = useMultiLanguage();

  const labelValue = `${translate(label.key, label.context)} - ${translate(Languages[language])}  ${
    required ? '*' : ''
  }`;

  const getValidator = () => {
    if (required || allLangRequired) {
      if (required && allLangRequired) {
        console.warn(
          'When required and allLangRequired are set to true, validation of allLangRequired is ignored'
        );
      }
      return required ? oneRequired : allRequired;
    }
    return undefined;
  };

  return (
    <div className={clsx(classes.container, className)}>
      <Field
        key={`${name}.${language}`}
        label={{ key: 'text.no.translation', context: { value: labelValue } }}
        name={`${name}.${language}`}
        initialValue={initialValue?.[language] || ''}
        {...restProps}
        validate={restProps.validate as any}
      />
      <Field
        name={name}
        validate={getValidator()}
        component={({ meta }: any) => <FormError {...meta} />}
        initialValue={{}}
      />
    </div>
  );
}

export default MultiLanguageField;
