import {IdType} from '@poinz/api';
import {
  MarketingCampaignStats,
  MarketingCampaignDetailStats
} from 'serviceNew/model/marketingCampaignStats';
import { callEndpoint, apiClient } from './apiClient';
import { getPaginatedResult, PaginationModel } from './api';

const ROOT = '/stats/business-profiles';
const MARKETING_CAMPAIGNS = 'marketing-campaigns';

async function getMarketingCampaignStats(
  businessProfileId: IdType,
  queryParams: {
    pageNumber?: number
  },
): Promise<PaginationModel<MarketingCampaignStats>> {
  const { pageNumber, ...restParams } = queryParams;
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${businessProfileId}/${MARKETING_CAMPAIGNS}`,
    { headers: new Headers(), queryParams: restParams }
  );
// @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<never>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

async function getDetailedMarketingCampaignStats(businessProfileId: IdType, marketingCampaignId: IdType): Promise<Array<MarketingCampaignDetailStats>> {
  const result = await callEndpoint(
    `${ROOT}/${businessProfileId}/${MARKETING_CAMPAIGNS}/${marketingCampaignId}`,
    'GET',
    { headers: new Headers(), authenticated: true }
  );
  return result.body;
}

export default {
  getMarketingCampaignStats,
  getDetailedMarketingCampaignStats
};
