import React, { ReactNode, ComponentType } from 'react';

import { UserRoleHierarchy, UserRole } from 'serviceNew/model/adminUser';

import { useAdminUser } from 'App/providers/AdminUserProvider';
import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import { useIsLoggedIn } from 'serviceNew/auth';

type Props = {
  roles?: Array<UserRole> | UserRole;
  children?: React.ReactElement;
  loggedIn?: boolean;
  elseComponent?: ComponentType<any> | null | undefined;
  deal?: boolean;
  loyalty?: boolean;
  communication?: boolean;
};

function RenderIf(props: Props) {
  const { deal, loyalty, communication, loggedIn, roles, children, elseComponent: Comp } = props;
  const { userRole } = useAdminUser();
  const { contractCompany, hasDealPermission, hasLoyaltyPermission, hasCommunicationPermission } =
    useContractCompany();
  const isLoggedIn = useIsLoggedIn();

  const loggedInCondition = loggedIn === undefined || loggedIn === isLoggedIn;
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
  const poinzRole = UserRoleHierarchy[userRole] <= UserRoleHierarchy.POINZ_ADMIN;
  const rolesCondition =
    !roles ||
    roles === userRole ||
    roles.includes(userRole) ||
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    (!Array.isArray(roles) && UserRoleHierarchy[userRole] <= UserRoleHierarchy[roles]);

  if (loggedInCondition && rolesCondition) {
    if (!poinzRole && contractCompany && (deal || loyalty)) {
      const dealsCondition = (deal && hasDealPermission) || !deal;
      const loyaltyCondition = (loyalty && hasLoyaltyPermission) || !loyalty;
      const comCondition = (communication && hasCommunicationPermission) || !communication;

      if (dealsCondition && loyaltyCondition && comCondition) {
        return children || null;
      }
      return Comp ? <Comp /> : null;
    }
    return children || null;
  }

  return Comp ? <Comp /> : null;
}

export default RenderIf;
