import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'typeface-roboto/index.css';
import 'typeface-roboto-mono/index.css';
import './App.css';
import RenderIf from 'App/components/RenderIf';
import { withAuth, AuthProps } from 'App/providers/AuthProvider';
import { LinearProgress } from '@mui/material';
import { Header, Footer } from './components';
import Sidebar from './Sidebar';
import MainContainer from './MainContainer';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import {
  ContractCompanyProvider,
  BusinessProfileProvider,
  LocationProvider,
  DialogProvider,
  AdminUserProvider
} from './providers';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import CategoryProvider from './providers/CategoryProvider';

declare const navigator: {
  language: string;
  userLanguage: string;
};

type Props = AuthProps & LocaleProps;
type State = {
  isSideBarOpen: boolean;
};

export const AppRef = {
  ref: undefined,
  get() {
    if (this.ref == null) {
      throw new Error('Cannot access null reference');
    }
    return this.ref;
  }
} as const;

export const SnackbarRef = {
  ref: undefined,
  get() {
    if (this.ref == null) {
      throw new Error('Cannot access null reference');
    }
    return this.ref;
  }
} as const;

class App extends Component<Props, State> {
  state = {
    isSideBarOpen: false
  };

  constructor(props: Props) {
    super(props);

    // @ts-expect-error - TS2540 - Cannot assign to 'ref' because it is a read-only property.
    AppRef.ref = this;
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'prod') {
      document.title = `poinz Studio (${process.env.REACT_APP_ENVIRONMENT})`;
    }
  }

  toggleSideBar = () => {
    this.setState(prevState => ({ isSideBarOpen: !prevState.isSideBarOpen }));
  };

  renderContent() {
    return (
      <>
        <RenderIf loggedIn>
          <Sidebar open={this.state.isSideBarOpen} onClick={this.toggleSideBar} />
        </RenderIf>
        <div className="App-scroll">
          <MainContainer />
          <Footer />
        </div>
      </>
    );
  }

  render() {
    const { authState } = this.props;

    if (authState === 'UNKNOWN') {
      return <LinearProgress />;
    }

    return (
      <DialogProvider>
        <AdminUserProvider>
          <ContractCompanyProvider>
            <CategoryProvider>
              <BusinessProfileProvider>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={this.props.locale}>
                  <LocationProvider>
                    <div className="App-root">
                      <Header open={this.state.isSideBarOpen} onClick={this.toggleSideBar} />
                      <div className="App-content">{this.renderContent()}</div>
                    </div>
                  </LocationProvider>
                </LocalizationProvider>
              </BusinessProfileProvider>
            </CategoryProvider>
          </ContractCompanyProvider>
        </AdminUserProvider>
      </DialogProvider>
    );
  }
}

// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Pick<RouteComponentProps<any, StaticContext, unknown>, never>, any> & WithRouterStatics<ComponentType<RouteComponentProps<any, StaticContext, unknown>>>' is not assignable to parameter of type 'ComponentType<AuthProps>'. | TS2345 - Argument of type 'typeof App' is not assignable to parameter of type 'ComponentType<RouteComponentProps<any, StaticContext, unknown>>'.
export default withLocale(withAuth(withRouter(App)));
