import * as React from 'react';
import clsx from 'clsx';
import { DragItem } from '../DraggableSort.utils';
import { StatusChip, Text } from 'App/components';
import { useStyles } from './DraggableItem.styles';
import { Icon } from '@mui/material';
import { Cashback, CASHBACK_TYPE } from 'serviceNew/model/cashback';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { IconButton } from '@mui/material';

type Props = {
  data: DragItem;
  className: string;
  onItemSelect?: (cashback: Cashback | null) => void;
};

const DraggableItem = ({
  className,
  data: { title, chips, cashback, imageUrl },
  onItemSelect
}: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx('DraggableItem', classes.root, className)}>
      <div className={classes.leftContent}>
        <Icon className={classes.icon}>drag_handle</Icon>
        <Text className={classes.title} variant="body2">
          {title}
        </Text>
        {imageUrl && <img className={classes.image} src={imageUrl} />}
      </div>
      <div>
        {cashback?.type === CASHBACK_TYPE.PARTNER && !!cashback?.businessProfileId && (
          <IconButton
            onClick={() => onItemSelect && cashback && onItemSelect(cashback)}
            size="small"
          >
            <InsertPhotoOutlinedIcon />
          </IconButton>
        )}
        {chips &&
          chips.map((chip, i) => (
            <StatusChip className={classes.chip} key={`chip-${i}`} {...chip} />
          ))}
      </div>
    </div>
  );
};

export default DraggableItem;
