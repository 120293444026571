import {
  getAuth,
  signOut as firebaseSignOut,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  getIdToken as firebaseGetIdToken,
  onIdTokenChanged as firebaseOnIdTokenChanged,
  getMultiFactorResolver,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  confirmPasswordReset as confirmPasswordResetFirebase,
  updatePassword as updatePasswordFirebase,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';

import firebaseApp from './index';

const AUTH = getAuth(firebaseApp);

export const AUTH_FLAGS = Object.freeze({
  ENTER_VERIFICATION_CODE: 'ENTER_VERIFICATION_CODE'
});

const reauthenticateUser = async (currentPassword: string) => {
  const credentialFirebase = EmailAuthProvider.credential;

// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
  const cred = await credentialFirebase(AUTH.currentUser?.email, currentPassword);

// @ts-expect-error - TS2345 - Argument of type 'User | null' is not assignable to parameter of type 'User'.
  return reauthenticateWithCredential(AUTH.currentUser, cred);
};

export const updatePassword = async (currentPassword: string, newPassword: string) => {
  try {
    await reauthenticateUser(currentPassword);
    //user entered correct password and MFA is not enabled
    //reauthenthication returns 200 and we update the passsword
// @ts-expect-error - TS2345 - Argument of type 'User | null' is not assignable to parameter of type 'User'.
    return await updatePasswordFirebase(AUTH.currentUser, newPassword);
  } catch (error: any) {
    // user entered correct password but MFA is enabled
    // reauthenthication returns 400 auth/multi-factor-auth-required
    // don't reauthenthicate, just update password
    if (error.code == 'auth/multi-factor-auth-required') {
// @ts-expect-error - TS2345 - Argument of type 'User | null' is not assignable to parameter of type 'User'.
      return await updatePasswordFirebase(AUTH.currentUser, newPassword);
    }

    // if there is an other error, propagate it further
    throw error;
  }
};

export const verifyCodeAndSignIn = async (code: string) => {
  // Verify the code and sign in
// @ts-expect-error - TS2339 - Property 'verificationId' does not exist on type 'Window & typeof globalThis'.
  const cred = PhoneAuthProvider.credential(window.verificationId, code);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

// @ts-expect-error - TS2339 - Property 'resolver' does not exist on type 'Window & typeof globalThis'.
  await window.resolver.resolveSignIn(multiFactorAssertion);
};

export const isEnrolledToMFA = () => {
  if (AUTH.currentUser) {
    return multiFactor(AUTH.currentUser).enrolledFactors?.length;
  }
  return false;
};

export const verifyCodeAndEnrollToMFA = async (code: string) => {
  // Verify the code and enroll to MFA
// @ts-expect-error - TS2339 - Property 'verificationId' does not exist on type 'Window & typeof globalThis'.
  const cred = PhoneAuthProvider.credential(window.verificationId, code);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

// @ts-expect-error - TS2345 - Argument of type 'User | null' is not assignable to parameter of type 'User'.
  await multiFactor(AUTH.currentUser).enroll(multiFactorAssertion);
};

export const addPhoneAsMFALayer = async (phoneNumber: string) => {
  // Ask for a phone number so that we can set up MFA
  const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    size: 'invisible'
  }, AUTH);

// @ts-expect-error - TS2345 - Argument of type 'User | null' is not assignable to parameter of type 'User'.
  multiFactor(AUTH.currentUser)
    .getSession()
    .then(async function (session) {
      // Specify the phone number and pass the MFA session.
      const phoneInfoOptions = {
        phoneNumber,
        session
      } as const;

      const phoneAuthProvider = new PhoneAuthProvider(AUTH);

      // Send verification code
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      );
// @ts-expect-error - TS2339 - Property 'verificationId' does not exist on type 'Window & typeof globalThis'.
      window.verificationId = verificationId;
    });
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await firebaseSignInWithEmailAndPassword(AUTH, email, password);
  } catch (error: any) {
    const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    }, AUTH);

    // Has MFA enabled, sent the code
    if (error.code == 'auth/multi-factor-auth-required') {
      const resolver = getMultiFactorResolver(AUTH, error);
// @ts-expect-error - TS2339 - Property 'resolver' does not exist on type 'Window & typeof globalThis'.
      window.resolver = resolver;
      // We will always have 1 factor, phone number
      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        } as const;

        const phoneAuthProvider = new PhoneAuthProvider(AUTH);
        // Send SMS verification code
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
// @ts-expect-error - TS2339 - Property 'verificationId' does not exist on type 'Window & typeof globalThis'.
        window.verificationId = verificationId;
        recaptchaVerifier.clear();
        return {
          nextStep: AUTH_FLAGS.ENTER_VERIFICATION_CODE,
// @ts-expect-error - TS2339 - Property 'phoneNumber' does not exist on type 'MultiFactorInfo'.
          phone: phoneInfoOptions.multiFactorHint.phoneNumber
        };
      } else {
        // Unsupported second factor.
      }
      // Throw all other errors like auth/wrong-password
    } else {
      throw error;
    }
  }
};

export async function getIdToken(forceRefresh?: boolean): Promise<string | null | undefined> {
  const { currentUser } = AUTH;
  if (currentUser != null) {
    return await firebaseGetIdToken(currentUser, forceRefresh);
  }
  return null;
}

export async function signOut(): Promise<void> {
  return firebaseSignOut(AUTH);
}

export function onIdTokenChanged(subscriber: any) {
  firebaseOnIdTokenChanged(AUTH, subscriber);
}

export const confirmPasswordReset = async (oobCode: string, password: string) => {
  return confirmPasswordResetFirebase(AUTH, oobCode, password);
};
