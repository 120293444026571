import * as React from 'react';

import { CircularProgress } from '@mui/material';

type WaitForProps = {
  condition: boolean,
  children: React.ReactNode
};

class WaitFor extends React.PureComponent<WaitForProps> {
  render() {
    const { children, condition } = this.props;
    if (!condition) {
      return <CircularProgress />;
    }
    return children;
  }
}

export default WaitFor;
