import * as React from 'react';
import _ from 'lodash';
import { withStyles, WithStyles } from '@mui/styles';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { TranslationKey } from 'serviceNew/locale';
import { CHART_STYLING } from '../../../constants/general';
import DataExporter from './DataExporter';
import {
  ExportableDataFormatter,
  ExcelSheetCellData,
  ExcelSheetColumnData
} from './ExcelSheetModel';

const styles = () => ({
  wrapper: {
    position: 'relative',
    '& .default-generate-excel-button': {
      position: 'absolute',
      right: 0
    }
  }
});

type ChartDataItem = {
  dateDay: string;
  [name: string]: any;
};

type WrappedProps = {
  chartData: Array<ChartDataItem>;
  chartList: Array<string>;
  name: string;
  title: TranslationKey;
};

type RechartExportableFormatter = {
  field: string;
  formatter: ExportableDataFormatter;
};

// @ts-expect-error - TS2344 - Type '() => { wrapper: { position: string; '& .default-generate-excel-button': { position: string; right: number; }; }; }' does not satisfy the constraint 'ClassKeyInferable<any, any>'.
type HOCComponentProps = LocaleProps & WithStyles<typeof styles>;

export default function rechartWithExportableData<
  Props extends WrappedProps,
  Comp extends React.ComponentType<Props>
>(Component: Comp, formatters: Array<RechartExportableFormatter> = []): React.ComponentType<Props> {
  class WrappedRechartWithExportableData extends React.PureComponent<Props & HOCComponentProps> {
    getColumns = (): Array<ExcelSheetColumnData> => {
      const { chartList } = this.props;
      const dateColumn: ExcelSheetColumnData = { title: 'Date' };
      const chartColumns: Array<ExcelSheetColumnData> = chartList.map(chart => ({
        title: this.getFormattedColumn(chart)
      }));
      return [dateColumn, ...chartColumns];
    };

    getFormattedColumn = (chartColumn: string): string => {
      const { name, translate } = this.props;
      const chartColumnLabel: TranslationKey = _.get(CHART_STYLING, [name, chartColumn, 'label']);
      return translate(chartColumnLabel);
    };

    getRow = (chartDataItem: ChartDataItem): Array<ExcelSheetCellData> => {
      const { chartList } = this.props;
      const dateValue: ExcelSheetCellData = { value: chartDataItem.dateDay };
      const chartValues: Array<ExcelSheetCellData> = chartList
        .map(field => this.getChartValue(chartDataItem, field))
        .map((value: string) => ({ value }));
      return [dateValue, ...chartValues];
    };

    getChartValue = (chartDataItem: ChartDataItem, field: string): string => {
      const fieldValue = chartDataItem[field];
      if (!fieldValue) {
        return '-';
      }
      const formatter = formatters.find(f => f.field === field);
      return formatter ? formatter.formatter(fieldValue) : fieldValue.toString();
    };

    render() {
      const {
        classes,
        translate,
        // eslint-disable-next-line no-unused-vars
        // @ts-expect-error - TS2339 - Property 'language' does not exist on type 'Readonly<Props & LocaleProviderProps & { classes: ClassNameMap<never>; }>'.
        language,
        // eslint-disable-next-line no-unused-vars
        // @ts-expect-error - TS2339 - Property 'setLanguage' does not exist on type 'Readonly<Props & LocaleProviderProps & { classes: ClassNameMap<never>; }>'.
        setLanguage,
        ...otherProps
      } = this.props;

      const name = translate(otherProps.title);
      const columns = this.getColumns();
      const data = otherProps.chartData.map(d => this.getRow(d));
      const sheet = { name, columns, data } as const;
      const sheets = [sheet];
      return (
        // @ts-expect-error - TS2339 - Property 'wrapper' does not exist on type '(Props & LocaleProviderProps & { classes: ClassNameMap<never>; })["classes"]'.
        <div className={classes.wrapper}>
          <DataExporter sheets={sheets} fileName={name} />
          {/* @ts-expect-error - TS2322 - Type 'Omit<Readonly<Props & LocaleProviderProps & { classes: ClassNameMap<never>; }>, "translate" | "language" | "classes" | "setLanguage">' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<Comp, Props>'. */}
          <Component {...otherProps} />
        </div>
      );
    }
  }

  const LocaleComponent = withLocale(WrappedRechartWithExportableData);
  // @ts-expect-error - TS2345 - Argument of type '() => { wrapper: { position: string; '& .default-generate-excel-button': { position: string; right: number; }; }; }' is not assignable to parameter of type 'Styles<any, any, string>'.
  const StyledComponent = withStyles(styles)(LocaleComponent);
  return StyledComponent;
}
