import * as React from 'react';

import { withFormContext, FormContextProps } from '../forms/GenericForm';
import TextSelect from '../TextSelect';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import { TextSelectSearch as TextSelectSearchBase, Text } from 'App/components';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import { MyTheme } from 'theme/muiTheme';
import clsx from 'clsx';

export { getOptionsFromEnum } from '../TextSelect';

type Props = FieldRenderProps &
  FormContextProps &
  JSX.LibraryManagedAttributes<typeof TextSelect, React.ComponentProps<typeof TextSelect>>;

const useStyles = makeStyles((theme: MyTheme) => ({
  labelContainer: {
    height: 25,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    padding: theme.spacing(0, 0, 0.5, 1.5)
  }
}));

const TextSelectSearch = (props: Props) => {
  const { translate } = useLocale();
  const classes = useStyles();
  const {
    field,
    meta,
    submitting,
    className,
    disabled,
    InputProps,
    onChange,
    required = false,
    name,
    form,
    ...restProps
  } = props;
  const { error, touched } = meta;
  const { setFieldValue } = form;

  const handleOnChange = (newValue: string) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
    setFieldValue(name, newValue);
  };

  return (
    <div className={clsx('TextSelectSearch', className)}>
      <div className={classes.labelContainer}>
        <Text className={classes.label} noWrap variant="subtitle2">
          {restProps.label.key}
        </Text>
        &nbsp;{required ? '*' : ''}
      </div>
      <TextSelectSearchBase
        error={!!error && !!touched}
        {...field}
        required={required}
        disabled={submitting || disabled}
        {...restProps}
        helperText={error && touched ? translate(error.key) : undefined}
        InputProps={{ ...InputProps, name }}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default withFieldAdapter(withFormContext(TextSelectSearch));
