import React from 'react';
import { LanguageInput } from 'App/providers/LocaleProvider';
import { CHART_STYLING } from '../../../constants/general';
import { ChartDataType } from './BarChart';

type Props = {
  payload: Array<any>;
  name: string;
  active?: boolean;
  total: number;
  additional?: ChartDataType;
  formatMessage: (arg1: LanguageInput) => string;
};

export const ChartTooltipUnconnected = ({
  payload,
  name,
  active,
  total,
  additional,
  formatMessage
}: Props) => {
  if (active) {
    const namedLabel =
      Object.keys(CHART_STYLING).includes(name) &&
      Object.keys(CHART_STYLING[name]).includes(payload[0].payload.name)
        ? formatMessage({ id: CHART_STYLING[name][payload[0].payload.name].label })
        : [payload[0].payload.name];
    return (
      <div
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          background: '#fff'
        }}
      >
        <p>
          <span style={{ color: '#EB6308' }}>{namedLabel}</span>
          <br />
          {`${formatMessage({ id: 'general.count' })}: ${payload[0].value}`}
          <br />
          {total && `${formatMessage({ id: 'general.total' })}: ${total}`}
          <br />
          {total &&
            `${formatMessage({ id: 'general.share' })}:
          ${Math.round(100 * (payload[0].value / total))}%`}
          <br />
          {additional && `${formatMessage({ id: additional.name })}: ${additional.value}`}
        </p>
      </div>
    );
  }
  return null;
};

export default ChartTooltipUnconnected;
