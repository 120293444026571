import React, { ReactNode } from 'react';
import { Modal as MuiModal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  content: {
    // @ts-expect-error - TS2339 - Property 'shadows' does not exist on type 'DefaultTheme'.
    boxShadow: theme.shadows[5],
    minWidth: '50%',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(3),
    overflow: 'auto',
    maxHeight: '90vh',
    maxWidth: '90%',
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius * 3
  }
}));

type Props = {
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
};

const Modal = ({ open, children, onClose }: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <MuiModal className={classes.modal} open={open} onClose={handleClose}>
      <Fade in={open}>
        <div className={classes.content}>{children}</div>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
