import moment, { Moment } from 'moment';
import { keyMirror } from 'util/ObjectHelper';
import { DealType } from 'serviceNew/model/deal';
import { DealCollectState } from 'serviceNew/model/dealCollect';

export const FILTER = keyMirror({
  FROM_DATE: null,
  TO_DATE: null,
  BUSINESS_PROFILE: null,
  LOCATION: null,
  OFFERING_ID: null,
  DEAL_ID: null,
  DEAL_COLLECT_STATE: null,
  DEAL_TYPE: null
});

export const FILTER_ACTION = keyMirror({
  REMOVE: null,
  SET: null
});

type RemoveAction = {
  type: typeof FILTER_ACTION.REMOVE;
  filter: keyof typeof FILTER;
};

type SetStringValueAction = {
  type: typeof FILTER_ACTION.SET;
  filter:
    | typeof FILTER.DEAL_ID
    | typeof FILTER.OFFERING_ID
    | typeof FILTER.LOCATION
    | typeof FILTER.BUSINESS_PROFILE;
  value: string | null | undefined;
};

type SetDealTypeAction = {
  type: typeof FILTER_ACTION.SET;
  filter: typeof FILTER.DEAL_TYPE;
  value: DealType | null | undefined;
};

type SetDateAction = {
  type: typeof FILTER_ACTION.SET;
  filter: typeof FILTER.FROM_DATE | typeof FILTER.TO_DATE;
  value: Moment | null | undefined;
};

type SetDealCollectStateAction = {
  type: typeof FILTER_ACTION.SET;
  filter: typeof FILTER.DEAL_COLLECT_STATE;
  value: DealCollectState | null | undefined;
};

type ReducerAction =
  | SetStringValueAction
  | SetDateAction
  | SetDealCollectStateAction
  | RemoveAction
  | SetDealTypeAction;

type FilterState = {
  FROM_DATE: moment.Moment | null | undefined;
  TO_DATE: moment.Moment | null | undefined;
  BUSINESS_PROFILE: string | null | undefined;
  LOCATION: string | null | undefined;
  OFFERING_ID: string | null | undefined;
  DEAL_ID: string | null | undefined;
  DEAL_COLLECT_STATE: DealCollectState | null | undefined;
  DEAL_TYPE: DealType | null | undefined;
};

// @ts-expect-error - TS2740 - Type '{ [x: number]: null; }' is missing the following properties from type 'FilterState': FROM_DATE, TO_DATE, BUSINESS_PROFILE, LOCATION, and 4 more.
export const initialFilterState: FilterState = {
  [FILTER.FROM_DATE]: null,
  [FILTER.TO_DATE]: null,
  [FILTER.BUSINESS_PROFILE]: null,
  [FILTER.LOCATION]: null,
  [FILTER.OFFERING_ID]: null,
  [FILTER.DEAL_ID]: null,
  [FILTER.DEAL_COLLECT_STATE]: null,
  [FILTER.DEAL_TYPE]: null
};

export type FilterDispatch = (action: ReducerAction) => void;

export function filterReducer(state: FilterState, action: ReducerAction): FilterState {
  switch (action.type) {
    case FILTER_ACTION.REMOVE:
      return {
        ...state,
        [action.filter]: null
      };
    case FILTER_ACTION.SET:
      return {
        ...state,

        // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'ReducerAction'.
        [action.filter]: action.value,
        ...(action.filter === FILTER.BUSINESS_PROFILE
          ? {
              [FILTER.DEAL_ID]: null,
              [FILTER.OFFERING_ID]: null,
              [FILTER.LOCATION]: null
            }
          : {})
      };
    default:
      return state;
  }
}
