import { PoinzApiClient } from '../../api';
import { ReferralCampaign } from './types';
import { ROOT } from './config';

const CAMPAIGNS = 'campaigns';
const PUBLIC = 'public';
const apiRoot = `/${ROOT}/${CAMPAIGNS}/${PUBLIC}`;

const getPublicCampaign =
  (apiClient: PoinzApiClient) =>
  async (code: string): Promise<ReferralCampaign> => {
    const result = await apiClient.get(apiRoot, {
      queryParams: { code }
    });

    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  getPublicCampaign: getPublicCampaign(apiClient)
});
