import * as React from 'react';
import { useGoogleMap, GooglePlacesServices } from '@poinz/hooks';
import { TranslationObject } from 'serviceNew/locale';
import AutoCompletion from './AutoCompletion';

type AutoCompleteItems = {
  suggestion: string;
  placeId: string;
  index: number;
};

export type Place = {
  street: Array<string | number>;
  streetName: string;
  streetNumber: number;
  zip: number;
  city: string;
  countryCode: string;
  lat: number;
  long: number;
  placeId: string;
};

type Props = {
  onPlaceSelection: (arg1: Place) => void;
  placeholder?: TranslationObject;
};

type Prediction = {
  description: string;
  place_id: string;
};

type PlaceResult = {
  address_components: Array<any>;
  place_id: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
};

function GoogleAddressAutoCompletion({ onPlaceSelection, placeholder, ...restProps }: Props) {
  const [autocompleteItems, setAutocompleteItems] = React.useState<AutoCompleteItems[]>([]);

  const { isLoaded, loadError, placesService, autocompleteService } = useGoogleMap([
    GooglePlacesServices.PLACES,
    GooglePlacesServices.AUTOCOMPLETE
  ]);

  const autocompleteCallback = (predictions: Array<Prediction>) => {
    if (Array.isArray(predictions)) {
      setAutocompleteItems(
        predictions.map((p, idx) => ({
          suggestion: p.description,
          placeId: p.place_id,
          index: idx
        }))
      );
    }
  };

  const detailsCallback = React.useCallback(
    (placeResult: PlaceResult | null) => {
      // eslint-disable-next-line camelcase
      const { address_components, geometry, place_id } = placeResult || {};

      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const address = address_components.reduce<Record<string, any>>((acc, addComp) => {
        switch (addComp.types[0]) {
          case 'route':
            acc.streetName = addComp.short_name;
            acc.street = Array.isArray(acc.street)
              ? [addComp.short_name, ...acc.street]
              : [addComp.short_name];
            break;
          case 'street_number':
            acc.streetNumber = addComp.short_name;
            acc.street = Array.isArray(acc.street)
              ? [...acc.street, addComp.short_name]
              : [addComp.short_name];
            break;
          case 'postal_code':
            acc.zip = addComp.short_name;
            break;
          case 'locality':
            acc.city = addComp.short_name;
            break;
          case 'country':
            acc.countryCode = addComp.short_name;
            break;
          default:
            break;
        }
        return acc;
      }, {});

      onPlaceSelection({
        // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
        placeId: place_id,
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        long: geometry.location.lng(),
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        lat: geometry.location.lat(),
        ...address
      });
    },
    [onPlaceSelection]
  );

  const onChange = React.useCallback(
    (value: string) => {
      if (autocompleteService && value) {
        autocompleteService.getPlacePredictions(
          {
            input: value
          },
          autocompleteCallback
        );
      }
    },
    [autocompleteService]
  );

  const fetchDetails = React.useCallback(
    placeId => {
      if (placesService) {
        placesService.getDetails({ placeId, language: 'de' }, detailsCallback);
      }
    },
    [detailsCallback, placesService]
  );

  const getPlaceholder = () => {
    if (loadError) {
      return { key: 'address.search.notAvailable' };
    }
    if (isLoaded) {
      return placeholder || { key: 'address.search.placeholder' };
    }
    return { key: 'address.search.loading' };
  };

  return (
    <AutoCompletion
      onChange={onChange}
      onSelect={fetchDetails}
      autoFocus={false}
      label={{ key: 'address.address' }}
      placeholder={getPlaceholder()}
      suggestions={autocompleteItems.map(ai => ({ id: ai.placeId, name: ai.suggestion }))}
      width="100%"
      isPoweredByGoogle
      // @ts-expect-error - TS2322 - Type 'true | Error | undefined' is not assignable to type 'boolean | undefined'.
      disabled={!isLoaded || loadError}
      {...restProps}
    />
  );
}

export default GoogleAddressAutoCompletion;
