import * as React from 'react';
import { Category } from 'serviceNew/model/category';
import categoryApi from 'serviceNew/api/categories';
import { IdType } from '@poinz/api';

type ProviderProps = {
  categories: Category &
    {
      displayLabel: string;
      id: number;
    }[];
  updateCategory: (values: Category) => Promise<void>;
  createCategory: (values: Category) => Promise<void>;
  deleteCategory: (id: IdType) => Promise<void>;
  isLoading: boolean;
};

const defaultContext: ProviderProps = {
  // @ts-expect-error - TS2322 - Type 'never[]' is not assignable to type 'Category & { displayLabel: string; }[]'.
  categories: [],
  isLoading: false,
  updateCategory: async () => {},
  createCategory: async () => {},
  deleteCategory: async () => {}
};

type Props = {
  children: React.ReactNode;
};

export const CategoryContext = React.createContext<ProviderProps>(defaultContext);

function CategoryProvider(props: Props) {
  const { children } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const result = await categoryApi.getPaginatedCategories(0);
        setCategories(result.content);
      } catch (e: any) {
        //
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const getDisplayLabel = React.useCallback(
    (category?: Category | null) => {
      if (!category) {
        return '';
      }
      if (category.parentId == null) {
        return category.label;
      }
      return `${getDisplayLabel(categories.find(c => c.id === category.parentId))} > ${
        category.label
      }`;
    },
    [categories]
  );

  const updateCategory = React.useCallback(
    async (values: Category) => {
      const updatedCategory = await categoryApi.updateCategory(values);
      setCategories([...categories.filter(c => c.id !== updatedCategory.id), updatedCategory]);
    },
    [categories]
  );

  const createCategory = React.useCallback(
    async (values: Category) => {
      const newCategory = await categoryApi.createCategory(values);
      setCategories([...categories, newCategory]);
    },
    [categories]
  );

  const deleteCategory = React.useCallback(
    async (id: IdType) => {
      await categoryApi.deleteCategory(id);
      setCategories(categories.filter(c => c.id !== id));
    },
    [categories]
  );

  const contextValue = React.useMemo(
    () => ({
      categories: categories.map(c => ({ ...c, displayLabel: getDisplayLabel(c) })),
      isLoading,
      updateCategory,
      createCategory,
      deleteCategory
    }),
    [categories, createCategory, isLoading, updateCategory, deleteCategory, getDisplayLabel]
  );

  // @ts-expect-error - TS2322 - Type '{ categories: { displayLabel: any; id: number; readOnly: boolean; parentId: number; names: MultiLanguageString; label: string; picture: string; created: string; createdBy: number; modified: string; modifiedBy: number; deleted: string; }[]; isLoading: boolean; updateCategory: (values: Category) => Promise<...>; creat...' is not assignable to type 'ProviderProps'.
  return <CategoryContext.Provider value={contextValue}>{children}</CategoryContext.Provider>;
}

export const useCategory = () => React.useContext(CategoryContext);

export default CategoryProvider;
