import * as React from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import clsx from 'clsx';
import { TranslatedText } from '.';

const useStyles = makeStyles(theme => ({
  tabs: {
    // paddingBottom: theme.spacing(3),
  },
  tab: {
    minWidth: 0,
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(2),
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(0.75, 1.5),
    textTransform: 'none',
    '&:last-child': {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
      marginRight: theme.spacing(0)
    }
  },
  flexContainer: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(1)
  },
  indicator: {
    borderRadius: 2,
    height: 4
  }
}));

type TabType<T> = {
  tab: T,
  label: TranslationObject,
  component?: React.ReactNode,
  hidden?: boolean
};

export type Props<T> = {
  value?: T,
  onChange?: (arg1: T) => void,
  tabs: Array<TabType<T>>,
  tabBarClassName?: string,
  tabClassName?: string
};

function TabView<T extends string>(props: Props<T>) {
  const { value, onChange, tabs, tabBarClassName, tabClassName } = props;
  const [selectedTab, setSelectedTab] = React.useState<T>(value || tabs[0].tab);
  const classes = useStyles();

  const currentTab = React.useMemo(() => tabs.find(tab => tab.tab === (value || selectedTab)), [
    selectedTab,
    tabs,
    value
  ]);

  function handleChange(_: any, tab: T) {
    if (typeof onChange === 'function') {
      onChange(tab);
    } else {
      setSelectedTab(tab);
    }
  }

  if (!currentTab) {
    return null;
  }

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons={false}
        value={currentTab.tab}
        onChange={handleChange}
        className={clsx(classes.tabs, tabBarClassName)}
        classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer }}
      >
        {tabs.map(tab =>
          !tab.hidden ? (
            <Tab
              key={tab.tab}
              className={clsx(classes.tab, tabClassName)}
              disableRipple
              value={tab.tab}
              color="secondary"
              label={<TranslatedText text={tab.label} />}
            />
          ) : null
        )}
      </Tabs>
      {currentTab.component ? currentTab.component : null}
    </>
  );
}

TabView.defaultProps = {
  tabBarClassName: '',
  tabClassName: ''
};

export default TabView;
