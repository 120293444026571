import { PoinzApiClient } from '../../api';

const VERSION = '/v2';
const WEB = '/web';
const USERS = '/users';

const apiRoot = `${VERSION}${WEB}${USERS}`;

const updateAvatar =
  (apiClient: PoinzApiClient) =>
  async (formData: FormData): Promise<void> => {
    await apiClient.put(`${apiRoot}/avatar`, {
      body: formData,
      isMultiPart: true
    });
  };

const resendConfirmationMail =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<void> => {
    await apiClient.post(`${apiRoot}/${id}/resend-confirmation-mail`, {
      authenticated: true
    });
  };

export default (apiClient: PoinzApiClient) => ({
  updateAvatar: updateAvatar(apiClient),
  resendConfirmationMail: resendConfirmationMail(apiClient)
});
