import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { CreatePoinzTransactionBody } from './types';

const VERSION = '/v1';
const TRANSACTIONS = '/transactions/poinz/api';
const apiRoot = `${ROOT}${VERSION}${TRANSACTIONS}`;

const create =
  (apiClient: PoinzApiClient) =>
  async (body: CreatePoinzTransactionBody): Promise<void> => {
    await apiClient.post(apiRoot, {
      body
    });
  };

export default (apiClient: PoinzApiClient) => ({
  create: create(apiClient)
});
