import * as React from 'react';

import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';

import { MyTheme } from 'theme/muiTheme';
import { Formik, FormikProps } from 'formik';
import { ApiError } from 'serviceNew/model/apiError';
import Button from './Button';
import Field from './Field';
import TextField from './fields/TextField';
import MultiSelectField from './fields/MultiSelectField';
import Text from './Text';

const styles = createStyles((theme: MyTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    flex: 1,
  },
  form: {
    display: 'flex',
  },
  textInput: {
    flex: 1,
// @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    marginRight: theme.extra.spacing.small,
  },
  button: {
// @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    marginLeft: theme.extra.spacing.small,
    height: '56px',
  },
}));

type Props<T> = {
  className: string,
  fieldLabel: TranslationObject,
  showFieldLabel: boolean,
  buttonLabel: TranslationObject,
  onSubmit: (value: T) => Promise<void>,
  validate: (value: T) => TranslationObject | null | undefined,
  initialValue: T | null | undefined,
  component: typeof TextField | typeof MultiSelectField
} & WithStyles<typeof styles>;

type State = {
  submitting: boolean,
  error: string | null | undefined
};

class OnelineForm<T> extends React.PureComponent<Props<T>, State> {
  static defaultProps = {
    className: '',
    value: undefined,
    component: TextField,
  };

  state = {
    submitting: false,
    error: undefined,
  };

  handleSubmit: (
    values: {
      value: T
    },
  ) => Promise<void> = async values => {
    const { value } = values;
    const { onSubmit } = this.props;

    if (value != null) {
      this.setState({ submitting: true });
      try {
        await onSubmit(value);
      } catch (e: any) {
        if (e instanceof ApiError) {
          this.setState({ error: e.toString() });
        }
      } finally {
        this.setState({ submitting: false });
      }
    }
  };

  renderForm = ({
    handleSubmit,
// @ts-expect-error - TS2314 - Generic type 'FormikProps' requires 1 type argument(s).
  }: FormikProps) => {
    const { submitting } = this.state;
    const {
      classes,
      fieldLabel,
      buttonLabel,
      showFieldLabel,
      validate,
      component,
      // eslint-disable-next-line no-unused-vars
      onSubmit,
      ...restProps
    } = this.props;
    return (
// @ts-expect-error - TS2339 - Property 'form' does not exist on type 'ClassNameMap<never>'.
      <form className={classes.form} onSubmit={handleSubmit}>
{ /* @ts-expect-error - TS2322 - Type '{ validate: (value: T) => TranslationObject | null | undefined; component: (props: any) => Element; name: string; className: any; label: TranslationObject; required: true; noLabel: boolean; initialValue: T | ... 1 more ... | undefined; }' is not assignable to type 'FieldConfig'. */}
        <Field
          {...restProps}
          name="value"
// @ts-expect-error - TS2322 - Type '{ validate: (value: T) => TranslationObject | null | undefined; component: (props: any) => Element; name: string; className: any; label: TranslationObject; required: true; noLabel: boolean; initialValue: T | ... 1 more ... | undefined; }' is not assignable to type 'IntrinsicAttributes & { onChange?: ((arg1: SyntheticEvent<Element, Event>) => Promise<undefined> | undefined) | undefined; hidden?: boolean | undefined; clearOnUnmount?: boolean | undefined; defaultOptions: Option[]; handleChange?: ((arg1: { ...; }) => void) | undefined; } & ... 7 more ... & Pick<...>'. | TS2339 - Property 'field' does not exist on type 'ClassNameMap<never>'.
          className={classes.field}
          label={fieldLabel}
          required
          noLabel={!showFieldLabel}
          {...{ validate, component }}
        />
        <Button
// @ts-expect-error - TS2339 - Property 'button' does not exist on type 'ClassNameMap<never>'.
          className={classes.button}
          color="primary"
          variant="contained"
          loading={submitting}
          label={buttonLabel}
          type="submit"
        />
      </form>
    );
  };

  render() {
    const { error } = this.state;
    const { classes, className, initialValue } = this.props;
    return (
// @ts-expect-error - TS2339 - Property 'container' does not exist on type 'ClassNameMap<never>'.
      <div className={[classes.container, className].join(' ')}>
        <Formik
          enableReinitialize
          render={this.renderForm}
          onSubmit={this.handleSubmit}
// @ts-expect-error - TS2322 - Type 'T | null | undefined' is not assignable to type 'T'.
          initialValues={{ value: initialValue }}
        />
        {error && (
          <Text color="error" context={{ value: error }}>
            text.no.translation
          </Text>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(OnelineForm);
