import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { WebConfig } from './types/webConfig';

const VERSION = '/v2';
const WEB = '/web';
const CONFIG = '/config';
const apiRoot = `${ROOT}${VERSION}${WEB}${CONFIG}`;

const get = (apiClient: PoinzApiClient) => async (): Promise<WebConfig> => {
  const result = await apiClient.get(apiRoot, {});
  return result.body;
};

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient)
});
