import * as React from 'react';
import _ from 'lodash';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Grid,
  LinearProgress
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { TranslationKey } from 'serviceNew/locale';
import { Location } from 'serviceNew/model/location';
import { Offering } from 'serviceNew/model/offering';
import { UserRole } from 'serviceNew/model/adminUser';
import { LANGUAGES_TRANSLATED } from 'serviceNew/locale/translations';
import { Button, Pagination, AuthorizableBarChart, PieChart, TranslatedText } from 'App/components';
import { parseName } from '../../../../../util/StringHelper';

import { ResolutionType, CHART_STYLING } from '../../../../../constants/general';

import { DataExporter, tableWithExportableData } from '../../../../components/DataExporter';
import {
  ExcelSheetData,
  ExcelSheetColumnData,
  ExcelSheetCellData
} from '../../../../components/DataExporter/ExcelSheetModel';
import { withLocale, LocaleProps } from '../../../../providers/LocaleProvider';

const styles = () => ({
  firstCol: {
    width: '25%'
  },
  middleCol: {
    width: '15%'
  },
  selectedLocation: {
    fontWeight: 500,
    background: '#eee'
  }
});

type Props = {
  // adding the userRole retrieved from the state for resolving the chart columns based on the logged user
  userRole: UserRole;
  locationStatsOverview: Array<any>;
  locationStatsDetailOverview: Array<any>;
  locationStatsActivityTimeline: Array<any>;
  resolution: ResolutionType;
  locationList: Array<Location>;
  offeringList: Array<Offering>;
  classes: any;
  selectedLocation: number | null | undefined;
  handleSelectLocation: (arg1: number) => void;
  days: string;
  lang: keyof typeof LANGUAGES_TRANSLATED;
  totalElements: number;
  currentPage: number;
  totalPages: number;
  handlePageChange: () => void;
  isRequesting: boolean;
} & LocaleProps;

// define a global locations variable to be assigned with the locations list passed through props
let locations: Array<never> = [];
// define a global currentLanguage variable to be assigned with the lang passed through props
let currentLanguage: keyof typeof LANGUAGES_TRANSLATED = 'en';
// define a global selectedDays variable to be assigned with the days passed through props
let selectedDays = '';
// move the chart list constants above the file
const chartList = {
  SUPER_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser', 'numLostUser'],
  POINZ_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser'],
  PARTNER_ADMIN: ['numNewUser', 'numScans', 'numRedemptions', 'numActiveUser'],
  // adding user because required by USER_ROLE type
  USER: []
} as const;

class LocationStatsTableUnconnected extends React.Component<Props> {
  // this method can be simplified as shown at getLocationName
  mapEntityName = (
    entityList: any,
    entityId: number,
    entityName: string,
    language: keyof typeof LANGUAGES_TRANSLATED
  ): string | null | undefined => {
    const filteredEntity = entityList.filter(ent => ent.id === entityId);
    if (filteredEntity.length === 1 && Object.keys(filteredEntity[0]).includes(entityName)) {
      return language
        ? parseName(filteredEntity[0][entityName], language)
        : filteredEntity[0][entityName];
    }
    return ' ';
  };

  // this method can be simplified as shown at getStatValue
  statsValue = (stat?: any | null) =>
    stat && Object.keys(stat).includes(this.props.days) ? stat[this.props.days] : 0;

  /**
   * Return the list of sheets to be exported for the selected location given as argument
   * @memberof LocationStatsTableUnconnected
   */
  getLocationSheets = (selectedLocation: number): Array<ExcelSheetData> => {
    const { locationStatsDetailOverview, offeringList } = this.props;
    // get the sheet with the activity timeline for the given location
    const activityTimelineSheet: ExcelSheetData = this.getLocationActivityTimelineSheet();
    // create the list of sheets to be exported
    const sheets: Array<ExcelSheetData> = [activityTimelineSheet];
    // get the offerings associated to the current location
    const offerings: Array<any> = locationStatsDetailOverview.filter(
      (l: any) => offeringList.findIndex(({ id }) => id === l.offeringId) > -1
    );
    // if there is at least one offering associated to the location
    if (offerings.length) {
      // add a sheet with the offerings data of the location
      sheets.push(this.getOfferingsSheet(selectedLocation, offerings));
    }

    return sheets;
  };

  /**
   * Get the ExcelSheetData with the offerings info related to the given location
   * @memberof LocationStatsTableUnconnected
   */
  getOfferingsSheet = (selectedLocation: number, offerings: Array<any>): ExcelSheetData => {
    const { locationList, lang, translate, offeringList } = this.props;
    // get the name of the location
    const location: string = this.mapEntityName(locationList, selectedLocation, 'name', lang) || '';
    // create the list of column labels related to the table data of the offerings
    const tableRawColumns: Array<TranslationKey> = [
      'location.name',
      'stats.totalUser',
      'stats.numNewUser',
      'stats.activeUser',
      'stats.numScans',
      'stats.numRedemptions'
    ];
    // create the list of ExcelSheetColumnData with the translated column labels
    const tableSheetColumns: Array<ExcelSheetColumnData> = tableRawColumns.map(
      (c: TranslationKey) => ({
        title: translate(c)
      })
    );
    // create the list of ExcelSheetCellData for each offering with the table data
    const tableSheetData: Array<Array<ExcelSheetCellData>> = offerings.map((o: any) => [
      { value: this.mapEntityName(offeringList, o.offeringId, 'name', lang) || '' },
      { value: o.totalUser },
      { value: this.statsValue(o.newUser) },
      { value: this.statsValue(o.activeUser) },
      { value: this.statsValue(o.codeCollects) },
      { value: this.statsValue(o.cardCollects) }
    ]);
    // return the sheet for the offerings table
    return { columns: tableSheetColumns, name: location, data: tableSheetData };
  };

  getLocationActivityTimelineSheet = (): ExcelSheetData => {
    const { translate, userRole, locationStatsActivityTimeline } = this.props;
    // get the name of the chart
    const name: string = translate('stats.activityTimeline');
    // get the column labels associated to the chart data
    // @ts-expect-error - TS2322 - Type 'readonly [] | readonly ["numNewUser", "numScans", "numRedemptions", "numActiveUser", "numLostUser"] | readonly ["numNewUser", "numScans", "numRedemptions", "numActiveUser"]' is not assignable to type 'string[]'.
    const dataColumns: Array<string> = chartList[userRole];
    // create the list of ExcelSheetColumnData with the static Date column and the translated columns of the chart
    const columns: Array<ExcelSheetColumnData> = [
      { title: 'Date' },
      ...dataColumns.map(col => ({ title: translate(CHART_STYLING.activityTimeline[col].label) }))
    ];
    // create the list of ExcelSheetCellData with the data of the chart for the location activity timeline
    const data: Array<Array<ExcelSheetCellData>> = locationStatsActivityTimeline.map(row => [
      { value: row.dateDay },
      ...dataColumns.map(col => ({ value: row[col] }))
    ]);
    // return the sheet for the activity timeline
    return { name, columns, data };
  };

  render() {
    const {
      locationStatsOverview,
      locationStatsDetailOverview,
      locationStatsActivityTimeline,
      totalPages,
      currentPage,
      totalElements,
      resolution,
      locationList,
      offeringList,
      classes,
      selectedLocation,
      handleSelectLocation,
      lang,
      handlePageChange,
      isRequesting,
      days
    } = this.props;
    // set the global variables to the retrieved props
    // @ts-expect-error - TS2322 - Type 'Location[]' is not assignable to type 'never[]'.
    locations = locationList;
    currentLanguage = lang;
    selectedDays = days;

    const pointDistribution = selectedLocation
      ? Object.keys(
          locationStatsOverview.filter(l => l.locationId === selectedLocation)[0].openingCode
        ).map(t => ({
          name: t,
          value: locationStatsOverview.filter(l => l.locationId === selectedLocation)[0]
            .openingCode[t]
        }))
      : [];

    if (isRequesting) {
      return (
        <>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.firstCol}>
                    <TranslatedText text={{ key: 'location.name' }} />
                  </TableCell>
                  <TableCell align="right" className={classes.middleCol}>
                    <TranslatedText text={{ key: 'stats.totalUser' }} />
                  </TableCell>
                  <TableCell align="right" className={classes.middleCol}>
                    <TranslatedText text={{ key: 'stats.numNewUser' }} />
                  </TableCell>
                  <TableCell align="right" className={classes.middleCol}>
                    <TranslatedText text={{ key: 'stats.activeUser' }} />
                  </TableCell>
                  <TableCell align="right" className={classes.middleCol}>
                    <TranslatedText text={{ key: 'stats.numScans' }} />
                  </TableCell>
                  <TableCell align="right" className={classes.middleCol}>
                    <TranslatedText text={{ key: 'stats.numRedemptions' }} />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </>
      );
    }

    return (
      <>
        <Paper style={{ paddingTop: 20, paddingBottom: 20 }} />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstCol}>
                  <TranslatedText text={{ key: 'location.name' }} />
                </TableCell>
                <TableCell align="right" className={classes.middleCol}>
                  <TranslatedText text={{ key: 'stats.totalUser' }} />
                </TableCell>
                <TableCell align="right" className={classes.middleCol}>
                  <TranslatedText text={{ key: 'stats.numNewUser' }} />
                </TableCell>
                <TableCell align="right" className={classes.middleCol}>
                  <TranslatedText text={{ key: 'stats.activeUser' }} />
                </TableCell>
                <TableCell align="right" className={classes.middleCol}>
                  <TranslatedText text={{ key: 'stats.numScans' }} />
                </TableCell>
                <TableCell align="right" className={classes.middleCol}>
                  <TranslatedText text={{ key: 'stats.numRedemptions' }} />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Paper>
        {locationStatsOverview.length > 0 && locationList.length > 0 ? (
          locationStatsOverview.map(e => (
            <Paper
              key={e.locationId}
              style={selectedLocation === e.locationId ? { marginTop: 12, marginBottom: 12 } : {}}
            >
              <Table>
                <TableBody>
                  <TableRow hover onClick={() => handleSelectLocation(e.locationId)}>
                    <TableCell
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.firstCol} ${classes.selectedLocation}`
                          : classes.firstCol
                      }
                    >
                      {this.mapEntityName(locationList, e.locationId, 'name', lang)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.middleCol} ${classes.selectedLocation}`
                          : classes.middleCol
                      }
                    >
                      {e.totalUser}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.middleCol} ${classes.selectedLocation}`
                          : classes.middleCol
                      }
                    >
                      {this.statsValue(e.newUser)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.middleCol} ${classes.selectedLocation}`
                          : classes.middleCol
                      }
                    >
                      {this.statsValue(e.activeUser)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.middleCol} ${classes.selectedLocation}`
                          : classes.middleCol
                      }
                    >
                      {this.statsValue(e.codeCollects)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        selectedLocation === e.locationId
                          ? `${classes.middleCol} ${classes.selectedLocation}`
                          : classes.middleCol
                      }
                    >
                      {this.statsValue(e.cardCollects)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {locationStatsDetailOverview.length > 0 &&
                locationStatsDetailOverview[0].locationId === e.locationId && (
                  <Collapse in={selectedLocation === e.locationId}>
                    <Table>
                      <TableBody>
                        {locationStatsDetailOverview.map(
                          o =>
                            offeringList.find(i => i.id === o.offeringId) && (
                              <TableRow key={e.locationId + o.offeringId}>
                                <TableCell className={classes.firstCol}>
                                  {this.mapEntityName(offeringList, o.offeringId, 'name', lang)}
                                </TableCell>
                                <TableCell align="right" className={classes.middleCol}>
                                  {o.totalUser}
                                </TableCell>
                                <TableCell align="right" className={classes.middleCol}>
                                  {this.statsValue(o.newUser)}
                                </TableCell>
                                <TableCell align="right" className={classes.middleCol}>
                                  {this.statsValue(o.activeUser)}
                                </TableCell>
                                <TableCell align="right" className={classes.middleCol}>
                                  {this.statsValue(o.codeCollects)}
                                </TableCell>
                                <TableCell align="right" className={classes.middleCol}>
                                  {this.statsValue(o.cardCollects)}
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                    {selectedLocation && (
                      <Paper style={{ padding: 24 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <AuthorizableBarChart
                              name="activityTimeline"
                              height={250}
                              title="stats.activityTimeline"
                              chartData={locationStatsActivityTimeline}
                              chartList={chartList}
                              xaxis="dateDay"
                              resolution={resolution}
                              tooltip
                              legend
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <PieChart
                              height={250}
                              name="openingCode"
                              title="stats.openingCode"
                              chartData={pointDistribution}
                              tooltip
                              legend
                            />
                          </Grid>
                          {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                          <Grid item xs={12} sm={12} align="center">
                            {/*
                      display the render props DataExporter component with a custom button for exporting the offerings table and
                      the activity timeline of the locations
                    */}
                            <DataExporter
                              sheets={this.getLocationSheets(selectedLocation)}
                              fileName={
                                this.mapEntityName(locationList, e.locationId, 'name', lang) || ''
                              }
                            >
                              {generateExcel => (
                                <Button
                                  variant="contained"
                                  label={{ key: 'general.export.excel' }}
                                  onClick={generateExcel}
                                />
                              )}
                            </DataExporter>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Collapse>
                )}
            </Paper>
          ))
        ) : (
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <div style={{ textAlign: 'center' }}>
                      <TranslatedText text={{ key: 'stats.noDataAvailable' }} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
        {locationStatsOverview.length < totalElements && (
          <Paper style={{ textAlign: 'center' }} elevation={0}>
            <Pagination
              currentPage={currentPage}
              totalElements={totalElements}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              items={10}
            />
          </Paper>
        )}
      </>
    );
  }
}

// refactored version of the class method mapEntityName to be used as formatter for the location.name field
// of the tableWithExportableData HOC
const getLocationName = (locationId: number): string => {
  const location = locations.find(({ id }) => id === locationId);
  const name = _.get(location, 'name');
  if (!name) {
    return '';
  }
  return currentLanguage ? parseName(name, currentLanguage) || '' : name;
};

// refactored version of the class method statsValue to be used as formatter for the stat fields
// of the tableWithExportableData HOC
const getStatValue = (stat?: any | null): string => `${_.get(stat, selectedDays, 0)}`;

const fields = [
  {
    name: 'locationId',
    label: 'location.name',
    formatter: val => getLocationName(val)
  },
  {
    name: 'totalUser',
    label: 'stats.totalUser',
    formatter: val => `${val}`
  },
  {
    name: 'newUser',
    label: 'stats.numNewUser',
    formatter: val => getStatValue(val)
  },
  {
    name: 'activeUser',
    label: 'stats.activeUser',
    formatter: val => getStatValue(val)
  },
  {
    name: 'codeCollects',
    label: 'stats.numScans',
    formatter: val => getStatValue(val)
  },
  {
    name: 'cardCollects',
    label: 'stats.numRedemptions',
    formatter: val => getStatValue(val)
  }
];
const dataPath = 'locationStatsOverview';
const name = 'location.locations';

const StyledTable = withStyles(styles, { withTheme: true })(
  withLocale(LocationStatsTableUnconnected)
);
const ExportableTable = tableWithExportableData<
  JSX.LibraryManagedAttributes<typeof StyledTable, React.ComponentProps<typeof StyledTable>>,
  any
>(StyledTable, dataPath, fields, name);
export default ExportableTable;
