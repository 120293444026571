import * as React from 'react';
import TextSelectSearch from '../TextSelectSearch';
import businessProfileSearchApi from 'serviceNew/api/businessProfileSearch';
// @ts-expect-error - TS2305 - Module '"formik"' has no exported member 'FieldRenderProps'.
import { FieldRenderProps as FormikFieldRenderProps } from 'formik';
import { FormContextProps } from 'App/components/forms/GenericForm';
import { Option } from 'App/components/TextSelect';
import { BusinessProfile } from '@poinz/api';

type Props = FormikFieldRenderProps &
  FormContextProps &
  JSX.LibraryManagedAttributes<
    typeof TextSelectSearch,
    React.ComponentProps<typeof TextSelectSearch>
  >;

const BusinessProfileSearchTextField = ({ defaultOptions, ...props }: Props) => {
  const [options, setOptions] = React.useState<Array<Option>>([]);
  const id = props.field.value || '';
  const selected = options ? options.find(option => option.value === id) : undefined;
  const inputLabel = selected?.label?.context?.value;

  React.useEffect(() => {
    if (defaultOptions && !options.length) {
      setOptions(defaultOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const handleInputChange = async (e: any) => {
    const value = e.target.value;
    const data = await businessProfileSearchApi.searchBusinessProfileByName(value);
    if (data?.length) {
      setOptions(
        data.map(bp => ({
          value: bp.id,
          label: {
            key: 'text.no.translation',
            context: { value: bp.name }
          }
        }))
      );
    } else {
      setOptions([]);
    }
  };

  return (
    <TextSelectSearch
      value={id}
      inputProps={{
        onChange: handleInputChange,
        value: inputLabel
      }}
      options={options}
      {...props}
    />
  );
};

export default BusinessProfileSearchTextField;
