import * as React from 'react';
import { Icon, Text, IconButton } from 'App/components';
import { useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { TableAction } from 'App/components/DataTable/DataTable';
import { useStyles } from './MoreMenuButton.styles';

type Props = {
  actions: TableAction<any>[];
  data: any;
  index: number;
  size?: number;
};

const DEFAULT_ICON_SIZE = 18;

export default function MoreMenuButton({ actions, data, index, size = DEFAULT_ICON_SIZE }: Props) {
  const classes = useStyles();
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const filteredActions: TableAction<any>[] = actions.filter(
    action => !action.hide || (typeof action.hide === 'function' && !action.hide(data))
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div ref={menuRef}>
        <IconButton size={size} icon="more_vertical" onClick={handleOpen} />
      </div>
      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {filteredActions.map((item, idx) => (
          <MenuItem
            onClick={() => {
              item.action(data, index);
              handleClose();
            }}
            key={idx.toString()}
          >
            {/* @ts-expect-error - TS2769 - No overload matches this call. */}
            <Icon icon={item.icon} width={20} height={20} />
            <Text variant="body2" className={classes.label}>
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
