import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { makeStyles } from '@mui/styles';
import { useDialog } from 'App/providers/DialogProvider';
import { difference } from '@poinz/utils';
import _ from 'lodash';
import FormSubmissionDialog from '../FormSubmissionDialog';

const useStyles = makeStyles(theme => ({
  linearProgress: {
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'. | TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0
  },
  form: {
    flex: 1
  }
}));

export type FormContextProps = {
  isUpdate: boolean;
};

const FormContext = React.createContext({
  isUpdate: false
});

type Props<T> = {
  className?: string | null | undefined;
  // @ts-expect-error - TS2314 - Generic type 'FormikProps' requires 1 type argument(s).
  children: (formProps: FormikProps) => React.ReactElement;
  initialValues?: Partial<T> | null | undefined;
  onSubmit: (values: Partial<T>, allValues?: T) => Promise<void>;
  isUpdate: boolean;
  formikRef?: React.ElementRef<any> | null;
  enableReinitialize?: boolean;
  noValidate?: boolean;
};

function GenericForm<T extends Record<any, any>>(props: Props<T>) {
  const {
    className,
    initialValues,
    isUpdate,
    onSubmit,
    children,
    formikRef = null,
    enableReinitialize = false,
    noValidate
  } = props;

  const classes = useStyles();
  const { showApiError } = useDialog();

  const handleSubmit = React.useCallback(
    async (values: T, actions: any): Promise<void> => {
      const changedValues =
        isUpdate && initialValues ? difference(values, initialValues, false) : values;
      await showApiError(onSubmit, { rethrow: false })(
        changedValues,
        isUpdate ? values : undefined,
        // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 3.
        actions
      );
      actions.setSubmitting(false);
    },
    [initialValues, isUpdate, onSubmit, showApiError]
  );

  // @ts-expect-error - TS2314 - Generic type 'FormikProps' requires 1 type argument(s).
  function renderForm(formProps: FormikProps) {
    const { isSubmitting, values, initialValues: formInitialValues } = formProps;

    const hasChangedValues = Object.values(difference(values, formInitialValues || {}, true)).some(
      val => (typeof val === 'object' ? !_.values(val).every(_.isEmpty) : val != null && val !== '')
    );

    return (
      // @ts-expect-error - TS2322 - Type '{ children: Element[]; className: string | null | undefined; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.
      <div {...{ className }}>
        <FormSubmissionDialog open={isSubmitting} />
        {/* @ts-expect-error - TS2322 - Type '{ submitting: any; isUpdate: boolean; }' is not assignable to type '{ isUpdate: boolean; }'. */}
        <FormContext.Provider value={{ submitting: isSubmitting, isUpdate }}>
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate={!!noValidate}
          >
            {children({ ...formProps, hasChangedValues, isSubmitting })}
          </form>
        </FormContext.Provider>
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'LegacyRef<Formik<T>> | undefined'.
      ref={formikRef}
      render={renderForm}
      onSubmit={handleSubmit}
      {...{ initialValues }}
    />
  );
}

GenericForm.defaultProps = {
  isUpdate: false
};

export type GenericFormProps = JSX.LibraryManagedAttributes<
  typeof GenericForm,
  React.ComponentProps<typeof GenericForm>
>;

export function withFormContext<P extends FormContextProps, C extends React.ComponentType<P>>(
  Comp: C
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>, FormContextProps>
> {
  return (props: Record<any, any>) => (
    <FormContext.Consumer>
      {(context: FormContextProps) => <Comp {...(context as any)} {...props} />}
    </FormContext.Consumer>
  );
}

export function useFormContext() {
  return React.useContext(FormContext);
}

export default GenericForm;
