import * as React from 'react';
import MUISwitch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles'
import { TranslationObject } from 'serviceNew/locale';

import clsx from 'clsx';
import InfoText from './InfoText';
import FormLabel from './FormLabel';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles({
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
});

type Props = {
  tooltip?: TranslationObject,
  label?: TranslationObject,
  value: boolean,
  disabled: boolean,
  onChange: (value: boolean) => void,
  className?: string,
  inline?: boolean
};

function Switch(props: Props) {
  const { label, className, tooltip, value, onChange, inline, ...restProps } = props;

  const { container, switchLabel, outerContainer } = useStyles();

  function handleChange(event: Event, checked: boolean) {
    onChange(checked);
  }

  return (
    <div className={clsx(outerContainer, className)}>
      {inline && <FieldLabel label={{ key: 'text.no.translation', context: { value: '' } }} />}
      <InfoText label={tooltip}>
        <div className={container}>
          <div>
{ /* @ts-expect-error - TS2322 - Type '(event: Event, checked: boolean) => void' is not assignable to type '(event: ChangeEvent<HTMLInputElement>, checked: boolean) => void'. */}
            <MUISwitch {...restProps} onChange={handleChange} checked={value} />
          </div>
          <div>{label && <FormLabel className={switchLabel} {...{ label }} />}</div>
        </div>
      </InfoText>
    </div>
  );
}

Switch.defaultProps = {
  tooltip: undefined,
  label: undefined,
  disabled: false,
};

export default Switch;
