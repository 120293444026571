import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import { Text, RawText } from 'App/components';
import Icon, { IconType } from 'App/components/Icon';
import clsx from 'clsx';

const DEFAULT_SIZE = 60;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(3),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    borderRadius: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white
  },
  iconContainer: {
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    width: ({ size }) => size,
    // @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    height: ({ size }) => size,
    flexShrink: 0,
    borderRadius: '50%',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.primary.light,
    alignItems: 'center',
    justifyContent: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginRight: theme.spacing(2)
  },
  icon: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.primary.main
  },
  textContainer: {
    justifyContent: 'center'
  }
}));

type Props = {
  className?: string;
  icon?: IconType;
  label: TranslationObject;
  value: number | string | number[] | string[];
  size: number;
};

function DataDisplayBox(props: Props) {
  const { className, icon, label, value, size } = props;

  const classes = useStyles({ size });

  return (
    <div className={clsx(classes.container, className)}>
      {icon && (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} icon={icon} size={size * 0.5} />
        </div>
      )}
      <div className={classes.textContainer}>
        <Text noWrap context={label.context} variant="subtitle2">
          {label.key}
        </Text>
        {[...(Array.isArray(value) ? value : [value])].map((val, idx) =>
          val ? (
            <RawText key={val || idx.toString()} variant="body2" color="textSecondary">
              {val.toString()}
            </RawText>
          ) : null
        )}
      </div>
    </div>
  );
}

DataDisplayBox.defaultProps = {
  size: DEFAULT_SIZE
};

export default DataDisplayBox;
