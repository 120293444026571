import { NewPaginationModel } from '../../types';
import { PoinzApiClient } from '../../api';
import { Egift, PaginatedEgiftParams, EgiftPayload } from './types';
import { ROOT } from './config';

export const VERSION = '/v2';
export const EGIFTS = '/egifts';

const apiRoot = `${ROOT}${VERSION}${EGIFTS}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedEgiftParams): Promise<NewPaginationModel<Egift>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

const createEGift =
  (apiClient: PoinzApiClient) =>
  async (body: EgiftPayload): Promise<any> => {
    const result = await apiClient.post(apiRoot, {
      body
    });
    return result;
  };

export default (apiClient: PoinzApiClient) => ({
  getList: getList(apiClient),
  createEGift: createEGift(apiClient)
});
