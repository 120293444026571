import * as React from 'react';
import clsx from 'clsx';

import { TranslationObject } from 'serviceNew/locale';
import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Text from './Text';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(-2),
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(-2),
    flexWrap: 'wrap'
  },
  button: {
    flexBasis: 300,
    flexGrow: 1,
    flexShrink: 0,
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2),
// @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius,
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2),
    borderWidth: 1,
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column'
  },
  buttonSelected: {
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[300]
  },
  input: {
    cursor: 'inherit',
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0
  },
  description: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1)
  }
}));

type Option<T> = {
  label: TranslationObject,
  description: TranslationObject,
  value: T
};

type Props<T> = {
  value: T,
  options: Option<T>[],
  onChange: (arg1: T) => void,
  name?: string
};

function SelectWithDetails<T extends string | number>(props: Props<T>) {
  const { name, value, options, onChange } = props;

  const classes = useStyles();

  function renderOptions() {
    return options.map(o => (
      <ButtonBase
        className={clsx(classes.button, value === o.value && classes.buttonSelected)}
        key={o.value}
// @ts-expect-error - TS2769 - No overload matches this call.
        htmlFor={o.value}
        component="label"
      >
        <Text variant="h5" context={o.label.context}>
          {o.label.key}
        </Text>
        {o.description && (
          <Text
            className={classes.description}
            color="textSecondary"
            context={o.description.context}
          >
            {o.description.key}
          </Text>
        )}
        <input
          className={classes.input}
// @ts-expect-error - TS2322 - Type 'string | number' is not assignable to type 'string | undefined'.
          id={o.value}
          name={name}
          value={o.value}
          type="radio"
          checked={o.value === value}
          {...{ onChange }}
        />
      </ButtonBase>
    ));
  }

  return <div className={classes.container}>{renderOptions()}</div>;
}

export default React.memo<Props<any>>(SelectWithDetails);
