import { LANGUAGES_TRANSLATED, MultiLanguageString } from './translations';

export const parseName = (name: string | MultiLanguageString, lang: string) => {
  // if the input name is a string or null or undefined
  if (!name || typeof name === 'string') {
    return name;
  }
  // if the name object has the lang key
  if (name[lang]) {
    return name[lang];
  }
  // find the key whose value inside the name object is not null or undefined
  const key = Object.keys(LANGUAGES_TRANSLATED).find(key => name[key]);
  return key ? name[key] : undefined;
};
