import {getMessage} from 'serviceNew/notifications';
import { OrderedPictureUpdate } from 'serviceNew/model/common';
import { createMultipartRequest, getIdFromHeaders } from '@poinz/api';
import { IdType } from '@poinz/api';
import { callEndpoint, callNotificationEndpoint } from './apiClient';
import { Deal, IdentifierPicture } from '../model/deal';
import { mapFormWithImages } from '../picture';

const BP = 'business-profiles';
const CC = 'contract-companies';
const DEALS = 'deals';

const ENTITY = 'deal.deal';

const buildBaseDealsURL = (bpId: IdType): string => `/${BP}/${bpId}/${DEALS}`;
const buildDealsURL = (dealId: IdType, bpId: IdType): string => `${buildBaseDealsURL(bpId)}/${dealId}`;

const buildCCBaseDealsURL = (ccId: IdType): string => `/${CC}/${ccId}/${DEALS}`;

function createRequest(deal: Deal) {
  const multiPartData: Array<{
    data: File,
    name: string
  }> = [];
  const dealForm = mapFormWithImages({ form: deal, imageFields: ['picture'], multiPartData });
  return createMultipartRequest('dealForm', dealForm, multiPartData);
}

async function getDeal(id: IdType, bpId: IdType): Promise<Deal> {
  const result = await callEndpoint(`${buildDealsURL(id, bpId)}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return new Deal(result.body);
}

const getTranslatedDeal = async (id: IdType, bpId: IdType): Promise<Deal> => {
  const result = await callNotificationEndpoint(
    `${buildDealsURL(id, bpId)}/translate`,
    'PUT',
    { headers: new Headers(), authenticated: true },
    {
      success: { key: 'deal.translate.success' },
      error: { key: 'deal.translate.error' }
    }
  );

  return new Deal(result.body);
};

const cloneDeal = async (id: IdType, bpId: IdType): Promise<Deal> => {
  const result = await callNotificationEndpoint(
    buildBaseDealsURL(bpId),
    'POST',
    { headers: new Headers(), authenticated: true, queryParams: { cloneFrom: id } },
    {
      success: { key: 'deal.clone.success' },
      error: { key: 'deal.clone.error' }
    }
  );

  return new Deal(result.body);
};

async function sendDealActivationEmail(
  {
    id,
    businessProfileId,
    adminUserIdList,
  }: {
    id: IdType,
    businessProfileId: IdType,
    adminUserIdList: IdType[]
  },
): Promise<void> {
  await callNotificationEndpoint(
    `${buildDealsURL(id, businessProfileId)}/send-activation-report`,
    'POST',
    { headers: new Headers(), authenticated: true, queryParams: { adminUserIdList } },
    {
      success: { key: 'deal.activation.report.send.success' },
      error: { key: 'deal.activation.report.send.failure' }
    }
  );
}

async function getDealList(bpId: IdType): Promise<Deal[]> {
  const result = await callEndpoint(`${buildBaseDealsURL(bpId)}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return result.body.map(d => new Deal(d));
}

async function getContractCompanyDealList(ccId: IdType): Promise<Deal[]> {
  const result = await callEndpoint(`${buildCCBaseDealsURL(ccId)}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return result.body.map(d => new Deal(d));
}

async function createDealFromBusinessProfile(params: {
  businessProfileId: IdType,
  values: Partial<Deal>
}) {
  const { values, businessProfileId } = params;
// @ts-expect-error - TS2345 - Argument of type 'Partial<Deal>' is not assignable to parameter of type 'Deal'.
  const formData = createRequest(values);
  const result = await callNotificationEndpoint(
    buildBaseDealsURL(businessProfileId),
    'POST',
    {
      headers: new Headers(),
      authenticated: true,
      isMultiPart: true,
      body: formData
    },
    getMessage.create(ENTITY)
  );
  return getIdFromHeaders(result.headers);
}

async function updateDealFromBusinessProfile(
  params: {
    businessProfileId: IdType,
    dealId: IdType,
    values: Partial<Deal>
  },
): Promise<Deal> {
  const { businessProfileId, dealId, values } = params;
// @ts-expect-error - TS2345 - Argument of type 'Partial<Deal>' is not assignable to parameter of type 'Deal'.
  const formData = createRequest(values);
  const result = await callNotificationEndpoint(
    buildDealsURL(dealId, businessProfileId),
    'PUT',
    {
      headers: new Headers(),
      authenticated: true,
      isMultiPart: true,
      body: formData
    },
    getMessage.update(ENTITY)
  );
  return new Deal(result.body);
}

async function deleteDeal(
  params: {
    businessProfileId: IdType,
    dealId: IdType
  },
): Promise<void> {
  const { businessProfileId, dealId } = params;
  await callNotificationEndpoint(
    buildDealsURL(dealId, businessProfileId),
    'DELETE',
    {
      headers: new Headers(),
      authenticated: true
    },
    getMessage.delete(ENTITY)
  );
}

type AdditionalPictureParams = {
  businessProfileId: IdType,
  dealId: IdType,
  pictureForm: Array<OrderedPictureUpdate>
};
async function updateAdditionalPictures(params: AdditionalPictureParams): Promise<Array<IdentifierPicture>> {
  const { businessProfileId, dealId, pictureForm } = params;
  const multiPartData: Array<{
    data: File,
    name: string
  }> = [];
  const form = mapFormWithImages({
    form: { pictureForm },
    imageListFields: ['pictureForm'],
    multiPartData
  });
  const formData = createMultipartRequest('pictureForm', form.pictureForm, multiPartData);
  const response = await callNotificationEndpoint(
    `${buildDealsURL(dealId, businessProfileId)}/additional-pictures`,
    'PUT',
    {
      headers: new Headers(),
      authenticated: true,
      body: formData,
      isMultiPart: true
    },
    getMessage.update(ENTITY)
  );
  return response.body;
}

export default {
  getDeal,
  getDealList,
  getContractCompanyDealList,
  createDealFromBusinessProfile,
  updateDealFromBusinessProfile,
  deleteDeal,
  sendDealActivationEmail,
  updateAdditionalPictures,
  getTranslatedDeal,
  cloneDeal
};
