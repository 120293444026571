import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import DataField from './DataField';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingLeft: theme.spacing(3),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingRight: theme.spacing(3)
  }
}));

type Props = {
  data?: any[];
  className?: string;
  textClassName?: string;
  variant?: string;
};

function RowDataDisplay(props: Props) {
  const { data, className, textClassName, variant } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.tableRow}>
        {data &&
          data.map((e, idx) => (
            <DataField {...e} key={idx.toString()} className={textClassName} variant={variant} />
          ))}
      </div>
    </div>
  );
}

export default RowDataDisplay;
