import {
  TAC_TYPE_TRANSLATIONS,
  CODE_TYPE_TRANSLATIONS,
  ALL_CODE_TYPE_TRANSLATIONS
} from 'serviceNew/model/code';
import { PoinzStatsOverviewType } from 'App/MainContainer/Dashboard/PoinzStats/PoinzStats';

export const USER_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  POINZ_ADMIN: 'POINZ_ADMIN',
  PARTNER_ADMIN: 'PARTNER_ADMIN',
  USER: 'USER'
} as const;

export const USER_ROLE_HIERARCHY = {
  SUPER_ADMIN: 0,
  POINZ_ADMIN: 1,
  PARTNER_ADMIN: 2,
  USER: 3
} as const;

export const DISCOUNT_TYPE = {
  PERCENTAGE: 'coupon.discount.percentage',
  AMOUNT: 'coupon.discount.amount',
  AMOUNT_PER_UNIT: 'coupon.discount.amount.per.unit',
  CUSTOM: 'coupon.discount.custom'
} as const;

export const DEAL_CATEGORY = {
  FOOD: 'category.food',
  SHOPPING_SERVICES: 'category.shopping_services',
  LEISURE: 'category.leisure',
  WELLNESS_BEAUTY: 'category.wellnessBeauty',
  TRAVEL: 'category.travel'
} as const;

export const DEAL_TYPE_TRANSLATIONS = {
  DEAL: 'deal.deal',
  COUPON: 'deal.coupon',
  COUPON_MSG: 'deal.couponMsg'
} as const;

export const DEAL_STATUS_TYPE = {
  ACTIVE: 'general.active',
  EXPIRED: 'general.expired',
  INACTIVE: 'general.inactive'
} as const;

export const DEAL_STATUS_COLOR = {
  ACTIVE: '#009688',
  EXPIRED: 'grey',
  INACTIVE: '#ffc107'
} as const;

export const DEAL_COLLECT_STATE = {
  REDEEMED: 'deal.redeemed',
  FAILED: 'deal.failed',
  PURCHASED: 'deal.purchased',
  CANCELLED: 'deal.cancelled',
  EXPIRED: 'deal.expired'
} as const;

export const DEAL_RULE_REPETITION_TYPE = {
  NONE: 'dealRule.none',
  DAILY: 'dealRule.daily',
  WEEKLY: 'dealRule.weekly'
} as const;

export const MESSAGE_TYPE = {
  INFO: 'message.info'
  // FEEDBACK: 'message.feedback',
} as const;

export const TARGETING_RULE_TYPE = {
  LAST_VISIT: 'targetRule.lastVisit',
  GENDER: 'targetRule.gender',
  AGE: 'targetRule.age',
  LOCATION: 'targetRule.location',
  OFFERING_EXCLUSION: 'targetRule.offeringExclusion',
  BUSINESS_PROFILE_EXCLUSION: 'targetRule.businessProfileExclusion',
  IN_APP_USER: 'targetRule.inAppUser'
} as const;

export const MESSAGE_REWARD_TYPE = {
  POINZ: 'message.reward.poinz',
  COUPON: 'message.reward.coupon',
  NONE: 'message.reward.none'
} as const;

export const MESSAGE_STATE_TYPE = {
  ACTIVE: 'general.active',
  TRIGGER: 'general.trigger',
  NOT_SENT: 'message.notSent',
  SENT: 'message.sent',
  EXPIRED: 'general.expired'
} as const;

export const MESSAGE_STATE_COLOR = {
  ACTIVE: '#009688',
  TRIGGER: 'blue',
  EXPIRED: 'grey',
  NOT_SENT: '#ffc107',
  SENT: '#009688'
} as const;

export const LOCATION_MODE = {
  ALL: 'location.mode.all',
  CUSTOM: 'location.mode.custom',
  NONE: 'location.mode.none'
} as const;

export const DISCOUNT_UNIT = {
  CHF: 'CHF',
  RAPPEN: 'RAPPEN'
} as const;

export const UNIT = {
  LITER: 'LITER'
} as const;

export const RESOLUTION_TYPE = {
  DAILY: 'general.daily',
  WEEKLY: 'general.weekly',
  MONTHLY: 'general.monthly'
} as const;

export const APP_LINK_MEDIUM = {
  NEWSLETTER: 'applink.newsletter',
  MESSAGE: 'applink.poinz.message',
  AD: 'applink.ad',
  AD_MOBILE: 'applink.ad.mobile',
  AD_WEB: 'applink.ad.web',
  OTHER: 'general.other'
} as const;

export const APP_LINK_SOURCE = {
  POINZ: 'general.poinz.app',
  FACEBOOK: 'general.facebook',
  INSTAGRAM: 'general.instagram',
  GOOGLE: 'general.google',
  RAPID_MAIL: 'general.rapid.mail'
} as const;

export const dayKeys = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
] as const;

export type DealType = keyof typeof DEAL_TYPE_TRANSLATIONS;
export type DiscountType = keyof typeof DISCOUNT_TYPE;
export type DiscountUnitType = keyof typeof DISCOUNT_UNIT;
export type UnitType = keyof typeof UNIT;
export type DealCollectState = keyof typeof DEAL_COLLECT_STATE;
export type DealRuleRepetitionType = keyof typeof DEAL_RULE_REPETITION_TYPE;
export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';
export type MessageType = keyof typeof MESSAGE_TYPE;
export type MessageRewardType = keyof typeof MESSAGE_REWARD_TYPE;
export type TargetingRuleNameType = keyof typeof TARGETING_RULE_TYPE;
export type UserRole = keyof typeof USER_ROLE;
export type DealStatusType = keyof typeof DEAL_STATUS_TYPE;
export type MessageStateType = keyof typeof MESSAGE_STATE_TYPE;
export type ResolutionType = keyof typeof RESOLUTION_TYPE;
export type AppLinkMediumType = keyof typeof APP_LINK_MEDIUM;
export type AppLinkSourceType = keyof typeof APP_LINK_SOURCE;

export const REPORT_FORMAT = {
  ZIP: 'ZIP',
  EXCEL: 'EXCEL'
} as const;

export const BUSINESS_PROFILE_STATS = {
  totalUser: {
    label: 'stats.totalUser',
    helperText: 'stats.totalUser.helperText',
    style: 'decimal',
    suffix: null
  },
  totalInappUser: {
    label: 'stats.totalInappUser',
    helperText: 'stats.totalInappUser.helperText',
    style: 'decimal',
    suffix: null
  },
  inappConversion: {
    label: 'stats.inappConversion',
    helperText: 'stats.inappConversion.helperText',
    style: 'percent',
    suffix: null
  },
  avgFrequency: {
    label: 'stats.avgFrequency',
    style: 'decimal',
    suffix: 'general.days'
  },
  totalCodeCollects: {
    label: 'stats.totalCodeCollects',
    style: 'decimal',
    suffix: null
  },
  totalPointCollects: {
    label: 'stats.totalPointCollects',
    style: 'decimal',
    suffix: null
  },
  totalCardCollects: {
    label: 'stats.totalCardCollects',
    style: 'decimal',
    suffix: null
  },
  openCards: {
    label: 'stats.openCards',
    style: 'decimal',
    suffix: null
  }
} as const;

export const DEAL_STATS = {
  dealCategory: {
    label: 'general.category',
    style: 'text',
    translation: DEAL_CATEGORY,
    suffix: null
  },
  price: {
    label: 'deal.price',
    style: 'currency',
    suffix: null
  },
  discountedPrice: {
    label: 'deal.discountedPrice',
    style: 'currency',
    suffix: null
  },
  discountPercentageValue: {
    label: 'deal.discount',
    style: 'percent',
    suffix: null
  },
  discountAmountValue: {
    label: 'deal.discount',
    style: 'currency',
    suffix: null
  },
  limitPerUser: {
    label: 'deal.limitPerUser',
    style: 'decimal',
    suffix: null
  }
} as const;

export const POINZ_STATS = {
  numNewUser: {
    label: 'stats.numNewUser',
    style: 'decimal',
    suffix: null
  },
  numCodeCollects: {
    label: 'stats.numScans',
    style: 'decimal',
    suffix: null
  },
  numInAppCodeCollects: {
    label: 'stats.numInAppCodeCollects',
    style: 'decimal',
    suffix: null
  },
  numCardRedemptions: {
    label: 'stats.numCardRedemptions',
    style: 'decimal',
    suffix: null
  },
  numDealPurchased: {
    label: 'stats.numDealPurchased',
    style: 'decimal',
    suffix: null,
    render: (data: Array<PoinzStatsOverviewType>) => {
      if (data.length === 0) {
        return 0;
      }
      const numMobileDealPurchased = data
        .filter(object => object.name === 'numMobileDealPurchased')
        .shift();
      const numWebDealPurchased = data
        .filter(object => object.name === 'numWebDealPurchased')
        .shift();
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      const totalDealsPurchased = numMobileDealPurchased.value + numWebDealPurchased.value;
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      return `${totalDealsPurchased} (📱${numMobileDealPurchased.value} 🖥 ${numWebDealPurchased.value})`;
    }
  },
  numDealRedeemed: {
    label: 'stats.numDealRedeemed',
    style: 'decimal',
    suffix: null
  }
} as const;

export const CHART_STYLING = {
  genderDistribution: {
    MALE: { label: 'general.male', color: '#EB6308' },
    FEMALE: { label: 'general.female', color: '#17BEBB' },
    OTHER: { label: 'general.other', color: '#9ce57f' },
    UNKNOWN: { label: 'general.unknown', color: '#006494' }
  },
  openingCode: {
    FLYER: { label: TAC_TYPE_TRANSLATIONS.FLYER, color: '#EB6308' },
    POSTER: { label: TAC_TYPE_TRANSLATIONS.POSTER, color: '#9293df' },
    STICKER: { label: TAC_TYPE_TRANSLATIONS.TABLE_STICKER, color: '#17BEBB' },
    FACEBOOK: { label: TAC_TYPE_TRANSLATIONS.FACEBOOK, color: '#283593' },
    MUC: { label: CODE_TYPE_TRANSLATIONS.MUC, color: '#006494' },
    OTHER: { label: TAC_TYPE_TRANSLATIONS.OTHER, color: '#9ce57f' },
    IN_APP: { label: TAC_TYPE_TRANSLATIONS.IN_APP, color: '#d0c8b7' },
    POS: { label: ALL_CODE_TYPE_TRANSLATIONS.POS, color: '#ffb316' },
    TABLE_TENT: { label: TAC_TYPE_TRANSLATIONS.TABLE_TENT, color: '#9a536a' },
    TABLE_STICKER: { label: TAC_TYPE_TRANSLATIONS.TABLE_STICKER, color: '#b71456' },
    MENU: { label: TAC_TYPE_TRANSLATIONS.MENU, color: '#1c2625' },
    WEBSITE: { label: TAC_TYPE_TRANSLATIONS.WEBSITE, color: '#27ae34' },
    TRIGGER_MSG: { label: TAC_TYPE_TRANSLATIONS.TRIGGER_MSG, color: '#00695c' },
    NEWSLETTER: { label: TAC_TYPE_TRANSLATIONS.NEWSLETTER, color: '#3b5e19' },
    LANDINGPAGE: { label: TAC_TYPE_TRANSLATIONS.LANDINGPAGE, color: '#e38fb1' },
    ROLL_UP: { label: TAC_TYPE_TRANSLATIONS.ROLL_UP, color: '#00baa8' },
    PAPER_CARD: { label: TAC_TYPE_TRANSLATIONS.PAPER_CARD, color: '#06300a' },
    PROMO_BOX: { label: TAC_TYPE_TRANSLATIONS.PROMO_BOX, color: '#8a5506' }
  },
  activityTimeline: {
    numNewUser: { color: '#EB6308', label: 'stats.numNewUser' },
    numScans: { color: '#17BEBB', label: 'stats.numScans' },
    numRedemptions: { color: '#006494', label: 'stats.numRedemptions' },
    numDealPurchased: { color: '#EB6308', label: 'stats.numPurchased' },
    numDealRedeemed: { color: '#17BEBB', label: 'stats.numRedemptions' },
    numActiveUser: { color: '#283593', label: 'stats.numActiveUser' },
    numActiveUserPos: { color: '#a300d9', label: 'stats.numActiveUserPos' },
    numLostUser: { color: '#ff3232', label: 'stats.numLostUser' },
    numInAppCodeCollects: { color: '#00695c', label: 'stats.numInAppCodeCollects' },
    numDealCancelled: { color: '#006494', label: 'stats.numDealCancelled' },
    numSent: { color: '#006494', label: 'stats.numSent' },
    numRead: { color: '#17BEBB', label: 'stats.numRead' },
    numPushed: { color: '#283593', label: 'stats.numPushed' },
    numCollected: { color: '#EB6308', label: 'stats.numCollected' }
  },
  marketingCampaignTimeline: {
    totalPlayer: { color: '#EB6308', label: 'marketingCampaign.totalPlayer' },
    totalPlayerNotFinished: { color: '#17BEBB', label: 'marketingCampaign.totalPlayerNotFinished' },
    totalWinner: { color: '#006494', label: 'marketingCampaign.totalWinner' },
    totalWinnerNotCollected: {
      color: '#EB6308',
      label: 'marketingCampaign.totalWinnerNotCollected'
    },
    totalPlayerWithConsolationPrize: {
      color: '#17BEBB',
      label: 'marketingCampaign.totalPlayerWithConsolationPrize'
    },
    totalPlayerWithConsolationPrizeNotCollected: {
      color: '#283593',
      label: 'marketingCampaign.totalPlayerWithConsolationPrizeNotCollected'
    }
  },
  ageDistribution: {
    color: '#EB6308',
    label: 'stats.ageDistribution',
    UNKNOWN: { label: 'general.unknown' }
  },
  pointDistribution: {
    color: '#EB6308',
    label: 'stats.pointDistribution'
  },
  registeredDistribution: {
    ANONYMOUS: { label: 'general.anonymous', color: '#EB6308' },
    REGISTERED: { label: 'general.registered', color: '#17BEBB' }
  },
  locationRedemptions: {
    color: '#EB6308',
    label: 'stats.pointDistribution'
  }
} as const;

export const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const DAYLIST = {
  DAY: { label: 'general.yesterday', value: 'd1' },
  WEEK: { label: 'general.lastweek', value: 'd7' },
  MONTH: { label: 'general.lastmonth', value: 'd30' }
} as const;

export const STRING_LIMIT = {
  TITLE: 255,
  BODY: 1500
} as const;

export const MESSAGE_STATS = {
  avgTimeToCollect: {
    label: 'message.avgTimeToCollect',
    style: 'decimal',
    suffix: null
  },
  avgTimeToRead: {
    label: 'message.avgTimeToRead',
    style: 'decimal',
    suffix: null
  }
} as const;
