import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  LinearProgress
} from '@mui/material';
import { TranslatedText } from 'App/components';
import { TranslationObject, LoadingDialogProps } from './LoadingDialog.utils';

type Props = {
  children: (props: LoadingDialogProps) => React.ReactElement;
};

const Transition = React.forwardRef((props, ref) => (
  // @ts-expect-error - TS2741 - Property 'children' is missing in type '{ ref: ForwardedRef<unknown>; direction: "up"; }' but required in type 'SlideProps'.
  <Slide direction="up" {...{ ref }} {...props} />
));

function LoadingDialog(props: Props) {
  const { children } = props;
  const [message, setMessage] = React.useState<TranslationObject | null>(null);
  const setLoadingMessage = React.useCallback(newMessage => setMessage(newMessage), []);
  return (
    <>
      {children({ setLoadingMessage })}
      {/* @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'JSXElementConstructor<TransitionProps & { children: ReactElement<any, any>; }> | undefined'. */}
      <Dialog TransitionComponent={Transition} open={!!message}>
        <LinearProgress />
        <DialogTitle>
          <TranslatedText text={{ key: 'general.pleaseWait' }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message && <TranslatedText text={message} />}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function withLoadingDialog<P extends LoadingDialogProps, C extends React.ComponentType<P>>(
  Component: any
): React.ComponentType<
  Diff<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>, LoadingDialogProps>
> {
  return props => (
    <LoadingDialog>{loadingProps => <Component {...props} {...loadingProps} />}</LoadingDialog>
  );
}

export default LoadingDialog;
