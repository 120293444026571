import * as React from 'react';
import { FieldProps } from 'formik';
import _ from 'lodash';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import MultiSelect from '../MultiSelect';
import TextInput from '../TextInput';

type Props = JSX.LibraryManagedAttributes<
  typeof TextInput,
  React.ComponentProps<typeof TextInput>
> &
  FieldProps &
  FormContextProps &
  FieldRenderProps;

function MultiSelectField(props: Props) {
  const { field, form, submitting, disabled, ...restProps } = props;
  const { errors, touched } = form;

  const { name, value } = field;

  const fieldError = _.get(errors, name);
  const fieldTouched = _.get(touched, name);
  return (
    <MultiSelect
      error={!!fieldError && !!fieldTouched}
      disabled={submitting || disabled}
      {...field}
      {...restProps}
      value={value || []}
    />
  );
}

MultiSelectField.defaultProps = {
  required: false
};

// @ts-expect-error - TS2345 - Argument of type 'typeof MultiSelectField' is not assignable to parameter of type 'ComponentType<FormContextProps>'.
export default withFieldAdapter(withFormContext(MultiSelectField));
