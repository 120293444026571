import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { IUserBalance } from './types';

enum BalanceEndpointType {
  ADMIN = 'admin',
  MOBILE = 'mobile'
}
const VERSION = '/v1';
const BALANCE = '/balance';
const getApiRoot = (type: BalanceEndpointType) => `${ROOT}${VERSION}/${type}${BALANCE}`;

export const campaignEndpoints = {
  getMobileBalance: getApiRoot(BalanceEndpointType.MOBILE),
  getAdminBalance: getApiRoot(BalanceEndpointType.ADMIN)
};

const getMobileBalance = (apiClient: PoinzApiClient) => async (): Promise<IUserBalance> => {
  const result = await apiClient.get(campaignEndpoints.getMobileBalance, {});

  return result.body;
};

const getAdminBalance = (apiClient: PoinzApiClient) => async (): Promise<IUserBalance> => {
  const result = await apiClient.get(campaignEndpoints.getAdminBalance, {});

  return result.body;
};

export default (apiClient: PoinzApiClient) => ({
  getMobileBalance: getMobileBalance(apiClient),
  getAdminBalance: getAdminBalance(apiClient)
});
