import React from 'react';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { formatDateTime } from 'serviceNew/locale/dates';
import { DealCollectActivity } from 'serviceNew/model/collectActivity';
import { PLATFORM_TYPE_TRANSLATIONS } from 'serviceNew/api/dealCollect';
import { REDEMPTION_TYPE_TRANSLATIONS } from 'serviceNew/model/redemptionCode';
import { DataTable } from 'App/components';
import { ALL_CODE_TYPE_TRANSLATIONS } from 'serviceNew/model/code';
import { DEAL_TYPE_TRANSLATIONS } from 'serviceNew/model/deal';
import { DEAL_COLLECT_STATE } from 'serviceNew/model/dealCollect';
import { formatPrice } from '@poinz/utils';

type Props = {
  dataList: Array<DealCollectActivity>,
  totalElements: number,
  totalPages: number,
  pageNumber: number,
  handlePageChange: (page: number) => void,
  isLoading: boolean
} & LocaleProps;

const DealCollectsActivityTableUnconnected = ({
  dataList,
  totalPages,
  pageNumber,
  handlePageChange,
  isLoading,
  translate,
}: Props) => {
  const { isPoinzAdmin } = useAdminUser();

  const columns = [
    {
      headerLabel: { key: 'general.modified' },
      fieldName: 'modified',
      width: 220,
      fieldText: tr => formatDateTime(tr.modified)
    },
    {
      headerLabel: { key: 'deal.referenceNumber' },
      fieldName: 'referenceNumber',
      width: 160
    },
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.id' },
            fieldName: 'id',
            width: 80
          }
        ]
      : []),
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.user' },
            fieldName: 'userId',
            width: 110
          }
        ]
      : []),
    {
      headerLabel: { key: 'businessProfile.businessProfile' },
      fieldName: 'businessProfileName',
      width: 170
    },
    {
      headerLabel: { key: 'deal.deal' },
      fieldName: 'dealName',
      width: 180
    },
    {
      headerLabel: { key: 'deal.dealType' },
      fieldName: 'dealType',
      width: 120,
// @ts-expect-error - TS2345 - Argument of type 'TranslationKey | undefined' is not assignable to parameter of type 'TranslationObject | TranslationKey'.
      fieldText: tr => translate(DEAL_TYPE_TRANSLATIONS[tr.dealType])
    },
    {
      headerLabel: { key: 'general.name.optionName' },
      fieldName: 'dealOptionName',
      width: 150
    },
    ...(isPoinzAdmin
      ? [
          {
            fieldName: 'discountedPrice',
            headerLabel: { key: 'deal.price' },
            fieldText: ({ discountedPrice }) =>
              discountedPrice != null ? formatPrice(discountedPrice) : '-',
            width: 80
          }
        ]
      : []),
    ...(isPoinzAdmin
      ? [
          {
            fieldName: 'netPrice',
            headerLabel: { key: 'deal.netPrice' },
            fieldText: ({ netPrice }) => (netPrice != null ? formatPrice(netPrice) : '-'),
            width: 130
          }
        ]
      : []),
    {
      headerLabel: { key: 'deal.dealCollectState' },
      fieldName: 'dealCollectState',
      width: 130,
      fieldText: tr =>
        tr.dealCollectState === 'PURCHASED' && tr.dealType !== 'DEAL'
          ? translate('coupon.collected')
          : translate(DEAL_COLLECT_STATE[tr.dealCollectState])
    },
    {
      headerLabel: { key: 'deal.redeemedAt' },
      fieldName: 'locationName',
      width: 210
    },
    {
      headerLabel: { key: 'codeGroup.codeType' },
      fieldName: 'codeType',
      width: 170,
      fieldText: tr => translate(ALL_CODE_TYPE_TRANSLATIONS[tr.codeType])
    },
    {
      headerLabel: { key: 'general.certificate' },
      fieldName: 'certificate',
      width: 130
    },
    {
      headerLabel: { key: 'deal.redemptionType' },
      fieldName: 'deal.redemptionType',
      width: 150,
      fieldText: tr => translate(REDEMPTION_TYPE_TRANSLATIONS[tr.redemptionType])
    },
    {
      headerLabel: { key: 'deal.platform' },
      fieldName: 'platform',
      width: 130,
      fieldText: tr => translate(PLATFORM_TYPE_TRANSLATIONS[tr.appType])
    }
  ];

  return (
    <DataTable
      isLoading={isLoading}
      data={dataList}
      columns={columns}
      keyExtractor={item => item.id}
      totalPages={totalPages}
      currentPage={(pageNumber || 0) + 1}
      handlePageChange={handlePageChange}
    />
  );
};

export default withLocale(DealCollectsActivityTableUnconnected);
