import * as React from 'react';

import { Form, FormRow, Field, Text } from 'App/components';

import { TextField, TextSelectField } from 'App/components/fields';
import { AppLinkFormType, APP_LINK_MEDIUM } from 'serviceNew/model/appLink';
import { TranslationObject } from 'serviceNew/locale';
import { makeStyles } from '@mui/styles';
import { getOptionsFromEnum, getValidSourceOptions } from 'App/components/TextSelect';
import { required } from 'serviceNew/validators/validators';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  },
  field: {
    flex: 1
  }
}));

type Props = {
  onCancel: () => void;
  onSubmit: (data: AppLinkFormType) => Promise<void>;
  title?: TranslationObject;
};

function AppLinkForm(props: Props) {
  const { onCancel, onSubmit, title } = props;
  const classes = useStyles();

  function renderFormChildren({ values, setFieldValue }) {
    return (
      <div>
        {title && (
          <Text variant="h6" context={title.context}>
            {title.key}
          </Text>
        )}
        <FormRow>
          <Field
            required
            className={classes.field}
            name="campaign"
            minWidth={200}
            label={{ key: 'appLink.campaign.name' }}
            component={TextField}
            validate={required}
          />
        </FormRow>
        <FormRow>
          <Field
            required
            className={classes.field}
            name="medium"
            label={{ key: 'appLink.medium' }}
            component={TextSelectField}
            options={getOptionsFromEnum(APP_LINK_MEDIUM)}
            validate={required}
            onChange={() => setFieldValue('source', undefined)}
          />
        </FormRow>
        {values?.medium && (
          <FormRow>
            <Field
              required
              className={classes.field}
              name="source"
              label={{ key: 'appLink.source' }}
              component={TextSelectField}
              options={getValidSourceOptions(values.medium)}
              validate={required}
            />
          </FormRow>
        )}
      </div>
    );
  }

  return (
    // @ts-expect-error - TS2322 - Type '{ onSubmit: (data: AppLinkFormType) => Promise<void>; showPaper: true; className: string; submitLabel: { key: string; }; buttons: { label: { key: string; }; onClick: () => void; }[]; renderChildren: ({ values, setFieldValue }: { ...; }) => Element; }' is not assignable to type 'Pick<FormProps<{ medium: "OTHER" | "NEWSLETTER" | "MESSAGE" | "AD" | "AD_MOBILE" | "AD_WEB"; source: "FACEBOOK" | "POINZ" | "GOOGLE" | "INSTAGRAM" | "RAPID_MAIL"; campaign: string; }>, "children" | ... 7 more ... | "renderChildren">'.
    <Form
      showPaper
      className={classes.root}
      submitLabel={{ key: 'general.submit' }}
      buttons={[{ label: { key: 'general.cancel' }, onClick: onCancel }]}
      renderChildren={renderFormChildren}
      {...{ onSubmit }}
    />
  );
}

export default AppLinkForm;
