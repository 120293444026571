import { Theme } from '@mui/material/styles';

export interface IColorMapFunction {
  (theme: Theme): string;
}
export type IIndicatorColorMap = string | IColorMapFunction;

export enum IPasswordStrengthIndicator {
  NO_PASSWORD,
  VERY_WEAK,
  WEAK,
  SOLID,
  STRONG,
  VERY_STRONG
}
