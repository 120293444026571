import * as React from 'react';
import {
  UserRoles,
  UserRole,
  AdminUser,
  INTERNAL_ROLE_CONSULTANT
} from 'serviceNew/model/adminUser';
import adminUserApi from 'serviceNew/api/adminUser';
import { useAuthState } from 'serviceNew/auth';
import { usePartnerView } from '../components/Header/PartnerViewSubject';
import apiNew from 'serviceNew/api/sharedApiClient';

type AdminUserContextType = {
  adminUser: AdminUser | null | undefined;
  consultants: AdminUser[];
  userRole: UserRole;
  isPoinzAdmin: boolean;
  isSuperAdmin: boolean;
  isLoading: boolean;
  updateAdminUser: (submitForm: Partial<AdminUser>) => Promise<void>;
};

export type AdminUserProps = {
  adminUserContext: AdminUserContextType;
};

const defaultContext = {
  adminUser: undefined,
  consultants: [],
  userRole: UserRoles.USER,
  isPoinzAdmin: false,
  isSuperAdmin: false,
  isLoading: false,
  updateAdminUser: async () => undefined
} as const;

// @ts-expect-error - TS2345 - Argument of type '{ readonly adminUser: undefined; readonly consultants: readonly []; readonly userRole: any; readonly isPoinzAdmin: false; readonly isSuperAdmin: false; readonly isLoading: false; readonly updateAdminUser: () => Promise<...>; }' is not assignable to parameter of type 'AdminUserContextType'.
const AdminUserContext = React.createContext<AdminUserContextType>(defaultContext);

type Props = {
  children: React.ReactNode;
};

function AdminUserProvider(props: Props) {
  const { children } = props;

  const [adminUser, setAdminUser] = React.useState<AdminUser | null | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [consultants, setConsultants] = React.useState<AdminUser[]>([]);

  const [authState] = useAuthState();

  React.useEffect(() => {
    async function getMyAdminUser() {
      setIsLoading(true);
      try {
        const retrievedAdminUser = await adminUserApi.getMyAdminUser();
        setAdminUser(retrievedAdminUser);
      } finally {
        setIsLoading(false);
      }
    }
    if (authState != null && authState !== 'UNKNOWN') {
      getMyAdminUser();
    } else {
      setIsLoading(false);
      setAdminUser(null);
    }
  }, [authState]);

  const updateAdminUser = React.useCallback(
    async submitForm => {
      if (adminUser) {
        const updatedAdminUser = await apiNew.user.adminUser.update(adminUser.id, submitForm);

        // @ts-expect-error - TS2345 - Argument of type 'AdminUser' is not assignable to parameter of type 'SetStateAction<AdminUser | null | undefined>'.
        setAdminUser(updatedAdminUser);
      }
    },
    [adminUser]
  );

  React.useEffect(() => {
    async function loadConsultants() {
      try {
        // @ts-expect-error - TS2345 - Argument of type 'readonly [any, any, any]' is not assignable to parameter of type '(string | number | symbol)[]'.
        const retrievedConsultants = await adminUserApi.searchAdminUser(INTERNAL_ROLE_CONSULTANT);
        setConsultants(retrievedConsultants);
      } catch (e: any) {
        //
      }
    }
    if (adminUser?.isPoinzAdmin) {
      loadConsultants();
    }
  }, [adminUser]);

  const [enablePartnerView] = usePartnerView();

  const contextValue = React.useMemo(() => {
    const newContextValue = {
      ...defaultContext,
      consultants,
      isLoading,
      updateAdminUser
    } as const;
    if (adminUser) {
      // @ts-expect-error - TS2540 - Cannot assign to 'adminUser' because it is a read-only property.
      newContextValue.adminUser = adminUser;
      // @ts-expect-error - TS2540 - Cannot assign to 'userRole' because it is a read-only property.
      newContextValue.userRole = enablePartnerView ? UserRoles.PARTNER_ADMIN : adminUser.role;
      // @ts-expect-error - TS2540 - Cannot assign to 'isPoinzAdmin' because it is a read-only property.
      newContextValue.isPoinzAdmin = enablePartnerView ? false : adminUser.isPoinzAdmin;
      // @ts-expect-error - TS2540 - Cannot assign to 'isSuperAdmin' because it is a read-only property.
      newContextValue.isSuperAdmin = enablePartnerView ? false : adminUser.isSuperAdmin;
    }

    return newContextValue;
  }, [adminUser, consultants, enablePartnerView, isLoading, updateAdminUser]);

  // @ts-expect-error - TS2322 - Type '{ readonly consultants: AdminUser[]; readonly isLoading: boolean; readonly updateAdminUser: (submitForm: any) => Promise<void>; readonly adminUser: undefined; readonly userRole: string; readonly isPoinzAdmin: false; readonly isSuperAdmin: false; }' is not assignable to type 'AdminUserContextType'.
  return <AdminUserContext.Provider value={contextValue}>{children}</AdminUserContext.Provider>;
}

export const useAdminUser = () => React.useContext(AdminUserContext);

export function withAdminUser<P extends AdminUserProps, Comp extends React.ComponentType<P>>(
  C: any
): React.ComponentType<any> {
  return (props: any) => (
    <AdminUserContext.Consumer>
      {(context: AdminUserContextType) => <C adminUserContext={context} {...props} />}
    </AdminUserContext.Consumer>
  );
}

export default AdminUserProvider;
