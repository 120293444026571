import {getIdFromHeaders} from '@poinz/api';
import { IdType } from '@poinz/api';
import { AdminUser, InternalRole } from 'serviceNew/model/adminUser';
import { callEndpoint } from './apiClient';

const ROOT = '/admin-users';

type CreateUserQuery = {
  isStudioUser: boolean | null | undefined
};

async function createAdminUser(contractCompanyId: IdType, adminUser: AdminUser, query: CreateUserQuery): Promise<IdType> {
  const result = await callEndpoint(ROOT, 'POST', {
    headers: new Headers(),
    authenticated: true,
    body: adminUser,
    queryParams: { contractCompanyId, ...query }
  });
  return getIdFromHeaders(result.headers);
}

async function getAdminUser(id: IdType): Promise<AdminUser> {
  const result = await callEndpoint(`${ROOT}/${id}`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return new AdminUser(result.body);
}

async function softDeleteAdminUser(id: IdType): Promise<void> {
  await callEndpoint(`${ROOT}/${id}`, 'DELETE', { headers: new Headers(), authenticated: true });
}

async function resendConfirmationEmail(id: IdType): Promise<void> {
  await callEndpoint(`${ROOT}/${id}/resend-confirmation-mail`, 'POST', {
    headers: new Headers(),
    authenticated: true
  });
}

async function getMyAdminUser(): Promise<AdminUser> {
  const result = await callEndpoint(`${ROOT}/me`, 'GET', {
    headers: new Headers(),
    authenticated: true
  });
  return new AdminUser({ ...result.body, userRole: 'PARTNER_ADMIN' });
}

async function searchAdminUser(internalRoleList: InternalRole[]): Promise<AdminUser[]> {
  const result = await callEndpoint(`${ROOT}/search`, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { internalRoleList }
  });
  return result.body.map(u => new AdminUser(u));
}

async function searchAdminUserByEmail(email: string): Promise<AdminUser[]> {
  const result = await callEndpoint(ROOT, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { search: email }
  });
  return result.body.content.map(u => new AdminUser(u));
}

export default {
  createAdminUser,
  getAdminUser,
  softDeleteAdminUser,
  resendConfirmationEmail,
  getMyAdminUser,
  searchAdminUser,
  searchAdminUserByEmail
};
