import * as React from 'react';

import { DragHandleProps, DraggableProps } from 'react-beautiful-dnd';
import { makeStyles, createStyles } from '@mui/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import { TranslationObject } from 'serviceNew/locale';
import { IconButton, Text } from 'App/components';
import Icon, { IconType } from 'App/components/Icon';

const useStyles = makeStyles(
  createStyles(theme => ({
    root: {
      flex: 1
    },
    children: {
      flex: 1
    },
    dragHandle: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-2),
      color: theme.palette.grey[400]
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    orangeAvatar: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    },
    redAvatar: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main
    },
    greyAvatar: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.common.white
    },
    summary: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1
    },
    descr: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    detailsRoot: {
      padding: theme.spacing(2)
    },
    summaryRoot: {
      padding: theme.spacing(0, 2)
    }
  }))
);

export type PanelState = 'COMPLETED' | 'NOT_COMPLETED' | 'ERROR';

export const PANEL_STATE = {
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  ERROR: 'ERROR'
} as const;

export type StateExpansionPanelProps = {
  title: TranslationObject;
  state: PanelState;
  isOpen?: boolean;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  subtitle?: TranslationObject | null | undefined;
  showDragIndicator?: boolean;
  dragHandleProps?: DragHandleProps | null | undefined;
  draggableProps?: DraggableProps;
  innerRef?: any | null | undefined;
  children: React.ReactNode;
  removeBlock?: () => unknown;
};

function StateExpansionPanel(props: StateExpansionPanelProps) {
  const {
    title,
    subtitle,
    state,
    children,
    showDragIndicator,
    draggableProps,
    dragHandleProps,
    innerRef,
    removeBlock,
    isOpen,
    onChange
  } = props;

  const classes = useStyles();

  function getIconName(): IconType {
    if (state === 'ERROR') {
      return 'clear';
    }
    return 'done';
  }

  function getAvatarClass() {
    if (state === 'NOT_COMPLETED') {
      // @ts-expect-error - TS2339 - Property 'greyAvatar' does not exist on type 'ClassNameMap<never>'.
      return classes.greyAvatar;
    }
    if (state === 'ERROR') {
      // @ts-expect-error - TS2339 - Property 'redAvatar' does not exist on type 'ClassNameMap<never>'.
      return classes.redAvatar;
    }
    // @ts-expect-error - TS2339 - Property 'orangeAvatar' does not exist on type 'ClassNameMap<never>'.
    return classes.orangeAvatar;
  }

  function renderDelete() {
    if (typeof removeBlock === 'function') {
      return (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            removeBlock();
          }}
          icon="delete"
        />
      );
    }
    return null;
  }

  return (
    <Accordion
      expanded={isOpen}
      ref={innerRef}
      // @ts-expect-error - TS2339 - Property 'root' does not exist on type 'ClassNameMap<never>'.
      className={classes.root}
      {...{ onChange }}
      {...draggableProps}
    >
      {/* @ts-expect-error - TS2339 - Property 'summaryRoot' does not exist on type 'ClassNameMap<never>'. */}
      <AccordionSummary classes={{ root: classes.summaryRoot }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {showDragIndicator && dragHandleProps && (
                // @ts-expect-error - TS2339 - Property 'dragHandle' does not exist on type 'ClassNameMap<never>'.
                <div className={classes.dragHandle} {...dragHandleProps}>
                  <Icon icon="drag_indicator" />
                </div>
              )}
              {/* @ts-expect-error - TS2339 - Property 'avatar' does not exist on type 'ClassNameMap<never>'. */}
              <Avatar className={`${classes.avatar} ${getAvatarClass()}`}>
                <Icon icon={getIconName()} />
              </Avatar>
              <div>
                <Text context={title.context} variant="h5">
                  {title.key}
                </Text>
                {subtitle != null && (
                  <Text variant="subtitle1" context={subtitle.context} color="textSecondary">
                    {subtitle.key}
                  </Text>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item>{renderDelete()}</Grid>
        </Grid>
      </AccordionSummary>
      {/* @ts-expect-error - TS2339 - Property 'detailsRoot' does not exist on type 'ClassNameMap<never>'. */}
      <AccordionDetails classes={{ root: classes.detailsRoot }}>
        {/* @ts-expect-error - TS2339 - Property 'children' does not exist on type 'ClassNameMap<never>'. */}
        <div className={classes.children}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
}

StateExpansionPanel.defaultProps = {
  subtitle: undefined,
  showDragIndictor: false,
  dragHandleProps: undefined,
  draggableProps: {},
  innerRef: undefined,
  removeBlock: undefined
};

export default React.memo<StateExpansionPanelProps>(StateExpansionPanel);
