import { PoinzApiClient } from '../../api';
import { AdminUser } from './types';

const VERSION = '/v2';
const ADMIN_USERS = '/admin-users';

const apiRoot = `${VERSION}${ADMIN_USERS}`;

const getMyAdminUser = (apiClient: PoinzApiClient) => async (): Promise<AdminUser> => {
  const result = await apiClient.get(`${apiRoot}/me`, {});
  return new AdminUser({ ...result.body, userRole: 'PARTNER_ADMIN' });
};

const update =
  (apiClient: PoinzApiClient) =>
  async (id: number, adminUser: Partial<AdminUser>): Promise<AdminUser> => {
    const result = await apiClient.put(`${apiRoot}/:id`, {
      pathParams: { id },
      body: adminUser
    });
    return new AdminUser(result.body);
  };

const requestPasswordReset =
  (apiClient: PoinzApiClient) =>
  async (email: string): Promise<void> => {
    await apiClient.get(`${apiRoot}/request-password-reset`, {
      queryParams: { email },
      authenticated: false
    });
  };

export default (apiClient: PoinzApiClient) => ({
  getMe: getMyAdminUser(apiClient),
  update: update(apiClient),
  requestPasswordReset: requestPasswordReset(apiClient)
});
