import * as React from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import { MyTheme } from 'theme/muiTheme';
import { withFieldAdapter, FieldRenderProps } from '../Field';

import FormLabel from '../FormLabel';
import ButtonBase from '../ButtonBase';

const styles = createStyles((theme: MyTheme) => ({
  button: {
    width: 40,
    height: 40,
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    marginLeft: theme.extra.spacing.small,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[500],
    borderRadius: 20,
    minWidth: 0
  },
  picker: {
    position: 'absolute',
    zIndex: 100
  }
}));

type Props = {
  className: string | null | undefined;
  label: TranslationObject;
} & FieldRenderProps &
  WithStyles<typeof styles>;

function ColorField(props: Props) {
  const { label, field, form, classes, className } = props;

  const [isPickerVisible, setIsPickerVisible] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<string>(field.value || '');
  const pickerRef = React.useRef<HTMLDivElement | null | undefined>();

  function handleChange(colorResult: ColorResult) {
    if (colorResult) {
      setColor(colorResult.hex);
    }
  }

  function handleChangeComplete(colorResult: ColorResult) {
    form.setFieldValue(field.name, colorResult.hex);
  }

  function toggleDisplayPicker(event: React.SyntheticEvent) {
    event.stopPropagation();
    setIsPickerVisible(isVisible => !isVisible);
  }

  const handleOutsideClick = React.useCallback(
    (event: MouseEvent) => {
      // @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'Node | null'.
      if (!pickerRef.current || pickerRef.current.contains(event.target)) {
        return;
      }
      setIsPickerVisible(false);
    },
    [pickerRef, setIsPickerVisible]
  );

  React.useEffect(() => {
    function removeEventListener() {
      document.removeEventListener('click', handleOutsideClick);
    }
    if (isPickerVisible) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      removeEventListener();
    }
    return removeEventListener;
  }, [isPickerVisible, handleOutsideClick]);

  return (
    <div {...{ className }}>
      <FormLabel {...{ label }} />
      <ButtonBase
        className={classes.button}
        onClick={toggleDisplayPicker}
        type="button"
        style={{
          backgroundColor: field.value
        }}
      >
        {!field.value && 'N/A'}
      </ButtonBase>
      {isPickerVisible && (
        // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLDivElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
        <div className={classes.picker} ref={pickerRef}>
          <ChromePicker
            disableAlpha
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            {...{ color }}
          />
        </div>
      )}
    </div>
  );
}

ColorField.defaultProps = {
  className: undefined
};

export default withStyles(styles)(withFieldAdapter(ColorField));
