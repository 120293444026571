import React from 'react';

import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { CodeCollectActivity } from 'serviceNew/model/collectActivity';
import { TAC_TYPE_TRANSLATIONS, ALL_CODE_TYPE_TRANSLATIONS } from 'serviceNew/model/code';
import { formatDateTime } from 'serviceNew/locale/dates';
import { DataTable } from 'App/components';
import { parseName } from 'util/StringHelper';

type Props = {
  dataList: Array<CodeCollectActivity>,
  totalElements: number,
  totalPages: number,
  pageNumber: number,
  handlePageChange: (page: number) => void,
  isLoading: boolean
} & LocaleProps;

const CodeCollectsActivity = ({
  dataList,
  totalPages,
  pageNumber,
  handlePageChange,
  isLoading,
  translate,
// @ts-expect-error - TS2339 - Property 'language' does not exist on type 'Props'.
  language,
}: Props) => {
  const { isPoinzAdmin } = useAdminUser();
  const columns = [
    {
      headerLabel: { key: 'general.created' },
      fieldName: 'created',
      width: 210,
      fieldText: tr => formatDateTime(tr.created),
    },
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.id' },
            fieldName: 'id',
            width: 130,
          },
        ]
      : []),
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.user' },
            fieldName: 'userId',
            width: 110,
          },
        ]
      : []),
    {
      headerLabel: { key: 'businessProfile.businessProfile' },
      fieldName: 'businessProfileName',
      width: 170,
    },
    {
      headerLabel: { key: 'location.location' },
      fieldName: 'locationName',
      width: 180,
    },
    {
      headerLabel: { key: 'offering.offering' },
      fieldName: 'offering',
      width: 220,
      fieldText: tr => parseName(tr.offeringName, language),
    },
    {
      headerLabel: { key: 'codeGroup.codeType' },
      fieldName: 'codeType',
      width: 220,
      fieldText: tr =>
        tr.codeType === 'TAC'
          ? translate(TAC_TYPE_TRANSLATIONS[tr.tacType])
          : translate(ALL_CODE_TYPE_TRANSLATIONS[tr.codeType]),
    },
    {
      headerLabel: { key: 'code.name' },
      fieldName: 'codeName',
      width: 230,
    },
    {
      headerLabel: { key: 'general.value' },
      fieldName: 'contribution',
      width: 130,
    },
  ];

  return (
    <DataTable
      isLoading={isLoading}
      data={dataList}
      columns={columns}
      keyExtractor={item => item.id}
      totalPages={totalPages}
      currentPage={(pageNumber || 0) + 1}
      handlePageChange={handlePageChange}
    />
  );
};

export default withLocale(CodeCollectsActivity);
