import * as React from 'react';

import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { Option } from 'serviceNew/model/common';
import { TranslationObject } from 'serviceNew/locale';

import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';

import InfoText from './InfoText';
import RawText from './RawText';

const styles = createStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

type Props<T> = Diff<
  JSX.LibraryManagedAttributes<typeof RawText, React.ComponentProps<typeof RawText>>,
  {
    children: any;
  }
> & {
  value: T;
  name: string;
  options: Option<T>[];
  onChange: (arg1: React.SyntheticEvent<HTMLInputElement>) => void;
  className?: string | null | undefined;
  tooltip?: TranslationObject;
} & LocaleProps &
  WithStyles<typeof styles>;

class RadioSelect<T extends string | number> extends React.PureComponent<Props<T>> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(event);
  };

  renderOptions() {
    const { options, translate } = this.props;
    return options.map(o => (
      <FormControlLabel
        key={o.value}
        value={o.value}
        control={<Radio color="secondary" />}
        label={translate(o.label.key, o.label.context)}
      />
    ));
  }

  render() {
    const { classes, value, name, className, tooltip } = this.props;
    return (
      <div {...{ className }}>
        <InfoText label={tooltip}>
          <FormControl component="fieldset">
            <RadioGroup
              className={(classes as any).container}
              onChange={this.handleChange}
              {...{ value, name }}
            >
              {this.renderOptions()}
            </RadioGroup>
          </FormControl>
        </InfoText>
      </div>
    );
  }
}

export default withLocale(withStyles(styles, { withTheme: true })(RadioSelect));
