import * as React from 'react';

import { Typography } from '@mui/material';

import TranslatedText from './TranslatedText';
import MultiLanguageText from './MultiLanguageText';

type TextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'inherit';

type TextColor =
  | 'default'
  | 'error'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary';

type ChildrenType =
  | string
  | React.ReactElement<React.ComponentProps<'b'>>
  | React.ReactElement<React.ComponentProps<typeof TranslatedText>>
  | false
  | React.ReactElement<React.ComponentProps<typeof MultiLanguageText>>;

type RawTextProps = {
  className: string;
  children: Array<ChildrenType> | ChildrenType;
  variant: TextVariant;
  color?: TextColor;
  paragraph?: boolean | null | undefined;
  noWrap: boolean;
  style?: any;
};

class RawText extends React.PureComponent<RawTextProps> {
  static defaultProps = {
    className: '',
    variant: 'body1',
    noWrap: false,
    style: {}
  };

  render() {
    const { children, className, variant, color, paragraph, noWrap, style } = this.props;
    return (
      // @ts-expect-error - TS2769 - No overload matches this call.
      <Typography
        {...{
          className,
          variant,
          color,
          paragraph,
          noWrap,
          style
        }}
      >
        {children}
      </Typography>
    );
  }
}

export default RawText;
