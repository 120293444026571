import * as React from 'react';
import Pagination, { PaginationProps } from '@mui/material/Pagination';

type Props = {
  onChange: (arg1: number) => void;
} & Partial<PaginationProps>;
function DataTablePagination({ onChange, ...restProps }: Props) {
  function onPageChange(event: React.SyntheticEvent<any>, value: number) {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }

  return <Pagination onChange={onPageChange} {...restProps} />;
}

export default DataTablePagination;
