import { PartnerClient } from '../../../partnerClient';
import analytics from './analytics';
import campaign from './campaign';
import transaction from './transaction';

export default (apiClient: PartnerClient) => ({
  analytics: analytics(apiClient),
  transaction: transaction(apiClient),
  campaign: campaign(apiClient)
});
