import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'App/components/Tooltip';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { SaveAlt } from '@mui/icons-material';

type Props = {
  generateExcel: () => any
} & LocaleProps;

type State = Record<any, any>;

class ExcelExporterButton extends React.PureComponent<Props, State> {
  render() {
    const { generateExcel, translate } = this.props;
    return (
      <Tooltip label={{ key: 'general.export.excel' }}>
        <IconButton
          classes={{ root: 'default-generate-excel-button' }}
          aria-label={translate('general.export.excel')}
          color="primary"
          onClick={generateExcel}
          size="large">
          <SaveAlt />
        </IconButton>
      </Tooltip>
    );
  }
}

export default withLocale(ExcelExporterButton);
