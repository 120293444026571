import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getIn, FieldArrayRenderProps } from 'formik';
import { makeStyles } from '@mui/styles';
import { FileUpload, IconButton, CroppableImage, ContainedImage } from 'App/components';
import { ImageListItemBar, ImageList, ImageListItem } from '@mui/material';

// @ts-expect-error - TS2345 - Argument of type '(theme: DefaultTheme) => { flex: { flex: number; }; grid: { marginTop: any; display: "flex"; flexWrap: "wrap"; justifyContent: "flex-start"; overflow: "hidden"; }; gridList: { flex: number; flexWrap: "nowrap"; transform: "translateZ(0)"; }; ... 4 more ...; image: { ...; }; }' is not assignable to parameter of type 'Styles<DefaultTheme, {}, "flex" | "grid" | "dialog" | "title" | "image" | "action" | "gridList" | "titleBar">'.
const useStyles = makeStyles(theme => ({
  flex: {
    flex: 1
  },
  grid: {
    // @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'DefaultTheme'.
    marginTop: theme.extra.spacing.base,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  gridList: {
    flex: 1,
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  title: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.primary.light
  },
  titleBar: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    background: theme.palette.overlay.middle
  },
  dialog: {
    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'DefaultTheme'.
    ...theme.mainComponents.modal
  },
  action: {
    display: 'flex',
    flexWrap: 'noWrap',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.common.white
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
}));

type Props = FieldArrayRenderProps;

function CroppableImageList(props: Props) {
  const { form, name, push, remove, replace } = props;
  const { values, touched } = form;
  const imageList = getIn(values, name);
  const classes = useStyles();

  function handleAdd(files: any) {
    files.forEach(file => {
      const id = uuidv4();
      push({ file, id });
    });
    if (!getIn(touched, name)) {
      // @ts-expect-error - TS2769 - No overload matches this call.
      form.setFieldTouched(name, []);
    }
  }

  function renderDropzone() {
    return <FileUpload onDrop={handleAdd} accept="image/jpeg" />;
  }

  function renderImageGrid() {
    return (
      // @ts-expect-error - TS2339 - Property 'grid' does not exist on type 'ClassNameMap<never>'.
      <div className={classes.grid}>
        {/* @ts-expect-error - TS2339 - Property 'gridList' does not exist on type 'ClassNameMap<never>'. */}
        <ImageList className={classes.gridList} rowHeight={200}>
          {imageList.map((image, idx) => (
            <ImageListItem key={idx.toString()}>
              <CroppableImage key={idx.toString()} value={image} onChange={v => replace(idx, v)}>
                {({ imageUrl, startCropping }) => (
                  <>
                    <ContainedImage image={imageUrl} backgroundColor={image.mainColor} />
                    <ImageListItemBar
                      title={image.file?.name ?? undefined}
                      classes={{
                        // @ts-expect-error - TS2339 - Property 'titleBar' does not exist on type 'ClassNameMap<never>'.
                        root: classes.titleBar,
                        // @ts-expect-error - TS2339 - Property 'title' does not exist on type 'ClassNameMap<never>'.
                        title: classes.title
                      }}
                      actionIcon={
                        // @ts-expect-error - TS2339 - Property 'action' does not exist on type 'ClassNameMap<never>'.
                        <div className={classes.action}>
                          {image.file != null && <IconButton icon="crop" onClick={startCropping} />}
                          <IconButton onClick={() => remove(idx)} icon="delete" />
                        </div>
                      }
                    />
                  </>
                )}
              </CroppableImage>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    );
  }

  return (
    // @ts-expect-error - TS2339 - Property 'flex' does not exist on type 'ClassNameMap<never>'.
    <div className={classes.flex}>
      {renderDropzone()}
      {imageList && renderImageGrid()}
    </div>
  );
}

export default CroppableImageList;
