import {BehaviorSubject} from 'rxjs';

import { useBehaviorSubject } from '@poinz/hooks';

import { AuthState } from 'serviceNew/model/auth';
import 'serviceNew/firebase';
import { onIdTokenChanged } from 'serviceNew/firebase/auth';

export const initialAuthState: AuthState = 'UNKNOWN';

export const authSubject = new BehaviorSubject<AuthState>(initialAuthState);

onIdTokenChanged(authSubject);

export const useAuthState = () => useBehaviorSubject<AuthState>(authSubject);
export const useIsLoggedIn = () => {
  const [authState] = useAuthState();
  return authState != null && authState !== 'UNKNOWN';
};
