import * as React from 'react';

// @ts-expect-error - TS2305 - Module '"react-router"' has no exported member 'Location'.
import { Redirect, Route, withRouter, Location } from 'react-router';

import { UserRoleHierarchy, UserRole } from 'serviceNew/model/adminUser';
import {
  useContractCompany,
  withOptionalContractCompany,
  OptionalContractCompanyProps
} from 'App/providers/ContractCompanyProvider';
import { useIsLoggedIn } from 'serviceNew/auth';

import { withAdminUser, AdminUserProps } from 'App/providers/AdminUserProvider';

export function PrivateRouteUnconnected(
  props: {
    component: React.ComponentType<any>;
    render: () => React.ReactElement<any>;
    location: Location;
    roles: Array<UserRole> | UserRole | null | undefined;
    redirectTo: string;
    deal: boolean;
    loyalty: boolean;
    communication: boolean;
    elseComponent: React.ComponentType<any>;
  } & OptionalContractCompanyProps &
    AdminUserProps
) {
  const { component: Component, contractCompany, render, adminUserContext, ...rest } = props;
  const {
    elseComponent: ElseComponent,
    roles,
    location,
    redirectTo,
    deal,
    loyalty,
    communication
  } = rest;

  const isLoggedIn = useIsLoggedIn();
  const { userRole } = adminUserContext;

  const { hasDealPermission, hasLoyaltyPermission, hasCommunicationPermission } =
    useContractCompany();

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
  const poinzRole = UserRoleHierarchy[userRole] <= UserRoleHierarchy.POINZ_ADMIN;
  const rolesCondition =
    !roles ||
    roles === userRole ||
    roles.includes(userRole) ||
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    (!Array.isArray(roles) && UserRoleHierarchy[userRole] <= UserRoleHierarchy[roles]);

  return (
    <Route
      {...rest}
      render={innerProps => {
        if (isLoggedIn) {
          if (rolesCondition) {
            if (!poinzRole && contractCompany && (deal || loyalty)) {
              const dealsCondition = (deal && hasDealPermission) || !deal;
              const loyaltyCondition = (loyalty && hasLoyaltyPermission) || !loyalty;
              const comCondition = (communication && hasCommunicationPermission) || !communication;

              if (dealsCondition && loyaltyCondition && comCondition) {
                if (render) {
                  return render();
                }
                return <Component {...innerProps} />;
              }
              if (ElseComponent) {
                return <ElseComponent />;
              }
              return (
                <Redirect
                  to={{
                    pathname: redirectTo || '/',
                    state: { from: location }
                  }}
                />
              );
            }
            if (render) {
              return render();
            }
            return <Component {...innerProps} />;
          }
          if (ElseComponent) {
            return <ElseComponent />;
          }
          return (
            <Redirect
              to={{
                pathname: redirectTo || '/',
                state: { from: location }
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: redirectTo || '/login',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}

export default withRouter(withAdminUser(withOptionalContractCompany(PrivateRouteUnconnected)));
