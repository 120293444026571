import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import routes from 'App/routing';

type Props = {
  children: React.ReactNode
};

export default function ContractCompanyGuard({
  children,
}: Props) {
  const { contractCompanyId } = useContractCompany();
  const { pathname } = useLocation();

  if (!contractCompanyId) {
    return (
      <Redirect
        to={{
          pathname: routes.administration.contractCompany.search.route,
          search: `redirectTo=${pathname}`,
        }}
      />
    );
  }

  return children;
}
