import React, { Component } from 'react';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Grid } from '@mui/material';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import withAuthScope from 'serviceNew/auth/authScope';
import { CHART_STYLING, ResolutionType } from '../../../constants/general';
import { theme } from '../../../theme/muiTheme';
import ChartTooltip from './ChartTooltip';
import TranslatedText from '../TranslatedText';

export type ChartDataType = {
  name: string;
  value: number | string;
};

type Props = {
  chartData: Array<ChartDataType>;
  chartList?: Array<string>;
  legend?: boolean;
  tooltip: boolean;
  xaxis?: string;
  resolution?: ResolutionType;
  height?: number;
  title: string;
  name: string;
  additionalTooltip?: ChartDataType;
} & LocaleProps;

type State = {
  unselected: Array<string>;
};

export class BarChartUnconnected extends Component<Props, State> {
  state = {
    unselected: []
  };

  selectLine = (event: any) => {
    const dataKey = event.dataKey.trim();
    const { unselected } = this.state;
    // @ts-expect-error - TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'.
    if (unselected.includes(dataKey)) {
      const newUnselected = unselected.filter(f => f !== dataKey);
      this.setState({
        unselected: newUnselected
      });
    } else {
      const newUnselected = unselected;
      // @ts-expect-error - TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'.
      newUnselected.push(dataKey);
      this.setState({
        unselected: newUnselected
      });
    }
  };

  render() {
    const {
      chartList,
      legend,
      tooltip,
      xaxis,
      formatMessage,
      chartData,
      resolution,
      height = 300,
      title,
      name,
      additionalTooltip
    } = this.props;
    const { unselected } = this.state;

    const formatDateXAxis = (tickItem: any) => {
      const startDate = moment(tickItem);
      switch (resolution) {
        case 'MONTHLY':
          startDate.subtract(29, 'days');
          break;
        case 'WEEKLY':
          startDate.subtract(6, 'days');
          break;
        default:
          break;
      }
      return startDate.format('L');
    };

    const dateLabelFormatter = (label: any) => {
      const endDate = moment(label).format('L');
      switch (resolution) {
        case 'MONTHLY':
          return `${moment(label).subtract(29, 'days').format('L')} - ${endDate}`;
        case 'WEEKLY':
          return `${moment(label).subtract(7, 'days').format('L')} - ${endDate}`;
        default:
          return endDate;
      }
    };

    const formatXAxis = (tickItem: any) => {
      if (
        Object.keys(CHART_STYLING).includes(name) &&
        Object.keys(CHART_STYLING[name]).includes(tickItem)
      ) {
        return formatMessage({ id: CHART_STYLING[name][tickItem].label });
      }
      return tickItem;
    };

    let total = 0;
    const chartDataLength = chartData.length;
    if (chartDataLength > 0 && !chartList) {
      for (let i = 0; i < chartDataLength; i += 1) {
        // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
        total += parseFloat(chartData[i] && chartData[i].value);
      }
    }

    return (
      <>
        <Grid container spacing={2}>
          {title && (
            <Grid item xs={12} sm={12}>
              <Typography noWrap align="left" variant="subtitle1">
                <TranslatedText text={{ key: title }} />
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            {chartData.length > 0 ? (
              <ResponsiveContainer width="99%" height={height}>
                <BarChart data={chartData}>
                  <XAxis
                    dataKey={xaxis || 'name'}
                    tickFormatter={resolution ? formatDateXAxis : formatXAxis}
                    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                    tick={theme.mainComponents.chart.axis.label}
                    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                    tickLine={theme.mainComponents.chart.axis.line}
                    // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                    axisLine={theme.mainComponents.chart.axis.line}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    allowDecimals={false}
                    tick={{ fontSize: 12, fontFamily: 'roboto' }}
                  />
                  {tooltip &&
                    (chartList ? (
                      <Tooltip
                        cursor={false}
                        labelFormatter={resolution ? dateLabelFormatter : undefined}
                      />
                    ) : (
                      <Tooltip
                        cursor={false}
                        content={
                          <ChartTooltip
                            formatMessage={formatMessage}
                            additional={additionalTooltip}
                            total={total}
                            name={name}
                            payload={[]}
                          />
                        }
                      />
                    ))}
                  {legend && (
                    <Legend
                      onClick={this.selectLine}
                      // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                      wrapperStyle={theme.mainComponents.chart.legend}
                    />
                  )}
                  {chartList ? (
                    chartList.map(e => (
                      <Bar
                        key={e}
                        legendType="circle"
                        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
                        dataKey={unselected.includes(e) ? `${e} ` : e}
                        fill={
                          // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
                          unselected.includes(e)
                            ? // @ts-expect-error - TS2339 - Property 'mainComponents' does not exist on type 'Theme'.
                              theme.mainComponents.chart.axis.line.stroke
                            : CHART_STYLING[name][e].color
                        }
                        name={formatMessage({ id: CHART_STYLING[name][e].label })}
                      />
                    ))
                  ) : (
                    <Bar
                      dataKey="value"
                      fill={CHART_STYLING[name].color}
                      name={formatMessage({ id: CHART_STYLING[name].label })}
                    />
                  )}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography align="left">
                <TranslatedText text={{ key: 'stats.noDataAvailable' }} />
              </Typography>
            )}
          </Grid>
          {resolution === 'MONTHLY' && (
            <div style={{ width: '100%', textAlign: 'center', paddingBottom: 16 }}>
              <Typography noWrap align="left" variant="caption">
                <TranslatedText text={{ key: 'stats.activityTimeline.monthly.description' }} />
              </Typography>
            </div>
          )}
        </Grid>
      </>
    );
  }
}

export const AuthorizableBarChart = withAuthScope(withLocale(BarChartUnconnected), 'chartList');

export default withLocale(BarChartUnconnected);
