import * as React from 'react';
import { TextFieldProps as TextFieldPropsMui } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { TranslationObject, EnumTranslationKeys, Language } from 'serviceNew/locale';
import { selectMultiLanguage } from 'serviceNew/locale/strings';
import { FormControl, Select, OutlinedInput, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { APP_LINK_SOURCE } from 'serviceNew/model/appLink';
import FieldLabel from './FieldLabel';
import TranslatedText from './TranslatedText';
import Text from './Text';

export type Option = {
  label: TranslationObject;
  value: any;
  subtitle?: TranslationObject;
};

export type IdName = {
  id: number;
  name: string;
};

const useStyles = makeStyles(theme => ({
  placeholder: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.text.hint
  },
  menuPaper: {
    maxHeight: 200
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export function getOptionsFromEnum(enumTranslations: EnumTranslationKeys): Array<Option> {
  return Object.keys(enumTranslations).map((key: any) => ({
    label: { key: enumTranslations[key] },
    value: key
  }));
}

export function getFilteredOptionsFromEnum<T extends string>(
  enumTranslations: EnumTranslationKeys,
  filteredKeys: T[]
): Array<Option> {
  return getOptionsFromEnum(enumTranslations).filter(o => !filteredKeys.includes(o.value));
}

export function getOptionsFromRawEnum<T extends string>(
  enumText: Partial<Record<T, string>>
): Array<Option> {
  // @ts-expect-error - TS2345 - Argument of type '(key: T) => { label: { key: string; context: { value: Partial<Record<T, string>>[T]; }; }; value: T; }' is not assignable to parameter of type '(value: string, index: number, array: string[]) => { label: { key: string; context: { value: Partial<Record<T, string>>[T]; }; }; value: T; }'.
  return Object.keys(enumText).map((key: T) => ({
    label: { key: 'text.no.translation', context: { value: enumText[key] } },
    value: key
  }));
}

export function getValidSourceOptions(medium: string): Array<Option> {
  const sourceList = getOptionsFromEnum(APP_LINK_SOURCE);

  switch (medium) {
    case 'NEWSLETTER':
      return sourceList.filter(o => o.value === 'RAPID_MAIL');
    case 'MESSAGE':
    case 'OTHER':
      return sourceList.filter(o => o.value === 'POINZ');
    case 'AD':
    case 'AD_MOBILE':
    case 'AD_WEB':
      return sourceList.filter(o => ['GOOGLE', 'FACEBOOK', 'INSTAGRAM'].includes(o.value));
    default:
      return [];
  }
}

export function getOptionsFromDataObject(
  valueArray: Array<any>,
  label: string,
  value: string,
  language?: Language | null
): Array<Option> {
  return valueArray.map(e => ({
    label:
      typeof e[label] === 'object' && language != null
        ? {
            key: 'text.no.translation',
            context: { value: selectMultiLanguage(e[label], language) }
          }
        : { key: 'text.no.translation', context: { value: e[label] } },
    value: e[value]
  }));
}

export function getOptionsFromDataArray(idNameArray: IdName[]): Array<Option> {
  if (!idNameArray?.length) return [];
  return idNameArray.map(item => ({
    label: { key: 'text.no.translation', context: { value: item.name } },
    value: item.id
  }));
}

type Props = {
  className?: string | null | undefined;
  options: Option[];
  label: TranslationObject;
  helperText?: TranslationObject | null | undefined;
  required: boolean;
  disabled: boolean;
  addEmpty: boolean;
  noLabel?: boolean;
  tooltip?: TranslationObject;
} & TextFieldPropsMui & {
    select?: boolean | null | undefined;
    label: any;
    helperText: any;
  };

function TextSelect(props: Props) {
  const {
    name,
    options,
    label,
    noLabel,
    placeholder,
    required,
    tooltip,
    addEmpty,
    helperText,
    className,
    disabled,
    error,
    value: fieldValue,
    onChange
  } = props;

  const classes = useStyles();

  React.useEffect(() => {
    if (!!onChange && fieldValue != null && options.find(o => o.value === fieldValue) == null) {
      onChange(undefined as any);
    }
  }, [fieldValue, onChange, options]);

  function renderValue(value: any) {
    if (!value) {
      return (
        <span className={classes.placeholder}>
          <TranslatedText text={placeholder || label} />
        </span>
      );
    }
    const valueLabel = options.find(o => o.value === value);
    if (valueLabel) {
      return <TranslatedText text={valueLabel.label} />;
    }
    return null;
  }

  return (
    <FormControl variant="outlined" classes={{ root: className }} {...{ disabled, error }}>
      {!noLabel && <FieldLabel {...{ label, required, tooltip }} />}
      {/* @ts-expect-error */}
      <Select
        value={props.value || ''}
        displayEmpty
        renderValue={renderValue}
        variant="outlined"
        input={<OutlinedInput {...{ name }} />}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        {...{ onChange }}
      >
        {addEmpty && (
          // @ts-expect-error - TS2769 - No overload matches this call.
          <MenuItem key="empty" value={null}>
            <TranslatedText text={{ key: 'general.noSelection' }} />
          </MenuItem>
        )}
        {options.map(o => (
          <MenuItem key={o.value} value={o.value} classes={{ root: classes.item }}>
            <div>
              <TranslatedText text={o.label} />
            </div>
            {o.subtitle && (
              <Text variant="caption" color="textSecondary" context={o.subtitle.context}>
                {o.subtitle.key}
              </Text>
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText {...{ error }}>
          <TranslatedText text={helperText} />
        </FormHelperText>
      )}
    </FormControl>
  );
}

TextSelect.defaultProps = {
  required: false,
  addEmpty: false,
  disabled: false,
  helperText: undefined
};

export default TextSelect;
