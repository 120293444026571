import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { PaymentMethod } from './types';

const PAYMENT_METHODS = 'payment-methods';

const apiRoot = `/${ROOT}/${PAYMENT_METHODS}`;

const getMyPaymentMethods =
  (apiClient: PoinzApiClient) =>
  async (userId?: number): Promise<PaymentMethod[]> => {
    const result = await apiClient.get(apiRoot, { ...(userId && { queryParams: { userId } }) });
    return result.body;
  };

const deletePaymentMethod =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<void> => {
    await apiClient.delete(`${apiRoot}/${id}`, {});
  };

export default (apiClient: PoinzApiClient) => ({
  delete: deletePaymentMethod(apiClient),
  get: getMyPaymentMethods(apiClient)
});
