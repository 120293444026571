import * as React from 'react';
import { Menu, MenuItem, Portal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from 'App/providers/LocaleProvider';
import { TranslationObject } from 'serviceNew/locale';
import FilterButton from './FilterButton';
import { FILTER_TYPE, FilterBaseType } from './FilterProps';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 8,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
    minWidth: 180
  }
}));

export type FilterSelectType<S> = {
  filterType: typeof FILTER_TYPE.SELECT;
  options: Array<{
    value: any;
    label: TranslationObject;
  }>;
} & FilterBaseType<S>;

type Props<V, S> = {
  value: V;
  filter: FilterSelectType<S>;
  filterKey: string;
  className?: string;
  containerRef: React.ElementRef<any>;
};

function SelectFilter<V, S>({ filter, className, value, containerRef }: Props<V, S>) {
  const { label, onChange, options, ...restProps } = filter;
  const { translate } = useLocale();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null | undefined>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = React.useCallback(
    () => (
      <Menu
        classes={{ paper: classes.paper }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={() => {
              onChange(option.value);
              handleClose();
            }}
            disableRipple
          >
            {translate(option.label.key, option.label.context)}
          </MenuItem>
        ))}
      </Menu>
    ),
    [anchorEl, classes.paper, isOpen, onChange, options, translate, value]
  );

  return (
    <>
      <FilterButton label={label} onClick={handleClick} {...{ className }} {...restProps} />
      {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */}
      <Portal container={containerRef.current}>{renderMenu()}</Portal>
    </>
  );
}

export default SelectFilter;
