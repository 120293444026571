import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { BusinessProfile, PartnerClient } from '../../../partnerClient';

const KEY = 'BUSINESS_PROFILE';

const useGetBusinessProfiles =
  (apiClient: PartnerClient) =>
  (contractCompanyId: number, includeDeleted = false): UseQueryResult<BusinessProfile[], any> => {
    return useQuery(
      [KEY, contractCompanyId],
      () => apiClient.backend.businessProfile.getList(contractCompanyId, includeDeleted),
      { enabled: false, refetchOnWindowFocus: false }
    );
  };

const useGetBusinessProfile =
  (apiClient: PartnerClient) =>
  ({
    businessProfileId,
    contractCompanyId
  }: {
    businessProfileId: number;
    contractCompanyId: number;
  }): UseQueryResult<BusinessProfile, any> => {
    return useQuery([KEY, businessProfileId, contractCompanyId], () =>
      apiClient.backend.businessProfile.get({ businessProfileId, contractCompanyId })
    );
  };

const useUpdateBusinessProfile =
  (apiClient: PartnerClient) =>
  ({
    businessProfileId,
    contractCompanyId
  }: {
    businessProfileId: number;
    contractCompanyId: number;
  }): UseMutationResult<BusinessProfile, any, FormData, BusinessProfile> => {
    const queryClient = useQueryClient();
    return useMutation(
      (data: FormData) =>
        apiClient.backend.businessProfile.update({ businessProfileId, contractCompanyId }, data),
      {
        onMutate: async (data: FormData) => {
          await queryClient.cancelQueries([KEY, businessProfileId, contractCompanyId]);

          const previousBusinessProfile: BusinessProfile | undefined = queryClient.getQueryData([
            KEY,
            businessProfileId,
            contractCompanyId
          ]);
          // queryClient.setQueryData<Partial<BusinessProfile>>(
          //   [KEY, businessProfileId, contractCompanyId],
          //   {
          //     ...previousBusinessProfile,
          //     ...data
          //   }
          // );
          return previousBusinessProfile;
        },
        onError: (err: any, data: FormData, previousBp: BusinessProfile | undefined) => {
          queryClient.setQueryData([KEY, businessProfileId, contractCompanyId], previousBp);
        },
        onSettled: () => {
          queryClient.invalidateQueries([KEY, businessProfileId, contractCompanyId]);
        }
      }
    );
  };

export default (apiClient: PartnerClient) => ({
  useGetBusinessProfile: useGetBusinessProfile(apiClient),
  useGetBusinessProfiles: useGetBusinessProfiles(apiClient),
  useUpdateBusinessProfile: useUpdateBusinessProfile(apiClient)
});
