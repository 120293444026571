import * as React from 'react';
import { PaginationModel, defaultPaginatedResult } from 'serviceNew/api/api';
import API from 'serviceNew/api/poinzCollectActivity';
import {
  CodeCollectActivity,
  CardCollectActivity,
  DealCollectActivity
} from 'serviceNew/model/collectActivity';
import { useLocale } from 'App/providers/LocaleProvider';
import { moment } from 'serviceNew/locale/dates';
import { DEAL_COLLECT_STATE } from 'serviceNew/model/dealCollect';
import { DEAL_TYPE_TRANSLATIONS } from 'serviceNew/model/deal';
import { useQueryState } from '@poinz/hooks';
import routes from 'App/routing';
import { getOptionsFromEnum } from 'App/components/TextSelect';
import FilterContainer, { FILTER_TYPE } from 'App/components/Filter';
import NavigationHeaderProvider from 'App/providers/NavigationHeaderProvider';
import DealCollectsActivityTable from '../CollectsActivity/DealCollectsActivity/DealCollectsActivity';
import CardCollectsActivityTable from '../CollectsActivity/CardCollectsActivity/CardCollectsActivity';
import CodeCollectsActivityTable from '../CollectsActivity/CodeCollectsActivity/CodeCollectsActivity';
import {
  initialFilterState,
  FILTER,
  FILTER_ACTION,
  filterReducer
} from './poinzCollectsActivityReducerFilter';
import { TranslationKey } from '@poinz/locale';

const TABS = {
  DEAL_COLLECT: 'dealCollects',
  CARD_COLLECT: 'cardCollects',
  CODE_COLLECT: 'codeCollects'
} as const;

type TabType = (typeof TABS)[keyof typeof TABS];

const poinzRoutes = routes.dashboard.poinzActivity;

const PoinzCollectsActivity = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [codeCollectsActivity, setCodeCollectsActivity] =
    React.useState<PaginationModel<CodeCollectActivity>>(defaultPaginatedResult);
  const [cardCollectsActivity, setCardCollectsActivity] =
    React.useState<PaginationModel<CardCollectActivity>>(defaultPaginatedResult);
  const [dealCollectsActivity, setDealCollectsActivity] =
    React.useState<PaginationModel<DealCollectActivity>>(defaultPaginatedResult);
  const [activeTab, setActiveTab] = useQueryState<TabType>('activeTab', TABS.DEAL_COLLECT);

  const [filterState, dispatch] = React.useReducer(filterReducer, initialFilterState);
  const { DEAL_TYPE: dealType, COLLECT_STATE: dealCollectState } = filterState;
  const { translate } = useLocale();

  const pageNumbers = React.useRef<Partial<Record<TabType, number>>>({
    [TABS.CODE_COLLECT]: 0,
    [TABS.CARD_COLLECT]: 0,
    [TABS.DEAL_COLLECT]: 0
  });

  React.useEffect(() => {
    const loadData = async () => {
      const date = moment().format(moment.HTML5_FMT.DATE);
      setIsLoading(true);

      switch (activeTab) {
        case TABS.CODE_COLLECT: {
          const codeCollects = await API.getCodeCollectsActivity({
            pageNumber:
              (codeCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.CODE_COLLECT]
                ? codeCollectsActivity?.pageNumber || 0
                : 0,
            date
          });

          setCodeCollectsActivity({ ...codeCollects, totalPages: codeCollects.totalPages + 1 });
          break;
        }
        case TABS.CARD_COLLECT: {
          const cardCollects = await API.getCardCollectsActivity({
            pageNumber:
              (cardCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.CARD_COLLECT]
                ? cardCollectsActivity?.pageNumber || 0
                : 0,
            date
          });
          setCardCollectsActivity({ ...cardCollects, totalPages: cardCollects.totalPages + 1 });
          break;
        }
        case TABS.DEAL_COLLECT: {
          const dealCollects = await API.getDealCollectsActivity({
            pageNumber:
              (dealCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.DEAL_COLLECT]
                ? dealCollectsActivity?.pageNumber || 0
                : 0,
            date,
            dealCollectState,
            dealType
          });
          setDealCollectsActivity({ ...dealCollects, totalPages: dealCollects.totalPages + 1 });
          break;
        }
        default:
          break;
      }
      setIsLoading(false);
    };

    loadData();
  }, [
    activeTab,
    dealCollectState,
    dealType,
    dealCollectsActivity.pageNumber,
    cardCollectsActivity.pageNumber,
    codeCollectsActivity.pageNumber
  ]);

  const handlePageChange = (toPage: any) => {
    const pageNumber = toPage - 1;
    switch (activeTab) {
      case TABS.CODE_COLLECT: {
        setCodeCollectsActivity({ ...codeCollectsActivity, pageNumber });
        break;
      }
      case TABS.CARD_COLLECT: {
        setCardCollectsActivity({ ...cardCollectsActivity, pageNumber });
        break;
      }
      case TABS.DEAL_COLLECT: {
        setDealCollectsActivity({ ...dealCollectsActivity, pageNumber });
        break;
      }
      default: {
        break;
      }
    }
  };

  const navigationRoutes = {
    [poinzRoutes.getMatch()]: {
      currentPathLabel: { key: 'sidebar.dashboard.overview' },
      hideBackButton: true,
      hideBreadCrumbs: true,
      tabBar: {
        value: activeTab,
        onChange: selectedTab => setActiveTab(selectedTab),
        tabs: [
          { tab: TABS.DEAL_COLLECT, label: { key: 'tab.dealCollectsActivity' } },
          { tab: TABS.CARD_COLLECT, label: { key: 'tab.cardCollectsActivity' } },
          { tab: TABS.CODE_COLLECT, label: { key: 'tab.codeCollectsActivity' } }
        ]
      }
    }
  } as const;

  const filters = React.useMemo(
    () => [
      {
        key: FILTER.DEAL_TYPE,
        label: { key: 'deal.dealType' },
        onChange: value =>
          dispatch({
            type: FILTER_ACTION.SET,
            filter: FILTER.DEAL_TYPE,
            value
          }),
        filterType: FILTER_TYPE.SELECT,
        getChipValue: value => translate(DEAL_TYPE_TRANSLATIONS[value] as TranslationKey),
        options: getOptionsFromEnum(DEAL_TYPE_TRANSLATIONS as any),
        onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.DEAL_TYPE })
      },
      {
        key: FILTER.COLLECT_STATE,
        label: { key: 'deal.dealCollectState' },
        onChange: value =>
          dispatch({
            type: FILTER_ACTION.SET,
            filter: FILTER.COLLECT_STATE,
            value
          }),
        filterType: FILTER_TYPE.SELECT,
        getChipValue: value => translate(DEAL_COLLECT_STATE[value]),
        options: getOptionsFromEnum(DEAL_COLLECT_STATE),
        onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.COLLECT_STATE })
      }
    ],
    [translate, dispatch]
  );

  return (
    <>
      {/* @ts-expect-error - TS2322 - Type '{ children: Element; navigationRoutes: { readonly [x: number]: { readonly currentPathLabel: { readonly key: "sidebar.dashboard.activity"; }; readonly action: () => Element | null; readonly hideBackButton: true; readonly hideBreadCrumbs: true; readonly tabBar: { ...; }; }; }; }' is not assignable to type '{ children: ReactNode; navigationRoutes: { [key: string]: NavigationRoute; }; }'. */}
      <NavigationHeaderProvider {...{ navigationRoutes }}>
        {activeTab === TABS.CODE_COLLECT && (
          <CodeCollectsActivityTable
            handlePageChange={handlePageChange}
            isLoading={isLoading}
            {...codeCollectsActivity}
          />
        )}

        {activeTab === TABS.CARD_COLLECT && (
          <CardCollectsActivityTable
            handlePageChange={handlePageChange}
            isLoading={isLoading}
            {...cardCollectsActivity}
          />
        )}

        {activeTab === TABS.DEAL_COLLECT && (
          <FilterContainer
            filters={filters}
            filterState={filterState}
            // @ts-expect-error - TS2322 - Type 'Dispatch<ReducerAction>' is not assignable to type 'Dispatch<ReducerAction, FilterState>'.
            dispatch={dispatch}
          >
            <DealCollectsActivityTable
              handlePageChange={handlePageChange}
              isLoading={isLoading}
              {...dealCollectsActivity}
            />
          </FilterContainer>
        )}
      </NavigationHeaderProvider>
    </>
  );
};

export default PoinzCollectsActivity;
