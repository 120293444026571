import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqualWith';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T[P] extends Record<string, any>
    ? RecursivePartial<T[P]>
    : T[P];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function difference<S extends Record<string, any>>(
  object: S,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  base?: any,
  deepDiff = true
): RecursivePartial<S> {
  if (!base) {
    return object;
  }
  return transform(
    object,
    <K extends keyof S>(result: RecursivePartial<S>, value: S[K], key: K) => {
      if (
        !isEqual(value, base[key], (v, o) =>
          o == null && isObject(v) && isEmpty(v) ? true : undefined
        )
      ) {
        (result as any)[key] =
          isObject(value) && isObject(base[key]) && deepDiff ? difference(value, base[key]) : value;
      }
    }
  );
}

export default difference;
