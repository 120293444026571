import { IdType } from '@poinz/api';
import { keyMirror } from 'util/ObjectHelper';
import { EnumTranslationKeys, Language } from 'serviceNew/locale';

type Gender = 'MALE' | 'FEMALE';

export type UserRole = 'SUPER_ADMIN' | 'POINZ_ADMIN' | 'PARTNER_ADMIN' | 'USER';

export const INTERNAL_ROLE = keyMirror({
  TECH: null,
  SALES: null,
  MARKETING: null,
  BUSINESS_SUPPORT: null,
  MANAGEMENT: null,
  HR: null,
  ACCOUNTING: null,
  QM: null,
  AM: null
});

export const INTERNAL_ROLE_CONSULTANT = [
  INTERNAL_ROLE.AM,
  INTERNAL_ROLE.SALES,
  INTERNAL_ROLE.MANAGEMENT
] as const;

export type InternalRole = keyof typeof INTERNAL_ROLE;

// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const INTERNAL_ROLE_TRANSLATIONS: EnumTranslationKeys<InternalRole> = {
  [INTERNAL_ROLE.AM]: 'adminUser.internalRoles.am',
  [INTERNAL_ROLE.SALES]: 'adminUser.internalRoles.sales',
  [INTERNAL_ROLE.TECH]: 'adminUser.internalRoles.tech',
  [INTERNAL_ROLE.MARKETING]: 'adminUser.internalRoles.marketing',
  [INTERNAL_ROLE.BUSINESS_SUPPORT]: 'adminUser.internalRoles.businessSupport',
  [INTERNAL_ROLE.MANAGEMENT]: 'adminUser.internalRoles.management',
  [INTERNAL_ROLE.HR]: 'adminUser.internalRoles.hr',
  [INTERNAL_ROLE.ACCOUNTING]: 'adminUser.internalRoles.accounting',
  [INTERNAL_ROLE.QM]: 'adminUser.internalRoles.qm'
};

export const UserRoles = keyMirror({
  POINZ_ADMIN: null,
  PARTNER_ADMIN: null,
  SUPER_ADMIN: null,
  USER: null
});

export const USER_ROLE_TRANSLATIONS = {
  POINZ_ADMIN: 'adminUser.roles.poinzAdmin',
  PARTNER_ADMIN: 'adminUser.roles.partnerAdmin',
  SUPER_ADMIN: 'adminUser.roles.superAdmin',
  USER: 'adminUser.roles.user'
} as const;

export const POINZ_ADMIN_USER_ROLES = [UserRoles.POINZ_ADMIN, UserRoles.SUPER_ADMIN] as const;

export const UserRoleHierarchy: Partial<Record<UserRole, number>> = {
  SUPER_ADMIN: 0,
  POINZ_ADMIN: 1,
  PARTNER_ADMIN: 2,
  USER: 3
};

// TODO: only export this if it is really needed
// @ts-expect-error - TS2315 - Type 'EnumTranslationKeys' is not generic.
export const Genders: EnumTranslationKeys<Gender> = {
  MALE: 'general.male',
  FEMALE: 'general.female'
};

export class AdminUser {
  // @ts-expect-error - TS2564 - Property 'id' has no initializer and is not definitely assigned in the constructor.
  id: IdType;
  // @ts-expect-error - TS2564 - Property 'firstName' has no initializer and is not definitely assigned in the constructor.
  firstName: string;
  // @ts-expect-error - TS2564 - Property 'lastName' has no initializer and is not definitely assigned in the constructor.
  lastName: string;
  // @ts-expect-error - TS2564 - Property 'email' has no initializer and is not definitely assigned in the constructor.
  email: string;
  // @ts-expect-error - TS2564 - Property 'phone' has no initializer and is not definitely assigned in the constructor.
  phone: string;
  gender: Gender | null | undefined;
  language: Language | null | undefined;
  // @ts-expect-error - TS2564 - Property 'oldPassword' has no initializer and is not definitely assigned in the constructor.
  oldPassword: string;
  // @ts-expect-error - TS2564 - Property 'password' has no initializer and is not definitely assigned in the constructor.
  password: string;
  // @ts-expect-error - TS2564 - Property 'passwordConfirmation' has no initializer and is not definitely assigned in the constructor.
  passwordConfirmation: string;
  // @ts-expect-error - TS2564 - Property 'belongsToIds' has no initializer and is not definitely assigned in the constructor.
  belongsToIds: Array<IdType>;
  // @ts-expect-error - TS2564 - Property 'role' has no initializer and is not definitely assigned in the constructor.
  role: UserRole;
  internalRole: InternalRole | null | undefined;
  // @ts-expect-error - TS2564 - Property 'confirmedAccount' has no initializer and is not definitely assigned in the constructor.
  confirmedAccount: boolean;
  isStudioUser: boolean | null | undefined;

  constructor(adminUser: Record<any, any>) {
    Object.assign(this, adminUser);
  }

  get isPoinzAdmin() {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    return UserRoleHierarchy[this.role] <= UserRoleHierarchy.POINZ_ADMIN;
  }

  get isSuperAdmin() {
    return UserRoleHierarchy[this.role] === UserRoleHierarchy.SUPER_ADMIN;
  }
}
