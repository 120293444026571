import React from 'react';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { useLocale } from 'App/providers/LocaleProvider';
import { TranslationObject } from 'serviceNew/locale';
import { moment } from 'serviceNew/locale/dates';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import FieldLabel from '../FieldLabel';
import { TextInput } from 'App/components';

type Props = {
  label: TranslationObject;
  disabled: boolean;
} & FormContextProps &
  FieldRenderProps;

const TimePickerField = ({ field, meta, label, disabled, required, tooltip, className }: Props) => {
  const { error, touched } = meta;
  const { translate } = useLocale();
  const curDateTime = moment(`${moment().format('YYYY-MM-DD')}T${field.value}`);

  return (
    <div {...{ className }}>
      <FieldLabel {...{ label, tooltip, required }} />
      <DesktopTimePicker
        renderInput={props => (
          <TextInput
            {...props}
            noLabel
            disabled
            style={{ width: '100%' }}
            error={!!error && touched}
            placeholder={translate(label.key, label.context)}
            label={translate(label.key, label.context)}
            helperText={touched && error && translate(error.key, error.context)}
          />
        )}
        // @ts-expect-error - TS2322 - Type '{ renderInput: (props: FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps) => Element; ... 11 more ...; fullWidth: true; }' is not assignable to type 'IntrinsicAttributes & DesktopTimePickerProps<Moment, Moment> & RefAttributes<HTMLDivElement>'.
        error={!!error && touched}
        helperText={touched && error && translate(error.key, error.context)}
        ampm={/[AaPp][Mm]/.test(curDateTime.format('LT'))}
        placeholder={translate(label.key, label.context)}
        value={curDateTime}
        // @ts-expect-error - TS2531 - Object is possibly 'null'.
        onChange={evt => field.onChange(evt.format('HH:mm'))}
        inputFormat="LT"
        cancelLabel={translate('general.cancel')}
        okLabel={translate('general.ok')}
        disabled={disabled}
        inputVariant="outlined"
        fullWidth
      />
    </div>
  );
};

export default withFieldAdapter(withFormContext(TimePickerField));
