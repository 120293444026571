import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';
import { Text, RawText, FieldLabel, IconButton } from 'App/components';
import clsx from 'clsx';
import { withFormContext, FormContextProps } from '../forms/GenericForm';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import FileUpload, { FileUploadView, FileUploadViewProps } from '../FileUpload';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    flexDirection: 'column'
  },
  preview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(1, 2)
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

type Props = {
  className?: string | null | undefined;
  label: TranslationObject;
  required: boolean;
  accept: string;
  maxSize: number;
  noLabel: boolean;
} & FieldRenderProps &
  FormContextProps;

function FileUploadField(props: Props) {
  const { label, className, required, noLabel, accept, maxSize, field, form } = props;
  const classes = useStyles();
  const { onChange, value, name } = field;
  const { setFieldValue } = form;

  const onFileDrop = (fileToUpload: File[]) => {
    onChange(fileToUpload[0]);
  };

  function renderContent(fileUploadProps: FileUploadViewProps) {
    if (!value) {
      return <FileUploadView {...fileUploadProps} />;
    }
    const newFile = typeof value !== 'string';

    return (
      <div className={classes.preview}>
        {newFile ? <RawText>{value.name}</RawText> : <Text>general.existingFile</Text>}
        <div className={classes.icons}>
          <IconButton onClick={() => setFieldValue(name, null)} icon="cloud_upload" />
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(classes.container, className)}>
      {!noLabel && <FieldLabel {...{ label, required }} />}
      <FileUpload resizeFiles={false} onDrop={onFileDrop} {...{ accept, maxSize }}>
        {renderContent}
      </FileUpload>
    </div>
  );
}

export default withFieldAdapter(withFormContext(FileUploadField));
