export const downloadExcel = (blob: Blob, fileName = 'filename'): void => {
  // Create a URL for the Blob object
  const url = window.URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the file download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`); // Set the desired file name

  if (document.body) {
    const body = document.body;
    // Append the link to the body and programmatically click it to trigger the download
    body.appendChild(link);
    link.click();
    // Clean up after the download is initiated
    body.removeChild(link);
  }
  // Revoke the object URL to free up memory
  window.URL.revokeObjectURL(url);
};

const ACTIVITY_TYPE = {
  dealCollects: 'deal-collects',
  codeCollects: 'code-collects',
  cardCollects: 'card-collects'
} as const;

export type ActivityType = keyof typeof ACTIVITY_TYPE;

export const getActivityType = (activity: ActivityType) => ACTIVITY_TYPE[activity];
