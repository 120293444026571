import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[200],
    padding: theme.spacing(4, 2),
    textAlign: 'center'
  },
  version: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  }
}));
