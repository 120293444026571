import * as React from 'react';

import { TranslationObject } from 'serviceNew/locale';
import { MyTheme } from 'theme/muiTheme';

import { createStyles, WithStyles, withStyles } from '@mui/styles';
import Text from './Text';

const styles = createStyles((theme: MyTheme) => ({
  text: {
// @ts-expect-error - TS2339 - Property 'extra' does not exist on type 'Theme'.
    marginBottom: theme.extra.spacing.small,
  },
}));

type TitleProps = {
  label: TranslationObject
} & WithStyles<typeof styles>;

class Title extends React.PureComponent<TitleProps> {
  render() {
    const { label, classes } = this.props;
    return (
// @ts-expect-error - TS2339 - Property 'text' does not exist on type 'ClassNameMap<never>'.
      <Text className={classes.text} variant="h4" context={label.context}>
        {label.key}
      </Text>
    );
  }
}

export default withStyles(styles)(Title);
