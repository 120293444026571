import {useLocale} from 'App/providers/LocaleProvider';
import { MultiLanguageString } from 'serviceNew/locale';
import { selectMultiLanguage } from 'serviceNew/locale/strings';

import { useMultiLanguage } from 'App/components/MultiLanguageForm';

type Props = {
  text: MultiLanguageString | string,
  isMultiLanguage?: boolean
};

function MultiLanguageText(props: Props) {
  const { text, isMultiLanguage } = props;
// @ts-expect-error - TS2339 - Property 'language' does not exist on type 'LocaleProviderProps'.
  const { language: userLanguage } = useLocale();
  const { language: multiLanguage } = useMultiLanguage();

  const language = isMultiLanguage ? multiLanguage : userLanguage;

  if (typeof text === 'string') {
    return text;
  }
  return selectMultiLanguage(text, language);
}

export default MultiLanguageText;
