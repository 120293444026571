import * as React from 'react';

import { FieldRenderProps } from '../Field';
import RadioSelect from '../RadioSelect';
import FormError from '../FormError';

type Props = JSX.LibraryManagedAttributes<
  typeof RadioSelect,
  React.ComponentProps<typeof RadioSelect>
> &
  FieldRenderProps;

function RadioSelectField(props: Props) {
  const { field, meta, className, ...restProps } = props;
  const { touched, error } = meta;

  return (
    <div {...{ className }}>
      <RadioSelect {...restProps} {...field} />
      <FormError {...{ touched, error }} />
    </div>
  );
}

export default RadioSelectField;
