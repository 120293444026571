export type IdType = number;

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export enum AuthProvider {
  POINZ = 'POINZ',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK'
}

export enum PoinzStatus {
  ROOKIE = 'ROKIE',
  STAR = 'STAR',
  HERO = 'HERO',
  KING = 'KING'
}

export enum Locale {
  EN = 'en',
  DE = 'de',
  IT = 'it',
  FR = 'fr',
  TH = 'th'
}

export interface UserDevice {
  id: number;
  pushToken?: string;
  notificationEnabled: boolean;
  pushSubscriptions: string[];
  systemName?: string;
  systemVersion?: string;
  brand?: string;
  device?: string;
}

export enum PoinzType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

export enum PoinzConfigurationStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export interface SimpleBusinessProfile {
  id: number;
  name: string;
  logo: string;
  mainColor: string;
  logoBackgroundColor: string;
}
