import { TranslationKey } from '@poinz/locale';
import { ScaledImage } from '../../../types';
import { IFlatAddress } from '../../user/types/addresses';
import { BusinessProfile } from '../../backend/types';

export enum ICartState {
  SHOPPING = 'SHOPPING',
  CHECKOUT = 'CHECKOUT',
  PURCHASE = 'PURCHASE'
}
export enum ICartItemType {
  GIFT_CARD = 'GIFT_CARD',
  DEAL = 'DEAL'
}

export enum ILoyaltyStatus {
  NOT_USED = 'NOT_USED',
  REQUESTED = 'REQUESTED',
  RESERVED = 'RESERVED'
}

export enum IItemStatus {
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  RESERVED = 'RESERVED',
  UNAVAILABLE = 'UNAVAILABLE'
}

export interface ICheckoutRecipient {
  from?: string;
  message?: string;
  email?: string;
}

export interface IDealItemBody {
  dealId: number;
  dealOptionId: number;
  itemType: ICartItemType.DEAL;
  quantity: number;
  price: number;
  recipient?: ICheckoutRecipient | null;
}

export interface IGiftCardItemBody {
  giftCardId: number;
  itemType: ICartItemType.GIFT_CARD;
  quantity: number;
  price: number;
  recipient?: ICheckoutRecipient | null;
}

export type IItemBody = IGiftCardItemBody | IDealItemBody;

export interface IUpdateItemBody {
  quantity: number;
  price: number;
  recipient: ICheckoutRecipient | null;
}

export interface ICartAddresses {
  deliveryAddress?: IFlatAddress;
  invoiceAddress: IFlatAddress;
}

export enum ICashbackType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

export interface ICartItem {
  id: number;
  businessProfile?: BusinessProfile;
  giftCardId: number;
  dealId: number;
  dealOptionId: number;
  name: string;
  picture: ScaledImage;
  itemType: ICartItemType;
  quantity: number;
  cashback: number;
  cashbackValue: number;
  cashSavingValue: number;
  cashbackType: ICashbackType;
  price: number;
  originalPrice?: number;
  status: IItemStatus;
  recipient: ICheckoutRecipient | null;
  unavailableDescription: TranslationKey;
  editable: boolean;
}

export interface IShoppingCart {
  id: number;
  userId: number;
  totalNumberOfItems: number;
  state: ICartState;
  stateChangedAt: string;
  currentStateExpiresAt: string;
  // total price of items without Swiss Loyalty discount
  totalPrice: number;
  // price that should be paid with DataTrans (without Swiss Loyalty discount)
  finalPrice: number;
  cashback: number;
  cashSaving: number;
  referenceNumber: string;
  items: ICartItem[];
  deliveryAddress?: IFlatAddress;
  invoiceAddress?: IFlatAddress;
  loyaltyCash: number;
  // default NOT_USED, it can be changed from REQUESTED to RESERVED when you move to CHECKOUT state
  loyaltyStatus: ILoyaltyStatus;
  // when this is true, it's safe to move checkout to PURCHASE state
  readyForPurchase: boolean;
  fullyPaidWithLoyalty: boolean;
  needsDeliveryAddress: boolean;
}

export interface ILoyaltyUsage {
  useLoyalty: boolean;
}
