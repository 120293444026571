import {BUSINESS_PROFILES} from 'serviceNew/api/businessProfile';
import { callEndpoint } from './apiClient';

export const STATS_API = 'stats';
export const OVERVIEW = 'overview';
export const DETAILS = 'details';
export const ACTIVITY_TIMELINE = 'activity-timeline';

async function getByBpId(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${OVERVIEW}`,
    'GET',
    {}
  );
  return result.body;
}

async function getActivityTimeline(params: any): Promise<any> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${ACTIVITY_TIMELINE}`,
    'GET',
    {
      queryParams: {
        resolution: params.resolution,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    }
  );
  return result.body;
}

export default {
  getByBpId,
  getActivityTimeline,
};
