import { Language, MultiLanguageString } from '@poinz/locale';
import { ScaledImage } from '../../../types/image';

export const BP_PERMISSIONS = {
  ENABLE_POS_ACTIVE_USERS: 'ENABLE_POS_ACTIVE_USERS'
};

export const initialBpPermissions = {
  ENABLE_POS_ACTIVE_USERS: false
};

export type BpPermissionkeys = keyof typeof BP_PERMISSIONS;

export type BpPermissions = { [BpPermissionkeys: string]: boolean };

export class BusinessProfile {
  id: number;

  apiDefaultLocationId: number;

  activateApi: boolean;

  apiKey: string;

  contractCompanyId: number;

  created: string;

  defaultLanguage: Language;

  facebookId: string;

  instagramId: string;

  logo: string;

  mainColor: string;

  logoBackgroundColor: string;

  name: string;

  picture: ScaledImage;

  pictureMainColor: string;

  reportingEmail: string;

  twitterId: string;

  url: string;

  description: MultiLanguageString;

  descriptionLocalized: string;

  permissions: BpPermissions;

  constructor(businessProfile: any) {
    Object.assign(this, businessProfile);
    this.activateApi = this.apiKey != null;
  }
}
