import React from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Switch, withRouter, Redirect } from 'react-router';
import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PrivateRoute as PrivateRouteRefactored, ErrorPage } from 'App/components';
import Snackbar, { withSnackbar, SnackbarProps } from 'App/components/Snackbar';
import routes, {
  SETUP_MFA_AUTH,
  ADMINISTRATION,
  DEALS,
  DASHBOARD,
  COMMUNICATION
} from 'App/routing';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { UserRoles } from 'serviceNew/model/adminUser';

import { isEnrolledToMFA } from 'serviceNew/firebase/auth';
import Dashboard from './Dashboard';
import { useIsLoggedIn } from 'serviceNew/auth';
import PrivateRoute from '../PrivateRoute';
import './MainContainer.css';
import UpgradePage from './UpgradePage';
import { localeSubject, useLocale } from 'App/providers/LocaleProvider';

const Login = React.lazy(() => import('App/routes/Login'));
const AdminUser = React.lazy(() => import('App/routes/AdminUser'));
const Administration = React.lazy(() => import('App/routes/Administration'));
const Communication = React.lazy(() => import('App/routes/Communication'));
const ForgotPassword = React.lazy(() => import('App/routes/ForgotPassword'));
const AccountSettings = React.lazy(() => import('App/routes/AccountSettings'));
const Loyalty = React.lazy(() => import('App/routes/Loyalty'));
const AwinRoutes = React.lazy(() => import('App/routes/AwinRoutes'));
const SetupMFA = React.lazy(() => import('App/routes/SetupMFA'));
const AdminRoutes = React.lazy(() => import('App/routes/AdminRoutes'));
const GiftCardRoutes = React.lazy(() => import('App/routes/GiftCard'));
const TransactionRoutes = React.lazy(() => import('App/routes/Transaction'));
const WorldlineRoutes = React.lazy(() => import('App/routes/WorldlinePartners'));
const AdtractionRoutes = React.lazy(() => import('App/routes/AdtractionRoutes'));
const TradedoublerRoutes = React.lazy(() => import('App/routes/TradedoublerRoutes'));
const Explore = React.lazy(() => import('App/routes/Explore'));
const Deals = React.lazy(() => import('App/routes/Deals'));

const styles = (theme: any) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    position: 'relative'
  },
  toolbar: theme.mixins.toolbar,
  snackbar: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    transform: 'none'
  }
});

export const MainContainerUnconnected = ({
  classes
}: {
  classes: any;
} & SnackbarProps) => {
  const adminUser = useAdminUser();
  const { isLoading, userRole } = adminUser;
  const { locale } = useLocale();
  const { pathname } = useLocation();
  const isLoggedIn = useIsLoggedIn();

  const isEnrolled = isEnrolledToMFA();

  React.useEffect(() => {
    localeSubject.next(locale);
  }, [locale]);

  const needsMFAForSuperAdmin = isLoggedIn && userRole === UserRoles.SUPER_ADMIN && !isEnrolled;

  if (needsMFAForSuperAdmin && pathname !== SETUP_MFA_AUTH) {
    return <Redirect to={{ pathname: routes.setupMultifactorAuth.route }} />;
  } else if (!needsMFAForSuperAdmin && pathname === SETUP_MFA_AUTH) {
    return <Redirect to={{ pathname: `${ADMINISTRATION}/overview` }} />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Snackbar classes={{ root: classes.snackbar }}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={ADMINISTRATION} />} />
              <Route path="/login" component={Login} />
              <Route path={routes.forgotPassword.route} component={ForgotPassword} />
              <Route path={routes.adminUser.route} component={AdminUser} />

              <Route
                path={[
                  routes.subscriptions.route,
                  routes.dealBillings.route,
                  routes.salesCommission.route,
                  routes.orders.route
                ]}
                component={AdminRoutes}
              />
              <PrivateRouteRefactored
                path="/"
                render={() => (
                  <>
                    <Switch>
                      <PrivateRoute
                        path={routes.accountSettings.route}
                        component={AccountSettings}
                      />
                      <PrivateRoute path={DASHBOARD} component={Dashboard} />
                      <PrivateRoute path={routes.administration.route} component={Administration} />
                      {needsMFAForSuperAdmin && (
                        <PrivateRoute
                          path={routes.setupMultifactorAuth.route}
                          component={SetupMFA}
                        />
                      )}
                      <PrivateRoute
                        loyalty
                        path={routes.loyalty.route}
                        component={Loyalty}
                        elseComponent={UpgradePage}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.awin.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={AwinRoutes}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.adtraction.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={AdtractionRoutes}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.tradedoubler.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={TradedoublerRoutes}
                      />
                      <PrivateRoute
                        path={routes.giftcardLines.route}
                        roles={UserRoles.POINZ_ADMIN}
                        component={GiftCardRoutes}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.transaction.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={TransactionRoutes}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.worldline.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={WorldlineRoutes}
                      />
                      <PrivateRoute
                        path={routes.swissLoyalty.explore.route}
                        roles={UserRoles.SUPER_ADMIN}
                        component={Explore}
                      />
                      <PrivateRoute
                        deal
                        path={DEALS}
                        component={Deals}
                        elseComponent={UpgradePage}
                      />
                      <PrivateRoute
                        loyalty
                        deal
                        path={COMMUNICATION}
                        component={Communication}
                        elseComponent={UpgradePage}
                      />
                      <Route
                        path=""
                        render={() => (
                          <ErrorPage
                            title="Not found"
                            message="The requested resource cannot be found."
                          />
                        )}
                      />
                    </Switch>
                  </>
                )}
              />
            </Switch>
          </React.Suspense>
        </Snackbar>
      )}
    </main>
  );
};

// @ts-expect-error - TS2345 - Argument of type '(theme: any) => { content: { flexGrow: number; backgroundColor: any; padding: any; position: string; }; toolbar: any; snackbar: { position: string; bottom: any; left: any; transform: string; }; }' is not assignable to parameter of type 'Styles<any, any, string>'.
export default withStyles(styles, { withTheme: true })(
  withRouter(withSnackbar(MainContainerUnconnected))
);
