import * as React from 'react';
import { Grid } from '@mui/material';

type Props = {
  children: React.ReactElement<any>[],
  isInnerList?: boolean
};

const GridContainer = ({
  children,
  isInnerList,
}: Props) => (
  <Grid container spacing={2}>
    {React.Children.map(children, child =>
      isInnerList ? (
        <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
          {child}
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          {child}
        </Grid>
      )
    )}
  </Grid>
);

export default GridContainer;
