import React from 'react';
import _ from 'lodash';
import { LanguageInput, NumberInput } from 'App/providers/LocaleProvider';
import { BarChart } from 'App/components';
import { TranslationKey } from 'serviceNew/locale/translations';
import { GridContainer, GridItem } from '../../../../components/FlexGridContainer';
import { DashboardCardBody } from '../../../../components/DashboardCard';
import StatsCardList from '../../../../components/StatsCardList';
import { MESSAGE_STATS } from '../../../../../constants/general';
import { MessageStatsDetailsProps } from './MessageStatsTable';

type Props = MessageStatsDetailsProps & {
  formatMessage: (arg1: LanguageInput) => string;
  formatNumber: (arg1: NumberInput) => string;
};

class MessageStatsDetails extends React.PureComponent<Props> {
  getDuration = (value?: number | null): string | null | undefined | number | null | undefined => {
    if (!value) {
      return value;
    }
    if (value > 86400) {
      return this.formatDuration(value / (60 * 60 * 24), 'general.days');
    }
    if (value > 6000) {
      return this.formatDuration(value / (60 * 60), 'general.hours');
    }
    if (value > 100) {
      return this.formatDuration(value / 60, 'general.minutes');
    }
    return this.formatDuration(value, 'general.seconds');
  };

  formatDuration = (value: number, id: TranslationKey): string => {
    const { formatMessage, formatNumber } = this.props;
    return `
      ${formatNumber({ value, style: 'decimal', maximumFractionDigits: 1 })}
      ${formatMessage({ id })}
    `;
  };

  getStatsList = () => {
    const { messageDetails } = this.props;
    return _.reduce(
      messageDetails,
      (acc, val, key) =>
        // @ts-expect-error - TS2769 - No overload matches this call. | TS2345 - Argument of type 'string | number | any[] | null | undefined' is not assignable to parameter of type 'number | null | undefined'.
        _.isFinite(val) ? acc.concat({ name: key, value: this.getDuration(val) }) : acc,
      []
    );
  };

  render() {
    const { messageDetails, resolution } = this.props;

    const activityTimeline = _.get(messageDetails, 'activityTimelineData', []);
    const statsList = this.getStatsList();

    return (
      <GridContainer>
        <GridItem xs={12} sm={9}>
          <DashboardCardBody elevation={0}>
            <BarChart
              name="activityTimeline"
              height={250}
              title="stats.activityTimeline"
              chartData={activityTimeline}
              chartList={['numSent', 'numRead', 'numPushed', 'numCollected']}
              xaxis="dateDay"
              resolution={resolution}
              tooltip
              legend
            />
          </DashboardCardBody>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <DashboardCardBody elevation={0}>
            <StatsCardList statsList={statsList} statsFormat={MESSAGE_STATS} vertical />
          </DashboardCardBody>
        </GridItem>
      </GridContainer>
    );
  }
}

export default MessageStatsDetails;
