import * as React from 'react';

import { withFormContext, FormContextProps } from '../forms/GenericForm';
import TextSelect from '../TextSelect';
import { withFieldAdapter, FieldRenderProps } from '../Field';

export { getOptionsFromEnum } from '../TextSelect';

type Props = FieldRenderProps &
  FormContextProps &
  JSX.LibraryManagedAttributes<typeof TextSelect, React.ComponentProps<typeof TextSelect>>;

function TextSelectField(props: Props) {
  const { field, meta, submitting, required, disabled, InputProps, name, ...restProps } = props;
  const { error, touched } = meta;
  return (
    <TextSelect
      error={!!error && !!touched}
      {...field}
      required={required}
      disabled={submitting || disabled}
      helperText={error && touched ? error : undefined}
      InputProps={{ ...InputProps, name }}
      {...restProps}
    />
  );
}

TextSelectField.defaultProps = {
  required: false
};

// @ts-expect-error - TS2345 - Argument of type 'typeof TextSelectField' is not assignable to parameter of type 'ComponentType<FormContextProps>'.
export default withFieldAdapter(withFormContext(TextSelectField));
