import { PartnerClient } from '../../../partnerClient';
import businessProfile from './businessProfile';
import contractCompany from './contractCompany';
import location from './location';

export default (apiClient: PartnerClient) => ({
  businessProfile: businessProfile(apiClient),
  contractCompany: contractCompany(apiClient),
  location: location(apiClient)
});
