// import { generatePath } from 'react-router';
import _ from 'lodash';

// DEPRECATED Use routes object below instead
export const ADMINISTRATION = '/administration';
export const LOYALTY = '/loyalty';
export const DEALS = '/deals';
export const SWISS_LOYALTY = '/swissLoyalty';
export const AWIN = `${SWISS_LOYALTY}/awin`;
export const TRANSACTION = `${SWISS_LOYALTY}/transaction`;
export const DEAL_RULES = '/deal-rules';
export const DEALS_OVERVIEW = '/deals/overview';
export const DEALS_REDEEM_OFFLINE = '/deals/redeem-offline';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_OVERVIEW = '/dashboard/overview';
export const DASHBOARD_LOYALTY = '/dashboard/loyalty';
export const DASHBOARD_ACTIVITY = '/dashboard/activity';
export const DASHBOARD_POINZ_ACTIVITY = '/dashboard/poinz-activity';
export const DASHBOARD_LOCATIONS = '/dashboard/locations';
export const DASHBOARD_DEALS = '/dashboard/deals';
export const DASHBOARD_POINZ = '/dashboard/poinz';
export const DASHBOARD_COMMUNICATION = '/dashboard/communication';
export const DASHBOARD_MARKETING_CAMPAIGNS = '/dashboard/marketing-campaigns';
export const COMMUNICATION = '/communication';
export const COMMUNICATION_OVERVIEW = '/communication/overview';
export const COMMUNICATION_COUPONS = '/communication/coupons';
export const MARKETING_CAMPAIGNS = '/communication/marketing-campaigns';
export const DEAL_BILLINGS = '/deal-billings';
export const VOUCHER = '/voucher';
export const GIFTCARDS = '/giftcards';
export const SETUP_MFA_AUTH = '/setup-multifactor-auth';

interface RouteObject {
  [key: string]: {
    getRoute: (args: Record<string, string>) => string;
    getMatch: () => string;
    route: string;
    // Recursive call
    [nestedKey: string]: RouteObject[keyof RouteObject] | any;
  };
}

function createRoutes<T>(routes: T, prefix: string): RouteObject {
  // @ts-expect-error - TS2769 - No overload matches this call.
  return _.mapValues(routes, (value, key) => {
    // @ts-expect-error - TS2339 - Property 'startsWith' does not exist on type 'number'. | TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
    const newPrefix = `${prefix}/${key.startsWith(':') ? key : _.kebabCase(key)}`;
    return {
      getRoute: args => {
        const regex = new RegExp(
          Object.keys(args)
            .map(k => `:${k}`)
            .join('|'),
          'gi'
        );
        return newPrefix.replace(regex, match => args[match.substring(1)]);
      },
      getMatch: () => {
        const regex = /:[a-zA-Z]+/gi;
        return newPrefix.replace(regex, match => `${match}(\\d+)`);
      },
      route: newPrefix,
      ...createRoutes(value, newPrefix)
    };
  });
}

const ROUTES = createRoutes(
  {
    accountSettings: {},
    adminUser: {
      resetPassword: {}
    },
    forgotPassword: {},
    setupMultifactorAuth: {},
    administration: {
      appLinks: {},
      overview: {},
      categories: {
        create: {},
        ':id': {}
      },
      contractCompany: {
        search: {},
        update: {}
      },
      userManagement: {},
      reports: {
        create: {},
        ':id': {}
      },
      promoDeals: {
        create: {},
        ':id': {}
      },
      cardTemplates: {
        create: {},
        ':id': {
          appLinks: {}
        }
      },
      voucher: {
        create: {},
        ':id': {}
      },
      businessProfiles: {
        create: {},
        ':id': {}
      },
      locations: {
        create: {},
        ':id': {}
      },
      posDevices: {
        create: {},
        ':id': {}
      },
      referralCampaigns: {
        create: {},
        ':id': {
          update: {},
          links: {
            create: {}
          }
        }
      },
      labeling: {}
    },
    loyalty: {
      overview: {},
      offering: {
        create: {},
        ':id': {}
      },
      codeGroup: {
        create: {},
        ':id': {}
      },
      code: {
        create: {},
        ':id': {}
      }
    },
    swissLoyalty: {
      awin: {
        create: {},
        ':id': {
          edit: {},
          commissions: {
            create: {},
            ':commissionId': {}
          },
          promos: {
            create: {},
            ':promoId': {}
          },
          campaigns: {
            create: {},
            ':campaignId': {}
          }
        }
      },
      adtraction: {
        claim: {},
        create: {},
        ':id': {
          edit: {},
          commissions: {
            ':commissionId': {}
          }
        }
      },
      tradedoubler: {
        ':id': {
          edit: {},
          commissions: {
            ':commissionId': {}
          }
        }
      },
      explore: {},
      transaction: {},
      worldline: {
        create: {},
        ':id': {
          update: {},
          terminals: {
            create: {}
          }
        }
      }
    },
    giftcardLines: {
      ':id': {
        giftcards: {
          ':giftcardId': {}
        }
      },
      purchases: {
        create: {}
      }
    },
    deals: {
      overview: {},
      create: {},
      ':id': {
        overview: {},
        dealRules: {
          ':dealRuleId': {},
          create: {}
        },
        redemptionType: {},
        appLinks: {},
        pictures: {},
        update: {}
      },
      redeemOffline: {}
    },
    dashboard: {
      overview: {},
      loyalty: {},
      activity: {},
      poinzActivity: {},
      marketingCampaigns: {},
      locations: {},
      deals: {
        ':id': {
          dealRules: {
            ':dealRuleId': {}
          }
        }
      },
      poinz: {},
      communication: {}
    },
    communication: {
      messages: {
        overview: {},
        create: {},
        ':id': {
          blocks: {},
          targetingRules: {},
          send: {}
        }
      },
      coupons: {
        overview: {},
        create: {},
        ':id': {}
      },
      marketingCampaigns: {
        overview: {},
        create: {},
        ':id': {
          appLinks: {}
        }
      }
    },
    dealBillings: {},
    orders: {},
    subscriptions: {
      create: {},
      ':id': {
        orders: {
          create: {},
          ':orderId': {
            update: {}
          }
        },
        singleOrders: {
          create: {},
          ':orderId': {
            update: {}
          }
        },
        update: {}
      },
      overview: {}
    },
    salesCommission: {}
  },
  ''
);

export default ROUTES;
