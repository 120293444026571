import * as React from 'react';

import {
  MarketingCampaignStats,
  MarketingCampaignDetailStats,
} from 'serviceNew/model/marketingCampaignStats';

import {
  DashboardNumberBlock,
  DashboardSection,
  FlexRow,
  FlexItem,
  FlexContainer,
} from 'App/components';
import { LineChart } from 'App/components/charts';

const fields = [
  {
    label: { key: 'marketingCampaign.totalPlayer' },
    propertyName: 'totalParticipant',
  },
  {
    label: { key: 'marketingCampaign.totalUniquePlayer' },
    propertyName: 'totalUniquePlayer',
  },
  {
    label: { key: 'marketingCampaign.totalWinner' },
    propertyName: 'totalWinner',
  },
  {
    label: { key: 'marketingCampaign.totalWinnerNotCollected' },
    propertyName: 'totalWinnerNotCollected',
  },
];

type Props = {
  activityData: MarketingCampaignDetailStats[],
  marketingCampaignStats: MarketingCampaignStats
};

function MarketingCampaignKeyFigureStats(props: Props) {
  const { activityData, marketingCampaignStats } = props;

  return (
    <DashboardSection title={{ key: 'marketingCampaign.keyFigures' }} withPadding>
      <FlexContainer>
        <FlexRow>
          <FlexItem>
            <DashboardNumberBlock data={marketingCampaignStats} {...{ fields }} />
          </FlexItem>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <LineChart
              data={activityData}
              dataSets={['numParticipant']}
              dataSetNames={{ numParticipant: { key: 'marketingCampaign.totalPlayer' } }}
              xAxis="dateDay"
              height={250}
            />
          </FlexItem>
        </FlexRow>
      </FlexContainer>
    </DashboardSection>
  );
}

export default MarketingCampaignKeyFigureStats;
