import { PoinzApiClient } from '../../api';
import { BusinessLocation } from './types/dealLocation';

const ROOT = '/v2/web/locations/deals';
const PUBLIC_ROOT = '/v2/public/locations/deals';

const get =
  (apiClient: PoinzApiClient) =>
  async (dealId: string): Promise<BusinessLocation[]> => {
    const result = await apiClient.callAuthenticatedEndpoint(ROOT, 'GET', {
      queryParams: { dealId }
    });
    return result.body;
  };

const getPublic =
  (apiClient: PoinzApiClient) =>
  async (dealId: number): Promise<BusinessLocation[]> => {
    const result = await apiClient.callAuthenticatedEndpoint(PUBLIC_ROOT, 'GET', {
      queryParams: { dealId }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  getPublic: getPublic(apiClient)
});
