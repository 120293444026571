import * as React from 'react';
import moment from 'moment';
import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import { useLocation } from 'App/providers/LocationProvider';
import { PaginationModel, defaultPaginatedResult } from 'serviceNew/api/api';
import collectActivityApi from 'serviceNew/api/collectActivity';
import {
  CodeCollectActivity,
  CardCollectActivity,
  DealCollectActivity
} from 'serviceNew/model/collectActivity';
import routes from 'App/routing';
import dealApi from 'serviceNew/api/deal';
import { Deal } from 'serviceNew/model/deal';
import offeringApi from 'serviceNew/api/offering';
import { Offering } from 'serviceNew/model/offering';
import { useLocale } from 'App/providers/LocaleProvider';
import { DEAL_COLLECT_STATE, DEAL_TYPE_TRANSLATIONS } from 'constants/general';
import { useQueryState } from '@poinz/hooks';
import NavigationHeaderProvider from 'App/providers/NavigationHeaderProvider';
import FilterContainer, { FILTER_TYPE } from 'App/components/Filter';
import { getOptionsFromEnum } from 'App/components/TextSelect';
import { makeStyles } from '@mui/styles';
import ContractCompanyGuard from 'App/guards/ContractCompanyGuard';
import { Button } from 'App/components';
import {
  FILTER,
  FILTER_ACTION,
  filterReducer,
  initialFilterState
} from './collectsActivityReducerFilter';
import { downloadExcel, getActivityType, ActivityType } from './CollectsActivity.utils';
import { useSnackbar } from 'App/components/Snackbar';
import { formatDateAndTime } from 'util/dates';
import DealCollectsActivityTable from './DealCollectsActivity/DealCollectsActivity';
import CardCollectsActivityTable from './CardCollectsActivity/CardCollectsActivity';
import CodeCollectsActivityTable from './CodeCollectsActivity/CodeCollectsActivity';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: theme.spacing(2)
  }
}));

const TABS: {
  [key: string]: ActivityType;
} = {
  DEAL_COLLECT: 'dealCollects',
  CARD_COLLECT: 'cardCollects',
  CODE_COLLECT: 'codeCollects'
};

const activityRoutes = routes.dashboard.activity;

function CollectsActivity() {
  const { contractCompany, hasDealPermission, hasLoyaltyPermission, contractCompanyId } =
    useContractCompany();
  const classes = useStyles();
  const { translate, parseMultilanguageString, formatDateTime } = useLocale();
  const { locations } = useLocation();
  const { businessProfiles: businessProfilesList, selectBusinessProfile } = useBusinessProfile();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [codeCollectsActivity, setCodeCollectsActivity] =
    React.useState<PaginationModel<CodeCollectActivity>>(defaultPaginatedResult);
  const [cardCollectsActivity, setCardCollectsActivity] =
    React.useState<PaginationModel<CardCollectActivity>>(defaultPaginatedResult);
  const [dealCollectsActivity, setDealCollectsActivity] =
    React.useState<PaginationModel<DealCollectActivity>>(defaultPaginatedResult);
  const [dealList, setDealList] = React.useState<Deal[]>([]);
  const [offeringList, setOfferingList] = React.useState<Offering[]>([]);
  const [activeTab, setActiveTab] = useQueryState('activeTab', TABS.DEAL_COLLECT);
  const [filterState, dispatch] = React.useReducer(filterReducer, initialFilterState);
  const [preparingDownload, setPreparingDownload] = React.useState(false);
  const showSnackbar = useSnackbar();

  const {
    FROM_DATE: from,
    TO_DATE: to,
    BUSINESS_PROFILE: businessProfileId,
    LOCATION: locationId,
    OFFERING_ID: offeringId,
    DEAL_ID: dealId,
    DEAL_COLLECT_STATE: dealCollectState,
    DEAL_TYPE: dealType
  } = filterState;

  const pageNumbers = React.useRef<{
    [key: string]: number;
  }>({
    [TABS.CODE_COLLECT]: 0,
    [TABS.CARD_COLLECT]: 0,
    [TABS.DEAL_COLLECT]: 0
  });

  const handleExport = React.useCallback(async () => {
    const queryParams = {
      dealType,
      dealCollectState,
      dealId,
      offeringId,
      locationId,
      businessProfileId,
      // @ts-expect-error - TS2345 - Argument of type 'Moment | null | undefined' is not assignable to parameter of type 'string | null | undefined'.
      from: formatDateAndTime(from),
      // @ts-expect-error - TS2345 - Argument of type 'Moment | null | undefined' is not assignable to parameter of type 'string | null | undefined'.
      to: formatDateAndTime(to)
    } as const;

    // @ts-expect-error - TS2345 - Argument of type '"dealCollects" | "codeCollects" | "cardCollects" | undefined' is not assignable to parameter of type '"dealCollects" | "codeCollects" | "cardCollects"'.
    const type = getActivityType(activeTab);

    if (contractCompanyId && type) {
      setPreparingDownload(true);
      try {
        const blob = await collectActivityApi.exportActivityIntoExcel(
          // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
          contractCompanyId,
          type,
          queryParams
        );
        downloadExcel(blob, `activities-${type}-${new Date().getTime()}`);
        showSnackbar({
          key: 'general.download.success'
        });
      } catch (error: any) {
        showSnackbar({
          key: 'error.unknown.body'
        });
      } finally {
        setPreparingDownload(false);
      }
    }
  }, [
    activeTab,
    businessProfileId,
    contractCompanyId,
    dealCollectState,
    dealId,
    dealType,
    from,
    locationId,
    offeringId,
    showSnackbar,
    to
  ]);

  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const queryParams = {
        locationId,
        businessProfileId,
        // @ts-expect-error - TS2345 - Argument of type 'Moment | null | undefined' is not assignable to parameter of type 'string | null | undefined'.
        from: formatDateAndTime(from),
        // @ts-expect-error - TS2345 - Argument of type 'Moment | null | undefined' is not assignable to parameter of type 'string | null | undefined'.
        to: formatDateAndTime(to)
      } as const;
      if (activeTab === TABS.CODE_COLLECT) {
        const pageNumber =
          (codeCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.CODE_COLLECT]
            ? codeCollectsActivity?.pageNumber
            : 0;
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        const codeCollects = await collectActivityApi.getCodeCollectsActivity(contractCompanyId, {
          pageNumber,
          offeringId,
          ...queryParams
        });
        pageNumbers.current[TABS.CODE_COLLECT] = pageNumber;
        setCodeCollectsActivity({ ...codeCollects, totalPages: codeCollects.totalPages + 1 });
      }
      if (activeTab === TABS.CARD_COLLECT) {
        const pageNumber =
          (cardCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.CARD_COLLECT]
            ? cardCollectsActivity?.pageNumber
            : 0;
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        const cardCollects = await collectActivityApi.getCardCollectsActivity(contractCompanyId, {
          pageNumber,
          offeringId,
          ...queryParams
        });
        pageNumbers.current[TABS.CARD_COLLECT] = pageNumber;
        setCardCollectsActivity({ ...cardCollects, totalPages: cardCollects.totalPages + 1 });
      }
      if (activeTab === TABS.DEAL_COLLECT) {
        const pageNumber =
          (dealCollectsActivity?.pageNumber || 0) !== pageNumbers.current[TABS.DEAL_COLLECT]
            ? dealCollectsActivity?.pageNumber
            : 0;
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        const dealCollects = await collectActivityApi.getDealCollectsActivity(contractCompanyId, {
          pageNumber,
          dealId,
          dealCollectState,
          dealType,
          ...queryParams
        });
        pageNumbers.current[TABS.DEAL_COLLECT] = pageNumber;
        setDealCollectsActivity({ ...dealCollects, totalPages: dealCollects.totalPages + 1 });
      }
      setIsLoading(false);
    }

    if (contractCompanyId) {
      loadData();
    }
  }, [
    activeTab,
    businessProfileId,
    dealId,
    dealType,
    dealCollectState,
    to,
    cardCollectsActivity.pageNumber,
    codeCollectsActivity.pageNumber,
    dealCollectsActivity.pageNumber,
    from,
    locationId,
    offeringId,
    contractCompanyId
  ]);

  React.useEffect(() => {
    (async function loadDealList() {
      setIsLoading(true);
      if (businessProfileId) {
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
        const newDealLists = await dealApi.getDealList(businessProfileId);
        setDealList(newDealLists);
      } else {
        // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
        const newDealLists = await dealApi.getContractCompanyDealList(contractCompanyId);
        setDealList(newDealLists);
      }
      setIsLoading(false);
    })();
  }, [businessProfileId, contractCompanyId]);

  React.useEffect(() => {
    (async function loadOfferings() {
      if (businessProfileId) {
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
        const retrievedOfferings = await offeringApi.getOfferings(businessProfileId);
        setOfferingList(retrievedOfferings);
      } else {
        setOfferingList([]);
      }
    })();
  }, [businessProfileId]);

  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'number'.
    selectBusinessProfile(businessProfileId);
  }, [businessProfileId, selectBusinessProfile]);

  const handlePageChange = (toPage: any) => {
    const pageNumber = toPage - 1;
    if (activeTab === TABS.CODE_COLLECT) {
      setCodeCollectsActivity(currValue => ({ ...currValue, pageNumber }));
    }
    if (activeTab === TABS.CARD_COLLECT) {
      setCardCollectsActivity(currValue => ({ ...currValue, pageNumber }));
    }
    if (activeTab === TABS.DEAL_COLLECT) {
      setDealCollectsActivity(currValue => ({ ...currValue, pageNumber }));
    }
  };

  const filters = React.useMemo(
    () => [
      [
        {
          key: FILTER.FROM_DATE,
          label: { key: 'general.from' },
          onChange: value => dispatch({ type: FILTER_ACTION.SET, value, filter: FILTER.FROM_DATE }),
          filterType: FILTER_TYPE.DATE,
          isDateTime: true,
          getChipValue: value => (value ? formatDateTime(moment(value).toISOString(), 'f') : null),
          onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.FROM_DATE }),
          pickerProps: {
            maxDate: to || undefined,
            disableFuture: true
          }
        },
        {
          key: FILTER.TO_DATE,
          label: { key: 'general.to' },
          onChange: value => dispatch({ type: FILTER_ACTION.SET, value, filter: FILTER.TO_DATE }),
          filterType: FILTER_TYPE.DATE,
          isDateTime: true,
          pickerProps: {
            minDate: from || undefined,
            disableFuture: true
          },
          getChipValue: value => (value ? formatDateTime(moment(value).toISOString(), 'f') : null),
          onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.TO_DATE })
        }
      ],
      ...(businessProfilesList && businessProfilesList.length > 0
        ? [
            {
              key: FILTER.BUSINESS_PROFILE,
              label: { key: 'businessProfile.businessProfile' },
              onChange: value => {
                dispatch({
                  type: FILTER_ACTION.SET,
                  filter: FILTER.BUSINESS_PROFILE,
                  value
                });
              },
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => {
                const businessProfile = businessProfilesList.find(bp => bp.id === value);

                return businessProfile ? businessProfile.name : translate('text.no.translation');
              },
              options: businessProfilesList.map(businessProfile => ({
                label: { key: 'text.no.translation', context: { value: businessProfile.name } },
                value: businessProfile.id
              })),
              onDelete: () => {
                dispatch({
                  type: FILTER_ACTION.REMOVE,
                  filter: FILTER.BUSINESS_PROFILE
                });
              }
            }
          ]
        : []),
      ...(locations && locations.length > 0
        ? [
            {
              key: FILTER.LOCATION,
              label: { key: 'location.location' },
              onChange: value =>
                dispatch({
                  filter: FILTER.LOCATION,
                  type: FILTER_ACTION.SET,
                  value
                }),
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => {
                const location = locations.find(loc => loc.id === value);

                return location ? location.name : translate('text.no.translation');
              },
              options: locations.map(location => ({
                label: { key: 'text.no.translation', context: { value: location.name } },
                value: location.id
              })),
              onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.LOCATION })
            }
          ]
        : []),
      ...(offeringList && offeringList.length > 0 && activeTab !== TABS.DEAL_COLLECT
        ? [
            {
              key: FILTER.OFFERING_ID,
              label: { key: 'offering.offering' },
              onChange: value =>
                dispatch({
                  filter: FILTER.OFFERING_ID,
                  type: FILTER_ACTION.SET,
                  value
                }),
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => {
                const offeringListItem = offeringList.find(loc => loc.id === value);

                return offeringListItem
                  ? parseMultilanguageString(offeringListItem.name)
                  : translate('text.no.translation');
              },
              options: offeringList.map(ol => ({
                label: {
                  key: 'text.no.translation',
                  context: { value: parseMultilanguageString(ol.name) }
                },
                value: ol.id
              })),
              onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.OFFERING_ID })
            }
          ]
        : []),
      ...(dealList && dealList.length > 0 && activeTab === TABS.DEAL_COLLECT
        ? [
            {
              key: FILTER.DEAL_ID,
              label: { key: 'deal' },
              onChange: value =>
                dispatch({
                  filter: FILTER.DEAL_ID,
                  type: FILTER_ACTION.SET,
                  value
                }),
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => {
                const dealListItem = dealList.find(loc => loc.id === value);

                return dealListItem
                  ? parseMultilanguageString(dealListItem.name)
                  : translate('text.no.translation');
              },
              options: dealList.map(dl => ({
                label: {
                  key: 'text.no.translation',
                  context: { value: parseMultilanguageString(dl.name) }
                },
                value: dl.id
              })),
              onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.DEAL_ID })
            }
          ]
        : []),
      ...(activeTab === TABS.DEAL_COLLECT
        ? [
            {
              key: FILTER.DEAL_COLLECT_STATE,
              label: { key: 'deal.dealCollectState' },
              onChange: value => {
                dispatch({
                  filter: FILTER.DEAL_COLLECT_STATE,
                  type: FILTER_ACTION.SET,
                  value
                });
              },
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => translate(DEAL_COLLECT_STATE[value]),
              options: getOptionsFromEnum(DEAL_COLLECT_STATE),
              onDelete: () =>
                dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.DEAL_COLLECT_STATE })
            },
            {
              key: FILTER.DEAL_TYPE,
              label: { key: 'deal.dealType' },
              onChange: value =>
                dispatch({
                  filter: FILTER.DEAL_TYPE,
                  type: FILTER_ACTION.SET,
                  value
                }),
              filterType: FILTER_TYPE.SELECT,
              getChipValue: value => translate(DEAL_TYPE_TRANSLATIONS[value]),
              options: getOptionsFromEnum(DEAL_TYPE_TRANSLATIONS),
              onDelete: () => dispatch({ type: FILTER_ACTION.REMOVE, filter: FILTER.DEAL_TYPE })
            }
          ]
        : [])
    ],
    [
      to,
      from,
      businessProfilesList,
      locations,
      offeringList,
      activeTab,
      dealList,
      formatDateTime,
      translate,
      parseMultilanguageString
    ]
  );

  const renderAction = React.useCallback(() => {
    if (activeTab) {
      return (
        <Button
          variant="contained"
          label={{ key: 'general.export.excel' }}
          onClick={handleExport}
          disabled={preparingDownload}
        />
      );
    }
    return null;
  }, [activeTab, handleExport, preparingDownload]);

  const navigationRoutes = {
    [activityRoutes.getMatch()]: {
      currentPathLabel: { key: 'sidebar.dashboard.activity' },
      action: renderAction,
      hideBackButton: true,
      hideBreadCrumbs: true,
      tabBar: {
        value: activeTab,
        onChange: selectedTab => setActiveTab(selectedTab),
        tabs: [
          ...(contractCompany && hasLoyaltyPermission
            ? [
                {
                  tab: TABS.CODE_COLLECT,
                  label: { key: 'tab.codeCollectsActivity' }
                },
                {
                  tab: TABS.CARD_COLLECT,
                  label: { key: 'tab.cardCollectsActivity' }
                }
              ]
            : []),
          ...(contractCompany && hasDealPermission
            ? [
                {
                  tab: TABS.DEAL_COLLECT,
                  label: { key: 'tab.dealCollectsActivity' }
                }
              ]
            : [])
        ]
      }
    }
  } as const;

  return (
    <ContractCompanyGuard>
      {/* @ts-expect-error - TS2322 - Type '{ children: Element; navigationRoutes: { readonly [x: number]: { readonly currentPathLabel: { readonly key: "sidebar.dashboard.activity"; }; readonly action: () => Element | null; readonly hideBackButton: true; readonly hideBreadCrumbs: true; readonly tabBar: { ...; }; }; }; }' is not assignable to type '{ children: ReactNode; navigationRoutes: { [key: string]: NavigationRoute; }; }'. */}
      <NavigationHeaderProvider {...{ navigationRoutes }}>
        <FilterContainer
          filters={filters}
          filterState={filterState}
          // @ts-expect-error - TS2322 - Type 'Dispatch<ReducerAction>' is not assignable to type 'Dispatch<ReducerAction, FilterState>'.
          dispatch={dispatch}
        >
          <div className={classes.tableContainer}>
            {activeTab === TABS.CODE_COLLECT && (
              <CodeCollectsActivityTable
                handlePageChange={handlePageChange}
                isLoading={isLoading}
                {...codeCollectsActivity}
              />
            )}

            {activeTab === TABS.CARD_COLLECT && (
              <CardCollectsActivityTable
                handlePageChange={handlePageChange}
                isLoading={isLoading}
                {...cardCollectsActivity}
              />
            )}

            {activeTab === TABS.DEAL_COLLECT && (
              <DealCollectsActivityTable
                handlePageChange={handlePageChange}
                isLoading={isLoading}
                {...dealCollectsActivity}
              />
            )}
          </div>
        </FilterContainer>
      </NavigationHeaderProvider>
    </ContractCompanyGuard>
  );
}

export default CollectsActivity;
