import { PoinzApiClient } from '../../api';
import { ROOT } from './config';
import { TransactionParams } from './types';

const VERSION = 'v1';
const TRANSACTIONS = 'transactions';

const apiRoot = `/${ROOT}/${VERSION}/${TRANSACTIONS}`;

export const getTransactionId =
  (apiClient: PoinzApiClient) =>
  async (body: TransactionParams): Promise<string> => {
    const result = await apiClient.post(apiRoot, {
      body
    });

    return result.body.transactionId;
  };

export default (apiClient: PoinzApiClient) => ({
  get: getTransactionId(apiClient)
});
