import React from 'react';
import { withLocale, LocaleProps } from 'App/providers/LocaleProvider';
import { useAdminUser } from 'App/providers/AdminUserProvider';
import { CardCollectActivity } from 'serviceNew/model/collectActivity';
import { DataTable } from 'App/components';
import { ALL_CODE_TYPE_TRANSLATIONS } from 'serviceNew/model/code';
import { formatDateTime } from 'serviceNew/locale/dates';
import { parseName } from 'util/StringHelper';

type Props = {
  dataList: Array<CardCollectActivity>,
  totalElements: number,
  totalPages: number,
  pageNumber: number,
  handlePageChange: (page: number) => void,
  isLoading: boolean
} & LocaleProps;

const CardCollectsActivity = ({
  dataList,
  totalPages,
  pageNumber,
  translate,
  handlePageChange,
  isLoading,
// @ts-expect-error - TS2339 - Property 'language' does not exist on type 'Props'.
  language,
}: Props) => {
  const { isPoinzAdmin } = useAdminUser();

  const columns = [
    {
      headerLabel: { key: 'general.created' },
      fieldName: 'created',
      width: 210,
      fieldText: tr => formatDateTime(tr.created)
    },
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.id' },
            fieldName: 'id',
            width: 130
          }
        ]
      : []),
    ...(isPoinzAdmin
      ? [
          {
            headerLabel: { key: 'general.user' },
            fieldName: 'userId',
            width: 130
          }
        ]
      : []),
    {
      headerLabel: { key: 'businessProfile.businessProfile' },
      fieldName: 'businessProfileName',
      width: 170
    },
    {
      headerLabel: { key: 'location.location' },
      fieldName: 'locationName',
      width: 200
    },
    {
      headerLabel: { key: 'offering.offering' },
      fieldName: 'offering',
      width: 170,
      fieldText: tr => parseName(tr.offeringName, language)
    },
    {
      headerLabel: { key: 'codeGroup.codeType' },
      fieldName: 'codeType',
      width: 180,
      fieldText: tr => translate(ALL_CODE_TYPE_TRANSLATIONS[tr.codeType])
    },
    {
      headerLabel: { key: 'general.certificate' },
      fieldName: 'certificate',
      width: 130
    }
  ];

  return (
    <DataTable
      isLoading={isLoading}
      totalPages={totalPages}
      currentPage={(pageNumber || 0) + 1}
      handlePageChange={handlePageChange}
      columns={columns}
      data={dataList}
      keyExtractor={item => item.id}
    />
  );
};

export default withLocale(CardCollectsActivity);
