import * as React from 'react';
import { Grid } from '@mui/material';

type Justify = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type Align = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';

type RowProps = {
  children: React.ReactNode,
  direction?: Direction,
  justifyContent?: Justify,
  alignItems?: Align
};

class Row extends React.PureComponent<RowProps> {
  static defaultProps = {
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    className: ''
  };

  render() {
    const { children, ...props } = this.props;
    return (
      <Grid container {...props}>
        {children}
      </Grid>
    );
  }
}

export default Row;
