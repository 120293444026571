export const firebaseConfig = Object.freeze({
  apiKey: 'AIzaSyCO1RtGUctFOWQZzmMaa5weerdMtpjNxSI',
  authDomain: 'poinz-partner.firebaseapp.com',
  databaseURL: 'https://poinz-partner.firebaseio.com',
  projectId: 'poinz-partner',
  storageBucket: 'poinz-partner.appspot.com',
  messagingSenderId: '683925683651',
  appId: '1:683925683651:web:802f77426f63b3b17c9176',
  measurementId: 'G-BC9LN8F972'
});
