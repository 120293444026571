import * as React from 'react';

import _ from 'lodash';
import { Formik, ArrayHelpers } from 'formik';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import { AddButton, IconButton, Icon, Button, MultiLanguageForm } from 'App/components';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'DefaultTheme'.
    borderTop: theme.border,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(2)
  },
  container: {
    display: 'flex',
    backgroundColor: 'white',
    // @ts-expect-error - TS2339 - Property 'border' does not exist on type 'DefaultTheme'.
    borderBottom: theme.border,
    '&:hover $actions': {
      display: 'flex'
    },
    '&:hover $rightInfo': {
      display: 'none'
    },
    '&:hover $dragHandleIcon': {
      display: 'flex'
    },
    minHeight: 56
  },
  innerContainer: {
    display: 'flex',
    flex: 1
  },
  summaryContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  dragHandleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    width: theme.spacing(2),
    overflow: 'hidden'
  },
  rightInfo: {
    display: 'flex'
  },
  actions: {
    display: 'none'
  },
  dragHandleIcon: {
    alignItems: 'center',
    display: 'none',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[500]
  },
  addButton: {
    display: 'flex'
  }
}));

type Props<T> = {
  createItem: () => T;
  name: string;
  arrayHelpers: ArrayHelpers;
  FormComponent: React.ComponentType<{
    values: T;
  }>;
  SummaryComponent: React.ComponentType<{
    values: T;
  }>;
  RightInfoComponent: React.ComponentType<{
    values: T;
  }>;
  maxItems: number | null | undefined;
  addLabel: TranslationObject;
  isDeletable: boolean;
  isMultiLanguage: boolean;
};

function ItemList<
  T extends {
    id: number;
  }
>(props: Props<T>) {
  const {
    name,
    arrayHelpers,
    FormComponent,
    SummaryComponent,
    createItem,
    RightInfoComponent,
    maxItems,
    addLabel,
    isDeletable,
    isMultiLanguage
  } = props;
  // @ts-expect-error - TS2339 - Property 'form' does not exist on type 'ArrayHelpers'.
  const { form, move, push, remove } = arrayHelpers;
  const { setFieldValue } = form;
  const items: T[] = _.get(form.values, name);
  const touched = _.get(form.touched, name);

  const [isEditing, setIsEditing] = React.useState<number | 'NEW' | null | undefined>(null);
  const classes = useStyles();

  function renderForm({ handleSubmit, values }) {
    const Wrapper = isMultiLanguage ? MultiLanguageForm : DialogContent;
    return (
      <>
        <Wrapper>
          <FormComponent {...{ values }} />
        </Wrapper>
        <DialogActions>
          <Button label={{ key: 'general.cancel' }} onClick={() => setIsEditing(null)} />
          <Button color="primary" label={{ key: 'general.submit' }} onClick={handleSubmit} />
        </DialogActions>
      </>
    );
  }

  function renderContent(item: T, index: number, dragHandleProps: any) {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.dragHandleContainer} {...dragHandleProps}>
          <div className={classes.dragHandleIcon}>
            <Icon size={20} color="inherit" icon="drag_indicator" />
          </div>
        </div>
        <div className={classes.summaryContainer}>
          <SummaryComponent values={item} />
        </div>
        <div className={classes.rightInfo}>
          <RightInfoComponent values={item} />
        </div>
        <div className={classes.actions}>
          <IconButton icon="edit" onClick={() => setIsEditing(index)} />
          {isDeletable && <IconButton icon="delete" onClick={() => remove(index)} />}
        </div>
      </div>
    );
  }

  function renderField(item: T, index: number) {
    const { id } = item;
    return (
      <Draggable draggableId={id} key={id} {...{ index }}>
        {({ draggableProps, innerRef, dragHandleProps }) => (
          <div className={classes.container} ref={innerRef} {...draggableProps}>
            {renderContent(item, index, dragHandleProps)}
          </div>
        )}
      </Draggable>
    );
  }

  function renderDialog() {
    return (
      <Dialog fullWidth maxWidth="md" open={isEditing != null} onClose={() => setIsEditing(null)}>
        <Formik
          // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type. | TS2538 - Type 'undefined' cannot be used as an index type.
          initialValues={isEditing === 'NEW' ? createItem() : items && items[isEditing]}
          onSubmit={values => {
            if (isEditing === 'NEW') {
              push(values);
            } else {
              setFieldValue(`${name}[${isEditing}]`, values);
            }
            setIsEditing(null);
          }}
          render={renderForm}
        />
      </Dialog>
    );
  }

  function setFieldTouched() {
    if (!touched) {
      form.setFieldTouched(name, [], true);
    }
  }

  function handleAdd() {
    setIsEditing('NEW');
  }

  function handleMove(result: DropResult) {
    const { source, destination } = result;
    if (destination != null) {
      move(source.index, destination.index);
      setFieldTouched();
    }
  }

  return (
    <DragDropContext onDragEnd={handleMove}>
      {items && items.length > 0 && (
        <Droppable droppableId={name}>
          {provided => (
            <div
              className={classes.outerContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map(renderField)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      {(maxItems == null || !items || items.length < maxItems) && (
        <div className={classes.addButton}>
          {/* @ts-expect-error - TS2322 - Type '() => void' is not assignable to type '() => Promise<undefined> | undefined'. */}
          <AddButton label={addLabel} onClick={handleAdd} />
        </div>
      )}
      {renderDialog()}
    </DragDropContext>
  );
}

export default ItemList;
