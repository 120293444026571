import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import { ROOT } from './config';
import { Deal } from './types';

const VERSION = '/v2';
const PUBLIC = '/public';
const WEB = '/web';
const DEALS = '/deals';
const EXPLORE = '/explore';
const apiRoot = `${ROOT}${VERSION}${WEB}${DEALS}`;
const publicRoot = `${ROOT}${VERSION}${PUBLIC}${DEALS}`;

export interface GetListParams {
  categoryList?: Array<string>;
  longCenter?: number;
  latCenter?: number;
  searchLat?: number;
  cityCategoryId?: number;
  couponOnly?: boolean;
  onlineOnly?: boolean;
  usedLocateMeFilter?: boolean;
  query?: string;
  page?: number;
}

const initialParams: GetListParams = {
  page: 0,
  couponOnly: false,
  onlineOnly: false,
  usedLocateMeFilter: false,
  categoryList: []
};

const getList =
  (apiClient: PoinzApiClient) =>
  async (params?: GetListParams): Promise<Deal[]> => {
    const result = await apiClient.get(`${apiRoot}${EXPLORE}`, {
      queryParams: {
        ...initialParams,
        ...(params ? params : {})
      }
    });
    return result.body;
  };

const getPublicList =
  (apiClient: PoinzApiClient) =>
  async (params: GetListParams): Promise<NewPaginationModel<Deal[]>> => {
    const result = await apiClient.get(`${publicRoot}${EXPLORE}`, {
      queryParams: {
        ...initialParams,
        ...params
      }
    });

    if (result.headers) {
      delete result.headers;
    }
    return result;
  };

const getPublicDeal =
  (apiClient: PoinzApiClient) =>
  async (dealId: string): Promise<NewPaginationModel<Deal[]>> => {
    const result = await apiClient.get(`${publicRoot}/:dealId`, {
      pathParams: {
        dealId
      }
    });
    return result.body;
  };

const getPublicDealBySlug =
  (apiClient: PoinzApiClient) =>
  async (slug: string): Promise<NewPaginationModel<Deal[]>> => {
    const result = await apiClient.get(`${publicRoot}/slug/:slug`, {
      pathParams: {
        slug
      }
    });
    return result.body;
  };

const getDealPreview =
  (apiClient: PoinzApiClient) =>
  async (previewId: string): Promise<NewPaginationModel<Deal[]>> => {
    const result = await apiClient.get(`${publicRoot}/preview/:previewId`, {
      pathParams: {
        previewId
      }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  getList: getList(apiClient),
  getPublicList: getPublicList(apiClient),
  getPublicDeal: getPublicDeal(apiClient),
  getPublicDealBySlug: getPublicDealBySlug(apiClient),
  getDealPreview: getDealPreview(apiClient)
});
