export enum PAYMENT_METHOD_BRAND {
  VISA = 'VISA',
  MC = 'MC',
  AMEX = 'AMEX',
  MAESTRO = 'MAESTRO',
  VPAY = 'VPAY',
  TWINT = 'TWINT',
  DINER = 'DINER'
}
export interface IPaymentMethod {
  id: number;
  maskedPan: string;
  brand: string;
  subBrand: PAYMENT_METHOD_BRAND;
  channel: string;
  created: Date;
  modified: Date;
  userId: number;
  canBeDeleted: boolean;
  description: string;
}
