import * as React from 'react';
import { FieldArray } from 'formik';

import { OrderedPictureUpdate } from 'serviceNew/model/common';
import { TranslationObject } from 'serviceNew/locale';
import { Field } from 'App/components';

import CroppableImageList from './CroppableImageList';

type Props = {
  name: string;
  validate?: (values: Array<OrderedPictureUpdate>) => TranslationObject | null | undefined;
};

function CroppableImageListField(props: Props) {
  const { name, validate } = props;

  function renderForm() {
    // @ts-expect-error - TS2322 - Type '(props: FieldArrayRenderProps) => Element' is not assignable to type 'string | ComponentType<void | FieldArrayRenderProps> | undefined'.
    return <FieldArray name={name} component={CroppableImageList} />;
  }

  return (
    <>
      {/* @ts-expect-error - TS2322 - Type '((values: OrderedPictureUpdate[]) => TranslationObject | null | undefined) | undefined' is not assignable to type '(((value: any) => string | Promise<void> | undefined) & ((values: any) => void | object | Promise<FormikErrors<any>>)) | undefined'. */}
      <Field name={name} validate={validate} initialValue={[]} render={() => null} />
      {renderForm()}
    </>
  );
}

export default CroppableImageListField;
