import {withLocale, LocaleProps} from 'App/providers/LocaleProvider';

import { TranslationObject } from 'serviceNew/locale';

type Props = {
  text: TranslationObject
} & LocaleProps;

function TranslatedText(props: Props): string {
  const { text, translate } = props;
  return translate(text.key, text.context);
}

export default withLocale(TranslatedText);
