import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import Tooltip from 'App/components/Tooltip';
import { TranslationObject } from 'serviceNew/locale';

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-flex',
    flex: 1,
    alignItems: 'center',
  },
}));

type Props = {
  children: React.ReactNode,
  label: TranslationObject | null | undefined,
  className?: string | null | undefined
};

function InfoText(props: Props) {
  const { children, label, className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      {children}
      {label && <Tooltip label={label} />}
    </div>
  );
}

export default InfoText;
