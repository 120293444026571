import * as React from 'react';
import { useParams } from 'react-router';
import { IdType } from '@poinz/api';
import {
  APP_LINK_MEDIUM,
  APP_LINK_SOURCE,
  AppLinkEntity,
  AppLinkFormType
} from 'serviceNew/model/appLink';
import { useDialog } from 'App/providers/DialogProvider';
import { makeStyles } from '@mui/styles';
import { Table } from 'App/components/Tables';
import { View, Text, IconButton, AppLinkForm, Button, TabView } from 'App/components';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from 'App/components/Modal';
import appLinkApi from 'serviceNew/api/appLink';
import { FileCopy } from '@mui/icons-material';
import { useSnackbar } from 'App/components/Snackbar';

import { Language, Languages, defaultLanguage } from 'serviceNew/locale';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    borderBottomColor: theme.palette.grey[400],
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(2),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(2)
  },
  table: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(2)
  }
}));

type Props = {
  appLinkEntity: AppLinkEntity;
  getId: (arg1: any) => IdType;
  setIsAddingProp?: (arg1: boolean) => void;
  isAddingProp?: boolean;
};

function AppLinksList(props: Props) {
  const { appLinkEntity, getId, isAddingProp, setIsAddingProp } = props;
  const classes = useStyles();
  const routeParams = useParams();
  const { showConfirmationDialog } = useDialog();
  const addNotification = useSnackbar();

  const id = React.useMemo(() => getId(routeParams), [getId, routeParams]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isAddingState, setIsAddingState] = React.useState<boolean>(false);
  const [appLinkList, setAppLinkList] = React.useState<Array<any>>([]);
  const [language, setLanguage] = React.useState<Language>(defaultLanguage);

  const isControlled = React.useMemo(
    () => typeof setIsAddingProp === 'function',
    [setIsAddingProp]
  );

  function setIsAdding(value: boolean) {
    if (typeof setIsAddingProp === 'function') {
      setIsAddingProp(value);
    } else {
      setIsAddingState(value);
    }
  }

  const isAdding = React.useMemo(
    () => isAddingProp ?? isAddingState,
    [isAddingProp, isAddingState]
  );

  const loadAppLinks = React.useCallback(async () => {
    if (appLinkEntity) {
      try {
        setIsLoading(true);
        const retrievedAppLinks = await appLinkApi[appLinkEntity].getAppLinks(id);
        setAppLinkList(retrievedAppLinks);
      } catch (e: any) {
        //
      } finally {
        setIsLoading(false);
      }
    }
  }, [appLinkEntity, id]);

  React.useEffect(() => {
    loadAppLinks();
  }, [loadAppLinks]);

  const handleDeleteLink = React.useCallback(
    async data => {
      try {
        await appLinkApi.deleteAppLink(data.id);
        setAppLinkList(currentList => currentList.filter(al => al.id !== data.id));
        await addNotification({
          key: 'entity.remove.success',
          context: { entity: 'general.link' }
        });
      } catch (e: any) {
        //
      }
    },
    [addNotification]
  );

  async function handleSubmit(appLinkForm: AppLinkFormType) {
    try {
      setIsLoading(true);
      await appLinkApi[appLinkEntity].createAppLink(id, appLinkForm);
      setIsAdding(false);
      await addNotification({ key: 'entity.add.success', context: { entity: 'general.link' } });
      await loadAppLinks();
    } catch (e: any) {
      //
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    setIsAdding(false);
  }

  const columns = React.useMemo(
    () => [
      {
        header: { key: 'appLink.campaign.name' },
        dataMapper: 'campaign',
        align: 'left',
        flex: 2
      },
      {
        header: { key: 'appLink.medium' },
        text: ({ medium }) => ({ key: APP_LINK_MEDIUM[medium] }),
        align: 'left',
        flex: 2
      },
      {
        header: { key: 'appLink.source' },
        text: ({ source }) => ({ key: APP_LINK_SOURCE[source] }),
        align: 'left',
        flex: 2
      },
      {
        header: { key: 'appLink.link' },
        text: ({ link }) => ({
          key: 'text.no.translation',
          context: { value: link[language] || '-' }
        }),
        flex: 6,
        align: 'left'
      },
      {
        header: { key: 'appLink.link' },
        dataMapper: ({ link }) => (
          <CopyToClipboard text={link[language]}>
            {/* @ts-expect-error - TS2322 - Type '{ children: Element; icon: "file_copy"; onClick: () => Promise<void>; }' is not assignable to type 'IntrinsicAttributes & { onClick: (arg1: SyntheticEvent<Element, Event>) => unknown; tooltip?: TranslationObject | undefined; className?: string | undefined; disabled?: boolean | undefined; } & IconProps'. | TS2786 - 'IconButton' cannot be used as a JSX component. */}
            <IconButton
              icon="file_copy"
              onClick={async () => {
                await addNotification({ key: 'general.link.successfully.copied' });
              }}
            >
              <FileCopy />
            </IconButton>
          </CopyToClipboard>
        ),
        align: 'center'
      }
    ],
    [addNotification, language]
  );

  const actions = [
    {
      icon: 'delete',
      action: showConfirmationDialog(handleDeleteLink, {
        title: { key: 'general.delete.modal.title' },
        body: { key: 'general.delete.modal.body', context: { label: 'voucher' } }
      })
    }
  ];

  function renderTable() {
    return (
      // @ts-expect-error - TS2322 - Type '({ header: { key: string; }; dataMapper: string; align: string; flex: number; text?: undefined; } | { header: { key: string; }; text: ({ medium }: { medium: any; }) => { key: any; }; align: string; flex: number; dataMapper?: undefined; } | { ...; } | { ...; } | { ...; })[]' is not assignable to type 'TableColumn[]'. | TS2322 - Type '{ icon: string; action: (data: any) => Promise<void>; }[]' is not assignable to type 'TableAction[]'.
      <Table className={classes.table} columns={columns} data={appLinkList} actions={actions} />
    );
  }

  function renderContent() {
    if (isLoading) {
      return null;
    }
    if (appLinkList?.length > 0) {
      return (
        <>
          <TabView
            value={language}
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<"en" | "de" | "fr" | "it">'.
            onChange={lang => setLanguage(lang)}
            tabs={Object.keys(Languages).map(key => ({ tab: key, label: { key: Languages[key] } }))}
          />
          {renderTable()}
        </>
      );
    }
    return <Text>appLink.no.existing.links</Text>;
  }

  return (
    <View>
      {!isControlled && (
        <div className={classes.header}>
          <Text variant="h6">appLink.links</Text>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            label={{ key: 'general.add' }}
            onClick={() => {
              setIsAdding(true);
            }}
          />
        </div>
      )}

      <Modal open={isAdding}>
        <AppLinkForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </Modal>

      {renderContent()}
    </View>
  );
}

export default AppLinksList;
