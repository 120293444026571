import { BusinessProfile, IdType } from '@poinz/api';

import { callEndpoint as callInternalEndpoint } from 'serviceNew/api/internalApi/internalApi';
import { callEndpoint } from './apiClient';

const SEARCH = 'search';
const ROOT = 'business-profiles';

async function searchBusinessProfileByName(query: string): Promise<
  Array<{
    id: IdType;
    name: string;
  }>
> {
  const result = await callEndpoint(`/${ROOT}/${SEARCH}`, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { name: query }
  });
  return result.body;
}

async function searchBusinessProfile(
  name: string,
  includeDeleted: boolean
): Promise<
  Array<{
    id: IdType;
    name: string;
  }>
> {
  const result = await callInternalEndpoint(`/${SEARCH}/${ROOT}`, 'GET', {
    headers: new Headers(),
    authenticated: true,
    queryParams: { name, includeDeleted }
  });
  return result.body;
}

async function searchBusinessProfileById(
  id: number
): Promise<{ id: number; businessProfileName: string }> {
  const result = await callEndpoint(`/public/${ROOT}/:id`, 'GET', {
    headers: new Headers(),
    authenticated: true,
    pathParams: { id }
  });
  return result.body;
}

export default {
  searchBusinessProfileByName,
  searchBusinessProfile,
  searchBusinessProfileById
};
