/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Divider
} from '@mui/material';
import { Menu as MenuIcon, Search, MoreVert } from '@mui/icons-material';
import RenderIf from 'App/components/RenderIf';
import { useLocale } from 'App/providers/LocaleProvider';
import { UserRoles } from 'serviceNew/model/adminUser';
import { Languages } from 'serviceNew/locale';
import { useIsLoggedIn } from 'serviceNew/auth';
import { signOut } from 'serviceNew/firebase/auth';
import { TranslatedText, Switch } from 'App/components';
import routes, { ADMINISTRATION } from 'App/routing';
import { usePartnerView } from './PartnerViewSubject';
import { useContractCompany } from 'App/providers/ContractCompanyProvider';
import UserMenu from './components/UserMenu';
import { useAdminUser } from '../../providers/AdminUserProvider';
import ContractCompanyPopover from './ContractCompanyPopover';
import { useStyles } from './Header.styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Language } from '@poinz/locale/dist';

type Props = {
  onClick: () => void;
  open: boolean;
};

const Header = ({ onClick, open }: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const menuOpen = React.useRef(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [partnerViewEnabled, setPartnerViewEnabled] = usePartnerView();
  const { adminUser } = useAdminUser();
  const { locale, changeLocale, translate } = useLocale();
  const { contractCompany, contractCompanyList } = useContractCompany();

  const handleClick = React.useCallback((event: any) => {
    menuOpen.current = event.currentTarget;
    setShowMenu(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setShowMenu(false);
    menuOpen.current = null;
  }, []);

  const isTest = process.env.REACT_APP_ENVIRONMENT !== 'prod';

  const getHeaderTitle = () =>
    `poinz studio${
      isTest && process.env.REACT_APP_ENVIRONMENT ? ` - ${process.env.REACT_APP_ENVIRONMENT}` : ''
    }`;

  const userLoggedIn = useIsLoggedIn();

  const handleLogout = React.useCallback(() => {
    signOut();
    handleClose();
  }, [handleClose]);

  const contractCompanyText = contractCompany && (
    <Typography color="inherit" variant="subtitle1" noWrap>
      {contractCompany.name}
    </Typography>
  );

  const contractCompanyComponent = contractCompany && (
    <>
      <RenderIf
        elseComponent={() => !!contractCompanyList?.length && <ContractCompanyPopover />}
        loggedIn
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'UserRole | UserRole[] | undefined'.
        roles={UserRoles.POINZ_ADMIN}
      >
        <Link to={`${ADMINISTRATION}/contract-company/update`} className={classes.link}>
          <Button className={classes.link}>{contractCompanyText}</Button>
        </Link>
      </RenderIf>
    </>
  );

  React.useEffect(() => {
    moment.locale(locale);
  }, []);

  const selectLanguage = (styleclass?: any) => (
    <Select
      variant="standard"
      label={translate('general.language')}
      value={locale}
      disableUnderline
      className={styleclass}
      classes={{ icon: styleclass }}
      onChange={event => {
        const newLocale: any = event.target.value;
        changeLocale(newLocale);
        moment.locale(newLocale);
      }}
    >
      {Object.keys(Languages).map(l => (
        <MenuItem key={l} value={l}>
          {translate(Languages[l])}
        </MenuItem>
      ))}
    </Select>
  );

  const contractCompanySearch = (
    // @ts-expect-error - TS2786 - 'RenderIf' cannot be used as a JSX component.
    <RenderIf loggedIn roles={UserRoles.POINZ_ADMIN}>
      <Link to={`${ADMINISTRATION}/contract-company/search`} className={classes.link}>
        <IconButton color="inherit" aria-label="Seach contract-company" size="large">
          <Search />
        </IconButton>
      </Link>
    </RenderIf>
  );

  const login = (
    <Link to="/login" className={classes.link}>
      <Button color="inherit">Login</Button>
    </Link>
  );

  const appBarTitle = (
    // <div>
    // @ts-expect-error - TS2769 - No overload matches this call.
    <Typography variant="h6" color="inherit" noWrap style={{ paddingLeft: !userLoggedIn && 24 }}>
      {getHeaderTitle()}
    </Typography>
    // </div>
  );

  const partnerViewToggle = adminUser?.isPoinzAdmin && (
    <Grid spacing={1} container wrap="nowrap" alignItems="center">
      <Grid item>
        <Switch
          onChange={() => setPartnerViewEnabled(prevVal => !prevVal)}
          value={partnerViewEnabled}
        />
      </Grid>
      <Grid item>
        <Typography>
          <TranslatedText text={{ key: 'header.partnerView' }} />
        </Typography>
      </Grid>
    </Grid>
  );

  const mobileBar = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>{appBarTitle}</Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <Grid item>{contractCompanySearch}</Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-owns="app-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="large"
            >
              <MoreVert />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={menuOpen.current}
              open={showMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <RenderIf loggedIn>
                {/* @ts-expect-error - TS2322 - Type 'Element | null | undefined' is not assignable to type 'Node | undefined'. */}
                {contractCompany && (
                  <MenuItem divider disabled>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {contractCompany.name}
                    </div>
                  </MenuItem>
                )}
              </RenderIf>
              <MenuItem>{selectLanguage()}</MenuItem>
              <Divider />
              <MenuItem>{partnerViewToggle}</MenuItem>
              {/* @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'Node | undefined', but multiple children were provided. | TS2786 - 'RenderIf' cannot be used as a JSX component. */}
              <RenderIf loggedIn>
                <Divider />
                <MenuItem
                  key="account-settings"
                  onClick={() => {
                    history.push(routes.accountSettings.route);
                    handleClose();
                  }}
                >
                  <TranslatedText text={{ key: 'userMenu.accountSettings' }} />
                </MenuItem>
              </RenderIf>
              <RenderIf loggedIn>
                <MenuItem key="logout" onClick={handleLogout}>
                  <TranslatedText text={{ key: 'general.logout' }} />
                </MenuItem>
              </RenderIf>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      className={clsx(classes.appBar, userLoggedIn && open && classes.appBarShift, {
        [classes.mobileIndex]: smDown
      })}
    >
      <Toolbar disableGutters={!open}>
        <RenderIf loggedIn>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onClick}
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </RenderIf>
        {!smDown ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item>{appBarTitle}</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RenderIf loggedIn>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
                  <Grid item>{contractCompanyComponent}</Grid>
                  <Grid item>{contractCompanySearch}</Grid>
                </Grid>
              </RenderIf>
            </Grid>
            <Grid item>
              <Grid container spacing={1} wrap="nowrap" justifyContent="center" alignItems="center">
                <Grid item>{selectLanguage(classes.language)}</Grid>
                <Grid item>
                  <RenderIf loggedIn={false}>{login}</RenderIf>
                  <RenderIf loggedIn>
                    <UserMenu>{partnerViewToggle}</UserMenu>
                  </RenderIf>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          mobileBar
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
