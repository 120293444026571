import { PoinzApiClient } from '../../api';
import { ApiKey, CreateApiKeyBody } from './types';
import { NewPaginationModel, PagableParams } from '../../types';
import { ROOT } from './config';

const apiRoot = `${ROOT}/keys`;

const create =
  (apiClient: PoinzApiClient) =>
  async (body: CreateApiKeyBody): Promise<void> => {
    const response = await apiClient.post(apiRoot, {
      body
    });

    return response.body;
  };

const getPaginatedList =
  (apiClient: PoinzApiClient) =>
  async (params?: PagableParams): Promise<NewPaginationModel<ApiKey>> => {
    const { page = 0, ...restParams } = params || {};
    const response = await apiClient.get(apiRoot, {
      queryParams: {
        page: page,
        ...restParams
      }
    });
    return response;
  };

const deleteKey =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<ApiKey> => {
    const response = await apiClient.delete(`${apiRoot}/:id`, { pathParams: { id } });
    return response.body;
  };

export default (apiClient: PoinzApiClient) => ({
  create: create(apiClient),
  getPaginatedList: getPaginatedList(apiClient),
  delete: deleteKey(apiClient)
});
