import { TranslationKey, TranslationObject } from 'serviceNew/locale';

const getUpdateMessage = (entity: TranslationKey) => ({
  success: { key: 'entity.update.success', context: { entity } },
  error: { key: 'entity.update.failure', context: { entity } }
});

const getDeleteMessage = (entity: TranslationKey) => ({
  success: { key: 'entity.remove.success', context: { entity } },
  error: { key: 'entity.remove.failure', context: { entity } }
});

const getCreateMessage = (entity: TranslationKey) => ({
  success: { key: 'entity.add.success', context: { entity } },
  error: { key: 'entity.add.failure', context: { entity } }
});

const getCustomMessage = (success: TranslationObject, error?: TranslationObject) => ({
  success,
  error: error || { key: 'errors.error' }
});

export const getMessage = {
  create: getCreateMessage,
  update: getUpdateMessage,
  delete: getDeleteMessage,
  custom: getCustomMessage
} as const;
