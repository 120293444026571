import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import moment from 'moment';
import { PrivateRoute, SelectionList, RoundImageWithLabels } from 'App/components';
import NavigationHeader from 'App/components/NavigationHeader';
import routes, { DASHBOARD_ACTIVITY } from 'App/routing';
import { useBusinessProfile } from 'App/providers/BusinessProfileProvider';
import { useLocale } from 'App/providers/LocaleProvider';
import useNavigation from 'serviceNew/navigation/useNavigation';
import { DEAL_TYPE } from 'serviceNew/model/deal';
import { useQueryState } from '@poinz/hooks';
import DealStatsOverview from './routes/DealStatsOverview';
import DealStatsDetails from './routes/DealStatsDetails';

type TabOption = typeof DEAL_TYPE.DEAL | typeof DEAL_TYPE.COUPON;

const DealStatsRoute = () => {
  const dealStatsRoute = routes.dashboard.deals;
  const { parseMultilanguageString } = useLocale();
  const { businessProfileId, businessProfiles, selectBusinessProfile } = useBusinessProfile();
  const { push } = useNavigation();
  const [dealType, setSelectedTab] = useQueryState<TabOption>('dealType', DEAL_TYPE.DEAL);
  const selectedTab = dealType || DEAL_TYPE.DEAL;

  function handleDealRuleChange(id: any) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDealRuleId = e?.target?.value;
      const pathname = selectedDealRuleId
        ? routes.dashboard.deals[':id'].dealRules[':dealRuleId'].getRoute({
            id,
            dealRuleId: selectedDealRuleId
          })
        : routes.dashboard.deals[':id'].getRoute({ id });
      push({ path: pathname });
    };
  }

  function renderDealDetailHeader({ state }) {
    const { dealStatOverview } = state;
    if (dealStatOverview) {
      return (
        <RoundImageWithLabels
          logo={dealStatOverview.picture?.sourceUrl}
          size={48}
          // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
          title={parseMultilanguageString(dealStatOverview?.name)}
          titleVariant="h6"
        />
      );
    }
    return null;
  }

  function renderDealDetailAction({ state, params }) {
    const { dealStatOverview } = state;
    const { dealRuleId, id } = params;
    if (dealStatOverview) {
      return (
        <SelectionList
          id={dealRuleId}
          array={dealStatOverview.dealRuleStats || []}
          onChange={handleDealRuleChange(id)}
          renderName={e =>
            `${moment(e.startDate).format('ll')} - ${moment(e.endDate).format('ll')}`
          }
          idProperty="dealRuleId"
          emptyLabel={{ key: 'stats.deals.allDealRules' }}
        />
      );
    }
    return null;
  }

  const navigationRoutes = {
    [routes.dashboard.getMatch()]: {
      disabled: true,
      breadcrumbLabel: { key: 'sidebar.dashboard' }
    },
    [dealStatsRoute[':id'].getMatch()]: {
      breadcrumbLabel: ({ state }) => {
        const { dealStatOverview } = state;
        return dealStatOverview
          ? {
              key: 'text.no.translation',
              context: { value: parseMultilanguageString(dealStatOverview.name) }
            }
          : null;
      },
      renderCurrentPath: renderDealDetailHeader,
      action: renderDealDetailAction
    },
    [dealStatsRoute[':id'].dealRules[':dealRuleId'].getMatch()]: {
      renderCurrentPath: renderDealDetailHeader,
      action: renderDealDetailAction
    },
    [dealStatsRoute[':id'].dealRules.getMatch()]: {
      disabled: true,
      currentPathLabel: { key: 'dealRule.dealRule' }
    },
    [dealStatsRoute.getMatch()]: {
      currentPathLabel: { key: 'sidebar.dashboard.deals' },
      hideBackButton: true,
      tabBar: {
        value: selectedTab,
        onChange: dt => setSelectedTab(dt),
        tabs: [
          { tab: DEAL_TYPE.DEAL, label: { key: 'deal.deals' } },
          { tab: DEAL_TYPE.COUPON, label: { key: 'deal.coupons' } }
        ]
      },
      action: () => (
        <SelectionList
          id={businessProfileId}
          array={businessProfiles}
          onChange={e => {
            const id = e.target.value;
            if (id) {
              selectBusinessProfile(id);
            }
          }}
          withEmpty={false}
          renderName={e => e.name}
        />
      )
    }
  } as const;

  return (
    <>
      {/* @ts-expect-error - TS2322 - Type '{ navigationRoutes: { readonly [x: number]: { readonly disabled: true; readonly breadcrumbLabel: { readonly key: "sidebar.dashboard"; }; readonly renderCurrentPath?: undefined; readonly action?: undefined; readonly currentPathLabel?: undefined; readonly hideBackButton?: undefined; readonly tabBar?: undefined; } | { ...' is not assignable to type 'Props'. */}
      <NavigationHeader {...{ navigationRoutes }} />
      <Switch>
        <PrivateRoute
          // @ts-expect-error - TS2322 - Type '{ deal: true; exact: true; path: any; render: () => Element; redirectTo: string; }' is not assignable to type 'IntrinsicAttributes & { component?: ComponentType<any> | undefined; render?: (() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined; redirectTo?: string | undefined; } & RouteProps<...> & OmitNative<...>'.
          deal
          exact
          path={dealStatsRoute.getMatch()}
          render={() => <DealStatsOverview {...{ selectedTab }} />}
          redirectTo={DASHBOARD_ACTIVITY}
        />
        <PrivateRoute
          // @ts-expect-error - TS2322 - Type '{ deal: true; exact: true; path: any; component: () => Element; redirectTo: string; }' is not assignable to type 'IntrinsicAttributes & { component?: ComponentType<any> | undefined; render?: (() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined; redirectTo?: string | undefined; } & RouteProps<...> & OmitNative<...>'.
          deal
          exact
          path={dealStatsRoute[':id'].getMatch()}
          component={DealStatsDetails}
          redirectTo={DASHBOARD_ACTIVITY}
        />
        <PrivateRoute
          // @ts-expect-error - TS2322 - Type '{ deal: true; exact: true; path: any; component: () => Element; redirectTo: string; }' is not assignable to type 'IntrinsicAttributes & { component?: ComponentType<any> | undefined; render?: (() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined; redirectTo?: string | undefined; } & RouteProps<...> & OmitNative<...>'.
          deal
          exact
          path={dealStatsRoute[':id'].dealRules[':dealRuleId'].getMatch()}
          component={DealStatsDetails}
          redirectTo={DASHBOARD_ACTIVITY}
        />
      </Switch>
    </>
  );
};

export default withRouter(DealStatsRoute);
