import { NewPaginationModel } from 'types';
import { PoinzApiClient } from '../../api';
import { ILabelsParams, ILabel, ILabelBody } from './types/labels';

const apiRoot = `/search/labels`;

const getLabels =
  (apiClient: PoinzApiClient) =>
  async (params?: ILabelsParams): Promise<NewPaginationModel<ILabel[]>> => {
    const result = await apiClient.get(apiRoot, { queryParams: { ...params, sort: 'id,ASC' } });
    return result.body;
  };

const addLabel =
  (apiClient: PoinzApiClient) =>
  async (params: { searchableEntityId: number; labelBody: ILabelBody }): Promise<ILabel> => {
    const { searchableEntityId, labelBody } = params;
    const result = await apiClient.post(apiRoot, {
      body: labelBody,
      queryParams: { searchableEntityId }
    });
    return result.body;
  };

const uploadLabels =
  (apiClient: PoinzApiClient) =>
  async (params: { searchableEntityId: number; data: Blob }): Promise<ILabel> => {
    const { searchableEntityId, data } = params;
    const formData = new FormData();
    formData.append('file', data);
    const result = await apiClient.post(apiRoot, {
      body: formData,
      isMultiPart: true,
      queryParams: { searchableEntityId }
    });
    return result.body;
  };

const exportAllLabels = (apiClient: PoinzApiClient) => async (): Promise<Blob> => {
  const result = await apiClient.get(`${apiRoot}/excel`, {
    headers: new Headers({ Accept: 'application/vnd.ms-excel' }),
    isBinary: true
  });
  return result.blob;
};

const importAllLabels =
  (apiClient: PoinzApiClient) =>
  async (params: { data: Blob }): Promise<void> => {
    const { data } = params;
    const formData = new FormData();
    formData.append('file', data);
    const result = await apiClient.post(`${apiRoot}/excel`, {
      body: formData,
      isMultiPart: true
    });
    return result.body;
  };

const deleteLabel =
  (apiClient: PoinzApiClient) =>
  async (id: number): Promise<void> => {
    await apiClient.delete(`${apiRoot}/${id}`, {});
  };

const deleteAllLabels =
  (apiClient: PoinzApiClient) =>
  async (params: { searchableEntityId: number }): Promise<void> => {
    await apiClient.delete(apiRoot, { queryParams: params });
  };

export default (apiClient: PoinzApiClient) => ({
  get: getLabels(apiClient),
  add: addLabel(apiClient),
  upload: uploadLabels(apiClient),
  delete: deleteLabel(apiClient),
  deleteAll: deleteAllLabels(apiClient),
  exportAll: exportAllLabels(apiClient),
  importAll: importAllLabels(apiClient)
});
