import { BusinessProfile } from 'partnerClient/backend/types';
import { BusinessLocation } from 'partnerClient/backend/types/dealLocation';
import { ICashbackType } from 'partnerClient/shoppingCart/types';
import { ScaledImage } from 'types';

export enum IAffiliateType {
  AWIN = 'AWIN',
  ADTRACTION = 'ADTRACTION',
  TRADEDOUBLER = 'TRADEDOUBLER',
  NONE = 'NONE'
}

export enum IAffiliateCashbackStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export interface IAffiliateCashback {
  cashbackValue: number;
  commissionId: number;
  id: number;
  nameLocalized: string;
  status: IAffiliateCashbackStatus;
  type: ICashbackType;
}

export enum ICampaignEligibility {
  EVERY_TRANSACTION = 'EVERY_TRANSACTION',
  FIRST_TRANSACTION = 'FIRST_TRANSACTION'
}

export interface ICashbackCampaign {
  endDate: Date;
  cashbackValue: number;
  cashbackType: ICashbackType;
  description?: string;
  locations?: BusinessLocation[];
  commissionGroupIds: number[];
  campaingEligibility: ICampaignEligibility;
}

export interface CashbackItem {
  value: number;
  type: ICashbackType;
  label: string;
  campaign?: ICashbackCampaign;
}

export interface IPromoAffiliate {
  id: number;
  promoCode: string;
  discount?: string;
  description?: string;
  startDate: string;
  endDate: string;
}

export interface IAffiliate {
  name: string;
  businessProfileId: number;
  worldlineOffline: boolean;
  worldlineOnline: boolean;
  worldlineCashback: number | null;
  wordlineCashbackName?: string;
  worldlineUrl: string | null;
  apiOnline: boolean;
  affiliateId: number;
  affiliateType: IAffiliateType;
  affiliateUrl: string | null;
  affiliateTrackingUrl: string | null;
  affiliateCashbacks: IAffiliateCashback[] | null;
  specialConditionsLocalized: string | null;
  slug: string;
  businessProfile: BusinessProfile;
  picture?: ScaledImage;
  campaigns: ICashbackCampaign[];
  promos: IPromoAffiliate[];
  commissions: any[];
}

export interface IFormattedAffiliateCashback extends IAffiliateCashback {
  campaign?: {
    value: number;
    type: ICashbackType;
    endDate: Date;
  };
}

export enum IAffiliateTab {
  ONLINE = 'online',
  IN_STORE = 'in-store'
}

export interface IFormattedAffiliate extends IAffiliate {
  affiliateCashbacks: IFormattedAffiliateCashback[];
}
