import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import RawText from './RawText';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    width: ({ size }) => size,
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    height: ({ size }) => size,
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    borderRadius: ({ size }) => size / 2,
// @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    flexShrink: 0
  },
  labels: {
    flexDirection: 'column',
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginLeft: theme.spacing(2),
    textAlign: 'left'
  },
  image: {
    width: 'auto',
// @ts-expect-error - TS2339 - Property 'size' does not exist on type '{}'.
    height: ({ size }) => size
  },
  subtitle: {
// @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(0.5)
  }
}));

type Props = {
  logo?: string,
  size: number,
  title: string,
  titleVariant?: string,
  subtitle?: string,
  subtitleVariant?: string,
  children?: React.ReactNode,
  className?: string
};

function RoundImageWithLabels(props: Props) {
  const {
    children,
    className,
    logo,
    size,
    title,
    titleVariant = 'h5',
    subtitle,
    subtitleVariant = 'body2'
  } = props;

  const classes = useStyles({ size });

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.logo}>
        {logo && <img className={classes.image} src={logo} alt="" />}
      </div>
      <div className={classes.labels}>
{ /* @ts-expect-error - TS2769 - No overload matches this call. */}
        <RawText variant={titleVariant}>{title}</RawText>
        <div className={classes.subtitle}>
{ /* @ts-expect-error - TS2769 - No overload matches this call. */}
          {subtitle && <RawText variant={subtitleVariant}>{subtitle}</RawText>}
          {children || null}
        </div>
      </div>
    </div>
  );
}

export default RoundImageWithLabels;
