import * as React from 'react';
import { withFieldAdapter, FieldRenderProps } from '../Field';
import Switch from '../Switch';

type SwitchFieldProps = Record<any, any> &
  JSX.LibraryManagedAttributes<typeof Switch, React.ComponentProps<typeof Switch>> &
  FieldRenderProps;

type State = {
  checked: boolean;
};

class SwitchField extends React.PureComponent<SwitchFieldProps, State> {
  static defaultProps = {
    color: 'primary'
  };

  render(): React.ReactElement {
    const { input, meta, disabled, ...props } = this.props;

    const { submitting } = meta;

    return <Switch disabled={submitting || disabled} {...input} {...props} />;
  }
}

export default withFieldAdapter(SwitchField);
