import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { TranslationObject } from 'serviceNew/locale';
import { useLocale } from 'App/providers/LocaleProvider';
import FieldLabel from './FieldLabel';

type Props = Partial<TextFieldProps> & {
  label: TranslationObject;
  disabled?: boolean;
  tooltip?: TranslationObject;
  placeholder?: TranslationObject;
  noLabel?: boolean;
  enable1Password?: boolean;
};

function TextInput(props: Props) {
  const { translate } = useLocale();
  const {
    label,
    noLabel,
    tooltip,
    required,
    placeholder,
    className,
    enable1Password = false,
    ...restProps
  } = props;
  return (
    <div {...{ className }}>
      {!noLabel && <FieldLabel {...{ label, required, tooltip }} />}
      <TextField
        type="text"
        fullWidth
        variant="outlined"
        placeholder={
          placeholder
            ? translate(placeholder?.key, placeholder?.context)
            : translate(label?.key, label?.context)
        }
        {...restProps}
        inputProps={{
          ...restProps.inputProps,
          ...(!enable1Password && { 'data-1p-ignore': '' })
        }}
        InputProps={{
          ...(restProps.InputProps || {})
        }}
      />
    </div>
  );
}

export default TextInput;
