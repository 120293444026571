import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import { ROOT } from './config';
import { AnalyticParams, LoyaltyAnalytics } from './types/analytics';

const apiRoot = `${ROOT}/v1/admin/business/:businessProfileId/analytics`;

const get =
  (apiClient: PoinzApiClient) =>
  async (
    businessProfileId: number,
    params: AnalyticParams
  ): Promise<NewPaginationModel<LoyaltyAnalytics>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      pathParams: { businessProfileId },
      queryParams: {
        page,
        ...params
      }
    });
    return result.body;
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient)
});
