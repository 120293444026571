import { ROOT } from './config';
import { PoinzApiClient } from '../../api';
import { NewPaginationModel } from '../../types';
import { Referral, PaginatedReferralParams } from './types';
import { IFlatAddress } from '../user/types/addresses';

const REFERRALS = '/referrals';
const apiRoot = `/${ROOT}${REFERRALS}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (params: PaginatedReferralParams): Promise<NewPaginationModel<Referral>> => {
    const { page = 0, ...restParams } = params;
    const result = await apiClient.get(apiRoot, {
      queryParams: {
        page,
        ...restParams
      }
    });
    return result;
  };

const apply =
  (apiClient: PoinzApiClient) =>
  async (code: string, address?: IFlatAddress): Promise<Referral> => {
    try {
      const response = await apiClient.post(apiRoot, {
        body: { code, address }
      });

      return response.body;
    } catch (e: any) {
      throw JSON.parse(e);
    }
  };

export default (apiClient: PoinzApiClient) => ({
  getList: getList(apiClient),
  apply: apply(apiClient)
});
