import { TranslationObject } from '@poinz/locale';
import { PagableParams } from '../../../types';

export enum ReferralStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED'
}

export enum ReferralType {
  SWISSCARD = 'SWISSCARD'
}

export interface ReferralLinkCampaign {
  name: TranslationObject;
  id: number;
}

export interface ReferralLink {
  code: string;
  campaign: ReferralLinkCampaign;
}

export interface Referral {
  referralLink: ReferralLink;
  created: string;
  status: ReferralStatus;
}

export interface PaginatedReferralParams extends PagableParams {
  userId?: number;
}
