import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { RowDataDisplay } from '.';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1)
  },
  view: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    padding: 0,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingTop: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    paddingBottom: theme.spacing(1),
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none'
  },
  button: {
    cursor: 'pointer'
  },
  border: {
    width: '100%',
    height: 1,
    backgroundColor: 'lightgrey'
  },
  label: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginBottom: theme.spacing(1),
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.grey[600]
  }
}));

type Props = {
  labels?: any[];
  clickable?: boolean;
  onClick?: any;
  data?: any[];
  children?: React.ReactNode;
};

function RowList(props: Props) {
  const { labels, data, clickable = false, onClick = (index: number) => index } = props;

  const classes = useStyles();

  const renderView = (element: any) => (
    <div className={classes.view}>
      <RowDataDisplay data={element} />
    </div>
  );

  const renderButton = (element: any, index: number) => (
    <button
      type="button"
      onClick={() => onClick(index)}
      className={clsx(classes.view, classes.button)}
    >
      <RowDataDisplay data={element} />
    </button>
  );

  return (
    <div className={classes.container}>
      <RowDataDisplay textClassName={classes.label} data={labels} />
      {data &&
        data.map((element, index) => (
          <>
            <div className={classes.border} />
            {clickable ? renderButton(element, index) : renderView(element)}
          </>
        ))}
    </div>
  );
}

export default RowList;
