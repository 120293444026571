import * as React from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';

function useEntityOrLoad<T>(
  propName: string,
  getter: (id: number) => Promise<T>,
  entityId?: number | null,
  entityIdProp?: string | null,
  entityMapper?: ((arg1?: any) => T) | null
): [T | null | undefined, number, Error | null | undefined, boolean] {
  // @ts-expect-error - TS2339 - Property 'id' does not exist on type '{}'.
  const { id: rawId } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const id = entityId || parseInt(rawId, 10);
  const entity = (state || {})[propName];

  const [error, setError] = React.useState<Error | null | undefined>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      async function loadEntity() {
        try {
          setLoading(true);
          setError(null);
          const retrievedEntity = await getter(id);
          history.replace({
            ...history.location,
            // @ts-expect-error - TS2698 - Spread types may only be created from object types.
            state: { ...history.location.state, [propName]: retrievedEntity }
          });
        } catch (e: any) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
      if (id && (!entity || entity[entityIdProp || 'id'] !== id)) {
        loadEntity();
      }
    },
    // eslint-disable-next-line
    [entity, getter, id, propName]
  );

  const values = React.useMemo(
    () => [
      typeof entityMapper === 'function' && entity ? entityMapper(entity) : entity,
      id,
      error,
      loading
    ],
    [entity, entityMapper, error, id, loading]
  );

  // @ts-expect-error - TS2322 - Type 'any[]' is not assignable to type '[T | null | undefined, number, Error | null | undefined, boolean]'.
  return values;
}

export default useEntityOrLoad;
