import * as React from 'react';

import { Paper as MuiPaper } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';

import { TranslationObject } from 'serviceNew/locale';

import { MyTheme } from 'theme/muiTheme';

import Text from './Text';

const styles = createStyles((theme: MyTheme) => ({
  paper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    borderRadius: theme.spacing(1)
  },
  paperPadding: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  }
}));

type Props = {
  className?: string;
  children: React.ReactNode;
  title?: TranslationObject | null | undefined;
  noPadding?: boolean;
} & WithStyles<typeof styles>;

const Paper = React.forwardRef((props: Props, ref) => {
  const { children, classes, className, noPadding, title, ...restProps } = props;
  // @ts-expect-error - TS2339 - Property 'paper' does not exist on type 'ClassNameMap<never>'.
  const paperStyles = [classes.paper];
  if (!noPadding) {
    // @ts-expect-error - TS2339 - Property 'paperPadding' does not exist on type 'ClassNameMap<never>'.
    paperStyles.push(classes.paperPadding);
  }
  paperStyles.push(className);
  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <MuiPaper elevation={0} className={paperStyles.join(' ')} {...{ ref }} {...restProps}>
      {title != null && (
        <Text variant="h6" context={title.context}>
          {title.key}
        </Text>
      )}
      {children}
    </MuiPaper>
  );
});

Paper.defaultProps = {
  className: '',
  noPadding: false
};

export default withStyles(styles)(Paper);
