import * as React from 'react';
import { useLoadScript } from '@react-google-maps/api';

declare const google: any;

export enum GooglePlacesServices {
  AUTOCOMPLETE = 'autocomplete',
  PLACES = 'places'
}

const libraries = ['places'];

const useGoogleMap = (services?: Array<string>, apiKey?: string) => {
  const [autocompleteService, setAutocompleteService] = React.useState<any>(undefined);
  const [placesService, setPlacesService] = React.useState<any>(undefined);
  const { isLoaded, loadError } = useLoadScript({
    libraries,
    region: 'CH',
    preventGoogleFontsLoading: false,
    googleMapsApiKey: apiKey || String(process.env.REACT_APP_GOOGLE_API_KEY)
  } as any);

  React.useEffect(() => {
    if (isLoaded && google && services && !placesService && !autocompleteService) {
      services.forEach(s => {
        switch (s) {
          case 'autocomplete':
            setAutocompleteService(new google.maps.places.AutocompleteService());
            break;
          case 'places':
            setPlacesService(new google.maps.places.PlacesService(document.createElement('div')));
            break;
          default:
            break;
        }
      });
    }
  }, [isLoaded, services, placesService, autocompleteService]);

  return {
    isLoaded,
    loadError,
    autocompleteService,
    placesService
  };
};

export default useGoogleMap;
