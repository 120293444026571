export enum IEntityTypes {
  DEAL = 'DEAL',
  GIFT_CARD = 'GIFT_CARD',
  AFFILIATE = 'AFFILIATE'
}

export interface ILabelsParams {
  page: number;
  size?: number;
  sort?: 'asc' | 'desc';
  searchableEntityId?: number;
  entityId?: number;
  entityType?: IEntityTypes;
}

export interface ILabel {
  id: number;
  name: string;
  searchableEntityId: number;
}

export interface ILabelBody {
  name: string;
  entityId: number;
  entityType: IEntityTypes;
}
