import {STATS_API, OVERVIEW, ACTIVITY_TIMELINE} from 'serviceNew/api/businessProfileStats';
import { callEndpoint } from './apiClient';

const POINZ = 'poinz';
const TOP_REWARDS = 'top-rewards';

export type TopRewardsType = {
  id: number,
  businessProfileName: string,
  name: string,
  numPurchased: number,
  numRedeemed: number,
  numCancelled: number,
  revenue: number
};

export type PoinzActivityTimelineStatsType = {
  dateDay: string,
  numActiveUser: number,
  numDealPurchased: number,
  numMobileDealPurchased: number,
  numWebDealPurchased: number,
  numDealRedeemed: number,
  numInAppCodeCollects: number,
  numNewUser: number,
  numRedemptions: number,
  numScans: number
};

async function getOverview(): Promise<any> {
  const result = await callEndpoint(`/${STATS_API}/${POINZ}/${OVERVIEW}`, 'GET', {});
  return result.body;
}

async function getActivityTimeline(params: any): Promise<Array<PoinzActivityTimelineStatsType>> {
  const result = await callEndpoint(`/${STATS_API}/${POINZ}/${ACTIVITY_TIMELINE}`, 'GET', {
    queryParams: {
      resolution: params.resolution,
      startDate: params.startDate,
      endDate: params.endDate,
    },
  });
  return result.body;
}

async function getTopRewards(params: any): Promise<any> {
  const result = await callEndpoint(`/${STATS_API}/${POINZ}/${TOP_REWARDS}`, 'GET', {
    queryParams: {
      pageNumber: params.pageNumber,
    },
  });
  return result;
}

export default {
  getOverview,
  getActivityTimeline,
  getTopRewards,
};
