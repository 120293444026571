export enum RewardType {
  SWISS_LOYALTY = 'SWISS_LOYALTY'
}

export interface Reward {
  type: RewardType;
  value: number;
}

export enum RefferralCampaignType {
  SWISSCARD = 'SWISSCARD',
  AFFILIATE = 'AFFILIATE'
}

export interface ReferralCampaign {
  id: number;
  name: string;
  description: string;
  terms: string;
  label: string;
  maxAmountOfReferrals: number;
  referrerReward: Reward;
  referralReward: Reward;
  active: boolean;
  startDate: Date;
  endDate: Date;
  type: RefferralCampaignType;
  recipientName: string;
  recipientDescription: string;
  recipientMessage: string;
  created: Date;
  createdBy: number;
  modified: Date;
  modifiedBy: number;
  hidden: boolean;
}
