import {BUSINESS_PROFILES} from 'serviceNew/api/businessProfile';
import { STATS_API, OVERVIEW } from 'serviceNew/api/businessProfileStats';
import { IdType } from '@poinz/api';
import { callEndpoint } from './apiClient';
import { DealType } from '../model/deal';
import { DealOverviewStats, DealDetailsStats } from '../model/dealStats';

const DEAL_ENDPOINT = 'deals';
const DETAILS = 'details';

async function getDealOverviewStats(id: IdType, dealType: DealType): Promise<Array<DealOverviewStats>> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${id}/${DEAL_ENDPOINT}/${OVERVIEW}`,
    'GET',
    {
      queryParams: {
        dealType
      }
    }
  );
  return result.body;
}

async function getDealOverviewStatsForDealId(
  params: {
    id: IdType,
    dealId: IdType
  },
): Promise<DealOverviewStats> {
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${params.id}/${DEAL_ENDPOINT}/${params.dealId}/${OVERVIEW}`,
    'GET',
    {}
  );
  return result.body;
}

async function getDealDetailStatsForDealId(
  params: {
    id: IdType,
    dealId: IdType,
    dealRuleId?: IdType,
    endDate?: string,
    startDate?: string
  },
): Promise<DealDetailsStats> {
  const { id, dealId, dealRuleId, startDate, endDate } = params;
  const result = await callEndpoint(
    `/${STATS_API}/${BUSINESS_PROFILES}/${id}/${DEAL_ENDPOINT}/${dealId}/${DETAILS}`,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        dealRuleId
      }
    }
  );
  return result.body;
}

export default {
  getDealOverviewStats,
  getDealOverviewStatsForDealId,
  getDealDetailStatsForDealId
};
