import * as React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TranslationObject } from 'serviceNew/locale';

import Button from './Button';
import TranslatedText from './TranslatedText';

type Props = {
  open: boolean;
  title: TranslationObject | null | undefined;
  content: TranslationObject;
  buttonLabel: TranslationObject;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

const Transition = React.forwardRef((props, ref) => (
  // @ts-expect-error - TS2741 - Property 'children' is missing in type '{ ref: ForwardedRef<unknown>; direction: "up"; }' but required in type 'SlideProps'.
  <Slide direction="up" {...props} {...{ ref }} />
));

// eslint-disable-next-line react/no-multi-comp
class ConfirmationDialog extends React.PureComponent<Props> {
  static defaultProps = {
    open: false,
    title: undefined,
    buttonLabel: { key: 'general.yes' }
  };

  render() {
    const { open, title, content, buttonLabel, onSubmit, onCancel } = this.props;
    return (
      // @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'JSXElementConstructor<TransitionProps & { children: ReactElement<any, any>; }> | undefined'.
      <MuiDialog TransitionComponent={Transition} {...{ open }}>
        {title && (
          <DialogTitle>
            <TranslatedText text={title} />
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>
            <TranslatedText text={content} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" label={{ key: 'general.cancel' }} onClick={onCancel} />
          <Button color="primary" onClick={onSubmit} label={buttonLabel} />
        </DialogActions>
      </MuiDialog>
    );
  }
}

export default ConfirmationDialog;
