import { NewPaginationModel } from 'types';
import { PoinzApiClient } from '../../api';
import { ISearchProduct, ISearchParams } from './types/search';

const apiRoot = `/search/search`;

const getProducts =
  (apiClient: PoinzApiClient) =>
  async (params?: ISearchParams): Promise<NewPaginationModel<ISearchProduct[]>> => {
    const initialParams = {
      searchableType: '',
      labels: []
    };

    return await apiClient.get(apiRoot, { queryParams: { ...initialParams, ...params } });
  };

export default (apiClient: PoinzApiClient) => ({
  getProducts: getProducts(apiClient)
});
