import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    flexDirection: 'column'
  },
  field: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    borderColor: theme.palette.grey[400],
    borderStyle: 'solid',
    height: 200,
    overflow: 'hidden'
  },
  infoBar: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 40
  },
  actionButtons: {
    display: 'flex',
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    color: theme.palette.common.white
  },
  error: {
    // @ts-expect-error - TS2339 - Property 'palette' does not exist on type 'DefaultTheme'.
    border: `1px dashed ${theme.palette.error.main}`,
    // @ts-expect-error - TS2339 - Property 'shape' does not exist on type 'DefaultTheme'.
    borderRadius: theme.shape.borderRadius
  }
}));
