import { ProblemApiError } from '../types';

export function getProblemErrorMessage(e: ProblemApiError) {
  const title = { key: 'errors.error' };
  let message: any = {
    key: 'error.unknown.body'
  };

  if (e?.key) {
    message = { key: e.key };
  } else if (e?.message) {
    message = { key: 'text.no.translation', context: { value: e.message } };
  }

  return { title, message };
}
