import { IdType } from '@poinz/api';
import { apiClient } from './apiClient';
import { getPaginatedResult, PaginationModel } from './api';

import {
  CodeCollectActivity,
  CardCollectActivity,
  DealCollectActivity
} from '../model/collectActivity';

import { DealCollectState, DealType } from '../../constants/general';

const ROOT = '/activities/contract-companies';
const CODE_COLLECTS = 'code-collects';
const CARD_COLLECTS = 'card-collects';
const DEAL_COLLECTS = 'deal-collects';

async function getCodeCollectsActivity(
  id: IdType,
  queryParams?: {
    pageNumber?: number;
    from?: string | null | undefined;
    to?: string | null | undefined;
    businessProfileId?: IdType | null | undefined;
    locationId?: IdType | null | undefined;
    offeringId?: IdType | null | undefined;
  }
): Promise<PaginationModel<CodeCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${id}/${CODE_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams
    }
  );
  // @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<CodeCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

async function getCardCollectsActivity(
  id: IdType,
  queryParams?: {
    pageNumber?: number;
    from?: string | null | undefined;
    to?: string | null | undefined;
    businessProfileId?: IdType | null | undefined;
    locationId?: IdType | null | undefined;
    offeringId?: IdType | null | undefined;
  }
): Promise<PaginationModel<CardCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${id}/${CARD_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams
    }
  );
  // @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<CardCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

async function getDealCollectsActivity(
  id: IdType,
  queryParams?: {
    pageNumber?: number;
    from?: string | null | undefined;
    to?: string | null | undefined;
    businessProfileId?: IdType | null | undefined;
    locationId?: IdType | null | undefined;
    dealId?: IdType | null | undefined;
    dealCollectState?: DealCollectState | null | undefined;
    dealType?: DealType | null | undefined;
  }
): Promise<PaginationModel<DealCollectActivity>> {
  const { pageNumber, ...restParams } = queryParams || {};
  const result = await apiClient.callPaginatedEndpoint(
    pageNumber || 0,
    `${ROOT}/${id}/${DEAL_COLLECTS}`,
    {
      headers: new Headers(),
      queryParams: restParams
    }
  );
  // @ts-expect-error - TS2322 - Type 'PaginationModel<unknown[]>' is not assignable to type 'PaginationModel<DealCollectActivity>'. | TS2345 - Argument of type 'NewPaginationModel<unknown>' is not assignable to parameter of type 'PaginatedResult<{ headers: Headers; body: unknown[]; pageNumber: number; }>'.
  return getPaginatedResult(result);
}

const exportActivityIntoExcel = async (
  contractCompanyId: string,
  suffix: string,
  queryParams: {
    [key: string]: unknown;
  }
) => {
  // @ts-expect-error - TS2322 - Type 'Result' is not assignable to type '{ blob: Blob; }'.
  const {
    blob
  }: {
    blob: Blob;
  } = await apiClient.callEndpoint(`${ROOT}/${contractCompanyId}/${suffix}`, 'GET', {
    headers: new Headers({
      'Content-Type': 'application/vnd.ms-excel',
      accept: 'application/vnd.ms-excel'
    }),
    isBinary: true,
    authenticated: true,
    queryParams
  });

  return blob;
};

export default {
  getCodeCollectsActivity,
  getCardCollectsActivity,
  getDealCollectsActivity,
  exportActivityIntoExcel
};
