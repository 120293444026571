import * as React from 'react';
import { FormHelperText } from '@mui/material';

import TimeDuration from '../TimeDuration';
import TranslatedText from '../TranslatedText';
import { withFieldAdapter, FieldRenderProps } from '../Field';

export { TIME_UNITS } from '../TimeDuration';

type Props = FieldRenderProps;

function TimeDurationField(props: Props) {
  const { className, field, meta, disabled, ...restProps } = props;
  const { error, touched, submitting } = meta;

  const isError = touched && !!error;

  return (
    <div {...{ className }}>
      <TimeDuration {...field} {...restProps} disabled={submitting || disabled} />
      {isError && (
        <FormHelperText error={isError}>
          <TranslatedText text={error} />
        </FormHelperText>
      )}
    </div>
  );
}

export default withFieldAdapter(TimeDurationField);
