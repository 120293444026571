import * as React from 'react';
import { Settings, DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Language, TranslationObject, TranslationKey, MultiLanguageString } from './translations';
import './i18n';
import { parseName } from './utils';
import { TOptionsBase } from 'i18next';

type Props = {
  children: React.ReactNode;
};

export type LanguageInput = {
  id: TranslationKey;
  value?: string;
  defaultMessage?: string;
};

export type NumberInput = {
  value: number;
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string;
  maximumFractionDigits?: number;
};

export type LocaleProviderProps = {
  changeLocale: (locale: Language) => void;
  locale: Language;
  translate: (translationEntity: TranslationKey | TranslationObject, context?: any) => any;
  formatDateTime: (value: string, format: string) => string;
  parseMultilanguageString: (value: string | MultiLanguageString) => string | undefined;
  formatMessage: (value: LanguageInput) => string;
  formatNumber: (value: NumberInput) => string;
};

const defaultContext: LocaleProviderProps = {
  changeLocale: () => undefined,
  locale: 'de',
  translate: () => '',
  formatDateTime: () => '',
  parseMultilanguageString: () => '',
  formatMessage: () => '',
  formatNumber: () => ''
};

export const LocaleContext = React.createContext<LocaleProviderProps>(defaultContext);

const LocaleProvider: React.FC<Props> = ({ children }: Props) => {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    Settings.defaultLocale = i18n.language;
    if (i18n.language != i18n.languages[0]) {
      i18n.changeLanguage(i18n.languages[0]);
    }
  }, [i18n]);

  const changeLocale = React.useCallback(
    (newLocale: Language) => {
      i18n.changeLanguage(newLocale);
    },
    [i18n]
  );

  const translate = React.useCallback(
    (translationEntity: TranslationKey | TranslationObject, context?: any) => {
      if (typeof translationEntity !== 'string') {
        return t(translationEntity?.key, translationEntity?.context as TOptionsBase);
      }
      return t(translationEntity, context);
    },
    [t]
  );

  const formatDateTime = (value: string, format: string) => {
    return `${DateTime.fromISO(value).setLocale(i18n.language).toFormat(format)}`;
  };

  const parseMultilanguageString = (value: string | MultiLanguageString) => {
    return parseName(value, i18n.language);
  };

  const formatMessage = ({ id, value }: LanguageInput) => {
    return i18n.t(id, value as TOptionsBase);
  };

  const formatNumber = ({ value, style, currency, maximumFractionDigits }: NumberInput) =>
    value.toLocaleString(i18n.language, { style, currency, maximumFractionDigits });

  const contextValue = React.useMemo(
    () => ({
      locale: i18n.languages[0] as Language,
      language: i18n.languages[0] as Language,
      changeLocale,
      translate,
      formatDateTime,
      parseMultilanguageString,
      formatMessage,
      formatNumber
    }),
    [
      changeLocale,
      i18n,
      translate,
      formatDateTime,
      parseMultilanguageString,
      formatMessage,
      formatNumber
    ]
  );

  return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
};

// remove this once we reimplement all components to be funcitonal
export const withLocale = (Child: any) => {
  return (props: any) => (
    <LocaleContext.Consumer>
      {(context: LocaleProviderProps) => <Child {...context} {...props} />}
    </LocaleContext.Consumer>
  );
};

export const useLocale = () => React.useContext(LocaleContext);

export default LocaleProvider;
