import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-router-dom"' has no exported member 'ContextRouter'.
import { withRouter, ContextRouter } from 'react-router-dom';

import {
  MarketingCampaignStats,
  MarketingCampaignDetailStats
} from 'serviceNew/model/marketingCampaignStats';
import API from 'serviceNew/api/marketingCampaignStats';
import { ContractCompanyProps, withContractCompany } from 'App/providers/ContractCompanyProvider';
import { parseEntityIdFromRequestParameters } from '@poinz/utils';
import { makeStyles } from '@mui/styles';

import { FlexContainer, FlexRow, FlexItem } from 'App/components';

import RewardGroupStats from './RewardGroupStats';
import EstimationChallengeStats from './EstimationChallengeStats';
import MarketingCampaignKeyFigureStats from './MarketingCampaignKeyFigureStats';

const useStyles = makeStyles(theme => ({
  container: {
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    padding: theme.spacing(2)
  }
}));

type Props = {
  isExpanded: boolean;
  data: MarketingCampaignStats;
} & ContractCompanyProps &
  ContextRouter;

function MarketingCampaignDetailStatsUnconnected(props: Props) {
  const [activityData, setActivityData] = React.useState<
    Array<MarketingCampaignDetailStats> | null | undefined
  >(undefined);
  const classes = useStyles();
  const { isExpanded, data, location } = props;

  const businessProfileId = parseEntityIdFromRequestParameters(
    location.search,
    'businessProfileId'
  );
  const loadData = React.useCallback(async () => {
    if (businessProfileId != null) {
      const retrievedMarketingCampaignStats = await API.getDetailedMarketingCampaignStats(
        businessProfileId,
        data.id
      );

      setActivityData(retrievedMarketingCampaignStats);
    }
  }, [businessProfileId, data.id]);

  React.useEffect(() => {
    if (isExpanded && activityData === undefined) {
      loadData();
    }
  }, [activityData, isExpanded, loadData]);

  const rewardGroups = React.useMemo(() => {
    if (activityData == null || activityData.length < 1) {
      return null;
    }
    return activityData.reduce<Record<string, any>>((acc, ad, idx) => {
      if (Array.isArray(ad.rewardStats)) {
        ad.rewardStats.forEach(rs => {
          acc[rs.rewardId] = rs.name
            ? { key: 'text.no.translation', context: { value: rs.name } }
            : { key: 'marketingCampaignStats.rewardGroup', context: { value: idx + 1 } };
        });
      }
      return acc;
    }, {});
  }, [activityData]);

  // @ts-expect-error - TS2339 - Property 'numWinner' does not exist on type '{}'. | TS2339 - Property 'numWinnerNotClaimed' does not exist on type '{}'.
  const { numWinner, numWinnerNotClaimed } = React.useMemo(() => {
    if (!activityData) {
      return {};
    }
    return activityData.reduce(
      (acc, ad) => {
        // @ts-expect-error
        acc.numWinner.push({
          dateDay: ad.dateDay,
          ...ad.rewardStats.reduce<Record<string, any>>((akk, value) => {
            // eslint-disable-next-line no-param-reassign
            akk[value.rewardId] = value.numWinner;
            return akk;
          }, {})
        });
        // @ts-expect-error
        acc.numWinnerNotClaimed.push({
          dateDay: ad.dateDay,
          ...ad.rewardStats.reduce<Record<string, any>>((akk, value) => {
            // eslint-disable-next-line no-param-reassign
            akk[value.rewardId] = value.numWinnerNotClaimed;
            return akk;
          }, {})
        });
        return acc;
      },
      { numWinner: [], numWinnerNotClaimed: [] }
    );
  }, [activityData]);

  if (isExpanded) {
    return (
      <div className={classes.container}>
        <FlexContainer>
          {activityData != null && (
            <FlexRow>
              <FlexItem>
                <MarketingCampaignKeyFigureStats
                  // @ts-expect-error - TS2322 - Type 'any' is not assignable to type 'never'.
                  marketingCampaignStats={data}
                  {...{ activityData }}
                />
              </FlexItem>
            </FlexRow>
          )}
          <FlexRow>
            {rewardGroups && (
              <FlexItem flexBasis={500}>
                <RewardGroupStats {...{ rewardGroups, numWinner, numWinnerNotClaimed }} />
              </FlexItem>
            )}
            {activityData && data.hasEstimationChallenge && (
              <FlexItem flexBasis={500}>
                {/* @ts-expect-error - TS2322 - Type 'any' is not assignable to type 'never'. */}
                <EstimationChallengeStats marketingCampaignStats={data} {...{ activityData }} />
              </FlexItem>
            )}
          </FlexRow>
        </FlexContainer>
      </div>
    );
  }
  return null;
}

export default withRouter(withContractCompany(MarketingCampaignDetailStatsUnconnected) as any);
