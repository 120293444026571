import { PoinzApiClient } from '../../api';
import { Location } from './types';
import { ROOT } from './config';

const VERSION = '/v2';
const BUSINESS_PROFILES = '/business-profiles';
const LOCATIONS = '/locations';

const apiRoot = `${ROOT}${VERSION}${BUSINESS_PROFILES}/:businessProfileId${LOCATIONS}`;

const getList =
  (apiClient: PoinzApiClient) =>
  async (id: number, includeDeleted = false): Promise<Location[]> => {
    const result = await apiClient.get(apiRoot, {
      pathParams: { businessProfileId: id },
      queryParams: { includeDeleted }
    });
    return result.body.map((loc: any) => new Location(loc));
  };

const get =
  (apiClient: PoinzApiClient) =>
  async (params: { locationId: number; businessProfileId: number }): Promise<Location> => {
    const { locationId, businessProfileId } = params;
    const result = await apiClient.get(`${apiRoot}/:locationId`, {
      pathParams: {
        businessProfileId,
        locationId
      }
    });
    return new Location(result.body);
  };

const update =
  (apiClient: PoinzApiClient) =>
  async (
    params: {
      businessProfileId: number;
      locationId: number;
    },
    data: FormData
  ): Promise<Location> => {
    const { businessProfileId, locationId } = params;
    const result = await apiClient.put(`${apiRoot}/:locationId`, {
      isMultiPart: true,
      body: data,
      pathParams: {
        businessProfileId,
        locationId
      }
    });
    return new Location(result.body);
  };

export default (apiClient: PoinzApiClient) => ({
  get: get(apiClient),
  update: update(apiClient),
  getList: getList(apiClient)
});
