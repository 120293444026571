import * as React from 'react';
import { matchSorter } from 'match-sorter';
import Downshift from 'downshift';
import { useLocale } from 'App/providers/LocaleProvider';
import { Paper, MenuItem, TextFieldProps } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { TranslationObject } from 'serviceNew/locale';
import TextInput from './TextInput';
import { Option } from './TextSelect';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    // @ts-expect-error - TS2339 - Property 'spacing' does not exist on type 'DefaultTheme'.
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: 250,
    overflow: 'scroll'
  }
}));

type TranslatedOption = {
  value: string | number;
  label: string;
};

type Props = Partial<TextFieldProps> & {
  label: TranslationObject;
  options: Option[];
  value: number | string;
  onChange: (arg1: number | string) => void;
  disabled: boolean | null | undefined;
};

function TextSelectSearch({
  options,
  value,
  onChange,
  label,
  disabled = false,
  ...restProps
}: Props) {
  const classes = useStyles();
  const { translate } = useLocale();

  const translatedOptions: TranslatedOption[] = options.map(o => ({
    ...o,
    label: translate(o.label.key, o.label.context)
  }));

  const selectedOption: TranslatedOption | null | undefined = translatedOptions.find(
    o => o.value === value
  );

  const { defaultValue } = (restProps as any).inputProps;
  const [inputValue, setInputValue] = React.useState(
    selectedOption ? selectedOption.label : defaultValue || ''
  );

  const getInputIsValue = () => (selectedOption ? inputValue === selectedOption.label : false);

  const handleKeyDown = (event: any) => {
    if (getInputIsValue() && event.key === 'Backspace') {
      setInputValue('');
    }
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleChange = (option: TranslatedOption) => {
    onChange(option.value);
    setInputValue(option.label);
  };

  const resetValue = () => {
    setInputValue(selectedOption ? selectedOption.label : '');
  };

  function renderOption(suggestionProps: { itemProps: never; option: TranslatedOption | any }) {
    const { option, itemProps } = suggestionProps;

    const isSelected = value === option.value;

    return (
      <MenuItem
        {...(itemProps as any)}
        key={option.value}
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
      >
        {option.label}
      </MenuItem>
    );
  }

  function getOptions() {
    return inputValue.length === 0 || getInputIsValue()
      ? translatedOptions
      : matchSorter(translatedOptions, inputValue, { keys: ['label'] });
  }

  function renderInput(inputProps) {
    const { InputProps, ref, ...other } = inputProps;

    return (
      <TextInput
        {...other}
        {...restProps}
        {...{ label }}
        disabled={disabled}
        InputProps={{
          inputRef: ref,
          ...InputProps
        }}
        noLabel
      />
    );
  }

  const handleOpenMenu = (openMenu: any) => () => {
    if (!disabled) {
      openMenu();
    }
  };

  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <Downshift
      itemToString={item => (item ? item.value : '')}
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={value}
    >
      {({ getInputProps, getItemProps, getLabelProps, isOpen, openMenu }) => {
        const {
          onBlur,
          onChange: inputChange,
          onFocus,
          onClick,
          ...inputProps
        } = getInputProps({
          onKeyDown: handleKeyDown,
          onFocus: handleOpenMenu(openMenu),
          onClick: handleOpenMenu(openMenu),
          onBlur: resetValue,
          onChange: handleInputChange
        });

        return (
          <div className={classes.container}>
            {renderInput({
              InputLabelProps: getLabelProps(),
              InputProps: {
                endAdornment: <Icon icon="arrow_drop_down" />,
                onBlur,
                onChange: inputChange,
                onFocus,
                onClick
              },
              value: inputValue,
              inputProps
            })}

            {isOpen ? (
              <Paper className={classes.paper} square>
                {getOptions().map(option =>
                  renderOption({
                    option,
                    // @ts-expect-error - TS2322 - Type 'Omit<Overwrite<GetItemPropsReturnValue, { item: TranslatedOption; }>, "item" | "index">' is not assignable to type 'never'.
                    itemProps: getItemProps({ item: option })
                  })
                )}
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
}

TextSelectSearch.defaulProps = {
  value: ''
};

export default TextSelectSearch;
