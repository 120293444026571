import * as React from 'react';

import { Redirect, Route, useLocation } from 'react-router-dom';

import { useIsLoggedIn } from 'serviceNew/auth';

type Props = {
  component?: React.ComponentType<any>,
  render?: () => React.ReactElement,
  redirectTo?: string
} & JSX.LibraryManagedAttributes<typeof Route, React.ComponentProps<typeof Route>>;

function PrivateRoute(props: Props) {
  const { component: Component, render, redirectTo, ...restProps } = props;

  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  return (
    <Route
      {...restProps}
      render={innerProps => {
        if (isLoggedIn) {
          if (render) {
            return render();
          }
// @ts-expect-error - TS2604 - JSX element type 'Component' does not have any construct or call signatures.
          return <Component {...innerProps} />;
        }

        return <Redirect to={{ pathname: redirectTo || '/login', state: { from: location } }} />;
      }}
    />
  );
}

export default PrivateRoute;
